import axios from "axios";
import Cookies from "universal-cookie";
import get from "lodash/get";
import * as types from "../ApiTypes";
import * as actions from "./sharedFunction";
import { API_URL, DISPLAY_CONFIG_URL, ROOM_URL, BASE_URL, COOKIE_PREFIX } from "../env";
import { getCompanyId } from "../helpers";

const cookies = new Cookies();

export const getDisplayConfig = (displayCode) => async (dispatch) => {
  dispatch(actions.requestData(types.GET_DISPLAY_CONFIG));
  
  const displayConfigUrl = await DISPLAY_CONFIG_URL();
  const roomUrl = await ROOM_URL();

  const headers = {};
  headers.Authorization = `Bearer ${displayCode}`;
  
  axios.get(`${displayConfigUrl}display/configuration`, { headers })
    .then((response) => (
      dispatch(actions.receiveData(response.data, types.GET_DISPLAY_CONFIG, roomUrl))
    ))
    .catch((error) => {
      dispatch(actions.receiveError(error, types.GET_DISPLAY_CONFIG));
    });
};

export const createDisplay = (displayId, config, redirect) => async (dispatch) => {
  dispatch(actions.postData(types.CREATE_DISPLAY));
  
  const apiUrl = await API_URL();
  const baseUrl = await BASE_URL();
  const companyId = getCompanyId();
  const cookiePrefix = await COOKIE_PREFIX();
  const accessToken = cookies.get(`${cookiePrefix}_access_token`);
  const axiosConfig = await actions.getAxiosConfig();
  
  axios.get(`/sign?displayId=${displayId}`)
    .then((signResponse) => {

      const body = {...config, displayId: signResponse.data };

      axios.post(`${apiUrl}companies/${companyId}/displays`, body, axiosConfig)
        .then((response) => {
          dispatch(actions.receiveData(response.data, types.CREATE_DISPLAY));
          if (redirect) {
            redirect(`/companies/${companyId}/screens`);
          }
        })
        .catch((error) => {
          if (error.response && error.response.status === 401) {
            actions.sessionTokenExpired(cookiePrefix, accessToken);
          } else {
            dispatch(actions.receiveError(error, types.CREATE_DISPLAY));
          }
        });
    })
    .catch((error) => {
      dispatch(actions.receiveError(error, types.CREATE_DISPLAY));
    });
};

export const getDisplays = (queryParams) => async (dispatch) => {
  dispatch(actions.requestData(types.GET_DISPLAYS));

  const apiUrl = await API_URL();
  const companyId = getCompanyId();
  const cookiePrefix = await COOKIE_PREFIX();
  const accessToken = cookies.get(`${cookiePrefix}_access_token`);
  
  axios.get(`${apiUrl}companies/${companyId}/displays`, await actions.getAxiosConfig(queryParams))
    .then((response) => {
      dispatch(actions.receiveData(response.data, types.GET_DISPLAYS));
    })
    .catch((error) => {
      if (error.response && error.response.status === 401) {
        actions.sessionTokenExpired(cookiePrefix, accessToken);
      } else {
        dispatch(actions.receiveError(error, types.GET_DISPLAYS));
      }
    });
};

export const getDisplay = (displayId) => async (dispatch) => {
  dispatch(actions.requestData(types.GET_DISPLAY));

  const apiUrl = await API_URL();
  const baseUrl = await BASE_URL();
  const companyId = getCompanyId();
  const cookiePrefix = await COOKIE_PREFIX();
  const accessToken = cookies.get(`${cookiePrefix}_access_token`);
  
  axios.get(`${apiUrl}companies/${companyId}/displays/${displayId}`, await actions.getAxiosConfig())
    .then((response) => {
      const display = response.data;
      if (displayId) {
        axios.get(`${baseUrl}sign?displayId=${displayId}`)
          .then((signResponse) => {
            display.url = `${window.location.origin}/dashboard/${signResponse.data}`;
            dispatch(actions.receiveData(display, types.GET_DISPLAY));
          })
          .catch((error) => {
            dispatch(actions.receiveError(error, types.GET_DISPLAY));
          });
      }
    })
    .catch((error) => {
      if (error.response && error.response.status === 401) {
        actions.sessionTokenExpired(cookiePrefix, accessToken);
      } else {
        dispatch(actions.receiveError(error, types.GET_DISPLAY));
      }
    });
};

export const updateDisplay = (displayId, config, redirect) => async (dispatch) => {
  dispatch(actions.postData(types.UPDATE_DISPLAY));

  const apiUrl = await API_URL();
  const companyId = getCompanyId();
  const cookiePrefix = await COOKIE_PREFIX();
  const accessToken = cookies.get(`${cookiePrefix}_access_token`);

  // Clean up config
  if (get(config, "yourLocation", null)) {
    const lat = Number(get(config, "yourLocation.lat", null));
    const lng = Number(get(config, "yourLocation.lng", null));
    const color = get(config, "yourLocation.color", null);
    if (lat && lng) {
      config.yourLocation = {};
      config.yourLocation.lat = lat;
      config.yourLocation.lng = lng;
      if (color) {
        config.yourLocation.color = color;
      }
    }
    else {
      delete config.yourLocation;
    }
  }

  const bearing = Number(get(config, "initialBearing", 0));
  if (bearing) {
    config.initialBearing = bearing;
  }
  
  axios.put(`${apiUrl}companies/${companyId}/displays/${displayId}`, config, await actions.getAxiosConfig())
    .then((response) => {
      dispatch(actions.receiveData(response.data, types.UPDATE_DISPLAY));
      if (redirect) {
        redirect(`/companies/${companyId}/screens`);
      }
    })
    .catch((error) => {
      if (error.response && error.response.status === 401) {
        actions.sessionTokenExpired(cookiePrefix, accessToken);
      } else {
        dispatch(actions.receiveError(error, types.UPDATE_DISPLAY));
      }
    });
};

export const deleteDisplay = (displayId, redirect) => async (dispatch) => {
  dispatch(actions.requestData(types.DELETE_DISPLAY));

  const apiUrl = await API_URL();
  const companyId = getCompanyId();
  const cookiePrefix = await COOKIE_PREFIX();
  const accessToken = cookies.get(`${cookiePrefix}_access_token`);
  
  axios.delete(`${apiUrl}companies/${companyId}/displays/${displayId}`, await actions.getAxiosConfig())
    .then((response) => {
      dispatch(actions.receiveData(response.data, types.DELETE_DISPLAY));
      if (redirect) {
        redirect(`/companies/${companyId}/screens`);
      }
    })
    .catch((error) => {
      if (error.response && error.response.status === 401) {
        actions.sessionTokenExpired(cookiePrefix, accessToken);
      } else {
        dispatch(actions.receiveError(error, types.DELETE_DISPLAY));
      }
    });
};

export const setRestartMessageDate = (date) => async (dispatch) => {
  dispatch(actions.receiveData(date, types.RESTART_MESSAGE));
};

export const updateDisplayForm = (form) => async (dispatch) => {
  dispatch(actions.receiveData(form, types.UPDATE_DISPLAY_FORM));
};

export const resetDisplayForm = () => async (dispatch) => {
  dispatch({ type: types.RESET_DISPLAY_FORM });
};

export const clearDisplayData = () => async (dispatch) => {
  dispatch({ type: types.CLEAR_DATA });
}