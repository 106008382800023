import React from "react";
import { Col, Row } from "react-grid-system";
import { Audio } from "react-loader-spinner";
import Button from "../Button";
import style from "./style.module.scss";

const Modal = ({ show, onHide, isLoading, title, text, texts, primaryBtn, secondaryBtn, tertiaryBtn, children }) => (
  show && (
    <div className={style.wrapper}>
      <div className={style.overlay} onClick={onHide} role="presentation" />
      <div className={style.modal}>
        <h1 className={style.title}>{title}</h1>
        {
          text && <p className={style.text}>{text}</p>
        }
        {
          texts && <p className={style.text}>{texts.map(t => (<>{t}<br/></>))}</p>
        }
        {isLoading && <Audio color="#ccc" height={"75px"} width={"75px"} />}
        {
          children && <div className={style.text}>{ children }</div>
        }
        {
          primaryBtn && secondaryBtn && tertiaryBtn && (
            <Row>
              <Col xs={12} sm={8} md={4} lg={4} offset={{ sm: 2, md: 0 }}>
                {tertiaryBtn && <Button color="white" {...tertiaryBtn} />}
              </Col>
              <Col xs={12} sm={8} md={4} lg={4} offset={{ sm: 2, md: 0 }}>
                {secondaryBtn && <Button {...secondaryBtn} />}
              </Col>
              <Col xs={12} sm={8} md={4} lg={4} offset={{ sm: 2, md: 0 }}>
                {primaryBtn && <Button {...primaryBtn} />}
              </Col>
            </Row>
          ) 
        }
        {
          primaryBtn && secondaryBtn && !tertiaryBtn && (
            <Row>
              <Col xs={12} sm={8} md={6} lg={6} offset={{ sm: 2, md: 0 }}>
                {secondaryBtn && <Button color="white" {...secondaryBtn} />}
              </Col>
              <Col xs={12} sm={8} md={6} lg={6} offset={{ sm: 2, md: 0 }}>
                {primaryBtn && <Button {...primaryBtn} />}
              </Col>
            </Row>
          )
        }
        {
          primaryBtn && !secondaryBtn && !tertiaryBtn && (
            <Row>
              <Col xs={12} sm={8} md={6} lg={6} offset={{ sm: 2, md: 3 }}>
                {primaryBtn && <Button {...primaryBtn} />}
              </Col>
            </Row>
          )
        }
      </div>
    </div>
  )
);

export default Modal;
