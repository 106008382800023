import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import moment from "moment";
import SearchBox from "../../../../components/SearchBox";
import Table from "../../../../components/Table";
import TopRowOptions from "../../../../components/TopRowOptions";
import * as anomalyActions from "../../../../actions/anomalies";
import CheckboxBlock from "../../../../components/CheckboxBlock";

class AnomalyList extends Component {

  constructor(props) {
    super(props);
    this.state = {
      searchText: undefined,
      sortBy: "createdAt",
      sortOrder: "desc",
      offset: 0,
      limit: 20,
      showArchived: false,
      showDeleted: false,
      highlightedId: undefined
    };
    this.fetchData = this.fetchData.bind(this);
    this.onRowClick = this.onRowClick.bind(this);
    this.onToggle = this.onToggle.bind(this);
  }

  componentDidMount() {
    this.fetchData();
  }

  onRowClick(column, rowInfo) {
    return {
      onClick: e => {
        if (rowInfo && column.name !== "isSelected") {
          let link = `/companies/${this.props.match.params.companyId}/installation/anomalies/${rowInfo.original.anomalyId}/info`;

          if (e.metaKey || e.ctrlKey) {
            window.open(`${link}`);
          }
          else {
            this.props.history.push(link);
          }
        }
      },
      onMouseOver: e => {
        if (rowInfo && rowInfo.original.anomalyId) {
          this.onHover(rowInfo.original.anomalyId);
        }
      },
      onMouseLeave: e => this.onHover(null),
      style: {
        cursor: "pointer",
        background: (
          rowInfo && rowInfo.original.anomalyId != null && rowInfo.original.anomalyId === this.state.highlightedId ? 
          "rgba(0,0,0,0.05)" : (rowInfo && rowInfo.original.anomalyId != null && rowInfo.original.anomalyId === this.props.match.params.anomalyId) ? "rgba(0,0,0,0.05)" : null)
      }
    }
  }

  onHover(id) {
    if (this.state.highlightedId !== id) {
      this.setState({ highlightedId: id });
    }
  }

  onToggle(row) {
    // if (this.props.selectedUsers[row.original.id] !== undefined && this.props.selectedUsers[row.original.id]) {
    //   this.props.deselectUser(row.original.id);
    // }
    // else {
    //   this.props.selectUser(row.original);
    // }
  }

  fetchData() {
    const queryParams = {
      search: this.state.searchText,
      sortBy: this.state.sortBy,
      sortOrder: this.state.sortOrder,
      offset: this.state.offset,
      limit: this.state.limit
    };

    queryParams.showArchived = this.state.showArchived;
    queryParams.showDeleted = this.state.showDeleted;

    this.props.getAnomalies(queryParams);
  }

  render() {
    const { isLoading } = this.props;
    // console.log("AnomalyList.render()", this.props);
  
    // const hasSelection = !isEmpty(this.props.selectedUsers);

    const options = (
      <TopRowOptions
        searchbox={(
          <SearchBox
            value={this.state.searchText}
            onSearchChanged={(value) => { this.setState({ searchText: value }) }}
            onSearchClick={() => this.setState({ offset: 0 }, this.fetchData)}
            onClear={() => this.setState({ searchText: undefined, offset: 0 }, this.fetchData)}
            inListView
          />
        )}
        buttons={(
          <div style={{ display: "flex", gap: "10px" }}>
            <CheckboxBlock
              label="Show archived"
              isChecked={this.state.showArchived}
              onClick={() => this.setState({ showArchived: !this.state.showArchived, offset: 0 }, this.fetchData)}
            />
            <CheckboxBlock
              label="Show deleted"
              isChecked={this.state.showDeleted}
              onClick={() => this.setState({ showDeleted: !this.state.showDeleted, offset: 0 }, this.fetchData)}
            />
          </div>
        )}
      />
    );

    const anotherTypeSelected = this.props.selectedTypes.length > 0 && !this.props.selectedTypes.includes("anomaly");

    const tableElement = (
      <Table
        data={this.props.anomalies.data}
        sortBy={this.state.sortBy}
        sortOrder={this.state.sortOrder}
        offset={this.state.offset}
        limit={this.state.limit}
        count={this.props.anomalies.count}
        onSortedChange={(newSorted) => {
          this.setState({ sortBy: newSorted[0].id, sortOrder: newSorted[0].desc ? "desc" : "asc", offset: 0 }, this.fetchData);
        }}
        onOffsetChange={(offset) => {
          this.setState({ offset }, this.fetchData);
        }}
        noDataText={isLoading ? "" : "No anomalies found"}
        columns={[
          // {
          //   header: "",
          //   accessorKey: "id",
          //   sortable: false,
          //   name: "isSelected",
          //   cell: ({ row }) => (
          //     <label className="checkboxContainer" htmlFor={`editCheckbox-${row.original.id}`}>
          //       <input
          //         id={`editCheckbox-${row.original.id}`}
          //         type="checkbox"
          //         className="checkbox"
          //         checked={
          //           this.props.selectedUsers[row.original.id] !== undefined && this.props.selectedUsers[row.original.id]
          //         }
          //         onChange={() => this.onToggle(row)}
          //         disabled={anotherTypeSelected}
          //       />
          //       <span className={anotherTypeSelected ? "disabledCheckmark" : "checkmark"} />
          //     </label>
          //   ),
          //   width: 60
          // },
          {
            header: "Created",
            accessorKey: "createdAt",
            defaultSortDesc: true,
            width: 140,
            cell: ({ row }) => (
              <div>
                {moment(row.original.createdAt).format("DD/MM/YY HH:mm")}
              </div>
            )
          },
          {
            header: "Property",
            accessorKey: "property",
            width: 140,
            cell: ({ row }) => (
              <div>
                { row.original.property ?? "N/A" }
              </div>
            )
          },
          {
            header: "Location",
            accessorKey: "locations",
            cell: ({ row }) => (
              <div>
                {row.original.locations.map((location, index) => (
                  <div key={index}>
                    {location.name}
                  </div>
                ))}
              </div>
            ),
          },
          {
            header: "Reason",
            accessorKey: "action",
            cell: ({ row }) => {

              let description = "";
              if (row.original.action === "IRREGULAR_DATA") {
                description = row.original.reason.toLowerCase().replaceAll("_", " ");
                description = description.charAt(0).toUpperCase() + description.slice(1);
              }
              else {
                description = row.original.action.toLowerCase().replaceAll("_", " ");
              }

              return (
                 <div>
                  { description }
                </div>
              );
            }
          },
          {
            header: "When",
            accessorKey: "start",
            cell: ({ row }) => {
              
              let start = moment(row.original.start);
              let end = moment(row.original.end);
              
              let dateString; 
              if (start.format("DD/MM/YY") === end.format("DD/MM/YY")) {
                if (start.format("HH:mm") === end.format("HH:mm")) {
                  dateString = `${start.format("DD/MM/YY")} ${start.format("HH:mm")}`;
                }
                else {
                  dateString = `${start.format("DD/MM/YY")} ${start.format("HH:mm")}-${end.format("HH:mm")}`;
                }
              }
              else {
                dateString = `${moment(row.original.start).format("DD/MM/YY")} - ${moment(row.original.end).format("DD/MM/YY")}`;
              }

              return (
                <div>
                  { dateString} 
                </div>
              );
            }
          },
          {
            header: "Info",
            accessorKey: "state",
            width: 80,
            cell: ({ row }) => (
              <div>
                { row.original.state }
              </div>
            )
          },
          {
            id: "arrow",
            header: "",
            sortable: false,
            className: "pull-right",
            width: 60,
            cell: ({ row }) => (<div><div className="arrow" /></div>)
          }
        ]}
        getTdProps={this.onRowClick}
        className="setMaxHeigth -row-clickable"
        loading={isLoading}
      />
    );

    return (
      <>
        <div>
          { options }
          { tableElement }
          <div style={{ paddingTop: "40px" }} />
        </div>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    anomalies: state.anomalies,
    auth: state.auth,
    isLoading: state.loading.anomalies,
    selectedTypes: state.selected.allTypes,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ 
    getAnomalies: anomalyActions.getAnomalies,
    updateAnomaly: anomalyActions.updateAnomaly,
   }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(AnomalyList);
