import React from "react";
import { Switch, Route } from "react-router-dom";
import InstallationContainer from "./container";
import NotFound from "../NotFound";

const InstallationSwitch = () => (
  <Switch>
    <Route path="/companies/:companyId/installation">
      <InstallationContainer />
    </Route>
    <Route >
      <NotFound />
    </Route>
  </Switch>
);

export default InstallationSwitch;