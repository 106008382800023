import React from "react";
import { Container, Row, Col } from "react-grid-system";
import Freetext from "../../components/Freetext";
import style from "./style.module.scss"

const NotFound = () => (
  <div className={style.outerScroll}>
    <Container className={style.container}>
      <Row className={style.topRow}>
        <Col>
          <Freetext header="Woops!" content="Could not find what you were looking for." />
        </Col>
      </Row>
    </Container>
  </div>
);

export default NotFound;
