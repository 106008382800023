import { toast } from "react-toastify";
import * as types from "../ApiTypes";

const initialState = {
  list: []
};

export default function customTagsReducer(state = initialState, action) {

  switch (action.type) {
    
    case types.REQ_DATA: {
      if (action.fetchType === types.GET_CUSTOM_TAGS) {
        return { list: [] };
      }
      
      return state;
    }

    case types.RECV_DATA: {
      if (action.fetchType === types.GET_CUSTOM_TAGS) {
        return { list: action.payload };
      }

      return state;
    }

    case types.RECV_ERROR: {
      if (action.fetchType === types.GET_CUSTOM_TAGS) {
        toast.error("Could not get custom tags");
        return state;
      }
      
      return state;
    }
    
    case types.CLEAR_DATA: {
      return initialState;
    }

    default: {
      return state;
    }
  }
}
