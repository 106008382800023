import { Visible, Hidden, setConfiguration } from "react-grid-system";
import React, { Component } from "react";
import bldngAiLogo from "./bldng-ai-logo.jpg";
import style from "./style.module.scss"

class LogoBar extends Component {

  constructor(props) {
		super(props);
   
    // Set gutter width for mobile
    setConfiguration({ gutterWidth: 20 });
  }

  getLogoElement() {
    return (
      <div tabIndex="0" role="button" className={style.brandImage} onClick={this.onLogoClick} onKeyDown={this.onLogoClick}>
        <img src={bldngAiLogo} alt="Logo" width="60px" height="60px" />
      </div>
    );
  }

  render() {
    return (
      <header className={style.header} role="banner">
        <Hidden xs sm md>
          <div className={style.largeMargin}>
            { this.getLogoElement() }
            <div className={style.title}>
              BLDNG.ai
            </div>
          </div>
        </Hidden>
        <Visible xs sm md>
          <div className={style.smallMargin}>
            { this.getLogoElement() }
            <div className={style.title}>
              BLDNG.ai
            </div>
          </div>
        </Visible>
      </header>
    );
  }
}

export default LogoBar;
