import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Container, Col, Row, Hidden, Visible } from "react-grid-system";
import { get, isEmpty } from "lodash";
import moment from "moment";
import SegmentedControl from "../../components/SegmentedControl";
import { Dropdown } from "../../components/DropdownSelection";
import Datepicker from "../../components/Datepicker";
import CheckboxBlock from "../../components/CheckboxBlock";
import { GraphType, StepSize, TimePeriod } from "../../components/Dashboard/Graph";
import GraphContainer from "../../components/Dashboard/Graph/container";
import { updateDashboard, queryHash } from "../../dashboardHelpers";
import { Role, toShortISOString } from "../../helpers";
import { getLocation } from "../../locationHelpers";
import * as selectedActions from "../../actions/selected";
import * as dashboardActions from "../../actions/dashboards";
import style from "./style.module.scss";

class Dashboard extends Component {

  constructor(props) {
    // console.log("Home.Dashboard.constructor");
    super(props);
    this.state = {
      timePeriod: this.getInitialPeriod(props),
      date: this.getInitialDay(props),
      showWholeDay: this.getInitialWholeDay(props),
      showWeekends: this.getInitialWeekends(props),
      showHolidays: this.getInitialHolidays(props),
      showQuarterHours: this.getInitialQuarterHours(props),
      expandedType: null,
      hasLoadedState: false,
    };

    this.onTimePeriodChanged = this.onTimePeriodChanged.bind(this);
    this.onDateChanged = this.onDateChanged.bind(this);
    this.onWeekNumberChanged = this.onWeekNumberChanged.bind(this);
    this.onMonthChanged = this.onMonthChanged.bind(this);
    this.onShowWholeDayChanged = this.onShowWholeDayChanged.bind(this);
    this.onShowWeekendsChanged = this.onShowWeekendsChanged.bind(this);
    this.onShowHolidaysChanged = this.onShowHolidaysChanged.bind(this);
    this.onShowQuarterHoursChanged = this.onShowQuarterHoursChanged.bind(this);
    this.onExpandChanged = this.onExpandChanged.bind(this);

    // If too early - show yesterday
    if (!this.state.showWholeDay && moment().isSame(this.state.date) && this.state.date.hour() < 9) {
      this.state.date = moment().startOf("day").subtract(1, "day");
    }
  
    // Set initial time scale
    this.state.timeScale = this.state.timePeriod === "day" ? "hour" : "day";
  }

  getSensorGraphIds() {

    let sensorGraphIds;
    if (this.state.timePeriod === "avg") {
      sensorGraphIds = [];
    }
    else {
      sensorGraphIds = [...this.props.filterSensorTypes];
    }
    
    // If includes peopleCount, we add peopleCount-building, peopleCount-floor, peopleCount-zone and peopleCount-room to the list of sensor types 
    if (this.props.filterSensorTypes.includes("peopleCount")) {

      sensorGraphIds = sensorGraphIds.filter(sensorType => sensorType !== "peopleCount");

      // Check if building/floor/zone/room has peopleCount
      let buildingHasPeopleCount = false;
      let floorHasPeopleCount = false;
      let zoneHasPeopleCount = false;
      let numberOfRoomsWithPeopleCount = 0;
      let numberOfMeetingRoomsWithPeopleCount = 0;
      this.props.locations.forEach(location => {
        if (location.type === "building" && get(this.props.allLocations[location._id], "recordedProperties", []).includes("peopleCount")) {
          buildingHasPeopleCount = true;
        }
        else if (location.type === "floor" && get(this.props.allLocations[location._id], "recordedProperties", []).includes("peopleCount")) {
          floorHasPeopleCount = true;
        }
        else if (location.type.includes("zone") && get(this.props.allLocations[location._id], "recordedProperties", []).includes("peopleCount")) {
          zoneHasPeopleCount = true;
        }
        else if (location.type.includes("room") && get(this.props.allLocations[location._id], "recordedProperties", []).includes("peopleCount")) {
          numberOfRoomsWithPeopleCount += 1;
          
          if (location.type.includes("room.meeting")) {
            numberOfMeetingRoomsWithPeopleCount += 1;
          }
        }
      });

      // If time period is avg, we add quarterOfDayOccupiedMinutes to the list of sensor types
      if (this.state.timePeriod === "avg") {
        if (numberOfRoomsWithPeopleCount > 0 && numberOfRoomsWithPeopleCount === numberOfMeetingRoomsWithPeopleCount) {
          sensorGraphIds = ["peopleDayOfWeekInMeetingRooms", ...sensorGraphIds];
        }
        else {
          if (numberOfRoomsWithPeopleCount > 0) {
            sensorGraphIds = ["peopleDayOfWeekInRooms", ...sensorGraphIds];
          }
          if (numberOfMeetingRoomsWithPeopleCount > 0) {
            sensorGraphIds = ["peopleDayOfWeekInMeetingRooms", ...sensorGraphIds];
          }
        }
        if (zoneHasPeopleCount) {
          sensorGraphIds = ["peopleDayOfWeekInZones", ...sensorGraphIds];
        }
        if (floorHasPeopleCount) {
          sensorGraphIds = ["peopleDayOfWeekInFloors", ...sensorGraphIds];
        }
        if (buildingHasPeopleCount) {
          sensorGraphIds = ["peopleDayOfWeekInBuildings", ...sensorGraphIds];
        }
      }
      else {
        if (numberOfRoomsWithPeopleCount > 0 && numberOfRoomsWithPeopleCount === numberOfMeetingRoomsWithPeopleCount) {
          sensorGraphIds = ["peopleCountInMeetingRooms", ...sensorGraphIds];
        }
        else {
          if (numberOfRoomsWithPeopleCount > 0) {
            sensorGraphIds = ["peopleCountInRooms", ...sensorGraphIds];
          }
          if (numberOfMeetingRoomsWithPeopleCount > 0) {
            sensorGraphIds = ["peopleCountInMeetingRooms", ...sensorGraphIds];
          }
        }
        if (zoneHasPeopleCount) {
          sensorGraphIds = ["peopleCountInZones", ...sensorGraphIds];
        }
        if (floorHasPeopleCount) {
          sensorGraphIds = ["peopleCountInFloors", ...sensorGraphIds];
        }
        if (buildingHasPeopleCount) {
          sensorGraphIds = ["peopleCountInBuildings", ...sensorGraphIds];
        }
      }
    }

    // If time period is avg, we add quarterOfDayOccupiedMinutes to the list of sensor types
    if (this.state.timePeriod === "avg") {
      if (this.props.filterSensorTypes.includes("occupiedMinutes")) {
        sensorGraphIds.push("occupiedDayOfWeek");
        sensorGraphIds.push("occupiedQuarterOfDay");
      }
    }

    // Ignore spesific sensor types in dashboard (others are globally ignored in filter_reducer)
    sensorGraphIds = sensorGraphIds.filter(t => t !== "cumulativeEnergy");

    return sensorGraphIds;
  }
    
  componentDidMount() {
    document.title = `BLDNG.ai - Home - Dashboard`;
    this.update();
  }

  update() {
    updateDashboard(this.state, this.props, this.getSensorGraphIds());
  }

  static getDerivedStateFromProps(nextProps, prevState) {

    let newState = {
      ...prevState
    };

    if (prevState.hasLoadedState) {
      return newState;
    }

   
    if (!isEmpty(nextProps.filterSensorTypes) && !isEmpty(nextProps.locations) && !isEmpty(nextProps.locationHierarchy)) {
      
      newState.hasLoadedState = true;
      
      // console.log("Home.Dashboard.getDerivedStateFromProps nextProps", nextProps);
      // console.log("Home.Dashboard.getDerivedStateFromProps prevState", prevState);

      newState.coreTime = { start: { hours: 8, minutes: 0 }, end: { hours: 16, minutes: 0 }};

      // updateDashboard(prevState, nextProps);
  
      return newState;
    }
   
    return null;
  }

  componentDidUpdate(prevProps, prevState) {
    // console.log("Home.Dashboard.componentDidUpdate prevState", prevState);
    // console.log("Home.Dashboard.componentDidUpdate this.state", this.state);
    this.update();
  }

  getInitialPeriod(props) {
    const queryParams = new URLSearchParams(props.history.location.search);
    if (queryParams.get("p") === "avg") {
      return "avg";
    }
    return Object.values(TimePeriod).includes(queryParams.get("p")) ? queryParams.get("p") : TimePeriod.Day;
  }

  getInitialDay(props) {
    const queryParams = new URLSearchParams(props.history.location.search);
    const paramDay = moment(queryParams.get("d"));

    const earliestMoment = moment().startOf("month").subtract(6, "months");
    if (paramDay.isValid() && paramDay.isAfter(earliestMoment)) {
      return paramDay;
    }

    return moment();
  }

  getInitialWholeDay(props) {
    const queryParams = new URLSearchParams(props.history.location.search);
    return queryParams.get("wd") === "true";
  }

  getInitialWeekends(props) {
    const queryParams = new URLSearchParams(props.history.location.search);
    return queryParams.get("w") !== "false";
  }

  getInitialHolidays(props) {
    const queryParams = new URLSearchParams(props.history.location.search);
    return queryParams.get("hd") !== "false";
  }

  getInitialQuarterHours(props) {
    const queryParams = new URLSearchParams(props.history.location.search);
    return queryParams.get("q") === "true";
  }

  onTimePeriodChanged(value) {
    let timeScale = value === "day" ? "hour" : "day";
    this.setState({ timePeriod: value, timeScale, expandedType: null });

    const params = new URLSearchParams(this.props.history.location.search);
    params.set("p", value);
    this.props.history.push({ 
      pathname: this.props.history.location.pathname.substr(0, this.props.history.location.pathname.length),
      search: params.toString()
    });
  }

  onDateChanged(date) {
    const newDate = date ? moment(date).startOf("day") : moment().startOf("day");
    this.setState({ date: newDate });

    const params = new URLSearchParams(this.props.history.location.search);
    params.set("d", toShortISOString(date));
    this.props.history.push({ 
      pathname: this.props.history.location.pathname.substr(0, this.props.history.location.pathname.length),
      search: params.toString()
    });
  }

  onWeekNumberChanged(value) {

    const earliestMoment = moment().startOf("month").subtract(6, "months");

    let newDate;
    if (value > moment().isoWeek()) {
      newDate = moment().subtract(1, "year").set("isoWeek", value).startOf("isoWeek");
    }
    else {
      newDate = moment().set("isoWeek", value).startOf("isoWeek");
    }

    if (newDate.isBefore(earliestMoment)) {
      newDate = earliestMoment;
    }

    this.setState({ date: newDate });

    const params = new URLSearchParams(this.props.history.location.search);
    params.set("d", toShortISOString(newDate));
    this.props.history.push({ 
      pathname: this.props.history.location.pathname.substr(0, this.props.history.location.pathname.length),
      search: params.toString()
    });
  }

  onMonthChanged(value) {

    let newDate;
    if (value > moment().month()) {
      newDate = moment().subtract(1, "year").set("month", value).startOf("month");
    }
    else {
      newDate = moment().set("month", value).startOf("month");
    }

    this.setState({ date: newDate });
    
    const params = new URLSearchParams(this.props.history.location.search);
    params.set("d", toShortISOString(newDate));
    this.props.history.push({ 
      pathname: this.props.history.location.pathname.substr(0, this.props.history.location.pathname.length),
      search: params.toString()
    });
  }

  onShowWholeDayChanged() {
    const params = new URLSearchParams(this.props.history.location.search);
    params.set("wd", !this.state.showWholeDay);
    this.props.history.push({ 
      pathname: this.props.history.location.pathname.substr(0, this.props.history.location.pathname.length),
      search: params.toString()
    });

    this.setState(prevState => ({ showWholeDay: !prevState.showWholeDay }));
  }

  onShowWeekendsChanged() {
    const params = new URLSearchParams(this.props.history.location.search);
    params.set("w", !this.state.showWeekends);
    this.props.history.push({ 
      pathname: this.props.history.location.pathname.substr(0, this.props.history.location.pathname.length),
      search: params.toString()
    });

    this.setState(prevState => ({ showWeekends: !prevState.showWeekends }));
  }

  onShowHolidaysChanged() {
    const params = new URLSearchParams(this.props.history.location.search);
    params.set("hd", !this.state.showHolidays);
    this.props.history.push({ 
      pathname: this.props.history.location.pathname.substr(0, this.props.history.location.pathname.length),
      search: params.toString()
    });

    this.setState(prevState => ({ showHolidays: !prevState.showHolidays }));
  }

  onShowQuarterHoursChanged() {
    const params = new URLSearchParams(this.props.history.location.search);
    params.set("q", !this.state.showQuarterHours);
    this.props.history.push({
      pathname: this.props.history.location.pathname.substr(0, this.props.history.location.pathname.length),
      search: params.toString()
    });
    
    this.setState(prevState => ({ showQuarterHours: !prevState.showQuarterHours }));
  }

  onExpandChanged(type) {
    this.setState({ expandedType: type });
  }

  render() {
    // const { isLoading } = this.props;
    // console.log("Home.Dashboard.render", this.state);

    // if (isLoading) {
    //   return null;
    // }

    const earliestMoment = moment().startOf("month").subtract(6, "months");

    // Calculate the last ~24 weeks
    let weeks = [];
    const earliestWeek = earliestMoment.isoWeek();
    const currentWeek = moment().isoWeek();
    if (currentWeek > earliestWeek) {
      weeks = [...Array(currentWeek + 1).keys()].slice(earliestWeek);
    }
    else {
      weeks = [...Array(currentWeek + 1).keys()].slice(1);
      const totaltWeeksLastYear = moment().subtract(currentWeek, "week").isoWeek();
      weeks = [...Array(totaltWeeksLastYear + 1).keys()].slice(earliestWeek).concat(weeks);
    }

    // Calculate the last ~6 months
    let months = [];
    const earliestMonth = earliestMoment.month();
    const currentMonth = moment().month();
    if (currentMonth > earliestMonth) {
      months = [...Array(currentMonth + 1).keys()].slice(earliestMonth);
    }
    else {
      months = [...Array(currentMonth + 1).keys()];
      months = [...Array(12).keys()].slice(earliestMonth).concat(months);
    }

    let datePicker;
    let weekPicker;
    let monthPicker;
    if (this.state.timePeriod === TimePeriod.Day) {
      datePicker = (
        <Datepicker
          date={this.state.date.toDate()}
          minDate={earliestMoment.toDate()}
          maxDate={new Date()}
          onChange={this.onDateChanged}
        />
      );
    }
    else if (this.state.timePeriod === TimePeriod.Week) {
      const weekNumber = this.state.date.isoWeek();
      weekPicker = (
        <Dropdown
          value={weekNumber}
          onChange={(option) => { this.onWeekNumberChanged(option.value)}}
          options={weeks.map(week => ({ label: week, value: week }))}
        />
      );
    }
    else if (this.state.timePeriod === TimePeriod.Month) {
      const month = this.state.date.month();
      monthPicker = (
        <Dropdown
          value={month}
          onChange={(option) => { this.onMonthChanged(option.value)}}
          options={months.map(month => ({ label: moment().set("month", month).format("MMMM"), value: month }))}
        />
      );
    }

    let options;
    if (datePicker) {
      options = (
        <div className={style.rightSelector}>
          <div style={{ width: "120px", marginBottom: "10px" }}>
            <CheckboxBlock key="workdayOption" label="Whole day" isChecked={this.state.showWholeDay} onClick={this.onShowWholeDayChanged} />
          </div>
          <div style={{ width: "142px", marginBottom: "10px" }}>
            <CheckboxBlock key="dayTimeScaleOption" label="Quarter hours" isChecked={this.state.showQuarterHours} onClick={this.onShowQuarterHoursChanged} />
          </div>
        </div>
      )
    }
    else {
      options = (
        <div className={style.rightSelector}>
          <div style={{ width: "120px", marginBottom: "10px" }}>
            <CheckboxBlock key="workdayOption" label="Whole day" isChecked={this.state.showWholeDay} onClick={this.onShowWholeDayChanged} />
          </div>
          <div style={{ width: "120px", marginBottom: "10px" }}>
            <CheckboxBlock key="weekendsOption" label="Weekends" isChecked={this.state.showWeekends} onClick={this.onShowWeekendsChanged} />
          </div>
          <div style={{ width: "120px", marginBottom: "10px" }}>
            <CheckboxBlock key="holidaysOption" label="Holidays" isChecked={this.state.showHolidays} onClick={this.onShowHolidaysChanged} />
          </div>
        </div>
      )
    }

    let links = [
      { label: "Dashboard", value: "dashboard" },
      { label: `Locations (${this.props.locations.length})`, value: "locations" }
    ];

    if (this.props.auth.hasSupportRole) {
      links.push({ label: "Min/max", value: "minmax" });
    }

    let viewControl = (
      <div className={style.centerSelector}>
        <div>
          <SegmentedControl  
            options={links}
            value="dashboard"
            onChange={this.props.onChangeView} 
          />
        </div>
      </div>
    );

    let dateControl = (
      <div className={style.leftSelector}>
        <SegmentedControl
          name="timeperiod"
          lightBg
          value={this.state.timePeriod}
          onChange={this.onTimePeriodChanged}
          options={[
            { label: "Day", value: TimePeriod.Day },
            { label: "Week", value: TimePeriod.Week, default: true },
            { label: "Month", value: TimePeriod.Month },
            { label: "Avg", value: "avg" }
          ]}
        />
        <div style={{ marginRight: "10px" }}>
          {datePicker}
          {weekPicker}
          {monthPicker}
        </div>
      </div>
    );

    let heading = (
      <div style={{ display: "flex", flexWrap: "wrap", rowGap: "10px" }}>
        {dateControl}
        {viewControl}
        {options}
      </div>
    );

    let sensorTypes = this.getSensorGraphIds();

    let graphs = [];
    if (this.state.expandedType) {
      sensorTypes = sensorTypes.filter(sensorType => sensorType === this.state.expandedType);
    }

    // Remove unwanted sensor types
    sensorTypes = sensorTypes.filter(sensorType => sensorType !== "cpuTemperature");

    // console.log("sensorTypes", sensorTypes);

    // Get core hours (working hours) from the first location that has a building
    let coreTime = { start: { hours: 8, minutes: 0 }, end: { hours: 16, minutes: 0 } };
    for (var i = 0; i < this.props.locations.length; i++) {
      const location = this.props.locations[i];
      if (location.type != "region") {
        // Get core hours from parent building in locationHierarchy
        const firstLocation = getLocation({ id: "*", children: this.props.locationHierarchy }, location._id);
        if (firstLocation.coreTime) { 
          coreTime = firstLocation.coreTime;
          break;
        }
      }
    }

    for (var i = 0; i < sensorTypes.length; i++) {
      const sensorType = sensorTypes[i];
      const hash = queryHash(sensorType, this.state, this.props);
      const dataKey = `${sensorType}-${hash}`;
      // console.log("dataKey", dataKey);

      const samples = this.props.data[dataKey] ?? [];
      const loadingStatus = this.props.dataLoadingStatus[dataKey] ?? { type: "loading" };
      // console.log("samples", samples);
      // console.log("loadingStatus", loadingStatus);
      let startDate;
      let endDate;
      let startTodayDate;
      let endTodayDate;
      if (this.state.timePeriod === TimePeriod.Day) {
        if (this.state.showWholeDay) {
          startDate = this.state.date.clone().startOf("day").toDate();
          endDate = this.state.date.clone().endOf("day").toDate();
        }
        else {
          startDate = this.state.date.clone().set("hours", coreTime.start.hours).startOf("hour").toDate();
          endDate = this.state.date.clone().set("hours", coreTime.end.hours - 1).endOf("hour").toDate();
        }
      }
      else if (this.state.timePeriod === TimePeriod.Week || this.state.timePeriod === "avg") {
        startDate = this.state.date.clone().startOf("isoWeek").toDate();
        endDate = this.state.date.clone().endOf("isoWeek").toDate();

        // Remove weekends
        if (!this.state.showWeekends) {
          endDate = moment(endDate).subtract(2, "day").toDate();
        }

        // Fix start and end of day
        if (this.state.showWholeDay) {
          startTodayDate = this.state.date.clone().startOf("day").toDate();
          endTodayDate = this.state.date.clone().endOf("day").toDate();
        }
        else {
          startTodayDate = this.state.date.clone().set("hours", coreTime.start.hours).startOf("hour").toDate();
          endTodayDate = this.state.date.clone().set("hours", coreTime.end.hours - 1).endOf("hour").toDate();
        }
      }
      else {
        startDate = this.state.date.clone().startOf("month").toDate();
        endDate = this.state.date.clone().endOf("month").toDate();
      }

      let stepSize;
      if (this.state.timePeriod === "day") {
        if (this.state.showQuarterHours) {
          stepSize = StepSize.Quarter;
        }
        else {
          stepSize = StepSize.Hour;
        }
      }
      else if (this.state.timePeriod === "week" || this.state.timePeriod === "month") {
        stepSize = StepSize.Day;
      }

      let graph;
      if (this.state.timePeriod === "avg") {
        if (["peopleDayOfWeek", "peopleDayOfWeekInBuildings", "peopleDayOfWeekInFloors", "peopleDayOfWeekInZones", "peopleDayOfWeekInRooms", "peopleDayOfWeekInMeetingRooms", "occupiedDayOfWeek"].includes(sensorType)) {
          graph = (
            <GraphContainer
              type={sensorType}
              samples={samples}
              isLoading={loadingStatus.type === "loading"}
              statusMessage={loadingStatus.type === "error" ? loadingStatus.message : undefined}
              graphType={GraphType.Bar}
              timePeriod={TimePeriod.WeekDays}
              startDate={startDate}
              endDate={endDate}
              enableExpand={true}
              expanded={this.state.expandedType === sensorType}
              onExpandedChanged={(expand) => { this.onExpandChanged(expand ? sensorType : null) }}
            />
          );
        }
        else if (["peopleCompareDayOfWeek", "occupiedCompareDayOfWeek"].includes(sensorType)) {
          graph = (
            <GraphContainer
              type={sensorType}
              samples={samples}
              isLoading={loadingStatus.type === "loading"}
              statusMessage={loadingStatus.type === "error" ? loadingStatus.message : undefined}
              graphType={GraphType.Bar}
              timePeriod={TimePeriod.WeekDays}
              startDate={startDate}
              endDate={endDate}
              enableExpand={true}
              expanded={this.state.expandedType === sensorType}
              onExpandedChanged={(expand) => { this.onExpandChanged(expand ? sensorType : null) }}
            />
          );
        }
        else if (["occupiedQuarterOfDay"].includes(sensorType)) {
          graph = (
            <GraphContainer
              type={sensorType}
              samples={samples}
              isLoading={loadingStatus.type === "loading"}
              statusMessage={loadingStatus.type === "error" ? loadingStatus.message : undefined}
              timePeriod={TimePeriod.QuarterOfDay}
              stepSize={StepSize.Quarter}
              startDate={startTodayDate}
              endDate={endTodayDate}
              enableExpand={true}
              expanded={this.state.expandedType === sensorType}
              onExpandedChanged={(expand) => { this.onExpandChanged(expand ? sensorType : null) }}
            />
          );
        }
      }
      else {
        graph = (
          <GraphContainer
            type={sensorType}
            samples={samples}
            isLoading={loadingStatus.type === "loading"}
            statusMessage={loadingStatus.type === "error" ? loadingStatus.message : undefined}
            timePeriod={this.state.timePeriod}
            stepSize={stepSize}
            startDate={startDate}
            endDate={endDate}
            enableExpand={true}
            expanded={this.state.expandedType === sensorType}
            onExpandedChanged={(expand) => { this.onExpandChanged(expand ? sensorType : null) }}
          />
        );
      }

      if (graph) {
        if (this.state.expandedType) {
          graphs.push(
            <Col key={sensorType + "-graph"} md={12}>
              {graph}
            </Col>
          );
        }
        else {
          graphs.push(
            <Col key={sensorType + "-graph"} md={6} xl={4} xxl={4}>
              {graph}
            </Col>
          );
        }
      }
    }

    let dashboard = (
      <Container fluid style={{ paddingLeft: "-10px", paddingRight: "-10px" }}>
        <Row> 
          { graphs }
        </Row>
      </Container>
    );
  
    return (
      <div className={style.listContainer}>
        <div className={style.scroll}>
          <div style={{ paddingTop: "40px" }} />
          {heading}
          <div style={{ paddingTop: "40px" }} />
          {dashboard}
          <div style={{ paddingTop: "40px" }} />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
    locations: state.filter.locations,
    customTags: state.customTags.list,
    isLoading: state.loading.locations,
    selectedLocations: state.selected.locations,
    data: state.dashboards.data,
    dataLoadingStatus: state.dashboards.dataLoadingStatus,
    filterSensorTypes: state.filter.sensorTypes,
    locationHierarchy: state.filter.hierarchy,
    allLocations: state.filter.flatHierarchy,
    queryHash: state.dashboards.queryHash,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    selectLocation: selectedActions.selectLocation,
    deselectLocation: selectedActions.deselectLocation,
    clearSelection: selectedActions.clearSelection,
    getDaySamples: dashboardActions.getDaySamples,
    getWeekSamples: dashboardActions.getWeekSamples,
    getMonthSamples: dashboardActions.getMonthSamples,
    storeDashboardQueryHash: dashboardActions.storeDashboardQueryHash,
   }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
