import LocalizedStrings from "react-localization";

export default new LocalizedStrings({
  en: {
    workplaceAnalysis: "Workplace analysis",
    showMore: "Show more...",
    showLess: "Show less...",
    top: "Top",
    bottom: "Bottom",
    help: "Help",
    viewHelp: "View help",
    options: {
      title: "Presentation options",
      bars: "Bars",
      lines: "Lines",
      workhours: "Work hours",
      allHours: "Whole day",
      split: "Every location",
      summed: "Summarized",
      percentage: "Percentage",
      count: "Count",
      average: "Average",
      averagePeak: "Average peak",
      peak: "Peak"
    },
    changeLanguage: "Change language",
    changeToBarGraph: "Change to bar graph",
    changeToLineGraph: "Change to line graph",
    changeToAverage: "Change to average data",
    changeToPeak: "Change to peak data",
    changeToWorkhours: "Change to work hours",
    changeToAllHours: "Change to whole day",
    changeToSplit: "Change to split data",
    changeToSummed: "Change to summed data",
    changeToPercentage: "Change to percentage",
    changeToNumber: "Change to number",
    unknown: "Unknown",
    noData: "No data",
    dataset: {
      title: "Dataset",
      timePeriod: "Time period",
      location: "Location",
      selectLocation: "Select location",
      workplaceCategory: "Workplace category",
      customTag: "Custom tag",
      undefined: "Select location first",
      allCategories: "All categories",
      allCustomTags: "<Only the selected location>",
      aggregationLevel: "Aggregation level",
      summed: "Summed",
      notSummed: "Not summed",
      required: "Required",
      newDataset: "Add a dataset",
    },
    explaination: "Explaination",
    utilization: {
      title: "Utilization degree",
      introduction: [
        "The utilization degree is an indication on how efficiently resources in the workplace are used; such as workplaces, support rooms and meeting rooms. Based on measurements of presence, BLDNG can measure when and how often each resource is in use. This provides insight into the number of resources that are in use at a given time."
      ],
      pages: [
        {
          id: "intraday",
          name: "Time of day"
        },
        {
          id: "day",
          name: "Days"
        },
        {
          id: "weekday",
          name: "Weekdays"
        },
        {
          id: "week",
          name: "Weeks"
        },
        {
          id: "month",
          name: "Months"
        }
      ],
      intraday: {
        title: "Utilization degree per hour of day",
        explaination: {
          avgAvg: "the average utilization degree achieved each hour",
          avgPeak: "the average of the highest utilization degree achieved each hour",
          peakPeak: "the highest utilization degree achieved at the busiest time, on the busiest day"
        },
        helpExample:  [
          "This bar chart shows how much the resources (workplaces/meeting rooms etc.) have been used each hour. The bars are stacked with different shades to indicate different metrics - light color for peak, medium color for average peak and dark color for average.",
          "For instance, the chart below shows that workplaces are used from 06:00, and that on average there is about 30% utilization of workplaces before 09:00. Thus, we see that there is good availability of workplaces in the morning.",
          "ex1"
        ]  
      },
      day: {
        title: "Utilization degree per day",
        explaination: {
          avgAvg: "the average utilization degree achieved each day",
          avgPeak: "the average of the highest utilization degree achieved each day",
          peakPeak: "the highest utilization degree achieved at the busiest time, on the busiest day"
        },
        helpExample: [
          "This bar chart shows how much the resources (workplaces/meeting rooms etc.) have been used each day. The bars are stacked with different shades to indicate different metrics - light color for peak, medium color for average peak and dark color for average.",
          "For instance, the chart below shows that workplaces had an average utilization degree (dark red) of 47% on October 7. The peak utilization degree (light red) was 80% on the same day.",
          "ex1"
        ]
      },
      weekday: {
        title: "Utilization degree per weekday",
        explaination: {
          avgAvg: "the average utilization degree achieved each weekday",
          avgPeak: "the average of the highest utilization degree achieved each weekday",
          peakPeak: "the highest utilization degree achieved at the busiest time, on the busiest day"
        },
        helpExample: [
          "This bar chart shows how much the resources (workplaces/meeting rooms etc.) have been used each weekday. The bars are stacked with different shades to indicate different metrics - light color for peak, medium color for average peak and dark color for average.",
          "For instance, the chart below shows that workplaces had an average utilization degree of 45% on Mondays.",
          "ex1"
        ]
      },
      week: {
        title: "Utilization degree per week",
        explaination: {
          avgAvg: "the average utilization degree achieved each week",
          avgPeak: "the average of the highest utilization degree achieved each week",
          peakPeak: "the highest utilization degree achieved at the busiest time, on the busiest day"
        },
        helpExample: [
          "This bar chart shows how much the resources (workplaces/meeting rooms etc.) have been used each week. The bars are stacked with different shades to indicate different metrics - light color for peak, medium color for average peak and dark color for average.",
          "For instance, the chart below shows that workplaces had an average utilization degree of 67% in week 6.",
          "ex1"
        ]
      },
      month: {
        title: "Utilization degree per month",
        explaination: {
          avgAvg: "the average utilization degree achieved each month",
          avgPeak: "the average of the highest utilization degree achieved each month",
          peakPeak: "the highest utilization degree achieved at the busiest time, on the busiest day"
        },
        helpExample: [
          "This bar chart shows how much the resources (workplaces/meeting rooms etc.) have been used each month. The bars are stacked with different shades to indicate different metrics - light color for peak, medium color for average peak and dark color for average.",
          "For instance, the chart below shows that workplaces had an average utilization degree of 47% in January.",
          "ex1"
        ]
      }
    },
    peopleCount: {
      title: "People count",
      introduction: [
        "Counting the number of people in an area provides insight into density and flow of people. With the use of people counting sensors, BLDNG can count the number of people in each work area at any given time. This is important for understanding usage patterns and identifying areas that have changed needs for resources or attention."
      ],
      capacityExplaination: "People capacity.",
      graphTitle: "People count in {0} ({1} - {2})",
      pages: [
        {
          id: "intraday",
          name: "Time of day"
        },
        {
          id: "day",
          name: "Days"
        },
        {
          id: "weekday",
          name: "Weekdays"
        },
        {
          id: "week",
          name: "Weeks"
        },
        {
          id: "month",
          name: "Months"
        },
        {
          id: "yearOverYear",
          name: "Year over year"
        }
      ],
      intraday: {
        title: "People count per hour of day",
        explaination: {
          avgAvg: "the average people count achieved each hour",
          avgPeak: "the average of the highest people count achieved each hour",
          peakPeak: "the highest people count achieved at the busiest time, on the busiest day"
        },
        helpExample: [
          "This bar chart shows the number of people in an area each hour. The bars are stacked with different shades to indicate different metrics - light color for peak, medium color for average peak and dark color for average.",
          "For instance, the chart below shows an average peak of 16 people from 12:00 to 15:00, and an average of 15 people from 13:00 to 14:00. This suggest a consistent pattern during this hour. In contrast, we can see that the difference is larger between 11:00 and 12:00, which tells us there is more variability at this hour.",
          "ex1",
        ],
      },
      day: {
        title: "People count per day",
        explaination: {
          avgAvg: "the average people count achieved each day",
          avgPeak: "the average of the highest people count achieved each day",
          peakPeak: "the highest people count achieved at the busiest time, on the busiest day"
        },
        helpExample: [
          "This bar chart shows the number of people in an area each day. The bars are stacked with different shades to indicate different metrics - light color for peak, medium color for average peak and dark color for average.",
          "For instance, the chart below shows an average of 24 and a peak of 90 people on October 7. We can also observe that we never reach the capacity for the area.",
          "ex1",
        ],
      },
      weekday: {
        title: "People count per weekday",
        explaination: {
          avgAvg: "the average people count per weekday",
          avgPeak: "the average of the highest people count per weekday",
          peakPeak: "the highest people count achieved at the busiest time, on the busiest day"
        },
        helpExample: [
          "This bar chart shows the number of people in an area each weekday. The bars are stacked with different shades to indicate different metrics - light color for peak, medium color for average peak and dark color for average.",
          "For instance, the chart below shows an average peak of 25 people on Wednesday and Thursday. We can also observe that the locations typically have 5 people on Fridays.",
          "ex1",
        ],
      },
      week: {
        title: "People count per week",
        explaination: {
          avgAvg: "the average people count achieved each week",
          avgPeak: "the average of the highest people count achieved each week",
          peakPeak: "the highest people count achieved at the busiest time, on the busiest day"
        },
        helpExample: [
          "This bar chart shows the number of people in an area each week. The bars are stacked with different shades to indicate different metrics - light color for peak, medium color for average peak and dark color for average.",
          "For instance, the chart below shows an average peak of 40 people in week 9. We can also observe that the locations have an average that fluctuates between 9 and 25 people.",
          "ex1",
        ],
      },
      month: {
        title: "People count per month",
        explaination: {
          avgAvg: "the average people count achieved each month",
          avgPeak: "the average of the highest people count achieved each month",
          peakPeak: "the highest people count achieved at the busiest time, on the busiest day"
        },
        helpExample: [
          "This bar chart shows the number of people in an area each month. The bars are stacked with different shades to indicate different metrics - light color for peak, medium color for average peak and dark color for average.",
          "For instance, the chart below shows an average peak of 24 people in February. We can also observe that the locations have an average that fluctuates between 9 and 15 people.",
          "ex1",
        ],
      },
      yearOverYear: {
        title: "People count year over year",
        explaination: {
          avgAvg: "the average people count achieved each month",
          avgPeak: "the average of the highest people count achieved each month",
          peakPeak: "the highest people count achieved at the busiest time, on the busiest day"
        },
        helpExample: [
          "This line chart shows the number of people in an area each month. Each year in the dataset has it's own color to make it easier to compare values across years. Each metric has different shades within each year - light color for peak, medium color for average peak and dark color for average.",
          "For instance, the chart below shows how the number of people varies from February 2023 (blue line) to April 2024 (red line). The blue line shows a dip during the summer break in July and a peak in September to November. Then, in February 2024, the number of people reached an all-time high of 24.",
          "ex1"
        ],
      }
    },
    occupancyDegree: {
      title: "Coverage ratio",
    }
  },
  nb: {
    workplaceAnalysis: "Arbeidsplassanalyse",
    showMore: "Vis mer...",
    showLess: "Vis mindre...",
    top: "Topp",
    bottom: "Bunn",
    help: "Hjelp",
    viewHelp: "Se hjelp",
    options: {
      title: "Presentasjonsalternativer",
      bars: "Stolper",
      lines: "Linjer",
      workhours: "Arbeidstid",
      allHours: "Hele dagen",
      split: "Hver lokasjon",
      summed: "Oppsummert",
      percentage: "Prosent",
      count: "Antall",
      average: "Gjennomsnitt",
      averagePeak: "Gjennomsnittlig maks",
      peak: "Maks"
    },
    changeLanguage: "Endre språk",
    changeToBarGraph: "Endre til stolpediagram",
    changeToLineGraph: "Endre til linjediagram",
    changeToAverage: "Endre til gjennomsnittsdata",
    changeToPeak: "Endre til toppdata",
    changeToWorkhours: "Endre til arbeidstid",
    changeToAllHours: "Endre til hele dagen",
    changeToSplit: "Endre til oppdelt data",
    changeToSummed: "Endre til summert data",
    changeToPercentage: "Endre til prosent",
    changeToNumber: "Endre til antall",
    unknown: "Ukjent",
    noData: "Ingen data",
    dataset: {
      title: "Datasett",
      timePeriod: "Tidsperiode",
      location: "Lokasjon",
      selectLocation: "Velg lokasjon",
      workplaceCategory: "Arbeidsplasskategori",
      customTag: "Egendefinert tag",
      undefined: "Velg lokasjon først",
      allCategories: "Alle kategorier",
      allCustomTags: "<Bare den valgte lokasjon>",
      aggregationLevel: "Aggregeringsnivå",
      summed: "Summert",
      notSummed: "Ikke summert",
      required: "Obligatorisk",
      newDataset: "Legg til et datasett",
    },
    explaination: "Forklaring",
    utilization: {
      title: "Utnyttelsesgrad",
      introduction: [
        "Utnyttelsesgrad gir en indikasjon på hvor effektivt ressurser på arbeidsplassen blir brukt; slik som arbeidsplasser, støtterom og møterom. Basert på målinger på tilstedeværelse kan BLDNG måle når og hvor ofte hver enkelt ressurs er i bruk. Dette gir innsikt i antallet ressurser som er i bruk på et gitt tidspunkt."
      ],
      pages: [
        {
          id: "intraday",
          name: "Tid på dagen"
        },
        {
          id: "day",
          name: "Dager"
        },
        {
          id: "weekday",
          name: "Ukedager"
        },
        {
          id: "week",
          name: "Uker"
        },
        {
          id: "month",
          name: "Måneder"
        }
      ],
      intraday: {
        title: "Utnyttelsesgrad per time",
        explaination: {
          avgAvg: "den gjennomsnittlige utnyttelsesgraden oppnådd hver time",
          avgPeak: "snittet av den høyeste utnyttelsesgraden oppnådd hver time",
          peakPeak: "den høyeste utnyttelsesgraden oppnådd på det travleste tidspunktet, på den travleste dagen"
        },
        helpExample: [
          "Dette stolpediagrammet viser hvor mye ressursene (arbeidsplasser/møterom e.l.) har blitt brukt hver time. Stolpene er gruppert sammen med ulikt fargede nyanser for å indikere ulike metrikker - lys farge for maks, middels farge for gjennomsnittlig maks og mørk farge for gjennomsnitt.",
          "Fra eksempelgrafen nedenfor leser vi at arbeidsplassene brukes fra klokken 06:00, og at det i snitt (mørk farge) er cirka 30% utnyttelse av arbeidsplasser innen klokken 9. Dermed ser vi at det er god tilgjengelighet på arbeidsplasser på morgenen.",
          "ex1"
        ]
      },
      day: {
        title: "Utnyttelsesgrad per dag",
        explaination: {
          avgAvg: "den gjennomsnittlige utnyttelsesgraden oppnådd hver dag",
          avgPeak: "snittet av den høyeste utnyttelsesgraden oppnådd hver dag",
          peakPeak: "den høyeste utnyttelsesgraden oppnådd på det travleste tidspunktet, på den travleste dagen"
        },
        helpExample: [
          "Dette stolpediagrammet viser hvor mye ressursene (arbeidsplasser/møterom e.l.) har blitt brukt hver dag. Stolpene er gruppert sammen med ulikt fargede nyanser for å indikere ulike metrikker - lys farge for maks, middels farge for gjennomsnittlig maks og mørk farge for gjennomsnitt.",
          "Fra eksempelgrafen nedenfor leser vi at det var i snitt (mørk farge) er cirka 47% utnyttelse av arbeidsplasser 7. oktober. Den høyeste utnyttelsesgraden (lys farge) var 80% samme dag.",
          "ex1"
        ]
      },
      weekday: {
        title: "Utnyttelsesgrad per ukedag",
        explaination: {
          avgAvg: "den gjennomsnittlige utnyttelsesgraden oppnådd hver ukedag",
          avgPeak: "snittet av den høyeste utnyttelsesgraden oppnådd hver ukedag",
          peakPeak: "den høyeste utnyttelsesgraden oppnådd på det travleste tidspunktet, på den travleste dagen"
        },
        helpExample: [
          "Dette stolpediagrammet viser hvor mye ressursene (arbeidsplasser/møterom e.l.) har blitt brukt hver ukedag. Stolpene er gruppert sammen med ulikt fargede nyanser for å indikere ulike metrikker - lys farge for maks, middels farge for gjennomsnittlig maks og mørk farge for gjennomsnitt.",
          "Fra eksempelgrafen nedenfor leser vi at det i snitt (mørk farge) er cirka 45% utnyttelse av arbeidsplasser på mandager.",
          "ex1",
        ]
      },
      week: {
        title: "Utnyttelsesgrad per uke",
        explaination: {
          avgAvg: "den gjennomsnittlige utnyttelsesgraden oppnådd hver uke",
          avgPeak: "snittet av den høyeste utnyttelsesgraden oppnådd hver uke",
          peakPeak: "den høyeste utnyttelsesgraden oppnådd på det travleste tidspunktet, på den travleste dagen"
        },
        helpExample: [
          "Dette stolpediagrammet viser hvor mye ressursene (arbeidsplasser/møterom e.l.) har blitt brukt hver uke. Stolpene er gruppert sammen med ulikt fargede nyanser for å indikere ulike metrikker - lys farge for maks, middels farge for gjennomsnittlig maks og mørk farge for gjennomsnitt.",
          "Fra eksempelgrafen nedenfor leser vi at det var i snitt (mørk farge) er cirka 67% utnyttelse av arbeidsplasser i uke 6.",
          "ex1"
        ]
      },
      month: {
        title: "Utnyttelsesgrad per måned",
        explaination: {
          avgAvg: "den gjennomsnittlige utnyttelsesgraden oppnådd hver måned",
          avgPeak: "snittet av den høyeste utnyttelsesgraden oppnådd hver måned",
          peakPeak: "den høyeste utnyttelsesgraden oppnådd på det travleste tidspunktet, på den travleste dagen"
        },
        helpExample: [
          "Dette stolpediagrammet viser hvor mye ressursene (arbeidsplasser/møterom e.l.) har blitt brukt hver måned. Stolpene er gruppert sammen med ulikt fargede nyanser for å indikere ulike metrikker - lys farge for maks, middels farge for gjennomsnittlig maks og mørk farge for gjennomsnitt.",
          "Fra eksempelgrafen nedenfor leser vi at det var i snitt (mørk farge) er cirka 47% utnyttelse av arbeidsplasser i januar.",
          "ex1",
        ]
      }
    },
    peopleCount: {
      title: "Mennesketelling",
      introduction: [
        "Å telle antall mennesker i et område gir innsikt i tetthet og folkestrøm. Med bruk av mennesketellingssensorer kan BLDNG telle antall mennesker som oppholder seg i hvert enkelt arbeidsområde på et hvert tidspunkt. Dette er viktig for å forstå bruksmønstre og identifisere områder som har endret behov for ressurser eller oppmerksomhet."
      ],
      capacityExplaination: "Menneskekapasitet.",
      graphTitle: "Mennesketelling i {0} ({1} - {2})",
      pages: [
        {
          id: "intraday",
          name: "Tid på dagen"
        },
        {
          id: "day",
          name: "Dager"
        },
        {
          id: "weekday",
          name: "Ukedager"
        },
        {
          id: "week",
          name: "Uker"
        },
        {
          id: "month",
          name: "Måneder"
        },
        {
          id: "yearOverYear",
          name: "År for år"
        }
      ],
      intraday: {
        title: "Mennesketelling per time",
        explaination: {
          avgAvg: "den gjennomsnittlige mennesketellingen oppnådd hver time",
          avgPeak: "snittet av den høyeste mennesketellingen oppnådd hver time",
          peakPeak: "den høyeste mennesketellingen oppnådd på det travleste tidspunktet, på den travleste dagen"
        },
        helpExample: [
          "Dette stolpediagrammet viser antall mennesker i et område hver time. Stolpene er gruppert sammen med ulikt fargede nyanser for å indikere ulike metrikker - lys farge for maks, middels farge for gjennomsnittlig maks og mørk farge for gjennomsnitt.",
          "Fra eksempelgrafen nedenfor leser vi at det i snitt er 16 personer fra klokken 12:00 til 15:00, og at det i snitt er 15 personer fra klokken 13:00 til 14:00. Dette tyder på et jevnt mønster i denne timen. I motsetning til dette kan vi se at forskjellen er større mellom klokken 11 og 12, noe som forteller oss at det er mer variasjon på dette tidspunktet.",
          "ex1",
        ]
      },
      day: {
        title: "Mennesketelling per dag",
        explaination: {
          avgAvg: "den gjennomsnittlige mennesketellingen oppnådd hver dag",
          avgPeak: "snittet av den høyeste mennesketellingen oppnådd hver dag",
          peakPeak: "den høyeste mennesketellingen oppnådd på det travleste tidspunktet, på den travleste dagen"
        },
        helpExample: [
          "Dette stolpediagrammet viser antall mennesker i et område hver dag. Stolpene er gruppert sammen med ulikt fargede nyanser for å indikere ulike metrikker - lys farge for maks, middels farge for gjennomsnittlig maks og mørk farge for gjennomsnitt.",
          "Fra eksempelgrafen nedenfor leser vi at det i snitt var 24 personer og et maksimum på 90 personer 7. oktober. Vi kan også observere at vi aldri når kapasiteten for områdene.",
          "ex1",
        ],
      },
      weekday: {
        title: "Mennesketelling per ukedag",
        explaination: {
          avgAvg: "den gjennomsnittlige mennesketellingen oppnådd hver ukedag",
          avgPeak: "snittet av den høyeste mennesketellingen oppnådd hver ukedag",
          peakPeak: "den høyeste mennesketellingen oppnådd på det travleste tidspunktet, på den travleste dagen"
        },
        helpExample: [
          "Dette stolpediagrammet viser antall mennesker i et område hver ukedag. Stolpene er gruppert sammen med ulikt fargede nyanser for å indikere ulike metrikker - lys farge for maks, middels farge for gjennomsnittlig maks og mørk farge for gjennomsnitt.",
          "Fra eksempelgrafen nedenfor leser vi at det i snitt er 25 personer på onsdag og torsdag. Vi kan også observere at det typisk er 5 personer på fredager.",
          "ex1",
        ]
      },
      week: {
        title: "Mennesketelling per uke",
        explaination: {
          avgAvg: "den gjennomsnittlige mennesketellingen oppnådd hver uke",
          avgPeak: "snittet av den høyeste mennesketellingen oppnådd hver uke",
          peakPeak: "den høyeste mennesketellingen oppnådd på det travleste tidspunktet, på den travleste dagen"
        },
        helpExample: [
          "Dette stolpediagrammet viser antall mennesker i et område hver uke. Stolpene er gruppert sammen med ulikt fargede nyanser for å indikere ulike metrikker - lys farge for maks, middels farge for gjennomsnittlig maks og mørk farge for gjennomsnitt.",
          "Fra eksempelgrafen nedenfor leser vi at det i snitt var 40 personer i uke 9. Vi kan også observere at områdene har et gjennomsnitt som varierer mellom 9 og 25 personer.",
          "ex1",
        ]
      },
      month: {
        title: "Mennesketelling per måned",
        explaination: {
          avgAvg: "den gjennomsnittlige mennesketellingen oppnådd hver måned",
          avgPeak: "snittet av den høyeste mennesketellingen oppnådd hver måned",
          peakPeak: "den høyeste mennesketellingen oppnådd på det travleste tidspunktet, på den travleste dagen"
        },
        helpExample: [
          "Dette stolpediagrammet viser antall mennesker i et område hver måned. Stolpene er gruppert sammen med ulikt fargede nyanser for å indikere ulike metrikker - lys farge for maks, middels farge for gjennomsnittlig maks og mørk farge for gjennomsnitt.",
          "Fra eksempelgrafen nedenfor leser vi at det i snitt var 24 personer i februar. Vi kan også observere at områdene har et gjennomsnitt som varierer mellom 9 og 15 personer.",
          "ex1",
        ]
      },
      yearOverYear: {
        title: "Mennesketelling år for år",
        explaination: {
          avgAvg: "den gjennomsnittlige mennesketellingen oppnådd hver måned",
          avgPeak: "snittet av den høyeste mennesketellingen oppnådd hver måned",
          peakPeak: "den høyeste mennesketellingen oppnådd på det travleste tidspunktet, på den travleste dagen"
        },
        helpExample: [
          "Dette linjediagrammet viser antall mennesker i et område hver måned. Hvert år i datasettet har sin egen farge for å gjøre det enklere å sammenligne verdier på tvers av år. Hver metrikk har ulike nyanser innenfor hvert år - lys farge for maks, middels farge for gjennomsnittlig maks og mørk farge for gjennomsnitt.",
          "Fra eksempelgrafen nedenfor leser vi hvordan antall mennesker varierer fra februar 2023 (blå linje) til april 2024 (rød linje). Den blå linjen viser en nedgang i sommerferien i juli og en topp i september til november. Deretter, i februar 2024, nådde antall mennesker et all-time high på 24.",
          "ex1"
        ],
      }
    },
    occupancyDegree: {
      title: "Dekningsgrad",
    }
  }
});
