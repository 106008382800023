import React, { Component } from "react";
import { Container, Col, Row } from "react-grid-system";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { get, isEmpty } from "lodash";
import Freetext from "../../components/Freetext";
import OptionFooter from "../../components/OptionFooter";
import Button from "../../components/Button";
import Loader from "../../components/Loader";
import InputGroup from "../../components/InputGroup";
import Checkbox from "../../components/Checkbox";
import SegmentedControl from "../../components/SegmentedControl";
import InputBox from "../../components/InputBox";
import * as displayActions from "../../actions/display";
import * as locationActions from "../../actions/locations";
import style from "./style.module.scss";
import SmallButton from "../../components/SmallButton";
import InputPointMap from "../Map/InputPointMap";

class Display extends Component {

  constructor(props) {
    super(props);
    this.state = {
      id: props.match.params.id,
      mode: props.match.params.mode,
      copyText: "Copy URL",
      customBearing: 0,
      showCustomBearing: false,
      hasLoadedState: false
    };

    this.onBackClick = this.onBackClick.bind(this);
    this.onNameChange = this.onNameChange.bind(this);
    this.onShowTitleChanged = this.onShowTitleChanged.bind(this);
    this.onShowLocationMenuChanged = this.onShowLocationMenuChanged.bind(this);
    this.onShowFloorMenuChanged = this.onShowFloorMenuChanged.bind(this);
    this.onShowSBLabelsChanged = this.onShowSBLabelsChanged.bind(this);
    this.onAllowInteractionsChanged = this.onAllowInteractionsChanged.bind(this);
    this.onShowCompassChanged = this.onShowCompassChanged.bind(this);
    this.onShowVacantChanged = this.onShowVacantChanged.bind(this);
    this.onShowOccupiedChanged = this.onShowOccupiedChanged.bind(this);
    this.onInitialBearingChanged = this.onInitialBearingChanged.bind(this);
    this.onCustomBearingChanged = this.onCustomBearingChanged.bind(this);
    this.onYourLocationLatitudeChanged= this.onYourLocationLatitudeChanged.bind(this);
    this.onYourLocationLongitudeChanged = this.onYourLocationLongitudeChanged.bind(this);
    this.onYourLocationPointChanged = this.onYourLocationPointChanged.bind(this);
    this.onYourLocationColorChanged = this.onYourLocationColorChanged.bind(this);
    this.onStatusFilterChanged = this.onStatusFilterChanged.bind(this);
    this.onLabelFilterChanged = this.onLabelFilterChanged.bind(this);
    this.onIconFilterChanged = this.onIconFilterChanged.bind(this);
    this.onSave = this.onSave.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.onDelete = this.onDelete.bind(this);
    this.onViewClick = this.onViewClick.bind(this);
    this.onChangeTypeClick = this.onChangeTypeClick.bind(this);
    this.onViewLocationClick = this.onViewLocationClick.bind(this);
    this.onChangeLocationClick = this.onChangeLocationClick.bind(this);
    this.onChangeReportClick = this.onChangeReportClick.bind(this);
    this.mapConfigurationElement = this.mapConfigurationElement.bind(this);
    this.reportConfigurationElement = this.reportConfigurationElement.bind(this);
    this.dashboardConfigurationElement = this.dashboardConfigurationElement.bind(this);

    this.onChangeDashboardClick = this.onChangeDashboardClick.bind(this);


    if (this.state.mode === "edit" && props.match.params.id !== undefined && props.display._id !== props.match.params.id) {
      this.props.getDisplay(props.match.params.id);
    }
    // else if (this.state.mode === "new" && isEmpty(this.props.form)) {
    //   this.props.resetDisplayForm();
    // }
  }

  static getDerivedStateFromProps(nextProps, prevState) {

    if (prevState.hasLoadedState) {
      return null;
    }

    // Only copy props if they exist and if display id is correct
    if (!isEmpty(nextProps.display) && !isEmpty(nextProps.display._id) && nextProps.match.params.id === nextProps.display._id) {

      const floorId = get(nextProps.display, "map.floorId", null);
      if (floorId) {

        // Fetch floor map
        nextProps.getFloorMap(floorId);

        const initialBearing = get(nextProps.form, "config.initialBearing", 0);

        const newState = {
          hasLoadedState: true,
          showCustomBearing: ![0, 90, 180, 270].includes(initialBearing),
          customBearing: initialBearing
        };

        return newState;
      }
      else {

        const newState = {
          hasLoadedState: true
        };
        
        return newState;
      }
      
    }
    else if (prevState.mode === "new" && get(nextProps, "form.floorId", null)) {
      
      // Fetch floor map
      nextProps.getFloorMap(nextProps.form.floorId);

      const newState = {
        hasLoadedState: true
      };
      
      return newState;
    }

    return null;
  }

  onBackClick() {
    this.props.resetDisplayForm();
    this.props.history.push(`/companies/${this.props.match.params.companyId}/displays`);
  }

  onChangeTypeClick() {
    this.props.history.push(`/companies/${this.props.match.params.companyId}/config-display/${this.state.mode}/type?code=${this.state.id}`);
  }

  onViewLocationClick() {
    this.props.history.push(`/companies/${this.props.match.params.companyId}/locations/${this.props.form.floorId}/locations`);
  }

  onChangeLocationClick() {
    this.props.history.push(`/companies/${this.props.match.params.companyId}/config-display/${this.state.mode}/locations/root?code=${this.state.id}`);
  }

  onChangeReportClick() {
    this.props.history.push(`/companies/${this.props.match.params.companyId}/config-display/${this.state.mode}/reports?code=${this.state.id}`);
  }

  onChangeDashboardClick() {
    this.props.history.push(`/companies/${this.props.match.params.companyId}/config-display/${this.state.mode}/insights?code=${this.state.id}`);
  }

  onNameChange(event) {
    const form = { ...this.props.form };
    form.name = event.target.value;
    this.props.updateDisplayForm(form);
  }

  onShowTitleChanged() {
    const form = { ...this.props.form };
    form.config.showTitle = !get(form, "config.showTitle", false);
    this.props.updateDisplayForm(form);
  }

  onShowLocationMenuChanged() {
    const form = { ...this.props.form };
    form.config.showLocationMenu = !get(form, "config.showLocationMenu", true);
    this.props.updateDisplayForm(form);
  }

  onShowFloorMenuChanged() {
    const form = { ...this.props.form };
    form.config.showFloorMenu = !get(form, "config.showFloorMenu", true);
    this.props.updateDisplayForm(form);
  }

  onShowSBLabelsChanged() {
    const form = { ...this.props.form };
    form.config.showSBLabels = !get(form, "config.showSBLabels", false);
    this.props.updateDisplayForm(form);
  }

  onAllowInteractionsChanged() {
    const form = { ...this.props.form };
    form.config.allowInteractions = !get(form, "config.allowInteractions", false);
    this.props.updateDisplayForm(form);
  }

  onShowCompassChanged() {
    const form = { ...this.props.form };
    form.config.showCompass = !get(form, "config.showCompass", false);
    this.props.updateDisplayForm(form);
  }

  onShowVacantChanged() {
    const form = { ...this.props.form };
    form.config.showVacant = !get(form, "config.showVacant", true);
    this.props.updateDisplayForm(form);
  }

  onShowOccupiedChanged() {
    const form = { ...this.props.form };
    form.config.showOccupied = !get(form, "config.showOccupied", true);
    this.props.updateDisplayForm(form);
  }

  onInitialBearingChanged(value) {
    const form = { ...this.props.form };

    if (value === "custom") {
      form.config.initialBearing = this.state.customBearing;
    }
    else {
      form.config.initialBearing = value;
    }

    this.props.updateDisplayForm(form);
    this.setState({ showCustomBearing: (value === "custom") });
  }

  onCustomBearingChanged(event) {
    const form = { ...this.props.form };
    form.config.initialBearing = event.target.value;
    this.props.updateDisplayForm(form);
    this.setState({ customBearing: event.target.value });
  }

  onYourLocationLatitudeChanged(event) {
    const form = { ...this.props.form };

    if (!form.config.yourLocation) {
      form.config.yourLocation = {};
    }

    form.config.yourLocation.lat = event.target.value;
    this.props.updateDisplayForm(form);
  }

  onYourLocationLongitudeChanged(event) {
    const form = { ...this.props.form };
    
    if (!form.config.yourLocation) {
      form.config.yourLocation = {};
    }

    form.config.yourLocation.lng = event.target.value;
    this.props.updateDisplayForm(form);
  }

  onYourLocationPointChanged(point) {
    const lat = get(point, "geometry.coordinates[1]", 0);
    const lng = get(point, "geometry.coordinates[0]", 0);

    if (lat && lng) {
      const form = { ...this.props.form };
    
      if (!form.config.yourLocation) {
        form.config.yourLocation = {};
      }

      form.config.yourLocation.lat = lat;
      form.config.yourLocation.lng = lng;
      this.props.updateDisplayForm(form);
    }
  }

  onYourLocationColorChanged(event) {
    const form = { ...this.props.form };
    
    if (!form.config.yourLocation) {
      form.config.yourLocation = {};
    }
    
    form.config.yourLocation.color = event.target.value;
    this.props.updateDisplayForm(form);
  }

  onStatusFilterChanged(event) {
    const form = { ...this.props.form };
    form.config.statusFilter = event.target.value;
    this.props.updateDisplayForm(form);
  }

  onLabelFilterChanged(event) {
    const form = { ...this.props.form };
    form.config.labelFilter = event.target.value;
    this.props.updateDisplayForm(form);
  }

  onIconFilterChanged(event) {
    const form = { ...this.props.form };
    form.config.iconFilter = event.target.value;
    this.props.updateDisplayForm(form);
  }

  onSave() {
    const { push } = this.props.history;

    const { form } = this.props;
    if (!isEmpty(form.floorId)) {
      form.config.floorLocked = true;
    }

    if (this.state.mode === "new") {
      this.props.createDisplay(this.state.id, form, push);
    }
    else {
      this.props.updateDisplay(this.state.id, form, push);
    }
  }
  
  onCancel() {
    if (this.state.mode === "new") {
      this.props.resetDisplayForm();
      this.props.history.push(`/companies/${this.props.match.params.companyId}/displays`);
    }
    else {
      this.props.resetDisplayForm();
    }
  }

  onDelete() {
    const { push } = this.props.history;
    this.props.deleteDisplay(this.state.id, push);
  }

  onViewClick() {
    const url = get(this.props.display, "url", null);
    if (url) {
      window.open(url, '_blank');
    }
  }

  mapConfigurationElement() {

    const canEdit = this.props.auth.hasAdminRole;
    
    // Get yourLocation to render in map
    var yourLocationFeature = null;
    const yourLocationLat = get(this.props.form, "config.yourLocation.lat", 0);
    const yourLocationLng = get(this.props.form, "config.yourLocation.lng", 0);
    if (yourLocationLat && yourLocationLng) {
      yourLocationFeature = { 
        type: "Feature",
        geometry: { 
          type: "Point", 
          coordinates: [yourLocationLng, yourLocationLat] 
        }
      };
    }

    return (
      <>
        <div style={{ paddingTop: "40px" }} />
        <Freetext header="Type" />
        <Row>
          <Col sm={12} md={3} lg={3}>
            <SmallButton text="Change type" color="white" onClick={this.onChangeTypeClick} disabled={!canEdit} />
          </Col>
        </Row>
        <div style={{ paddingTop: "40px" }} />
        <Freetext header="Initial floor location" />
        <Row>
          <Col>
            <SmallButton text="View location" color="white" onClick={this.onViewLocationClick} disabled={!canEdit} />
            <SmallButton text="Change location" color="white" onClick={this.onChangeLocationClick} disabled={!canEdit} />
          </Col>
        </Row>
        <div style={{ paddingTop: "40px" }} />
        <Freetext header="Configuration" content="Here you can configure presentation details for the map. NB! Any change can take up to one hour to take effect." />
        <div style={{ paddingTop: "20px" }} />
        <Row>
          <Col>
            <Checkbox label="Show name as a title on map" isChecked={get(this.props.form, "config.showTitle", false)} onClick={this.onShowTitleChanged} disabled={!canEdit} />
          </Col>
        </Row>
        <div style={{ paddingTop: "20px" }} />
        <Row>
          <Col>
            <Checkbox label="Show location navigation" isChecked={get(this.props.form, "config.showLocationMenu", true)} onClick={this.onShowLocationMenuChanged} disabled={!canEdit} />
          </Col>
        </Row>
        <div style={{ paddingTop: "20px" }} />
        <Row>
          <Col>
            <Checkbox label="Show floor navigation" isChecked={get(this.props.form, "config.showFloorMenu", true)} onClick={this.onShowFloorMenuChanged} disabled={!canEdit} />
          </Col>
        </Row>
        <div style={{ paddingTop: "20px" }} />
        <Row>
          <Col>
            <Checkbox label="Show labels (location names)" isChecked={get(this.props.form, "config.showSBLabels", false)} onClick={this.onShowSBLabelsChanged} disabled={!canEdit} />
          </Col>
        </Row>
        <div style={{ paddingTop: "20px" }} />
        <Row>
          <Col>
            <Checkbox label="Allow interactions (scrolling/zooming)" isChecked={get(this.props.form, "config.allowInteractions", false)} onClick={this.onAllowInteractionsChanged} disabled={!canEdit} />
          </Col>
        </Row>
        <div style={{ paddingTop: "20px" }} />
        <Row>
          <Col>
            <Checkbox label="Show compass" isChecked={get(this.props.form, "config.showCompass", false)} onClick={this.onShowCompassChanged} disabled={!canEdit} />
          </Col>
        </Row>
        <div style={{ paddingTop: "20px" }} />
        <Row>
          <Col>
            <Checkbox label="Show vacant status" isChecked={get(this.props.form, "config.showVacant", true)} onClick={this.onShowVacantChanged} disabled={!canEdit} />
          </Col>
        </Row>
        <div style={{ paddingTop: "20px" }} />
        <Row>
          <Col>
            <Checkbox label="Show occupied status" isChecked={get(this.props.form, "config.showOccupied", true)} onClick={this.onShowOccupiedChanged} disabled={!canEdit} />
          </Col>
        </Row>
        <div style={{ paddingTop: "40px" }} />
        <Row>
          <Col>
            <SegmentedControl 
              name="bearing"
              title="Initial compass direction (which direction is up)"
              value={this.state.showCustomBearing ? "custom" : get(this.props.form, "config.initialBearing", 0)}
              onChange={this.onInitialBearingChanged} 
              options={[ 
                { label: "North", value: 0, default: true },
                { label: "East", value: 90 },
                { label: "South", value: 180 },
                { label: "West", value: 270 },
                { label: "Custom", value: "custom" }
              ]}
              disabled={!canEdit}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={4}>
            { this.state.showCustomBearing && 
              <InputBox onChange={this.onCustomBearingChanged} label="Custom bearing (North=0, East=90, South=180, West=270)" value={this.state.customBearing} required valid={Number(this.state.customBearing)} showValidIcon disabled={!canEdit} />
            }
          </Col>
        </Row>
        <div style={{ paddingTop: "40px" }} />
        <Freetext header={`Show "you are here"`} content={`Add the coordinates for where the display is to enable a "you are here" marker in the map.`} />
        <Row>
          <Col xs={12} md={3}>
            <InputBox 
              label="Latitude" 
              value={get(this.props.form, "config.yourLocation.lat", "")} 
              valid={Number(get(this.props.form, "config.yourLocation.lat", 0))} 
              onChange={this.onYourLocationLatitudeChanged} 
              required 
              showValidIcon
              disabled={!canEdit} 
              />
          </Col>
          <Col xs={12} md={3}>
            <InputBox 
              label="Longitude" 
              value={get(this.props.form, "config.yourLocation.lng", "")} 
              valid={Number(get(this.props.form, "config.yourLocation.lng", 0))} 
              onChange={this.onYourLocationLongitudeChanged} 
              required 
              showValidIcon
              disabled={!canEdit} 
              />
          </Col>
          <Col xs={12} md={2}>
            <InputBox 
              label="Marker color (hex)" 
              value={get(this.props.form, "config.yourLocation.color", "")} 
              valid={/^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/i.test(get(this.props.form, "config.yourLocation.color", ""))} 
              onChange={this.onYourLocationColorChanged} 
              required 
              showValidIcon
              disabled={!canEdit} 
              />
          </Col>
        </Row>
        {
          this.props.location.floorMap && canEdit && (
            <>
              <div style={{ paddingTop: "20px" }} />
              <Row>
                <Col>
                  <div className={style.mapContainer}>
                    <InputPointMap 
                      company={this.props.selectedCompany}
                      location={this.props.location}
                      map={this.props.location.floorMap}
                      point={yourLocationFeature}
                      onPointChanged={this.onYourLocationPointChanged}
                      />
                  </div>
                </Col>
              </Row>
            </>
          )
        }
        <div style={{ paddingTop: "40px" }} />
        <Freetext header="Filters" content='Add locations filters to different presentations. These work by entering location types in an "and/or/not"-format. Any location type that meets the statement requirements will be presented. For example "zone|room|!toilet" will show data for any zone or room that is not a toilet.' />
        <div style={{ paddingTop: "20px" }} />
        <Row>
          <Col xs={12} md={6}>
            <InputBox label="Status filter (which location type should show status)" placeholder="ex. meeting|quiet" value={get(this.props.form, "config.statusFilter", "")} onChange={this.onStatusFilterChanged} disabled={!canEdit} />
          </Col>
        </Row>
        <div style={{ paddingTop: "20px" }} />
        <Row>
          <Col xs={12} md={6}>
            <InputBox label="Label filter (which location type should show labels)" placeholder="ex. meeting|zone" value={get(this.props.form, "config.labelFilter", "")} onChange={this.onLabelFilterChanged} disabled={!canEdit} />
          </Col>
        </Row>
        {/* <div style={{ paddingTop: "20px" }} />
        <Row>
          <Col>
            <InputGroup label="Icon filter (which location type should show icons)" placeholder="ex. toilet" value={get(this.props.form, "config.iconFilter", "")} onChange={this.onIconFilterChanged} />
          </Col>
        </Row> */}
      </>
    )
  }

  reportConfigurationElement() {

    const canEdit = this.props.auth.hasAdminRole;

    const changeReportButtons = (
      <>
        <div style={{ paddingTop: "40px" }} />
        <Freetext header="Type" />
        <Row>
          <Col sm={12} md={3} lg={3}>
            <SmallButton text="Change type" color="white" onClick={this.onChangeTypeClick} disabled={!canEdit} />
          </Col>
        </Row>
        <div style={{ paddingTop: "40px" }} />
        <Freetext header="Power BI report" />
        <Row>
          <Col sm={12} md={3} lg={3}>
            <SmallButton text="Change report" color="white" onClick={this.onChangeReportClick} disabled={!canEdit} />
          </Col>
        </Row>
      </>
    );

    return changeReportButtons;
  }

  dashboardConfigurationElement() {

    const canEdit = this.props.auth.hasAdminRole;

    const changeReportButtons = (
      <>
        <div style={{ paddingTop: "40px" }} />
        <Freetext header="Type" />
        <Row>
          <Col sm={12} md={3} lg={3}>
            <SmallButton text="Change type" color="white" onClick={this.onChangeTypeClick} disabled={!canEdit} />
          </Col>
        </Row>
        <div style={{ paddingTop: "40px" }} />
        <Freetext header="Insights" />
        <Row>
          <Col sm={12} md={3} lg={3}>
            <SmallButton text="Change insight" color="white" onClick={this.onChangeDashboardClick} disabled={!canEdit} />
          </Col>
        </Row>
      </>
    );

    return changeReportButtons;
  }

  render() {
    if (this.props.isLoading) {
      return <Loader fullScreen />;
    }

    if (this.state.mode === "edit" && isEmpty(this.props.display)) {
      return (
        <div className={style.outerScroll}>
          <Container className={style.container}>
            <Row className={style.topRow}>
              <Col>
                <Freetext header="Woops!" content="Could not find what you were looking for." />
              </Col>
            </Row>
          </Container>
        </div>
      );
    }

    let headerText;
    let configElements;
    if (!isEmpty(this.props.form.floorId)) {
      headerText = "Map display";
      configElements = this.mapConfigurationElement();
    }
    else if (!isEmpty(this.props.form.reportId)) {
      headerText = "Report display";
      configElements = this.reportConfigurationElement();
    }
    else if (!isEmpty(this.props.form.dashboardId)) {
      headerText = "Insight display";
      configElements = this.dashboardConfigurationElement();
    }

    const showFooter = ((
      this.props.form.name && 
      this.props.form.name.length > 0 &&
      this.props.form.name !== this.props.display.name) ||
      (this.props.form.floorId !== get(this.props.display, "map.floorId", undefined)) ||
      (this.props.form.reportId !== get(this.props.display, "powerbi._id", undefined)) ||
      (get(this.props.form, "config.showTitle", undefined) !== get(this.props.display, "map.config.showTitle", undefined)) ||
      (get(this.props.form, "config.showLocationMenu", undefined) !== get(this.props.display, "map.config.showLocationMenu", undefined)) ||
      (get(this.props.form, "config.showFloorMenu", undefined) !== get(this.props.display, "map.config.showFloorMenu", undefined)) ||
      (get(this.props.form, "config.showSBLabels", undefined) !== get(this.props.display, "map.config.showSBLabels", undefined)) ||
      (get(this.props.form, "config.allowInteractions", undefined) !== get(this.props.display, "map.config.allowInteractions", undefined)) ||
      (get(this.props.form, "config.showCompass", undefined) !== get(this.props.display, "map.config.showCompass", undefined)) ||
      (get(this.props.form, "config.showVacant", undefined) !== get(this.props.display, "map.config.showVacant", undefined)) ||
      (get(this.props.form, "config.showOccupied", undefined) !== get(this.props.display, "map.config.showOccupied", undefined)) ||
      (get(this.props.form, "config.initialBearing", undefined) !== get(this.props.display, "map.config.initialBearing", undefined)) ||
      (get(this.props.form, "config.yourLocation.lat", undefined) !== get(this.props.display, "map.config.yourLocation.lat", undefined)) ||
      (get(this.props.form, "config.yourLocation.lng", undefined) !== get(this.props.display, "map.config.yourLocation.lng", undefined)) ||
      (get(this.props.form, "config.yourLocation.color", undefined) !== get(this.props.display, "map.config.yourLocation.color", undefined)) ||
      (get(this.props.form, "config.statusFilter", "") !== get(this.props.display, "map.config.statusFilter", "")) ||
      (get(this.props.form, "config.labelFilter", "") !== get(this.props.display, "map.config.labelFilter", "")) ||
      (get(this.props.form, "config.iconFilter", "") !== get(this.props.display, "map.config.iconFilter", "")));

    const canEdit = this.props.auth.hasAdminRole;
    const canDelete = this.props.auth.hasAdminRole;

    return (
      <div className={showFooter ? style.outerEditScroll : style.outerScroll}>
        <Container className={showFooter ? style.editContainer : style.container}>
          <Row className={style.topRow}>
            <Col md={3}>
              <Freetext header={headerText} />
            </Col>
            {
              canDelete && configElements && (
                <>
                  <Col md={2} offset={{ md: 5 }}>
                    <Button text="View" onClick={this.onViewClick} />  
                  </Col>
                  <Col md={2}>
                    <Button text="Delete" onClick={this.onDelete} color="red" />  
                  </Col>
                </>
              ) 
            }
            {
              canDelete && !configElements && (
                <Col md={2} offset={{ md: 7 }}>
                    <Button text="Delete" onClick={this.onDelete} color="red" />  
                  </Col>
              )
            }
            {
              !canDelete && configElements && (
                <Col md={2} offset={{ md: 7 }}>
                    <Button text="View" onClick={this.onViewClick} />  
                  </Col>
              )
            }
          </Row>
          <Row>
            <Col sm={12} md={5}>
              <InputBox onChange={this.onNameChange} label="Name" valid={this.props.form.name} value={get(this.props.form, "name", "")} required showValidIcon disabled={!canEdit} />
            </Col>
          </Row>
          { configElements }
          <div style={{ paddingTop: "80px" }} />
        </Container>
        { 
          showFooter && <OptionFooter cancel={this.onCancel} options={[{ label: "Save", callback: this.onSave }]} />
        }
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    data: state.displays.data,
    display: state.displays.display,
    form: state.displays.form,
    isLoading: state.loading.displays,
    selectedCompany: state.auth.selectedCompany,
    auth: state.auth,
    location: state.location
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ 
    getDisplay: displayActions.getDisplay,
    createDisplay: displayActions.createDisplay,
    updateDisplay: displayActions.updateDisplay,
    deleteDisplay: displayActions.deleteDisplay,
    updateDisplayForm: displayActions.updateDisplayForm,
    resetDisplayForm: displayActions.resetDisplayForm,
    getFloorMap: locationActions.getFloorMap
  }, dispatch);
}

export default connect(mapStateToProps,mapDispatchToProps)(Display);
