import LocalizedStrings from "react-localization";

export default new LocalizedStrings({
  en: {
    months: "Months"
  },
  nb: {
    months: "Måneder"
  }
});
