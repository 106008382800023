import React from "react";
import { get } from "lodash";
import moment from "moment";
import { TimePeriod, StepSize, GraphType } from "./index";
import ReportGraph from "./reportGraph";
import DashboardGraph from "./dashboardGraph";

export class GraphContainer extends React.Component {
  render() {
    // console.log("GraphContainer.render.props", this.props);

    // Find unique entityIds in samples
    const entityIds = this.props.samples.map(sample => sample.entityId);
    let uniqueEntityIds = [...new Set(entityIds)];
    if (uniqueEntityIds.length === 0) {
      uniqueEntityIds = [null];
    }
    uniqueEntityIds.sort();

    let dateRanges = get(this.props, "x.dateRanges", []);

    // If startDate and endDate is set, use that instead of dateRanges
    if (this.props.startDate && this.props.endDate) {
      dateRanges = [{ startDate: this.props.startDate, endDate: this.props.endDate }];
    }
    // Only have ticks for one week if timePeriod is WeekDays
    else if (this.props.timePeriod === TimePeriod.WeekDays) {
      const startMoment = moment(this.props.x.min).startOf("isoWeek");
      let endMoment = moment(this.props.x.min).endOf("isoWeek");

      if (!this.props.includeWeekends) {
        endMoment = endMoment.subtract(2, "days");
      }

      dateRanges = [{ startDate: startMoment, endDate: endMoment }];
    }
    // Only have ticks for one day if timePeriod is Day, HourOfDay or QuarterOfDay
    else if ([TimePeriod.Day, TimePeriod.HourOfDay, TimePeriod.QuarterOfDay].includes(this.props.timePeriod)) {
      const startMoment = moment().startOf("day");
      const endMoment = moment().endOf("day");
      dateRanges = [{ startDate: startMoment, endDate: endMoment }];
    }
    // If no dateRanges is set, use x.min and x.max
    else if (dateRanges.length === 0) {
      if (this.props.x && this.props.x.min && this.props.x.max) {
        dateRanges = [{ startDate: this.props.x.min, endDate: this.props.x.max }];
      }
    }

    let ticks = [];

    // Create ticks (x-axis) for labels and datasets for each date range
    dateRanges.forEach(dateRange => {
      let startMoment = moment(dateRange.startDate);
      let endMoment = moment(dateRange.endDate);
    
      if (this.props.stepSize === StepSize.Month) {
        // Make tick contain whole month
        startMoment = startMoment.startOf("month");
        endMoment = endMoment.endOf("month");
      }
      else if (this.props.stepSize === StepSize.Week) {
        // Make tick contain whole week
        startMoment = startMoment.startOf("isoWeek");
        endMoment = endMoment.endOf("isoWeek");
      }

      // console.log("------------");
      // console.log("dateRange", dateRange);
      // console.log("startMoment", startMoment.format("DD.MM.YYYY"));
      // console.log("endMoment", endMoment.format("DD.MM.YYYY"));

      // Create hour array
      // let timeIncrements = [];
      // let time = startMoment.clone();
      // while (time <= endMoment) {
      //   timeIncrements.push(time.clone());
      //   if (this.props.stepSize === StepSize.Quarter) {
      //     time = time.add(15, "minutes");
      //   }
      //   else {
      //     time = time.add(60, "minutes");
      //   }
      // }

      // Create ticks (x-axis) for labels and datasets
      if (StepSize.Month === this.props.stepSize) {
        // Create month array
        const months = [];
        let month = startMoment.clone();
        while (month <= endMoment) {
          months.push(month);
          month = month.clone().add(1, "month");
        }
        ticks = ticks.concat(months);
      }
      else if (StepSize.Week === this.props.stepSize) {
        // Create weeks array
        const weeks = [];
        let week = startMoment.clone();
        while (week <= endMoment) {
          weeks.push(week);
          week = week.clone().add(1, "week");
        }
        ticks = ticks.concat(weeks);
      }
      else if (this.props.timePeriod === TimePeriod.WeekDays) {
        // Create days array
        const days = [];
        let day = startMoment.clone();
        while (day <= endMoment) {
          days.push(day);//.format("dddd"));
          day = day.clone().add(1, "day");
        }
        ticks = ticks.concat(days);
      }
      else if (StepSize.Day === this.props.stepSize || [TimePeriod.Week, TimePeriod.Month].includes(this.props.timePeriod)) {
        // Create days array
        const days = [];
        let day = startMoment.clone();
        while (day <= endMoment) {
          days.push(day);
          day = day.clone().add(1, "day");
        }
        ticks = ticks.concat(days);
      }
      else if (this.props.timePeriod === undefined || this.props.timePeriod === TimePeriod.Dynamic) {
        if (this.props.stepSize === StepSize.Quarter || this.props.stepSize === StepSize.Minute) {
          // Create minutes array
          let minuteIncrements = [];
          let mTime = startMoment.clone();
          while (mTime <= endMoment) {
            minuteIncrements.push(mTime.clone());
            mTime = mTime.add(1, "minutes");
          }
          ticks = ticks.concat(minuteIncrements);
        }
      }
      else if (this.props.stepSize === StepSize.Hour) {
        // Create minutes array
        let minuteIncrements = [];
        let mTime = startMoment.clone();
        while (mTime <= endMoment) {
          minuteIncrements.push(mTime.clone());
          mTime = mTime.add(60, "minutes");
        }
        ticks = ticks.concat(minuteIncrements);
      }
      else if (this.props.stepSize === StepSize.Quarter) {
        // Create minutes array
        let minuteIncrements = [];
        let mTime = startMoment.clone();
        while (mTime <= endMoment) {
          minuteIncrements.push(mTime.clone());
          mTime = mTime.add(15, "minutes");
        }
        ticks = ticks.concat(minuteIncrements);
      }
      else if ([TimePeriod.Day, TimePeriod.HourOfDay, TimePeriod.QuarterOfDay].includes(this.props.timePeriod)) {
        // Create minutes array
        let minuteIncrements = [];
        let mTime = startMoment.clone();
        while (mTime <= endMoment) {
          minuteIncrements.push(mTime.clone());
          mTime = mTime.add(1, "minutes");
        }
        ticks = ticks.concat(minuteIncrements);
      }
    });

    // console.log("=>");
    // console.log("ticks", ticks);

    if (this.props.source === "report") {
      return (
        <ReportGraph
          { ...this.props}
          stacked={true}
          uniqueEntityIds={uniqueEntityIds}
          ticks={ticks}
        />
      );
    }

    return (
      <DashboardGraph
        { ...this.props}
        graphType={this.props.graphType || GraphType.Line}
        uniqueEntityIds={uniqueEntityIds}
        ticks={ticks}
      />
    );
  }
}

export default GraphContainer;