import React, { Component } from "react";
import { Container, Col, Row } from "react-grid-system";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { get, isEmpty } from "lodash";
import Freetext from "../../components/Freetext";
import Loader from "../../components/Loader";
import Checkbox from "../../components/Checkbox";
import InputBox from "../../components/InputBox";
import * as screenActions from "../../actions/screens";
import style from "./style.module.scss";

class ScreenGroupDetails extends Component {

  constructor(props) {
    super(props);
    this.state = {
    };

    this.onNameChange = this.onNameChange.bind(this);
    this.onLanguageChanged = this.onLanguageChanged.bind(this);
    this.onAllowInteractionsChanged = this.onAllowInteractionsChanged.bind(this);
    this.onHideViewIdentifierChanged = this.onHideViewIdentifierChanged.bind(this);
    this.onUseMeetingRoomCalendarsChanged = this.onUseMeetingRoomCalendarsChanged.bind(this);
    this.onResetTimerChanged = this.onResetTimerChanged.bind(this);
    this.onReloadDefaultViewChanged = this.onReloadDefaultViewChanged.bind(this);
    this.onResetTimeoutChanged = this.onResetTimeoutChanged.bind(this);
    this.onOverrideThemeChanged = this.onOverrideThemeChanged.bind(this);
    this.onThemeColorChanged = this.onThemeColorChanged.bind(this);
    this.onOverrideMazeMapChanged = this.onOverrideMazeMapChanged.bind(this);
    this.onMazeMapAPIKeyChanged = this.onMazeMapAPIKeyChanged.bind(this);
    this.onMazeMapCampusTagChanged = this.onMazeMapCampusTagChanged.bind(this);

    this.onCopyURL = this.onCopyURL.bind(this);
    this.mapConfigurationElement = this.mapConfigurationElement.bind(this);
  }

  onNameChange(event) {
    const form = JSON.parse(JSON.stringify(this.props.form));
    form.name = event.target.value;
    this.props.updateScreenGroupForm(form);
  }

  onLanguageChanged(event) {
    const form = JSON.parse(JSON.stringify(this.props.form));
    form.language = event.target.value === "" ? null : event.target.value;
    this.props.updateScreenGroupForm(form);
  }

  onAllowInteractionsChanged() {
    const form = JSON.parse(JSON.stringify(this.props.form));
    form.allowInteractions = !get(form, "allowInteractions", false);
    this.props.updateScreenGroupForm(form);
  }

  onHideViewIdentifierChanged() {
    const form = JSON.parse(JSON.stringify(this.props.form));
    form.hideViewIdentifier = !get(form, "hideViewIdentifier", false);
    this.props.updateScreenGroupForm(form);
  }

  onUseMeetingRoomCalendarsChanged() {
    const form = JSON.parse(JSON.stringify(this.props.form));
    form.useMeetingRoomCalendars = !get(form, "useMeetingRoomCalendars", false);
    this.props.updateScreenGroupForm(form);
  }

  onResetTimerChanged() {
    const form = JSON.parse(JSON.stringify(this.props.form));
    form.resetTimer = !!get(form, "resetTimer", false) ? null : { reloadDefaultView: false, timeout: null };
    this.props.updateScreenGroupForm(form);
  }
  
  onReloadDefaultViewChanged() {
    const form = JSON.parse(JSON.stringify(this.props.form));
    form.resetTimer = {
      reloadDefaultView: !get(form, "resetTimer.reloadDefaultView", false),
      timeout: get(form, "resetTimer.timeout", null)
    };
    this.props.updateScreenGroupForm(form);
  }

  onResetTimeoutChanged(event) {
    const form = JSON.parse(JSON.stringify(this.props.form));
    form.resetTimer = {
      reloadDefaultView: get(form, "resetTimer.reloadDefaultView", false),
      timeout: Number(event.target.value)
    };
    this.props.updateScreenGroupForm(form);
  }

  onOverrideThemeChanged() {
    const form = JSON.parse(JSON.stringify(this.props.form));
    form.configOverrides = get(form, "configOverrides", {});
    form.configOverrides.theme = !!get(form, "configOverrides.theme", false) ? null : {};
    this.props.updateScreenGroupForm(form);
  }

  onThemeColorChanged(themeProp, event) {
    const form = JSON.parse(JSON.stringify(this.props.form));
    form.configOverrides = get(form, "configOverrides", {});
    form.configOverrides.theme = get(form, "configOverrides.theme", {});

    // If event.target.value is empty, and screen had no previous value, remove the property
    if (event.target.value === "") {
      if (!get(this.props.screen, `configOverrides.theme.${themeProp}`, false)) {
        delete form.configOverrides.theme[themeProp];
      }
      else {
        form.configOverrides.theme[themeProp] = null;
      }
    }
    else {
      form.configOverrides.theme[themeProp] = event.target.value;
    }

    this.props.updateScreenGroupForm(form);
  }

  onOverrideMazeMapChanged() {
    const form = JSON.parse(JSON.stringify(this.props.form));
    form.configOverrides = get(form, "configOverrides", {});
    form.configOverrides.mazeMap = !!get(form, "configOverrides.mazeMap", false) ? null : {};
    this.props.updateScreenGroupForm(form);
  }

  onMazeMapAPIKeyChanged(event) {
    const form = JSON.parse(JSON.stringify(this.props.form));
    form.configOverrides = get(form, "configOverrides", {});
    form.configOverrides.mazeMap = get(form, "configOverrides.mazeMap", {});
    form.configOverrides.mazeMap.apiKey = event.target.value;
    this.props.updateScreenGroupForm(form);
  }

  onMazeMapCampusTagChanged(event) {
    const form = JSON.parse(JSON.stringify(this.props.form));
    form.configOverrides = get(form, "configOverrides", {});
    form.configOverrides.mazeMap = get(form, "configOverrides.mazeMap", {});
    form.configOverrides.mazeMap.campusTag = event.target.value;
    this.props.updateScreenGroupForm(form);
  }

  onCopyURL() {
    let domain = window.location.host.replace("admin.", "screen.");
    const url = `https://${domain}/${this.props.match.params.companyId}/${this.props.screenGroupId}`;

    // Add url to clipboard
    window.Clipboard.copy(url);
  }

  mapConfigurationElement() {

    const canEdit = this.props.auth.hasAdminRole;

    let reloadDefaultViewElement = null;
    if (get(this.props.form, "resetTimer", false)) {
      reloadDefaultViewElement = (
        <>
          <InputBox
            label="Timer (seconds)"
            placeholder="ex. 60"
            value={get(this.props.form, "resetTimer.timeout", "")}
            onChange={this.onResetTimeoutChanged}
            disabled={!canEdit}
            style={{ width: "100%", maxWidth: "300px" }}
          />
          <div style={{ marginTop: "20px" }} />
          <Row>
            <Col>
              <Checkbox label="Reload default view on timeout" isChecked={get(this.props.form, "resetTimer.reloadDefaultView", false)} onClick={this.onReloadDefaultViewChanged} disabled={!canEdit} />
            </Col>
          </Row>
        </>
      )
    }

    let themeElements = null;
    if (get(this.props.form, "configOverrides.theme", false)) {

      // Theme properties with friendly names
      const themeProps = {
        primaryColor: "Primary color",
        vacantColor: "Vacant color",
        occupiedColor: "Occupied color",
        vacantBookedColor: "Vacant booked color",
        noDataColor: "No data color",
        vacantFloorColor: "Vacant floor color",
        occupiedFloorColor: "Occupied floor color",
        vacantBookedFloorColor: "Vacant booked floor color",
        noDataFloorColor: "No data floor color",
        vacantFurnitureFillColor: "Vacant furniture fill color",
        occupiedFurnitureFillColor: "Occupied furniture fill color",
        noDataFurnitureFillColor: "No data furniture fill color",
        disabledFurnitureFillColor: "Disabled furniture fill color",
        vacantFurnitureStrokeColor: "Vacant furniture stroke color",
        occupiedFurnitureStrokeColor: "Occupied furniture stroke color",
        noDataFurnitureStrokeColor: "No data furniture stroke color",
        disabledFurnitureStrokeColor: "Disabled furniture stroke color"
      };

      themeElements = (
        <div style={{ display: "flex", flexWrap: "wrap", gap: "20px", marginTop: "20px" }}>
          { Object.keys(themeProps).map((themeKey) => {
            return (
              <div key={themeKey} style={{ flex: "1 1 200px", minWidth: "100px", maxWidth: "200px" }}>
                <InputBox
                  label={themeProps[themeKey]}
                  placeholder="ex. #ff0000"
                  value={get(this.props.form, `configOverrides.theme.${themeKey}`, "")}
                  onChange={(event) => this.onThemeColorChanged(themeKey, event)}
                  disabled={!canEdit}
                  style={{ width: "100%", marginTop: "0px"}}
                />
              </div>
            )
          })}
        </div>
      );
    }

    let mazeMapElement = null;
    if (get(this.props.form, "configOverrides.mazeMap", false)) {
      mazeMapElement = (
        <>
          <InputBox
            label="MazeMap API key"
            placeholder="ex. 123456"
            value={get(this.props.form, "configOverrides.mazeMap.apiKey", "")}
            onChange={this.onMazeMapAPIKeyChanged}
            disabled={!canEdit}
            style={{ width: "100%", maxWidth: "300px" }}
          />
          <div style={{ marginTop: "20px" }} />
          <InputBox
            label="MazeMap campus tag"
            placeholder="ex. campus"
            value={get(this.props.form, "configOverrides.mazeMap.campusTag", "")}
            onChange={this.onMazeMapCampusTagChanged}
            disabled={!canEdit}
            style={{ width: "100%", maxWidth: "300px" }}
          />
        </>
      );
    }

    return (
      <>
        <div style={{ paddingTop: "25px" }} />
        <Freetext header="General" content="Set the basic configurations for all views/devices in this screen. Default values should be fine in most cases." />
        <div style={{ marginTop: "10px" }} />
        <Row>
          <Col>
            <Checkbox label="Allow map interactions" description="(clicking/scrolling/zooming)" isChecked={get(this.props.form, "allowInteractions", false)} onClick={this.onAllowInteractionsChanged} disabled={!canEdit} />
          </Col>
        </Row>
        <div style={{ marginTop: "10px" }} />
        <Row>
          <Col>
            <Checkbox label="Hide view identifier" description="(tag on the bottom of the screen)" isChecked={get(this.props.form, "hideViewIdentifier", false)} onClick={this.onHideViewIdentifierChanged} disabled={!canEdit} />
          </Col>
        </Row>
        <div style={{ marginTop: "10px" }} />
        <Row>
          <Col>
            <Checkbox label="Use meeting room calendars" description="(for busy status)" isChecked={get(this.props.form, "useMeetingRoomCalendars", false)} onClick={this.onUseMeetingRoomCalendarsChanged} disabled={!canEdit} />
          </Col>
        </Row>
        <div style={{ marginTop: "30px" }} />
        <InputBox
          label="Override device language (en/no/se)"
          placeholder="ex. no"
          value={get(this.props.form, "language", "")}
          onChange={this.onLanguageChanged}
          disabled={!canEdit}
          style={{ width: "100%", maxWidth: "300px" }}
        />
        <div style={{ marginTop: "40px" }} />
        <Freetext header="Reset timer" content="With interactable screens it might be appropriate to reset the screen to it's initial state after a user has used it." />
        <div style={{ marginTop: "10px" }} />
        <Row>
          <Col>
            <Checkbox label="Enable reset timer" isChecked={!!get(this.props.form, "resetTimer", false)} onClick={this.onResetTimerChanged} disabled={!canEdit} />
          </Col>
        </Row>
        { reloadDefaultViewElement }
        <div style={{ marginTop: "40px" }} />
        <Freetext header="Theme" content="Adjust the colors used in the map. By default, the map uses the company’s theme colors, but you can choose to override them and customize each color to fit your specific needs." />
        <div style={{ marginTop: "10px" }} />
        <Row>
          <Col>
            <Checkbox label="Override the company theme" isChecked={!!get(this.props.form, "configOverrides.theme", false)} onClick={this.onOverrideThemeChanged} disabled={!canEdit} />
          </Col>
        </Row>
        { themeElements }
        <div style={{ marginTop: "40px" }} />
        <Freetext header="MazeMap" content="Add a custom MazeMap API key and campus tag for this screen. By default, the screen will use the MazeMap settings from the company settings." />
        <div style={{ marginTop: "10px" }} />
        <Row>
          <Col>
            <Checkbox label="Override MazeMap settings" isChecked={!!get(this.props.form, "configOverrides.mazeMap", false)} onClick={this.onOverrideMazeMapChanged} disabled={!canEdit} />
          </Col>
        </Row>
        { mazeMapElement }
      </>
    )
  }

  render() {
    // console.log("ScreenConfigDetails.render", this.props);

    if (this.props.isLoading) {
      return <Loader fullScreen />;
    }

    if (this.props.screenGroupId !== "new" && isEmpty(this.props.screen)) {
      return (
        <div className={style.outerScroll}>
          <Container className={style.container}>
            <Row className={style.topRow}>
              <Col>
                <Freetext header="Woops!" content="Could not find what you were looking for." />
              </Col>
            </Row>
          </Container>
        </div>
      );
    }

    let configElements = this.mapConfigurationElement();

    return (
      <>
        { configElements }
        <div style={{ paddingTop: "80px" }} />
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    screen: state.screen,
    form: state.screen.form,
    isLoading: state.loading.screen,
    auth: state.auth,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ 
    deleteScreenGroup: screenActions.deleteScreenGroup,
    updateScreenGroupForm: screenActions.updateScreenGroupForm
  }, dispatch);
}

export default connect(mapStateToProps,mapDispatchToProps)(ScreenGroupDetails);