import React, { Component } from "react";
import { Col, Row } from "react-grid-system";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import Button from "../../components/Button";
import SearchBox from "../../components/DashboardFilterSearchBox";
import style from "./style.module.scss";

class SideBar extends Component {

  static propTypes = {
    children: PropTypes.instanceOf(Object).isRequired,
    filterOpen: PropTypes.bool.isRequired,
    editFilterMode: PropTypes.bool.isRequired,
    openFilter: PropTypes.func.isRequired,
    closeFilter: PropTypes.func.isRequired,
    onApplyFilter: PropTypes.func.isRequired,
    onLoadFilter: PropTypes.func.isRequired,
    onSaveFilter: PropTypes.func.isRequired,
    onEditFilter: PropTypes.func.isRequired,
    hasSelectedFilter: PropTypes.bool.isRequired,
    filterHasChanged: PropTypes.bool.isRequired,
    filterHasBeenQueried: PropTypes.bool.isRequired,
  };

  render() {
    return (
      <div className={style.sideBar} data-open={this.props.filterOpen} onClick={(event) => this.props.openFilter(event)}>
    <div className={style.sideHeader}>
      <div className={style.sideTitle}>
        Filter
      </div>
      {this.props.filterOpen && (
        <div className={style.closeFilterButton} onClick={(event) => this.props.closeFilter(event)}>
          <FontAwesomeIcon icon={faTimes} color={"gray"} size="lg" />
        </div>
      )
      }
    </div>
    {this.props.filterOpen && (
      <div className={style.sideContent}>
        <div style={{ paddingTop: "20px" }} />
        <div className={style.sideDescription}>
          Change the dataset to inspect different parts of the sensor installation. Edit the filter directly or load a previously saved filter.
        </div>
        <div style={{ paddingTop: "30px" }} />
        { this.props.children }
        <div style={{ paddingTop: "40px" }} />
        <div className={style.sideButtonContainer}>
          {this.props.editFilterMode ? (
            <>
              <Row>
                <Col>
                  <Button
                    text="Apply filter"
                    onClick={this.props.onApplyFilter}
                    disabled={this.props.filterHasBeenQueried}
                  />
                </Col>
              </Row>
              {/* <Row>
                <Col>
                  <Button
                    text="Load filter"
                    onClick={this.props.onLoadFilter}
                  />
                </Col>
                <Col>
                  <Button
                    text="Save filter"
                    onClick={this.props.onSaveFilter}
                    disabled={!this.props.filterHasChanged}
                  />
                </Col>
              </Row> */}
            </>
          ) : (
            <Row>
              <Col>
                {/* <Button
                  text="Edit filter"
                  onClick={this.props.onEditFilter}
                /> */}
                <Button
                  text="Load filter"
                  onClick={this.props.onLoadFilter}
                />
              </Col>
              <Col>
                
                <Button
                    text="Save filter"
                    onClick={this.props.onSaveFilter}
                    disabled={!this.props.filterHasChanged}
                  />
              </Col>
            </Row>
          )
          }
        </div>
        <div style={{ paddingTop: "20px" }} />
      </div>
    )
    }
  </div>
    );
  }
}

export default SideBar;