import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { get, isEmpty } from "lodash";
import MapboxMap from "../Map/MapboxMap";
import Loader from "../../components/Loader";
import Freetext from "../../components/Freetext";
import Checkbox from "../../components/Checkbox";
import InputBox from "../../components/InputBox";
import Modal from "../../components/Modal";
import OptionFooter from "../../components/OptionFooter";
import { DropdownSelection } from "../../components/DropdownSelection";
import * as authActions from "../../actions/auth";
import * as locationActions from "../../actions/locations";
import * as gatewayActions from "../../actions/gateways";
import * as jobActions from "../../actions/jobs";
import * as selectedActions from "../../actions/selected";
import style from "./style.module.scss";

class NewGateway extends Component {

  constructor(props) {
    super(props);
    this.state = {
      gatewayId: "",
      type: "",
      id: props.match.params.id,
      name: "",
      msisdn: "",
      description: "",
      validity: "",
      vendor: "",
      autoProvisioningEnabled: false
    };

    this.onBackClick = this.onBackClick.bind(this);
    this.onNameChange = this.onNameChange.bind(this);
    this.onMSISDNChange = this.onMSISDNChange.bind(this);
    this.onDescriptionChange = this.onDescriptionChange.bind(this);
    this.onValidityChange = this.onValidityChange.bind(this);
    this.onVendorChange = this.onVendorChange.bind(this);
    this.onAutoProvisioningEnabledChange = this.onAutoProvisioningEnabledChange.bind(this);
    this.onGatewayIdChange = this.onGatewayIdChange.bind(this);
    this.onSaveWithMap = this.onSaveWithMap.bind(this);
    this.onSaveWithoutMap = this.onSaveWithoutMap.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.getGatewayTypes = this.getGatewayTypes.bind(this);
    this.onGatewayTypeChange = this.onGatewayTypeChange.bind(this);
    this.onViewJob = this.onViewJob.bind(this);
    this.onClose = this.onClose.bind(this);

    if (props.match.params.id !== props.location.id) {
      props.getLocation(props.match.params.id);
      props.getFloorMap(props.match.params.id);
      // props.getCompanyMap();
    }

    // Reset Created job for Modal
    this.props.clearCreatedJob();
  }

  onBackClick(id) {
    if (get(this.props.location, "state.canGoBack", false)) {
      this.props.history.goBack();
    }
    else {
      this.props.history.push(`/companies/${this.props.match.params.companyId}/locations/${id}`);
    }
  }

  onViewJob() {
    this.props.history.push(`/companies/${this.props.match.params.companyId}/installation/job-groups/unknown/unknown/${this.props.createdJob.jobId}`);
  }

  onClose() {
    this.props.clearCreatedJob();
  }

  onNameChange(event) {
    this.setState({ name: event.target.value });
  }

  onMSISDNChange(event) {
    this.setState({ msisdn: event.target.value });
  }

  onDescriptionChange(event) {
    this.setState({ description: event.target.value });
  }

  onValidityChange(event) {
    this.setState({ validity: event.target.value });
  }

  onVendorChange(event) {
    this.setState({ vendor: event.target.value });
  }

  onAutoProvisioningEnabledChange(event) {
    this.setState({ autoProvisioningEnabled: event.target.checked });
  }

  onGatewayIdChange(event) {
    let gatewayIdValue = event.target.value;
    gatewayIdValue = gatewayIdValue.replace("EUI64+","EUI64-");
    this.setState({ gatewayId: gatewayIdValue });
  }

  onSaveWithMap() {
    const { push } = this.props.history;

    const body = {
      name: this.state.name,
      locationIds: [this.props.location.id]
    };

    if (!isEmpty(this.state.msisdn)) {
      body.msisdn = this.state.msisdn;
    }

    if (!isEmpty(this.state.description)) {
      body.description = this.state.description;
    }

    switch (this.state.type) {
      case "hono":
        if (!isEmpty(this.state.validity) && Number.isInteger(Number(this.state.validity)) && Number(this.state.validity) >= 0) {
          body.validityMs = Number(this.state.validity);
        }
        body.vendor = this.state.vendor;
        body.honoDeviceId = this.state.gatewayId;
        body.autoProvisioningEnabled = this.state.autoProvisioningEnabled;
        break;
      case "yanzi":
        body.gatewayDid = this.state.gatewayId;
        break;
      default:
        return;
    }

    if (this.props.createdFeature) {
      body.geoJsonFeature = this.props.createdFeature;
    }

    this.props.createGateway(this.state.type, body, this.props.location.id, push);  
  }

  onSaveWithoutMap() {
    const { push } = this.props.history;

    const body = {
      name: this.state.name,
      locationIds: [this.props.location.id]
    };

    if (!isEmpty(this.state.msisdn)) {
      body.msisdn = this.state.msisdn;
    }

    if (!isEmpty(this.state.description)) {
      body.description = this.state.description;
    }

    switch (this.state.type) {
      case "hono":
        if (!isEmpty(this.state.validity) && Number.isInteger(Number(this.state.validity)) && Number(this.state.validity) >= 0) {
          body.validityMs = Number(this.state.validity);
        }
        body.vendor = this.state.vendor;
        body.honoDeviceId = this.state.gatewayId;
        body.autoProvisioningEnabled = this.state.autoProvisioningEnabled;
        break;
      case "yanzi":
        body.gatewayDid = this.state.gatewayId;
        break;
      default:
        return;
    }

    this.props.createGateway(this.state.type, body, this.props.location.id, push);
  }

  onCancel() {
    this.onBackClick(this.state.id);
  }

  getGatewayTypes() {
    let types = [];

    if (get(this.props.company, "hasYanziIntegration", false)) {
      types.push({ id:"yanzi", name:"Yanzi" });
    }

    types.push({ id:"hono", name:"IoT" });
    
    return types;
  }

  onGatewayTypeChange(selection) {
    this.setState({ type: selection.target.value });
  }

  render() {
    // const { isLoading } = this.props;

    if (this.props.isLoading || this.props.location.id === undefined) {
      return <Loader fullScreen />;
    }

    let mapElement;
    if (this.props.location.floorMap) {
      mapElement = (
        <div className={style.mapPart}>
          <MapboxMap 
            mapId="new-gateway-map-container" 
            id={this.state.id} 
            company={this.props.company} 
            location={this.props.location} 
            map={this.props.location.floorMap} 
            drawMode="draw_point"
            showSensors
            showGateways
          />
          <div className={style.mapHelp}>
            <span>Sensors are </span>
            <span style={{ color: "#0080FF" }}>blue</span>
            <span>. Gateways are </span>
            <span style={{ color: "#82612F" }}>brown</span>
            <span>.</span>
          </div>
        </div>
      );
    }
    else if (this.props.companyMap) {
      mapElement = (
        <div className={style.mapPart}>
          <MapboxMap 
            mapId="new-gateway-map-container" 
            id={this.state.id} 
            company={this.props.company} 
            location={this.props.location} 
            map={this.props.companyMap} 
            drawMode="draw_point" 
            showSensors
            showGateways
          />
          <div className={style.mapHelp}>
            <span>Sensors are </span>
            <span style={{ color: "#0080FF" }}>blue</span>
            <span>. Gateways are </span>
            <span style={{ color: "#82612F" }}>brown</span>
            <span>.</span>
          </div>
        </div>
      );
    }

    // Options for saving
    const options = [];
    if (isEmpty(this.props.createdFeature)) {
      options.push({ 
        label: "Save without map", 
        callback: this.onSaveWithoutMap, 
        disabled: this.state.name.length < 3 || this.state.gatewayId.length === 0 || (this.state.type === "hono" && this.state.vendor.length === 0)
      });
    }
    else {
      options.push({ 
        label: "Save with map", 
        callback: this.onSaveWithMap, 
        disabled: this.state.name.length < 3 || this.state.gatewayId.length === 0 || (this.state.type === "hono" && this.state.vendor.length === 0)
      });
    }

    return (
      <>
        <div className={style.editContainer}>
          <div className={style.row}>
            <div className={style.col50}>
              <div className={style.scroll}>
                <div className={style.topRow}>
                  <Freetext header={`Add gateway to ${this.props.location.name}`} />
                </div>
                <h3>1. Enter gateway details</h3>
                <DropdownSelection 
                  name="gatewayType"
                  label="Gateway type"
                  options={this.getGatewayTypes()}
                  onChange={this.onGatewayTypeChange}
                />
                {
                  this.state.type !== "" && (
                    <>
                      <InputBox autoFocus onChange={this.onGatewayIdChange} label="Gateway ID" placeholder="e.g. EUI64-0080E1030005DADE" value={this.state.gatewayId} valid={this.state.gatewayId} required showValidIcon />
                      <InputBox onChange={this.onNameChange} label="Name (minimum 3 characters)" placeholder="e.g. Roof-K5B" value={this.state.name} valid={this.state.name.length > 2} required showValidIcon />
                      <InputBox onChange={this.onDescriptionChange} label="Description (optional)" placeholder="e.g. The gateway is above the ceiling tiles" value={this.state.description} valid={this.state.description} />
                      <InputBox onChange={this.onMSISDNChange} label="MSISDN (optional)" placeholder="e.g. 4780123456" value={this.state.msisdn} valid={this.state.msisdn}  />
                    </>
                  )
                }
                {
                  this.state.type === "hono" && (
                    <>
                      <InputBox onChange={this.onVendorChange} label="Vendor name" placeholder="e.g. Pressac" value={this.state.vendor} valid={this.state.vendor.length > 0} />
                      <InputBox onChange={this.onValidityChange} label="Validity duration (in milliseconds)" placeholder="unlimited by default" value={this.state.validity} valid={Number.isInteger(Number(this.state.validity)) && Number(this.state.validity) >= 0} required showValidIcon />
                      <div style={{ marginTop: "25px" }}>
                        <Checkbox label="Use auto-provisioning" description="(enroll sensors when they connect)" isChecked={this.state.autoProvisioningEnabled} onClick={this.onAutoProvisioningEnabledChange} />
                      </div>
                    </>
                  )
                }
                <div style={{ paddingTop: "80px" }} />
              </div>
            </div>
            <div className={style.col50}>
              <h3 className={style.mapHeader}>2. Place gateway in the map</h3>
              { mapElement }
            </div>
          </div>
          <OptionFooter 
            cancel={this.onCancel} 
            cancelButtonLabel="Cancel"
            options={options} 
          />
        </div>

        <Modal
          show={!isEmpty(this.props.createdJob)}
          title="Creating gateway"
          text={"The gateway creation request has been sent."}
          primaryBtn={{
            text: "Add another",
            onClick: this.onClose
          }}
          secondaryBtn={{
            text: "View job",
            onClick: this.onViewJob
          }}
          tertiaryBtn={{
            text: "Return",
            color: "blue", 
            onClick: this.onCancel
          }}
        />
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    location: state.location,
    sensor: state.sensor,
    isLoading: state.loading.gateways,
    createdJob: state.gateways.createdJob,
    company: state.auth.selectedCompany,
    companyMap: state.auth.map,
    createdFeature: state.selected.createdFeature
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    createGateway: gatewayActions.createGateway,
    getLocation: locationActions.getLocation,
    getFloorMap: locationActions.getFloorMap,
    getCompanyMap: authActions.getCompanyMap,
    clearSelection: selectedActions.clearSelection,
    clearCreatedJob: jobActions.clearCreatedJob
  }, dispatch);
}

export default connect(mapStateToProps,mapDispatchToProps)(NewGateway);