import get from "lodash/get";
import { toast } from "react-toastify";
import * as types from "../ApiTypes";
import { Role, getRole } from "../helpers";

const initialState = {
  id: null,
  name: null,
  email: null,
  phoneNumber: null,
  role: Role.User,
  userGroups: [],
};

export default function inviteReducer(state = initialState, action) {

  switch (action.type) {
    
    case types.REQ_DATA: {
      if (action.fetchType === types.GET_INVITE) {
        return initialState;
      }

      return state;
    }

    case types.RECV_DATA: {
      if (action.fetchType === types.GET_INVITE) {

        const role = action.payload.userGroup.role;

        return {
          ...state,
          id: action.payload.id,
          name: action.payload.name,
          email: action.payload.email,
          phoneNumber: action.payload.phone_number,
          role: getRole(role),
          userGroups: [action.payload.userGroup]
        };
      }

      if (action.fetchType === types.INVITE_USERS) {
        toast.success("Invite sent");
        return state;
      }

      if (action.fetchType === types.DELETE_INVITE) {
        toast.success("Invite deleted");
        return state;
      }

      return state;
    }

    case types.RECV_ERROR: {

      const statusCode = get(action, "payload.response.status", "Error");

      if (action.fetchType === types.GET_INVITE) {
        toast.error(`${statusCode}: Could not get invite`);
        return initialState;
      }

      if (action.fetchType === types.INVITE_USERS) {
        toast.error(`${statusCode}: Could not send invite`);
        return state;
      }

      if (action.fetchType === types.DELETE_INVITE) {
        toast.error(`${statusCode}: Could not delete invite`);
        return state;
      }
      
      return state;
    }

    case types.CLEAR_DATA, types.CLEAR_INVITE: {
      return initialState;
    }

    default: {
      return state;
    }
  }
}
