import React from "react";
import styles from "./styles.module.scss";

export class Filter extends React.PureComponent {
  constructor(props) {
    super(props);
  }

  render() {
    const { label, children } = this.props;
    return (
      <div className={styles.filterInputContainer}>
        <span>{label}</span>
        {children}
      </div>
    )
  }
}
