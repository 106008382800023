import React, { Component } from "react";
import { get, isEmpty, uniqueId } from "lodash";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import moment from "moment";
import DetailsHeader from "../../../components/DetailsHeader";
import SubNavigation from "../../../components/SubNavigation";
import { ControlledDropdownSelection } from "../../../components/DropdownSelection";
import TextBox from "../../../components/TextBox";
import LocationSelector from "./locationSelector";
import ButtonInputBox from "../../../components/ButtonInputBox";
import InputPointMap from "../../Map/InputPointMap";
import TopRowOptions from "../../../components/TopRowOptions";
import { getLocation } from "../../../locationHelpers";
import * as unregisteredSensorActions from "../../../actions/unregisteredSensors";
import * as locationActions from "../../../actions/locations";
import * as API from "../../../ApiTypes";

const tabBarLinks = [
  { label: `Details` },
  { label: `Location` },
  { label: `Messages` },
  { label: `Place in map` }
];

class UnregisteredDetails extends Component {

  constructor(props) {
    // console.log("UnregisteredDetails.constructor()", props);
    super(props);
    this.state = {
      selectedIndex: 0,
      showLocationSelector: false,
      combinedData: null
    };

    this.updateData = this.updateData.bind(this);
    this.selectedTab = this.selectedTab.bind(this);
    this.onDescriptionChange = this.onDescriptionChange.bind(this);
    this.onVortoVendorChange = this.onVortoVendorChange.bind(this);
    this.onVortoModelChange = this.onVortoModelChange.bind(this);
    this.onVortoVersionChange = this.onVortoVersionChange.bind(this);
    this.onLocationChange = this.onLocationChange.bind(this);
    this.getLocationString = this.getLocationString.bind(this);
    this.onLocationPointChanged = this.onLocationPointChanged.bind(this);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    
    const selectedDeviceIds = Object.keys(nextProps.selectedDevices);

    // Create combined data which includes every value that the user sees on the screen
    if (!isEmpty(selectedDeviceIds)) {

      const combinedData = {};

      // Overwrite the data if there is a draft
      if (nextProps.draft) {
        if (nextProps.draft.vortoId !== undefined) {
          combinedData.vortoId = nextProps.draft.vortoId;
        }
  
        if (nextProps.draft.description !== undefined) {
          combinedData.description = nextProps.draft.description;
        }
  
        if (nextProps.draft.locationId !== undefined) {
          combinedData.locationId = nextProps.draft.locationId;
        }
  
        if (nextProps.draft.geoJsonFeature !== undefined) {
          combinedData.geoJsonFeature = nextProps.draft.geoJsonFeature;
        }
      }

      return { combinedData };
    }

    return { combinedData: null };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    this.updateData();
  }

  updateData() {
    const selectedDeviceIds = Object.keys(this.props.selectedDevices);
    if (selectedDeviceIds.length === 1 && !isEmpty(this.props.locationHierarchy)) {
      const firstDevice = this.props.selectedDevices[selectedDeviceIds[0]];

      // Get locationId
      const locationId = get(this.props.draft, "locationId", get(firstDevice, "locationIds[0]", null));
      if (locationId && locationId !== this.props.location.currentLocationId) {
        this.props.getLocation(locationId);
      }

      // Get samples
      if (firstDevice.deviceId !== this.props.currentDeviceId) {
        this.props.getUnregisteredSensorSamples(firstDevice.deviceId);
      }
    }
  }

  selectedTab(index) {
    this.setState({ selectedIndex: index });
  }

  onDescriptionChange(event) {
    const description = event.target.value;
    this.props.updateUnregisteredSensorDraft({ description });
  }

  onVortoVendorChange(event) {
    const vortoVendor = event.target.value;
    
    // Find first vortoId with the same vendor
    const vortoVendorObject = this.props.vendors[vortoVendor];

    const models = Object.keys(vortoVendorObject);
    models.sort();
    const firstModel = models[0];

    const versions = Object.keys(vortoVendorObject[firstModel]);
    versions.sort();
    const firstVersion = versions[0];

    const vortoId = vortoVendorObject[firstModel][firstVersion];

    this.props.updateUnregisteredSensorDraft({ vortoId });
  }

  onVortoModelChange(event) {
    const vortoModel = event.target.value;

    // Get the drafted data
    const selectedDeviceIds = Object.keys(this.props.selectedDevices);
    const firstDevice = this.props.selectedDevices[selectedDeviceIds[0]];

    // Get the previous value to fill out the new vortoId
    let oldVortoId = "";
    if (isEmpty(this.state.combinedData.vortoId)) {
      oldVortoId = get(firstDevice, "maybeVortoIds[0]", null);
    }
    else {
      oldVortoId = this.state.combinedData.vortoId;
    }

    const oldVorto = this.props.vortoIds.find(vorto => vorto.id === oldVortoId);
    
    // Find first vortoId with the same vendor
    const vortoModelObject = this.props.vendors[oldVorto.vendor][vortoModel];

    const versions = Object.keys(vortoModelObject);
    versions.sort();
    const firstVersion = versions[0];

    const vortoId = vortoModelObject[firstVersion];

    this.props.updateUnregisteredSensorDraft({ vortoId });
  }

  onVortoVersionChange(event) {
    const vortoVersion = event.target.value;

    // Get the drafted data
    const selectedDeviceIds = Object.keys(this.props.selectedDevices);
    const firstDevice = this.props.selectedDevices[selectedDeviceIds[0]];

    // Get the previous value to fill out the new vortoId
    let oldVortoId = "";
    if (isEmpty(this.state.combinedData.vortoId)) {
      oldVortoId = get(firstDevice, "maybeVortoIds[0]", null);
    }
    else {
      oldVortoId = this.state.combinedData.vortoId;
    }
     
    const oldVorto = this.props.vortoIds.find(vorto => vorto.id === oldVortoId);
    
    const vortoId = this.props.vendors[oldVorto.vendor][oldVorto.model][vortoVersion];

    this.props.updateUnregisteredSensorDraft({ vortoId });
  }

  onLocationChange(locationId) {
    this.props.updateUnregisteredSensorDraft({ locationId });
  }

  getLocationString(locationId) {
    let breadcrumbs = get(this.props, `locationBreadcrumbs[${locationId}]`, []);

    if (breadcrumbs.length === 0) {
      return "";
    }
    else if (breadcrumbs.length === 1) {
      return breadcrumbs[0].name;
    }
    
    // Create breadcrumb string: last location name (, joined parent names)
    return breadcrumbs[breadcrumbs.length - 1].name + " (" + breadcrumbs.slice(0, breadcrumbs.length - 1).map(location => location.name).join(", ") + ")";
  }

  onLocationPointChanged(point) {
    const lat = get(point, "geometry.coordinates[1]", 0);
    const lng = get(point, "geometry.coordinates[0]", 0);

    if (lat && lng) {
      const geoJsonFeature = {
        "id": uniqueId(),
        "type": "Feature",
        "geometry": {
          "type": "Point",
          "coordinates": [lng, lat]
        }
      };

      this.props.updateUnregisteredSensorDraft({ geoJsonFeature });
    }
  }

  render() {
    // console.log("UnregisteredDetails.render()", this.state);
    // console.log("UnregisteredDetails.render()", this.props.selectedDevices);

    if (isEmpty(this.props.selectedDevices)) {
      return null;
    }
    
    const selectedDeviceIds = Object.keys(this.props.selectedDevices);
    const isEditingMultipleSensors = selectedDeviceIds.length > 1;
    const title = `Edit ${selectedDeviceIds.length} sensors`;

    // Check if all selected sensors have the same vendor
    const firstDevice = this.props.selectedDevices[selectedDeviceIds[0]];
    const allSensorsHaveSameVendor = selectedDeviceIds.every(deviceId => this.props.selectedDevices[deviceId].vendor === firstDevice.vendor);
    const allSensorsHaveSameVortoId = selectedDeviceIds.every(deviceId => this.props.selectedDevices[deviceId].vortoId === firstDevice.vortoId);
    const allSensorsHaveNullVortoId = selectedDeviceIds.every(deviceId => isEmpty(this.props.selectedDevices[deviceId].vortoId));
    const allSensorsHaveSameDescription = selectedDeviceIds.every(deviceId => this.props.selectedDevices[deviceId].description === firstDevice.description);
    const allSensorsHaveSameLocationId = selectedDeviceIds.every(deviceId => get(this.props.selectedDevices[deviceId], "locationIds[0]", null) === get(firstDevice, "locationIds[0]", null));
    const allSensorsHaveALocationId = selectedDeviceIds.every(deviceId => !isEmpty(this.props.selectedDevices[deviceId].locationIds));


    // console.log("allSensorsHaveSameVendor", allSensorsHaveSameVendor);
    // console.log("allSensorsHaveSameVortoId", allSensorsHaveSameVortoId);
    // console.log("allSensorsHaveNullVortoId", allSensorsHaveNullVortoId);
    // console.log("allSensorsHaveSameDescription", allSensorsHaveSameDescription);
    // console.log("allSensorsHaveSameLocationId", allSensorsHaveSameLocationId);
    // console.log("allSensorsHaveALocationId", allSensorsHaveALocationId);

    // A modal popup for selecting location
    if (this.state.showLocationSelector) {

      const companyName = get(this.props.selectedCompany, "name", "Company");
      const topLocation = { _id: "top", name: companyName, children: this.props.locationHierarchy };
      let selectedLocation = null;

      let locationId = null;
      if (isEmpty(this.state.combinedData.locationId)) {
        if (allSensorsHaveSameLocationId) {
          locationId = get(firstDevice, "locationIds[0]", null);
        }
      }
      else {
        locationId = this.state.combinedData.locationId;
      }

      if (locationId) {
        selectedLocation = getLocation(topLocation, locationId);
      }

      return (
        <div style={{ display: "block", position: "fixed", top: "0px", left: "0px", width: "100%", height: "100%", backgroundColor: "rgba(0, 0, 0, 0.5)", zIndex: 1000 }}>
          <LocationSelector 
            topLocation={topLocation}
            selectedLocation={selectedLocation}
            isLoading={this.props.isLoading}
            onLocationChange={this.onLocationChange}
            onClose={() => this.setState({ showLocationSelector: false })}
          />
        </div>
      );
    }

    let detailsElement = null;
    switch (this.state.selectedIndex) {
      case 0:

        // Get the drafted or saved vorto data, and the maybeVorto data 
        let vortoId = null;
        let maybeVortoId = null;
        if (isEmpty(this.state.combinedData.vortoId)) {
          if (allSensorsHaveSameVortoId) {
            vortoId = firstDevice.vortoId;
          }
        }
        else {
          vortoId = this.state.combinedData.vortoId;
        }

        if (allSensorsHaveSameVendor) {
          maybeVortoId = get(firstDevice, "maybeVortoIds[0]", null);
        }

        // console.log("vortoId", vortoId);
        // console.log("maybeVortoId", maybeVortoId);

        const vorto = this.props.vortoIds.find(vorto => vorto.id === vortoId);
        const maybeVorto = this.props.vortoIds.find(vorto => vorto.id === maybeVortoId);

        // Get selectedVortoVendor, selectedVortoModel and selectedVortoVersion from vortoIds.find()
        const selectedVortoVendor = vorto ? vorto.vendor : (maybeVorto ? maybeVorto.vendor : null);
        const selectedVortoModel = vorto ? vorto.model : null;
        const selectedVortoVersion = vorto ? vorto.version : null;

        // console.log("selectedVortoVendor", selectedVortoVendor);
        // console.log("selectedVortoModel", selectedVortoModel);
        // console.log("selectedVortoVersion", selectedVortoVersion);

        // Get list of vendors from vortoIds
        let vendorList = Object.keys(this.props.vendors);
        if (maybeVorto) {
          vendorList = vendorList.filter(vendorName => vendorName === selectedVortoVendor);
        }
        vendorList.sort((a, b) => a.localeCompare(b));
        const vendorOptions = vendorList.map(vendorName => ({ id: vendorName, name: vendorName }));

        // Get list of models for options
        let modelOptions = [];
        if (selectedVortoVendor) {
          const modelList = Object.keys(this.props.vendors[selectedVortoVendor]);
          modelList.sort((a, b) => a.localeCompare(b));
          modelOptions = modelList.map(modelName => ({ id: modelName, name: modelName }));
        }

        // Get list of versions for options
        let versionOptions = [];
        if (selectedVortoVendor && selectedVortoModel) {
          const versionList = Object.keys(this.props.vendors[selectedVortoVendor][selectedVortoModel]);

          // Sort versionList by semver
          versionList.sort((a, b) => semver.gt(a, b) ? 1 : -1);
          versionOptions = versionList.map(versionName => ({ id: versionName, name: versionName }));
        }
        
        // Add empty options or "<multiple values>" options
        if (allSensorsHaveSameVendor && allSensorsHaveNullVortoId) {
          modelOptions.unshift({ id: "", name: "" });
          versionOptions.unshift({ id: "", name: "" });
  
          if (!selectedVortoVendor) {
            vendorOptions.unshift({ id: "", name: "" });
          }
        }
        else if (!selectedVortoVendor) {
          vendorOptions.unshift({ id: "", name: "<multiple values>" });
          modelOptions.unshift({ id: "", name: "-" });
          versionOptions.unshift({ id: "", name: "-" });
        }

        // Get the description
        let description = "";
        let descriptionPlaceholder = "";
        if (isEmpty(this.state.combinedData.description)) {
          if (isEditingMultipleSensors) {
            description = allSensorsHaveSameDescription ? firstDevice.description : "";
            descriptionPlaceholder = allSensorsHaveSameDescription ? "" : "<multiple values>";
          }
          else {
            description = firstDevice.description ?? "";
          }
        }
        else {
          description = this.state.combinedData.description;
        }

        detailsElement = <div>
          <ControlledDropdownSelection
            label="Vendor" 
            options={vendorOptions}
            value={selectedVortoVendor ?? ""}
            onChange={this.onVortoVendorChange}
            style={{ marginRight: "20px", minWidth: "100px" }}
            disabled={!allSensorsHaveSameVendor}
          />
          <ControlledDropdownSelection
            label="Model" 
            options={modelOptions}
            value={selectedVortoModel ?? ""}
            onChange={this.onVortoModelChange}
            style={{ marginRight: "20px", minWidth: "100px" }}
            disabled={!allSensorsHaveSameVendor}
          />
          <ControlledDropdownSelection
            label="Model version" 
            options={versionOptions}
            value={selectedVortoVersion ?? ""}
            onChange={this.onVortoVersionChange}
            style={{ minWidth: "100px" }}
            disabled={!allSensorsHaveSameVendor}
          />
          <TextBox
            label="Description"
            value={description}
            placeholder={descriptionPlaceholder}
            onChange={this.onDescriptionChange}
          />
        </div>;
        break;

      case 1: 

        let locationId = null;
        let locationText = "";

        // If combinedData has unset locationId
        if (isEmpty(this.state.combinedData.locationId)) {
          if (allSensorsHaveSameLocationId) {
            if (allSensorsHaveALocationId) {
              locationId = get(firstDevice, "locationIds[0]", null);
            }
          }
          else {
            locationText = "<multiple values>";
          }
        }
        else {
          locationId = this.state.combinedData.locationId;
        }

        if (locationId) {
          locationText = this.getLocationString(locationId);
        }

        detailsElement = <div>
          <ButtonInputBox
            label="Location"
            value={locationText}
            placeholder="Required"
            onClick={() => this.setState({ showLocationSelector: true })}
            valid={locationId}
            required
          />
        </div>;
        break;

      case 2:

        if (selectedDeviceIds.length > 1) {
          detailsElement = <TopRowOptions description="You have selected multiple sensors. Select one to show its messages." />;
          break;
        }
  
        if (isEmpty(this.props.samples)) {
          detailsElement = <TopRowOptions description="No messages received from this sensor yet." />;
          break;
        }
        
        const sampleElements = this.props.samples.map(sample => {
          const date = `${moment(sample.datetime).format("HH:mm:ss - DD/MM/YY")}`;
          const text = sample.message;
          return (
            <TextBox
              key={sample.datetime}
              label={date}
              value={text}
              size={text.length > 300 ? "large" : "medium"}
            />
          );
        });

        detailsElement = <div>
           <TopRowOptions description="The last 10 messages received from this sensor." />
          {sampleElements}
        </div>
        break;

      case 3:

        if (selectedDeviceIds.length !== 1) {
          detailsElement = <div>
            <TopRowOptions description="You can only place one sensor at a time." />
          </div>;
          break;
        }

        // Get locationId
        let mapLocationId = null;

        // If combinedData has unset locationId
        if (isEmpty(this.state.combinedData.locationId)) {
          mapLocationId = get(firstDevice, "locationIds[0]", null);
        }
        else {
          mapLocationId = this.state.combinedData.locationId;
        }

        // Get yourLocation to render in map
        let locationFeature = null;
        if (isEmpty(this.state.combinedData.geoJsonFeature)) {
          if (firstDevice.geoJsonFeature) {
            locationFeature = firstDevice.geoJsonFeature;
          }
        }
        else {
          locationFeature = this.state.combinedData.geoJsonFeature;
        }

        if (this.props.location.currentLocationId === mapLocationId && this.props.location.id === mapLocationId) {
          detailsElement = <div>
            <div style={{ height: "400px", width: "100%", marginTop: "20px" }}>
              <InputPointMap
                company={this.props.selectedCompany}
                location={this.props.location}
                map={this.props.location.floorMap}
                bearing={0}
                mapMode={locationFeature ? "simple_select" : "draw_point"}
                point={locationFeature}
                onPointChanged={this.onLocationPointChanged}
              />
            </div>
          </div>;
        }
        else if (mapLocationId) {
          detailsElement = <div>
            <TopRowOptions description="Loading the location..." />
          </div>;
        }
        else {
          detailsElement = <div>
            <TopRowOptions description="This sensor has no location." />
          </div>;
        }

        break;
      default:
        return;
    }
    
    return (
      <div>
        <DetailsHeader
          title={title}
          isLoading={this.props.isSensorsLoading}
        />
        <SubNavigation links={tabBarLinks} selectedIndex={this.state.selectedIndex} onClick={this.selectedTab} slim />
        { detailsElement }
        <div style={{ paddingTop: "40px" }} />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    vortoIds: state.auth.vortoIds,
    vendors: state.auth.vendors,
    locationHierarchy: state.locations.hierarchy,
    locationBreadcrumbs: state.locations.breadcrumbs,
    selectedCompany: state.auth.selectedCompany,
    selectedDevices: state.selected.unregisteredSensors,
    currentDeviceId: state.unregisteredSensors.currentDeviceId,
    samples: state.unregisteredSensors.samples,
    draft: state.unregisteredSensors.draft,
    isSensorsLoading : state.loading.unregisteredSensors,
    location: state.location,
    isLoadingMap: state.loading[API.GET_FLOOR_MAP],
    isLoadingLocation: state.loading[API.GET_LOCATION],
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ 
    getUnregisteredSensor: unregisteredSensorActions.getUnregisteredSensor,
    updateUnregisteredSensorDraft: unregisteredSensorActions.updateUnregisteredSensorDraft,
    clearUnregisteredSensor: unregisteredSensorActions.clearUnregisteredSensor,
    getUnregisteredSensorSamples: unregisteredSensorActions.getUnregisteredSensorSamples,
    getLocation: locationActions.getLocation,
    getFloorMap: locationActions.getFloorMap,
    clearLocation: locationActions.clearLocation,
   }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(UnregisteredDetails);