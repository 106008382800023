import React, { Component } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { isEmpty } from "lodash";
import SubNavigation from "../../components/SubNavigation";
import Loader from "../../components/Loader";
import DetailsHeader from "../../components/DetailsHeader";
import AtomicSensorLocations from "./atomicSensorLocations";
import AtomicSensorData from "./atomicSensorData";
import * as sensorActions from "../../actions/sensors";
import * as selectedActions from "../../actions/selected";
import style from "./style.module.scss";

class AtomicSensor extends Component {

  constructor(props) {
    super(props);
    this.state = {
      id: null,
      type: null,
      displayName: "",
      locations: [],
      ignored: false,
    };

    this.onBackClick = this.onBackClick.bind(this);
    this.onEditNameSave = this.onEditNameSave.bind(this);
    this.onIgnoredChange = this.onIgnoredChange.bind(this);
  }

  static getDerivedStateFromProps(nextProps, prevState) {

    if (isEmpty(nextProps.atomicSensors) || nextProps.match.params.id === prevState.id) {
      return null;
    }

    const atomicSensor = nextProps.atomicSensors.find(s => s._id === nextProps.match.params.id);

    if (!isEmpty(atomicSensor)) {

      const newState = {
        id: atomicSensor._id,
        type: atomicSensor.type,
        displayName: atomicSensor.displayName || "",
        locations: atomicSensor.locations || [],
        ignored: atomicSensor.ignored || false
      };

      document.title = `BLDNG.ai - Sensor - ${atomicSensor.type}`;

      return newState;
    }
    
    const newState = {
      id: null,
      type: null,
      displayName: "",
      locations: [],
      ignored: false
    };

    document.title = `BLDNG.ai - Sensor`;

    return newState;
  }

  onBackClick() {
    this.props.history.push(`/companies/${this.props.match.params.companyId}/sensors/${this.props.match.params.sensorId}`);
  }

  onEditNameSave(name) {
    const body = { 
      displayName: name
    };

    this.props.updateAtomicSensor(this.props.sensorId, this.state.id, body);
  }

  onIgnoredChange() {
    this.setState(prevState => ({ ignored: !prevState.ignored }));
  }

  render() {
    if (isEmpty(this.props.match.params.id) || isEmpty(this.state.id)) {
      return null;
    }

    if (this.props.isLoading) {
      return <Loader fullScreen />;
    }

    const subNavigationLinks = [];
    subNavigationLinks.push({ pathname: `/companies/${this.props.match.params.companyId}/sensors/${this.props.match.params.sensorId}/atomic-sensors/${this.props.match.params.id}/data`, label: `Data` });
    subNavigationLinks.push({ pathname: `/companies/${this.props.match.params.companyId}/sensors/${this.props.match.params.sensorId}/atomic-sensors/${this.props.match.params.id}/locations`, label: `Locations` });
    
    return (
      <>
        <div className={style.scroll}>
          <DetailsHeader
            title={isEmpty(this.state.displayName) ? this.state.type : this.state.displayName}
            canUpdateName
            onNameSaveClicked={this.onEditNameSave}
          />
          <SubNavigation links={subNavigationLinks} slim />
          <Switch>
            <Redirect exact from="/companies/:companyId/sensors/:sensorId/atomic-sensors/:id" to={{ pathname: `/companies/${this.props.match.params.companyId}/sensors/${this.props.match.params.sensorId}/atomic-sensors/${this.props.match.params.id}/data` }} />
            <Route path="/companies/:companyId/sensors/:sensorId/atomic-sensors/:id/locations" children={(props) => <AtomicSensorLocations {...props} locations={this.state.locations} />} />
            <Route path="/companies/:companyId/sensors/:sensorId/atomic-sensors/:id/data" children={(props) => <AtomicSensorData {...props} type={this.state.type} onDeleteDataClicked={this.props.onDeleteDataClicked} />} />
          </Switch>
        </div>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    selectedCompany: state.auth.selectedCompany,
    atomicSensors: state.sensor.atomicSensors,
    isLoading: state.loading.sensor,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    selectAtomicSensor: selectedActions.selectAtomicSensor,
    updateAtomicSensor: sensorActions.updateAtomicSensor,
  }, dispatch);
}

export default connect(mapStateToProps,mapDispatchToProps)(AtomicSensor);