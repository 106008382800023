import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter,generatePath} from 'react-router-dom'
import Cookies from "universal-cookie";
import axios from "axios";
import { DASHBOARD_URL, COOKIE_PREFIX } from "../../env";

const cookies = new Cookies();

class Insights extends Component {

  constructor(props) {
    super(props);
    this.state = {
      src: null,
      dashboardURL: null,
    };
  }

  componentDidMount() {
    document.title = `BLDNG.ai - Insights`;
   
    this.setURL();

    window.addEventListener('message',this.onMessage)
  }

  componentWillUnmount(){
    window.removeEventListener('message',this.onMessage)
  }

  componentDidUpdate(prevProps) {
    if (prevProps.companyId !== this.props.companyId) {
      this.setURL(this.state.dashboardURL);
    }
    else if (prevProps.match.params.id !== this.props.match.params.id) {
      this.setURL();
    }
  }

  onMessage = (e) => {

    function postMessage(targetWindow, msg, targetOrigin) {
      targetWindow.postMessage(JSON.stringify(msg), targetOrigin);
    }

    function getData(data) {
      if (typeof data === 'string') {
        try {
          data = JSON.parse(data);
        } catch (e) {
          // Got junk in the message, ignore.
          data = {};
        }
      }
      return data;
    }

    const source = e.source;
    const origin = e.origin;
    const data = getData(e.data) || {};
    const action = data.action;
    const value = data.value;

    switch (action) {
      case 'reconnect': {
        new Promise( async(resolve,reject)=>{
      
          const cookiePrefix = await COOKIE_PREFIX();
          await axios.get(`/postauth`,{
            params:{
              silent:true
            },
            withCredentials:true
          }).catch(reject)
          postMessage(source, {
            action:'connected',
            data:{
              access_token:cookies.get(`${cookiePrefix}_access_token`),
            }
          }, origin)
          resolve()
        })
       
        break;
      }
      case 'viewDashboard': {
        const viewPath = `/companies/${this.props.match.params.companyId}/insights/view/${value}`;
        this.props.history.push(viewPath);
        break;
      }
      case 'editDashboard': {
        const editPath = `/companies/${this.props.match.params.companyId}/insights/edit/${value}`;
        this.props.history.push(editPath);
        break;
      }
      // DEPRECATED
      case 'setDashboard': {
        const path = generatePath(this.props.match.path,{
          companyId:this.props.companyId,
          id:value
        })
        this.props.history.push(path);
        break;
      }
      case "copy-to-clipboard": {
        const item = new window.ClipboardItem({ 'image/png': data.value });
        navigator.clipboard.write([item])  
        break;
      }
      default:
        break;
        //none
    }
  }

  setURL = async (url) => {
    if (!url) {
      url = await DASHBOARD_URL();
    }

    if (url) {
      let src = `${url}/companies/${this.props.companyId}/dashboards`;
      
      if (this.props.match.params.id) {
        if (this.props.match.path.includes("edit")) {
          src += `/${this.props.match.params.id}/builder`
        }
        else {
          src += `/${this.props.match.params.id}/view`
        }
      }

      this.setState({
        src: `${src}`,
        dashboardURL: url,
      });
    }
  }

  render() {
    if (!this.props.companyId || !this.state.src) {
      return null;
    }

    return <iframe title="insights" height="100%" width="100%" src={this.state.src} allow="clipboard-read; clipboard-write"/>;
  }
}

function mapStateToProps(state) {
  return {
    companyId: state.auth.selectedCompany
      ? state.auth.selectedCompany._id
      : null,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({}, dispatch);
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Insights))
