import React from "react";
import DatePicker from "react-datepicker";
import { setDefaultLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.min.css";
import enGB from 'date-fns/locale/en-GB';
import styled from "styled-components";

setDefaultLocale(enGB);

const DatepickerInputButton = React.forwardRef(function datepickerInputButton({ value, onClick }, ref) {
  return (
    <div ref={ref} >
      <Button
        type={"button"}
        onClick={(event) => { onClick(event); event.stopPropagation(); }}
        >
        <Label>{value}</Label>
      </Button>
    </div>
  );
});

const Datepicker = ({date, minDate, maxDate, onChange = () => {}, dateFormat = "dd/MM/yyyy", showTime = false, customInput = null}) => {
  
  const datePickerElement = (
    <DatePicker 
      selected={date}
      minDate={minDate}
      maxDate={maxDate}
      dateFormat={dateFormat}
      onChange={onChange}
      showTimeSelect={showTime}
      timeFormat="HH:mm"
      timeIntervals={1}
      timeInputLabel="Time"
      customInput={customInput ? customInput : <DatepickerInputButton />}
      popperModifiers={[
        {
          name: 'arrow',
          // options: { padding: 24 },
          options: {
            padding: ({ popper, reference, placement }) => ({
              right: Math.min(popper.width, reference.width) - 24,
            }),
          },
        },
      ]}
    />
  );

  // Only include OuterContainer if we're not using a custom input
  if (customInput !== null) {
    return datePickerElement;
  }
  
  return (
    <OuterContainer>
      {datePickerElement}
    </OuterContainer>
  );
};

export default Datepicker;

const OuterContainer = styled.div`
  width: 100px;
  display: block;
  background-color: #fff;
  border-radius: 4px;
  border-color: #ddd;
  border-width: 1px;
  border-style: solid;
  overflow: hidden;
  height: 33px;
`;

const Button = styled.button`
  display: block;
  background-color: #fff;
  padding-top: 0px;
  padding-bottom: 0px;
  padding-left: 0px;
  padding-right: 0px;
  border: 0;
  height: 33px;
  width: 100px;
  cursor: pointer;
`;

const Label = styled.span`
  display: block;
  position: relative;
  // margin-top: 6px;
  font-family: Source Sans Pro, Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-size: 15px;
  color: #444;
`;