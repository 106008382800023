import React from "react";
import styles from "./styles.module.scss";
import { Hidden } from "react-grid-system";

export class MainContainer extends React.Component { 
  render() {
    const { children } = this.props;

    return (
      <Hidden xs sm md>
        <div className={styles.mainContainer}>
          { children }
        </div>
      </Hidden>
    )
  }
}
