import React from "react";
import { Col, Row } from "react-grid-system";
import moment from "moment";
import SearchBox from "../../../../components/SearchBox";
import { isFinite } from "lodash";
import PropTypes from 'prop-types';
import { Filter } from "./Filter";

export class Filters extends React.Component { 
  static propTypes = {
    filters: PropTypes.shape({
      fromDate: PropTypes.string,
      toDate: PropTypes.string,
      noSamplesSinceDate: PropTypes.string,
      sensorTableSearchValue: PropTypes.string,
      sortOrder: PropTypes.string,
      sortBy: PropTypes.string,
      offset: PropTypes.number,
      batteryLevel: PropTypes.number,
      signalStrength: PropTypes.number
    }).isRequired,
    handleUpdatesToFilter: PropTypes.func.isRequired,
    handleSearchClear: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this._handleFromDateChange = this._handleFromDateChange.bind(this);
    this._handleToDateChange = this._handleToDateChange.bind(this);
    this._handleBatteryLevelChange = this._handleBatteryLevelChange.bind(this);
    this._handleSignalStrengthChange = this._handleSignalStrengthChange.bind(this);
    this._handleNoSamplesSinceChange = this._handleNoSamplesSinceChange.bind(this);
    this._handleSensorSearchChange = this._handleSensorSearchChange.bind(this);
  }

  _handleFromDateChange(e) {
    const { handleUpdatesToFilter } = this.props;
    handleUpdatesToFilter({
      ...this.props.filters,
      fromDate: e.target.value.length > 0 ? new Date(e.target.value).toISOString() : undefined,
      offset: 0,
    });
  }

  _handleToDateChange(e) {
    const { handleUpdatesToFilter } = this.props;
    handleUpdatesToFilter({
      ...this.props.filters,
      toDate: e.target.value.length > 0 ? new Date(e.target.value).toISOString() : undefined,
      offset: 0,
    });
  }

  _handleBatteryLevelChange(e) {
    const { handleUpdatesToFilter } = this.props;
    handleUpdatesToFilter({
      ...this.props.filters,
      batteryLevel: isFinite(parseInt(e.target.value)) ? parseInt(e.target.value) : undefined,
      offset: 0,
    });
  }

  _handleSignalStrengthChange(e) {
    const { handleUpdatesToFilter } = this.props;
    handleUpdatesToFilter({
      ...this.props.filters,
      signalStrength: isFinite(parseInt(e.target.value)) ? Math.floor(parseInt(e.target.value)) : undefined,
      offset: 0,
    });
  }

  _handleNoSamplesSinceChange(e) {
    const { handleUpdatesToFilter } = this.props;
    handleUpdatesToFilter({
      ...this.props.filters,
      noSamplesSinceDate: e.target.value.length > 0 ? new Date(e.target.value).toISOString() : undefined,
      offset: 0,
    });
  }

  _handleSensorSearchChange(value) {
    const { handleUpdatesToFilter } = this.props;
    handleUpdatesToFilter({
      ...this.props.filters,
      sensorTableSearchValue: value.length > 0 ? value : "",
      offset: 0,
    });
  }

  render() {
    const {
      fromDate,
      toDate,
      noSamplesSinceDate,
      sensorTableSearchValue,
      batteryLevel,
      signalStrength
    } = this.props.filters;

    return (
      <Row>
        <Col md={8} style={{ display: "flex", flexWrap: "wrap" }}>
          <Filter label="From date">
            <input 
              type="date" 
              onChange={this._handleFromDateChange} 
              value={fromDate ? moment(fromDate).format('YYYY-MM-DD') : ""}
            />
          </Filter>

          <Filter label="To date">
            <input 
              type="date" 
              onChange={this._handleToDateChange} 
              value={toDate ? moment(toDate).format('YYYY-MM-DD') : ""}
              max={new Date().toISOString().split("T")[0]}
            />
          </Filter>

          <Filter label="Max battery level">
            <input 
              onChange={this._handleBatteryLevelChange}
              value={batteryLevel ?? "" }
            />
          </Filter>

          <Filter label="Max signal strength">
            <input 
              onChange={this._handleSignalStrengthChange}
              value={signalStrength ?? "" }
            />
          </Filter>

          <Filter label="No samples since">
            <input 
              type="date" 
              onChange={this._handleNoSamplesSinceChange}
              value={noSamplesSinceDate ? moment(noSamplesSinceDate).format('YYYY-MM-DD') : ""}
              max={new Date().toISOString().split("T")[0]}
            />
          </Filter>
        </Col>

        <Col md={4}>
          <SearchBox 
            value={sensorTableSearchValue}
            onSearchChanged={this._handleSensorSearchChange}
            onClear={this.props.handleSearchClear}
          />
        </Col>
      </Row>
    )
  }
}