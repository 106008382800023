import { toast } from "react-toastify";
import * as types from "../ApiTypes";

const initialState = {
  id: null,
  index: null,
  title: null,
  description: null,
  section: {},
  microapps: [],
  userGroups: [],
  locations: [],
  canEdit: false,
  showInGlobalView: false,
  draft: {}
};

export default function contentReducer(state = initialState, action) {
  switch (action.type) {
    
    case types.REQ_DATA: {
      if (action.fetchType === types.GET_CONTENT) {
        return initialState;
      }

      if (action.fetchType === types.CREATE_CONTENT) {
        return initialState;
      }
      
      return state;
    }

    case types.RECV_DATA: {
      if (action.fetchType === types.GET_CONTENT) {
        return {
          ...initialState,
          id: action.payload._id,
          index: action.payload.index,
          title: action.payload.title,
          description: action.payload.description,
          section: action.payload.section ?? {},
          microapps: action.payload.microapps ?? [],
          userGroups: action.payload.usergroups ?? [],
          locations: action.payload.locations ?? [],
          showInGlobalView: action.payload.showInGlobalView ?? false,
          canEdit: action.payload.canEdit
        };
      }
      
      if (action.fetchType === types.CREATE_CONTENT) {
        toast.success("Content created");
        return {
          ...initialState,
          id: action.payload.id,
          title: action.payload.title,
          description: action.payload.description,
          section: action.payload.section ?? {},
          microapps: action.payload.microapps ?? [],
          canEdit: action.payload.canEdit
        };
      }

      if (action.fetchType === types.UPDATE_CONTENT) {
        toast.success("Content updated");
        return state;
      }

      if (action.fetchType === types.DELETE_CONTENT) {
        toast.success("Content deleted");
        return initialState;
      }

      if (action.fetchType === types.SEND_NOTIFICATION) {
        toast.success("Notification sent");
        return state;
      }
      
      return state;
    }

    case types.RECV_ERROR: {
      if (action.fetchType === types.GET_CONTENT) {
        toast.error("Could not get content");
        return initialState;
      }

      if (action.fetchType === types.CREATE_CONTENT) {
        toast.error("Could not create content");
        return state;
      }

      if (action.fetchType === types.UPDATE_CONTENT) {
        toast.error("Could not save content");
        return state;
      }

      if (action.fetchType === types.DELETE_CONTENT) {
        toast.error("Could not delete content");
        return state;
      }

      if (action.fetchType === types.SEND_NOTIFICATION) {
        toast.error("Could not send notification");
        return state;
      }
      
      return state;
    }

    case types.DRAFT_CONTENT_CHANGE: {

      const newDraft = { 
        showInGlobalView: state.showInGlobalView, 
        index: state.index, 
        ...state.draft, 
        ...action.payload 
      };
      var isDifferent = false;
      Object.keys(newDraft).forEach(key => {
        if (state[key] !== newDraft[key]) {
          isDifferent = true;
        }
      });

      if (isDifferent) {
        return {...state, draft: newDraft };
      }

      return {...state, draft: {} };
    }

    case types.CLEAR_DATA, types.CLEAR_CONTENT: {
      return initialState;
    }

    case types.CLEAR_SELECTION: {
      return {...state, draft: {} };
    }

    default: {
      return state;
    }
  }
}
