import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Col, Row } from "react-grid-system";
import { Audio } from "react-loader-spinner";
import moment from "moment";
import { get, isEmpty } from "lodash";
import Button from "../../../components/Button";
import Table from "../../../components/Table";
import * as jobActions from "../../../actions/jobs";
import style from "./style.module.scss";

class JobModal extends Component {

  constructor(props) {
    super(props);
    this.onRowClick = this.onRowClick.bind(this);
  }

  onRowClick(column, rowInfo) {
    return {
      onClick: e => {
        if (rowInfo.original.propertyName === "location") {
          this.props.setJob({});
          this.props.history.push(`/companies/${this.props.match.params.companyId}/locations/${rowInfo.original.id}/locations`);
        }
        else if (rowInfo.original.propertyName === "sensor") {
          this.props.setJob({});
          this.props.history.push(`/companies/${this.props.match.params.companyId}/sensors/${rowInfo.original.id}`);
        }
        else if (rowInfo.original.propertyName === "gateway") {
          this.props.setJob({});
          this.props.history.push(`/companies/${this.props.match.params.companyId}/gateways/${rowInfo.original.id}`);
        }
        // else {
        //   window.Clipboard.copy(rowInfo.original.propertyValue)
        // }
      },
      style: {
        cursor: "pointer"
      }
    }
  }

  render() {
    const { isLoading, onHide, primaryBtn, secondaryBtn, tertiaryBtn, title, job } = this.props;

    var elements = [];
    elements.push({ propertyName: "createdAt", propertyValue: !isEmpty(job.createdAt) ? `${moment(job.createdAt).format("HH:mm - DD/MM/YY")}` : "?" });
    elements.push({ propertyName: "updatedAt", propertyValue: !isEmpty(job.updatedAt) ? `${moment(job.updatedAt).format("HH:mm - DD/MM/YY")}` : "?" });
    elements.push({ propertyName: "completedAt", propertyValue: !isEmpty(job.completedAt) ? `${moment(job.completedAt).format("HH:mm - DD/MM/YY")}` : "?" });
    elements.push({ propertyName: "scheduledForRetry", propertyValue: `${job.scheduledForRetry}` });

    const location = get(job, "locations[0]", null);
    if (location) {
      elements.push({ propertyName: "location", propertyValue: location.name, id: location._id });
    }

    const sensor = get(job, "sensors[0]", null);
    if (sensor) {
      elements.push({ propertyName: "sensor", propertyValue: sensor.name, id: sensor._id });
    }

    const gateway = get(job, "gateways[0]", null);
    if (gateway) {
      elements.push({ propertyName: "gateway", propertyValue: gateway.name, id: gateway._id });
    }

    if (job.user) {
      elements.push({ propertyName: "user", propertyValue: job.user.name ?? job.user.email, id: job.user._id });
    }

    const tableElement = (
      <Table
        data={elements}
        noDataText={isLoading ? "" : "No data found"}
        columns={[
          {
            id: "propertyName",
            header: "",
            accessorKey: "propertyName",
            sortable: false,
            cell: ({ row }) => (<span>{row.original.propertyName}</span>)
          },
          {
            id: "propertyValue",
            header: "",
            accessorKey: "propertyValue",
            sortable: false,
            cell: ({ row }) => (<span>{row.original.propertyValue}</span>)
          },
          {
            id: "arrow",
            header: "",
            accessorKey: 'id',
            sortable: false,
            className: "pull-right",
            width: 60,
            cell: ({ row }) => {
              if (row.original.propertyName === "location" ||
                  row.original.propertyName === "sensor" ||
                  row.original.propertyName === "gateway") {
                return (<div className="arrow" />);
              }
              return (<div></div>)
            }
          }
        ]}
        getTdProps={this.onRowClick}
        className="setMaxHeigth -row-clickable -highlight"
        loading={isLoading}
      />
    );

    return (
      <div className={style.wrapper}>
      <div className={style.overlay} onClick={onHide} role="presentation" />
      <div className={style.modal}>
        <h1 className={style.title}>{job.type}</h1>
        
        { tableElement }
        {isLoading && <Audio color="#ccc" height={"75px"} width={"75px"} />}
        {
          primaryBtn && secondaryBtn && tertiaryBtn && (
            <Row>
              <Col xs={12} sm={8} md={4} lg={4} offset={{ sm: 2, md: 0 }}>
                {tertiaryBtn && <Button color="white" {...tertiaryBtn} />}
              </Col>
              <Col xs={12} sm={8} md={4} lg={4} offset={{ sm: 2, md: 0 }}>
                {secondaryBtn && <Button {...secondaryBtn} />}
              </Col>
              <Col xs={12} sm={8} md={4} lg={4} offset={{ sm: 2, md: 0 }}>
                {primaryBtn && <Button {...primaryBtn} />}
              </Col>
            </Row>
          ) 
        }
        {
          primaryBtn && secondaryBtn && !tertiaryBtn && (
            <Row>
              <Col xs={12} sm={8} md={6} lg={6} offset={{ sm: 2, md: 0 }}>
                {secondaryBtn && <Button color="white" {...secondaryBtn} />}
              </Col>
              <Col xs={12} sm={8} md={6} lg={6} offset={{ sm: 2, md: 0 }}>
                {primaryBtn && <Button {...primaryBtn} />}
              </Col>
            </Row>
          )
        }
        {
          primaryBtn && !secondaryBtn && !tertiaryBtn && (
            <Row>
              <Col xs={12} sm={8} md={6} lg={6} offset={{ sm: 2, md: 3 }}>
                {primaryBtn && <Button {...primaryBtn} />}
              </Col>
            </Row>
          )
        }
      </div>
    </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    job: state.job
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    getJob: jobActions.getJob,
    setJob: jobActions.setJob
   }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(JobModal);