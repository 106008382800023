import axios from "axios";
import Cookies from "universal-cookie";
import * as types from "../ApiTypes";
import * as actions from "./sharedFunction";
import { API_URL, COOKIE_PREFIX, WS_URL } from "../env";
import { getCompanyId } from "../helpers";

const cookies = new Cookies();

export const getWaitingScreenDevices = (queryParams) => async (dispatch) => {
  dispatch(actions.requestData(types.GET_WAITING_SCREEN_DEVICES));

  const apiUrl = await API_URL();
  const companyId = getCompanyId();
  const cookiePrefix = await COOKIE_PREFIX();
  const accessToken = cookies.get(`${cookiePrefix}_access_token`);
    
  axios.get(`${apiUrl}companies/${companyId}/pendingdevices`, await actions.getAxiosConfig(queryParams))
    .then((response) => {
      dispatch(actions.receiveData(response.data, types.GET_WAITING_SCREEN_DEVICES));
    })
    .catch((error) => {
      if (error.response && error.response.status === 401) {
        actions.sessionTokenExpired(cookiePrefix, accessToken);
      } else {
        dispatch(actions.receiveError(error, types.GET_WAITING_SCREEN_DEVICES));
      }
    });
};

export const getScreenGroups = (queryParams) => async (dispatch) => {
  dispatch(actions.requestData(types.GET_SCREEN_GROUPS));

  const apiUrl = await API_URL();
  const companyId = getCompanyId();
  const cookiePrefix = await COOKIE_PREFIX();
  const accessToken = cookies.get(`${cookiePrefix}_access_token`);
  
  axios.get(`${apiUrl}companies/${companyId}/screengroups`, await actions.getAxiosConfig(queryParams))
    .then((response) => {
      dispatch(actions.receiveData(response.data, types.GET_SCREEN_GROUPS));
    })
    .catch((error) => {
      if (error.response && error.response.status === 401) {
        actions.sessionTokenExpired(cookiePrefix, accessToken);
      } else {
        dispatch(actions.receiveError(error, types.GET_SCREEN_GROUPS));
      }
    });
};

export const getScreenGroupDetails = (id) => async (dispatch) => {
  dispatch(actions.requestData(types.GET_SCREEN_GROUP_DETAILS, { id }));

  const apiUrl = await API_URL();
  const companyId = getCompanyId();
  const cookiePrefix = await COOKIE_PREFIX();
  const accessToken = cookies.get(`${cookiePrefix}_access_token`);
  
  axios.get(`${apiUrl}companies/${companyId}/screengroups/${id}`, await actions.getAxiosConfig())
    .then((response) => {
      dispatch(actions.receiveData(response.data, types.GET_SCREEN_GROUP_DETAILS));
    })
    .catch((error) => {
      if (error.response && error.response.status === 401) {
        actions.sessionTokenExpired(cookiePrefix, accessToken);
      } else {
        dispatch(actions.receiveError(error, types.GET_SCREEN_GROUP_DETAILS));
      }
    });
};

export const getScreenGroup = (id) => async (dispatch) => {
  dispatch(actions.requestData(types.GET_SCREEN_GROUP, { id }));

  const apiUrl = await API_URL();
  const companyId = getCompanyId();
  const cookiePrefix = await COOKIE_PREFIX();
  const accessToken = cookies.get(`${cookiePrefix}_access_token`);
  
  axios.get(`${apiUrl}companies/${companyId}/screengroups/${id}`, await actions.getAxiosConfig())
    .then((response) => {
      dispatch(actions.receiveData(response.data, types.GET_SCREEN_GROUP, { inDev: cookiePrefix.includes("dev") }));
    })
    .catch((error) => {
      if (error.response && error.response.status === 401) {
        actions.sessionTokenExpired(cookiePrefix, accessToken);
      } else {
        dispatch(actions.receiveError(error, types.GET_SCREEN_GROUP));
      }
    });
};

export const createScreenGroup = (config, redirect) => async (dispatch) => {
  dispatch(actions.postData(types.CREATE_SCREEN_GROUP));
  
  const apiUrl = await API_URL();
  const companyId = getCompanyId();
  const cookiePrefix = await COOKIE_PREFIX();
  const accessToken = cookies.get(`${cookiePrefix}_access_token`);
  const axiosConfig = await actions.getAxiosConfig();

  axios.post(`${apiUrl}companies/${companyId}/screengroups`, config, axiosConfig)
    .then((response) => {
      dispatch(actions.receiveData(response.data, types.CREATE_SCREEN_GROUP));
      dispatch(getScreenGroups());

      if (response.data && response.data.id) {
        if (redirect) {
          redirect(`/companies/${companyId}/screens/edit/${response.data.id}`);
        }
      }
    })
    .catch((error) => {
      if (error.response && error.response.status === 401) {
        actions.sessionTokenExpired(cookiePrefix, accessToken);
      } else {
        dispatch(actions.receiveError(error, types.CREATE_SCREEN_GROUP));
      }
    });
};

export const updateScreenGroup = (id, config, callback) => async (dispatch) => {
  dispatch(actions.postData(types.UPDATE_SCREEN_GROUP));

  const apiUrl = await API_URL();
  const companyId = getCompanyId();
  const cookiePrefix = await COOKIE_PREFIX();
  const accessToken = cookies.get(`${cookiePrefix}_access_token`);
  
  axios.patch(`${apiUrl}companies/${companyId}/screengroups/${id}`, config, await actions.getAxiosConfig())
    .then((response) => {
      dispatch(actions.receiveData(response.data, types.UPDATE_SCREEN_GROUP));
      if (callback) {
        callback();
      }
      dispatch(getScreenGroup(id));
    })
    .catch((error) => {
      if (error.response && error.response.status === 401) {
        actions.sessionTokenExpired(cookiePrefix, accessToken);
      } else {
        dispatch(actions.receiveError(error, types.UPDATE_SCREEN_GROUP));
      }
    });
};

export const deleteScreenGroup = (id, redirect) => async (dispatch) => {
  dispatch(actions.postData(types.DELETE_SCREEN_GROUP));

  const apiUrl = await API_URL();
  const companyId = getCompanyId();
  const cookiePrefix = await COOKIE_PREFIX();
  const accessToken = cookies.get(`${cookiePrefix}_access_token`);

  axios.delete(`${apiUrl}companies/${companyId}/screengroups/${id}`, await actions.getAxiosConfig())
    .then((response) => {
      dispatch(actions.receiveData(response.data, types.DELETE_SCREEN_GROUP));
      dispatch(getScreenGroups());
      if (redirect) {
        redirect(`/companies/${companyId}/screens`);
      }
    })
    .catch((error) => {
      if (error.response && error.response.status === 401) {
        actions.sessionTokenExpired(cookiePrefix, accessToken);
      } else {
        dispatch(actions.receiveError(error, types.DELETE_SCREEN_GROUP));
      }
    });
};

export const getScreenDevices = (screenId) => async (dispatch) => {
  dispatch(actions.requestData(types.GET_SCREEN_DEVICES));

  const apiUrl = await API_URL();
  const companyId = getCompanyId();
  const cookiePrefix = await COOKIE_PREFIX();
  const accessToken = cookies.get(`${cookiePrefix}_access_token`);
  
  axios.get(`${apiUrl}companies/${companyId}/screengroups/${screenId}/devices`, await actions.getAxiosConfig({ limit: 100 }))
    .then((response) => {
      dispatch(actions.receiveData(response.data, types.GET_SCREEN_DEVICES));
    })
    .catch((error) => {
      if (error.response && error.response.status === 401) {
        actions.sessionTokenExpired(cookiePrefix, accessToken);
      } else {
        dispatch(actions.receiveError(error, types.GET_SCREEN_DEVICES));
      }
    });
};

export const getScreenDevice = (screenGroupId, deviceId) => async (dispatch) => {
  dispatch(actions.requestData(types.GET_SCREEN_DEVICE, { id: deviceId }));

  const apiUrl = await API_URL();
  const companyId = getCompanyId();
  const cookiePrefix = await COOKIE_PREFIX();
  const accessToken = cookies.get(`${cookiePrefix}_access_token`);

  axios.get(`${apiUrl}companies/${companyId}/screengroups/${screenGroupId}/devices/${deviceId}`, await actions.getAxiosConfig())
    .then((response) => {
      dispatch(actions.receiveData(response.data, types.GET_SCREEN_DEVICE));
    })
    .catch((error) => {
      if (error.response && error.response.status === 401) {
        actions.sessionTokenExpired(cookiePrefix, accessToken);
      } else {
        dispatch(actions.receiveError(error, types.GET_SCREEN_DEVICE));
      }
    });
}

export const updateScreenDevice = (screenGroupId, deviceId, body, callback) => async (dispatch) => {
  dispatch(actions.postData(types.UPDATE_SCREEN_DEVICE));

  const apiUrl = await API_URL();
  const companyId = getCompanyId();
  const cookiePrefix = await COOKIE_PREFIX();
  const accessToken = cookies.get(`${cookiePrefix}_access_token`);

  axios.patch(`${apiUrl}companies/${companyId}/screengroups/${screenGroupId}/devices/${deviceId}`, body, await actions.getAxiosConfig())
    .then((response) => {
      dispatch(actions.receiveData(response.data, types.UPDATE_SCREEN_DEVICE, { id: deviceId, ...body }));
      if (callback) {
        callback();
      }
    })
    .catch((error) => {
      if (error.response && error.response.status === 401) {
        actions.sessionTokenExpired(cookiePrefix, accessToken);
      } else {
        dispatch(actions.receiveError(error, types.UPDATE_SCREEN_DEVICE));
      }
    });
};

export const deleteScreenDevice = (screenGroupId, deviceId, callback) => async (dispatch) => {
  dispatch(actions.postData(types.DELETE_SCREEN_DEVICE));

  const apiUrl = await API_URL();
  const companyId = getCompanyId();
  const cookiePrefix = await COOKIE_PREFIX();
  const accessToken = cookies.get(`${cookiePrefix}_access_token`);

  axios.delete(`${apiUrl}companies/${companyId}/screengroups/${screenGroupId}/devices/${deviceId}`, await actions.getAxiosConfig())
    .then((response) => {
      dispatch(actions.receiveData(response.data, types.DELETE_SCREEN_DEVICE, { id: deviceId }));
      if (callback) {
        callback();
      }
    })
    .catch((error) => {
      if (error.response && error.response.status === 401) {
        actions.sessionTokenExpired(cookiePrefix, accessToken);
      } else {
        dispatch(actions.receiveError(error, types.DELETE_SCREEN_DEVICE));
      }
    });
};

export const getScreenLinks = (screenGroupId) => async (dispatch) => {
  dispatch(actions.requestData(types.GET_SCREEN_LINKS));

  const apiUrl = await API_URL();
  const companyId = getCompanyId();
  const cookiePrefix = await COOKIE_PREFIX();
  const accessToken = cookies.get(`${cookiePrefix}_access_token`);
  
  axios.get(`${apiUrl}companies/${companyId}/screengroups/${screenGroupId}/links`, await actions.getAxiosConfig())
    .then((response) => {
      dispatch(actions.receiveData(response.data, types.GET_SCREEN_LINKS));
    })
    .catch((error) => {
      if (error.response && error.response.status === 401) {
        actions.sessionTokenExpired(cookiePrefix, accessToken);
      } else {
        dispatch(actions.receiveError(error, types.GET_SCREEN_LINKS));
      }
    });
}

export const getScreenLink = (screenGroupId, linkId) => async (dispatch) => {
  dispatch(actions.requestData(types.GET_SCREEN_LINK, { id: linkId }));

  const apiUrl = await API_URL();
  const companyId = getCompanyId();
  const cookiePrefix = await COOKIE_PREFIX();
  const accessToken = cookies.get(`${cookiePrefix}_access_token`);

  axios.get(`${apiUrl}companies/${companyId}/screengroups/${screenGroupId}/links/${linkId}`, await actions.getAxiosConfig())
    .then((response) => {
      dispatch(actions.receiveData(response.data, types.GET_SCREEN_LINK));
    })
    .catch((error) => {
      if (error.response && error.response.status === 401) {
        actions.sessionTokenExpired(cookiePrefix, accessToken);
      } else {
        dispatch(actions.receiveError(error, types.GET_SCREEN_LINK));
      }
    });
}

export const createScreenLink = (screenGroupId, link, callback) => async (dispatch) => {
  dispatch(actions.postData(types.CREATE_SCREEN_LINK));

  const apiUrl = await API_URL();
  const companyId = getCompanyId();
  const cookiePrefix = await COOKIE_PREFIX();
  const accessToken = cookies.get(`${cookiePrefix}_access_token`);

  axios.post(`${apiUrl}companies/${companyId}/screengroups/${screenGroupId}/links`, link, await actions.getAxiosConfig())
    .then((response) => {
      dispatch(actions.receiveData(response.data, types.CREATE_SCREEN_LINK));
      // dispatch(getScreenLinks(screenGroupId));
      if (callback) {
        callback(response.data.id);
      }
    })
    .catch((error) => {
      if (error.response && error.response.status === 401) {
        actions.sessionTokenExpired(cookiePrefix, accessToken);
      } else {
        dispatch(actions.receiveError(error, types.CREATE_SCREEN_LINK));
      }
    });
}

export const updateScreenLink = (screenGroupId, linkId, link) => async (dispatch) => {
  dispatch(actions.postData(types.UPDATE_SCREEN_LINK));

  const apiUrl = await API_URL();
  const companyId = getCompanyId();
  const cookiePrefix = await COOKIE_PREFIX();
  const accessToken = cookies.get(`${cookiePrefix}_access_token`);

  axios.patch(`${apiUrl}companies/${companyId}/screengroups/${screenGroupId}/links/${linkId}`, link, await actions.getAxiosConfig())
    .then((response) => {
      dispatch(actions.receiveData(response.data, types.UPDATE_SCREEN_LINK, { id: linkId }));
      dispatch(getScreenLink(screenGroupId, linkId));
    })
    .catch((error) => {
      if (error.response && error.response.status === 401) {
        actions.sessionTokenExpired(cookiePrefix, accessToken);
      } else {
        dispatch(actions.receiveError(error, types.UPDATE_SCREEN_LINK));
      }
    });
}

export const deleteScreenLink = (screenGroupId, linkId, callback) => async (dispatch) => {
  dispatch(actions.postData(types.DELETE_SCREEN_LINK));

  const apiUrl = await API_URL();
  const companyId = getCompanyId();
  const cookiePrefix = await COOKIE_PREFIX();
  const accessToken = cookies.get(`${cookiePrefix}_access_token`);

  axios.delete(`${apiUrl}companies/${companyId}/screengroups/${screenGroupId}/links/${linkId}`, await actions.getAxiosConfig())
    .then((response) => {
      dispatch(actions.receiveData(response.data, types.DELETE_SCREEN_LINK));
      if (callback) {
        callback();
      }
    })
    .catch((error) => {
      if (error.response && error.response.status === 401) {
        actions.sessionTokenExpired(cookiePrefix, accessToken);
      } else {
        dispatch(actions.receiveError(error, types.DELETE_SCREEN_LINK));
      }
    });
}

export const updateScreenGroupForm = (form) => async (dispatch) => {
  dispatch(actions.receiveData(form, types.UPDATE_SCREEN_GROUP_FORM));
};

export const resetScreenGroupForm = () => async (dispatch) => {
  dispatch({ type: types.RESET_SCREEN_GROUP_FORM });
};

export const deselectScreenGroup = () => async (dispatch) => {
  dispatch({ type: types.DESELECT_SCREEN_GROUP });
}

export const clearScreenData = () => async (dispatch) => {
  dispatch({ type: types.CLEAR_SCREEN_GROUP_DATA });
}

export const forceScreenRestart = (screenGroupId, deviceId, linkId) => async (dispatch) => {

  // curl -v -XPOST --location 'https://ws.dev.bldng.ai/restart-screens'
  const wsAPI = await WS_URL();
  const companyId = getCompanyId();
  const cookiePrefix = await COOKIE_PREFIX();
  const accessToken = cookies.get(`${cookiePrefix}_access_token`);

  const body = { companyId, screenGroupId };
  
  if (deviceId) {
    body.deviceId = deviceId;
  }
  else if (linkId) {
    body.linkId = linkId;
  }

  axios.post(`${wsAPI}restart-screens`, body, await actions.getAxiosConfig())
    .then((response) => {
      console.log("forceReloadOfScreens response", response);
      dispatch(actions.receiveData(response.data, types.FORCE_SCREEN_RESTART));
    })
    .catch((error) => {
      console.log("forceReloadOfScreens error", error);
      if (error.response && error.response.status === 401) {
        actions.sessionTokenExpired(cookiePrefix, accessToken);
      } 
      dispatch(actions.receiveError(error, types.FORCE_SCREEN_RESTART));
    });
}