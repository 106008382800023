import get from "lodash/get";
import { toast } from "react-toastify";
import * as types from "../ApiTypes";

const initialState = {
  data: {
    count: 0,
    tags: [],
    limit: 20
  },
  tag: {}
};

export default function senorTagsReducer(state = initialState, action) {

  switch (action.type) {

    case types.REQ_DATA: {

      if (action.fetchType === types.GET_SENSOR_TAGS) {
        return {...state, data: initialState.data };
      }

      if (action.fetchType === types.GET_SENSOR_TAG) {
        return {...state, tag: {} };
      }
      
      return state;
    }

    case types.RECV_DATA: {

      if (action.fetchType === types.GET_SENSOR_TAGS) {
        return {...state, data: action.payload };
      }

      if (action.fetchType === types.GET_SENSOR_TAG) {
        return {...state, tag: action.payload };
      }

      if (action.fetchType === types.CREATE_SENSOR_TAG) {
        toast.success("Sensor tag created");
        return state;
      }

      if (action.fetchType === types.UPDATE_SENSOR_TAG) {
        toast.success("Sensor tag updated");
        return {...state, tag: {} };
      }
      
      return state;
    }

    case types.RECV_ERROR: {

      const statusCode = get(action, "payload.response.status", "Error");
      const message = get(action, "payload.response.message", "Error");

      if (action.fetchType === types.GET_SENSOR_TAGS) {
        toast.error(`${statusCode}: Could not get tags`);
        return initialState;
      }

      if (action.fetchType === types.CREATE_SENSOR_TAG) {
        if (message) {
          toast.error(`${statusCode}: ${message}`);
        }
        else {
          toast.error(`${statusCode}: Could not create tag`);
        }
        return initialState;
      }
      
      return state;
    }

    case types.CLEAR_DATA: {
      return initialState;
    }
    
    default: {
      return state;
    }
  }
}
