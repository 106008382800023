import React, { Component } from "react";
import { Container, Col, Row } from "react-grid-system";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getParameterByName } from "../../helpers";
import Freetext from "../../components/Freetext";
import OptionFooter from "../../components/OptionFooter";
import Table from "../../components/Table";
import * as insightActions from "../../actions/insight";
import * as displayActions from "../../actions/display";
import style from "./style.module.scss";

class DisplayInsightSelect extends Component {

  constructor(props) {
    super(props);
    this.state = {
      mode: props.match.params.mode,
      displayId: getParameterByName("code"),
      searchText: undefined,
      offset: 0,
      limit: 20
    };
    this.onCancel = this.onCancel.bind(this);
    this.fetchData = this.fetchData.bind(this);
    this.onSearchClick = this.onSearchClick.bind(this);
    this.onSearchChange = this.onSearchChange.bind(this);
    this.onRowClick = this.onRowClick.bind(this);
    this.slimTableElement = this.slimTableElement.bind(this);
  }

  componentDidMount() {
    this.fetchData();
  }

  onCancel() {
    this.props.history.push(`/companies/${this.props.match.params.companyId}/displays`);
  }

  onSearchClick(searchText) {
    this.props.getInsights({ search: searchText });
  }

  onSearchChange(event) {
    this.setState({ searchText: event.target.value === '' ? undefined : event.target.value });
  }

  onRowClick(column, rowInfo) {
    return {
      onClick: e => {
        if (column.name !== 'isSelected' && rowInfo !== undefined) {
          const form = { ...this.props.form };
          form.dashboardId = rowInfo.original.reportId;
          this.props.updateDisplayForm(form);
          this.props.history.push(`/companies/${this.props.match.params.companyId}/displays/${this.state.mode}/${this.state.displayId}`);
        }
      },
      style: {
        cursor: "pointer"
      }
    }
  }

  fetchData() {
    const queryParams = {
      search: this.state.searchText,
      offset: this.state.offset,
      limit: this.state.limit
    };

    this.props.getInsights(queryParams);
  }

  slimTableElement() {
    return (
      <Table
        data={this.props.data.data}
        offset={this.state.offset}
        limit={this.state.limit}
        count={this.props.data.count}
        onOffsetChange={offset => this.setState({ offset }, this.fetchData)}
        noDataText={this.props.isLoading ? "" : "No insight reports found"}
        columns={[
          {
            header: "Name",
            accessorKey: "title",
          },
          {
            header: "ID",
            accessorKey: "reportId",
            sortable: false,
          },
          {
            id: "arrow",
            header: "",
            accessorKey: 'properties',
            sortable: false,
            className: "pull-right",
            width: 60,
            cell: () => (<div className="arrow" />)
          }
        ]}
        getTdProps={this.onRowClick}
        className="-row-clickable setMaxHeigth -highlight"
        loading={this.props.isLoading}
      />
    );
  }

  render() {
    // console.log("DisplayLocationSelect.render()", this.props);

    return (
      <div className={style.outerEditScroll}>
        <Container className={style.editContainer}>
          <Row className={style.topRow}>
            <Col>
              <Freetext header="Select insight" content="Select the insight you want to show. Remember that the insight will reset every hour (as part of the reauthentication)." />
            </Col>
          </Row>
          { this.slimTableElement() }
          <div style={{ paddingTop: "40px" }} />
        </Container>
        <OptionFooter cancel={this.onCancel} />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    form: state.displays.form,
    data: state.insights.data,
    isLoading: state.loading.insights
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ 
    getInsights: insightActions.getInsights,
    updateDisplayForm: displayActions.updateDisplayForm,
    resetDisplayForm: displayActions.resetDisplayForm
  }, dispatch);
}

export default connect(mapStateToProps,mapDispatchToProps)(DisplayInsightSelect);
