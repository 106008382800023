import { toast } from "react-toastify";
import isEmpty from "lodash/isEmpty";
import * as types from "../ApiTypes";

const initialState = {
  data: {
    count: 0,
    results: [],
    limit: 20
  },
};

export default function serviceAccountsReducer(state = initialState, action) {

  switch (action.type) {
    
    case types.REQ_DATA: {
      if (action.fetchType === types.GET_SERVICE_ACCOUNTS) {
        return state;
      }
      
      return state;
    }

    case types.RECV_DATA: {
      if (action.fetchType === types.GET_SERVICE_ACCOUNTS) {
        return {
          ...state,
          data: action.payload
        }
      }

      return state;
    }

    case types.RECV_ERROR: {
      if (action.fetchType === types.GET_SERVICE_ACCOUNTS) {
        toast.error("Could not get service accounts");
        return {...state, data: [] };
      }

      return state;
    }

    default: {
      return state;
    }
  }
}
