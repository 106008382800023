import React from "react";
import styled from "styled-components";
import isFinite from "lodash/isFinite";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUp, faArrowDown } from "@fortawesome/free-solid-svg-icons";
import Loader from "../../../components/Loader";
import Info from "../../../components/Info";

interface StatisticsProps {
  locationId: string;
  title: string;
  subtitle: string;
  avgPercentage: number;
  avgPercentageChange: number;
  peakPercentage: number;
  peakPercentageChange: number;
  isLoading: boolean;
  description?: string;
}

const Statistics: React.FC<StatisticsProps> = ({ locationId, title, subtitle, avgPercentage, avgPercentageChange, peakPercentage, peakPercentageChange, isLoading, description }) => {

  let avgElement;
  if (avgPercentageChange > 0) {
    avgElement = <FontAwesomeIcon icon={faArrowUp} color="green" fontSize={14} transform={{ rotate: 45 }} style={{ marginRight: "3px" }} />;
  } 
  else if (avgPercentageChange < 0) {
    avgElement = <FontAwesomeIcon icon={faArrowDown} color="#CA4C4C" fontSize={14} transform={{ rotate: -45 }} style={{ marginRight: "3px" }} />;
  }
  else if (peakPercentageChange === 0) {
    avgElement = <FontAwesomeIcon icon={faArrowUp} color="#888888" fontSize={14} transform={{ rotate: 45 }} style={{ marginRight: "3px" }} />;
  }

  let peakElement;
  if (peakPercentageChange > 0) {
    peakElement = <FontAwesomeIcon icon={faArrowUp} color="green" fontSize={14} transform={{ rotate: 45 }} style={{ marginRight: "3px" }} />;
  }
  else if (peakPercentageChange < 0) {
    peakElement = <FontAwesomeIcon icon={faArrowDown} color="#CA4C4C" fontSize={14} transform={{ rotate: -45 }} style={{ marginRight: "3px" }} />;
  }
  else if (peakPercentageChange === 0) {
    peakElement = <FontAwesomeIcon icon={faArrowUp} color="#888888" fontSize={14} transform={{ rotate: 45 }} style={{ marginRight: "3px" }} />;
  }

  return (
    <Container>
      <InnerContainer>
        <Header>
          <Title>
            { title }
          </Title>
          <Subtitle>
            ({ subtitle })
          </Subtitle>
        </Header>
        <GraphContainer>
          { 
            isLoading ? (
              <StatusMessage><Loader size={"40px"} /></StatusMessage>
            ) : (
              <>
                <Graph>
                  <Descriptor>avg</Descriptor>
                  <GraphValue>{ avgPercentage }%</GraphValue>
                  { isFinite(avgPercentageChange) && <GraphValueChange>({ avgElement }{ Math.abs(avgPercentageChange) }%)</GraphValueChange> }
                </Graph>
                <Graph $last>
                  <Descriptor>peak</Descriptor>
                  <GraphValue>{ peakPercentage }%</GraphValue>
                  { isFinite(peakPercentageChange) && <GraphValueChange>({ peakElement }{ Math.abs(peakPercentageChange) }%)</GraphValueChange> }
                </Graph>
              </>
            )
          }
        </GraphContainer>
        { description && <Info text={description} absolute popupLeft />}
      </InnerContainer>
    </Container>
  );
};

const Container = styled.div`
  display: block;
  height: 102px;
  min-width: 351px;
  flex-grow: 1;
  box-sizing: border-box;
  background-color: #fff;
  flex: 1;
`;

const InnerContainer = styled.div`
  position: relative;
  margin: 16px 20px;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: left;
`;

const Title = styled.div`
  display: inline-block;
  font-family: Source Sans Pro;
  font-size: 15px;
  font-weight: 600;
  color: #222222;
`;

const Subtitle = styled.div`
  display: inline-block;
  font-family: Source Sans Pro;
  font-size: 12px;
  font-weight: 600;
  color: #888888;
  margin-left: 5px;
`;

const GraphContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: left;
  margin-top: 10px;
`;

const StatusMessage = styled.div`
  display: inline-block;
  position: relative;
  margin: 20px;
  width: 50px;
  height: 50px;
  box-sizing: border-box;
`;

const Graph = styled.div<{ $last?: boolean }>`
  margin-right: ${props => props.$last ? "0px" : "20px"};
`;

const Descriptor = styled.div`
  display: inline-block;
  font-family: Source Sans Pro;
  font-size: 15px;
  font-weight: 600;
  color: #888888;
`;

const GraphValue = styled.div`
  display: inline-block;
  font-family: Source Sans Pro;
  font-size: 30px;
  font-weight: 600;
  color: #222222;
  margin-left: 5px;
`;

const GraphValueChange = styled.div`
  display: inline-block;
  font-family: Source Sans Pro;
  font-size: 16px;
  font-weight: 600;
  color: #888888;
  margin-left: 5px;
`;

export default Statistics;
