import React, { Component } from "react";
import { Col, Row } from "react-grid-system";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { get, isEmpty, isEqual, last } from "lodash";
import MapboxMap from "../Map/MapboxMap";
import Loader from "../../components/Loader";
import Freetext from "../../components/Freetext";
import Button from "../../components/Button";
import SmallButton from "../../components/SmallButton";
import Checkbox from "../../components/Checkbox";
import InputBox from "../../components/InputBox";
import OptionFooter from "../../components/OptionFooter";
import { ControlledDropdownSelection, DropdownSelection } from "../../components/DropdownSelection";
import { showInMapOptions, locationTypes, getSupportedLocationTypes, durationOptions, extensionAttributeValueTypes } from "../../locationHelpers";
import { createExtensionAttributeTable, createOccupancyConfigTable, createCustomTagsTable } from "./editLocationHelpers";
import * as locationActions from "../../actions/locations";
import * as featureActions from "../../actions/features";
import * as customTagActions from "../../actions/customTags";
import * as API from "../../ApiTypes";
import style from "./style.module.scss";

class EditLocation extends Component {

  constructor(props) {
    super(props);
    this.state = {
      hasLoadedState: false,
      name: "",
      mapSourceName: "",
      type: undefined,
      floorLevel: "",
      defaultFloorId: "",
      hardCapacity: "",
      squareMeters: "",
      mazemapSquareMeters: "",
      graphId: undefined,
      isBookable: false,
      showInMap: null,
      map: null,
      isTypeValid: false,
      occupancyRoomTimeouts: {},
      selectedConfigs: {},
      supportedLocationTypes: [],
      extensionAttributes: {},
      selectedExtensionAttributes: {},
      newExtensionAttributeName: "",
      newExtensionAttributeValue: "",
      newExtensionAttributeValueType: "string",
      duplicateFeatures: [],
      unusedFeatures: [],
      customTags: [],
      newCustomTag: null,
      selectedCustomTags: {}
    };

    this.onNameChange = this.onNameChange.bind(this);
    this.onFetchNameFromMap = this.onFetchNameFromMap.bind(this);
    this.onTypeChange = this.onTypeChange.bind(this);
    this.onFloorLevelChange = this.onFloorLevelChange.bind(this);
    this.onDefaultFloorIdChange = this.onDefaultFloorIdChange.bind(this);
    this.onShowInMapChange = this.onShowInMapChange.bind(this);
    this.onHardCapacityChange = this.onHardCapacityChange.bind(this);
    this.onSquareMetersChange = this.onSquareMetersChange.bind(this);
    this.onMazemapSquareMetersChange = this.onMazemapSquareMetersChange.bind(this);
    this.onGraphIdChange = this.onGraphIdChange.bind(this);
    this.onIsBookable = this.onIsBookable.bind(this);
    this.onSave = this.onSave.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.onSyncFloorMap = this.onSyncFloorMap.bind(this);
    this.onFileUpload = this.onFileUpload.bind(this);
    this.openFileDialog = this.openFileDialog.bind(this);
    this.deleteFile = this.deleteFile.bind(this);
    this.inputOpenFileRef = React.createRef();

    this.onToggleConfig = this.onToggleConfig.bind(this);
    this.onDeleteSelectedConfigs = this.onDeleteSelectedConfigs.bind(this);
    this.onDeselectSelectedConfigs = this.onDeselectSelectedConfigs.bind(this);
    this.onNewConfigTypeChange = this.onNewConfigTypeChange.bind(this);
    this.onNewConfigOccupancyDurationChange = this.onNewConfigOccupancyDurationChange.bind(this);
    this.onNewConfigKeepAliveDurationChange = this.onNewConfigKeepAliveDurationChange.bind(this);
    this.addNewConfig = this.addNewConfig.bind(this);

    this.onToggleExtensionAttribute = this.onToggleExtensionAttribute.bind(this);
    this.onNewExtensionAttributeNameChange = this.onNewExtensionAttributeNameChange.bind(this);
    this.onNewExtensionAttributeValueChange = this.onNewExtensionAttributeValueChange.bind(this);
    this.onNewExtensionAttributeValueTypeChange = this.onNewExtensionAttributeValueTypeChange.bind(this);
    this.addNewExtensionAttribute = this.addNewExtensionAttribute.bind(this);
    this.onDeselectSelectedExtensionAttributes = this.onDeselectSelectedExtensionAttributes.bind(this);
    this.onDeleteSelectedExtensionAttributes = this.onDeleteSelectedExtensionAttributes.bind(this);

    this.onDeleteDuplicates = this.onDeleteDuplicates.bind(this);
    this.onDeleteUnusedFeatures = this.onDeleteUnusedFeatures.bind(this);

    this.onNewCustomTagChange = this.onNewCustomTagChange.bind(this);
    this.onAddTagClicked = this.onAddTagClicked.bind(this);
    this.onToggleCustomTag = this.onToggleCustomTag.bind(this);
    this.onDeleteSelectedCustomTags = this.onDeleteSelectedCustomTags.bind(this);
    this.onDeselectSelectedCustomTags = this.onDeselectSelectedCustomTags.bind(this);

    if (props.match.params.id !== "root" && props.location.id !== props.match.params.id) {
      props.getLocation(props.match.params.id);
      props.getFloorMap(props.match.params.id);
    }

    // Download custom tags if not already done so
    if (isEmpty(get(this.props.customTags,"data.result", []))) {
      this.props.getCustomTags();
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {

    let newState = {
      ...prevState
    };

    // Fill in location name if feature selected and name is empty
    if (nextProps.selectedFeatures.length === 1) {
      const mapSourceName = get(nextProps.selectedFeatures, "[0].properties.mapSourceName", null);
      if (mapSourceName) {
        newState.mapSourceName = mapSourceName;
      }
    }

    if (prevState.hasLoadedState) {
      return newState;
    }


    // Only copy props if they exist and if location id is correct
    if (!isEmpty(nextProps.location) && nextProps.match.params.id === nextProps.location.id) {

      newState = {
        id: nextProps.location.id,
        name: nextProps.location.name,
        mapSourceName: get(nextProps, "location.geoJsonFeature.properties.mapSourceName", null),
        type: nextProps.location.type,
        graphId: get(nextProps, "location.geoJsonFeature.properties.microsoftGraph.emailAddress", undefined),
        hardCapacity: get(nextProps, "location.geoJsonFeature.properties.capacity.hard", ""),
        squareMeters: get(nextProps, "location.squareMeters", ""),
        mazemapSquareMeters: get(nextProps, "location.mazemapSquareMeters", ""),
        isBookable: nextProps.location.isBookable,
        showInMap: nextProps.location.showInMap,
        isTypeValid: true,
        hasLoadedState: true,
        floorLevel: nextProps.location.floorLevel,
        defaultFloorId: nextProps.location.defaultFloorId,
        occupancyRoomTimeouts: nextProps.location.occupancyRoomTimeouts,
        extensionAttributes: nextProps.location.extensionAttributes,
        customTags: JSON.parse(JSON.stringify(nextProps.location.customTags))
      };

      const breadcrumbs = get(nextProps, "location.breadcrumbs", []);
      const parentLocation = last(breadcrumbs);
      const parentLocationType = get(parentLocation, "type", "root");
      newState.supportedLocationTypes = getSupportedLocationTypes(parentLocationType);

      // If location is a floor, wait for floorMap
      if (nextProps.location.type === "floor") {
        
        if (nextProps.location.floorMap !== undefined) {
          newState.map = nextProps.location.floorMap;
          newState.duplicateFeatures = getDuplicates(newState.map);
          newState.unusedFeatures = getUnusedFeatures(newState.map);
          return newState;
        }
        
        return null;
      }
      
      return newState;
    }

    return null;
  }

  onNameChange(event) {
    this.setState({ name: event.target.value });
  }

  onFetchNameFromMap() {
    this.setState(prevState => ({ name: prevState.mapSourceName }));
  }

  onTypeChange(event) {
    this.setState({ type: event.target.value });
  }

  onFloorLevelChange(event) {
    this.setState({ floorLevel: event.target.value });
  }

  onDefaultFloorIdChange(event) {
    this.setState({ defaultFloorId: event.target.value})
  }

  onHardCapacityChange(event) {
    const hardCapacity = parseInt(event.target.value) || "";
    this.setState({ hardCapacity });
  }

  onSquareMetersChange(event) {
    const squareMeters = parseInt(event.target.value) || "";
    this.setState({ squareMeters });
  }

  onMazemapSquareMetersChange(event) {
    const mazemapSquareMeters = parseInt(event.target.value) || "";
    this.setState({ mazemapSquareMeters });
  }

  onGraphIdChange(event) {
    this.setState({ graphId: event.target.value });
  }

  onIsBookable() {
    this.setState(prevState => ({ isBookable: !prevState.isBookable }));
  }

  onShowInMapChange(event) {
    // Get option value for "show in map" id
    const showInMapObject = showInMapOptions.find((option) => (option.id === event.target.value));
    this.setState({ showInMap: showInMapObject.value });
  }

  // onMapChanged(event) {
  //   this.setState({ map: event.target.value });
  // }

  onSave() {
    const { push } = this.props.history;

    const body = { 
      name: this.state.name,
      type: this.state.type
    };

    // Add show location status in map if changed
    if (this.state.showInMap !== this.props.location.showInMap) {
      body.showLocationStatusInMap = this.state.showInMap;
    }

    // MS Graph Id
    if (!isEmpty(this.state.graphId) && (this.state.type.includes("zone") || this.state.type.includes("room") || this.state.type.includes("asset"))) {
      body.microsoftGraph = { emailAddress: this.state.graphId };
    }
    else {
      body.microsoftGraph = null;
    }

    // GeojsonFeature
    const newFeatureId = get(this.props.selectedFeatures,"[0].id", null);
    if (newFeatureId && newFeatureId !== get(this.props.location, "geoJsonFeature._id", null)) {
      body.geoJsonFeatureId = newFeatureId;
    }

    // Capacity "capacity"
    body.capacity = {
      hard: this.state.hardCapacity === "" ? null : this.state.hardCapacity 
    };

    // Square meters
    body.squareMeters = this.state.squareMeters === "" ? null : parseFloat(this.state.squareMeters);

    if (this.state.type === "building") {
      body.mazemapSquareMeters = this.state.mazemapSquareMeters === "" ? null : parseFloat(this.state.mazemapSquareMeters);
    }

    // Bookable
    body.isBookable = this.state.isBookable;

    // Only upload maps for floors
    if (this.state.type === "floor") {

      if (this.state.floorLevel !== this.props.location.floorLevel) {
        body.floorLevel = this.state.floorLevel;
      }
      
      // Update map if this location owns the map and there is a change
      if (this.state.map !== this.props.location.floorMap) {
        body.map = this.state.map;
      }
    }

    // Only save defaultFloorId if a building
    if (this.state.type === "building") {
      if (this.state.defaultFloorId === "") {
        body.defaultFloorId = null;
      }
      else if (this.state.defaultFloorId !== this.props.location.defaultFloorId) {
        body.defaultFloorId = this.state.defaultFloorId;
      }
    }

    // Add location config
    if (!isEmpty(this.state.occupancyRoomTimeouts)) {
      body.occupancyRoomTimeouts = this.state.occupancyRoomTimeouts;

      // Clean up _id
      Object.keys(body.occupancyRoomTimeouts).forEach(key => {
        delete body.occupancyRoomTimeouts[key]._id;
      });
    }
    else {
      body.occupancyRoomTimeouts = null;
    }

    // Add extension attributes
    body.externalExtensionAttributes = this.state.extensionAttributes;

    // Add custom tags
    body.customTags = this.state.customTags.map(tag => tag.id);

    this.props.editLocation(this.props.location, body, push);
  }

  onCancel() {
    this.props.history.push(`/companies/${this.props.match.params.companyId}/locations/${this.props.location.id}/locations`);
  }

  onSyncFloorMap() {
    const { push } = this.props.history;
    this.props.syncFloorMap(this.props.location.id, push);
  }

  onFileUpload(event) {
    event.preventDefault();
    const reader = new FileReader();
    const file = event.target.files[0];

    const { push } = this.props.history;

    reader.onloadend = () => {
      const json = JSON.parse(reader.result);
      if (json.type === "FeatureCollection") {
        delete json.type;
        this.props.uploadFloorMap(this.state.id, json, push);
      }
    }

    if (event.target.files[0]) {
      reader.readAsText(file);
      event.target.value = ''; // eslint-disable-line no-param-reassign
    }
  }

  onToggleConfig(row) {
    if (this.state.selectedConfigs[row.type] !== undefined && this.state.selectedConfigs[row.type]) {
      this.setState(prevState => {
        const newSelectedConfigs = {...prevState.selectedConfigs};
        delete newSelectedConfigs[row.type];
        return { selectedConfigs: newSelectedConfigs };
      });
    }
    else {
      this.setState(prevState => {
        const newSelectedConfigs = {...prevState.selectedConfigs};
        newSelectedConfigs[row.type] = row;
        return { selectedConfigs: newSelectedConfigs };
      });
    }
  }

  onDeleteSelectedConfigs() {
    if (!isEmpty(this.state.selectedConfigs)) {
      Object.keys(this.state.selectedConfigs).forEach(type => {
        this.setState(prevState => {
          const newOccupancyRoomTimeouts = {...prevState.occupancyRoomTimeouts};
          delete newOccupancyRoomTimeouts[type];       
          return { occupancyRoomTimeouts: newOccupancyRoomTimeouts, selectedConfigs: {} };
        });
      })
    }
  }

  onDeselectSelectedConfigs() {
    this.setState({ selectedConfigs: {} });
  }

  onNewConfigTypeChange(event) {
    this.setState({ newConfigType: event.target.value });
  }

  onNewConfigOccupancyDurationChange(event) {
    this.setState({ newConfigOccupancyDuration: event.target.value });
  }

  onNewConfigKeepAliveDurationChange(event) {
    this.setState({ newConfigKeepAliveDuration: event.target.value });
  }

  addNewConfig() {
    this.setState(prevState => {
      const newOccupancyRoomTimeouts = {...prevState.occupancyRoomTimeouts};
      newOccupancyRoomTimeouts[prevState.newConfigType] = { occupiedDurationSeconds: prevState.newConfigOccupancyDuration, keepAliveDurationSeconds: prevState.newConfigKeepAliveDuration };       
      return { occupancyRoomTimeouts: newOccupancyRoomTimeouts };
    });
  }

  onToggleExtensionAttribute(row) {
    if (this.state.selectedExtensionAttributes[row.name] !== undefined && this.state.selectedExtensionAttributes[row.name]) {
      this.setState(prevState => {
        const newSelectedExtensionAttributes = {...prevState.selectedExtensionAttributes};
        delete newSelectedExtensionAttributes[row.name];
        return { selectedExtensionAttributes: newSelectedExtensionAttributes };
      });
    }
    else {
      this.setState(prevState => {
        const newSelectedExtensionAttributes = {...prevState.selectedExtensionAttributes};
        newSelectedExtensionAttributes[row.name] = row;
        return { selectedExtensionAttributes: newSelectedExtensionAttributes };
      });
    }
  }

  onNewExtensionAttributeNameChange(event) {
    this.setState({ newExtensionAttributeName: event.target.value });
  }

  onNewExtensionAttributeValueChange(event) {
    this.setState({ newExtensionAttributeValue: event.target.value });
  }

  onNewExtensionAttributeValueTypeChange(event) {
    this.setState({ newExtensionAttributeValueType: event.target.value });
  }

  addNewExtensionAttribute() {
    this.setState(prevState => {
      const newExtensionAttributes = {...prevState.extensionAttributes};
      if (prevState.newExtensionAttributeValueType === "number" && Number.isFinite(prevState.newExtensionAttributeValue)) {
        newExtensionAttributes[prevState.newExtensionAttributeName] = Number(prevState.newExtensionAttributeValue);
      }
      else {
        newExtensionAttributes[prevState.newExtensionAttributeName] = prevState.newExtensionAttributeValue;
      }
      return { extensionAttributes: newExtensionAttributes };
    });
  }

  onDeleteSelectedExtensionAttributes() {
    if (!isEmpty(this.state.selectedExtensionAttributes)) {
      Object.keys(this.state.selectedExtensionAttributes).forEach(name => {
        this.setState(prevState => {
          const newSelectedExtensionAttributes = {...prevState.extensionAttributes};
          delete newSelectedExtensionAttributes[name];       
          return { extensionAttributes: newSelectedExtensionAttributes };
        });
      })
    }

    this.setState({ selectedExtensionAttributes: {} });
  }

  onDeselectSelectedExtensionAttributes() {
    this.setState({ selectedExtensionAttributes: {} });
  }

  openFileDialog() {
    this.inputOpenFileRef.current.click();
  }

  deleteFile() {
    const { push } = this.props.history;
    this.props.deleteFeatures(this.state.map.features, this.props.location.id, this.props.location.id, push);
  }

  onDeleteDuplicates() {
    const { push } = this.props.history;
    this.props.deleteFeatures(this.state.duplicateFeatures, this.props.location.id, this.props.location.id, push);
  }

  onDeleteUnusedFeatures() {
    const { push } = this.props.history;
    this.props.deleteFeatures(this.state.unusedFeatures, this.props.location.id, this.props.location.id, push);
  }

  onAddTagClicked(event) {
    this.setState(prevState => {
      // If already exists
      if (prevState.customTags.find(tag => tag.id === prevState.newCustomTag.id)) {
        return;
      }
      var customTags = prevState.customTags;
      customTags.push(prevState.newCustomTag);
      return { customTags };
    });
  }

  onNewCustomTagChange(event) {
    const newCustomTag = this.props.customTags.find(tag => tag.id === event.target.value);
    this.setState({ newCustomTag });
  }

  onToggleCustomTag(row) {
    if (this.state.selectedCustomTags[row.id] !== undefined && this.state.selectedCustomTags[row.id]) {
      this.setState(prevState => {
        const newSelectedCustomTags = {...prevState.selectedCustomTags};
        delete newSelectedCustomTags[row.id];
        return { selectedCustomTags: newSelectedCustomTags };
      });
    }
    else {
      this.setState(prevState => {
        const newSelectedCustomTags = {...prevState.selectedCustomTags};
        newSelectedCustomTags[row.id] = row;
        return { selectedCustomTags: newSelectedCustomTags };
      });
    }
  }

  onDeleteSelectedCustomTags() {
    if (!isEmpty(this.state.selectedCustomTags)) {
      this.setState(prevState => {
          var newCustomTags = [...prevState.customTags];
          newCustomTags = newCustomTags.filter(tag => !Object.keys(this.state.selectedCustomTags).includes(tag.id));
          return { customTags: newCustomTags, selectedCustomTags: {} };
        });
    }
  }

  onDeselectSelectedCustomTags() {
    this.setState({ selectedCustomTags: {} });
  }

  getOptionFooter() {
    if (Object.keys(this.state.selectedCustomTags).length > 0) {
      return (
        <OptionFooter 
          cancel={this.onDeselectSelectedCustomTags} 
          options={[
            { 
              label: "Delete",
              destructive: true,
              callback: this.onDeleteSelectedCustomTags
            }
          ]}
        />
      );
    }
    else if (Object.keys(this.state.selectedConfigs).length > 0) {
      return (
        <OptionFooter 
          cancel={this.onDeselectSelectedConfigs} 
          options={[
            { 
              label: "Delete",
              destructive: true,
              callback: this.onDeleteSelectedConfigs
            }
          ]}
        />
      );
    }
    else if (Object.keys(this.state.selectedExtensionAttributes).length > 0) {
      return (
        <OptionFooter 
          cancel={this.onDeselectSelectedExtensionAttributes} 
          options={[
            { 
              label: "Delete",
              destructive: true,
              callback: this.onDeleteSelectedExtensionAttributes
            }
          ]}
        />
      );
    }
    else {
      const missingFloorLevel = (this.state.type === "floor" && (this.state.floorLevel === "" || this.state.floorLevel === "0"));
      const hasChange = (this.state.name !== this.props.location.name || 
        this.state.type !== this.props.location.type ||
        this.state.defaultFloorId !== this.props.location.defaultFloorId ||
        this.state.hardCapacity !== get(this.props, "location.geoJsonFeature.properties.capacity.hard", "") ||
        this.state.squareMeters !== get(this.props, "location.squareMeters", "") ||
        this.state.mazemapSquareMeters !== get(this.props, "location.mazemapSquareMeters", "") ||
        this.state.graphId !== get(this.props, "location.geoJsonFeature.properties.microsoftGraph.emailAddress", undefined) ||
        this.state.showInMap !== this.props.location.showInMap ||
        this.state.isBookable !== this.props.location.isBookable ||
        JSON.stringify(this.state.customTags) !== JSON.stringify(this.props.location.customTags) ||
        (get(this.props.selectedFeatures,"[0].id", false) && 
        get(this.props.selectedFeatures,"[0].id", null) !== get(this.props.location,"geoJsonFeature._id", null)) ||
        !isEqual(this.state.occupancyRoomTimeouts, this.props.location.occupancyRoomTimeouts) ||
        !isEqual(this.state.extensionAttributes, this.props.location.extensionAttributes) ||
        (this.state.type === "floor" && (JSON.stringify(this.state.map) !== JSON.stringify(this.props.location.floorMap) || `${this.state.floorLevel}` !== `${this.props.location.floorLevel}`)));

      return (
        <OptionFooter 
          cancel={this.onCancel} 
          options={[
            { 
              label: "Save", 
              callback: this.onSave, 
              disabled: this.props.isLoadingLocations ||
                this.props.isLoading ||
                (this.state.name.length === 0 || !this.state.isTypeValid) || 
                missingFloorLevel ||
                !hasChange
            }
          ]} 
        />
      );
    }
  }

  render() {
    const isLoading = this.props.isLoading || 
      this.props.isLoadingLocations || 
      this.props.location === null || 
      this.props.location.id === undefined || 
      this.props.isSyncingMap;

    if (isLoading) {
      return <Loader fullScreen />;
    }

    const heading = (
      <Row className={style.topRow}>
        <Col md={9}>
          <div>
            <Freetext header="Edit location" />
          </div>
        </Col>
      </Row>
    );

    // Get "show in map" id for options selection
    const showInMapSelectionId = get(showInMapOptions.find((option) => (option.value === this.state.showInMap)), "id", undefined);

    // Get map text
    let mapText;
    if (this.state.type === "building") {
      mapText = "Go through every floor in this building and syncronize the maps.";
    }
    else if (this.state.map) {
      mapText = "There is a map for this floor. Deletion will remove the map for all locations on this floor. Sync will replace the existing map.";
    }
    else {
      mapText = "There is no map for this floor. Upload or sync from MazeMap.";
    }

    // Create table for extension attributes
    const extensionAttributeTable = createExtensionAttributeTable(this.state.extensionAttributes, this.state.selectedExtensionAttributes, this.onToggleExtensionAttribute);

    // Create table for occupancy timeouts
    const occupancyConfigTable = createOccupancyConfigTable(this.state.occupancyRoomTimeouts, this.state.selectedConfigs, this.onToggleConfig);

    // Create table for custom tags
    const customTagTable = createCustomTagsTable(this.state.customTags, this.state.selectedCustomTags, this.onToggleCustomTag);

    let mapElement;
    if (this.props.location.floorMap) {
      mapElement = (
        <MapboxMap 
          mapId="new-location-map-container" 
          id={this.state.id} 
          company={this.props.company} 
          location={this.props.location} 
          map={this.props.location.floorMap}
          showConnectForNewLocation
          showMap
          />
      );
    }

    return (
      <div className={style.editContainer}>
        <div className={style.row}>
          <div className={style.col50}>
            <div className={style.scroll}>
              { heading }
              <Row>
                <Col sm={12} md={8} lg={8}>
                  <InputBox 
                    autoFocus 
                    onChange={this.onNameChange} 
                    label="Name" 
                    placeholder="a short descriptive name" 
                    valid={this.state.name} 
                    value={this.state.name}
                    required 
                    showValidIcon
                    info="required"
                    />
                </Col>
              </Row>
              <Row>
                <Col sm={12} md={12} lg={4}>
                  <InputBox 
                    label="Name in connected map feature"
                    disabled
                    required
                    value={`${this.state.mapSourceName ?? ""}`}
                    />
                </Col>
                <Col sm={12} md={12} lg={4}>
                  <SmallButton
                    text={`Copy to name`}
                    onClick={this.onFetchNameFromMap} 
                    disabled={isEmpty(this.state.mapSourceName)}
                    style={{ marginTop: "43px" }}
                    />
                </Col>
              </Row>
              <Row nogutter>
                <ControlledDropdownSelection 
                  value={this.state.type} 
                  options={this.state.supportedLocationTypes}
                  name="selectedType" 
                  onChange={this.onTypeChange} 
                  label="Type" 
                  info="required"
                />
                { 
                  this.state.type === "floor" && (
                    <InputBox 
                      type="number" 
                      onChange={this.onFloorLevelChange} 
                      label="Floor level" 
                      valid={this.state.floorLevel && this.state.floorLevel.length > 0} 
                      value={this.state.floorLevel} 
                      style={{ width: "80px", paddingLeft: "20px" }}
                      info="required"
                      />
                  )
                }
                { 
                  this.state.type === "building" && (
                    <InputBox 
                      onChange={this.onDefaultFloorIdChange} 
                      label="Default floor id" 
                      placeholder="Default is bottom floor" 
                      valid={this.state.defaultFloorId} 
                      value={this.state.defaultFloorId} 
                      style={{ width: "260px", paddingLeft: "20px" }}
                      />
                  )
                }
              </Row>
              <Row nogutter>
                <InputBox 
                  type="number" 
                  onChange={this.onHardCapacityChange} 
                  label="People capacity" 
                  valid={this.state.hardCapacity} 
                  value={this.state.hardCapacity} 
                  style={{ width: "120px", marginRight: "20px" }} 
                />
              </Row>
              <Row nogutter>
                <InputBox 
                  type="number" 
                  onChange={this.onSquareMetersChange} 
                  label="Square meters" 
                  valid={this.state.squareMeters} 
                  value={this.state.squareMeters} 
                  style={{ width: "120px", marginRight: "20px" }}
                  disabled={!this.props.auth.hasSupportRole}
                />
                { this.state.type === "building" && (
                  <InputBox 
                    type="number" 
                    onChange={this.onMazemapSquareMetersChange} 
                    label="Mazemap square meters" 
                    valid={this.state.mazemapSquareMeters}
                    value={this.state.mazemapSquareMeters}
                    style={{ width: "160px" }}
                    disabled={!this.props.auth.hasSupportRole}
                  />
                )}
              </Row>
              <div style={{ paddingTop: "40px" }} />
              <Freetext header="Custom tags" />
              <Row>
                <Col>
                  { customTagTable }
                </Col>
              </Row>
              <div style={{ paddingTop: "20px" }} />
              <Row nogutter>
                <DropdownSelection
                  options={this.props.customTags.map(tag => ({ id: tag.id, name: tag.name }))}
                  name="newCustomTag" 
                  onChange={this.onNewCustomTagChange} 
                  label="Add custom tag" 
                />
                <SmallButton
                  text="Add" 
                  disabled={isEmpty(this.state.newCustomTag) || this.state.customTags.find(tag => tag.id === this.state.newCustomTag.id) }
                  onClick={this.onAddTagClicked}
                  style={{ marginTop: "43px", marginLeft: "20px" }}
                />
              </Row>
              <div style={{ paddingTop: "40px" }} />
              <Freetext header="Configuration for booking in the app" />
              <div style={{ paddingTop: "20px" }} />
              <Row>
                <Col>
                  <Checkbox 
                    label='This location contain bookable workplaces (based on "Limited capacity" above)' 
                    isChecked={this.state.isBookable} 
                    onClick={this.onIsBookable} 
                    />
                </Col>
              </Row>
              { (this.state.type.includes("zone") || this.state.type.includes("room") || this.state.type.includes("asset")) && (
                  <>
                    <div style={{ paddingTop: "40px" }} />
                    <Row>
                      <Col sm={12} md={8} lg={8}>
                        <InputBox 
                          onChange={this.onGraphIdChange} 
                          label="Microsoft Graph ID" 
                          placeholder="The locations e-mail address" 
                          valid={this.state.graphId} 
                          value={this.state.graphId} 
                          />
                      </Col>
                    </Row>
                  </>
                )
              }
              <div style={{ paddingTop: "60px" }} />
              <Freetext header="Configurations for the map" content="Here you can configure if a room should be shown, how long it will take for a room to become vacant and how long it will take for a room to show an unknown status. The status is unknown when the last sample is too old for a live system." />
              <Row>
                <Col sm={12} md={4} lg={4}>
                  <ControlledDropdownSelection 
                    value={showInMapSelectionId} 
                    options={showInMapOptions}
                    name="showInMap" 
                    onChange={this.onShowInMapChange} 
                    label="Show in map" 
                  />
                </Col>
              </Row>
              <Row>
                <Col sm={12} md={12} lg={12}>
                  { occupancyConfigTable }
                </Col>
              </Row>
              <Freetext content="Add a new configuration below or delete an old configuration by selecting it above and clicking delete." />
              <Row nogutter>
                <DropdownSelection
                  options={locationTypes}
                  name="newConfigType" 
                  onChange={this.onNewConfigTypeChange} 
                  label="Type" 
                />
                <DropdownSelection
                  options={durationOptions}
                  name="newConfigOccupancyDuration" 
                  onChange={this.onNewConfigOccupancyDurationChange} 
                  label="Occupancy duration" 
                  style={{ paddingLeft: "20px" }}
                />
                <DropdownSelection
                  options={durationOptions}
                  name="newConfigKeepAliveDuration" 
                  onChange={this.onNewConfigKeepAliveDurationChange} 
                  label="Keep Alive duration" 
                  style={{ paddingLeft: "20px" }}
                />
                <SmallButton
                  text="Add" 
                  disabled={isEmpty(this.state.newConfigType) || isEmpty(this.state.newConfigKeepAliveDuration) || isEmpty(this.state.newConfigKeepAliveDuration)}
                  onClick={this.addNewConfig}
                  style={{ marginTop: "43px", marginLeft: "20px" }}
                />
              </Row>
              <div style={{ paddingTop: "60px" }} />
              <Freetext header="Extension attributes for the Webhook API" content="Here you can add any attributes you want to be included in the Webhook API." />
              <Row>
                <Col lg={12}>
                  { extensionAttributeTable }
                </Col>
              </Row>
              <Freetext content="Add a new attributes below or delete an old attributes by selecting it above and clicking delete. You are limited to 32 characters in both fields." />
              <Row nogutter>
                <InputBox 
                  type="string" 
                  onChange={this.onNewExtensionAttributeNameChange} 
                  label="Name"
                  value={this.state.newExtensionAttributeName} 
                  valid={this.state.newExtensionAttributeName.length < 32} 
                  required
                  style={{ width: "200px" }}
                  />
                <InputBox 
                  type="string"
                  onChange={this.onNewExtensionAttributeValueChange} 
                  label="Value"
                  value={this.state.newExtensionAttributeValue} 
                  valid={this.state.newExtensionAttributeValue.length < 32}
                  style={{ width: "150px", paddingLeft: "20px" }}
                  />
                <ControlledDropdownSelection
                  options={extensionAttributeValueTypes}
                  name="newExtensionAttributeValueType" 
                  onChange={this.onNewExtensionAttributeValueTypeChange} 
                  label="Value type" 
                  value={this.state.newExtensionAttributeValueType}
                  style={{ paddingLeft: "20px" }}
                />
                <SmallButton 
                  text="Add" 
                  disabled={isEmpty(this.state.newExtensionAttributeName) || (! Number.isFinite(this.state.newExtensionAttributeValue) && this.state.newExtensionAttributeValueType === "number")}
                  onClick={this.addNewExtensionAttribute}
                  style={{ marginTop: "43px", marginLeft: "20px" }}
                />
              </Row>
              {/* { this.state.type === "building" && (
                  <div>
                    <div style={{ paddingTop: "40px" }} />
                    <Row>
                      <Col sm={12} md={12} lg={12}>
                        <Freetext header="Map" content={mapText} />
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={12} md={3} lg={3}>
                        <Button text="Sync floor maps" onClick={this.onSyncFloorMaps} color="blue" />
                      </Col>
                    </Row>
                  </div>
                )
              } */}
              { this.state.type === "floor" && (
                <div>
                  <div style={{ paddingTop: "40px" }} />
                  <Row>
                    <Col lg={12}>
                      <Freetext header="Map" content={mapText} />
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={12} md={3} lg={3}>
                      <Button text="Sync floor map" onClick={this.onSyncFloorMap} color="blue" />
                    </Col>
                    { this.state.map ? 
                      (
                        <Col sm={12} md={3} lg={3}>
                          <Button text="Delete" onClick={this.deleteFile} color="red" />
                        </Col>
                        /* <Col sm={12} md={3} lg={3}>
                          <Button text="Replace" onClick={this.openFileDialog} />
                        </Col> */
                      ) : (
                        <Col sm={12} md={3} lg={3}>
                          <Button text="Upload" onClick={this.openFileDialog} />
                        </Col>
                      )
                    }
                  </Row>
                  { this.state.map &&
                    <>
                      <Row>
                        <Col>
                          <Freetext content={"Clean up the map by removing duplicates without a location connection."} />
                        </Col>
                      </Row>
                      <Row>
                        <Col sm={12} md={3} lg={3}>
                          <Button text={`Delete ${this.state.duplicateFeatures.length} duplicate(s)`} onClick={this.onDeleteDuplicates} color="red" disabled={this.state.duplicateFeatures.length === 0} />
                        </Col>
                        <Col sm={12} md={3} lg={3}>
                          <Button text={`Delete ${this.state.unusedFeatures.length} unused`} onClick={this.onDeleteUnusedFeatures} color="red" disabled={this.state.unusedFeatures.length === 0} />
                        </Col>
                      </Row>
                    </>
                  }
                  <label htmlFor="mapInput">
                    <input ref={this.inputOpenFileRef} name="file" id="mapInput" type="file" className={style.fileInput} onChange={this.onFileUpload} style={{ display: "none" }} />
                  </label>
                </div>
              )
              }
              <div style={{ paddingTop: "80px" }} />
            </div>
          </div>
          <div className={style.mapPart40}>
            { mapElement }
          </div>
        </div>
        { this.getOptionFooter() }
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
    company: state.auth.selectedCompany,
    location: state.location,
    isLoadingLocations: state.loading.locations,
    isSyncingMap: state.loading[API.SYNC_FLOOR_MAP],
    selectedFeatures: state.selected.features,
    customTags: state.customTags.list,
    isLoading: state.loading.location,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ 
    getLocation: locationActions.getLocation,
    editLocation: locationActions.editLocation,
    getFloorMap: locationActions.getFloorMap,
    syncFloorMap: locationActions.syncFloorMap,
    deleteFeatures: featureActions.deleteFeatures,
    uploadFloorMap: locationActions.uploadFloorMap,
    getCustomTags: customTagActions.getCustomTags
  }, dispatch);
}

export default connect(mapStateToProps,mapDispatchToProps)(EditLocation);

function getDuplicates(map) {

  if (!get(map, "features[0]", false)) {
    return [];
  } 

  let groups = {};
  map.features.forEach((e,i) => {
    const geometryString = JSON.stringify(e.geometry);
    if (groups[geometryString]) {
      groups[geometryString].push(e);
    }
    else {
      groups[geometryString] = [e];
    }
  })

  const duplicateKeys = Object.keys(groups).filter(geometryKey => groups[geometryKey].length > 1);

  // Find which features we can delete
  let duplicateFeatures = []
  duplicateKeys.forEach(key => {
    var group = groups[key];
    const featureWithLocationId = group.find(feature => get(feature, "properties.locationId", false));
    if (featureWithLocationId) {
      // Keep a feature with locationId - remove the rest 
      const filteredGroup = group.filter(feature => feature !== featureWithLocationId);
      duplicateFeatures = duplicateFeatures.concat(filteredGroup);
    }
    else {
      // Keep a random feature - remove the rest
      const filteredGroup = group.slice(0, group.length - 1);
      duplicateFeatures = duplicateFeatures.concat(filteredGroup);
    }
  });

  console.log("duplicateFeatures", duplicateFeatures);

  return duplicateFeatures;
}

function getUnusedFeatures(map) {

  if (!get(map, "features[0]", false)) {
    return [];
  }

  var unusedFeatures = map.features
    .filter(feature => get(feature, "properties.locationId", null) === null)
    .filter(feature => get(feature, "properties.sensor", null) === null)
    .filter(feature => get(feature, "properties.gateway", null) === null);

  console.log("unusedFeatures", unusedFeatures);

  return unusedFeatures;
}