import React, { MouseEvent } from "react";
import PropTypes from "prop-types";
import styling from "./style.module.scss";

// Define a type for allowed color values
type ButtonColor = "blue" | "gray" | "red" | "white" | "white-red" | "white-gray" | "clear";
const allowedColors: ButtonColor[] = ["blue", "gray", "red", "white", "white-red", "white-gray", "clear"];

type SmallButtonProps = {
  text: string;
  type?: "button" | "submit" | "reset";
  hoverText?: string;
  onClick?: (event: MouseEvent<HTMLButtonElement>) => void;
  disabled?: boolean;
  color?: ButtonColor;
  noLeftMargin?: boolean;
  onlyLeftMargin?: boolean;
  onlyRightMargin?: boolean;
  noMargin?: boolean;
  style?: React.CSSProperties;
};

const colorClassNames = {
  blue: styling.blue,
  gray: styling.gray,
  red: styling.red,
  white: styling.white,
  "white-red": styling.whiteRed,
  "white-gray": styling.whiteGray,
  clear: styling.clear,
};

const computeButtonStyle = (props: SmallButtonProps) => {
  
  // Add margins based on if the button the first in a row, the last in a row, or in the middle
  let margin = "";
  if (props.noMargin) {
    margin = "5px 0px";
  } else if (props.onlyLeftMargin) {
    margin = "5px 0px 5px 10px";
  } else if (props.onlyRightMargin) {
    margin = "5px 10px 5px 0px";
  } else if (props.noLeftMargin) {
    margin = "5px 0px";
  } else {
    margin = "5px 5px 5px 10px";
  }

  return {
    minWidth: "84px",
    padding: "0px 15px",
    margin,
    ...props.style,
  };
};

const SmallButton: React.FC<SmallButtonProps> = ({
  text,
  type = "button",
  hoverText,
  onClick = () => {},
  disabled = false,
  color = "blue",
  noLeftMargin = false,
  onlyLeftMargin = false,
  onlyRightMargin = false,
  noMargin = false,
  style = {}
}) => {
  
  const className = colorClassNames[color] || styling.clear;
  const buttonStyle = computeButtonStyle({ 
    text, type, hoverText, onClick, disabled, color, noLeftMargin, onlyLeftMargin, onlyRightMargin, noMargin, style 
  });

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    if (onClick) {
      onClick(event);
    }
    event.stopPropagation();
  };

  return (
    <div className={styling.slimContainer}>
      <button
        className={className}
        title={hoverText}
        type={type}
        disabled={disabled}
        onClick={handleClick}
        style={buttonStyle}
        >
        <span>{text}</span>
      </button>
    </div>
  );
}

SmallButton.propTypes = {
  text: PropTypes.string.isRequired,
  type: PropTypes.oneOf(["button", "submit", "reset"]),
  hoverText: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  color: PropTypes.oneOf(allowedColors),
  noLeftMargin: PropTypes.bool,
  onlyLeftMargin: PropTypes.bool,
  noMargin: PropTypes.bool,
  style: PropTypes.object,
};

export default SmallButton;