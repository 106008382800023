import get from "lodash/get";
import { toast } from "react-toastify";
import * as types from "../ApiTypes";

const initialState = {
  id: null,
  property: null,
  updatedAt: null,
  sensorId: null,
  atomicSensorId: null,
  atomicSensors: [],
  state: null,
  action: null,
  reason: null,
  start: null,
  end: null,
  locations: [],
  user: { id: null, name: null },
  isAggregated: false,
  draft: {}
};

export default function anomalyReducer(state = initialState, action) {

  switch (action.type) {
    
    case types.REQ_DATA: {
      if (action.fetchType === types.GET_ANOMALY) {
        return initialState;
      }

      return state;
    }

    // queryHash: `${action.metadata.anomalyId}-${action.metadata.startdate}-${action.metadata.enddate}-${action.metadata.span}`

    case types.RECV_DATA: {
      if (action.fetchType === types.GET_ANOMALY) {

        // Copy the first atomic sensor id and sensor id to the anomaly
        let sensorId = null;
        let atomicSensorId = null;
        let property = action.payload.property;
        if (!action.payload.isAggregated) {
          sensorId = get(action.payload.atomicSensors, "[0].sensorId", null);
          atomicSensorId = get(action.payload.atomicSensors, "[0].atomicSensorId", null);
          property = get(action.payload.atomicSensors, "[0].property", null);
        }

        return {
          ...state,
          id: action.payload.anomalyId,
          property: property,
          updatedAt: action.payload.updatedAt,
          sensorId: sensorId,
          atomicSensorId: atomicSensorId,
          atomicSensors: action.payload.atomicSensors,
          state: action.payload.state,
          action: action.payload.action,
          reason: action.payload.reason,
          start: action.payload.start,
          end: action.payload.end,
          locations: action.payload.locations,
          user: action.payload.user,
          isAggregated: action.payload.isAggregated ?? false,
        };
      }
      
      if (action.fetchType === types.UPDATE_ANOMALY) {
        toast.success("Anomaly updated");

        if (state.id === action.metadata.anomalyId) {
          return {
            ...state,
            state: action.metadata.state
          }
        }

        return state;
      }

      return state;
    }

    case types.RECV_ERROR: {

      const statusCode = get(action, "payload.response.status", "Error");

      if (action.fetchType === types.GET_ANOMALY) {
        toast.error(`${statusCode}: Could not get anomaly`);
        return initialState;
      }

      if (action.fetchType === types.UPDATE_ANOMALY) {
        toast.error(`${statusCode}: Could not update anomaly`);
        return state;
      }
      
      return state;
    }

    case types.DRAFT_ANOMALY_CHANGE: {
      return {...state, draft: action.payload };
    }

    case types.CLEAR_DATA, types.CLEAR_ANOMALY: {
      return initialState;
    }

    case types.CLEAR_SELECTION: {
      return {...state, draft: {} };
    }

    // case types.STORE_ANOMALY_QUERY_HASH: {
    //   console.log("store query hash", action.payload);
    //   return {
    //     ...state,
    //     queryHash: action.payload
    //   };
    // }

    default: {
      return state;
    }
  }
}
