import React, { Component } from "react";
import { Icon, DARKGREEN, DARKRED, DARKGREY, WHITE } from "../Icon";
import { faCheck, faExclamation } from '@fortawesome/free-solid-svg-icons';
import style from "./style.module.scss";

class InputGroup extends Component {
  constructor(props) {
    super(props);

    this.state = {
      didBlur: false
    };
  }

  getInputStyles() {
    if (this.props.value === undefined || this.props.value === null || this.props.value === "") {
      return {};
    }
    else if (this.props.disabled) {
      return { color: DARKGREY };
    }
    else if (this.props.valid) {
      return { borderColor: DARKGREEN };
    }
    return { borderColor: DARKRED };
  }

  renderIcon() {
    if (this.props.value === undefined || this.props.value === null || this.props.value === "" || this.props.hideicon) {
      return null;
    }
    else if (this.props.disabled) {
      if (this.props.valid) {
        return <Icon color={WHITE} bgColor={DARKGREEN} icon={faCheck} classic />;
      }
      return null;
    }
    else if (this.props.valid) {
      return <Icon color={WHITE} bgColor={DARKGREEN} icon={faCheck} classic />;
    }
    return <Icon color={WHITE} bgColor={DARKRED} icon={faExclamation} classic />;
  }

  render() {
    const { label, placeholder, value, onChange, onPaste, onKeyDown, errorMessage, valid, formSubmitted, required, disabled, ...rest } = this.props;
    const invalid = (formSubmitted || this.state.didBlur) && !valid;
    return (
      <div className={style.inputGroup}>
        <label>{label}</label>
        <div className={style.inputWrapper}>
          <input
            placeholder={placeholder}
            style={required ? this.getInputStyles() : {}}
            value={value}
            onChange={onChange}
            onBlur={() => this.setState({ didBlur: true })}
            onPaste={onPaste}
            onKeyDown={onKeyDown}
            {...rest}
            disabled={disabled}
          />
          {required && this.renderIcon()}
        </div>

        {(invalid && errorMessage) && <p className={style.errorMessage}>{errorMessage}</p>}
      </div>
    );
  }
}

export default InputGroup;
