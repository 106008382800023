import axios from "axios";
import Cookies from "universal-cookie";
import * as types from "../ApiTypes";
import * as actions from "./sharedFunction";
import { API_URL, COOKIE_PREFIX } from "../env";
import { getCompanyId } from "../helpers";

const cookies = new Cookies();

export const getJobGroups = (queryParams) => async (dispatch) => {
  dispatch(actions.requestData(types.GET_JOB_GROUPS));

  const apiUrl = await API_URL();
  const companyId = getCompanyId();
  const cookiePrefix = await COOKIE_PREFIX();
  const accessToken = cookies.get(`${cookiePrefix}_access_token`);

  axios.get(`${apiUrl}companies/${companyId}/jobgroups`, await actions.getAxiosConfig(queryParams))
    .then((response) => {
      dispatch(actions.receiveData(response.data, types.GET_JOB_GROUPS, queryParams));
    })
    .catch((error) => {
      if (error.response && error.response.status === 401) {
        actions.sessionTokenExpired(cookiePrefix, accessToken);
      } else {
        dispatch(actions.receiveError(error, types.GET_JOB_GROUPS));
      }
    });
};

export const getJobGroup = (id, type) => async (dispatch) => {
  dispatch(actions.requestData(types.GET_JOB));

  const apiUrl = await API_URL();
  const companyId = getCompanyId();
  const cookiePrefix = await COOKIE_PREFIX();
  const accessToken = cookies.get(`${cookiePrefix}_access_token`);

  axios.get(`${apiUrl}companies/${companyId}/jobgroups/${type}/${id}`, await actions.getAxiosConfig())
    .then((response) => {
      dispatch(actions.receiveData(response.data, types.GET_JOB_GROUP));
    })
    .catch((error) => {
      if (error.response && error.response.status === 401) {
        actions.sessionTokenExpired(cookiePrefix, accessToken);
      } else {
        dispatch(actions.receiveError(error, types.GET_JOB_GROUP));
      }
    });
};

export const getJob = (id) => async (dispatch) => {
  dispatch(actions.requestData(types.GET_JOB));

  const apiUrl = await API_URL();
  const companyId = getCompanyId();
  const cookiePrefix = await COOKIE_PREFIX();
  const accessToken = cookies.get(`${cookiePrefix}_access_token`);

  axios.get(`${apiUrl}companies/${companyId}/jobs/${id}`, await actions.getAxiosConfig())
    .then((response) => {
      dispatch(actions.receiveData(response.data, types.GET_JOB));
    })
    .catch((error) => {
      if (error.response && error.response.status === 401) {
        actions.sessionTokenExpired(cookiePrefix, accessToken);
      } else {
        dispatch(actions.receiveError(error, types.GET_JOB));
      }
    });
};

export const setJobGroup = (jobGroup) => async (dispatch) => {
  // console.log("setJobGroup", jobGroup);
  dispatch(actions.receiveData(jobGroup, types.GET_JOB_GROUP));
};

export const setJob = (job) => async (dispatch) => {
  // console.log("setJob", job);
  dispatch(actions.receiveData(job, types.GET_JOB));
};

export const deleteJob = (id, jobGroup, redirect) => async (dispatch) => {
  dispatch(actions.requestData(types.DELETE_JOB));

  const apiUrl = await API_URL();
  const companyId = getCompanyId();
  const cookiePrefix = await COOKIE_PREFIX();
  const accessToken = cookies.get(`${cookiePrefix}_access_token`);

  axios.delete(`${apiUrl}companies/${companyId}/jobs/${id}`, await actions.getAxiosConfig())
    .then((response) => {
      dispatch(actions.receiveData(response.data, types.DELETE_JOB));
      if (redirect) {
        if (jobGroup.jobs.length > 1) {
          dispatch(getJobGroup(jobGroup.id, jobGroup.type));
          redirect(`/companies/${companyId}/installation/job-groups/${jobGroup.type}/${jobGroup.id}`);
        }
        else {
          dispatch(getJobGroups({ offset: 0, limit: 20 }));
          redirect(`/companies/${companyId}/installation/job-groups`);
        } 
      }
    })
    .catch((error) => {
      if (error.response && error.response.status === 401) {
        actions.sessionTokenExpired(cookiePrefix, accessToken);
      } else {
        dispatch(actions.receiveError(error, types.DELETE_JOB));
      }
    });
};

export const clearCreatedJob = () => async (dispatch) => {
  dispatch(actions.requestData(types.CLEAR_CREATED_JOB));
};