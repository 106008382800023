import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";

export const WHITE = "#ffffff";
export const BLACK = "#000000";
export const GREY = "#d3d3d3";
export const DARKGREY = "#888";

// Admin colors
export const DARKGREEN = "#429953";
// export const BLUE = "#1C4D82";
export const DARKRED = "#c12d2a";
// export const BROWN = "#95521c";

// Screen colors
export const GREEN = "#6ac259";
export const BLUE = "#007ad0";
export const RED = "#fc5145";
export const BROWN = "#945200";

export const Icon = (({ color = BLACK, bgColor = WHITE, icon, styles = {}, tooltip = "", classic = false }) => {
  
  // Use old style from admin (circle with icon in the middle)
  if (classic) {
    return (
      <ClassicContainer title={tooltip} style={{ backgroundColor: bgColor, ...styles }}>
        <FontAwesomeIcon icon={icon} color={color} />
      </ClassicContainer>
    );
  }

  return (
    <Container title={tooltip} style={{ backgroundColor: bgColor, ...styles }}>
      <FontAwesomeIcon icon={icon} color={color} />
    </Container>
  );

});


const ClassicContainer = styled.div`
  width: 23px;
  height: 23px;
  border-radius: 50%;
  position: absolute;
  bottom: 9px;
  right: 7px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Container = styled.div`
  width: 22px;
  height: 22px;
  margin-bottom: 10px;
  border-radius: 50%;
  display: inline-block;
  justify-content: center;
  align-items: center;
`;