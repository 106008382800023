import style from "./style.module.scss"

const BreadCrumb = ({ crumbs = [[]], onClick = () => { } }) => {
  return (
    <div className={style.crumbWrapper}>
      {crumbs.map((links, i) => {
        return <div key={i} className={style.crumbContainer}>
          {links.slice().map((link, j) => {
            return (<div key={j} className={style.link} onClick={() => onClick(link.id || link._id, j)} ><div className={style.linkText}>{link.name}{" >"}</div></div>);
          })}
        </div>
      })}
    </div>
  );
}

export default BreadCrumb;
