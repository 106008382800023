import React from "react";
import isEmpty from "lodash/isEmpty";
import Button from "../Button";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronUp, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import style from "./style.module.scss";

interface AppRowProps {
  index: number;
  count: number;
  selectedIndex: number;
  sectionIndex: number;
  selectedSectionIndex: number;
  row: any;
  addButton: any;
  viewButton: any;
  onClick: (sectionIndex: number, index: number) => void;
  moveUp: (sectionIndex: number, index: number) => void;
  moveDown: (sectionIndex: number, index: number) => void;
  onMouseEnter: () => void;
  onMouseLeave: () => void;
}

const AppRow: React.FC<AppRowProps>  = ({ index, count, selectedIndex, sectionIndex, selectedSectionIndex, row, addButton, viewButton, onClick = () => {}, moveUp = () => {}, moveDown = () => {}, onMouseEnter = () => {}, onMouseLeave = () => {} }) => {

  let titleElement = null;
  if (row) {
    switch (row.type) {
      case "text":
      case "sensor-data":
        if (isEmpty(row.title)) {
          titleElement = <h3 className={style.emptyTitle}>{"<no title>"}</h3>
        }
        else {
          titleElement = <h3 className={style.title}>{row.title}</h3>
        }
        break;
      case "text-area":
        if (isEmpty(row.text)) {
          titleElement = <h3 className={style.emptyTitle}>{"<no text>"}</h3>
        }
        else {
          titleElement = <h3 className={style.title}>{row.text}</h3>
        }
        break;
      case "image":
        if (isEmpty(row.imageUrl)) {
          titleElement = <h3 className={style.emptyTitle}>{"<no image>"}</h3>
        }
        else {
          titleElement = <h3 className={style.title}>{row.imageUrl}</h3>
        }
        break;
      default:
        break;
    }
  }

  return addButton ? (
    <div className={style.rowContainer}>
      <div className={style.arrowContainer}>
        <div className={style.arrowPlaceholder} />
      </div>
      <div className={style.newBackground} 
      onMouseEnter={(event) => { onMouseEnter(); }}
      onMouseLeave={(event) => { onMouseLeave(); }}
      onClick={(event) => { event.stopPropagation(); }}>
        <div className={style.buttonContainer}>
          <Button {...addButton} slim />
        </div>
      </div>
    </div>
  ) : (
    <div className={style.rowContainer}>
      <div className={style.arrowContainer} onClick={(event) => { event.stopPropagation(); }}>
        <div className={style.arrowPlaceholder} />
        <div className={style.arrowUp} onClick={(event) => { if (index !== 0) { moveUp(sectionIndex, index); } event.stopPropagation(); }}>
          <FontAwesomeIcon icon={faChevronUp} color={index === 0 ? "#ccc" : "#000"} />
        </div>
        <div className={style.arrowDown} onClick={(event) => { if (index !== count - 1) { moveDown(sectionIndex, index); } event.stopPropagation(); }}>
          <FontAwesomeIcon icon={faChevronDown} color={index === count - 1 ? "#ccc" : "#000"} />
        </div>
      </div>
      <div 
        className={ (index === selectedIndex && sectionIndex === selectedSectionIndex) ? style.selectedBackground : style.background} 
        onMouseEnter={(event) => { onMouseEnter(); }}
        onMouseLeave={(event) => { onMouseLeave(); }}
        onClick={(event) => { onClick(sectionIndex, index); event.stopPropagation(); }}>
        <div className={style.content}>
          <div className={style.left}>
            <p className={style.type}>{row.type}</p>
            {
              titleElement
            }
          </div>
          {
            viewButton && (
              <div className={style.right}>
                <Button { ...viewButton } slim />
              </div>
            )
          }
        </div>
      </div>
    </div>
  );
};

export default AppRow;
