import React, { Component } from "react";
import { Hidden, Visible } from "react-grid-system";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import isEmpty from "lodash/isEmpty";
import AnomalyList from "./anomalyList";
import AnomalyDetails from "./anomalyDetails";
import style from "./style.module.scss";

class Anomalies extends Component {

  render() {
    // console.log("Anomalies.render()", this.props)

    const showOptionFooter = !isEmpty(this.props.anomalyDraft);

    return (
      <>
        <Hidden xs sm md>
          <div className={ (showOptionFooter) ? style.mainContainer : style.mainContainerHidden }>
            <div className={style.row}>
              <div className={style.listContainer}>
                <div className={style.scroll}>
                  <AnomalyList { ...this.props} />
                </div>
              </div>
              <div className={style.sideBar}>
                <div className={style.scroll}>
                  <AnomalyDetails { ...this.props} />
                </div>
              </div>
            </div>
          </div>
        </Hidden>
        <Visible xs sm md>
          <div className={ (showOptionFooter) ? style.mainContainer : style.mainContainerHidden } style={{ backgroundColor: !isEmpty(this.props.match.params.anomalyId) && "#F6F6F6" }}>
            <div className={style.slimScroll}>
              {
                isEmpty(this.props.match.params.anomalyId) ? (<AnomalyList { ...this.props} />) : (<AnomalyDetails { ...this.props} />)
              }
            </div>
          </div>
        </Visible>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    anomalyDraft: state.anomaly.draft,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ 
    
   }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(Anomalies);
