import React, { Component } from "react";
import { Switch, Route } from "react-router-dom";
import { Hidden, Visible } from "react-grid-system";
import { connect } from "react-redux";
import isEmpty from "lodash/isEmpty";
import ContentList from "./contentList";
import ContentDetails from "./contentDetails";
import style from "./style.module.scss";

class ContentContainer extends Component {

  render() {
    const hasSelection = !isEmpty(this.props.selectedLocations) || !isEmpty(this.props.selectedUserGroups) || !isEmpty(this.props.selectedContent);
    return (
      <>
        <Hidden xs sm md>
          <div className={ hasSelection ? style.mainContainer : style.mainContainerHidden }>
            <div className={style.row}>
              <div className={style.listContainer}>
                <div className={style.scroll}>
                  <ContentList {...this.props} />
                </div>
              </div>
              <div className={style.sideBar}>
                <div className={style.scroll}>
                  <ContentDetails {...this.props} />
                </div>
              </div>
            </div>
          </div>
        </Hidden>
        <Visible xs sm md>
          <div className={ hasSelection ? style.mainContainer : style.mainContainerHidden } style={{ backgroundColor: !isEmpty(this.props.contentId) && "#F6F6F6" }}>
            <div className={style.slimScroll}>
              {
                isEmpty(this.props.contentId) ? (<ContentList {...this.props} />) : (<ContentDetails {...this.props} />)
              }
            </div>
          </div>
        </Visible>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    selectedLocations: state.selected.locations,
    selectedUserGroups: state.selected.userGroups,
    selectedContent: state.selected.content
  };
}

export default connect(mapStateToProps, null)(ContentContainer);