import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { OuterContainer, InnerContainer, Row, Col } from ".";
import * as accountActions from "../../actions/account";
import styled from "styled-components";
import { get } from "lodash";

class Overview extends Component {

  render() {
    // console.log("Overview props", this.props);

    const featureList = [
      {
        name: "BLDNG.dashboard",
        description: "A dashboard for live operational insights and historical presentation of sensor data.", //"A dashboard that allows you to inspect and monitor your office in detail.",
        enabled: true
      },
      {
        name: "BLDNG.screen",
        description: "A digital signage solution that allows you to display floor maps on screens in your office.",
        enabled: get(this.props.company, "serviceFeatures.enableScreen", false)
      },
      {
        name: "myBLDNG app",
        description: "A mobile app that enables workplace services for the employees.",
        enabled: get(this.props.company, "serviceFeatures.enableApp", false)
      },
      {
        name: "Resource booking with Graph API",
        description: "A solution that allows you to book resources in your office using Microsoft Graph API.",
        enabled: get(this.props.company, "applicationFeatures.useGraphResources", false)
      },
      {
        name: "Workplace Analysis",
        description: "An advanced report for the utilization of your workspace.",
        enabled: true
      },
      // {
      //   name: "No-show Analysis (coming soon)",
      //   description: "A report that provides insights into how often meeting rooms are booked but not used.",
      //   enabled: get(this.props.company, "serviceFeatures.enableNoShowAnalysis", false)
      // },
      // {
      //   name: "Energy Analysis (coming soon)",
      //   description: "A report that provides insights into energy consumption combined with the number of people in your office.",
      //   enabled: get(this.props.company, "serviceFeatures.enableEnergyAnalysis", false)
      // },
    ];

    const integrationList = [
      {
        name: "Microsoft Graph API",
        description: "An integration that allows users to use Microsoft 365 features in the myBLDNG app or on Screens.",
        enabled: get(this.props.company, "applicationFeatures.useAzureApis", false)
      },
      {
        name: "Entra ID",
        description: "Users can log in to the website and myBLDNG app with their business organisation username and password.",
        enabled: get(this.props.company, "scimActive", false)
      },
      {
        name: "Mazemap",
        description: "Provide floor maps in the solution.",
        enabled: !!get(this.props.company, "mazemapCampusTag", false)
      }
    ];


    // Look up model and vendor
    let hasPressac = this.props.vendors["pressac"];
    let hasSteinel = this.props.vendors["steinel"];
    let hasDisruptiveTechnologies = this.props.vendors["disruptive technologies"];
    let hasAirthings = this.props.vendors["airthings"];
    let hasEurecam = this.props.vendors["eurecam"];
    let hasYanzi = this.props.vendors["yanzi"];
    let hasTerabee = this.props.vendors["terabee"];
    let hasUbiqisense = this.props.vendors["ubiqisense"];
    let hasCiscoWebex = this.props.vendors["cisco"];
    
    // Look up vendor for inboundIntegrations
    let hasCiscoSpaces = this.props.inboundIntegrations.find(integration => integration.name.toLowerCase().includes("cisco"));
    let hasGlitre = this.props.inboundIntegrations.find(integration => integration.name.toLowerCase().includes("glitre") || integration.name.toLowerCase().includes("å insite") || integration.name.toLowerCase() === "å");
    let hasRelynk = this.props.inboundIntegrations.find(integration => integration.name.toLowerCase().includes("relynk"));
    let hasPlaceOS = this.props.inboundIntegrations.find(integration => integration.name.toLowerCase().includes("placeos"));

    const sensorHardwareList = [
      {
        name: "Pressac",
        description: "A suite of different sensors.",
        enabled: hasPressac
      },
      {
        name: "Steinel",
        description: "Sensors that count people in larger areas.",
        enabled: hasSteinel
      },
      {
        name: "Disruptive Technologies",
        description: "A suite of different small sensors.",
        enabled: hasDisruptiveTechnologies
      },
      {
        name: "Airthings",
        description: "Sensors that measure air quality.",
        enabled: hasAirthings
      },
      {
        name: "Eurecam",
        description: "People counting sensors.",
        enabled: hasEurecam
      },
      {
        name: "Yanzi",
        description: "A suite of different sensors.",
        enabled: hasYanzi
      },
      {
        name: "Terabee",
        description: "Presence detection sensors.",
        enabled: hasTerabee
      },
      {
        name: "Ubiqisense",
        description: "Presence detection sensors.",
        enabled: hasUbiqisense
      }
    ];

    const sensorServiceList = [
      {
        name: "Yanzi",
        description: "A suite of different sensors.",
        enabled: hasYanzi
      },
      {
        name: "Cisco Spaces",
        description: "Integration to the Cisco infrastructure and equipment. Including Meraki cameras, sensors and Webex.",
        enabled: hasCiscoSpaces
      },
      {
        name: "Disruptive Technologies",
        description: "A suite of different small sensors.",
        enabled: hasDisruptiveTechnologies
      },
      {
        name: "Relynk",
        description: "Connecting to existing sensors integrated in the buildings ITB system.",
        enabled: hasRelynk
      },
      {
        name: "Cisco Webex",
        description: "Using meeting room equipment to detect presence and air quality.",
        enabled: hasCiscoWebex
      },
      {
        name: "Å Insite",
        description: "Measure real time power consumption.",
        enabled: hasGlitre
      },
      {
        name: "PlaceOS",
        description: "People counters for workplace.",
        enabled: hasPlaceOS
      }
    ];

    return (
      <OuterContainer $darkBackground>
        <InnerContainer>
          <Row $paddingTop="15px">
            <Col>
              <h2>Features</h2>
              <p>All features that are active in your company.</p>
            </Col>
          </Row>
          <Row>
            <Grid>
              {
                featureList.map((feature, index) => {
                  return (
                    <Block key={index}>
                      <BlockContent>
                        <BlockInnerTitle>{feature.name}</BlockInnerTitle>
                        <BlockInnerDescription>{feature.description}</BlockInnerDescription>
                      </BlockContent>
                      <BlockEnabledFooter $enabled={feature.enabled}>
                        <span>{feature.enabled ? "Active" : "Inactive"}</span>
                      </BlockEnabledFooter>
                    </Block>
                  );
                })
              }
            </Grid>
          </Row>
          <Row>
            <Col>
              <h2>Integrations</h2>
              <p>All integrations that are active in your company.</p>
            </Col>
          </Row>
          <Row>
            <Grid>
              {
                integrationList.map((feature, index) => {
                  return (
                    <Block key={index}>
                      <BlockContent>
                        <BlockInnerTitle>{feature.name}</BlockInnerTitle>
                        <BlockInnerDescription>{feature.description}</BlockInnerDescription>
                      </BlockContent>
                      <BlockEnabledFooter $enabled={feature.enabled}>
                        <span>{feature.enabled ? "Active" : "Inactive"}</span>
                      </BlockEnabledFooter>
                    </Block>
                  );
                })
              }
            </Grid>
          </Row>
          <Row>
            <Col>
              <h2>Sensor hardware</h2>
              <p>All sensor hardware that is used or available for your company.</p>
            </Col>
          </Row>
          <Row>
            <Grid>
              {
                sensorHardwareList.map((feature, index) => {
                  return (
                    <Block key={index}>
                      <BlockContent>
                        <BlockInnerTitle>{feature.name}</BlockInnerTitle>
                        <BlockInnerDescription>{feature.description}</BlockInnerDescription>
                      </BlockContent>
                      <BlockEnabledFooter $enabled={feature.enabled}>
                        <span>{feature.enabled ? "Active" : "Inactive"}</span>
                      </BlockEnabledFooter>
                    </Block>
                  );
                })
              }
            </Grid>
          </Row>
          <Row>
            <Col>
              <h2>Sensor services</h2>
              <p>All sensor services that are used or available for your company.</p>
            </Col>
          </Row>
          <Row>
            <Grid>
              {
                sensorServiceList.map((feature, index) => {
                  return (
                    <Block key={index}>
                      <BlockContent>
                        <BlockInnerTitle>{feature.name}</BlockInnerTitle>
                        <BlockInnerDescription>{feature.description}</BlockInnerDescription>
                      </BlockContent>
                      <BlockEnabledFooter $enabled={feature.enabled}>
                        <span>{feature.enabled ? "Active" : "Inactive"}</span>
                      </BlockEnabledFooter>
                    </Block>
                  );
                })
              }
            </Grid>
          </Row>
        </InnerContainer>
      </OuterContainer>
    );
  }
}

function mapStateToProps(state) {
  return { 
    auth: state.auth,
    selectedCompany: state.auth.selectedCompany,
    isAuthLoading: state.loading.auth,
    buildings: state.accountSummary.buildings,
    vendors: state.accountSummary.vendors,
    inboundIntegrations: state.accountSummary.inboundIntegrations,
    company: state.company.data,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    getAccountSummary: accountActions.getAccountSummary,
  }, dispatch);
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Overview));

const Grid = styled.div`
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  position: relative;
  font-size: 16px;
  font-weight: 400;
  flex-wrap: wrap;
`;

const Block = styled.div`
  position: relative;
  background-color: rgb(255, 255, 255);
  border-radius: 6px;
  border-width: 1px;
  border-style: solid;
  border-color: rgb(221, 221, 221);
  margin-left: "10px";
  margin-right: "10px";
  font-size: 16px;
  font-weight: 400;
  width: 200px;
  height: 200px;
  margin-right: 10px;
  margin-bottom: 10px;
  overflow: hidden;
`;

const BlockEnabledFooter = styled.div`
  position: absolute;
  bottom: 0px;
  right: 0px;
  width: 60px;
  height: 20px;
  border-radius: 6px 0px 0px 0px;
  // background-color: ${props => props.$enabled ? '#429953' : '#bbb'};
  background-color: ${props => props.$enabled ? '#1C4D82' : '#bbb'};
  text-align: center;

  span {
    display: block;
    margin-top: 2px;
    color: #fff;
    font-size: 12px;
    font-weight: 600;
  }
`;

const BlockContent = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  position: relative;
  padding: 15px 20px 25px 20px;
  font-size: 16px;
  font-weight: 400;
`;

const BlockInnerTitle = styled.div`
  font-size: 20px;
  font-weight: 600;
  color: #222222;
  margin-top: 0px;
  margin-bottom: 8px;
`;

const BlockInnerDescription = styled.div`
  font-size: 16px;
  font-weight: 400;
  color: #666;
  margin-bottom: 5px;
`;