import { toast } from "react-toastify";
import isEmpty from "lodash/isEmpty";
import * as types from "../ApiTypes";

const initialState = {
  data: {},
  draft: {}
};

export default function serviceAccountReducer(state = initialState, action) {

  switch (action.type) {
    
    case types.REQ_DATA: {
      if (action.fetchType === types.GET_SERVICE_ACCOUNT) {
        return state;
      }
      
      return state;
    }

    case types.RECV_DATA: {
      if (action.fetchType === types.GET_SERVICE_ACCOUNT) {
        return {
          ...state,
          data: action.payload,
          draft: {}
        }
      }

      if (action.fetchType === types.CREATE_SERVICE_ACCOUNT) {
        toast.success("Service account created");
        return {
          ...state,
          data: {},
          draft: {}
        }
      }

      if (action.fetchType === types.UPDATE_SERVICE_ACCOUNT) {
        toast.success("Service account updated");
        return {
          ...state,
          data: {},
          draft: {}
        }
      }

      if (action.fetchType === types.DELETE_SERVICE_ACCOUNT) {
        toast.success("Service account deleted");
        return {
          ...state,
          data: {},
          draft: {}
        }
      }

      return state;
    }

    case types.RECV_ERROR: {
      if (action.fetchType === types.GET_SERVICE_ACCOUNT) {
        toast.error("Could not get service account");
        return {...state, data: {} };
      }

      if (action.fetchType === types.CREATE_SERVICE_ACCOUNT) {
        toast.error("Could not create service account");
        return state;
      }

      if (action.fetchType === types.UPDATE_SERVICE_ACCOUNT) {
        toast.error("Could not update service account");
        return state;
      }

      if (action.fetchType === types.DELETE_SERVICE_ACCOUNT) {
        toast.error("Could not delete service account");
        return state;
      }
      
      return state;
    }

    case types.UPDATE_SERVICE_ACCOUNT_DRAFT: {
      const newDraft = { ...state.draft, ...action.draft };
      
      Object.keys(newDraft).forEach(draftKey => {
        if (newDraft[draftKey] === state.data[draftKey]) {
          delete newDraft[draftKey];
        }
        else if (isEmpty(state.data[draftKey]) && isEmpty(newDraft[draftKey])) {
          delete newDraft[draftKey];
        }
      });

      return {...state, draft: newDraft };
    }

    case types.CLEAR_SERVICE_ACCOUNT_DRAFT: {
      return {...state, draft: {}};
    }

    case types.CLEAR_SERVICE_ACCOUNT: {
      return initialState;
    }

    default: {
      return state;
    }
  }
}
