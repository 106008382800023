import React from "react";
import { Container, Col, Row } from "react-grid-system";
import Freetext from "../../components/Freetext";
import style from "./style.module.scss"

const NoAccess = () => (
  <div className={style.outerScroll}>
    <Container className={style.container}>
      <Row className={style.topRow}>
        <Col>
          <Freetext header="No access" content="You don't have the appropriate administration privileges." />
        </Col>
      </Row>
    </Container>
  </div>
);

export default NoAccess;
