import React, { Component } from "react";
import { TwitterPicker } from "react-color";
import { Icon, DARKGREEN, DARKRED, WHITE } from "../Icon";
import { Info } from "ui";
import { faCheck, faExclamation } from '@fortawesome/free-solid-svg-icons';
import style from "./style.module.scss";

class ColorPicker extends Component {
  // label, placeholder, value, onChange, errorMessage, valid, formSubmitted, disabled, style

  constructor(props) {
    super(props);

    this.state = {
      show: false
    };

    this.open = this.open.bind(this);
    this.close = this.close.bind(this);

    this.popoverRef = React.createRef();
  }

  getInputStyles() {
    if (this.props.disabled) {
      return { backgroundColor: "#FaFaFa", color: "#999" };
    }
    else if (this.props.value === undefined || this.props.value === null || this.props.value === "") {
      return {};
    }
    else if (this.props.valid) {
      return { outlineColor: DARKGREEN, outlineWidth: "2px" };
    }
    return { outlineColor: DARKRED, outlineWidth: "2px" };
  }

  renderIcon() {
    if (this.props.value === undefined || this.props.value === null || this.props.value === "" || this.props.hideicon) {
      return null;
    }
    else if (this.props.disabled) {
      return null;
    }
    else if (this.props.valid) {
      return <Icon color={WHITE} bgColor={DARKGREEN} icon={faCheck} classic />;
    }
    return <Icon color={WHITE} bgColor={DARKRED} icon={faExclamation} classic />;
  }

  open(event) { 
    event.preventDefault();
    event.stopPropagation();
    
    this.setState({ show: true }, () => {
      document.addEventListener("click", this.close);
    });
  }

  close(event) {
    if (this.popoverRef.current && this.popoverRef.current.contains(event.target)) {
      return; 
    }

    this.setState({ show: false }, () => {
      document.removeEventListener("click", this.close);
    });
  }

  render() {
    const { label, value = "#6c757d", onChange, help, info, formSubmitted, disabled, ...rest } = this.props;
    return (
      <div className={style.inputGroup} style={{ paddingTop: label ? "20px" : 0, paddingBottom: info ? "20px" : 0, ...this.props.style}}>
        { label && <label>{label}</label> }
        { help && <Info text={help} />}
        <div className={style.inputWrapper}>
          <div className={style.colorBox} style={{ backgroundColor: value }} onClick={this.open} />
          { this.state.show && (
            <>
              <div ref={this.popoverRef} className={style.popover}>
                <TwitterPicker
                  color={value}
                  onChangeComplete={(color) => { onChange(color); }}
                  colors={[ "#8a5a44","#e85d04", "#d00000","#a01a58", "#7b2cbf", "#3a86ff", "#0353a4", "#2a9d8f", "#409030", "#6c757d" ]}
                />
              </div>
            </>
          ) }
        </div>
        {info && <div className={style.info}>{info}</div>}
      </div>
    );
  }
}

export default ColorPicker;
