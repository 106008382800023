import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import isEmpty from "lodash/isEmpty";
import DetailsHeader from "../../components/DetailsHeader";
import SubNavigation from "../../components/SubNavigation";
import GroupUsers from "../Organization/groupDetailsUsers";
import GroupLocations from "../Organization/groupDetailsLocations";
import GroupContent from "../Organization/groupDetailsContent";
import * as userGroupActions from "../../actions/userGroups";
import style from "./style.module.scss";

const tabBarLinks = [
  { label: `Users` },
  { label: `Locations` },
  { label: `Content` }
];

class GroupDetails extends Component {

  constructor(props) {
    super(props);
    this.state = {
      selectedIndex: 0
    };
    this.selectedTab = this.selectedTab.bind(this);

    if (isEmpty(this.props.groupId)) {
      this.props.clearUserGroup();
    }
    else if (this.props.userGroup.id !== this.props.groupId) {
      this.props.getUserGroup(this.props.groupId);
    }
  }

  componentDidUpdate(prevProps) {
    // Only load data if id change
    if (isEmpty(this.props.groupId)) {
      this.props.clearUserGroup();
    }
    else if (prevProps.groupId !== this.props.groupId) {
      this.props.getUserGroup(this.props.groupId);
    }
  }

  selectedTab(index) {
    this.setState({ selectedIndex: index });
  }

  render() {

    if (isEmpty(this.props.groupId) || isEmpty(this.props.userGroup.id)) {
      return null;
    }

    const title = this.props.userGroup.name ?? "Details";

    let detailsElement = null;
    switch (this.state.selectedIndex) {
      case 0:
        detailsElement = <GroupUsers {...this.props} />
        break;
      case 1:
        detailsElement = <GroupLocations {...this.props} />
        break;
      case 2:
        detailsElement = <GroupContent {...this.props} />
        break;
      default:
        break;
    }

    return (
      <div>
        <DetailsHeader title={title} />
        <SubNavigation links={tabBarLinks} selectedIndex={this.state.selectedIndex} onClick={this.selectedTab} slim />
        { detailsElement }
        <div style={{ paddingTop: "40px" }} />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    userGroup: state.userGroup,
    selectedCompany: state.auth.selectedCompany
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    getUserGroup: userGroupActions.getUserGroup,
    clearUserGroup: userGroupActions.clearUserGroup
   }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(GroupDetails);
