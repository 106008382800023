import React, { Component } from "react";
import { Container, Col, Row, Visible, Hidden } from "react-grid-system";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Freetext from "../../components/Freetext";
import OptionFooter from "../../components/OptionFooter";
import Table from "../../components/Table";
import * as locationActions from "../../actions/locations";
import * as screenViewActions from "../../actions/screenViews";
import style from "./style.module.scss";

class ScreenLocationSelect extends Component {

  constructor(props) {
    super(props);
    this.state = {
      searchText: "",
      sortBy: null,
      sortOrder: "asc",
      offset: 0,
      limit: 20
    };
    this.onCancel = this.onCancel.bind(this);
    this.fetchData = this.fetchData.bind(this);
    this.onSearchClick = this.onSearchClick.bind(this);
    this.onSearchChange = this.onSearchChange.bind(this);
    this.onRowClick = this.onRowClick.bind(this);
    this.onSortedChange = this.onSortedChange.bind(this);
    this.onOffsetChange = this.onOffsetChange.bind(this);
    this.slimTableElement = this.slimTableElement.bind(this);
  }

  componentDidMount() {
    this.fetchData();
  }

  componentDidUpdate(prevProps) {
    // Only load data if id change
    if (this.props.match.params.locationId !== prevProps.match.params.locationId) {
      this.fetchData();
    }
  }

  fetchData() {
    const queryParams = {
      search: this.state.searchText,
      sortBy: this.state.sortBy,
      sortOrder: this.state.sortOrder,
      offset: this.state.offset,
      limit: this.state.limit
    };

    if (this.props.match.params.locationId === "root") {
      this.props.getRootLocations(queryParams);
    }
    else {
      this.props.getLocation(this.props.match.params.locationId);
      this.props.getLocationChildren(this.props.match.params.locationId, queryParams);
    }
  }

  onCancel() {
    this.props.history.push(`/companies/${this.props.match.params.companyId}/screens/${this.props.screenGroupId}/views/${this.props.screenViewId}/map/${this.props.match.params.mapIndex}`);
  }

  onSortedChange(newSorted) {
    const sortBy = newSorted[0].id;
    const sortOrder = newSorted[0].desc ? "desc" : "asc";
    this.setState({ sortBy, sortOrder, offset: 0 }, this.fetchData);
  }

  onOffsetChange(offset) {
    this.setState({ offset }, this.fetchData);
  }

  onSearchClick() {
    this.setState({ offset: 0 }, this.fetchData);
  }

  onSearchChange(event) {
    this.setState({ searchText: event.target.value });
  }

  onRowClick(column, rowInfo) {
    return {
      onClick: e => {
        if (rowInfo && column.name !== "isSelected") {
          if (rowInfo.original.type === "floor") {
            const mapComponent = JSON.parse(JSON.stringify(this.props.mapComponent));
            mapComponent.mapLocationId = rowInfo.original._id;
            
            this.props.updateScreenViewForm(mapComponent, this.props.pathToMap);
            this.props.history.push(`/companies/${this.props.match.params.companyId}/screens/edit/${this.props.screenGroupId}/views/${this.props.screenViewId}/map/${this.props.match.params.mapIndex}`);
          }
          else if (rowInfo.original.children !== undefined) {
            this.props.history.push(`/companies/${this.props.match.params.companyId}/screens/edit/${this.props.screenGroupId}/views/${this.props.screenViewId}/map/${this.props.match.params.mapIndex}/locations/${rowInfo.original._id}`);
          }
        }
      },
      style: {
        cursor: "pointer",
        position: "relative"
      }
    }
  }

  slimTableElement() {
    return (
      <Table
        data={this.props.children.locations}
        sortBy={this.state.sortBy}
        sortOrder={this.state.sortOrder}
        offset={this.state.offset}
        limit={this.state.limit}
        count={this.props.children.count}
        onSortedChange={this.onSortedChange}
        onOffsetChange={this.onOffsetChange}
        noDataText={this.props.isLoading ? "" : "No locations found"}
        columns={[
          {
            header: "Name",
            accessorKey: "name",
            cell: ({ row }) => (<span title={row.original.name}>{row.original.name}</span>)
          },
          {
            header: "Type",
            accessorKey: "type",
            minWidth: 50,
            maxWidth: 200
          },
          {
            id: "arrow",
            header: "",
            accessorKey: 'children',
            sortable: false,
            className: "pull-right",
            width: 60,
            cell: ({ row }) => (<div className="arrow" />)
          }
        ]}
        getTdProps={this.onRowClick}
        className="-row-clickable setMaxHeigth -highlight"
        loading={this.props.isLoading}
      />
    );
  }

  render() {
    return (
      <>
        <Hidden xs sm md>
          <div className={ style.mainContainer }>
            <div className={style.scroll}>
              { this.slimTableElement() }
            </div>
          </div>
        </Hidden>
        <Visible xs sm md>
          <div className={ style.mainContainer }>
            <div className={style.slimScroll}>
              { this.slimTableElement() }
            </div>
          </div>
        </Visible>

        <OptionFooter cancel={this.onCancel} />
      </>
    );

    return (
      <div className={style.outerEditScroll}>
        <Container className={style.editContainer}>
          <Row className={style.topRow}>
            <Col>
              <Freetext header="Select floor" content="Navigate to the floor you want to on the screen." />
            </Col>
          </Row>
          { this.slimTableElement() }
          <div style={{ paddingTop: "40px" }} />
        </Container>
        <OptionFooter cancel={this.onCancel} />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    form: state.screen.form,
    location: state.location,
    children: state.locations.data,
    isLoading: state.loading.locations
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ 
    getLocation: locationActions.getLocation,
    getRootLocation: locationActions.getRootLocation,
    getLocationChildren: locationActions.getLocationChildren,
    getRootLocations: locationActions.getRootLocations,
    updateScreenViewForm: screenViewActions.updateScreenViewForm
  }, dispatch);
}

export default connect(mapStateToProps,mapDispatchToProps)(ScreenLocationSelect);
