import get from "lodash/get";
import { toast } from "react-toastify";
import * as types from "../ApiTypes";

const initialState = {
  data: {
    displays: [],
    count: 0,
    limit: 20
  },
  display: {},
  form: {}
};

export default function displaysReducer(state = initialState, action) {

  switch (action.type) {

    case types.REQ_DATA: {
      if (action.fetchType === types.GET_DISPLAYS) {
        return {...state, data: initialState.data };
      }

      if (action.fetchType === types.GET_DISPLAY) {
        return {...state, display: {}, form: {} };
      }
      
      return state;
    }

    case types.RECV_DATA: {
      if (action.fetchType === types.GET_DISPLAYS) {
        return {...state, data: action.payload };
      }

      if (action.fetchType === types.GET_DISPLAY) {
        // Create form object
        const form = {
          name: get(action.payload, "name", ""),
          reportId: get(action.payload, "powerbi._id", undefined),
          floorId: get(action.payload, "map.floorId", undefined),
          dashboardId: get(action.payload, "dashboard.dashboardId", undefined),
          config: { ...get(action.payload, "map.config", undefined) }
        };

        if (get(action.payload, "map.config.yourLocation", null)) {
          form.config.yourLocation = { ...get(action.payload, "map.config.yourLocation", {})};
        }

        return {...state, display: action.payload, form };
      }

      if (action.fetchType === types.CREATE_DISPLAY) {
        toast.success("Display created");
        return {...state, data: {}, display: {}, form: {} };
      }

      if (action.fetchType === types.UPDATE_DISPLAY_FORM) {
        return {...state, form: action.payload };
      }

      if (action.fetchType === types.UPDATE_DISPLAY) {
        toast.success("Display updated");
        return {...state, data: {}, display: {}, form: {} };
      }

      if (action.fetchType === types.DELETE_DISPLAY) {
        toast.success("Display deleted");
        return {...state, data: {}, display: {}, form: {} };
      }

      return state;
    }

    case types.RECV_ERROR: {

      const statusCode = get(action, "payload.response.status", "Error");

      if (action.fetchType === types.GET_DISPLAYS) {
        toast.error(`${statusCode}: Could not get displays`);
        return {...state, data: initialState.data };
      }

      if (action.fetchType === types.GET_DISPLAY) {
        toast.error(`${statusCode}: Could not get display`);
        return {...state, display: {}, form: {} };
      }

      if (action.fetchType === types.CREATE_DISPLAY) {
        toast.error(`${statusCode}: Could not create display`);
        return state;
      }

      if (action.fetchType === types.UPDATE_DISPLAY) {
        toast.error(`${statusCode}: Could not update display`);
        return state;
      }

      if (action.fetchType === types.DELETE_DISPLAY) {
        toast.error(`${statusCode}: Could not delete display`);
        return state;
      }
      
      return state;
    }

    case types.RESET_DISPLAY_FORM: {
      // Create form object
      const form = {
        name: get(state.display, "name", ""),
        reportId: get(state.display, "powerbi._id", undefined),
        floorId: get(state.display, "map.floorId", undefined),
        dashboardId: get(state.display, "dashboard.dashboardId", undefined),
        config: { ...get(state.display, "map.config", undefined) }
      };

      // Make sure to not reuse Object
      if (get(state.display, "map.config.yourLocation", null)) {
        form.config.yourLocation = { ...get(state.display, "map.config.yourLocation", {})};
      }

      return {...state, form };
    }

    case types.CLEAR_DATA: {
      return initialState;
    }
    
    default: {
      return state;
    }
  }
}
