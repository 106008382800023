import get from "lodash/get";
import { toast } from "react-toastify";
import * as types from "../ApiTypes";
import locations from "../containers/Locations/locations";

const initialState = {
  id: null,
  createdAt: null,
  updatedAt: null,
  completedAt: null,
  status: null,
  type: null,
  data: {},
  locations: null,
  gateways: null,
  sensors: null,
  user: null,
  canDelete: false,
  canRetry: false
};

export default function jobReducer(state = initialState, action) {

  switch (action.type) {

    case types.REQ_DATA: {

      if (action.fetchType === types.GET_JOB) {
        return { ...initialState, id: action.payload ? action.payload.jobId : null };
      }

      return state;
    }

    case types.RECV_DATA: {

      if (action.fetchType === types.GET_JOB) {
        return {
          ...initialState,
          id: action.payload._id,
          createdAt: action.payload.createdAt,
          updatedAt: action.payload.updatedAt,
          completedAt: action.payload.completedAt,
          status: get(action.payload, "status", "unknown"),
          type: action.payload.jobType,
          data: action.payload.jobData,
          locations: action.payload.locations,
          gateways: action.payload.gateways,
          sensors: action.payload.sensors,
          user: action.payload.user,
          canDelete: action.payload.canDelete,
          canRetry: action.payload.canRetry
        };
      }

      if (action.fetchType === types.DELETE_JOB) {
        toast.success(`Job deleted`);
        return initialState;
      }

      return state;
    }

    case types.RECV_ERROR: {

      const statusCode = get(action, "payload.response.status", "Error");

      if (action.fetchType === types.GET_JOB) {
        toast.error(`${statusCode}: Could not get job`);
        return state;
      }

      if (action.fetchType === types.DELETE_JOB) {
        toast.error(`${statusCode}: Could not delete job`);
        return state;
      }

      return state;
    }

    case types.CLEAR_DATA: {
      return initialState;
    }

    default: {
      return state;
    }
  }
}
