import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Switch, Route } from "react-router-dom";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import NotFound from "../NotFound";
import ScreenViewMap from "./screenViewMap";
import ScreenViewLayout from "./screenViewLayout";
import SelectLocation from "./selectLocation";
import * as screenViewActions from "../../actions/screenViews";

class ScreenViewContainer extends Component {

  componentDidMount() {
    if (isEmpty(this.props.match.params.screenViewId)) {
      this.props.clearScreenViewData();
    }
    else if (this.props.screenView.id !== this.props.match.params.screenViewId) {
      this.props.getScreenView(this.props.match.params.screenGroupId, this.props.match.params.screenViewId);
    }
  }

  componentDidUpdate(prevProps) {
    // Only load data if id change
    if (isEmpty(this.props.match.params.screenViewId)) {
      this.props.clearScreenViewData();
    }
    else if (this.props.screenView.id !== this.props.match.params.screenViewId) {
      this.props.getScreenView(this.props.match.params.screenGroupId, this.props.match.params.screenViewId);
    }
  }

  render() {
    // console.log("ScreenViewContainer", this.props);
    
    let pathToMap = null;
    let mapComponent = null;

    if (get(this.props.mapComponentPaths, this.props.match.params.mapIndex) !== undefined) {

       // Find all map and svg-map components in the layout
      const view = this.props.screenViewForm;
      const layout = get(view, "layout", {});

      // Get the map component from the path
      pathToMap = this.props.mapComponentPaths[this.props.match.params.mapIndex];
      mapComponent = get(layout, pathToMap);
    }

    return (
      <Switch>
        <Route path="/companies/:companyId/screens/edit/:screenGroupId/views/:screenViewId/map/:mapIndex/locations/:locationId" children={(props) => 
          <SelectLocation
            {...props}
            screenGroupId={this.props.match.params.screenGroupId}
            screenViewId={this.props.match.params.screenViewId}
            pathToMap={pathToMap}
            mapComponent={mapComponent}
          />}
        />
        <Route path="/companies/:companyId/screens/edit/:screenGroupId/views/:screenViewId/map/:mapIndex" children={(props) => 
          <ScreenViewMap 
            {...props} 
            screenGroupId={this.props.screenGroupId}
            screenViewId={this.props.screenViewId}
            pathToMap={pathToMap}
            mapComponent={mapComponent}
          />}
        />
        <Route path="/companies/:companyId/screens/edit/:screenGroupId/views/:screenViewId/layout" children={(props) => 
          <ScreenViewLayout
            {...props}
            screenGroupId={this.props.match.params.screenGroupId}
            screenViewId={this.props.match.params.screenViewId}
          />}
        />
        <Route render={NotFound} />
      </Switch>
    );
  }

}

function mapStateToProps(state) {
  return {
    screenView: state.screenView,
    screenViewForm: state.screenView.form
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    getScreenView: screenViewActions.getScreenView,
  }, dispatch);
}

export default connect(mapStateToProps,mapDispatchToProps)(ScreenViewContainer);
