import React from "react";
import style from "./style.module.scss"

const Freetext = ({header, headerDescription, content, onClick, onHeaderClick, link = null, inline = false, children}) => {
  if (link !== null) {
    const mailhref = `mailto: ${link}`;
    if (content.includes(link)) {
      const contentArray = content.split(link);
      return (<div className={style.freetext} onClick={onClick}>
        <h2 onClick={onHeaderClick}>{header} { headerDescription && (<span style={{ fontSize: "20px", color: "#888"}}>{headerDescription}</span>)}</h2>
        <p>{contentArray[0]}<a href={mailhref} style={{ color: "#00458F"}}>{link}</a>{contentArray[1]}</p>
      </div>)
    }
  }

  return (<div className={inline ? style.inlineFreetext : style.freetext} onClick={onClick}>
    <h2 onClick={onHeaderClick}>{header} { headerDescription && (<span style={{ fontSize: "20px", color: "#888"}}>{headerDescription}</span>)}</h2>
    <p>{content}</p>
  </div>);
};

export default Freetext;