import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Col, Row } from "react-grid-system";
import { isEmpty } from "lodash";
import SearchBox from "../../components/SearchBox";
import TopRowOptions from "../../components/TopRowOptions";
import Table from "../../components/Table";
import * as selectedActions from "../../actions/selected";

class GroupLocations extends Component {

  constructor(props) {
    super(props);
    this.state = {
      activeSearch: undefined,
      searchText: "",
      sortBy: "name",
      sortOrder: "asc",
      offset: 0,
      limit: 20,
    };
    this.onAddLocationClicked = this.onAddLocationClicked.bind(this);
    this.onSearchClick = this.onSearchClick.bind(this);
    this.onToggle = this.onToggle.bind(this);
    this.onRowClick = this.onRowClick.bind(this);
  }

  componentDidMount() {
    if (!isEmpty(this.props.userGroup.name)) {
      document.title = `BLDNG.ai - ${this.props.userGroup.name} - locations`;
    }
  }

  onAddLocationClicked() {
    const { push } = this.props.history;
    this.props.selectUserGroup(this.props.userGroup, push, "/locations/root/locations");
  }

  onSearchClick() {
    // console.log("GroupLocations.onSearchClick()", this.state.activeSearchText);
    this.setState({ activeSearch: this.state.searchText });
  }

  onRowClick(column, rowInfo) {
    return {
      onClick: e => {
        // console.log(rowInfo);
        if (rowInfo && column.name !== "isSelected") {
          let link = `/companies/${this.props.match.params.companyId}/locations/${rowInfo.original._id}/locations`;

          if (e.metaKey || e.ctrlKey) {
            window.open(`${link}`);
          }
          else {
            this.props.history.push(link);
          }
        }
      },
      style: {
        cursor: "pointer"
      }
    }
  }

  onToggle(row) {
    if (this.props.selectedLocations[row.original._id] !== undefined && this.props.selectedLocations[row.original._id]) {
      this.props.deselectLocation(row.original._id);
    }
    else {
      this.props.selectLocation(row.original);
    }
  }

  render() {
    const { isLoading } = this.props;
    // console.log("GroupLocations.render()", this.state);

    if (isEmpty(this.props.groupId)) {
      return null;
    }

    const anotherTypeSelected = this.props.selectedTypes.length > 0 && !this.props.selectedTypes.includes("location");

    // Filter locations based on search
    let locations = this.props.userGroup.locations;
    if (this.state.activeSearch) {
      locations = locations.filter(location => location.name.toLowerCase().includes(this.state.activeSearch.toLowerCase()));
    }

    // Sort locations
    locations = locations.sort((a, b) => {
      if (this.state.sortBy === "name") {
        if (this.state.sortOrder === "asc") {
          return a.name.localeCompare(b.name);
        }
        else {
          return b.name.localeCompare(a.name);
        }
      }
    });

    const tableElement = (
      <Table
        data={locations}
        sortBy={this.state.sortBy}
        sortOrder={this.state.sortOrder}
        onSortedChange={(newSorted) => {
          this.setState({ sortBy: newSorted[0].id, sortOrder: newSorted[0].desc ? "desc" : "asc" });
        }}
        noDataText={isLoading ? "" : "No locations found"}
        columns={[
          {
            id: "id",
            header: "",
            accessorKey: "_id",
            sortable: false,
            name: "isSelected",
            cell: ({ row }) => (
              <label className="checkboxContainer" htmlFor={`editCheckbox-${row.original._id}`}>
                <input
                  id={`editCheckbox-${row.original._id}`}
                  type="checkbox"
                  className="checkbox"
                  checked={(this.props.selectedLocations[row.original._id] !== undefined && this.props.selectedLocations[row.original._id])}
                  onChange={() => this.onToggle(row)}
                  disabled={anotherTypeSelected}
                />
                <span className={anotherTypeSelected ? "disabledCheckmark" : "checkmark"} />
              </label>
            ),
            width: 60
          },
          {
            header: "Name",
            accessorKey: "name",
            cell: ({ row }) => (<span>{row.original.name}</span>)
          },
          {
            id: "arrow",
            header: "",
            sortable: false,
            className: "pull-right",
            width: 60,
            cell: () => (<div className="arrow" />)
          }
        ]}
        getTdProps={this.onRowClick}
        className="setMaxHeigth -row-clickable -highlight"
        loading={isLoading}
      />
    );

    return (
      <>
        <TopRowOptions
          searchbox={(
            <SearchBox
              value={this.state.searchText}
              onSearchChanged={(value) => { this.setState({ searchText: value }) }}
              onSearchClick={this.onSearchClick}
              onClear={() => { this.setState({ activeSearch: undefined, searchText: "" }, this.onSearchClick) }}
              inDetailsView
            />
          )}
        />
        <Row>
          <Col xs={12} md={12}>
            { tableElement }
          </Col>
        </Row>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    userGroup: state.userGroup,
    selectedCompany: state.auth.selectedCompany,
    selectedLocations: state.selected.locations,
    selectedTypes: state.selected.allTypes,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    selectUserGroup: selectedActions.selectUserGroup,
    selectLocation: selectedActions.selectLocation,
    deselectLocation: selectedActions.deselectLocation
   }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(GroupLocations);
