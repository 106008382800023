import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { omitBy, isNil } from "lodash";
import moment from "moment";
import * as gatewayActions from "../../../../actions/gateways";
import Freetext from "../../../../components/Freetext";
import { CheckboxTree } from "../../../../components/CheckboxTree";
import { MainContainer } from "./MainContainer";
import { FilterActionFooter } from "./FilterActionFooter";
import { GatewayTable } from "./GatewayTable";
import { Filters } from "./FilterSection";
import styles from "./styles.module.scss";

class Gateways extends Component {
  constructor(props) {
    super(props);

    const urlParametersToFilterState = this._readUrlParametersAndSetFilters();
    const state = {
      expandedLocations: [],
      locations: [],
      filters: {
        fromDate: undefined,
        toDate: undefined,
        lastSampleAt: undefined,
        gatewayTableSearchValue: "",
        sortOrder: "desc",
        sortBy: "createdAt",
        offset: 0,
        activeSensors: undefined,
        selectedLocations: [],
        deselectedLocations: [],
        unassignedGateways: undefined
      },
      haveFiltersBeenUpdated: false,
      successfullyFetchedTheLocationHierarchy: false,
      resetFilters: false
    };

    this.state = {
      ...state,
      filters: {
        ...state.filters,
        ...urlParametersToFilterState
      }
    }

    this._fetchGateways = this._fetchGateways.bind(this);
    this._handleKeyDown = this._handleKeyDown.bind(this);
    this._updateUrlParametersWithFilters = this._updateUrlParametersWithFilters.bind(this);
    this._readUrlParametersAndSetFilters = this._readUrlParametersAndSetFilters.bind(this);
    this.handleUpdatesToFilter = this.handleUpdatesToFilter.bind(this);
    this.handleSearchClear = this.handleSearchClear.bind(this);
    this.resetFiltersToDefault = this.resetFiltersToDefault.bind(this);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if(prevState.successfullyFetchedTheLocationHierarchy) {
      return null;
    } 
   
    if(prevState.locations !== nextProps.locationHierarchy && nextProps.locationHierarchy !== undefined) {
      if(nextProps.locationHierarchy.length === 0) {
        return {successfullyFetchedTheLocationHierarchy: false}
      }

      return {
        locations: nextProps.locationHierarchy,
        successfullyFetchedTheLocationHierarchy: true
      }
    }
    else {
      return null;
    }
  }

  _readUrlParametersAndSetFilters() {
    let queryParams = new URLSearchParams(this.props.location.search);
    let objectToBeWrittenToState = {};

    if(this.props.location.search.length === 0) {}
    else {
      for(const [key, value] of queryParams) {
        switch(key) {
          case "from": 
            if(moment(value, moment.ISO_8601, true).isValid()) {
              Object.assign(objectToBeWrittenToState, {
                fromDate: value
              });
            }
            break;
          
          case "to":
            if(moment(value, moment.ISO_8601, true).isValid()) {
              Object.assign(objectToBeWrittenToState, {
                toDate: value
              });
            }
            break;

          case "lastSampleAt":
            if(moment(value, moment.ISO_8601, true).isValid()) {
              Object.assign(objectToBeWrittenToState, {
                lastSampleAt: value
              });
            }
            break;

          case "search":
            Object.assign(objectToBeWrittenToState, {
              gatewayTableSearchValue: value.toString()
            });
            break;

          case "sortOrder":
            if(value.toLowerCase() === "asc" || value.toLowerCase() === "desc") {
              Object.assign(objectToBeWrittenToState, {
                sortOrder: value
              });
            }
            break;

          case "orderBy":
            Object.assign(objectToBeWrittenToState, {
              sortBy: value
            });
            break;

          
          case "offset":
            if(parseInt(value)) {
              Object.assign(objectToBeWrittenToState, {
                offset: value
              });
            }
            break;

          case "activeSensors":
            if(parseInt(value)) {
              Object.assign(objectToBeWrittenToState, {
                activeSensors: parseInt(value)
              });
            }
            break;

          case "selectedLocations":
            try {
              const array = JSON.parse(value);
              Object.assign(objectToBeWrittenToState, {
                selectedLocations: array
              });
            }
            catch(error) {
              Object.assign(objectToBeWrittenToState, {
                selectedLocations: []
              });
            }
            break;

          case "deselectedLocations":
            try {
              const array = JSON.parse(value);
              Object.assign(objectToBeWrittenToState, {
                deselectedLocations: array
              });
            }
            catch(error) {
              Object.assign(objectToBeWrittenToState, {
                deselectedLocations: []
              });
            }
            break;

          
          case "unassignedGateways":
            Object.assign(objectToBeWrittenToState, {
              unassignedGateways: value === "true" ? true : false
            });
            break;

          default:
            console.error("This parameter key is not supported.");
            break;
        }
      }
    }

    return objectToBeWrittenToState;
  }

  _updateUrlParametersWithFilters(prevProps, prevState) {
    const { filters } = this.state;
    if(prevState.filters === filters) {
      return;
    }

    const { filters: {
      fromDate, 
      toDate, 
      lastSampleAt, 
      gatewayTableSearchValue, 
      sortOrder, 
      sortBy, 
      offset, 
      activeSensors,
      selectedLocations, 
      deselectedLocations,
      unassignedGateways
    }} = this.state;

    const validQueryParameters = omitBy(Object.assign({}, {
      from: fromDate,
      to: toDate,
      lastSampleAt,
      search: gatewayTableSearchValue.length > 0 ? gatewayTableSearchValue : undefined,
      sortOrder: sortOrder !== "desc" ? sortOrder : undefined,
      sortBy: sortBy !== "createdAt" ? sortBy : undefined,
      offset: offset !== 0 ? offset : undefined,
      activeSensors,
      selectedLocations: selectedLocations.length > 0 ? JSON.stringify(selectedLocations) : undefined,
      deselectedLocations: deselectedLocations.length > 0 ? JSON.stringify(deselectedLocations) : undefined,
      unassignedGateways
    }), isNil);

    let queryString = "?";

    for(const [index, [key, value]] of Object.entries(validQueryParameters).entries()) {
      if(index === 0) {
        queryString = queryString + `${key}=${value}`
      } 
      else {
        queryString = queryString + `&${key}=${value}`
      }
    }

    this.props.history.push({ 
      pathname: this.props.history.location.pathname.substr(0, this.props.history.location.pathname.length),
      search: queryString
    })
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      filters: {
        sortOrder,
        sortBy,
        offset,
        selectedLocations,
        deselectedLocations,
        unassignedGateways
      },
      filters,
      resetFilters
    } = this.state;

    let queryParams = new URLSearchParams(this.props.location.search).toString();

    if(
      queryParams.length === 0 
      && 
      (
        this.state.filters.selectedLocations.length > 0
        || this.state.filters.deselectedLocations.length > 0
        || this.state.filters.sortOrder !== "desc"
        || this.state.filters.toDate !== undefined
        || this.state.filters.fromDate !== undefined
        || this.state.filters.gatewayTableSearchValue !== ""
        || this.state.filters.sortBy !== "createdAt"
        || this.state.filters.offset !== 0
        || this.state.filters.unassignedGateways !== undefined
        || this.state.filters.lastSampleAt !== undefined
        || this.state.filters.activeSensors !== undefined
      )
      && JSON.stringify(this.state.filters) === JSON.stringify(prevState.filters) ) {
     
      window.location.reload();
      return;
    } 

    if(
      prevState.filters.sortOrder !== sortOrder ||
      prevState.filters.sortBy !== sortBy ||
      (prevState.filters !== filters && (prevState.filters.offset !== offset))
    ) {
      this._fetchGateways();
    }
    else if(
      prevState.filters.selectedLocations !== selectedLocations ||
      prevState.filters.deselectedLocations !== deselectedLocations ||
      prevState.filters.unassignedGateways !== unassignedGateways
    ) {
      this.setState(prevState => ({
        filters: {
          ...prevState.filters,
          offset: 0
        }
      }));
      this._fetchGateways();
    }
    
    if(resetFilters) {
      this.setState({ resetFilters: false });
      this._fetchGateways();
    }

    this._updateUrlParametersWithFilters(prevProps, prevState);
  }

  _handleKeyDown = (event) => {
    const { haveFiltersBeenUpdated } = this.state;
    if(event.keyCode === 13 && haveFiltersBeenUpdated) {
      this._fetchGateways();
    }
  };

  componentDidMount() {
    this._fetchGateways();

    document.addEventListener("keydown", this._handleKeyDown);
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this._handleKeyDown);
  }

  handleUpdatesToFilter(filters) {
    this.setState(prevState => ({
      filters: {
        ...prevState.filters,
        ...filters
      },
      haveFiltersBeenUpdated: true
    }));
  }

  handleSearchClear() {
    // Clear search state and refetch data
    this.setState(prevState => ({
      filters: {
        ...prevState.filters,
        gatewayTableSearchValue: ""
      }
    }), this._fetchGateways);
  }

  resetFiltersToDefault() {
    let selectedLocations = new URLSearchParams(this.props.location.search).get("selectedLocations");
    let deselectedLocations = new URLSearchParams(this.props.location.search).get("deselectedLocations");

    this.setState({
      filters: {
        fromDate: undefined,
        toDate: undefined,
        lastSampleAt: undefined,
        gatewayTableSearchValue: "",
        sortOrder: "desc",
        sortBy: "createdAt",
        offset: 0,
        activeSensors: undefined,
        selectedLocations: selectedLocations ? JSON.parse(selectedLocations) : [],
        deselectedLocations: deselectedLocations ? JSON.parse(deselectedLocations) : [],
        unassignedGateways: undefined
      },
      haveFiltersBeenUpdated: false,
      resetFilters: true
    });
  }

  _fetchGateways() {
    const { filters: {
      fromDate, 
      toDate, 
      lastSampleAt, 
      gatewayTableSearchValue, 
      sortOrder, 
      sortBy, 
      offset,
      activeSensors,
      selectedLocations, 
      deselectedLocations,
      unassignedGateways
    }} = this.state;
    const { getGateways } = this.props;

    const queryParams = Object.assign({}, {
      from: fromDate,
      to: toDate,
      lastSampleAt,
      search: gatewayTableSearchValue,
      sortOrder,
      sortBy,
      offset,
      activeSensors,
      selectedLocations: JSON.stringify(selectedLocations),
      deselectedLocations: JSON.stringify(deselectedLocations),
      unassignedGateways
    });

    getGateways(queryParams);

    this.setState({
      haveFiltersBeenUpdated: false
    });
  }

  render() {
    const { 
      haveFiltersBeenUpdated, 
      filters,
      filters: {
        selectedLocations,
        deselectedLocations,
        unassignedGateways
      },
      resetFilters
    } = this.state;
    const { 
      awaitingGatewayResponseFromServer,
      gatewayResponse,
      gatewayResponse: {
        countByLocation
      }, 
    } = this.props;

    return (
      <MainContainer>
        <div className={styles.row}>
          <div className={styles.filterCol}>
            <div className={styles.filterContainer}>

                <Freetext header="Location hierarchy" />

                <CheckboxTree 
                  nodes={this.state.locations}
                  countByLocation={countByLocation}
                  reset={resetFilters}
                  handleUpdatesToFilter={this.handleUpdatesToFilter}
                  userSelectedNodes={selectedLocations}
                  userDeselectedNodes={deselectedLocations}
                  unassignedFlag={unassignedGateways}
                  unassignedEntryLabel="Unassigned gateways"
                  stringToFetchUnassigned="unassignedGateways"
                  unassignedLabel="unassignedGateways"
                />
              
            </div>
          </div>
          <div className={styles.listCol}>
            <div className={styles.listContainer}>

                <Filters 
                  filters={filters}
                  handleUpdatesToFilter={this.handleUpdatesToFilter}
                  handleSearchClear={this.handleSearchClear}
                />
                
                <GatewayTable 
                  gatewayResponse={gatewayResponse}
                  filters={filters}
                  handleUpdatesToFilter={this.handleUpdatesToFilter}
                  awaitingGatewayResponseFromServer={awaitingGatewayResponseFromServer}
                  history={this.props.history}
                  match={this.props.match}
                />

            </div>
          </div>
        </div>

        <FilterActionFooter
          resetFiltersToDefault={this.resetFiltersToDefault}
          fetchGateways={this._fetchGateways}
          haveFiltersBeenUpdated={haveFiltersBeenUpdated}
        />
      </MainContainer>
    );
  }
}

function mapStateToProps(state) {
  return {
    locationHierarchy: state.locations.hierarchy,
    gatewayResponse: state.gateways.metricData,
    awaitingGatewayResponseFromServer: state.loading.gateways,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    getGateways: gatewayActions.getAllGateways,
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(Gateways);
