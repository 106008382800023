import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Col, Row } from "react-grid-system";
import { get, isEmpty } from "lodash";
import SmallButton from "../../components/SmallButton";
import SearchBox from "../../components/SearchBox";
import Table from "../../components/Table";
import NotificationModal from "../../components/NotificationModal";
import * as selectedActions from "../../actions/selected";
import * as contentActions from "../../actions/content";
import style from "./style.module.scss"
import TopRowOptions from "../../components/TopRowOptions";

class ContentGroups extends Component {

  constructor(props) {
    super(props);
    this.state = {
      searchText: undefined,
      activeSearch: undefined,
      sortBy: "name",
      sortOrder: "asc",
      showNotificationModal: false,
      notificationTitle: "",
      notificationMessage: ""
    };
    this.onAddGroupClicked = this.onAddGroupClicked.bind(this);
    this.onNotifyClicked = this.onNotifyClicked.bind(this);
    this.onRowClick = this.onRowClick.bind(this);
    this.sendNotification = this.sendNotification.bind(this);
  }

  componentDidMount() {
    if (!isEmpty(this.props.content.title)) {
      document.title = `BLDNG.ai - ${this.props.content.title} - groups`;
    }
  }

  onAddGroupClicked() {
    const { push } = this.props.history;
    this.props.selectContent(this.props.content, push, `/companies/${this.props.match.params.companyId}/org/groups`);
  }

  onNotifyClicked() {
    this.setState({ showNotificationModal: true });
  }

  onRowClick(column, rowInfo) {
    return {
      onClick: e => {
        if (column.name !== 'isSelected' && rowInfo !== undefined) {
          let link = `/companies/${this.props.match.params.companyId}/org/groups/${rowInfo.original._id}/users`;

          if (e.metaKey || e.ctrlKey) {
            window.open(`${link}`);
          }
          else {
            this.props.history.push(link);
          }
        }
      },
      style: {
        cursor: "pointer"
      }
    }
  }

  onToggle(row) {
    if (this.props.selectedUserGroups[row.original._id] !== undefined && this.props.selectedUserGroups[row.original._id]) {
      this.props.deselectUserGroup(row.original._id);
    }
    else {
      this.props.selectUserGroup(row.original);
    }
  }

  sendNotification() {
    this.props.sendNotification(this.props.content, this.state.notificationTitle, this.state.notificationMessage);
    this.setState({ showNotificationModal: false })
  }

  render() {
    // console.log("ContentDetailsGroup.render()", this.props);
    const { isLoading } = this.props;

    if (isEmpty(this.props.contentId)) {
      return null;
    }

    const canUpdateContent = this.props.content.canEdit;

    // Check if user can update some groups also
    const canUpdateGroup = this.props.auth.hasAdminRole;

    const options = (
      <TopRowOptions
        searchbox={(
          <SearchBox
            value={this.state.searchText}
            onSearchChanged={(value) => this.setState({ searchText: value === '' ? undefined : value })}
            onSearchClick={() => this.setState(prevState => ({ activeSearch: prevState.searchText }))}
            onClear={() => this.setState({ searchText: undefined, activeSearch: undefined })}
            inDetailsView
          />
        )}
        buttons={[
          <SmallButton key="b1" text="Notify" onClick={this.onNotifyClicked} noMargin />,
          <SmallButton key="b2" text="Add group" onClick={this.onAddGroupClicked} disabled={!(canUpdateContent || canUpdateGroup)} onlyLeftMargin />
        ]}
      />
    );

    const anotherTypeSelected = this.props.selectedTypes.length > 0 && !this.props.selectedTypes.includes("userGroup");

    // Filter out groups that are not in the search
    let filteredGroups = this.props.content.userGroups;
    if (this.state.activeSearch !== undefined) {
      filteredGroups = filteredGroups.filter(group => group.name.toLowerCase().includes(this.state.activeSearch.toLowerCase()));
    }

    // Sort the groups
    filteredGroups = filteredGroups.sort((a, b) => {
      if (this.state.sortOrder === "asc") {
        return a[this.state.sortBy] > b[this.state.sortBy] ? 1 : -1;
      }
      else {
        return a[this.state.sortBy] < b[this.state.sortBy] ? 1 : -1;
      }
    });

    const tableElement = (
      <Table
        data={filteredGroups}
        noDataText={isLoading ? "" : "No groups found"}
        sortBy={this.state.sortBy}
        sortOrder={this.state.sortOrder}
        onSortedChange={(newSorted) => {
          this.setState({
            sortBy: newSorted[0].id,
            sortOrder: newSorted[0].desc ? "desc" : "asc"
          });
        }}
        columns={[
          {
            id: "id",
            header: "",
            accessorKey: "_id",
            sortable: false,
            name: "isSelected",
            cell: ({ row }) => (
              <label className="checkboxContainer" htmlFor={`editCheckbox-${row.original._id}`}>
                <input
                  id={`editCheckbox-${row.original._id}`}
                  type="checkbox"
                  className="checkbox"
                  checked={(this.props.selectedUserGroups[row.original._id] !== undefined && this.props.selectedUserGroups[row.original._id])}
                  onChange={() => this.onToggle(row)}
                  disabled={anotherTypeSelected}
                />
                <span className={anotherTypeSelected ? "disabledCheckmark" : "checkmark"} />
              </label>
            ),
            width: 60
          },
          {
            header: "Name",
            accessorKey: "name",
            cell: ({ row }) => (<span>{row.original.name}</span>)
          },
          {
            id: "arrow",
            header: "",
            sortable: false,
            className: "pull-right",
            width: 60,
            cell: () => (<div className="arrow" />)
          }
        ]}
        getTdProps={this.onRowClick}
        className="setMaxHeigth -row-clickable -highlight"
        loading={isLoading}
      />
    );

    return (
      <>
        { options }
        <Row>
          <Col xs={12} md={12}>
            { tableElement }
          </Col>
        </Row>

        <NotificationModal
          show={this.state.showNotificationModal}
          onHide={() => this.setState({ showNotificationModal: false })}
          title="Send notification"
          text={"All users that have access to the content will receive a push notification. NB! Be careful that you don't send too many messages since that can make them turn notifications off."}
          titleLabel="Title"
          titleValue={this.state.notificationTitle}
          titleValid={this.state.notificationTitle}
          onTitleChange={(event) => this.setState({ notificationTitle: event.target.value })}
          messageLabel="Message"
          messageValue={this.state.notificationMessage}
          messageValid={this.state.notificationMessage}
          onMessageChange={(event) => this.setState({ notificationMessage: event.target.value })}
          primaryBtn={{
            text: "Send",
            onClick: this.sendNotification
          }}
          secondaryBtn={{
            text: "Close",
            onClick: () => this.setState({ showNotificationModal: false })
          }}
        />
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    content: state.content,
    auth: state.auth,
    selectedCompany: state.auth.selectedCompany,
    selectedUserGroups: state.selected.userGroups,
    selectedCompanyId: state.auth.selectedCompany._id,
    selectedTypes: state.selected.allTypes,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    selectContent: selectedActions.selectContent,
    selectUserGroup: selectedActions.selectUserGroup,
    deselectUserGroup: selectedActions.deselectUserGroup,
    sendNotification: contentActions.sendNotification
   }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(ContentGroups);
