import React, { Component } from "react";
import { Hidden, Visible } from "react-grid-system";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import isEmpty from "lodash/isEmpty";
import get from "lodash/get";
import JSONEditorReact from "./editor";
import Loader from "../../components/Loader";
import TopRowOptions from "../../components/TopRowOptions";
import ScreenViewLayoutDetails from "./screenViewLayoutDetails";
import * as screenViewActions from "../../actions/screenViews";
import style from "./style.module.scss";

class ScreenViewLayout extends Component {

  constructor(props) {
    super(props);

    this.state = {
      jsonText: null,
      selectionPath: [],
      showAddView: false
    }

    this.onAddLayoutElementClicked = this.onAddLayoutElementClicked.bind(this);
    this.onLayoutChanged = this.onLayoutChanged.bind(this);
    this.onSelectionChanged = this.onSelectionChanged.bind(this);
    this.onShowAddView = this.onShowAddView.bind(this);
  }
  
  // Put the layout-object in the state as text
  static getDerivedStateFromProps(newProps, prevState) {

    // If state.jsonText is invalid json keep it - since it is in edit mode
    if (prevState.jsonText) {
      try {
        JSON.parse(prevState.jsonText);
      }
      catch (error) {
        // console.log("Error parsing jsonText", error);
        return null;
      }
    }

    // If the edit view is empty, return null
    if (prevState.jsonText === "") {
      return null;
    }
      
    const view = newProps.screenViewForm;
    if (view) {

      const layoutString = JSON.stringify(view.layout ?? {}, null, 2);

      // If no previous state, return the layout
      if (prevState.jsonText === null) {
        return { jsonText: layoutString };
      }

      // If no change in form, return null
      const existingFormatedLayoutString = JSON.stringify(JSON.parse(prevState.jsonText) ?? {}, null, 2);
      if (layoutString === existingFormatedLayoutString) {
        return null;
      }

      // If there is a meaningful change in the form, return the new layout
      return { jsonText: layoutString };
    }

    return null;
  }

  onAddLayoutElementClicked(path, element) {
    console.log("Add layout element clicked", path, element);

    const form = JSON.parse(JSON.stringify(this.props.screenViewForm));

    // Get the selected object from the selectionPath
    let layout = null;
    try {
      layout = JSON.parse(this.state.jsonText);
    }
    catch (error) {}

    // If the layout is empty, create a new one
    if (isEmpty(layout)) {
      layout = element;

      try {
        form.layout = layout;

        // Update the layout selection path
        this.setState({ jsonText: null, selectionPath: ["children"], showAddView: false });

        // Update the form with the new layout
        this.props.updateScreenViewForm(form);

      }
      catch (error) {}

      return;
    }

    let pathToContainer = null;
    let container = null;
    let indexOfSelectedChild = -2; // -1 means before the first child, -2 means unknown
    for (let i = 0; i < path.length - 1; i++) {
      const apath = path.slice(0, i);

      let layoutElement;
      if (apath.length === 0) {
        layoutElement = layout;
      }
      else {
        layoutElement = get(layout, apath.join("."), null);
      }

      if (layoutElement && layoutElement.class === "container") {
        if (path[i] === "children" && typeof path[i + 1] === "number") {
          container = layoutElement;
          pathToContainer = apath;
          indexOfSelectedChild = path[i + 1];
        }
      }
    }

    // console.log("container", container);
    // console.log("pathToContainer", pathToContainer);
    // console.log("indexOfSelectedChild", indexOfSelectedChild);

    // If we found a container, add the element to the children array
    if (container) {

      let indexToNewElement = 0;
      if (container.children === undefined) {
        container.children = [];
      }

      if (indexOfSelectedChild > -2) {
        container.children.splice(indexOfSelectedChild + 1, 0, element);
        indexToNewElement = indexOfSelectedChild + 1;
      }
      else {
        container.children.push(element);
        indexToNewElement = container.children.length - 1;
      }

      form.layout = layout;

      // Update the form with the new layout
      this.props.updateScreenViewForm(form);

      // Update the layout selection path
      this.setState({ selectionPath: [...pathToContainer, "children", indexToNewElement], showAddView: false });
    }
  }

  onLayoutChanged(editorValue) {
    // console.log("ScreenConfigLayout.onLayoutChanged", editorValue);

    const form = JSON.parse(JSON.stringify(this.props.screenViewForm));

    let layout = this.state.jsonText;

    // Save either from JSON or text
    if (editorValue.json) {
      try {
        layout = JSON.stringify(editorValue.json, null, 2);
      }
      catch (error) {
        layout = "";
      }
    }
    else if (editorValue.text) {
      if (editorValue.text === "") {
        layout = "";
      }
      else {
        layout = editorValue.text;
      }
    }
    else {
      layout = "";
    }

    // Try to parse the layout
    if (!isEmpty(layout)) {
      try {
        const layoutObject = JSON.parse(layout);

        // Check for changes in the layout
        const existingLayoutString = JSON.stringify(form.layout ?? {}, null, 2);
        const newLayoutString = JSON.stringify(layoutObject ?? {}, null, 2);

        if (existingLayoutString !== newLayoutString) {
          // Update the layout
          form.layout = layoutObject;
          this.props.updateScreenViewForm(form);
        }

      }
      catch (error) {
        // console.error("Error parsing layout", error);
      }
    }

    // Update the state
    this.setState({ jsonText: layout });
  }

  onSelectionChanged(selection) {
    // console.log("ScreenConfigLayout.onSelectionChanged", selection);

    // Wait for the selection to be stable (when the user stops writing)
    setTimeout(() => {

      // Get the path to the selected object
      const range = selection.ranges[0];
      const text = this.state.jsonText;
      const start = range.anchor - 1;
      // console.log("ScreenConfigLayout.onSelectionChanged", start, text);

      const path = findSelectedObjectPath(text, start);

      // Save the path
      this.setState({ selectionPath: path, showAddView: false });
    }, 100);
  }

  onShowAddView() {
    this.setState({ showAddView: true });
  }

  render() {
    // console.log("ScreenConfigLayout.render.state", this.state);
    // console.log("ScreenConfigLayout.render.props", this.props);

    const canEdit = this.props.auth.hasSupportRole;

    if (!canEdit) {
      return (
        <div className={style.singleView}>
          <div className={style.slimScroll}>

          </div>
        </div>
      );
    }

    if (this.props.isLoading) {
      return <Loader fullScreen />;
    }

    const view = this.props.screenViewForm;

    if (!view) {
      return (
        <div className={style.singleView}>
          <div className={style.slimScroll}>

          </div>
        </div>
      );
    }

    // const layoutString = JSON.stringify(view.layout ?? {}, null, 2);
    const layoutContent = { text: this.state.jsonText };

    const layoutElement = (
      <>
        <div style={{ paddingTop: "15px" }} />
        <JSONEditorReact 
          content={layoutContent}
          onChange={this.onLayoutChanged} 
          mode="text"
          indentation={2}
          tabSize={2}
          mainMenuBar={true}
          navigationBar={true}
          statusBar={true}
          onRenderMenu={(items) => {

            // Remove first 4 items
            items.splice(0, 4);

            // Remove transform
            // items = items.filter((item) => item.className !== "jse-transform");

            // Remove elements 3-6 (sort, transform, search and separator)
            items.splice(2, 4);

            // Add new component button
            // items.push({
            //   type: "button",
            //   className: "jse-new-component",
            //   title: "New component",
            //   icon: faPlus,
            //   onClick: this.onShowAddView,
            // });

            return items;
          }}
          onSelect={this.onSelectionChanged}
        />
        <div style={{ paddingTop: "10px" }} />
      </>
    );

    const description = `The layout for a view is built on a comprehensive system that combines CSS with pre-built components and a query language to fetch specific data from the installation. This result in a file that looks complex, but if you understand CSS and read the component documentation, then you can almost create anything you want without any actual programming knowledge.`;
    const help = `Tip: Click on a container or component to see the documentation for the selected object. Click inside children in a container to add a new component.`;

    const options = (
      <TopRowOptions
        description={description}
      />
    );

    return (
      <>
        <Hidden xs sm md>
          <div className={style.singleView}>
            <div className={style.row}>
              <div className={style.slimListContainer}>
                <div className={style.scroll}>
                  { options }
                  { layoutElement }
                  <p>{ help }</p>
                </div>
              </div>
              <div className={style.bigSideBar}>
                <div className={style.scroll}>
                  <ScreenViewLayoutDetails
                    layout={this.state.jsonText}
                    selectionPath={this.state.selectionPath}
                    showAddView={this.state.showAddView}
                    auth={this.props.auth}
                    onAddClicked={this.onAddLayoutElementClicked}
                  />
                </div>
              </div>
            </div>
          </div>
        </Hidden>

        <Visible xs sm md>
          <div className={style.singleView}>
            <div className={style.slimScroll}>
              { options }
              { layoutElement }
            </div>
          </div>
        </Visible>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    screenView: state.screenView,
    screenViewForm: state.screenView.form,
    isLoading: state.loading.screen,
    selectedCompany: state.auth.selectedCompany,
    auth: state.auth,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ 
    updateScreenViewForm: screenViewActions.updateScreenViewForm,
  }, dispatch);
}

export default connect(mapStateToProps,mapDispatchToProps)(ScreenViewLayout);

const findSelectedObjectPath = (text, start, depth = 0) => {
  
  if (start === -1) {
    return [];
  }
  
  // Find the preceding { before the start index (but for each } we find, we need to find a matching {)
  let objectPropertyNameEndIndex = -1;
  
  let lastBracket = null;
  let numObjects = 0;
  let numOfOpenEndedBrackets = 0;
  let numOfOpenEndedSquareBrackets = 0;

  let foundObjectContainer = false;
  let foundArrayContainer = false;

  let foundComma = false;

  for (let i = start; i >= 0; i--) {
    const char = text[i];
    // console.log("findSelectedObjectPath.char:", JSON.stringify(char), "numOEB:", numOfOpenEndedBrackets, "numOESB:", numOfOpenEndedSquareBrackets, "numObjects:", numObjects, "foundObjectContainer", foundObjectContainer, "foundArrayContainer", foundArrayContainer);
    // if (!foundComma && depth === 0) {
    //   console.log("findSelectedObjectPath.char:", JSON.stringify(char), "numOEB:", numOfOpenEndedBrackets, "numOESB:", numOfOpenEndedSquareBrackets, "numObjects:", numObjects);
    // }

    if (char === ",") {
      foundComma = true;
    }

    // Check if the next character is a backslash, if so, we are in a string and should skip the next character
    if (i > 0 && text[i-1] === "\\") {
      // console.log("skipping next character");
      i--;
      continue;
    }

    if (char === ":") {
      // If we are in a object, find the end of the property name (key)
      if (lastBracket === "{" && numOfOpenEndedBrackets === 0 && foundObjectContainer) {
        objectPropertyNameEndIndex = i;
        break;
      }
      
      // If we are in an array, find the end of the property name (key)
      if (lastBracket === "[" && numOfOpenEndedSquareBrackets === 0 && foundArrayContainer) {
        objectPropertyNameEndIndex = i;
        break;
      }
    }

    // If we detect a new object, wait for it to be closed before looking for the property name or count the number of objects
    if (char === "}") {
      numOfOpenEndedBrackets++;
    }
    
    // Close the object if we are in an open ended object
    if (char === "{") {
      if (numOfOpenEndedBrackets > 0) {
        numOfOpenEndedBrackets--;

        if (numOfOpenEndedBrackets === 0) {
          numObjects++;
        }
      }
      else {
        // Found the object the cursor is in
        foundObjectContainer = true;
        numObjects = 0;
      }
    }

    // If we detect a new array, wait for it to be closed before looking for the property name or count the number of objects
    if (char === "]") {
      numOfOpenEndedSquareBrackets++;
    }
    
    // Close the array if we are in an open ended array
    if (char === "[") {

      if (numOfOpenEndedSquareBrackets > 0) {
        numOfOpenEndedSquareBrackets--;
      }
      else {
        // Found the array the cursor is in
        foundArrayContainer = true;
      }
    }

    // Remember the last bracket we found
    if (["[", "]", "{", "}"].includes(char)) {
      lastBracket = char;
    }
  }

  // console.log("objectPropertyNameEndIndex", objectPropertyNameEndIndex);
  // console.log("numOfOpenEndedBrackets", numOfOpenEndedBrackets);
  // console.log("numOfOpenEndedSquareBrackets", numOfOpenEndedSquareBrackets);
  // console.log("numObjects", numObjects);

  // Find the property name (key) before the start index
  const objectPropertyStartIndex = text.lastIndexOf("\"", objectPropertyNameEndIndex - 2);
  if (objectPropertyStartIndex === -1) {
    return [];
  }

  const objectProperty = text.substring(objectPropertyStartIndex + 1, objectPropertyNameEndIndex - 1);

  // If we are in an array, find the index of the current object
  if (lastBracket === "[") {

    // Count one half less if we did not start inside an object
    // - that way we can add new objects between existing objects
    if (!foundObjectContainer) {
        numObjects = numObjects - 0.5;
    }

    return [...findSelectedObjectPath(text, objectPropertyStartIndex - 1, depth + 1), objectProperty, numObjects];
  }

  return [...findSelectedObjectPath(text, objectPropertyStartIndex - 1, depth + 1), objectProperty];
}
