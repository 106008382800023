import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Calendar from '../Calendar';
import { rangeShape } from '../DayCell';
import { generateStyles } from '../../utils';
import classnames from 'classnames';
import coreStyles from '../../styles';

class DateRange extends Component {

  constructor(props, context) {
    super(props, context);
    this.state = {
      
    };
    this.styles = generateStyles([coreStyles, props.classNames]);
  }

  render() {
    return (
      <Calendar
        {...this.props}
        displayMode="dateRange"
        direction="horizontal"
        className={classnames(this.styles.dateRangeWrapper, this.props.className)}
        onChange={this.props.onChange}
        updateRange={this.props.onChange}
        ref={target => {
          this.calendar = target;
        }}
      />
    );
  }
}

DateRange.defaultProps = {
  classNames: {},
  ranges: [],
  moveRangeOnFirstSelection: false,
  retainEndDateOnFirstSelection: false,
  rangeColors: ['#3d91ff', '#3ecf8e', '#fed14c'],
  disabledDates: []
};

DateRange.propTypes = {
  ...Calendar.propTypes,
  onChange: PropTypes.func,
  className: PropTypes.string,
  ranges: PropTypes.arrayOf(rangeShape),
  moveRangeOnFirstSelection: PropTypes.bool,
  retainEndDateOnFirstSelection: PropTypes.bool,
};

export default DateRange;
