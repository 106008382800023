import axios from "axios";
import Cookies from "universal-cookie";
import * as types from "../ApiTypes";
import * as actions from "./sharedFunction";
import { API_URL, COOKIE_PREFIX } from "../env";
import { getCompanyId } from "../helpers";

const cookies = new Cookies();

export const getFilters = (queryParams) => async (dispatch) => {
  dispatch(actions.requestData(types.GET_FILTERS));

  const apiUrl = await API_URL();
  const companyId = getCompanyId();
  const cookiePrefix = await COOKIE_PREFIX();
  const accessToken = cookies.get(`${cookiePrefix}_access_token`);

  axios.get(`${apiUrl}companies/${companyId}/filters`, await actions.getAxiosConfig(queryParams))
    .then((response) => {
      dispatch(actions.receiveData(response.data, types.GET_FILTERS));
    })
    .catch((error) => {
      if (error.response && error.response.status === 401) {
        actions.sessionTokenExpired(cookiePrefix, accessToken);
      } else {
        dispatch(actions.receiveError(error, types.GET_FILTERS));
      }
    });
};

export const getFilter = (id) => async (dispatch) => {
  dispatch(actions.requestData(types.GET_FILTER));

  const apiUrl = await API_URL();
  const companyId = getCompanyId();
  const cookiePrefix = await COOKIE_PREFIX();
  const accessToken = cookies.get(`${cookiePrefix}_access_token`);

  axios.get(`${apiUrl}companies/${companyId}/filters/${id}`, await actions.getAxiosConfig())
    .then((response) => {
      dispatch(actions.receiveData(response.data, types.GET_FILTER));
    })
    .catch((error) => {
      if (error.response && error.response.status === 401) {
        actions.sessionTokenExpired(cookiePrefix, accessToken);
      } else {
        dispatch(actions.receiveError(error, types.GET_FILTER));
      }
    });
};

export const loadFilter = (id) => async (dispatch) => {
  dispatch(actions.requestData(types.LOAD_FILTER));

  const apiUrl = await API_URL();
  const companyId = getCompanyId();
  const cookiePrefix = await COOKIE_PREFIX();
  const accessToken = cookies.get(`${cookiePrefix}_access_token`);

  axios.get(`${apiUrl}companies/${companyId}/filters/${id}`, await actions.getAxiosConfig())
    .then((response) => {
      dispatch(actions.receiveData(response.data, types.LOAD_FILTER));
    })
    .catch((error) => {
      if (error.response && error.response.status === 401) {
        actions.sessionTokenExpired(cookiePrefix, accessToken);
      } else {
        dispatch(actions.receiveError(error, types.LOAD_FILTER));
      }
    });
};

export const setFilter = (filter = {}, locationId, redirect) => async (dispatch) => {
  dispatch(actions.receiveData(filter, types.LOAD_FILTER));
  if (redirect && locationId) {
    const companyId = getCompanyId();
    redirect(`/companies/${companyId}/home/dash?l=${locationId}`);
  }
};

export const createFilter = (body, redirect) => async (dispatch) => {
  dispatch(actions.postData(types.CREATE_FILTER));

  const apiUrl = await API_URL();
  const companyId = getCompanyId();
  const cookiePrefix = await COOKIE_PREFIX();
  const accessToken = cookies.get(`${cookiePrefix}_access_token`);
  
  axios.post(`${apiUrl}companies/${companyId}/filters`, body, await actions.getAxiosConfig())
    .then((response) => {
      if (response.data.id) {
        dispatch(actions.receiveData({ id: response.data.id, ...body }, types.CREATE_FILTER));
        if (redirect) {
          redirect(`/companies/${companyId}/home/dash?f=${response.data.id}`);
        }
      }
      else {
        dispatch(actions.receiveError(null, types.CREATE_FILTER));
      }
    })
    .catch((error) => {
      if (error.response && error.response.status === 401) {
        actions.sessionTokenExpired(cookiePrefix, accessToken);
      } else {
        dispatch(actions.receiveError(error, types.CREATE_FILTER));
      }
    });
};

export const updateFilter = (filterId, body, redirect) => async (dispatch) => {
  dispatch(actions.postData(types.UPDATE_FILTER));

  const apiUrl = await API_URL();
  const companyId = getCompanyId();
  const cookiePrefix = await COOKIE_PREFIX();
  const accessToken = cookies.get(`${cookiePrefix}_access_token`);

  axios.put(`${apiUrl}companies/${companyId}/filters/${filterId}`, body, await actions.getAxiosConfig())
    .then((response) => {
      dispatch(actions.receiveData({ id: filterId, ...body }, types.UPDATE_FILTER));
      dispatch(getFilters());
    })
    .catch((error) => {
      if (error.response && error.response.status === 401) {
        actions.sessionTokenExpired(cookiePrefix, accessToken);
      } else {
        dispatch(actions.receiveError(error, types.UPDATE_FILTER));
      }
    });
};

// export const deleteFilter = (filterId, redirect) => async (dispatch) => {
//   dispatch(actions.requestData(types.DELETE_FILTER));

//   const apiUrl = await API_URL();
//   const companyId = getCompanyId();
//   const cookiePrefix = await COOKIE_PREFIX();
//   const accessToken = cookies.get(`${cookiePrefix}_access_token`);
  
//   axios.delete(`${apiUrl}companies/${companyId}/filters/${filterId}`, await actions.getAxiosConfig())
//     .then((response) => {
//       dispatch(actions.receiveData(response.data, types.DELETE_FILTER));
//       dispatch(getFilters())
//     })
//     .catch((error) => {
//       if (error.response && error.response.status === 401) {
//         actions.sessionTokenExpired(cookiePrefix, accessToken);
//       } else {
//         dispatch(actions.receiveError(error, types.DELETE_FILTER));
//       }
//     });
// };

export const deleteFilters = (filterIds) => async (dispatch) => {

  dispatch(actions.requestData(types.DELETE_FILTERS));

  const apiUrl = await API_URL();
  const companyId = getCompanyId();
  const cookiePrefix = await COOKIE_PREFIX();
  const accessToken = cookies.get(`${cookiePrefix}_access_token`);
  const config = await actions.getAxiosConfig();

  // Looping through all filter ids... 
  axios.all(filterIds.map((filterId) => (
      axios.delete(`${apiUrl}companies/${companyId}/filters/${filterId}`, config)
    )))
    .then((response) => {
      dispatch(actions.receiveData(response.data, types.DELETE_FILTERS));
      dispatch(getFilters())
    })
    .catch((error) => {
      if (error.response && error.response.status === 401) {
        actions.sessionTokenExpired(cookiePrefix, accessToken);
      } else {
        dispatch(actions.receiveError(error, types.DELETE_FILTERS));
      }
    });
}

export const updateFilterDraft = (queries, id, name, redirect) => (dispatch) => {
  dispatch({ type: types.UPDATE_FILTER_DRAFT, payload: { id, name, queries } });

  if (redirect) {
    const companyId = getCompanyId();
    redirect(`/companies/${companyId}/home/dash?f=${id}`);
  }
};

export const highlightFilter = (filter) => (dispatch) => {
  dispatch({ type: types.HIGHLIGHT_FILTER, payload: filter });
};

export const clearHighlightedFilter = () => (dispatch) => {
  dispatch({ type: types.CLEAR_HIGHLIGHTED_FILTER });
};

export const selectFilter = (filter) => (dispatch) => {
  dispatch({ type: types.SELECT_FILTER, payload: filter });
};

export const deselectFilter = (filter) => (dispatch) => {
  dispatch({ type: types.DESELECT_FILTER, payload: filter });
};

export const clearAllSelectFilters = () => (dispatch) => {
  dispatch({ type: types.CLEAR_ALL_SELECTED_FILTERS });
};