import LocalizedStrings from "react-localization";

export default new LocalizedStrings({
  en: {
    unknown: "Unknown",
    online: "Online",
    offline: "Offline",
    stale: "Stale",
    disabled: "Unknown"
  }
});
