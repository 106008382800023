import React from "react";
import { Col, Row } from "react-grid-system";
import Button from "../Button";
import InputGroup from "../InputGroup";
import style from "./style.module.scss";
import InputBox from "../InputBox";

const InputModal = ({ show, onHide, title, text, primaryBtn, secondaryBtn, inputLabel, inputValid, inputValue, onInputChange, children }) => (
  show && (
    <div className={style.wrapper}>
      <div className={style.overlay} onClick={onHide} role="presentation" />
      <div className={style.modal}>
        <h1 className={style.title}>{title}</h1>
        <p className={style.text}>{text}</p>
        <Row style={{ marginTop: "20px" }}>
          <Col sm={12} md={12} lg={12}>
            <InputBox
              label={inputLabel} 
              valid={inputValid} 
              value={inputValue} 
              onChange={onInputChange} 
              required
            />
          </Col>
        </Row>
        { children }
        <Row style={{ marginTop: "40px" }}>
          <Col xs={12} sm={8} md={6} lg={6} offset={{ sm: 2, md: 0 }}>
            {secondaryBtn && <Button color="white" {...secondaryBtn} />}
          </Col>
          <Col xs={12} sm={8} md={6} lg={6} offset={{ sm: 2, md: 0 }}>
            {primaryBtn && <Button {...primaryBtn} disabled={!inputValid}/>}
          </Col>
        </Row>
      </div>
    </div>
  )
);

export default InputModal;