import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Col, Row } from "react-grid-system";
import { isEmpty } from "lodash";
import Checkbox from "../../components/Checkbox";
import InputBox from "../../components/InputBox";
import * as contentActions from "../../actions/content";

class ContentInfo extends Component {

  constructor(props) {
    super(props);
    this.onShowInGlobalViewChange = this.onShowInGlobalViewChange.bind(this);
    this.onContentIndexChange = this.onContentIndexChange.bind(this);
  }

  componentDidMount() {
    if (!isEmpty(this.props.content.title)) {
      document.title = `BLDNG.ai - ${this.props.content.title} - config`;
    }
  }

  onShowInGlobalViewChange(event) {
    
    if (isEmpty(this.props.content.id)) {
      return;
    }

    var newContent = {};
    newContent.showInGlobalView = event.target.checked;
    this.props.draftContentChange(newContent);
  }

  onContentIndexChange(event) {

    var newContent = {};
    const value = parseInt(event.target.value);
    if (Number.isInteger(value) && value > -1) {
      newContent.index = event.target.value;
    }
    else {
      newContent.index = null;
    }

    this.props.draftContentChange(newContent);
  }

  render() {
    
    if (isEmpty(this.props.contentId)) {
      return null;
    }

    const canUpdateContent = this.props.content.canEdit;

    var showInGlobalView = false;
    if (isEmpty(this.props.content.draft)) {
      showInGlobalView = this.props.content.showInGlobalView;
    }
    else {
      showInGlobalView = this.props.content.draft.showInGlobalView;
    }

    var index = "";
    if (isEmpty(this.props.content.draft)) {
      index = this.props.content.index;
    }
    else {
      index = this.props.content.draft.index ?? "";
    }

    return (
      <>
        <div style={{ paddingTop: "40px" }} />
        <Row>
          <Col>
            <Checkbox label="Show content on the front page in the app" isChecked={showInGlobalView} onClick={this.onShowInGlobalViewChange} disabled={!canUpdateContent}/>
          </Col>
        </Row>
        {
          this.props.auth.hasSupportRole && (
            <Row>
              <Col>
                <InputBox label="Content display index" value={index} onChange={this.onContentIndexChange} />
              </Col>
            </Row>
          )
        }
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    content: state.content,
    auth: state.auth,
    selectedCompany: state.auth.selectedCompany
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    draftContentChange: contentActions.draftContentChange,
   }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(ContentInfo);
