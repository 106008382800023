import React, { Component } from "react";
import { Col, Row } from "react-grid-system";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { isEmpty } from "lodash";
import Table from "../../components/Table";
import Loader from "../../components/Loader";
import * as sensorActions from "../../actions/sensors";
import * as selectedActions from "../../actions/selected";
import style from "./style.module.scss";

class AtomicSensorLocations extends Component {

  constructor(props) {
    super(props);
    this.state = {
      id: props.match.params.id
    };
    
    this.onRowClick = this.onRowClick.bind(this);
  }

  onRowClick(column, rowInfo)  {
    return {
      onClick: e => {
        if (rowInfo && column.name !== "isSelected") {  
          let link = `/companies/${this.props.match.params.companyId}/locations/${rowInfo.original._id}`;

          if (e.metaKey || e.ctrlKey) {
            window.open(`${link}`);
          }
          else {
            this.props.history.push(link);
          }
        }
      },
      style: {
        cursor: "pointer"
      }
    }
  }

  onToggle(row) {
    if (this.props.selectedLocations[row.original._id] !== undefined && this.props.selectedLocations[row.original._id]) {
      this.props.deselectLocation(row.original._id);
    }
    else {
      this.props.selectLocation(row.original);
    }
  }

  render() {
    if (isEmpty(this.props.match.params.id) || isEmpty(this.state.id)) {
      return null;
    }

    if (this.props.isLoading) {
      return <Loader fullScreen />;
    }

    const anotherTypeSelected = this.props.selectedTypes.length > 0 && !this.props.selectedTypes.includes("location");

    const tableElement = (
      <Row nogutter>
        <Col sm={12}>
          <Table
            data={this.props.locations}
            noDataText={this.props.isLoading ? "" : "No locations found"}
            columns={[
              {
                id: "id",
                header: "",
                accessorKey: "_id",
                sortable: false,
                name: "isSelected",
                cell: ({ row }) => (
                  <label className="checkboxContainer" htmlFor={`editCheckbox-${row.original._id}`}>
                    <input
                      id={`editCheckbox-${row.original._id}`}
                      type="checkbox"
                      className="checkbox"
                      checked={this.props.selectedLocations[row.original._id] !== undefined && this.props.selectedLocations[row.original._id]}
                      onChange={() => this.onToggle(row)}
                      disabled={anotherTypeSelected}
                    />
                    <span className={anotherTypeSelected ? "disabledCheckmark" : "checkmark"} />
                  </label>
                ),
                width: 60
              },
              {
                header: "Name",
                accessorKey: "name",
                cell: ({ row }) => (<span title={row.original.name}>{row.original.name}</span>)
              },
              {
                id: "arrow",
                header: "",
                accessorKey: "_id",
                sortable: false,
                className: "pull-right",
                width: 60,
                cell: ({ row }) => (<div className="arrow" />)
              }
            ]}
            getTdProps={this.onRowClick}
            className="setMaxHeigth -row-clickable -highlight"
            loading={this.props.isLoading}
          />
        </Col>
      </Row>
    );

    return (
      tableElement
    );
  }
}

function mapStateToProps(state) {
  return {
    selectedAtomicSensors: state.selected.atomicSensors,
    selectedLocations: state.selected.locations,
    selectedTypes: state.selected.allTypes,
    isLoading: state.loading.sensor,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    selectAtomicSensor: selectedActions.selectAtomicSensor,
    deselectAtomicSensor: selectedActions.deselectAtomicSensor,
    selectLocation: selectedActions.selectLocation,
    deselectLocation: selectedActions.deselectLocation,
    updateAtomicSensor: sensorActions.updateAtomicSensor
  }, dispatch);
}

export default connect(mapStateToProps,mapDispatchToProps)(AtomicSensorLocations);