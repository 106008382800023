import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Hidden, Visible } from "react-grid-system";
import { get, isEmpty } from "lodash";
import SearchBox from "../../components/SearchBox";
import Table from "../../components/Table";
import TopRowOptions from "../../components/TopRowOptions";
import SmallButton from "../../components/SmallButton";
import * as sensorTagActions from "../../actions/sensorTags";
import * as selectedActions from "../../actions/selected";
import style from "./style.module.scss";

class Sensortags extends Component {

  constructor(props) {
    super(props);
    this.state = {
      searchText: undefined,
      sortBy: "name",
      sortOrder: "asc",
      offset: 0,
      limit: 20,
      highlightedId: null
    };
    this.fetchData = this.fetchData.bind(this);
    this.onSearchChange = this.onSearchChange.bind(this);
    this.onRowClick = this.onRowClick.bind(this);
    this.onToggle = this.onToggle.bind(this);
  }

  componentDidMount() {
    this.fetchData();
  }

  onSearchChange(value) {
    let searchValue = value;
    searchValue = searchValue.replace("EUI64+","EUI64-");
    if (searchValue === "") {
      searchValue = undefined;
    }

    this.setState({ searchText: searchValue });
  }

  onRowClick(column, rowInfo) {
    return {
      onClick: e => {
        if (column.name !== "isSelected" && rowInfo !== undefined) {
          this.props.history.push({
            pathname: `/companies/${this.props.match.params.companyId}/sensor-tags/${rowInfo.original._id}`,
            state: { canGoBack: true }
          });
        }
      },
      style: {
        cursor: "pointer",
      }
    }
  }

  onToggle(row) {
    if (this.props.selectedSensorTags[row.original._id] !== undefined && this.props.selectedSensorTags[row.original._id]) {
      this.props.deselectSensorTag(row.original._id);
    }
    else {
      this.props.selectSensorTag(row.original);
    }
  }

  fetchData() {
    const queryParams = {
      search: this.state.searchText,
      sortBy: this.state.sortBy,
      sortOrder: this.state.sortOrder,
      offset: this.state.offset,
      limit: this.state.limit
    };

    this.props.getSensorTags(queryParams);
  }

  render() {
    const isDisabled = false;
    const hasSelection = !isEmpty(this.props.selectedSensors) || !isEmpty(this.props.selectedSensorTags);

    const selectedSensorIds = Object.keys(this.props.selectedSensors);

    const canCreateSensorTag = this.props.auth.hasInstallerRole;
    
    const newTagLink = `/companies/${this.props.match.params.companyId}/sensor-tags/`;
    
    const topRowOptions = (
      <TopRowOptions
        searchbox={(
          <SearchBox
            value={this.state.searchText}
            onSearchChanged={this.onSearchChange}
            onSearchClick={() => this.setState({ offset: 0 }, this.fetchData)}
            onClear={() => this.setState({ searchText: undefined, offset: 0 }, this.fetchData)}
            inListView
          />
        )}
        buttons={[
          <SmallButton key="b1" text="New tag" link={newTagLink} disabled={!canCreateSensorTag || !get(selectedSensorIds, "[1]", false)} singleLine noMargin />
        ]}
      />
    );

    const tableElement = (
      <Table
        data={this.props.sensorTags.tags}
        sortBy={this.state.sortBy}
        sortOrder={this.state.sortOrder}
        offset={this.state.offset}
        limit={this.state.limit}
        count={this.props.sensorTags.count}
        onSortedChange={(newSorted) => {
          this.setState({
            sortBy: newSorted[0].id,
            sortOrder: newSorted[0].desc ? "desc" : "asc"
          }, this.fetchData);
        }}
        onOffsetChanged={(offset) => {
          this.setState({ offset }, this.fetchData);
        }}
        noDataText={this.props.isSensorTagsLoading ? "" : "No tags found"}
        columns={[
          {
            id: "id",
            header: "",
            accessorKey: "_id",
            sortable: false,
            name: "isSelected",
            cell: ({ row }) => (
              <label className="checkboxContainer" htmlFor={`editCheckbox-${row.original._id}`}>
                <input
                  id={`editCheckbox-${row.original._id}`}
                  type="checkbox"
                  className="checkbox"
                  checked={this.props.selectedSensorTags[row.original._id] !== undefined && this.props.selectedSensorTags[row.original._id]}
                  onChange={() => this.onToggle(row)}
                  disabled={isDisabled}
                />
                <span className={isDisabled ? "disabledCheckmark" : "checkmark"} />
              </label>
            ),
            width: 60
          },
          {
            header: "Name",
            accessorKey: "name",
            cell: ({ row }) => (<span title={row.original.name}>{row.original.name}</span>)
          },
          {
            header: "Description",
            accessorKey: "description",
            sortable: false,
            cell: ({ row }) => (<span title={row.original.description}>{row.original.description}</span>)
          },
          {
            id: "arrow",
            header: "",
            accessorKey: "properties",
            sortable: false,
            className: "pull-right",
            width: 60,
            cell: ({ row }) => (<div className="arrow" />)
          }
        ]}
        getTdProps={this.onRowClick}
        className="setMaxHeigth -row-clickable -highlight"
        loading={this.props.isSensorTagsLoading}
      />
    );

    const slimTableElement = (
      <Table
        data={this.props.sensorTags.tags}
        sortBy={this.state.sortBy}
        sortOrder={this.state.sortOrder}
        offset={this.state.offset}
        limit={this.state.limit}
        count={this.props.sensorTags.count}
        onSortedChange={(newSorted) => {
          this.setState({
            sortBy: newSorted[0].id,
            sortOrder: newSorted[0].desc ? "desc" : "asc"
          }, this.fetchData);
        }}
        onOffsetChanged={(offset) => {
          this.setState({ offset }, this.fetchData);
        }}
        noDataText={this.props.isSensorTagsLoading ? "" : "No tags found"}
        columns={[
          {
            header: "Name",
            accessorKey: "name",
            cell: ({ row }) => (<span title={row.original.name}>{row.original.name}</span>)
          },
          {
            id: "arrow",
            header: "",
            accessorKey: "properties",
            sortable: false,
            className: "pull-right",
            width: 60,
            cell: ({ row }) => (<div className="arrow" />)
          }
        ]}
        getTdProps={this.onRowClick}
        className="setMaxHeigth -row-clickable -highlight"
        loading={this.props.isSensorTagsLoading}
      />
    );

    return (
      <>
        <Hidden xs sm md>
          <div className={ hasSelection ? style.mainContainer : style.mainContainerHidden }>
            <div className={style.row}>
              <div className={style.col}>
                <div className={style.scroll}>
                  { topRowOptions }
                  { tableElement }
                </div>
              </div>
            </div>
          </div>
        </Hidden>
        <Visible xs sm md>
        <div className={ hasSelection ? style.mainContainerMobile : style.mainContainerHiddenMobile }>
            <div className={style.row}>
              <div className={style.col}>
                <div className={style.slimScroll}>
                  { topRowOptions }
                  { slimTableElement }
                </div>
              </div>
            </div>
          </div>
        </Visible>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    selectedCompany: state.auth.selectedCompany,
    sensorTags: state.sensorTags.data,
    isSensorTagsLoading: state.loading.sensorTags,
    selectedSensors: state.selected.sensors,
    selectedSensorTags: state.selected.sensorTags,
    hoveredFeature: state.selected.hoveredFeature,
    companyMap: state.auth.map,
    auth: state.auth
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ 
    getSensorTags: sensorTagActions.getSensorTags,
    selectSensorTag: selectedActions.selectSensorTag,
    deselectSensorTag: selectedActions.deselectSensorTag,
    clearSelection: selectedActions.clearSelection
   }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(Sensortags);
