
let items = [];
export const createReportLegendPlugin = (updateHiddenEntities) => ({
  id: "reportLegend",
  beforeDraw: (chart, args, options) => {

    // Prevent updating loop
    if (chart.$_reportLegendUpdating === undefined) {
      chart.$_reportLegendUpdating = false;
    }

    const ctx = chart.ctx;
    const { datasets } = chart.data;

    // Build a map of entity IDs from datasets
    const entityIdMap = {};
    datasets.forEach((dataset, index) => {
      const entityId = dataset.entityId;
      if (entityId) {
        if (!entityIdMap[entityId]) {
          entityIdMap[entityId] = {
            label: dataset.entityName || entityId,
            datasets: [],
          };
        }
        entityIdMap[entityId].datasets.push({
          label: dataset.label,
          backgroundColor: dataset.backgroundColor,
          index,
        });
      };
    });

    // Initialize legend position and style
    const xLabelHeight = chart.scales.x.labelRotation > 0 ? 35 : 20;
    const chartWidthWithLabels = chart.chartArea.width + chart.chartArea.left + (chart.width - chart.chartArea.right);
    const chartHeightWithLabels = chart.chartArea.height + chart.chartArea.top + xLabelHeight;
    const x = 20;
    const y = chartHeightWithLabels + 25;
    const fontSize = 14;
    const boxSize = fontSize;
    const lineHeight = fontSize * 1.2;
    const font = `${fontSize}px Arial`;

    ctx.save();
    ctx.font = font;
    ctx.textBaseline = "middle";

    // Calculate the size of the legend before drawing it
    let legendWidth = 0;
    let tempX = x;
    let tempY = 0;
    Object.values(entityIdMap).forEach((entity) => {

      // Item width is the sum of the box size, 4 pixels of padding, the text width and 30 pixels of padding
      let itemWidth = boxSize * 2 + 4 + ctx.measureText(entity.label).width + 30;

      // Check if we need to wrap to the next line
      if (tempX + itemWidth > chartWidthWithLabels) {
        tempX = x;
        tempY += lineHeight;
      }

      // Add entity label length to tempX
      tempX += itemWidth;

      // Update the legend width to match the longest line
      if (tempX > legendWidth) {
        legendWidth = tempX;
      }
    });

    // Get the new legend position based on the calculated width - centered horizontally
    let legendX = (chartWidthWithLabels - legendWidth) / 2;

    // Draw the legend
    let xPos = legendX;
    let yPos = y;
    items = [];
    Object.values(entityIdMap).forEach((entity) => {

      // Item width is the sum of the box size, 4 pixels of padding, the text width and 30 pixels of padding
      let itemWidth = boxSize * 2 + 4 + ctx.measureText(entity.label).width + 30;

      // Check if we need to wrap to the next line
      if (xPos + itemWidth > chartWidthWithLabels) {
        xPos = legendX;
        yPos += lineHeight;
      }

      // Store legend item coordinates and dimensions
      items.push({
        x: xPos,
        y: yPos,
        width: itemWidth,
        height: boxSize,
        entity,
      });

      // Draw the entity data color boxes
      entity.datasets.reverse().forEach((dataset) => {
        ctx.fillStyle = dataset.backgroundColor;
        ctx.fillRect(xPos, yPos, boxSize, boxSize);
        xPos += boxSize + 2;
      });

      // Draw the entity label
      const allHidden = entity.datasets.every((dataset) => chart.data.datasets[dataset.index].hidden);
      ctx.fillStyle = allHidden ? "#777" : "#444";
      ctx.fillText(entity.label, xPos + 8, yPos + lineHeight / 2);

      // Draw the strike-through line if all datasets are hidden
      if (allHidden) {
        const textWidth = ctx.measureText(entity.label).width;
        const strikeY = yPos + lineHeight / 2;
        ctx.beginPath();
        ctx.moveTo(xPos + 8, strikeY);
        ctx.lineTo(xPos + 8 + textWidth, strikeY);
        ctx.strokeStyle = "#777";
        ctx.lineWidth = 1;
        ctx.stroke();
      }

      // Add text length to xPos
      xPos += ctx.measureText(entity.label).width + 30;
    });

    ctx.restore();

    // Calculate the height of the legend
    const legendHeight = yPos - y + lineHeight;
    const paddedLegendHeight = Math.ceil(legendHeight + 35);

    // Update the chart options to add extra padding at the bottom to accommodate the legend
    const currentBottomPadding = chart.options.layout.padding.bottom;
    if (currentBottomPadding != paddedLegendHeight) {
      chart.options.layout.padding.bottom = paddedLegendHeight;
      chart.update("none");
    }
  },

  beforeUpdate: (chart) => {
    // Check if the number of datasets has changed
    // Without this check, the grayscale colors are also saved when the chart is updated
    if (chart.$_reportLegendDatasetCount !== chart.data.datasets.length) {
      // Save the original backgroundColors of all datasets
      chart.$_reportLegendOriginalBackgroundColors = chart.data.datasets.map((dataset) => dataset.backgroundColor);
      
      // Update the dataset count
      chart.$_reportLegendDatasetCount = chart.data.datasets.length;
    }
  },
  afterEvent: (chart, e) => {

    // Prevent updating loop
    if (chart.$_reportLegendUpdating) return;

    const eventX = e.event.x;
    const eventY = e.event.y;
    const eventType = e.event.type;
    const { datasets } = chart.data;

    // Check if the event happened on a legend item
    const item = items.find((item) =>
      eventX >= item.x &&
      eventX <= item.x + item.width &&
      eventY >= item.y &&
      eventY <= item.y + item.height
    );

    if (item) {
      // console.log("item", item);
      const itemDatasets = item.entity.datasets;

      // Build a map of entity IDs from datasets
      const entityIdMap = {};
      datasets.forEach((dataset, index) => {
        const entityId = dataset.entityId;
        if (!entityIdMap[entityId]) {
          entityIdMap[entityId] = {
            datasets: [],
          };
        }
        entityIdMap[entityId].datasets.push({
          index,
          entityId,
        });
      });

      if (eventType === "mousemove") {
        // Change the cursor to a pointer when hovering over a legend item
        chart.canvas.style.cursor = "pointer";

        // Check if the item is hidden
        const allHidden = itemDatasets.every((dataset) => datasets[dataset.index].hidden);

        // Apply grayscale effect to the other datasets
        datasets.forEach((dataset, index) => {
          if (!allHidden && !itemDatasets.some((ds) => ds.index === index)) {
            const newColor = toGrayscale(chart.$_reportLegendOriginalBackgroundColors[index]);
            updateDatasetStyles(chart, index, newColor);
            datasets[index].backgroundColor = newColor;
            datasets[index].borderColor = newColor;
            datasets[index].pointBackgroundColor = newColor;
            datasets[index].pointBorderColor = newColor;
          }
          else {
            const newColor = chart.$_reportLegendOriginalBackgroundColors[index];
            updateDatasetStyles(chart, index, newColor);
            datasets[index].backgroundColor = newColor;
            datasets[index].borderColor = newColor;
            datasets[index].pointBackgroundColor = newColor;
            datasets[index].pointBorderColor = newColor;
          }
        });

        // console.log("mousemove - hoveredLegend", item);

        // Update the chart to reflect the changes
        chart.$_reportLegendUpdating = true;
        chart.update('none');
        chart.$_reportLegendUpdating = false;

      }
      else if (eventType === "click") {
        // console.log("selectedItem", item);

        // Toggle the visibility of datasets belonging to the clicked entity
        item.entity.datasets.forEach((dataset) => {
          const chartDataset = chart.data.datasets[dataset.index];
          chartDataset.hidden = !chartDataset.hidden;
        });

        // Update the hiddenEntities state in the parent component
        const hiddenEntities = [];
        Object.values(entityIdMap).forEach((entity) => {
          if (entity.datasets.every((dataset) => chart.data.datasets[dataset.index].hidden)) {
            hiddenEntities.push(entity.datasets[0].entityId);
          }
        });

        updateHiddenEntities(hiddenEntities);

        // Update the chart to reflect the changes
        chart.update();
      }
    }
    else if (eventType === "mousemove" || eventType === "mouseout") {
      // Reset the cursor when not hovering over a legend item
      chart.canvas.style.cursor = "default";

      // Revert the backgroundColor of all datasets to the original colors
      if (chart.$_reportLegendOriginalBackgroundColors) {
        datasets.forEach((dataset, index) => {
          const newColor = chart.$_reportLegendOriginalBackgroundColors[index];
          updateDatasetStyles(chart, index, newColor);
          datasets[index].backgroundColor = newColor;
          datasets[index].borderColor = newColor;
          datasets[index].pointBackgroundColor = newColor;
          datasets[index].pointBorderColor = newColor;
        });

        chart.$_reportLegendUpdating = true;
        chart.update('none');
        chart.$_reportLegendUpdating = false;
      }
    }
  },
});

function toGrayscale(color) {
  const c = document.createElement("canvas").getContext("2d");
  c.fillStyle = color;
  c.fillRect(0, 0, 1, 1);
  const data = c.getImageData(0, 0, 1, 1).data;
  const gray = 0.299 * data[0] + 0.587 * data[1] + 0.114 * data[2];
  return `rgb(${gray}, ${gray}, ${gray})`;
}

function updateDatasetStyles(chart, index, newColor) {
  const meta = chart.getDatasetMeta(index);
  meta.controller._cachedDataOpts.backgroundColor = newColor;
  meta.controller._cachedMeta.data.forEach((element) => {
    element.options.backgroundColor = newColor;
    element.options.borderColor = newColor;
    element.options.pointBackgroundColor = newColor;
    element.options.pointBorderColor = newColor;
  });
}
