import React from "react";
import { Link } from "react-router-dom";
import { RotatingLines } from "react-loader-spinner";
import style from "./style.module.scss"

interface ButtonProps {
  text: string;
  type: "button" | "reset" | "submit" | undefined;
  hoverText?: string;
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
  isLoading?: boolean;
  link: string;
  disabled?: boolean;
  color: string;
  singleLine: boolean;
  paddingTop?: string;
  marginRight?: string;
  width?: string;
  minWidth?: string;
  slim: boolean;
}

// Either use onClick or link
const Button: React.FC<ButtonProps> = ({text, type = "button", hoverText, onClick = () => {}, link, disabled = false, isLoading = false, color = "blue", singleLine = false, paddingTop, marginRight, width, minWidth, slim = false }) => (
  <div className={slim ? style.slimContainer : style.buttonContainer} style={{ paddingTop, width, minWidth, marginRight }}>
    {
      link ? (
      <Link to={link}>
        <button className={color === "blue" ? style.blue : color === "white" ? style.white : color === "red" ? style.red : style.clear} title={hoverText} type={type} disabled={disabled} data-singleline={singleLine}>
          <div className={style.buttonPadding}>
            { <span>{text}</span> }
          </div>
        </button>
      </Link>
      ) : (
        <button
          className={color === "blue" ? style.blue : color === "white" ? style.white : color === "red" ? style.red : style.clear}
          title={hoverText}
          type={type}
          disabled={disabled}
          onClick={(event) => { onClick(event); event.stopPropagation(); }}
          data-singleline={singleLine}
          data-loading={isLoading}>

          { isLoading && (
            <div className={style.loadingContainer}>
              <RotatingLines strokeColor="#777" width="20px" />
            </div>
          )}

          <div className={style.buttonPadding}>
            { <span style={{ opacity: isLoading ? 0 : 1 }}>{text}</span> }
          </div>

        </button>
      )
    }
  </div>
);

export default Button;