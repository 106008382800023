import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import JSON5 from "json5";
import StaticTable from "../../components/StaticTable";
import SmallButton from "../../components/SmallButton";
import styled from "styled-components";

class ScreenViewLayoutDetails extends Component {

  constructor(props) {
    super(props);
    this.state = {
      mode: "initial",
      selectedObject: null,
    };

    this.onAddContainerClicked = this.onAddContainerClicked.bind(this);
    this.onAddComponentClicked = this.onAddComponentClicked.bind(this);
    this.onAddCardComponentClicked = this.onAddCardComponentClicked.bind(this);
  }

  // Put the selected object in the state, from props, if props is valid
  static getDerivedStateFromProps(newProps, prevState) {
    if (newProps.selectionPath && newProps.layout) {
      
      let newState = { ...prevState };

      // Get the selected object from the selectionPath
      let view = null;
      let existingView = null;
      try {
        existingView = JSON.parse(newProps.layout);
      }
      catch (error) {}

      if (isEmpty(existingView) && (isEmpty(newProps.layout) === "" || newProps.layout === "{}")) {
        newState.mode = "initial";
        newState.selectedObject = null;
      }
      else {
        try {
          newState.mode = "documentation";
          view = JSON5.parse(newProps.layout);
          const selectedObject = getLastObjectFromPath(newProps.selectionPath, view);
          // console.log("ScreenConfigLayoutDetails.getDerivedStateFromProps.selectedObject", selectedObject);

          if (selectedObject) {
            newState.selectedObject = selectedObject;
          }
          else {
            newState.selectedObject = null;
            newState.mode = "add-view";
          }
        }
        catch (error) {}
      }

      if (newProps.showAddView) {
        newState.mode = "add-view";
      }

      return newState;
    }

    return null;
  }

  onAddContainerClicked(isRoot = false) {
    const container = {
      class: "container",
      css: {},
      children: []
    };

    if (isRoot) {
      container.css = {
        position: "relative",
        width: "100%",
        height: "100%",
      }
    }

    this.props.onAddClicked(this.props.selectionPath, container);
  }

  onAddComponentClicked(type, extraProperties = {}) {
    const component = {
      class: "component",
      type: type,
      ...extraProperties
    };

    this.props.onAddClicked(this.props.selectionPath, component);
  }

  onAddCardComponentClicked() {
    const component = {
      class: "component",
      type: "card",
      components: [
        [
          {
            type: "text",
            sensorType: "temperature",
            text: "Temperature",
          }
        ]
      ]
    };

    this.props.onAddClicked(this.props.selectionPath, component);
  }


  render() {
    // console.log("ScreenConfigLayoutDetails.render.state", this.state);
    // console.log("ScreenConfigLayoutDetails.render.selectionPath", this.props.selectionPath);

    if (!this.props.auth.hasSupportRole) {
      return null;
    }

    if (this.state.mode === "initial") {
      return (
        <>
          <Header $paddingTop="25px">Add a container</Header>
          <Paragraph>A standard root container will be added to the layout.</Paragraph>
          <SmallButton text="Container" onClick={() => this.onAddContainerClicked(true)} disabled={this.props.isLoading} onlyRightMargin />
          <div style={{ paddingTop: "40px" }} />
        </>
      );
    }
    else if (this.state.mode === "add-view") {
      return (
        <>
          <Header $paddingTop="25px">Add a container</Header>
          <Paragraph>A container will be added either as a root element or as a child in the selected container.</Paragraph>
          <SmallButton text="Container" onClick={() => this.onAddContainerClicked(false)} disabled={this.props.isLoading} onlyRightMargin />
          <Header $paddingTop="25px">Add a component</Header>
          <Paragraph>Click on the component you what to add to the selected container in the layout.</Paragraph>
          <SmallButton text="Text" onClick={() => this.onAddComponentClicked("text", { text: "" })} disabled={this.props.isLoading} onlyRightMargin />
          <SmallButton text="Text area" onClick={() => this.onAddComponentClicked("text-area", { text: "" })} disabled={this.props.isLoading} onlyRightMargin />
          <SmallButton text="Image" onClick={() => this.onAddComponentClicked("image")} disabled={this.props.isLoading} onlyRightMargin />
          <SmallButton text="Datetime" onClick={() => this.onAddComponentClicked("datetime")} disabled={this.props.isLoading} onlyRightMargin />
          <SmallButton text="Map" onClick={() => this.onAddComponentClicked("map", { dataSource: { type: "policy" }, mapLocationId: "" })} disabled={this.props.isLoading} onlyRightMargin />
          <SmallButton text="SVG map" onClick={() => this.onAddComponentClicked("svg-map", { dataSource: { type: "policy" }, mapLocationId: "", url: "" })} disabled={this.props.isLoading} onlyRightMargin />
          <SmallButton text="Header" onClick={() => this.onAddComponentClicked("header", { title: "" })} disabled={this.props.isLoading} onlyRightMargin />
          <SmallButton text="View links" onClick={() => this.onAddComponentClicked("links", { links: [] })} disabled={this.props.isLoading} onlyRightMargin />
          <SmallButton text="Compass" onClick={() => this.onAddComponentClicked("compass", { mapLocationId: "" })} disabled={this.props.isLoading} onlyRightMargin />
          <SmallButton text="Legend" onClick={() => this.onAddComponentClicked("legend", { showVacant: true, showOccupied: true })} disabled={this.props.isLoading} onlyRightMargin />
          <SmallButton text="Bar graph" onClick={() => this.onAddComponentClicked("bar", { locationId: "", sensorType: "" })} disabled={this.props.isLoading} onlyRightMargin />
          <SmallButton text="Card" onClick={() => this.onAddCardComponentClicked()} disabled={this.props.isLoading} onlyRightMargin />
          <SmallButton text="Available meeting rooms" onClick={() => this.onAddComponentClicked("meeting-rooms", { dataSource: { type: "policy" } })} disabled={this.props.isLoading} onlyRightMargin />
          {/* <SmallButton text="All meeting rooms" onClick={() => this.onAddComponentClicked("all-meeting-rooms")} disabled={this.props.isLoading} onlyRightMargin /> */}
          <SmallButton text="Meeting room count" onClick={() => this.onAddComponentClicked("meeting-room-count")} disabled={this.props.isLoading} onlyRightMargin />
          <SmallButton text="Aggregated data graph" onClick={() => this.onAddComponentClicked("graph")} disabled={this.props.isLoading} onlyRightMargin />
          <SmallButton text="Static graph" onClick={() => this.onAddComponentClicked("static-graph")} disabled={this.props.isLoading} onlyRightMargin />
          <div style={{ paddingTop: "40px" }} />
        </>
      );
    }

    const selectedClass = get(this.state.selectedObject, "class");
    const selectedType = get(this.state.selectedObject, "type");

    const componentDefinitionColumnSizes = {
      property: 40,
      value: 60
    };

    const configurationColumnSizes = {
      property: 20,
      type: 15,
      default: 15,
      description: 50,
    };

    let componentDefinition = [];
    let configurationDefinition = [];
    let contentElement = null;
    if (selectedClass === "component" && selectedType) {
      
      componentDefinition = [
        { property: "class", value: `"component"` },
        { property: "type", value: `"${selectedType}"` },
      ];

      const thresholdGroupDefinition = [
        { property: "upper", type: "Threshold", default: "-", description: "The upper threshold. Will trigger if value is above this threshold." },
        { property: "middle", type: "Threshold?", default: "-", description: "The middle threshold. Will trigger if value is between the upper threshold and this threshold." },
        { property: "lower", type: "Threshold", default: "-", description: "The lower threshold. Will trigger if value is lower then the other defined thresholds. The value here has no impact." },
      ];

      const thresholdDefinition = [
        { property: "value", type: "number?", default: "- / 0.5 / 0.8", description: "Overriding the default thresholds value. Default value is different for lower, middle and upper thresholds." },
        { property: "text", type: "string?", default: "-", description: "A text to be shown when the threshold is met (instead of the value)." },
        { property: "icon", type: "string?", default: "-", description: "An icon to be shown when the threshold is met (instead of the value or text)." },
        { property: "color", type: "hex-color?", default: "green / yellow / red", description: "The color of the icon or text. Default value is different for lower, middle and upper thresholds." },
      ];

      const dataSourcesDefinition = [
        { property: "type", type: "policy", default: "", description: "The type is always 'policy'." },
        { property: "locationIds", type: "string[]?", default: "-", description: "An array of locationIds that the data will be queried from." },
        { property: "query", type: "Object?", default: "-", description: "The query to use for the data." },
        { property: "attributes", type: "string[]?", default: "-", description: "An array of attributes to use for the data." },
        { property: "properties", type: "string[]?", default: "-", description: "An array of properties (sensorTypes) to use for the data." },
        { property: "allAttributes", type: "boolean?", default: "false", description: "If true, all attributes will be used for the data." },
        { property: "allProperties", type: "boolean?", default: "false", description: "If true, all properties (sensorTypes) will be used for the data." },
      ];

      switch (selectedType) {
        case "map":

          configurationDefinition = [
            { property: "dataSource", type: "DataSource", default: "-", description: "The data source for the map." },
            { property: "storeId", type: "string?", default: "-", description: "The Redux storeId that makes it possible to interact with multiple maps at once. Use the same id in the meeting-component." },
            { property: "mapLocationId", type: "string", default: "-", description: "The locationId of the map" },
            { property: "statusFilter", type: "string?", default: "-", description: "The status filter to use for the map." },
            { property: "labelFilter", type: "string?", default: "-", description: "The label filter to use for the map." },
            { property: "labelLocationIds", type: "string[]?", default: "-", description: "The location ids that should have labels in the map (works with the label filter)." },
            { property: "showVacant", type: "boolean?", default: "true", description: "If true, vacant will be shown in the map." },
            { property: "showOccupied", type: "boolean?", default: "true", description: "If true, occupied will be shown in the map." },
            { property: "showVacantBooked", type: "boolean?", default: "false", description: "If true, vacant-but-booked will be shown in the map." },
            // { property: "useCircularLabels", type: "boolean?", default: "false", description: "If true, circular labels will be used in the map." },
            // { property: "useShortLabels", type: "boolean?", default: "false", description: "If true, short labels will be used in the map." },
            { property: "useThreeD", type: "boolean?", default: "false", description: "If true, the map will be shown in 3D." },
            { property: "hideBackground", type: "boolean?", default: "false", description: "If true, the map background will be hidden." },
            { property: "backgroundColor", type: "hex-color?", default: "#ffffff", description: "The background color of the map." },
            { property: "hideInventory", type: "boolean?", default: "false", description: "If true, the MazeMap inventory (furniture) will be hidden." },
            { property: "youAreHere", type: "{ latitude: number, longitude: number, color?: string }?", default: "-", description: "The position of the user location on the map." },
            { property: "initialLocation", type: "{ northEast: { latitude: number, longitude: number }, southWest: { latitude: number, longitude: number } }?", default: "-", description: "The initial location of the map." },
            { property: "initialBearing", type: "number?", default: "-", description: "The initial bearing of the map." },
            { property: "initialPitch", type: "number?", default: "-", description: "The initial pitch of the map." },
            { property: "mazeMap", type: "{ apiKey?: string, campusTag?: string }?", default: "-", description: "Overriding the company and screen MazeMap configuration." },
          ];

          contentElement = (
            <>
              <Header>Map</Header>
              <Paragraph>This component is a reference to the a configured map that you can access in the menu above.</Paragraph>
              <StaticTable data={componentDefinition} columnSizes={componentDefinitionColumnSizes} style={{ maxWidth: "300px" }} />
              <Paragraph $paddingTop="20px">Configuration definition for all properties in the component.</Paragraph>
              <StaticTable data={configurationDefinition} columnSizes={configurationColumnSizes} />
            </>
          );

          break;
        case "svg-map":

          configurationDefinition = [
            { property: "dataSource", type: "DataSource", default: "-", description: "The data source for the map." },
            { property: "storeId", type: "string?", default: "-", description: "The Redux storeId that makes it possible to interact with multiple maps at once. Use the same id in the meeting-component." },
            { property: "mapLocationId", type: "string", default: "-", description: "The locationId of the map" },
            { property: "url", type: "string", default: "-", description: "A URL to a public hosted SVG file." },
            { property: "statusFilter", type: "string?", default: "-", description: "The status filter to use for the map." },
            { property: "labelFilter", type: "string?", default: "-", description: "The label filter to use for the map." },
            { property: "labelLocationIds", type: "string[]?", default: "-", description: "The location ids that should have labels in the map (works with the label filter)." },
            { property: "showVacant", type: "boolean?", default: "true", description: "If true, vacant will be shown in the map." },
            { property: "showOccupied", type: "boolean?", default: "true", description: "If true, occupied will be shown in the map." },
            { property: "showVacantBooked", type: "boolean?", default: "false", description: "If true, vacant-but-booked will be shown in the map." },
            // { property: "useCircularLabels", type: "boolean?", default: "false", description: "If true, circular labels will be used in the map." },
            // { property: "useShortLabels", type: "boolean?", default: "false", description: "If true, short labels will be used in the map." },
          ];

          contentElement = (
            <>
              <Header>SVG map</Header>
              <Paragraph>This component is used to render SVG floor maps. To allow the SVG to scale and fit the view, it should not have a predefined height and width. If you want room status in the map, create rectangles with locationId and locationType as attributes.</Paragraph>
              <StaticTable data={componentDefinition} columnSizes={componentDefinitionColumnSizes} style={{ maxWidth: "300px" }} />
              <Paragraph $paddingTop="20px">Configuration definition for all properties in the component.</Paragraph>
              <StaticTable data={configurationDefinition} columnSizes={configurationColumnSizes} />
            </>
          );

          break;
        case "header":

          configurationDefinition = [
            { property: "title", type: "string?", default: "-", description: "A title that will be in the middle of the header." },
            { property: "showLocationName", type: "boolean?", default: "false", description: "If true, the location name will be used instead of the title." },
            { property: "showThemeLogo", type: "boolean?", default: "false", description: "If true, the theme logo will be shown on the left side in the header."},
          ];

          contentElement = (
            <>
              <Header>Header</Header>
              <Paragraph>This component is used to render the standard banner header.</Paragraph>
              <StaticTable data={componentDefinition} columnSizes={componentDefinitionColumnSizes} style={{ maxWidth: "300px" }} />
              <Paragraph $paddingTop="20px">Configuration definition for all properties in the component.</Paragraph>
              <StaticTable data={configurationDefinition} columnSizes={configurationColumnSizes} />
            </>
          );

          break;
        case "text":

          configurationDefinition = [
            { property: "text", type: "string | Expression", default: "-", description: "A string or an Expressions to add texts based on sensor data." },
          ];

          contentElement = (
            <>
              <Header>Text</Header>
              <Paragraph>This component is used to render a text block.</Paragraph>
              <StaticTable data={componentDefinition} columnSizes={componentDefinitionColumnSizes} style={{ maxWidth: "300px" }} />
              <Paragraph $paddingTop="20px">Configuration definition for all properties in the component.</Paragraph>
              <StaticTable data={configurationDefinition} columnSizes={configurationColumnSizes} />
            </>
          );

          break;
        case "text-area":

          configurationDefinition = [
            { property: "title", type: "string?", default: "-", description: "A title that will appear above the text area." },
            { property: "text", type: "string | Expression", default: "-", description: "A string or an Expressions to add texts based on sensor data." },
            { property: "css", type: "{ [key]: value | Expression }?", default: "-", description: "A CSS object to style the text area." },
          ];

          contentElement = (
            <>
              <Header>Text area</Header>
              <Paragraph>This component is used to render a text area that is placed in the standard card layout.</Paragraph>
              <StaticTable data={componentDefinition} columnSizes={componentDefinitionColumnSizes} style={{ maxWidth: "300px" }} />
              <Paragraph $paddingTop="20px">Configuration definition for all properties in the component.</Paragraph> 
              <StaticTable data={configurationDefinition} columnSizes={configurationColumnSizes} />
            </>
          );

          break;
        case "image":

          configurationDefinition = [
            { property: "url", type: "string | Expression", default: "-", description: "A URL to a public hosted image or an Expressions to add images based on sensor data." },
            { property: "scaling", type: `"contain" | "fill-width" | "fill-height"`, default: "-", description: "The scaling of the image." },
            { property: "horizontalPosition", type: `"center" | "left" | "right"`, default: "-", description: "The horizontal position of the image." },
          ];

          contentElement = (
            <>
              <Header>Image</Header>
              <Paragraph>This component is used to render an image.</Paragraph>
              <StaticTable data={componentDefinition} columnSizes={componentDefinitionColumnSizes} style={{ maxWidth: "300px" }} />
              <Paragraph $paddingTop="20px">Configuration definition for all properties in the component.</Paragraph>
              <StaticTable data={configurationDefinition} columnSizes={configurationColumnSizes} />
            </>
          );

          break;
        case "datetime":

          configurationDefinition = [
            { property: "format", type: "string?", default: "HH:mm", description: "A moment.js format string." },
          ];

          contentElement = (
            <>
              <Header>DatetimeView</Header>
              <Paragraph>This component is used to render a datetime.</Paragraph>
              <StaticTable data={componentDefinition} columnSizes={componentDefinitionColumnSizes} style={{ maxWidth: "300px" }} />
              <Paragraph $paddingTop="20px">Configuration definition for all properties in the component.</Paragraph> 
              <StaticTable data={configurationDefinition} columnSizes={configurationColumnSizes} />
            </>
          );

          break;
        case "floor-menu":
        case "nav-links":
        case "links":

          configurationDefinition = [
            { property: "links", type: "NavLink[]", default: "[]", description: "An array of labels and viewIds that is used to change between views." },
          ];

          const optionArrayDefinition = [
            { property: "label", type: "string", default: "-", description: "A label for the navigation link." },
            { property: "value", type: "string", default: "-", description: "The view id for a view in this screen group that will be shown if the button is clicked." }
          ];

          contentElement = (
            <>
              <Header>Navigation links</Header>
              <Paragraph>This component is used to render links (buttons) in a vertical list that change the view this is presented on the Screen. This is often used to change floors, where each floor is one view.</Paragraph>
              <StaticTable data={componentDefinition} columnSizes={componentDefinitionColumnSizes} style={{ maxWidth: "300px" }} />
              <Paragraph $paddingTop="20px">Configuration definition for all properties in the component.</Paragraph> 
              <StaticTable data={configurationDefinition} columnSizes={configurationColumnSizes} />
              <Header $paddingTop="35px">NavLink</Header>
              <StaticTable data={optionArrayDefinition} columnSizes={configurationColumnSizes} />
            </>
          );

          break;
        case "compass":

          configurationDefinition = [
            { property: "mapLocationId", type: "string", default: "-", description: "The locationId of the map that the compass will be used on." },
          ];

          contentElement = (
            <>
              <Header>Compass</Header>
              <Paragraph>This component is used to render a compass.</Paragraph>
              <StaticTable data={componentDefinition} columnSizes={componentDefinitionColumnSizes} style={{ maxWidth: "300px" }} />
              <Paragraph $paddingTop="20px">Configuration definition for all properties in the component.</Paragraph> 
              <StaticTable data={configurationDefinition} columnSizes={configurationColumnSizes} />
            </>
          );

          break;
        case "legend":

          configurationDefinition = [
            { property: "showVacant", type: "boolean", default: "-", description: "If true, vacant will be shown in the legend." },
            { property: "showOccupied", type: "boolean", default: "-", description: "If true, occupied will be shown in the legend." },
            { property: "showVacantBooked", type: "boolean?", default: "false", description: "If true, vacant-but-booked will be shown in the legend." },
            { property: "showUserLocation", type: "boolean?", default: "false", description: "If true, user location will be shown in the legend." },
            { property: "userLocationColor", type: "hex-color?", default: "#000000", description: "The color of the user location in the legend." },
          ];

          contentElement = (
            <>
              <Header>Legend</Header>
              <Paragraph>This component is used to render the standard legend based on theme colors and map config.</Paragraph>
              <StaticTable data={componentDefinition} columnSizes={componentDefinitionColumnSizes} style={{ maxWidth: "300px" }} />
              <Paragraph $paddingTop="20px">Configuration definition for all properties in the component.</Paragraph>
              <StaticTable data={configurationDefinition} columnSizes={configurationColumnSizes} />
            </>
          );

          break;
        case "bar":

          configurationDefinition = [
            { property: "locationId", type: "string", default: "-", description: "The locationId that the data will be queried from." },
            { property: "sensorType", type: "string", default: "-", description: "The type of data to query." },
            { property: "maxValue", type: "number?", default: "100", description: "The maximum value for the sensor data. If displaying peopleCount, it will use the location capacity as maxValue." },
            { property: "showPercentageBox", type: "boolean?", default: "false", description: "If true, a box with the percentage value in text form will appear to the left of the bar." },
            { property: "thresholds", type: "ThresholdGroup?", default: "-", description: "A group of thresholds for the bar." },
            { property: "barStyle", type: "BarStyle?", default: "-", description: "The style of the bar." },
          ];

          const barStyleDefinition = [
            { property: "height", type: "string?", default: "1.4em", description: "The height of the bar." },
            { property: "color", type: "hex-color?", default: "#444", description: "The color of the bar." },
            { property: "backgroundColor", type: "hex-color?", default: "#ddd", description: "The background color of the bar." },
            { property: "borderRadius", type: "string?", default: "5px", description: "The border radius of the bar." }
          ];

          contentElement = (
            <>
              <Header>Bar graph</Header>
              <Paragraph>This component is used to render horizontal bar chart.</Paragraph>
              <StaticTable data={componentDefinition} columnSizes={componentDefinitionColumnSizes} style={{ maxWidth: "300px" }} />
              <Paragraph $paddingTop="20px">Configuration definition for all properties in the component.</Paragraph>
              <StaticTable data={configurationDefinition} columnSizes={configurationColumnSizes} />
              <Header $paddingTop="35px">Threshold group</Header>
              <StaticTable data={thresholdGroupDefinition} columnSizes={configurationColumnSizes} />
              <Header $paddingTop="35px">Threshold</Header>
              <StaticTable data={thresholdDefinition} columnSizes={configurationColumnSizes} />
              <Header $paddingTop="35px">Bar style</Header>
              <StaticTable data={barStyleDefinition} columnSizes={configurationColumnSizes} />
            </>
          );

          break;
        case "card":

          configurationDefinition = [
            { property: "title", type: "string?", default: "-", description: "A title that will appear above the content in a banner with the primary theme color." },
            { property: "components", type: "CardComponent[][]", default: "-", description: "An array of arrays of card components that will be rendered in the card." },
          ];

          const cardComponentDefinition = [
            { property: "type", type: `"text" | "title"`, default: "-", description: "The type of component to render in the card." },
            { property: "text", type: "string", default: "-", description: "A text below the sensor data." },
            { property: "sensorType", type: "string", default: "-", description: "The type of data to query." },
            { property: "locationId", type: "string?", default: "-", description: "The locationId that the data will be queried from." },
            { property: "defaultValue", type: "string?", default: "-", description: "A default value to show if the sensor data is not available." },
            { property: "icon", type: "string?", default: "-", description: "An icon to show in the component." },
            { property: "showPercentage", type: "boolean?", default: "false", description: "If true, the percentage value will be shown instead of the sensor data." },
            { property: "useColorOnText", type: "boolean?", default: "false", description: "If true, the color of the text will be based on the sensor data." },
            { property: "thresholds", type: "ThresholdGroup?", default: "-", description: "A group of thresholds for the component." },
            { property: "width", type: "string?", default: "-", description: "The CSS width of the component." },
            { property: "minWidth", type: "string?", default: "-", description: "The CSS min-width of the component." }
          ];

          contentElement = (
            <>
              <Header>Card</Header>
              <Paragraph>This component is used to render a card with information from sensor data. It will show the last received value.</Paragraph>
              <StaticTable data={componentDefinition} columnSizes={componentDefinitionColumnSizes} style={{ maxWidth: "300px" }} />
              <Paragraph $paddingTop="20px">Configuration definition for all properties in the component.</Paragraph>
              <StaticTable data={configurationDefinition} columnSizes={configurationColumnSizes} />
              <Header $paddingTop="35px">Card component</Header>
              <StaticTable data={cardComponentDefinition} columnSizes={configurationColumnSizes} />
              <Header $paddingTop="35px">Threshold group</Header>
              <StaticTable data={thresholdGroupDefinition} columnSizes={configurationColumnSizes} />
              <Header $paddingTop="35px">Threshold</Header>
              <StaticTable data={thresholdDefinition} columnSizes={configurationColumnSizes} />
            </>
          );

          break;
        case "meeting-rooms":

          configurationDefinition = [
            { property: "dataSource", type: "DataSource", default: "-", description: "The data source for the meeting rooms." },
            { property: "storeId", type: "string?", default: "-", description: "The Redux storeId that makes it possible to interact with multiple maps at once. Use the same id in the map-component." },
            { property: "title", type: "string?", default: "-", description: "A title that will appear above the content in a banner with the primary theme color." },
            { property: "showTouchControls", type: "boolean?", default: "false", description: "If true, the component includes visual queue for touch." },
            { property: "showIAQ", type: "boolean?", default: "false", description: "If true, the Indoor Air Quality of the meeting rooms will be shown." },
          ];

          contentElement = (
            <>
              <Header>Available meeting rooms</Header>
              <Paragraph>This component is used to render only available meeting rooms based on both sensor data and M365 calendars.</Paragraph>
              <StaticTable data={componentDefinition} columnSizes={componentDefinitionColumnSizes} style={{ maxWidth: "300px" }} />
              <Paragraph $paddingTop="20px">Configuration definition for all properties in the component.</Paragraph>
              <StaticTable data={configurationDefinition} columnSizes={configurationColumnSizes} />
              <Header $paddingTop="35px">Data sources</Header>
              <StaticTable data={dataSourcesDefinition} columnSizes={configurationColumnSizes} />
            </>
          );

          break;
        case "all-meeting-rooms":

          configurationDefinition = [
            { property: "dataSource", type: "DataSource", default: "-", description: "The data source for the meeting rooms." },
            { property: "storeId", type: "string?", default: "-", description: "The Redux storeId that makes it possible to interact with multiple maps at once. Use the same id in the map-component." },
            { property: "title", type: "string?", default: "-", description: "A title that will appear above the content in a banner with the primary theme color." },
            { property: "showTouchControls", type: "boolean?", default: "false", description: "If true, the component includes visual queue for touch." },
            { property: "showIAQ", type: "boolean?", default: "false", description: "If true, the Indoor Air Quality of the meeting rooms will be shown." },
          ];

          contentElement = (
            <>
              <Header>All meeting rooms</Header>
              <Paragraph>This component is used to render all meeting rooms with information from both sensor data and M365 calendars.</Paragraph>
              <StaticTable data={componentDefinition} columnSizes={componentDefinitionColumnSizes} style={{ maxWidth: "300px" }} />
              <Paragraph $paddingTop="20px">Configuration definition for all properties in the component.</Paragraph>
              <StaticTable data={configurationDefinition} columnSizes={configurationColumnSizes} />
              <Header $paddingTop="35px">Data sources</Header>
              <StaticTable data={dataSourcesDefinition} columnSizes={configurationColumnSizes} />
            </>
          );

          break;
        case "meeting-room-count":

          configurationDefinition = [
            { property: "dataSource", type: "DataSource", default: "-", description: "The data source for the meeting rooms." },
            { property: "title", type: "string?", default: "-", description: "A title that will appear above the content in a banner with the primary theme color." },
            { property: "text", type: "string?", default: "-", description: "A text below the available meeting room count." },
          ];

          contentElement = (
            <>
              <Header>Meeting room count</Header>
              <Paragraph>This component is used to render a count of available meeting rooms in a card layout. The meeting rooms need to be defined in the locationIds.</Paragraph>
              <StaticTable data={componentDefinition} columnSizes={componentDefinitionColumnSizes} style={{ maxWidth: "300px" }} />
              <Paragraph $paddingTop="20px">Configuration definition for all properties in the component.</Paragraph>
              <StaticTable data={configurationDefinition} columnSizes={configurationColumnSizes} />
              <Header $paddingTop="35px">Data sources</Header>
              <StaticTable data={dataSourcesDefinition} columnSizes={configurationColumnSizes} />
            </>
          );

          break;
        case "graph":

          const aggregationDefinition = [
            { property: "steps", type: "AggregationStep[]", default: "-", description: "An array of aggregation steps." },
            { property: "startDate", type: "ISOString | Expression", default: "-", description: "The start date for the graph." },
            { property: "startTime", type: "string (HH:mm)", default: "-", description: "The start time for the graph." },
            { property: "endDate", type: "ISOString? | Expression?", default: "now", description: "The end date for the graph." },
            { property: "endTime", type: "string? (HH:mm)", default: "now", description: "The end time for the graph." },
            { property: "includeHolidays", type: "boolean?", default: "true", description: "If true, holidays will be included in the graph." },
            { property: "includeWeekends", type: "boolean?", default: "true", description: "If true, weekends will be included in the graph." },
            { property: "comparison", type: "Aggregation?", default: "-", description: "The comparison aggregation for the graph." },
          ];

          const graphStyleDefinition = [
            { property: "type", type: "line | bar", default: "-", description: "The type of graph to render." },
            { property: "title", type: "string?", default: "-", description: "The title of the graph." },
            { property: "clip", type: "boolean?", default: "true", description: "If true, the graph will be clipped to the container." },
            { property: "axis", type: "{ x: { label: string, grid: any }, y: { label: string, min: number, max: number, unit: string, grid: any } }", default: "-", description: "The axis configuration for the graph." },
            { property: "showLegend", type: "boolean?", default: "true", description: "If true, the legend will be shown." },
            { property: "datasets", type: "{ [datasetId]: DatasetStyle }", default: "-", description: "An object with datasetIds as keys and DatasetStyles as values." }
          ];

          const datasetStyleDefinition = [
            { property: "label", type: "string?", default: "-", description: "The label of the dataset." },
            { property: "valueKeys", type: "{ [valueKey: string]: { [style: string]: any } }", default: "-", description: "An object with valueKeys as keys and objects with styles as values." },
            { property: "?", type: "any", default: "-", description: "Any other Chart.js style options." }
          ];

          const aggregationStepDefinition = [
            { property: "key", type: "string?", default: "-", description: "The key of the aggregation step ('avg', 'min', 'max' or 'sum')." },
            { property: "keys", type: "string[]?", default: "-", description: "An array of keys to aggregate ('avg', 'min', 'max' or 'sum')." },
            { property: "operation", type: "string?", default: "-", description: "The operation to use for the aggregation ('avg', 'min', 'max' or 'sum')." },
            { property: "opertations", type: "{ key: string, operation: string }[]?", default: "-", description: "An array of objects with key and operation to use for the aggregation ('avg', 'min', 'max' or 'sum')" },
            { property: "by", type: "string | string[]", default: "-", description: "The key or keys to group by ('hour', 'hourOfDay', 'day', 'dayOfWeek', 'week', 'month', 'year' or 'entityId')." },
            { property: "postprocess", type: "string?", default: "-", description: "The postprocess operation to use for the aggregation ('ROUND_TO_INTEGER' or 'DECIMAL_TO_PERCENT')." },
            { property: "sort", type: "string?", default: "-", description: "The sort operation to use for the aggregation ('asc' or 'desc')." },
          ];

          configurationDefinition = [
            { property: "sensorType", type: "string", default: "-", description: "The type of data to query." },
            { property: "datasets", type: "{ [datasetId]: DataSource }", default: "-", description: "An object with datasetIds as keys and DataSources as values." },
            { property: "aggregation", type: "Aggregation", default: "-", description: "The aggregation of the data." },
            { property: "style", type: "GraphStyle", default: "-", description: "The style of the graph." }
          ];

          contentElement = (
            <>
              <Header>Graph</Header>
              <Paragraph>This component is used to render a graph of sensor data from a location.</Paragraph>
              <StaticTable data={componentDefinition} columnSizes={componentDefinitionColumnSizes} style={{ maxWidth: "300px" }} />
              <Paragraph $paddingTop="20px">Configuration definition for all properties in the component.</Paragraph>
              <StaticTable data={configurationDefinition} columnSizes={configurationColumnSizes} />
              <Header $paddingTop="35px">Aggregation</Header>
              <StaticTable data={aggregationDefinition} columnSizes={configurationColumnSizes} />
              <Header $paddingTop="35px">Aggregation step</Header>
              <StaticTable data={aggregationStepDefinition} columnSizes={configurationColumnSizes} />
              <Header $paddingTop="35px">Graph style</Header>
              <StaticTable data={graphStyleDefinition} columnSizes={configurationColumnSizes} />
              <Header $paddingTop="35px">Dataset style</Header>
              <StaticTable data={datasetStyleDefinition} columnSizes={configurationColumnSizes} />
              <Header $paddingTop="35px">Data sources</Header>
              <StaticTable data={dataSourcesDefinition} columnSizes={configurationColumnSizes} />
            </>
          );

          break;
        default:
          // contentElement = (
          //   <>
          //     <Header>{selectedType}</Header>
          //     <Paragraph>No component with this name is exist.</Paragraph>
          //   </>
          // );
          break;
      }
    }
    else if (selectedClass === "container") {

      componentDefinition = [
        { property: "class", value: `"container"` },
      ];

      configurationDefinition = [
        { property: "css", type: "{ [key]: value | Expression }", default: "-", description: "Inline CSS that will be added to the container. Supports both kebab-case and camelCase format for CSS-properties. The value for a CSS-property can be an Expression." },
        { property: "children", type: "array", default: "-", description: "A list of child components and containers." },
      ];

      contentElement = (
        <>
          {/* <Container>
            <TextContainer>
              <Header>Container</Header>
              <Paragraph>A container is used to create a layout hierarchy with inline CSS.</Paragraph>
            </TextContainer>
            <ButtonContainer>
              <SmallButton text="Add container" onClick={() => this.onAddContainerClicked("text-area")} disabled={this.props.isLoading} noLeftMargin/>
              <SmallButton text="Add component" onClick={this.onAddComponentClicked} disabled={this.props.isLoading} />
            </ButtonContainer>
          </Container> */}

          <Header>Container</Header>
          <Paragraph>A container is used to create a layout hierarchy with inline CSS.</Paragraph>
          <StaticTable data={componentDefinition} columnSizes={componentDefinitionColumnSizes} style={{ maxWidth: "300px" }} />
          <Paragraph $paddingTop="20px">Configuration definition for all properties in a container.</Paragraph>
          <StaticTable data={configurationDefinition} columnSizes={configurationColumnSizes} />
        </>
      );
    }

    let expressionElement = null;
    const componentWithExpression = selectedClass === "component" && selectedType && (selectedType === "text" || selectedType === "text-area" || selectedType === "image");
    if (componentWithExpression || selectedClass === "container") {

      const propertyDefinitionColumnSizes = {
        property: 35,
        description: 65
      };

      const formatDefinitionColumnSizes = {
        property: 25,
        type: 25,
        description: 50,
      };

      const themePropertiesDefinition = [
        { property: "logoUrl", type: "string", default: "-", description: "The URL to the logo (defined in the theme)." },
        { property: "primaryColor", type: "hex-color", default: "#1C4D82", description: "The primary color (defined in the theme)." },
        { property: "vacantColor", type: "hex-color", default: "#77CF3A", description: "The vacant state color (defined in the theme)." },
        { property: "occupiedColor", type: "hex-color", default: "#D15656", description: "The occupied state color (defined in the theme)." },
        { property: "vacantBookedColor", type: "hex-color", default: "#FAD648", description: "The vacant booked state color (defined in the theme)." }
      ];

      const getConfigDefinition = [
        { property: "propertyName", type: "string", description: "The name of the property to query." },
        { property: "fallback", type: "any?", description: "A value or Expression that will be used if the property is not found. If undefined, returns null." }
      ];

      const getDataDefinition = [
        { property: "locationId", type: "string", description: "The locationId that the data will be queried from." },
        { property: "propertyType", type: "string", description: "The type of data to query." },
        { property: "fallback", type: "any?", description: "A value or Expression that will be used if the property is not found. If undefined, returns null." }
      ];

      const propertyTypesDefinition = [
        { property: "temperature", description: "The temperature in the location." },
        { property: "humidity", description: "The humidity in the location." },
        { property: "carbonDioxideLevel", description: "The CO2 level in the location." },
        { property: "vocLevel", description: "The volatile organic compounds level in the location." },
        { property: "soundPressureLevel", description: "The noise level in the location." },
        { property: "peopleCount", description: "The number of people in the location." },
        { property: "roomStatus", description: "If the room is vacant or occupied." },
        { property: "indoorAirQualityIndex", description: "The IAQ index in the location." },
      ];

      const getAttributeDefinition = [
        { property: "locationId", type: "string", description: "The locationId that the attribute will be queried from." },
        { property: "attributeName", type: "string", description: "The name of the attribute to query." },
        { property: "fallback", type: "any?", description: "A value or Expression that will be used if the attribute is not found. If undefined, returns null." }
      ];

      const attributeNameDefinition = [
        { property: "name", description: "The location name." },
        { property: "description", description: "The location description." },
        { property: "type", description: "The location type." },
        { property: "capacity", description: "The people capacity in the location." },
        { property: "timezone", description: "The timezone of the location." },
        { property: "zone", description: "The zone the location is in, if any." },
        { property: "floor", description: "The floor the location is in, if any." },
        { property: "building", description: "The building the location is in, if any." },
        { property: "region", description: "The region the location is in, if any." }
      ];

      const comparisonOperatorDefinition = [
        { property: "operator", type: "string", description: "Either '==', '>', '>=', '<', '<=' or '!='." },
        { property: "leftValue", type: "number | Expression", description: "A number or Expression to compare." },
        { property: "rightValue", type: "number | Expression", description: "A number or Expression to compare." }
      ];

      const logicalOperatorDefinition = [
        { property: "operator", type: "string", description: "Either 'and', 'or', or 'not'." },
        { property: "value", type: "boolean | Expression", description: "A value or Expression to compare." },
      ];

      const caseDefinition = [
        { property: "condition", type: "boolean | Expression", description: "The condition to check." },
        { property: "returnValue", type: "any | Expression", description: "The value or Expression to return if the condition is true." },
        { property: "fallback", type: "any | Expression", description: "The value or Expression to return if no conditions are true." },
      ];

      const mathDefinition = [
        { property: "operator", type: "string", description: "Either '+', '-', '*', '/', 'max' or 'min'." },
        { property: "leftValue", type: "number | Expression", description: "A number or Expression to calculate." },
        { property: "rightValue", type: "number | Expression", description: "A number or Expression to calculate." }
      ];

      const math2Definition = [
        { property: "operator", type: "string", description: "Either 'floor', 'ceil', 'round' or 'abs'." },
        { property: "value", type: "number | Expression", description: "A number or Expression to calculate." }
      ];

      const joinDefinition = [
        { property: "separator", type: "string", description: "The separator to use when joining the values."},
        { property: "values", type: "array", description: "An array of strings or Expressions to join." },
      ];

      expressionElement = (
        <>
          <Header $paddingTop="35px">Expression</Header>
          <Paragraph>The layout config has a lot of ways to customize how each container and component will look, but you can only add static css values which means that you have to add the same color code muliple places and never base the look of on any data. This is why we added expressions - to let you add logic to your css and make any layout you want.</Paragraph>
          
          <h3>Get config</h3>
          <Paragraph>A query to look up properties in the Screen Theme.</Paragraph>
          <CodeBlock>{`["get-config", <propertyName>, <fallback>]`}</CodeBlock>
          <StaticTable data={getConfigDefinition} columnSizes={formatDefinitionColumnSizes} />
          <Paragraph $paddingTop="20px">All properties in the config</Paragraph>
          <StaticTable data={themePropertiesDefinition} columnSizes={configurationColumnSizes} />

          <h3>Get data</h3>
          <Paragraph>A query that look up sensor data in a location.</Paragraph>
          <CodeBlock>{`["get-data", <locationId>, <propertyType>, <fallback>]`}</CodeBlock>
          <StaticTable data={getDataDefinition} columnSizes={formatDefinitionColumnSizes} />
          <Paragraph $paddingTop="20px">Some of the property types</Paragraph>
          <StaticTable data={propertyTypesDefinition} columnSizes={propertyDefinitionColumnSizes} />

          <h3>Get attribute</h3>
          <Paragraph>A query that look up an attribute for a location.</Paragraph>
          <CodeBlock>{`["get-attribute", <locationId>, <attributeName>, <fallback>]`}</CodeBlock>
          <StaticTable data={getAttributeDefinition} columnSizes={formatDefinitionColumnSizes} />
          <Paragraph $paddingTop="20px">Some of the available attributes</Paragraph>
          <StaticTable data={attributeNameDefinition} columnSizes={propertyDefinitionColumnSizes} />

          <h3>Case</h3>
          <Paragraph>The case function is our switch-logic operator. It either selects the first true condition or the fallback if no conditions are true. Use logical operators and comparison operators to create complex switch statements.</Paragraph>
          <CodeBlock>{`["case", <condition>, <returnValue>, <condition>, <returnValue>, ..., <fallback>]`}</CodeBlock>
          <StaticTable data={caseDefinition} columnSizes={formatDefinitionColumnSizes} />

          <h3>Join</h3>
          <Paragraph>These functions joins the values using the specified separator.</Paragraph>
          <CodeBlock>{`["join", <separator>, <value>, ..., <value>]`}</CodeBlock>
          <StaticTable data={joinDefinition} columnSizes={formatDefinitionColumnSizes} />

          <h3>Logical operators</h3>
          <Paragraph>These functions evaluates all values with the specified logical operator.</Paragraph>
          <CodeBlock>{`[<operator>, <value>, ..., <value>]`}</CodeBlock>
          <StaticTable data={logicalOperatorDefinition} columnSizes={formatDefinitionColumnSizes} />

          <h3>Comparison operators</h3>
          <Paragraph>These functions compares the left value with the right value using the specified operator.</Paragraph>
          <CodeBlock>{`[<operator>, <leftValue>, <rightValue>]`}</CodeBlock>
          <StaticTable data={comparisonOperatorDefinition} columnSizes={formatDefinitionColumnSizes} />

          <h3>Math calculation operators</h3>
          <Paragraph>These functions calculates the left value with the right value using the specified operator.</Paragraph>
          <CodeBlock>{`[<operator>, <leftValue>, <rightValue>]`}</CodeBlock>
          <StaticTable data={mathDefinition} columnSizes={formatDefinitionColumnSizes} />

          <h3>Math formating operators</h3>
          <Paragraph>These functions formats the value using the specified operator.</Paragraph>
          <CodeBlock>{`[<operator>, <value>]`}</CodeBlock>
          <StaticTable data={math2Definition} columnSizes={formatDefinitionColumnSizes} />
        </>
      );
    }

    return (
      <>
        <div style={{ paddingTop: "25px" }} />
        { contentElement }
        { expressionElement }
        <div style={{ paddingTop: "40px" }} />
      </>
    );
  }
}

function mapStateToProps(state) {
  return {

  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
  }, dispatch);
}

export default connect(mapStateToProps,mapDispatchToProps)(ScreenViewLayoutDetails);

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  min-height: 44px;
  justify-content: ${props => props.$justify ?? "space-between"};
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  margin-right: 5px;
`;

const Header = styled.h2`
  font-size: 26px;
  line-height: 38.5px;
  min-height: 38.5px;
  margin: 0;
  font-family: Source Sans Pro;
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  color: #0c0f26;
  margin-top: ${props => props.$paddingTop ?? "0px"};
`;

const Paragraph = styled.p`
  font-family: Source Sans Pro;
  font-size: 15px;
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #0c0f26;
  margin-top: ${props => props.$paddingTop ?? "0px"};
`;

const CodeBlock = styled.div`
  display: block;
  width: fit-content;
  max-width: 100%;
  overflow: auto;
  max-height: 400px;
  margin-bottom: 20px;

  background-color: white;
  border: 1px solid #dfe4e8;
  border-radius: 5px;
  padding: 10px 15px; // 8px; 

  font-family: monospace;
  font-size: 14px;
  line-height: 1.5;
  letter-spacing: normal;
  color: #0c0f26;
  white-space: pre-wrap;
  word-wrap: break-word;
  overflow-wrap: break-word;
  word-break: break-word;
  hyphens: auto;
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: start;
  margin-bottom: 10px;
`;

const getLastObjectFromPath = (path, object) => {
  let lastProp = object;
  let lastObject = null;

  if (typeof lastProp === "object") {
    lastObject = lastProp;
  }

  for (let i = 0; i < path.length; i++) {
    const key = get(path, i, null);

    // In case of an invalid path/object
    if (key === null || key === "css") {
      break;
    }

    lastProp = get(lastProp, key);

    if (!lastProp) {
      return null;
    }

    if (typeof lastProp === "object") {
      lastObject = lastProp;

      if (lastProp.class && lastProp.class === "component") {
        return lastObject;
      }
    }
  }

  return lastObject;
}