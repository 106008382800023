import React from "react";
import Tag from "../Tag";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faClose, faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import { DropdownSelection } from "../DropdownSelection";
import { getLocationFilterTypes, locationFilterTypes } from "../../locationHelpers";
import style from "./style.module.scss"

const DashboardFilterSearchBox = ({ editMode = false, queries = [], selectedIndex = -1, hideEditButton = false, onQueryClick = () => {}, onQueryChange = () => {}, onKeyDown = () => {}, onSearchClick = () => {}, onRemoveCustomTagClick = () => {}, onRemoveLocationTypeClick = () => {}, onRemoveLocationClick = () => {}, onRemoveNameTagClick = () => {}, onAddQuery = () => {}, onRemoveQuery = () => {} }) => (
  <div className={style.searchContainer}>
    <div className={style.box}>
      {
        queries.map((query, index) => (
          <div key={`query-${index}`} className={style.queryContainer} data-selected={index === selectedIndex} data-first={index === 0} data-last={index === queries.length - 1}>
            <div className={style.innerBox}>
              { query.locations.length > 0 ? (
                query.locations.map(location => <div key={location.id} className={style.tag}><Tag text={location.name} not={!location.include} onRemoveClick={editMode ? () => onRemoveLocationClick(index,location) : null} /></div>)
              ) : (
                <div key={"all-locations"} className={style.tag}><Tag text={"All locations"} /></div>
              )
              }
              { query.locationTypes.map(type => <div key={type.id} className={style.tag}><Tag text={type.name} color={"#5c677d"} onRemoveClick={editMode ? () => onRemoveLocationTypeClick(index,type) : null} /></div>) }
              { query.customTags.map(tag => <div key={tag.id} className={style.tag}><Tag text={tag.name} color={tag.colorTheme} onRemoveClick={editMode ? () => onRemoveCustomTagClick(index,tag) : null} /></div>) }
              { query.value && (<Tag text={query.value} onRemoveClick={editMode ? () => onRemoveNameTagClick() : null} />) }
            </div>
            <div className={style.queryOptions}>
              {
                !hideEditButton && (
                  <div className={style.queryOptionButton} onClick={() => onQueryClick(index)}>
                    <div className={style.queryIcon}>
                      <FontAwesomeIcon icon={faPencilAlt} color={index === selectedIndex ? "#1c4d82" : "grey"} />
                    </div>
                  </div>
                )
              }
              {
                index === selectedIndex && queries.length !== 1 && editMode && (
                  <div className={style.queryOptionButton} onClick={() => onRemoveQuery(index)}>
                    <div className={style.queryIcon}>
                      <FontAwesomeIcon icon={faClose} color={index === selectedIndex ? "#9e2523" : "grey"} />
                    </div>
                  </div>
                )
              }
            </div>
          </div>
        ))
      }
    </div>
    {
      editMode && (
        <div className={style.addQueryBox}>
          <div className={style.queryAddButton} onClick={() => onAddQuery()}>
            <div className={style.queryIcon}>
              <FontAwesomeIcon icon={faPlus} color="grey" />
            </div>
          </div>
        </div>
      )
    }
    
    {/* <div className={style.searchButton} onClick={() => onSearchClick()}>
      <div className={style.searchButtonText}>Search</div>
    </div> */}
  </div>
);

export default DashboardFilterSearchBox;