import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import styled from "styled-components";
import util_hour_ex from "./Utilization/util_hour_ex.png";
import util_days_ex from "./Utilization/util_days_ex.png";
import util_weekday_ex from "./Utilization/util_weekday_ex.png";
import util_week_ex from "./Utilization/util_week_ex.png";
import util_month_ex from "./Utilization/util_month_ex.png";
import pc_hour_ex from "./PeopleCount/pc_hour_ex.png";
import pc_days_ex from "./PeopleCount/pc_days_ex.png";
import pc_weekday_ex from "./PeopleCount/pc_weekday_ex.png";
import pc_week_ex from "./PeopleCount/pc_week_ex.png";
import pc_month_ex from "./PeopleCount/pc_month_ex.png";
import pc_year_over_year_ex from "./PeopleCount/pc_year_over_year_ex.png";
import strings from "./strings";

class HelpView extends Component {

  render() {
    const { selectedView, selectedPage, showingAvg, onClose } = this.props;

    // Get localized strings
    let texts = {};
    let exampleImage = null;
    let intro;
    let definitions;
    if (selectedView === "u") {
      switch (selectedPage) {
        case "intraday":
          texts = strings.utilization.intraday;
          exampleImage = util_hour_ex;
          break;
        case "day":
          texts = strings.utilization.day;
          exampleImage = util_days_ex;
          break;
        case "weekday":
          texts = strings.utilization.weekday;
          exampleImage = util_weekday_ex;
          break;
        case "week":
          texts = strings.utilization.week;
          exampleImage = util_week_ex;
          break;
        case "month":
          texts = strings.utilization.month;
          exampleImage = util_month_ex;
          break;
        default:
          break;
      } 
    }
    else if (selectedView === "pc") {
      switch (selectedPage) {
        case "intraday":
          texts = strings.peopleCount.intraday;
          exampleImage = pc_hour_ex;
          break;
        case "day":
          texts = strings.peopleCount.day;
          exampleImage = pc_days_ex;
          break;
        case "weekday":
          texts = strings.peopleCount.weekday;
          exampleImage = pc_weekday_ex;
          break;
        case "week":
          texts = strings.peopleCount.week;
          exampleImage = pc_week_ex;
          break;
        case "month":
          texts = strings.peopleCount.month;
          exampleImage = pc_month_ex;
          break;
        case "yearOverYear":
          texts = strings.peopleCount.yearOverYear;
          exampleImage = pc_year_over_year_ex;
          break;
        default:
          break;
      }
    }

    // intro = (
    //   <p>
    //     {texts.helpIntro.map((t,i) => {
    //       return <span key={i} style={{ fontWeight: t.fontWeight, color: t.color }}>{t.text}</span>;
    //     })}
    //   </p>
    // );

    // definitions = (
    //   <p>
    //     { texts.helpDefinitions.map((line, i) => {
    //       return (
    //         <div key={i}>
    //           { line.map((textPart, j) => {
    //             return <span key={j} style={{ fontWeight: textPart.fontWeight, color: textPart.color }}>{textPart.text}</span>;
    //           })}
    //         </div>
    //       );
    //     })}
    //   </p>
    // );

    return (
      <Container onClick={onClose}>
        <ScrollBox>
          <Block onClick={(e) => e.stopPropagation()}>
            <CloseButton onClick={onClose}>
              <FontAwesomeIcon icon={faXmark} size="lg" />
            </CloseButton>
            <BlockContent>
              <BlockInnerTitle>{ texts.title }</BlockInnerTitle>

              { intro }

              { definitions }

              { texts.helpExample && texts.helpExample.map((text, index) => {
                if (text === "ex1" && exampleImage) {
                  return (
                    <ExampleBlockImageContainer key={"ex-" + index} >
                      <img src={exampleImage} alt="example" style={{ width: "910px", maxWidth: "100%" }} />
                    </ExampleBlockImageContainer>
                  );
                }
                else {
                  return <p key={"ex-" + index}>{text}</p>;
                }
              })}

            </BlockContent>
          </Block>
        </ScrollBox>
      </Container>
    );
  }
}

export default HelpView;

const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  z-index: 3;
`;

const ScrollBox = styled.div`
  position: relative;
  overflow: auto;
  max-height: 100%;
  height: 100%;
  padding: 30px;
  box-sizing: border-box;
`;

const Block = styled.div`
  position: relative;
  background-color: rgb(255, 255, 255);
  border-radius: 6px;
  border-width: 1px;
  border-style: solid;
  border-color: rgb(221, 221, 221);
  margin-left: "10px";
  margin-right: "10px";
  font-size: 16px;
  font-weight: 400;
  
  max-width: 1100px;
  margin: 0 auto;
`;

const BlockContent = styled.div`
  // display: flex;
  // flex-direction: column;
  // flex-grow: 1;
  position: relative;
  min-height: 380px;
  padding: 10px 20px;
  font-size: 16px;
  font-weight: 400;
`;

const CloseButton = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: 50px;
  height: 50px;
  cursor: pointer;
  z-index: 3;

  font-size: 20px;
  font-weight: 100;
  color: #333;

  display: flex;
  align-items: center;
  justify-content: center;
`;

const BlockInnerTitle = styled.div`
  font-size: 20px;
  font-weight: 600;
  color: #222222;
  margin-top: 8px;
  margin-bottom: 8px;
`;

const ExampleBlockImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
`;