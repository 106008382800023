import get from "lodash/get";
import { toast } from "react-toastify";
import * as types from "../ApiTypes";
import locations from "../containers/Locations/locations";

const initialState = {
  id: null,
  name: null,
  type: null,
  updatedAt: null,
  jobs: []
};

export default function jobGroupReducer(state = initialState, action) {

  switch (action.type) {

    case types.REQ_DATA: {

      if (action.fetchType === types.GET_JOB_GROUP) {
        return { ...initialState, id: action.payload ? action.payload.jobId : null };
      }

      return state;
    }

    case types.RECV_DATA: {

      if (action.fetchType === types.GET_JOB_GROUP) {
        return {
          ...initialState,
          id: action.payload.subject._id,
          name: action.payload.subject.name,
          type: action.payload.subject.type,
          updatedAt: action.payload.lastUpdateAt,
          jobs: action.payload.jobs
        };
      }

      return state;
    }

    case types.RECV_ERROR: {

      const statusCode = get(action, "payload.response.status", "Error");

      if (action.fetchType === types.GET_JOB_GROUP) {
        toast.error(`${statusCode}: Could not get job group`);
        return state;
      }

      return state;
    }

    case types.CLEAR_DATA: {
      return initialState;
    }

    default: {
      return state;
    }
  }
}
