import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import moment from "moment";
import { get, isEmpty } from "lodash";
import DetailsHeader from "../../components/DetailsHeader";
import StatusLabel from "../../components/StatusLabel";
import Table from "../../components/Table";
import * as jobActions from "../../actions/jobs";
import style from "./style.module.scss";

class JobList extends Component {

  constructor(props) {
    super(props);
    this.onRowClick = this.onRowClick.bind(this);

    if (this.props.match.params.id && this.props.match.params.type && this.props.match.params.id !== "unknown" && this.props.match.params.type !== "unknown") {
      this.props.getJobGroup(this.props.match.params.id, this.props.match.params.type);
    }
  }

  componentDidMount() {
    if (!isEmpty(this.props.jobGroup.name)) {
      document.title = `BLDNG.ai - JobList - ${this.props.jobGroup.name}`;
    }
    else {
      document.title = `BLDNG.ai - JobList - ${this.props.jobGroup.id}`;
    }
  }

  onRowClick(column, rowInfo) {
    return {
      onClick: e => {
        this.props.getJob(rowInfo.original._id);
        this.props.history.push(`/companies/${this.props.match.params.companyId}/installation/job-groups/${this.props.match.params.type}/${this.props.match.params.id}/${rowInfo.original._id}`);
      },
      style: {
        cursor: "pointer"
      }
    }
  }

  render() {
    const { isLoading } = this.props;

    if (!this.props.match.params.id || 
      this.props.match.params.id === "unknown" || 
      !this.props.match.params.type || 
      this.props.match.params.type === "unknown" || 
      isEmpty(this.props.jobGroup.id)) {
      return null;
    }

    const tableElement = (
      <Table
        data={this.props.jobGroup.jobs}
        noDataText={isLoading ? "" : "No jobs found"}
        columns={[
          {
            header: "Updated",
            accessorKey: "updatedAt",
            sortable: false,
            width: 120,
            cell: ({ row }) => {
              const date = `${moment(row.original.updatedAt).format("HH:mm - DD/MM/YY")}`;
              return <span title={date}>{date}</span>;
            }
          },
          {
            header: "Type",
            accessorKey: "jobType",
            sortable: false,
            cell: ({ row }) => (<span>{row.original.jobType}</span>)
          },
          // {
          //   header: "Status",
          //   accessorKey: "jobStatus",
          //   width: 100,
          //   cell: ({ row }) => (<span>{row.original.jobStatus}</span>)
          // },
          {
            header: "Status",
            accessorKey: "jobStatus",
            sortable: false,
            width: 120,
            cell: ({ row }) => {
              const { jobStatus } = row.original;
              switch (jobStatus) {
                case "waiting":
                  return <StatusLabel text={jobStatus} color={"grey"} inRow />
                case "failed":
                  return <StatusLabel text={jobStatus} color={"red"} inRow />
                case "active":
                  return <StatusLabel text={jobStatus} color={"blue"} inRow />
                case "completed":
                  return <StatusLabel text={jobStatus} color={"green"} inRow />
                default:
                  break;
              }
              
              return <StatusLabel text={jobStatus} color={"grey"} inRow />
            }
          },
          {
            id: "arrow",
            header: "",
            accessorKey: "_id",
            sortable: false,
            className: "pull-right",
            width: 60,
            cell: ({ row }) => (<div className="arrow" />)
          }
        ]}
        getTdProps={this.onRowClick}
        className="setMaxHeigth -row-clickable -highlight"
        loading={isLoading}
      />
    );

    return (
      <div className={style.scroll}>
        <DetailsHeader
          title={get(this.props.jobGroup, "name", "?")}
        />
        { tableElement }
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    jobGroup: state.jobGroup,
    selectedLocations: state.selected.locations
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    getJobGroup: jobActions.getJobGroup,
    getJob: jobActions.getJob,
    setJob: jobActions.setJob
   }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(JobList);