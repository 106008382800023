import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { rangeShape } from '../DayCell';
import Month from '../Month';
import { calcFocusDate, generateStyles } from '../../utils';
import classnames from 'classnames';
import {
  addMonths,
  subMonths,
  startOfMonth,
  format,
  eachDayOfInterval,
  startOfWeek,
  endOfWeek,
  isSameDay,
  addYears,
  setYear,
  setMonth,
  differenceInCalendarMonths,
  min,
  max,
} from 'date-fns';
import defaultLocale from 'date-fns/locale/en-US';
import coreStyles from '../../styles';
import { ariaLabelsShape } from '../../accessibility';
import strings from '../strings';

class Calendar extends PureComponent {

  constructor(props, context) {
    super(props, context);
    this.dateOptions = { locale: props.locale };
    if (props.weekStartsOn !== undefined) this.dateOptions.weekStartsOn = props.weekStartsOn;
    this.styles = generateStyles([coreStyles, props.classNames]);
    this.listSizeCache = {};
    this.isFirstRender = true;
    this.state = {
      monthNames: this.getMonthNames(),
      focusedDate: calcFocusDate(null, props),
      drag: {
        status: false,
        range: { startDate: null, endDate: null }
      }
    };
  }

  getMonthNames() {
    return [...Array(12).keys()].map(i => this.props.locale.localize.month(i));
  }

  focusToDate = (date, props = this.props, preventUnnecessary = true) => {
    // console.log("focusToDate", date, props, preventUnnecessary);
    if (preventUnnecessary) {
      const focusedDateDiff = differenceInCalendarMonths(date, this.state.focusedDate);
      if ((focusedDateDiff <= 0) && Math.abs(focusedDateDiff) < props.months) {
        return;
      }
    }

    this.setState({ focusedDate: date });
  };

  componentDidUpdate(prevProps) {

    if (prevProps.locale !== this.props.locale || 
      prevProps.weekStartsOn !== this.props.weekStartsOn) {

      this.dateOptions = { locale: this.props.locale };
      if (this.props.weekStartsOn !== undefined) {
        this.dateOptions.weekStartsOn = this.props.weekStartsOn;
      }

      this.setState({
        monthNames: this.getMonthNames(),
      });
    }
  }

  changeShownDate = (value, mode = 'set') => {
    const { focusedDate } = this.state;
    const { onShownDateChange, minDate, maxDate } = this.props;
    const modeMapper = {
      monthOffset: () => addMonths(focusedDate, value),
      setMonth: () => setMonth(focusedDate, value),
      setYear: () => setYear(focusedDate, value),
      set: () => value,
    };

    // console.log("focusedDate", focusedDate);
    // console.log("minDate", minDate);
    // console.log("maxDate", maxDate);

    // const newDate = min([max([modeMapper[mode](), minDate]), maxDate]);
    const newDate = min([max([modeMapper[mode]()]), maxDate]);

    // console.log("changeShownDate", value, mode, newDate);

    this.focusToDate(newDate, this.props, false);
    onShownDateChange && onShownDateChange(newDate);
  };

  renderMonthAndYear = (focusedDate, changeShownDate, props) => {
    const { showMonthArrow, minDate, maxDate, months, ariaLabels } = props;
    // const minDateWithOffset = addMonths(minDate, months);
    const styles = this.styles;
    return (
      <div onMouseUp={e => e.stopPropagation()} className={styles.monthAndYearWrapper}>
        {showMonthArrow ? (
          <button
            type="button"
            className={classnames(styles.nextPrevButton, styles.prevButton)}
            onClick={() => changeShownDate(-1, 'monthOffset')}
            aria-label={ariaLabels.prevButton}
            //disabled={differenceInCalendarMonths(focusedDate, minDate) <= 0}
            >
            <i />
          </button>
        ) : null}
        <span className={styles.monthAndYearPickers}>
          { strings.months }
        </span>
        {showMonthArrow ? (
          <button
            type="button"
            className={classnames(styles.nextPrevButton, styles.nextButton)}
            onClick={() => changeShownDate(+1, 'monthOffset')}
            aria-label={ariaLabels.nextButton}
            disabled={differenceInCalendarMonths(maxDate, focusedDate) < props.months}>
            <i />
          </button>
        ) : null}
      </div>
    );
  };

  renderWeekdays() {
    const now = new Date();
    return (
      <div className={this.styles.weekDays}>
        {eachDayOfInterval({
          start: startOfWeek(now, this.dateOptions),
          end: endOfWeek(now, this.dateOptions),
        }).map((day, i) => (
          <div className={this.styles.weekDay} key={i}>
            {format(day, this.props.weekdayDisplayFormat, this.dateOptions)}
          </div>
        ))}
      </div>
    );
  }

  onDragSelectionStart = date => {
    // console.log('onDragSelectionStart', date);
    const { onChange, dragSelectionEnabled } = this.props;

    // Create a new range 
    const newRange = {
      startDate: date,
      endDate: date,
    };

    if (dragSelectionEnabled) {
      this.setState({
        drag: {
          status: true,
          range: newRange
        },
      });
    } else {
      onChange && onChange(newRange);
    }
  };

  onDragSelectionEnd = date => {
    // console.log('onDragSelectionEnd', date);
    const { updateRange, displayMode, onChange, dragSelectionEnabled } = this.props;

    if (!dragSelectionEnabled) return;

    // console.log('onDragSelectionEnd 2');

    if (displayMode === 'date' || !this.state.drag.status) {
      onChange && onChange({ startDate: date, endDate: date });
      return;
    }

    // console.log('onDragSelectionEnd 3');

    const newRange = {
      startDate: this.state.drag.range.startDate,
      endDate: date,
    };

    if (displayMode !== 'dateRange' || isSameDay(newRange.startDate, date)) {
      // console.log('onDragSelectionEnd 4');
      this.setState({ drag: { status: false, range: {} } }, () => onChange && onChange({ startDate: date, endDate: date }));
    } 
    else {
      // console.log('onDragSelectionEnd 5');
      this.setState({ drag: { status: false, range: {} } }, () => {
        updateRange && updateRange(newRange);
      });
    }
    // console.log('onDragSelectionEnd 6');
  };

  onDragSelectionMove = date => {
    const { drag } = this.state;
    
    if (!drag.status || !this.props.dragSelectionEnabled) return;
    
    this.setState({
      drag: {
        status: drag.status,
        range: { startDate: drag.range.startDate, endDate: date }
      },
    });
  };
  
  render() {
    const {
      direction,
      disabledDates,
      disabledDay,
      holidayDates,
      rangeColors,
      color,
      className,
    } = this.props;
    const { focusedDate } = this.state;
    const isVertical = direction === 'vertical';

    const ranges = this.props.ranges.map((range, i) => ({
      ...range,
      color: range.color || rangeColors[i] || color,
    }));

    // console.log('Calendar.render() props', this.props);
    // console.log('Calendar.render() state', this.state);

    return (
      <div
        className={classnames(this.styles.calendarWrapper, className)}
        onMouseUp={() => {
          // console.log("onMouseUp in Calendar")
          this.setState({ drag: { status: false, range: {} } })
        }}
        onMouseLeave={() => {
          // console.log("onMouseLeave in Calendar")
          this.setState({ drag: { status: false, range: {} } })
        }}
        >
        {this.renderMonthAndYear(focusedDate, this.changeShownDate, this.props)}
        <div
          className={classnames(
            this.styles.months,
            isVertical ? this.styles.monthsVertical : this.styles.monthsHorizontal
          )}>
          {new Array(this.props.months).fill(null).map((_, i) => {
            let startFocusedMonth = startOfMonth(this.state.focusedDate);
            let monthStep = addMonths(startFocusedMonth, i);
            return (
              <Month
                {...this.props}
                ranges={ranges}
                key={i}
                drag={this.state.drag}
                dateOptions={this.dateOptions}
                disabledDates={disabledDates}
                disabledDay={disabledDay}
                disableWeekends={this.props.disableWeekends}
                disableHolidays={this.props.disableHolidays}
                holidayDates={holidayDates}
                month={monthStep}
                onDragSelectionStart={this.onDragSelectionStart}
                onDragSelectionEnd={this.onDragSelectionEnd}
                onDragSelectionMove={this.onDragSelectionMove}
                // focusedRange={this.state.focusedRange}
                styles={this.styles}
                showWeekDays={!isVertical || i === 0}
                showMonthName={!isVertical || i > 0}
              />
            );
          })}
        </div>
      </div>
    );
  }
}

Calendar.defaultProps = {
  showMonthArrow: true,
  showMonthAndYearPickers: true,
  disabledDates: [],
  disabledDay: () => {},
  disableWeekends: false,
  disableHolidays: false,
  holidayDates: [],
  classNames: {},
  locale: defaultLocale,
  ranges: [],
  focusedRange: [0, 0],
  dateDisplayFormat: "DD/MM/YYYY",
  monthDisplayFormat: 'MMMM  yyyy',
  weekdayDisplayFormat: 'E',
  dayDisplayFormat: 'd',
  displayMode: 'dateRange',
  months: 1,
  color: '#2D7ACC',
  direction: 'horizontal',
  maxDate: addYears(new Date(), 20),
  minDate: addYears(new Date(), -100),
  rangeColors: ['#3d91ff', '#3ecf8e', '#fed14c'],
  startDatePlaceholder: 'Early',
  endDatePlaceholder: 'Continuous',
  editableDateInputs: false,
  dragSelectionEnabled: true,
  fixedHeight: true,
  ariaLabels: {},
  weekStartsOn: 1,
};

Calendar.propTypes = {
  showMonthArrow: PropTypes.bool,
  showMonthAndYearPickers: PropTypes.bool,
  disabledDates: PropTypes.array,
  disabledDay: PropTypes.func,
  disableWeekends: PropTypes.bool,
  disableHolidays: PropTypes.bool,
  holidayDates: PropTypes.array,
  minDate: PropTypes.object,
  maxDate: PropTypes.object,
  date: PropTypes.object,
  onChange: PropTypes.func,
  classNames: PropTypes.object,
  locale: PropTypes.object,
  shownDate: PropTypes.object,
  onShownDateChange: PropTypes.func,
  ranges: PropTypes.arrayOf(rangeShape),
  dateDisplayFormat: PropTypes.string,
  monthDisplayFormat: PropTypes.string,
  weekdayDisplayFormat: PropTypes.string,
  weekStartsOn: PropTypes.number,
  dayDisplayFormat: PropTypes.string,
  focusedRange: PropTypes.arrayOf(PropTypes.number),
  initialFocusedRange: PropTypes.arrayOf(PropTypes.number),
  months: PropTypes.number,
  className: PropTypes.string,
  displayMode: PropTypes.oneOf(['dateRange', 'date']),
  color: PropTypes.string,
  updateRange: PropTypes.func,
  direction: PropTypes.oneOf(['vertical', 'horizontal']),
  startDatePlaceholder: PropTypes.string,
  endDatePlaceholder: PropTypes.string,
  rangeColors: PropTypes.arrayOf(PropTypes.string),
  editableDateInputs: PropTypes.bool,
  dragSelectionEnabled: PropTypes.bool,
  fixedHeight: PropTypes.bool,
  ariaLabels: ariaLabelsShape,
};

export default Calendar;
