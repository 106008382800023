import React, { Component } from "react";
import styled from "styled-components";

class TopRowOptions extends Component {

  constructor(props) {
    super(props);
  }

  render() {
    return (
      <Container>
        { 
          this.props.description && (
            <TextContainer>
              { this.props.description }
            </TextContainer>
          )
        }
        {
          this.props.searchbox && (
            <SearchContainer>
              { this.props.searchbox }
            </SearchContainer>
          )
        }
        {
          this.props.buttons && (
            <ButtonContainer>
              { this.props.buttons }
            </ButtonContainer>
          )
        }
      </Container>
    );
  }
}

export default TopRowOptions;

const Container = styled.div`
  display: flex;
  padding-top: 25px;
  margin-bottom: 5px;
  flex-wrap: wrap;
  justify-content: ${props => props.justify ?? "space-between"};
  flex-grow: ${props => props.useFullWidth ?? 1};
  align-items: center;
  row-gap: 15px;
`;

const TextContainer = styled.p`
  display: flex;
  align-items: center;
  width: 100%;
  margin-top: 0;
  margin-bottom: 0;

  font-family: Source Sans Pro;
  font-size: 15px;
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.25;
  letter-spacing: normal;
  color: #0c0f26;
`;

const SearchContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-right: 10px;
  max-width: 100%;
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: end;
`;