import React from "react";
// @ts-ignore
import { get, isNil, isEmpty} from "lodash";
import styled from "styled-components";
import moment from "moment";
import "moment/locale/nb";
import "chartjs-adapter-moment";
import ChartDataLabels from "chartjs-plugin-datalabels";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Tooltip,
  Title,
  Legend,
  TimeScale,
  Filler
} from "chart.js";
import { Line, Bar, getElementAtEvent } from "react-chartjs-2";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload, faFileCsv, faFileImage, faExpandArrowsAlt, faArrowDown, faSortNumericUp, faChartLine, faChartColumn } from "@fortawesome/free-solid-svg-icons";
import { getTitle, getHelpText } from "./helpers";
import { SelectionPlugin } from "./chartSelection";
import { createReportLegendPlugin } from "./chartLegend";
import { BarWidthPlugin } from "./chartBarWidth";
import RangeSlider from "./rangeSlider";
import Loader from "../../Loader";
import Info from "../../Info";
import strings from "./strings";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Tooltip,
  Title,
  Legend,
  TimeScale,
  Filler,
  ChartDataLabels,
  {
    id: "white-background",
    beforeDraw: (chart) => {
      const ctx = chart.ctx;
      ctx.fillStyle = "white";
      ctx.fillRect(0, 0, chart.width, chart.height);
    },
    beforeDatasetsDraw: function(chart) {
      // Hide chart outside of the canvas
      var ctx = chart.ctx;
      var chartArea = chart.chartArea;
      ctx.save();
      ctx.beginPath();

      ctx.rect(chartArea.left - 5, chartArea.top - 5, chartArea.right - chartArea.left + 10, chartArea.bottom - chartArea.top + 10);
      // ctx.clip();
    },
    afterDatasetsDraw: function(chart) {
      // Hide chart outside of the canvas
      chart.ctx.restore();
    },
  },
  BarWidthPlugin,
  SelectionPlugin
  // ZoomPlugin
);

export enum TimePeriod {
  Hour = "hour",
  Day = "day",
  Week = "week",
  Month = "month",
  WeekDays = "weekDays",
  HourOfDay = "hourOfDay",
  QuarterOfDay = "quarterOfDay",
  Dynamic = "dynamic"
}

export enum StepSize {
  Minute = "minute",
  Quarter = "quarter",
  Hour = "hour",
  Day = "day",
  Week = "week",
  Month = "month"
}

export enum GraphType {
  Line = "line",
  Bar = "bar"
}

const avgColors = {
  borderColor: "#F94144",
  backgroundColor: "#F94144"
};

const maxColors = {
  borderColor: "#277DA1",
  backgroundColor: "#277DA1",
};

const minColors = {
  borderColor: "#F9844A",
  backgroundColor: "#F9844A",
};

const capacityColors = {
  borderColor: "#444444",
  backgroundColor: "#444444",
  radius: 0
};

const uniqueColors = [
  "#e6194b", "#3cb44b", "#ffe119", "#4363d8", "#f58231", "#911eb4", "#46f0f0", "#f032e6", "#bcf60c", "#fabebe", "#008080", "#e6beff", "#9a6324", "#fffac8", "#800000", "#aaffc3", "#808000", "#ffd8b1", "#000075", "#808080", "#ffffff", "#000000"
];


const safeColors = [["#F94144", "#BA3033", "#7A2021"], ["#39B7ED", "#277DA1", "#174B61"], ["#EB8C6A", "#CC6843", "#9E5034"], ["#93E66A", "#6AB346", "#4E912C"], ["#D27685", "#9E4784", "#66347F"], ["#84B5AE", "#839AA8", "#635666"]]; 

// Light and dark colors
const blue = ["#2E9AD9", "#2274A5"];
const orange = ["#F78239", "#DE671D"];
const green = ["#67B853", "#569945"];
const purple = ["#9E4784", "#7B1E7A"];
const red = ["#DB2727", "#B31717"];
const yellow = ["#FAC13C", "#D4A12A"];
const brown = ["#A86242", "#7D451B"];
const pink = ["#DB5A67", "#B83946"];

// const safeTwinColors = [blue, orange, green, purple, red, yellow, brown, pink];
// const safeTwinColors = [orange, red, yellow, brown, pink, purple, blue, green];
const safeTwinColors = [blue, red, orange, green, brown, pink, purple, yellow];

export interface Sample {
  entityId: string | null;
  avg: number | null;
  max: number | null;
  min: number | null;
  capacity: number | null;
  count: number | null;
  sum: number | null;
  total: number | null;
  value: number | null;
  unit: string;
  datetime: string;
  weekday: number | null;
  hourOfDay: string | null;
  quarterOfDay: string | null;
  stack: string | null;
}

interface xAxis {
  min: Date;
  max: Date;
}

interface yAxis {
  min: number;
  max: number;
  zoomMax: number;
  zoomMin: number;
  unit: string;
}

interface GraphProps {

  // Type of sample
  type: string;
  graphType: GraphType;
  source: string;
  dataType: string;
  samples: Sample[];
  valueKeys: string[];
  uniqueEntityIds: (string | null)[];
  entities?: { [key: string]: string };

  // x and y axis options
  options: any;

  // Time period (x-axis)
  x: xAxis;
  timePeriod?: TimePeriod;
  stepSize?: StepSize;
  ticks: moment.Moment[];

  // Value range (y-axis)
  y: yAxis;
  fixedScale: boolean;
  capacity?: number;
  stacked?: boolean;
  toggleFixedScale?: () => void;

  // Graph type
  toggleGraphType?: () => void;

  // Container scaling
  aspectRatio?: number;

  // Expand/minimize
  enableExpand: boolean;
  expanded: boolean;
  onExpandedChanged: (expanded: boolean) => void;

  // Language
  language: string;

  // Status indicator
  isLoading: boolean;
  statusMessage: string;

  // Time period selection
  enableSelection: boolean;
  selectionStartDate?: Date;
  selectionEndDate?: Date;
  onSelectionChanged: (startDate: Date, endDate: Date) => void;

  // Disable mouse events on dragging slider
  onDraggingStart: () => void;
  onDraggingEnd: () => void;
}

interface GraphState {
  weekSliderValues: number[];
  monthSliderValues: number[];
  hiddenEntities: string[];
  prevUniqueEntityIds: string[];
}

const defaultChartSize = 5;

export class Graph extends React.Component<GraphProps, GraphState> {

  private chartRef: React.RefObject<ChartJS>;
  private chartContainerRef: React.RefObject<HTMLDivElement>;

  constructor(props: GraphProps) {
    super(props);
    this.state = {
      weekSliderValues: [0, defaultChartSize],
      monthSliderValues: [0, defaultChartSize],
      hiddenEntities: [],
      prevUniqueEntityIds: []
    };
    this.chartRef = React.createRef();
    this.chartContainerRef = React.createRef();
    this.getImage = this.getImage.bind(this);
    this.getCSV = this.getCSV.bind(this);
    this.createCSV = this.createCSV.bind(this);
    this.onWeekTimeSliderChanged = this.onWeekTimeSliderChanged.bind(this);
    this.onMonthTimeSliderChanged = this.onMonthTimeSliderChanged.bind(this);
    this.onExpandedClicked = this.onExpandedClicked.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.updateHiddenEntities = this.updateHiddenEntities.bind(this);
    this.tooltipMouseMoveHandler = this.tooltipMouseMoveHandler.bind(this);
  }

  static getDerivedStateFromProps(nextProps: GraphProps, prevState: GraphState) {
    if (JSON.stringify(nextProps.uniqueEntityIds) !== JSON.stringify(prevState.prevUniqueEntityIds)) {
      return {
        hiddenEntities: [],
        prevUniqueEntityIds: nextProps.uniqueEntityIds
      };
    }
    return null;
  }

  componentWillUnmount() {
    if (this.chartRef && this.chartRef.current) {
      const canvas = this.chartRef.current.canvas;
      canvas.removeEventListener("mousemove", this.tooltipMouseMoveHandler);
    }
  }

  shouldComponentUpdate(nextProps: GraphProps, nextState: any) {
    // console.log("Graph.shouldComponentUpdate.props", this.props, nextProps);

    let selectionStartDateChanged;
    if (nextProps.selectionStartDate) {
      if (this.props.selectionStartDate) {
        selectionStartDateChanged = this.props.selectionStartDate.getTime() !== nextProps.selectionStartDate.getTime();
      }
      else {
        selectionStartDateChanged = true;
      }
    }
    else if (this.props.selectionStartDate) {
      selectionStartDateChanged = true;
    }

    let selectionEndDateChanged;
    if (nextProps.selectionEndDate) {
      if (this.props.selectionEndDate) {
        selectionEndDateChanged = this.props.selectionEndDate.getTime() !== nextProps.selectionEndDate.getTime();
      }
      else {
        selectionEndDateChanged = true;
      }
    }
    else if (this.props.selectionEndDate) {
      selectionEndDateChanged = true;
    }

    let shouldUpdate = (
      JSON.stringify(this.props.samples) !== JSON.stringify(nextProps.samples) ||
      this.props.type !== nextProps.type ||
      this.props.valueKeys !== nextProps.valueKeys ||
      this.props.timePeriod !== nextProps.timePeriod ||
      this.props.stepSize !== nextProps.stepSize ||
      this.props.x.min !== nextProps.x.min ||
      this.props.x.max !== nextProps.x.max ||
      selectionStartDateChanged ||
      selectionEndDateChanged ||
      this.props.fixedScale !== nextProps.fixedScale ||
      this.props.graphType !== nextProps.graphType ||
      this.props.capacity !== nextProps.capacity ||
      this.props.expanded !== nextProps.expanded ||
      this.props.isLoading !== nextProps.isLoading ||
      this.props.statusMessage !== nextProps.statusMessage ||
      this.state.weekSliderValues !== nextState.weekSliderValues ||
      this.state.monthSliderValues !== nextState.monthSliderValues ||
      JSON.stringify(this.state.hiddenEntities) !== JSON.stringify(nextState.hiddenEntities)
    );

    return shouldUpdate;
  }

  tooltipMouseMoveHandler(event: any) {
    // console.log("tooltipMouseMoveHandler", event, this.chartRef);
    if (this.chartRef && this.chartRef.current) {
      const chart = this.chartRef.current;

      const bars = chart.data.datasets.flatMap((_, datasetIndex) => {
        return chart.getDatasetMeta(datasetIndex).data;
      });

      const rect = chart.canvas.getBoundingClientRect();
      const canvasX = event.clientX - rect.left;
      const canvasY = event.clientY - rect.top;

      // Check if the event happened on a bar
      let isOverBar = false;
      for (const bar of bars) {
        // console.log("bar", bar);

        // @ts-ignore
        const barWidth = bar.width;
        // @ts-ignore
        const barHeight = bar.height;
        // @ts-ignore
        const barBase = bar.base;

        const barRect = {
          left: bar.x - barWidth / 2,
          top: barBase - barHeight,
          right: bar.x + barWidth / 2,
          bottom: barBase,
        };
  
        // Check if the mouse is on the bar or above it
        if (
          canvasX >= barRect.left &&
          canvasX <= barRect.right &&
          // canvasY >= barRect.top && (ignore the top of the bar)
          canvasY <= barRect.bottom
        ) {
          isOverBar = true;
          break;
        }
      }
  
      if (chart.options.plugins && chart.options.plugins.tooltip && chart.options.plugins.tooltip.enabled !== isOverBar) {
        chart.options.plugins.tooltip.enabled = isOverBar;
        chart.update("none");
      }
    }
  }

  getImage() {
    const chart = this.chartRef.current;
    const chartContainer = this.chartContainerRef.current;
    if (chart && chartContainer) {

      // Create a link to download the image
      var a = document.createElement("a");

      if (chart.height < 300) {
        // Scale the chart to a larger size before taking a screenshot
        chartContainer.style.width = "1040px";
        chartContainer.style.height = "400px";

        // Trigger the download with a click after 0.1 second delay
        setTimeout(() => {
          a.href = chart.toBase64Image("image/png", 1);
          a.download = getTitle(this.props.type) + ".png";
          a.click();

          // Restore the chart to its original size
          chartContainer.style.width = "";
          chartContainer.style.height = "";
        }, 200);
      }
      else {
        a.href = chart.toBase64Image("image/png", 1);
        a.download = getTitle(this.props.type) + ".png";
        a.click();
      }
    }
  }

  getCSV() {
    const csv = this.createCSV();
    const csvData = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    const csvURL = window.URL.createObjectURL(csvData);
    const a = document.createElement("a");
    a.href = csvURL;
    a.download = getTitle(this.props.type) + ".csv";
    a.click();
  }

  createCSV() {
    const data = this.props.samples;
    const keys = this.props.valueKeys;
    const header = ["date", "entityId", ...keys];
    const rows = data.map((sample) => {
      const row = [sample.datetime || sample.weekday || sample.hourOfDay];
      row.push(sample.entityId);
      for (const key of keys) {
        // @ts-ignore
        row.push(sample[key]);
      }
      return row;
    });
    return [header, ...rows].join("\n");
  }

  onExpandedClicked() {
    if (!this.props.expanded) {
      this.props.onExpandedChanged(true);
    }
    else {
      this.props.onExpandedChanged(false);
    }
  }

  onWeekTimeSliderChanged(values: [number, number]) {
    // console.log("onTimeSliderChanged.values", values);
    const indexThatChanged = this.state.weekSliderValues[0] !== values[0] ? 0 : 1;
    const maxValueInWeeks = this.props.ticks.length + 1;

    if (indexThatChanged === 0) {
      const newSecondValue = values[0] + defaultChartSize;
      if (newSecondValue > maxValueInWeeks) {
        // do not allow moving start date to the right
        return;
      }
      else {
        this.setState({ weekSliderValues: [values[0], newSecondValue] });
      }
    }
    else {
      const newFirstValue = values[1] - defaultChartSize;
      if (newFirstValue < 0) {
        // do not allow moving end date to the left
        return;
      }
      else {
        this.setState({ weekSliderValues: [newFirstValue, values[1]] });
      }
    }
  }

  onMonthTimeSliderChanged(values: [number, number]) {
    // console.log("onTimeSliderChanged.values", values);
    const indexThatChanged = this.state.monthSliderValues[0] !== values[0] ? 0 : 1;
    const maxValueInMonths = this.props.ticks.length + 1;

    if (indexThatChanged === 0) {
      const newSecondValue = values[0] + defaultChartSize;
      if (newSecondValue > maxValueInMonths) {
        // do not allow moving start date to the right
        return;
      }
      else {
        this.setState({ monthSliderValues: [values[0], newSecondValue] });
      }
    }
    else {
      const newFirstValue = values[1] - defaultChartSize;
      if (newFirstValue < 0) {
        // do not allow moving end date to the left
        return;
      } 
      else {
        this.setState({ monthSliderValues: [newFirstValue, values[1]] });
      }
    }
  }

  handleClick(event: any) {
    if (this.chartRef.current && this.props.enableSelection && this.props.onSelectionChanged) {
      const element = getElementAtEvent(this.chartRef.current, event);

      if (element && element.length > 0) {
        const index = element[0].index;

        // We need to look directly at the data labels to get the correct date
        // because the sample index is not the same as the data index (not including null values)
        const data = this.chartRef.current.data;
        if (data && data.labels && data.labels.length > index) {

           // Get the start and end date of the selected sample
          if (this.props.stepSize === StepSize.Day) {
            const sampleDate = (data.labels[index] as moment.Moment).clone();
            const startDate = sampleDate;
            const endDate = sampleDate.clone().endOf("day");
            this.props.onSelectionChanged(startDate.toDate(), endDate.toDate());
          }
          else if (this.props.stepSize === StepSize.Hour) {
            const sampleDate = (data.labels[index] as moment.Moment).clone();
            const startDate = sampleDate;
            const endDate = sampleDate.clone().endOf("hour");
            this.props.onSelectionChanged(startDate.toDate(), endDate.toDate());
          }
          else if (this.props.stepSize === undefined) {
            const sampleDate = moment(data.labels[index] as string);

            // Check the samples before and after the selected sample to find the start and end date 
            let startDate = sampleDate;
            let endDate = sampleDate;
            if (index > 0 && index < data.labels.length - 1) {
              const previousSampleDate = moment(data.labels[index - 1] as string);
              const prevDifference = sampleDate.diff(previousSampleDate, "seconds");
              const nextSampleDate = moment(data.labels[index + 1] as string);
              const nextDifference = nextSampleDate.diff(sampleDate, "seconds");

              // Add seconds to the start date and subtract seconds from the end date to make sure the selection is visible
              // and try to use a standard sized margin
              if (prevDifference > 600 && nextDifference > 600) {
                startDate = sampleDate.clone().subtract(600, "seconds");
                endDate = sampleDate.clone().add(600, "seconds");
              }
              else if (prevDifference > 60 && nextDifference > 60) {
                startDate = sampleDate.clone().subtract(60, "seconds");
                endDate = sampleDate.clone().add(60, "seconds");
              }
              else if (prevDifference > 30 && nextDifference > 30) {
                startDate = sampleDate.clone().subtract(30, "seconds");
                endDate = sampleDate.clone().add(30, "seconds");
              }
              else if (prevDifference > 10 && nextDifference > 10) {
                startDate = sampleDate.clone().subtract(10, "seconds");
                endDate = sampleDate.clone().add(10, "seconds");
              }
              else if (prevDifference > 1 && nextDifference > 1) {
                startDate = sampleDate.clone().subtract(1, "seconds");
                endDate = sampleDate.clone().add(1, "seconds");
              }
            }
            else if (index > 0) {
              const previousSampleDate = moment(data.labels[index - 1] as string);
              const difference = sampleDate.diff(previousSampleDate, "seconds");
              
              // Add seconds to the start date to make sure the selection is visible
              // and try to use a standard sized margin
              if (difference > 600) {
                startDate = sampleDate.clone().subtract(600, "seconds");
              }
              else if (difference > 60) {
                startDate = sampleDate.clone().subtract(60, "seconds");
              }
              else if (difference > 30) {
                startDate = sampleDate.clone().subtract(30, "seconds");
              }
              else if (difference > 10) {
                startDate = sampleDate.clone().subtract(10, "seconds");
              }
              else if (difference > 1) {
                startDate = sampleDate.clone().subtract(1, "seconds");
              }
            }
            else if (index < this.props.samples.length - 1) {
              const nextSampleDate = moment(data.labels[index + 1] as string);
              const difference = nextSampleDate.diff(sampleDate, "seconds");

              // Subtract seconds from the end date to make sure the selection is visible
              // and try to use a standard sized margin
              if (difference > 600) {
                endDate = sampleDate.clone().add(600, "seconds");
              }
              else if (difference > 60) {
                endDate = sampleDate.clone().add(60, "seconds");
              }
              else if (difference > 30) {
                endDate = sampleDate.clone().add(30, "seconds");
              }
              else if (difference > 10) {
                endDate = sampleDate.clone().add(10, "seconds");
              }
              else if (difference > 1) {
                endDate = sampleDate.clone().add(1, "seconds");
              }
            }

            this.props.onSelectionChanged(startDate.toDate(), endDate.toDate());
          }
        }
      }
    }
  }

  getStandardOptions(props: GraphProps, state: GraphState) {
    let options: any = {
      aspectRatio: this.props.aspectRatio || 2,
      responsive: true,
      maintainAspectRatio: !(props.source === "report"), // Report graphs should not maintain aspect ratio
      clip: false,
      spanGaps: true,
      layout: {
        padding: {
          top: 15,
          bottom: (props.source === "report") ? 0 : 25,
          left: 20,
          right: (props.source === "report") ? 40 : 15
        }
      },
      animation: {
        duration: 0
      },
      plugins: {
        legend: {
          display: !(props.source === "report"),
          position: "right",
          labels: {
            boxWidth: 16
          },
        },
        reportLegend: (props.source === "report"),
        title: {
          font: { 
            family: "'Helvetica Neue', Arial, Helvetica, sans-serif",
            size: 14,
            weight: "normal",
            lineHeight: 0.5
          },
          padding: {
            top: (props.source === "report") ? 10 : 0,
            bottom: (props.source === "report") ? 20 : 15,
          }
        },
        "time-selection": {
          zoom: {
            startTimestamp: props.selectionStartDate ? props.selectionStartDate.getTime() : null,
            endTimestamp: props.selectionEndDate ? props.selectionEndDate.getTime() : null,
            drag: {
              enabled: props.enableSelection
            },
            onSelectionComplete: (selection: any) => {
              const startDate = selection.startDate;
              const endDate = selection.endDate;
              props.onSelectionChanged(startDate, endDate);
            }
          }
        },
        datalabels: {
          display: false
        }
      },
      devicePixelRatio: 2
    };

    const title = getTitle(props.type);
    if (!isEmpty(title)) {
      // @ts-ignore
      options.plugins.title.display = true;
      // @ts-ignore
      options.plugins.title.text = title;
    }

    // if props.stacked
    if (props.source === "report") {
      
      // Only show the new legend in the report graphs
      options.plugins = {
        ...options.plugins,
        reportLegend: true
      };

      // Only override the interaction in the report bar graphs
      if (props.graphType === "bar") {
        options.interaction = {
          mode: "x" as const,
          intersect: false
        };
      }

      // @ts-ignore
      options.maxBarThickness = 50;
      
      options.scales = {
        ...get(options, "scales", undefined),
        x: {
          ...get(options, "scales.x", undefined),
          stacked: true
        },
        y: {
          ...get(options, "scales.y", undefined),
          stacked: false,
          beginAtZero: props.fixedScale || props.graphType === "bar" ? true : false
        }
      };

      // Add fixed scale if defined
      if (props.fixedScale) {
        if (props.y.min !== undefined) {
          options.scales.y = {
            ...get(options, "scales.y", undefined),
            min: props.y.min
          };
        }
        if (props.y.max !== undefined) {
          options.scales.y = {
            ...get(options, "scales.y", undefined),
            max: props.y.max
          };
        }
      }

      if (props.graphType === "bar") {
        options.plugins.tooltip = {
          enabled: false,
          position: "nearest",
          interaction: {
            mode: "index",
          },
          intersect: true,
          multiKeyBackground: "transparent",
          boxWidth: 15,
          boxHeight: 15,
          itemSort: (a: any, b: any) => {
            // Sort by dataset.label (entity name) so the order becomes peakPeak, avgPeak, avgAvg
            return b.dataset.label.localeCompare(a.dataset.label);
          },
          callbacks: {
            title: (context: any) => {
              if (context.length === 0) {
                return "";
              }

              const stack = context[0].dataset.entityName;
              return stack;
            },
            label: (context: any) => {
              if (context.length === 0) {
                return "";
              }

              // console.log("context", context);

              let label = "";
              if (context.dataset.label === "avgAvg") {
                label = strings.avg + ": " + Math.round(context.parsed.y) + context.dataset.unit;
              }
              else if (context.dataset.label === "avgPeak") {
                // If peakPeak is present then we know that avgPeak is the average of the values
                if (props.valueKeys.includes("peakPeak")) {
                  label = strings.avg + ": " + Math.round(context.parsed.y) + context.dataset.unit;
                }
                else {
                  label = strings.peak + ": " + Math.round(context.parsed.y) + context.dataset.unit;
                }
              }
              else if (context.dataset.label === "peakPeak") {
                label = strings.peak + ": " + Math.round(context.parsed.y) + context.dataset.unit;
              }
              else if (context.dataset.label === "capacity") {
                label = strings.capacity + ": " + Math.round(context.parsed.y) + context.dataset.unit;
              }

              return label;
            },
            labelColor: (context: any) => {
              if (context.length === 0) {
                return {};
              }

              return {
                borderColor: "transparent", // context.dataset.backgroundColor,
                backgroundColor: context.dataset.backgroundColor,
                borderWidth: 0,
              }
            },
          },
        };

        // Add custom tooltip handler for the report graphs
        options.onResize = (chart: any) => {
          // console.log("onResize", chart);
          if (chart) {
            const canvas = chart.canvas;
            canvas.removeEventListener("mousemove", this.tooltipMouseMoveHandler);
            canvas.addEventListener("mousemove", this.tooltipMouseMoveHandler);
          }
        };
      }
      else {
        options.plugins.tooltip = {
          enabled: true,
          position: "nearest",
          multiKeyBackground: "transparent",
          boxWidth: 15,
          boxHeight: 15,
          callbacks: {
            title: (context: any) => {
              // console.log("context", context);
              if (context.length === 0) {
                return "";
              }

              const stack = context[0].dataset.entityName;
              return stack;
            },
            label: (context: any) => {
              if (context.length === 0) {
                return "";
              }

              // console.log("context.parsed", context.parsed);
              // console.log("context", context);

              let label = "";
              if (context.dataset.label === "avgAvg") {
                label = strings.avg + ": " + Math.round(context.parsed.y) + context.dataset.unit;
              }
              else if (context.dataset.label === "avgPeak") {
                // If peakPeak is present then we know that avgPeak is the average of the values
                if (props.valueKeys.includes("peakPeak")) {
                  label = strings.avg + ": " + Math.round(context.parsed.y) + context.dataset.unit;
                }
                else {
                  label = strings.peak + ": " + Math.round(context.parsed.y) + context.dataset.unit;
                }
              }
              else if (context.dataset.label === "peakPeak") {
                label = strings.peak + ": " + Math.round(context.parsed.y) + context.dataset.unit;
              }
              else if (context.dataset.label === "capacity") {
                label = strings.capacity + ": " + Math.round(context.parsed.y) + context.dataset.unit;
              }

              return label;
            },
            labelColor: (context: any) => {
              if (context.length === 0) {
                return {};
              }

              return {
                borderColor: "transparent", // context.dataset.backgroundColor,
                backgroundColor: context.dataset.backgroundColor,
                borderWidth: 0,
              }
            },
          },
        };
      }
    }
    else {

      // Set tooltip title based on time period
      if (props.timePeriod === TimePeriod.Day || props.timePeriod === TimePeriod.QuarterOfDay || props.timePeriod === TimePeriod.HourOfDay) {
        if (props.stepSize === StepSize.Quarter) {
           // @ts-ignore
           options.plugins.tooltip = {
            enabled: true,
            callbacks: {
              title: function(context: any) {
                const date = moment(parseInt(context[0].parsed.x));
                return date.format("HH:mm") + "-" + date.clone().add(15, "minutes").format("HH:mm");
              }
            }
          }
        }
        else {
           // @ts-ignore
           options.plugins.tooltip = {
            enabled: true,
            callbacks: {
              title: function(context: any) {
                const date = moment(parseInt(context[0].parsed.x));
                return date.format("HH:mm")+ "-" + date.clone().add(1, "hour").format("HH:mm");
              }
            }
          }
        }
      }
    }

    return options;
  }

  configureTimeScale(options: any, props: GraphProps) {
    // console.log("configureTimeScale", props.timePeriod, props.stepSize);
    switch (props.timePeriod) {
      case TimePeriod.Day:
      case TimePeriod.QuarterOfDay:
      case TimePeriod.HourOfDay:

        // Fix scale
        // @ts-ignore
        options.scales.x = {
          type: "time",
          time: {
            unit: "minute",
            displayFormats: {
              minute: "x",
            }
          }
        };

        if (props.stepSize === StepSize.Quarter) {

          options.spanGaps = 1000 * 60 * 15; // 15 min

          // @ts-ignore
          options.scales.x.ticks = {
            maxRotation: 45,
            minRotation: 45,
            stepSize: 60,
            callback: function(val: any, index: any) {
              // @ts-ignore
              const date = moment(parseInt(val));
              return date.format("HH:mm") + "-" + date.clone().add(15, "minutes").format("HH:mm");
            },
          }
        }
        else {
          options.spanGaps = 1000 * 60 * 60; // 1 hour

          // @ts-ignore
          options.scales.x.ticks = {
            maxRotation: 45,
            minRotation: 45,
            stepSize: 60,
            callback: function(val: any, index: any) {
              // @ts-ignore
              const date = moment(parseInt(val));
              return date.format("HH")+ "-" + date.clone().add(1, "hour").format("HH");
            },
          }
        }

        break;

      case TimePeriod.Week:

        options.spanGaps = 1000 * 60 * 60 * 25; // 1 day and 1 hour

        // Fix scale
        options.scales.x = {
          type: "time",
          time: {
            unit: "day",
            tooltipFormat: "D. MMM yyyy", 
            displayFormats: {
              day: "dddd"
            }
          }
        };

        break;

      case TimePeriod.Month:

        options.spanGaps = 1000 * 60 * 60 * 25; // 1 day and 1 hour

        // Fix scale
        options.scales.x = {
          type: "time",
          time: {
            unit: "day",
            tooltipFormat: "D. MMM yyyy", 
            displayFormats: {
              day: "D. MMM"
            }
          },
          ticks: {
            stepSize: 1
          }
        };

        break;

      case TimePeriod.WeekDays:

        options.spanGaps = 1000 * 60 * 60 * 25; // 1 day and 1 hour

        // Fix scale
        options.scales.x = {
          // type: "bar",
          time: {
            unit: "day",
            tooltipFormat: "dddd",
            displayFormats: {
              day: "dddd"
            }
          }
        };

        break;

      case TimePeriod.Dynamic:
        // Fix scale
        // @ts-ignore
        options.spanGaps = true;
        options.scales.x = {
          type: "time",
          time: {
            unit: "minute",
            round: "minute",
            tooltipFormat: "HH:mm", 
            displayFormats: {
              minute: "HH:mm",
              hour: "HH:mm", 
            }
          },
          min: props.x.min.valueOf(),
          max: props.x.max.valueOf(),
        };
        break;

      default:
        break;
    }

    if (this.props.stepSize === StepSize.Week) {

      // Check if props.x.min and props.x.max are in the same year
      const inOneYear = moment(props.x.min).year() === moment(props.x.max).year();

      // Fix scale
      options.scales.x = {
        type: "category",
        // time: {
        //   unit: "week",
        //   tooltipFormat: "DD.MM.YY",
        //   displayFormats: {
        //     week: `W`
        //   }
        // },
        ticks: {
          maxRotation: 45
        }
      };
    }
    else if (this.props.stepSize === StepSize.Month) {

      // Check if props.x.min and props.x.max are in the same year
      const inOneYear = moment(props.x.min).year() === moment(props.x.max).year();

      // Fix scale
      options.scales.x = {
        type: "category",
        // time: {
        //   unit: "month",
        //   tooltipFormat: inOneYear ? "MMMM" : "MMMM YYYY",
        //   displayFormats: {
        //     month: inOneYear ? "MMMM" : "MMMM YYYY"
        //   },
        //   // parser: function(date: any) {
        //   //   // @ts-ignore
        //   //   console.log("date", date.format("MMMM"));
        //   //   return date.format("MMMM");
        //   // },
        // },
        ticks: {
          maxRotation: 45,
        }
      };
    }
  }

  configureValueScale(options: any, props: GraphProps) {
    if (props.y.unit === "%") {
      options.scales.y = {
        ...options.scales.y,
        ticks: {
          callback: function(value: any, index: any, values: any) {
            return value + "%";
          }
        }
      };
    }
  }

  configureGridColor(options: any) {
    options.scales = {
      ...get(options, "scales", undefined),
      x: {
        ...get(options, "scales.x", undefined),
        grid: {
          color: "#e0e0e0"
        }
      },
      y: {
        ...get(options, "scales.y", undefined),
        grid: {
          color: "#e0e0e0"
        }
      }
    };
  }

  updateHiddenEntities(hiddenEntities: string[]) {
    this.setState({ hiddenEntities });
  }

  render() {
    // console.log("Graph.render.state", this.state);
    // console.log("Graph.render.props", this.props);
    const { type, samples, enableExpand, expanded, uniqueEntityIds, isLoading, statusMessage } = this.props;

    // Update language
    moment.locale(this.props.language || "en");
    strings.setLanguage(this.props.language || "en");

    // Clone ticks to prevent modifying original
    let ticks: moment.Moment[] = this.props.ticks.map((tick: moment.Moment) => tick.clone());
    
    // Add options
    let options = {
      scales: {
        x: {},
        y: {}
      },
      ...this.props.options,
      ...this.getStandardOptions(this.props, this.state)
    }

    this.configureTimeScale(options, this.props);
    this.configureValueScale(options, this.props);

    // console.log("options", options);

    // Set grid color
    this.configureGridColor(options);

    const datasets: any[] = [];

    // Loop through entityIds
    uniqueEntityIds.forEach((entityId, index) => {

      // Only supporting 20 colors
      // if (index > 19) {
      //   return;
      // }

      // Create datasets
      var filteredData: any = {};
      this.props.valueKeys.forEach(key => {
        filteredData[key] = [];
      });

      // Get samples for entityId
      const samplesOfOneDataset = samples.filter(sample => sample.entityId === entityId);
      const datasetName = samplesOfOneDataset.length > 0 ? get(samplesOfOneDataset, "[0].name", "Unknown") : null;

      // Add datasets for timeIncrements
      if (this.props.timePeriod === undefined || this.props.timePeriod === TimePeriod.Dynamic) {
        if (this.props.stepSize === StepSize.Quarter || this.props.stepSize === StepSize.Minute) {
          ticks.forEach(date => {
            // Get value for this minute
            const sample: any = samplesOfOneDataset.find(sample => { 
              return moment(sample.datetime).format("HH:mm") === date.format("HH:mm");
            });

            this.props.valueKeys.forEach(key => {
              filteredData[key].push(sample ? sample[key] : null);
            });
          });
        }
        else if (this.props.stepSize === StepSize.Week) {
          // Add to dataset
          ticks.forEach(week => {
            // Get value for this day
            const sample: any = samplesOfOneDataset.find(sample => { 
              const date = moment(sample.datetime);
              return date.year() === week.year() && date.isoWeek() === week.isoWeek();
            });
  
            this.props.valueKeys.forEach(key => {
              filteredData[key].push(sample ? sample[key] : 0);
            });
          });
        }
        else if (this.props.stepSize === StepSize.Month) {
          // Add to dataset
          ticks.forEach(month => {
            // Get value for this day
            const sample: any = samplesOfOneDataset.find(sample => { 
              const date = moment(sample.datetime);
              return date.year() === month.year() && date.month() === month.month();
            });
  
            this.props.valueKeys.forEach(key => {
              filteredData[key].push(sample ? sample[key] : null);
            });
          });
        }
        else {
          samplesOfOneDataset.forEach((sample: any) => {
            this.props.valueKeys.forEach(key => {
              filteredData[key].push(sample ? sample[key] : null);
              ticks.push(moment(sample.datetime));
            });
          });
        }
      }
      else if ([TimePeriod.Week, TimePeriod.Month].includes(this.props.timePeriod)) {
        // Add to dataset
        ticks.forEach(day => {
          // Get value for this day
          const sample: any = samplesOfOneDataset.find(sample => { 
            return moment(sample.datetime).format("YYYY-MM-DD") === day.format("YYYY-MM-DD");
          });

          this.props.valueKeys.forEach(key => {
            filteredData[key].push(sample ? sample[key] : null);
          });
        });
      }
      else if ([TimePeriod.Day, TimePeriod.HourOfDay, TimePeriod.QuarterOfDay].includes(this.props.timePeriod)) {
        // Add to dataset
        ticks.forEach(date => {
          // Get value for this hour
          const sample: any = samplesOfOneDataset.find(sample => { 
            if (this.props.timePeriod === TimePeriod.Day) {
              return moment(sample.datetime).format("HH:mm") === date.format("HH:mm");
            }
            else if (this.props.timePeriod === TimePeriod.HourOfDay) {
              return sample.hourOfDay === date.format("HH:mm:ss");
            }
            else if (this.props.timePeriod === TimePeriod.QuarterOfDay) {
              return sample.quarterOfDay === date.format("HH:mm:ss");
            }
          });

          this.props.valueKeys.forEach(key => {
            filteredData[key].push(sample ? sample[key] : null);
          });
        });
      }
      else if (this.props.timePeriod === TimePeriod.WeekDays) {
        // Add to dataset
        ticks.forEach(day => {
          // Get value for this day
          const sample: any = samplesOfOneDataset.find(sample => {
            return sample.weekday === day.isoWeekday();
          });

          this.props.valueKeys.forEach(key => {
            filteredData[key].push(sample ? sample[key] : null);
          });
        });
      }

      if (this.props.source === "report") {
        let options: any = {
          // spanGap: timePeriod === TimePeriod.Dynamic ? false : true,
          // categoryPercentage: 0.95,
        };

        // Line graph options
        if (this.props.graphType === GraphType.Line) {
          // options.fill = false;
          options.tension = 0.3;

          if (this.props.dataType === "peopleCount") {
            // options.fill = true;
          }
        }

        // Get a random color
        const colors = safeTwinColors[index%11];

        const topColors = {
          backgroundColor: colors[0],
          borderColor: colors[0],
          pointBackgroundColor: colors[0],
          pointBorderColor: colors[0],
        };

        const bottomColors = {
          backgroundColor: colors[1],
          borderColor: colors[1],
          pointBackgroundColor: colors[1],
          pointBorderColor: colors[1],
        };

        const capacityColors: any = {
          backgroundColor: "#000",
          borderColor: "#000",
          pointBackgroundColor: "#000",
          pointBorderColor: "#000",
        };

        // console.log("filteredData", filteredData);

        this.props.valueKeys.forEach(key => {
          if (filteredData[key].length > 0) {

            // Use the same two colors for all datasets (average and peak)
            let useColors: any = bottomColors;
            if (filteredData.peakPeak !== undefined) {
              if (key === "peakPeak") {
                useColors = topColors;
              }
              else {
                useColors = bottomColors;
              }
            }
            else if (key === "avgPeak") {
              useColors = topColors;
            }

            // Use black for capacity
            if (key === "capacity") {
              useColors = capacityColors;
            }

            // Use the same type for all datasets (except capacity)
            let type = this.props.graphType === GraphType.Line ? "line" : "bar";
            if (key === "capacity") {
              type = "line";
            }

            const data = { type: type, label: key, unit: get(this.props, "y.unit", ""), data: filteredData[key], ...useColors, ...options };

            if (entityId && this.props.stacked) {
              data.stack = entityId;
            }
            
            if (entityId) {
              data.entityId = entityId;
              data.entityName = get(this.props.entities, entityId, strings.unknown);
              data.hidden = this.state.hiddenEntities.includes(entityId);
            }
            else {
              data.hidden = false;
            }

            datasets.push(data);
          }
        });
      }
      else if (uniqueEntityIds.length > 1) {
        let data: any[] = []
        let colors = {
          borderColor: uniqueColors[index],
          backgroundColor: uniqueColors[index]
        };

        let dataset = { label: datasetName, data, ...colors };

        // Only use one type of value
        const firstKey = get(this.props.valueKeys, "[0]", null);
        if (firstKey) {
          dataset.data = filteredData[firstKey];
        }

        datasets.push(dataset);
      }
      else {
        let options: any = {
          // spanGap: timePeriod === TimePeriod.Dynamic ? false : true,
         
        };

        this.props.valueKeys.forEach(key => {
          if (filteredData[key].length > 0) {

            let useColors: any = maxColors;
            if (key === "avg") {
              useColors = avgColors;
            }
            else if (key === "min") {
              useColors = minColors;
            }
            else if (key === "capacity") {
              useColors = capacityColors;
            }

            /// TODO: Add order?
            datasets.push({ label: key, data: filteredData[key], ...useColors, ...options });
          }
        });

        // if (filteredMax.length > 0) {
        //   datasets.push({ label: "max", order: 3, data: filteredMax, ...maxColors, ...options });
        // }
      }
    });

    // Add bar stack labels
    if (this.props.source === "report") {// && datasets.length > 0 && datasets[0].data && datasets[0].data.length < 7) {
      if (this.props.graphType === GraphType.Bar) {
        // @ts-ignore
        options.plugins.datalabels = {
          color: (context: any) => {
            const isMax = context.dataset.label === "peakPeak";
            const isAvg = context.dataset.label === "avgPeak";
            return isMax ? "#fff" : (isAvg ? "#eee" : "#ddd");
          },
          display: (context: any) => {
            // console.log("context", context);

            var index = context.dataIndex;
            var value = context.dataset.data[index];

            // Don't show labels for capacity
            if (context.dataset.label === "capacity") {
              return false;
            }

            // Only show labels for bars wider than 30px
            let barWidth = context.chart.$_reportBarWidth;
            if (barWidth < 30) { 
              return false;
            }

            // Get the height of the bar to calculate the weighted value (pixel count), which is the value of the bar in pixels
            const chartMinY = context.chart.scales.y.min;
            const chartMaxY = context.chart.scales.y.max;
            const barHeight = chartMaxY - chartMinY;
            const chartHeight = context.chart.height;
            const barHeightPercentage = chartHeight / barHeight;

            // Only show labels if there is room between the stacked bars
            // If odd number of datasets then check if the previous dataset has a value
            // - this works since we only have stacks of 2, but if we then we need another solution
            if (context.datasetIndex % 2 === 1) {
              // Get value of previous dataset
              const prevValue = context.chart.data.datasets[context.datasetIndex - 1].data[index];

              // Get the bar height in pixels
              const barHeightInPixels = (value - prevValue) * barHeightPercentage;

              // Only show label if space is larger than 28px
              return barHeightInPixels > 28;
            }
            else {
              // Get the bar height in pixels
              const barHeightInPixels = value * barHeightPercentage;

              // Only show label if space is larger than 28px
              return barHeightInPixels > 28;
            }

          },
          font: {
            weight: "bold",
            size: 12
          },
          formatter: (value: any, context: any) => {
            // console.log("value", value);
            // console.log("context", context);
            return Math.round(value) + get(context, "dataset.unit", "");
          },
          align: "start",
          offset: 2,
          anchor: "end"
        };
      }
      else {
        // @ts-ignore
        options.plugins.datalabels = {
          clip: false,
          display: (context: any) => {
            // console.log("line context", context);
            if (context.dataset.label === "capacity") {
              return false;
            }
            
            return "auto";
          },
          backgroundColor: (context: any) => {
            return context.dataset.backgroundColor;
          },
          borderRadius: 4,
          color: "white",
          font: {
            weight: "bold",
            size: 12
          },
          formatter: (value: any, context: any) => {
            return Math.round(value) + get(context, "dataset.unit", "");
          }
        };
      }
    }
    
    // console.log("options", options);
    // console.log("ticks", ticks);
    // console.log("datasets", datasets);
    // console.log("options", options);

    // Temp help text
    const help = getHelpText(type);

    // Create range slider if needed
    let rangeSlider: any = null;
    if (this.props.stepSize === StepSize.Week || this.props.stepSize === StepSize.Month) {

      const minValue = 0;
      const maxValue = ticks.length;

      // Only show max Z weeks with sliderValue as start
      const z = this.props.graphType === GraphType.Bar ? defaultChartSize : 20;
      if (maxValue > z) {
        
        let sliderStart = this.props.stepSize === StepSize.Week ? this.state.weekSliderValues[0] : this.state.monthSliderValues[0];
        let sliderEnd = this.props.stepSize === StepSize.Week ? this.state.weekSliderValues[1] : this.state.monthSliderValues[1];

        const difference = maxValue - sliderEnd;
        if (difference < 0) {
          sliderStart = sliderStart + difference;
          sliderEnd = sliderEnd + difference;
        }

        ticks = ticks.slice(sliderStart, sliderEnd);
        datasets.forEach(dataset => {
          dataset.data = dataset.data.slice(sliderStart,sliderEnd);
        });

        rangeSlider = (
          <RangeSlider
            values={[sliderStart, sliderEnd]}
            min={minValue}
            max={maxValue}
            onChange={this.props.stepSize === StepSize.Week ? this.onWeekTimeSliderChanged : this.onMonthTimeSliderChanged}
            onDragStart={this.props.onDraggingStart}
            onDragEnd={this.props.onDraggingEnd}
          />
        );
      }
    }

    // Create labels from ticks
    let labels: any[] = [];
    if (this.props.timePeriod === TimePeriod.WeekDays) {
      labels = ticks.map((tick: any) => {
        return tick.format("dddd");
      });
    }
    else if (this.props.stepSize === StepSize.Week) {
      const inOneYear = moment(this.props.x.min).isSame(moment(this.props.x.max), "year");
      labels = ticks.map((tick: any) => {
        if (inOneYear) {
          return `${strings.week} ${tick.isoWeek()}`;
        }
        return `${strings.week} ${tick.isoWeek()}, ${tick.format("YYYY")}`;
      });
    }
    else if (this.props.stepSize === StepSize.Month) {
      const inOneYear = moment(this.props.x.min).isSame(moment(this.props.x.max), "year");
      labels = ticks.map((tick: any) => {
        if (inOneYear) {
          return tick.format("MMMM");
        }
        return tick.format("MMMM YYYY");
      });
    }
    else {
      labels = ticks;
    }

    // console.log("labels", labels);

    // Extra plugins
    let plugins = [];
    if (this.props.source === "report") {
      plugins.push(createReportLegendPlugin(this.updateHiddenEntities));
    }
    
    return (
      <Container $hasMinSize={this.props.source === "report"}>
        <GraphContainer 
          $hasMinSize={this.props.source === "report"} 
          $aspectRatio={this.props.aspectRatio ?? 2} 
          ref={this.chartContainerRef}>

          { this.props.graphType === GraphType.Bar ?
            (
              <Bar 
                // @ts-ignore
                options={options} 
                data={{ labels, datasets }} 
                plugins={plugins}
                // @ts-ignore
                ref={this.chartRef}
              />
            ) : (
              <Line 
                // @ts-ignore
                options={options} 
                data={{ labels, datasets }}
                plugins={plugins}
                // @ts-ignore
                ref={this.chartRef}
                onClick={this.handleClick}
              />
            )
          }

          { isLoading && <StatusMessage><Loader size={"70px"} /></StatusMessage> }

          { statusMessage && (
            <StatusMessage>
              <StatusText>{statusMessage}</StatusText>
            </StatusMessage>
            )
          }

       </GraphContainer>

       { !statusMessage && rangeSlider } 

        <Menu>
        { enableExpand && (
          <MenuItem>
            <MenuItemImage role="button" onClick={this.onExpandedClicked} onKeyDown={this.onExpandedClicked}>
              {
                expanded && (
                  <FontAwesomeIcon icon={faArrowDown} size="sm" color="#666" transform={{ rotate: 45 }} />
                )
              }
              { !expanded && (
                  <FontAwesomeIcon icon={faExpandArrowsAlt} size="sm" color="#666" />
                )
              }
            </MenuItemImage>
          </MenuItem>
        )}
          <MenuItem>
            <MenuItemImage role="button" onClick={this.getCSV} onKeyDown={this.getCSV}>
              <FontAwesomeIcon icon={faFileCsv} size="sm" color="#666" />
            </MenuItemImage>
          </MenuItem>
          <MenuItem>
            <MenuItemImage role="button" onClick={this.getImage} onKeyDown={this.getImage}>
              <FontAwesomeIcon icon={faFileImage} size="sm" color="#666" />
            </MenuItemImage>
          </MenuItem>
          { this.props.toggleFixedScale && (
            <MenuItem>
              <MenuItemImage role="button" onClick={this.props.toggleFixedScale} onKeyDown={this.props.toggleFixedScale} >
                <FontAwesomeIcon icon={faSortNumericUp} size="sm" color="#666" />
              </MenuItemImage>
            </MenuItem>
            )
          }
          { this.props.toggleGraphType && (
            <MenuItem>
              <MenuItemImage role="button" onClick={this.props.toggleGraphType} onKeyDown={this.props.toggleGraphType} >
                <FontAwesomeIcon icon={this.props.graphType === GraphType.Bar ? faChartLine : faChartColumn } size="sm" color="#666" />
              </MenuItemImage>
            </MenuItem>
            )
          }
        </Menu>
        { help && (
          <BottomMenu>
            <MenuItem>
              <Info text={help} popupLeft />
            </MenuItem>
          </BottomMenu>
        )
        }
      </Container>
    );
  }
}

const Container = styled.div<{ $hasMinSize: boolean }>`
  display: block;  
  position: relative;
  box-sizing: border-box;
  background-color: #fff;
  border-radius: 6px;
  border-width: 1px;
  border-style: solid;
  border-color: #ddd;
  margin-bottom: 20px;
  min-width: ${props => props.$hasMinSize ? "485px" : "0"};
  overflow: hidden;
`;

const GraphContainer = styled.div<{ $aspectRatio: number, $hasMinSize: boolean }>`
  display: block; 
  position: relative;
  border-radius: 6px;
  overflow: hidden;
  aspect-ratio: ${props => props.$aspectRatio };
  width: 100%;
  min-height: ${props => props.$hasMinSize ? "240px" : "0"};
  min-width: ${props => props.$hasMinSize ? "480px" : "0"};
`;

const Menu = styled.div`
  display: block;
  position: absolute;
  top: 5px;
  right: 2px;
  // background-color: #dff;
  width: 100px;
  height: 25px;
`;

const MenuItem = styled.div`
  display: block;
  float: right;
  cursor: pointer;
  // background-color: #fdf;
  width: 20px;
  height: 20px;
  margin-right: 3px;
`;

const MenuItemImage = styled.div`
  display: block;
  width: 100%;
  height: 100%;
  text-align: center;
`;

const StatusMessage = styled.div`
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
  opacity: 0.8;
  padding: 70px;
  box-sizing: border-box;
  align-items: center;
`;

const StatusText = styled.div`
  display: block;
  text-align: center;
  width: 100%;
  font-size: 16px;
  color: #777;
`;

const BottomMenu = styled.div`
  display: block;
  position: absolute;
  bottom: 0px;
  right: 4px;
  width: 100px;
  height: 25px;
`;

export default Graph;