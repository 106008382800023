import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import HierarchyContainer from "./container";
import NewLocation from "./newLocation";
import EditLocation from "./editLocation";
import NewHonoSensor from "./newHonoSensor";
import NewYanziSensor from "./newYanziSensor";
import NewWebexSensor from "./newWebexSensor";
import NewManualWebexSensor from "./newManualWebexSensor";
import NewGateway from "./newGateway";
import NewMeshNode from "./newMeshNode";
import NotFound from "../NotFound";

const LocationSwitch = () => (
  <Switch>
    <Redirect exact from="/companies/:companyId/locations" to={{ pathname: `/companies/:companyId/locations/root` }} />
    <Route path="/companies/:companyId/locations/:id/new-hono-sensor" component={NewHonoSensor} />
    <Route path="/companies/:companyId/locations/:id/new-yanzi-sensor" component={NewYanziSensor} />
    <Route path="/companies/:companyId/locations/:id/new-gateway" component={NewGateway} />
    <Route path="/companies/:companyId/locations/:id/new-mesh-node" component={NewMeshNode} />
    <Route path="/companies/:companyId/locations/:id/new-webex-sensor" component={NewWebexSensor} />
    <Route path="/companies/:companyId/locations/:id/new-webex-sensor-manual" component={NewManualWebexSensor} />
    <Route path="/companies/:companyId/locations/:id/new-location" component={NewLocation} />
    <Route path="/companies/:companyId/locations/:id/edit-location" component={EditLocation} />
    <Route path="/companies/:companyId/locations/:locationId?" children={(props) => <HierarchyContainer {...props} locationId={props.match.params.locationId} />} />
    <Route component={NotFound} />
  </Switch>
);

export default LocationSwitch;