import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { get, isEmpty } from "lodash";
import moment from "moment";
import { Col, Row, Container } from "react-grid-system";
import { TimePeriod } from "../../components/Dashboard/Graph";
import GraphContainer from "../../components/Dashboard/Graph/container";
import SegmentedControl from "../../components/SegmentedControl";
import { Dropdown } from "../../components/DropdownSelection";
import Datepicker from "../../components/Datepicker";
import CheckboxBlock from "../../components/CheckboxBlock";
import { updateLocationDashboard, queryHash } from "../../dashboardHelpers";
import { getLocation } from "../../locationHelpers";
import { toShortISOString } from "../../helpers";
import * as dashboardActions from "../../actions/dashboards";
import style from "./style.module.scss";

class Dashboard extends Component {

  constructor(props) {
    // console.log("Location.Dashboard.constructor");
    super(props);
    this.state = {
      id: props.locationId,
      timePeriod: this.getInitialPeriod(props),
      date: this.getInitialDay(props),
      showWholeDay: this.getInitialWholeDay(props),
      showWeekends: this.getInitialWeekends(props),
      showHolidays: this.getInitialHolidays(props),
      showQuarterHours: this.getInitialQuarterHours(props),
      expandedType: null,
      hasLoadedState: false,
    };

    // If too early - show yesterday
    if (!this.state.showWholeDay && moment().isSame(this.state.date) && this.state.date.hour() < 9) {
      this.state.date = moment().startOf("day").subtract(1, "day");
    }

    // Set initial time scale
    this.state.timeScale = this.state.timePeriod === "day" ? "hour" : "day";

    this.onTimePeriodChanged = this.onTimePeriodChanged.bind(this);
    this.onDateChanged = this.onDateChanged.bind(this);
    this.onWeekNumberChanged = this.onWeekNumberChanged.bind(this);
    this.onMonthChanged = this.onMonthChanged.bind(this);
    this.onShowWholeDayChanged = this.onShowWholeDayChanged.bind(this);
    this.onShowWeekendsChanged = this.onShowWeekendsChanged.bind(this);
    this.onShowHolidaysChanged = this.onShowHolidaysChanged.bind(this);
    this.onShowQuarterHoursChanged = this.onShowQuarterHoursChanged.bind(this);
    this.onExpandChanged = this.onExpandChanged.bind(this);
  }

  static getDerivedStateFromProps(nextProps, prevState) {

    let newState = {
      ...prevState
    };

    if (prevState.hasLoadedState) {
      return newState;
    }

    // Only copy props if they exist and if location id is correct
    if (!isEmpty(nextProps.location) && prevState.id === nextProps.location.id) {

      newState = {
        hasLoadedState: true
      };

      updateLocationDashboard(prevState, nextProps);

      return newState;
    }

    return null;
  }

  componentDidUpdate(prevProps, prevState) {
    updateLocationDashboard(this.state, this.props);
  }

  getInitialPeriod(props) {
    const queryParams = new URLSearchParams(props.history.location.search);
    return Object.values(TimePeriod).includes(queryParams.get("p")) ? queryParams.get("p") : TimePeriod.Day;
  }

  getInitialDay(props) {
    const queryParams = new URLSearchParams(props.history.location.search);
    const paramDay = moment(queryParams.get("d"));

    const earliestDay = moment().subtract(180, "days").startOf("day");
    if (paramDay.isValid() && paramDay.isAfter(earliestDay)) {
      return paramDay;
    }

    return moment();
  }

  getInitialWholeDay(props) {
    const queryParams = new URLSearchParams(props.history.location.search);
    return queryParams.get("wd") === "true";
  }

  getInitialWeekends(props) {
    const queryParams = new URLSearchParams(props.history.location.search);
    return queryParams.get("w") !== "false";
  }

  getInitialHolidays(props) {
    const queryParams = new URLSearchParams(props.history.location.search);
    return queryParams.get("hd") !== "false";
  }

  getInitialQuarterHours(props) {
    const queryParams = new URLSearchParams(props.history.location.search);
    return queryParams.get("q") === "true";
  }

  onTimePeriodChanged(value) {
    let timeScale = value === "day" ? "hour" : "day";
    this.setState({ timePeriod: value, timeScale });

    const params = new URLSearchParams(this.props.history.location.search);
    params.set("p", value);
    this.props.history.push({ 
      pathname: this.props.history.location.pathname.substr(0, this.props.history.location.pathname.length),
      search: params.toString()
    });
  }

  onDateChanged(date) {
    const newDate = date ? moment(date).startOf("day") : moment().startOf("day");
    this.setState({ date: newDate });

    const params = new URLSearchParams(this.props.history.location.search);
    params.set("d", toShortISOString(date)); // .split('T')[0]); //
    this.props.history.push({ 
      pathname: this.props.history.location.pathname.substr(0, this.props.history.location.pathname.length),
      search: params.toString()
    });
  }

  onWeekNumberChanged(value) {

    let newDate;
    if (value > moment().isoWeek()) {
      newDate = moment().subtract(1, "year").set("isoWeek", value).startOf("isoWeek");
      this.setState({ date: newDate });
    }
    else {
      newDate = moment().set("isoWeek", value).startOf("isoWeek");
      this.setState({ date: newDate });
    }

    const params = new URLSearchParams(this.props.history.location.search);
    params.set("d", toShortISOString(newDate)); // .split('T')[0]); //
    this.props.history.push({ 
      pathname: this.props.history.location.pathname.substr(0, this.props.history.location.pathname.length),
      search: params.toString()
    });
  }

  onMonthChanged(value) {
    
    let newDate;
    if (value > moment().month()) {
      newDate = moment().subtract(1, "year").set("month", value).startOf("month");
      this.setState({ date: newDate });
    }
    else {
      newDate = moment().set("month", value).startOf("month");
      this.setState({ date: newDate });
    }
    const params = new URLSearchParams(this.props.history.location.search);
    params.set("d", toShortISOString(newDate)); // .split('T')[0]); //
    this.props.history.push({ 
      pathname: this.props.history.location.pathname.substr(0, this.props.history.location.pathname.length),
      search: params.toString()
    });
  }

  onShowWholeDayChanged() {
    const params = new URLSearchParams(this.props.history.location.search);
    params.set("wd", !this.state.showWholeDay);
    this.props.history.push({ 
      pathname: this.props.history.location.pathname.substr(0, this.props.history.location.pathname.length),
      search: params.toString()
    });

    this.setState(prevState => ({ showWholeDay: !prevState.showWholeDay }));
  }

  onShowWeekendsChanged() {
    const params = new URLSearchParams(this.props.history.location.search);
    params.set("w", !this.state.showWeekends);
    this.props.history.push({ 
      pathname: this.props.history.location.pathname.substr(0, this.props.history.location.pathname.length),
      search: params.toString()
    });

    this.setState(prevState => ({ showWeekends: !prevState.showWeekends }));
  }

  onShowHolidaysChanged() {
    const params = new URLSearchParams(this.props.history.location.search);
    params.set("hd", !this.state.showHolidays);
    this.props.history.push({ 
      pathname: this.props.history.location.pathname.substr(0, this.props.history.location.pathname.length),
      search: params.toString()
    });

    this.setState(prevState => ({ showHolidays: !prevState.showHolidays }));
  }

  onShowQuarterHoursChanged() {
    const params = new URLSearchParams(this.props.history.location.search);
    params.set("q", !this.state.showQuarterHours);
    this.props.history.push({
      pathname: this.props.history.location.pathname.substr(0, this.props.history.location.pathname.length),
      search: params.toString()
    });
    
    this.setState(prevState => ({ showQuarterHours: !prevState.showQuarterHours }));
  }

  onExpandChanged(type) {
    this.setState({ expandedType: type });
  }

  render() {
    const isLoading = this.props.isLocationLoading;
    // console.log("props", this.props.location);
    // console.log("state", this.state);

    if (isLoading) {
      return null;
    }

    const earliestMoment = moment().subtract(180, "days");

    // Calculate the last ~24 weeks
    let weeks = [];
    const earliestWeek = earliestMoment.isoWeek();
    const currentWeek = moment().isoWeek();
    if (currentWeek > earliestWeek) {
      weeks = [...Array(currentWeek + 1).keys()].slice(earliestWeek);
    }
    else {
      weeks = [...Array(currentWeek + 1).keys()].slice(1);
      const totaltWeeksLastYear = moment().subtract(currentWeek, "week").isoWeek();
      weeks = [...Array(totaltWeeksLastYear + 1).keys()].slice(earliestWeek).concat(weeks);
    }

    // Calculate the last ~6 months
    let months = [];
    const earliestMonth = earliestMoment.month();
    const currentMonth = moment().month();
    if (currentMonth > earliestMonth) {
      months = [...Array(currentMonth + 1).keys()].slice(earliestMonth);
    }
    else {
      months = [...Array(currentMonth + 1).keys()];
      months = [...Array(12).keys()].slice(earliestMonth).concat(months);
    }

    let datePicker;
    let weekPicker;
    let monthPicker;
    if (this.state.timePeriod === TimePeriod.Day) {
      datePicker = (
        <Datepicker
          date={this.state.date.toDate()}
          minDate={earliestMoment.toDate()}
          maxDate={new Date()}
          onChange={this.onDateChanged}
        />
      );
    }
    else if (this.state.timePeriod === TimePeriod.Week) {
      const weekNumber = this.state.date.isoWeek();
      weekPicker = (
        <Dropdown
          value={weekNumber}
          onChange={(option) => { this.onWeekNumberChanged(option.value)}}
          options={weeks.map(week => ({ label: week, value: week }))}
        />
      );
    }
    else if (this.state.timePeriod === TimePeriod.Month) {
      const month = this.state.date.month();
      monthPicker = (
        <Dropdown
          value={month}
          onChange={(option) => { this.onMonthChanged(option.value)}}
          options={months.map(month => ({ label: moment().set("month", month).format("MMMM"), value: month }))}
        />
      );
    }

    let options;
    if (datePicker) {
      options = (
        <div style={{ display: "flex", gap: "10px", justifyContent: "end", flexBasis: "50%", flexGrow: 0 }}>
          <div style={{ width: "120px", marginBottom: "10px" }}>
            <CheckboxBlock key="workdayOption" label="Whole day" isChecked={this.state.showWholeDay} onClick={this.onShowWholeDayChanged} />
          </div>
          <div style={{ width: "142px", marginBottom: "10px" }}>
            <CheckboxBlock key="dayTimeScaleOption" label="Quarter hours" isChecked={this.state.showQuarterHours} onClick={this.onShowQuarterHoursChanged} />
          </div>
        </div>
      )
    }
    else {
      options = (
        <div style={{ display: "flex", gap: "10px", justifyContent: "end", flexBasis: "50%", flexGrow: 0 }}>
          <div style={{ width: "120px", marginBottom: "10px" }}>
            <CheckboxBlock key="workdayOption" label="Whole day" isChecked={this.state.showWholeDay} onClick={this.onShowWholeDayChanged} />
          </div>
          <div style={{ width: "120px", marginBottom: "10px" }}>
            <CheckboxBlock key="weekendsOption" label="Weekends" isChecked={this.state.showWeekends} onClick={this.onShowWeekendsChanged} />
          </div>
          <div style={{ width: "120px", marginBottom: "10px" }}>
            <CheckboxBlock key="holidaysOption" label="Holidays" isChecked={this.state.showHolidays} onClick={this.onShowHolidaysChanged} />
          </div>
        </div>
      )
    }

    let dateControl = (
      <div style={{ display: "flex", gap: "10px", justifyContent: "start", flexBasis: "50%", flexGrow: 0 }}>
        <SegmentedControl
          name="timeperiod"
          lightBg
          value={this.state.timePeriod}
          onChange={this.onTimePeriodChanged}
          options={[
            { label: "Day", value: TimePeriod.Day },
            { label: "Week", value: TimePeriod.Week, default: true },
            { label: "Month", value: TimePeriod.Month }
          ]}
        />
        <div style={{ marginRight: "10px" }}>
          {datePicker}
          {weekPicker}
          {monthPicker}
        </div>
      </div>
    );

    let heading = (
      <div style={{ display: "flex", flexWrap: "wrap", rowGap: "10px" }}>
        {dateControl}
        {options}
      </div>
    );

    let sensorTypes = this.props.location.sensorTypes;

    let graphs = [];
    if (this.state.expandedType) {
      sensorTypes = sensorTypes.filter(sensorType => sensorType === this.state.expandedType);
    }

    // Get core hours (working hours) from the first location that has a building
    let coreTime = { start: { hours: 8, minutes: 0 }, end: { hours: 16, minutes: 0 } };
    if (this.props.location.type != "region") {
      // Get core hours from parent building in locationHierarchy
      const firstLocation = getLocation({ id: "*", children: this.props.locationHierarchy }, this.props.location.id);
      if (firstLocation && firstLocation.coreTime) { 
        coreTime = firstLocation.coreTime;
      }
    }

    // Remove unwanted sensor types
    sensorTypes = sensorTypes.filter(sensorType => sensorType !== "cpuTemperature");
    
    sensorTypes.forEach(sensorType => {

      // Get the data for the sensor type with the correct hash
      let sensorHash = `${sensorType}-${queryHash(sensorType, this.state, this.props)}`;

      const samples = this.props.data[sensorHash];
      const loadingStatus = this.props.dataLoadingStatus[sensorHash];
      let startDate;
      let endDate;
      if (this.state.timePeriod === TimePeriod.Day) {
        if (this.state.showWholeDay) {
          startDate = this.state.date.startOf("day").toDate();
          endDate = this.state.date.endOf("day").toDate();
        }
        else {
          startDate = this.state.date.clone().set("hours", coreTime.start.hours).startOf("hour").toDate();
          endDate = this.state.date.clone().set("hours", coreTime.end.hours - 1).endOf("hour").toDate();
        }
      }
      else if (this.state.timePeriod === TimePeriod.Week) {
        // if (this.state.date.isoWeek() > moment().isoWeek()) {
        //   startDate = moment().subtract(1, "year").isoWeek(this.state.weekNumber).startOf("isoWeek").toDate();
        //   endDate = moment().subtract(1, "year").isoWeek(this.state.weekNumber).endOf("isoWeek").toDate();
        // }
        // else {
          startDate = this.state.date.clone().startOf("isoWeek").toDate();
          endDate = this.state.date.clone().endOf("isoWeek").toDate();
        // }
  
        // Remove weekends
        if (!this.state.showWeekends) {
          endDate = moment(endDate).subtract(2, "day").toDate();
        }
      }
      else {
        // if (this.state.month > moment().month()) {
        //   startDate = moment().subtract(1, "year").month(this.state.month).startOf("month").toDate();
        //   endDate = moment().subtract(1, "year").month(this.state.month).endOf("month").toDate();
        // }
        // else {
          startDate = this.state.date.clone().startOf("month").toDate();
          endDate = this.state.date.clone().endOf("month").toDate();
        // }
      }

      // Does the location have a defined capacity that we can use in the fixed scale?
      let capacity = get(this.props.location, "geoJsonFeature.properties.capacity.soft", undefined);
      if (isEmpty(capacity)) {
        capacity = get(this.props.location, "geoJsonFeature.properties.capacity.hard", undefined);
      }
  
      if (samples) {
        const timeScale = this.state.timePeriod === "day" && this.state.showQuarterHours ? "quarter" : this.state.timeScale;
        
        const graph = (
          <GraphContainer
            type={sensorType}
            samples={samples}
            isLoading={loadingStatus.type === "loading"}
            statusMessage={loadingStatus.type === "error" ? loadingStatus.message : undefined}
            timePeriod={this.state.timePeriod}
            startDate={startDate}
            endDate={endDate}
            enableExpand={true}
            expanded={this.state.expandedType == sensorType}
            stepSize={timeScale}
            capacity={capacity}
            onExpandedChanged={(expand) => { this.onExpandChanged(expand ? sensorType : null) }}
            />
        );
    
        if (this.state.expandedType) {
          graphs.push(
            <Col key={sensorType + "-graph"} md={12}>
              { graph }
            </Col>
          );
        }
        else {
          graphs.push(
            <Col key={sensorType + "-graph"} md={6} xl={4} xxl={3}>
              { graph }
            </Col>
          );
        }
      }
    });

    let dashboard = (
      <Container fluid style={{ paddingLeft: "-10px", paddingRight: "-10px" }}>
        <Row> 
          { graphs }
        </Row>
      </Container>
    );

    return (
      <div className={style.listContainerHidden}>
        <div className={style.row}>
          <div className={style.colDash}>
            <div className={style.scroll}>
              <div style={{ paddingTop: "40px" }} />
              {heading}
              <div style={{ paddingTop: "40px" }} />
              {dashboard}
              <div style={{ paddingTop: "40px" }} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    selectedCompany: state.auth.selectedCompany,
    isLocationLoading: state.loading.location,
    location: state.location,
    data: state.dashboards.data,
    dataLoadingStatus: state.dashboards.dataLoadingStatus,
    queryHash: state.dashboards.queryHash,
    locationHierarchy: state.locations.hierarchy,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    getDaySamples: dashboardActions.getDaySamples,
    getWeekSamples: dashboardActions.getWeekSamples,
    getMonthSamples: dashboardActions.getMonthSamples,
    storeDashboardQueryHash: dashboardActions.storeDashboardQueryHash,
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
