import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Redirect, withRouter } from "react-router-dom";
import { Container, Col, Row } from "react-grid-system";
import { Visible, Hidden } from "react-grid-system";
import Lottie from "lottie-react";
import { getProfile, signoutUser } from "../../actions";
import Freetext from "../../components/Freetext";
import Button from "../../components/Button";
import { getParameterByName } from "../../helpers";
import bldngAiLogo from "../../bldng-ai-logo.jpg";
import * as animationData from "./bldng-services.json";
import style from "./style.module.scss";

class Signin extends Component {

  constructor(props) {
    super(props);
    const error = getParameterByName("error");
    const loginRequired = error === "login_required";
    this.state = {
      hasError: error && !loginRequired,
      loginRequired
    };
  }

  // componentWillMount() {
  //   // if token -> do action

  //   // if (!this.state.loginRequired && !this.state.hasError) {
  //   //   this.props.getProfile();
  //   // }

  //   // if (this.state.loginRequired) {
  //   //   this.props.signoutUser();
  //   // }
  // }

  componentWillUnmount() {
    if (this.props.errorMessage) {
      this.props.authError(null);
    }
  }

  getRedirectPath() {
    // console.log("getRedirectPath", this.props.location);
    const locationState = this.props.location.state;
    if (locationState && locationState.from && locationState.from.pathname) {
      return locationState.from.pathname; // redirects to referring url
    }

    return "/locations";
  }

  componentDidCatch(error, info) {
    // Display fallback UI
    this.setState({ hasError: true });
    // You can also log the error to an error reporting service
    // logErrorToMyService(error, info);
  }

  handleSubmit() {
    if (process.env.NODE_ENV === "development") {
      window.location.href = `http://localhost:8081/login`;
    }
    else {
      window.location.href = `${window.location.origin}/login`;
    }
  }

  render() {

    const defaultOptions = {
      loop: 2,
      autoplay: true,
      animationData: animationData,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid meet"
      }
    };

    const signInBlock = (
      <div className={style.block}>
        <div style={{ display: "block" }}>
          <Freetext
            header="Sign in to your account"
          />
          <ul>
            <li style={{ fontSize: "16px", listStyleType: "disc" }}>Administer your sensor installation</li>
            <li style={{ fontSize: "16px", listStyleType: "disc" }}>Get insight into building usage and health</li>
            <li style={{ fontSize: "16px", listStyleType: "disc" }}>Create useful app content for employees</li>
          </ul>
          <div className={style.buttonPadding}>
            <Button text="Sign in" onClick={this.handleSubmit} />
          </div>
        </div>
      </div>
    );

    const bigLoginElement = (
      <Row nogutter style={{ height: "100%" }}>
        <Col xs={12} md={5} className={style.leftColumn}>
          <div className={style.logoContainer}>
            <img src={bldngAiLogo} alt="Logo" width="100px" height="100px" />
            <div className={style.logoText}>BLDNG.ai</div>
          </div>
          <div className={style.animationContainer}>
            <Lottie animationData={animationData} loop={2} />
          </div>
        </Col>
        <Col xs={12} md={7} className={style.rightColumn}>
          <div className={style.right}>
            {/* <div className={style.aboveBlockTitle}>Admin portal</div> */}
            {signInBlock}
          </div>
          <div className={style.footnote}>© {new Date().getFullYear()} BLDNG.ai. All rights reserved. <a href={`${window.location.origin}/terms-of-service`}>Terms of Service</a>. <a href={`${window.location.origin}/privacy-policy`}>Privacy Policy</a>.</div>
        </Col>
      </Row>
    );

    const smallLoginElement = (
      <div className={style.rightColumn}>
        <div className={style.right}>
          {signInBlock}
          <div className={style.footnote}>© {new Date().getFullYear()} BLDNG.ai. All rights reserved. <a href={`${window.location.origin}/terms-of-service`}>Terms of Service</a>. <a href={`${window.location.origin}/privacy-policy`}>Privacy Policy</a>.</div>
        </div>
      </div>
    );

    if (this.state.hasError) {

      return (
        <div className={style.listContainer}>
          <div className={style.scroll}>
            <div className={style.rightColumn}>
              <div className={style.right} style={{ maxWidth: "500px" }}>
                <div className={style.block}>
                  <div style={{ display: "block" }}>
                    <Freetext
                      header="Something went wrong. Try again."
                      content="If the problem persists, contact your local IT support or BLDNG.ai support at support@bldng.ai."
                      link="support@bldng.ai"
                    />
                    <div className={style.buttonPadding}>
                      <Button text="Sign in" onClick={this.handleSubmit} />
                    </div>
                  </div>
                </div>
                </div>
              </div>
            </div>
        </div>
      );
    }
    return (this.props.authenticated && !this.state.loginRequired) ? (
      <Redirect
        to={{
          pathname: this.getRedirectPath(),
          state: {
            from: this.props.location
          }
        }}
      />
    ) : (
      <div>
        <Hidden xs sm md lg>
          <div className={style.listContainer}>
            <div className={style.scroll}>
              {bigLoginElement}
            </div>
          </div>
        </Hidden>
        <Visible xs sm md lg>
          <div className={style.listContainer}>
            <div className={style.scroll}>
              {smallLoginElement}
            </div>
          </div>
        </Visible>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    authenticated: state.auth.authenticated,
    errorMessage: state.auth.error
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ getProfile, signoutUser }, dispatch)
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Signin));
