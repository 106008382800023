import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Switch, Route, Redirect } from "react-router-dom";
import isEmpty from "lodash/isEmpty";
import get from "lodash/get";
import NotFound from "../../../NotFound";
import DetailsHeader from "../../../../components/DetailsHeader";
import SubNavigation from "../../../../components/SubNavigation";
import AnomalyDetailsInfo from "./anomalyDetailsInfo";
import AnomalyDetailsLocations from "./anomalyDetailsLocations";
import AnomalyDetailsAtomicSensors from "./anomalyDetailsAtomicSensors";
import * as anomaliesActions from "../../../../actions/anomalies";
import * as sensorActions from "../../../../actions/sensors";

class AnomalyDetails extends Component {

  constructor(props) {
    super(props);
    // console.log("AnomalyDetails.constructor",this.props);

    if (isEmpty(this.props.match.params.anomalyId)) {
      this.props.clearAnomaly();
    }
    else if (this.props.anomaly.id !== this.props.match.params.anomalyId) {
      this.props.getAnomaly(this.props.match.params.anomalyId);
    }
    else if (this.props.anomaly.sensorId && this.props.anomaly.sensorId !== this.props.sensor.id) {
      // Got new anomaly, load sensor
      this.props.getSensor(this.props.anomaly.sensorId);
    }
  }

  componentDidUpdate(prevProps) {
    // console.log("AnomalyDetails.componentDidUpdate",this.props);
    // Only load data if id change
    if (isEmpty(this.props.match.params.anomalyId)) {
      this.props.clearAnomaly();
    }
    else if (prevProps.match.params.anomalyId !== this.props.match.params.anomalyId) {
      this.props.getAnomaly(this.props.match.params.anomalyId);
    }
    else if (prevProps.anomaly.id !== this.props.anomaly.id && this.props.anomaly.sensorId) {
      // Got new anomaly, load sensor
      this.props.getSensor(this.props.anomaly.sensorId);
    }
  }

  render() {
    // console.log("AnomalyDetails.render()", this.props)
    const { isLoading } = this.props;

    if (isEmpty(this.props.match.params.anomalyId) || !this.props.anomaly.id) {
      return null;
    }

    const subNavigationLinks = [];
    subNavigationLinks.push({ pathname: `/companies/${this.props.match.params.companyId}/installation/anomalies/${this.props.match.params.anomalyId}/info`, label: `Info` });
    subNavigationLinks.push({ pathname: `/companies/${this.props.match.params.companyId}/installation/anomalies/${this.props.match.params.anomalyId}/locations`, label: `Locations` });
    subNavigationLinks.push({ pathname: `/companies/${this.props.match.params.companyId}/installation/anomalies/${this.props.match.params.anomalyId}/atomic-sensors`, label: `Atomic sensors` });
   
    let title = "Anomaly";
    title = get(this.props.anomaly, "reason", "Anomaly").toLowerCase().replaceAll("_", " ");
    title = title.charAt(0).toUpperCase() + title.slice(1);

    return (
      <div>
        <DetailsHeader
          title={title}
          isLoading={isLoading}
        />
        <SubNavigation links={subNavigationLinks} slim />
        <Switch>
          <Redirect exact from={`/companies/:companyId/installation/anomalies/${this.props.match.params.anomalyId}`} to={{ pathname: `/companies/${this.props.match.params.companyId}/installation/anomalies/${this.props.match.params.anomalyId}/info` }} />
          <Route path="/companies/:companyId/installation/anomalies/:anomalyId/info" children={(props) => <AnomalyDetailsInfo {...props} anomalyId={props.match.params.anomalyId} />} />
          <Route path="/companies/:companyId/installation/anomalies/:anomalyId/locations" children={(props) => <AnomalyDetailsLocations {...props} anomalyId={props.match.params.anomalyId} />} />
          <Route path="/companies/:companyId/installation/anomalies/:anomalyId/atomic-sensors" children={(props) => <AnomalyDetailsAtomicSensors {...props} anomalyId={props.match.params.anomalyId} />} />
          <Route component={NotFound} />
        </Switch>
        <div style={{ paddingTop: "40px" }} />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    anomaly: state.anomaly,
    sensor: state.sensor,
    selectedCompany: state.auth.selectedCompany,
    locationBreadcrumbs: state.locations.breadcrumbs,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    getAnomaly: anomaliesActions.getAnomaly,
    clearAnomaly: anomaliesActions.clearAnomaly,
    getSensor: sensorActions.getSensor,
   }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(AnomalyDetails);