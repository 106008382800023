import { toast } from "react-toastify";
import { uniqueId, get } from "lodash";
import { getLocation } from "../locationHelpers";
import moment from "moment";
import * as types from "../ApiTypes";

const initialState = {
  wpaCategories: [],
  workplaceFilters: {},
  workplaceFiltersLoading: false,
  workplaceFiltersError: null,
  queries: [], // array of query objects
  queryData: {}, // object with query data
  queryLoading: {},
  locationHierarchy: [],
  breadcrumbs: {},
  expandedHierarchy: [],
  customTags: []
};

export default function reportReducer(state = initialState, action) {
  switch (action.type) {

    case types.CREATE_WORKPLACE_QUERY: {
      const dateRanges = action.payload.dateRanges;
      const includeHolidays = action.payload.includeHolidays;
      const includeWeekends = action.payload.includeWeekends;
      const workplaceCategoryId = action.payload.workplaceCategoryId;
      const customTagId = action.payload.customTagId;
      const locationId = action.payload.locationId;
      const summed = action.payload.summed;
      const graphType = action.payload.graphType;
      const graphScaleType = action.payload.graphScaleType;
      const graphTimeSpan = action.payload.graphTimeSpan;
      const graphShowLabels = action.payload.graphShowLabels;

      // console.log("CREATE_WORKPLACE_QUERY", action.payload);

      const id = uniqueId("wpqId"); // Create random id for query

      let newQuery = {
        id,
        dateRanges,
        includeHolidays,
        includeWeekends,
        workplaceCategoryId,
        customTagId,
        locationId,
        summed,
        graphType,
        graphScaleType,
        graphTimeSpan,
        graphShowLabels,
      };

      // Find location-object, workplaceCategory-object and customTag-object
      newQuery = updateQueryWithObjects(newQuery, state.workplaceFilters.wpaCategories, state.expandedHierarchy, state.customTags);

      const queries = [...state.queries];
      queries.push(newQuery);

      return {
        ...state,
        queries
      };
    }

    case types.UPDATE_WORKPLACE_QUERY: {
      // console.log("UPDATE_WORKPLACE_QUERY", action.payload);

      // Find the correct query
      const id = action.payload.id;
      const index = state.queries.findIndex(query => query.id === id);

      // If query exists, update it
      if (index > -1) {

        let updatedQuery = {
          ...state.queries[index],
          dateRanges: action.payload.dateRanges,
          includeHolidays: action.payload.includeHolidays,
          includeWeekends: action.payload.includeWeekends,
          workplaceCategoryId: action.payload.workplaceCategoryId,
          customTagId: action.payload.customTagId,
          locationId: action.payload.locationId,
          summed: action.payload.summed,
          graphType: action.payload.graphType,
          graphScaleType: action.payload.graphScaleType,
          graphTimeSpan: action.payload.graphTimeSpan,
          graphShowLabels: action.payload.graphShowLabels,
        };

        // Find location-object, workplaceCategory-object and customTag-object
        updatedQuery = updateQueryWithObjects(updatedQuery, state.workplaceFilters.wpaCategories, state.expandedHierarchy, state.customTags);

        const queries = [...state.queries];
        queries[index] = updatedQuery;
        return {
          ...state,
          queries
        };
      }
    }

    case types.DELETE_WORKPLACE_QUERY: {

      // Find the correct query
      const id = action.payload.id;
      const index = state.queries.findIndex(query => query.id === id);

      // If query exists, delete it
      if (index > -1) {
        const queries = [...state.queries];
        queries.splice(index, 1);

        return {
          ...state,
          queries
        };
      }
    }

    case types.REQ_DATA: {

      if (action.fetchType === types.GET_WORKPLACE_CATEGORIES) {
        return {
          ...state,
          wpaCategoriesLoading: true,
          wpaCategoriesError: null
        };
      }

      if (action.fetchType === types.GET_WORKPLACE_FILTERS) {
        return {
          ...state,
          workplaceFiltersLoading: true,
          workplaceFiltersError: null
        };
      }

      if (action.fetchType === types.GET_UTILIZATION_INTRADAY ||
        action.fetchType === types.GET_UTILIZATION_DAYS ||
        action.fetchType === types.GET_UTILIZATION_WEEKDAYS ||
        action.fetchType === types.GET_UTILIZATION_WEEKS ||
        action.fetchType === types.GET_UTILIZATION_MONTHS ||
        action.fetchType === types.GET_PEOPLE_COUNT_INTRADAY ||
        action.fetchType === types.GET_PEOPLE_COUNT_DAYS ||
        action.fetchType === types.GET_PEOPLE_COUNT_WEEKDAYS ||
        action.fetchType === types.GET_PEOPLE_COUNT_WEEKS ||
        action.fetchType === types.GET_PEOPLE_COUNT_MONTHS
      ) {

        // Get key for query
        const hashKey = action.metadata.hashKey;

        // Update loading status
        const queryLoading = { ...state.queryLoading };
        queryLoading[hashKey] = { type: "loading" };

        return {
          ...state,
          queryLoading
        };
      }

      return state;
    }

    case types.RECV_DATA: {

      // Get key for queries
      const hashKey = get(action, "metadata.hashKey", null);

      if (action.fetchType === types.GET_WORKPLACE_CATEGORIES) {
        return {
          ...state,
          wpaCategories: action.payload,
          wpaCategoriesLoading: false,
          wpaCategoriesError: null
        };
      }

      if (action.fetchType === types.GET_WORKPLACE_FILTERS) {

        const workplaceFilters = action.payload;

        // Translate workplaceFilters.holidays to date objects
        Object.keys(workplaceFilters.holidays).forEach(holidayGroup => {
          workplaceFilters.holidays[holidayGroup] = workplaceFilters.holidays[holidayGroup].map(holiday => ({
            ...holiday,
            date: new Date(holiday.startDate.substring(0, 10))
          }))
        });

        // Expand location hierarchy with hasCoverageData, childHasCoverageData, hasPeopleCountData, childHasPeopleCountData, hasOccupancyData and childHasOccupancyData
        const expandedHierarchy = expandLocationHierarchy(state.locationHierarchy, workplaceFilters.locations);

        // Find location and workplaceCategory for each query
        const queries = updateQueriesWithLocationAndWorkplaceCategory(state.queries, workplaceFilters.wpaCategories, expandedHierarchy, state.customTags);

        return {
          ...state,
          queries,
          expandedHierarchy,
          workplaceFilters: workplaceFilters,
          workplaceFiltersLoading: false,
          workplaceFiltersError: null
        };
      }

      if (action.fetchType === types.GET_LOCATION_HIERARCHY) {

        // Expand location hierarchy with hasCoverageData, childHasCoverageData, hasPeopleCountData, childHasPeopleCountData, hasOccupancyData and childHasOccupancyData
        const expandedHierarchy = expandLocationHierarchy(action.payload, state.workplaceFilters.locations);

        // Find location and workplaceCategory for each query
        const queries = updateQueriesWithLocationAndWorkplaceCategory(state.queries, state.workplaceFilters.wpaCategories, expandedHierarchy, state.customTags);

        return {
          ...state,
          queries,
          expandedHierarchy,
          locationHierarchy: action.payload,
        };
      }

      if (action.fetchType === types.GET_CUSTOM_TAGS) {

        // Find location and workplaceCategory for each query
        const queries = updateQueriesWithLocationAndWorkplaceCategory(state.queries, state.workplaceFilters.wpaCategories, state.expandedHierarchy, action.payload);

        return { ...state, queries, customTags: action.payload };
      }

      if (action.fetchType === types.GET_UTILIZATION_INTRADAY) {

        // Translate action.payload to utilization object
        const utilizationIntraday = action.payload.map(sample => ({
          avgAvg: sample.avgAvg * 100,
          avgPeak: sample.avgPeak * 100,
          peakPeak: sample.peakPeak * 100,
          hourOfDay: sample.hour < 10 ? `0${sample.hour}:00:00` : `${sample.hour}:00:00`,
          entityId: sample.wpaCategoryId ?? sample.customTagId,
          unit: "%",
        }));

        // Store utilizationIntraday in queryData
        const queryData = { ...state.queryData };
        queryData[hashKey] = utilizationIntraday;
        const queryLoading = { ...state.queryLoading };
        queryLoading[hashKey] = { type: "finished" };

        return {
          ...state,
          queryData,
          queryLoading
        };
      }

      if (action.fetchType === types.GET_UTILIZATION_DAYS) {

        // Translate action.payload to utilization object
        const utilizationDays = action.payload.map(sample => ({
          avgAvg: sample.avgAvg * 100,
          avgPeak: sample.avgPeak * 100,
          peakPeak: sample.peakPeak * 100,
          datetime: sample.datetime,
          entityId: sample.wpaCategoryId ?? sample.customTagId,
          unit: "%",
        }));

        // Store utilizationWeekdays in queryData
        const queryData = { ...state.queryData };
        queryData[hashKey] = utilizationDays;
        const queryLoading = { ...state.queryLoading };
        queryLoading[hashKey] = { type: "finished" };

        return {
          ...state,
          queryData,
          queryLoading
        };
      }

      if (action.fetchType === types.GET_UTILIZATION_WEEKDAYS) {

        // Translate action.payload to utilization object
        const utilizationWeekdays = action.payload.map(sample => ({
          avgAvg: sample.avgAvg * 100,
          avgPeak: sample.avgPeak * 100,
          peakPeak: sample.peakPeak * 100,
          weekday: sample.weekday,
          entityId: sample.wpaCategoryId ?? sample.customTagId,
          unit: "%",
        }));

        // Store utilizationWeekdays in queryData
        const queryData = { ...state.queryData };
        queryData[hashKey] = utilizationWeekdays;
        const queryLoading = { ...state.queryLoading };
        queryLoading[hashKey] = { type: "finished" };

        return {
          ...state,
          queryData,
          queryLoading
        };
      }

      if (action.fetchType === types.GET_UTILIZATION_WEEKS) {

        // Translate action.payload to utilization object
        const utilizationWeeks = action.payload.map(sample => ({
          avgAvg: sample.avgAvg * 100,
          avgPeak: sample.avgPeak * 100,
          peakPeak: sample.peakPeak * 100,
          datetime: sample.datetime,
          entityId: sample.wpaCategoryId ?? sample.customTagId,
          unit: "%",
        }));

        // Store utilizationWeeks in queryData
        const queryData = { ...state.queryData };
        queryData[hashKey] = utilizationWeeks;
        const queryLoading = { ...state.queryLoading };
        queryLoading[hashKey] = { type: "finished" };

        return {
          ...state,
          queryData,
          queryLoading
        };
      }

      if (action.fetchType === types.GET_UTILIZATION_MONTHS) {

        // Translate action.payload to utilization object
        const utilizationMonths = action.payload.map(sample => ({
          avgAvg: sample.avgAvg * 100,
          avgPeak: sample.avgPeak * 100,
          peakPeak: sample.peakPeak * 100,
          datetime: sample.datetime,
          entityId: sample.wpaCategoryId ?? sample.customTagId,
          unit: "%",
        }));

        // Store utilizationMonths in queryData
        const queryData = { ...state.queryData };
        queryData[hashKey] = utilizationMonths;
        const queryLoading = { ...state.queryLoading };
        queryLoading[hashKey] = { type: "finished" };

        return {
          ...state,
          queryData,
          queryLoading
        };
      }

      if (action.fetchType === types.GET_PEOPLE_COUNT_INTRADAY) {

        let customTagId = null;
        if (action.metadata.customTagId && action.metadata.customTagId !== "all") {
          customTagId = action.metadata.customTagId;
        }

        // Translate action.payload to peopleCount object
        let countData = action.payload.filter(d => !d.isCapacity).map(sample => ({
          avgAvg: sample.avgAvg,
          avgPeak: sample.avgPeak,
          peakPeak: sample.peakPeak,
          hourOfDay: sample.hour < 10 ? `0${sample.hour}:00:00` : `${sample.hour}:00:00`,
          name: sample.name,
          locationId: sample.locationId,
          entityId: sample.locationId ?? customTagId ?? action.metadata.locationId,
          unit: "",
          capacity: sample.capacity,
        }));

        // Translate action.payload to percentage object
        const percentageData = action.payload.filter(d => !d.isCapacity).map(sample => ({
          avgAvg: sample.avgAvgPercentage,
          avgPeak: sample.avgPeakPercentage,
          peakPeak: sample.peakPeakPercentage,
          hourOfDay: sample.hour < 10 ? `0${sample.hour}:00:00` : `${sample.hour}:00:00`,
          name: sample.name,
          locationId: sample.locationId,
          entityId: sample.locationId ?? customTagId ?? action.metadata.locationId,
          unit: "%",
        }));

        // Remove capacity from countData and add it as its own time series
        if (action.metadata.summed || customTagId === null) {
          let capacitySeries = [];

          // Loop through countData and add capacity as its own time series
          // - start at the end and add newer capacity values to samples that don't have a capacity value
          let previousCapacity = null;
          for (let i = countData.length - 1; i >= 0; i--) {
            let sample = countData[i];
            let nextCapacity = sample.capacity !== undefined ? sample.capacity : previousCapacity;
            capacitySeries.push({
              capacity: nextCapacity,
              hourOfDay: sample.hourOfDay,
              name: "Capacity",
              entityId: "capacity"
            });

            previousCapacity = nextCapacity;

            // Remove capacity from countData
            delete sample.capacity;
          }

          // Flip capacitySeries to match the order of countData
          capacitySeries = capacitySeries.reverse();

          // Add capacitySeries to countData
          countData = [...countData, ...capacitySeries];
        }

        // Store countData and percentageData in queryData
        const queryData = { ...state.queryData };
        queryData[hashKey] = countData;
        queryData[hashKey + "-percentage"] = percentageData;
        const queryLoading = { ...state.queryLoading };
        queryLoading[hashKey] = { type: "finished" };

        return {
          ...state,
          queryData,
          queryLoading
        };
      }

      if (action.fetchType === types.GET_PEOPLE_COUNT_DAYS) {

        let customTagId = null;
        if (action.metadata.customTagId && action.metadata.customTagId !== "all") {
          customTagId = action.metadata.customTagId;
        }

        // Temp combine capacity and people count
        const capacityData = action.payload.filter(sample => sample.isCapacity);
        let temp = action.payload.filter(sample => !sample.isCapacity);
        temp.forEach(sample => {
          const capacitySample = capacityData.find(capacitySample => capacitySample.datetime === sample.datetime);
          if (capacitySample) {
            sample.capacity = capacitySample.capacity;
          }
        });

        // Translate action.payload to peopleCount object
        let countData = temp.filter(d => !d.isCapacity).map(sample => ({
          avgAvg: sample.avgAvg,
          avgPeak: sample.avgPeak,
          peakPeak: sample.peakPeak,
          datetime: sample.datetime,
          name: sample.name,
          locationId: sample.locationId,
          entityId: sample.locationId ?? customTagId ?? action.metadata.locationId,
          unit: "",
          capacity: sample.capacity,
        }));

        // Translate action.payload to percentage object
        const percentageData = action.payload.filter(d => !d.isCapacity).map(sample => ({
          avgAvg: sample.avgAvgPercentage,
          avgPeak: sample.avgPeakPercentage,
          peakPeak: sample.peakPeakPercentage,
          datetime: sample.datetime,
          name: sample.name,
          locationId: sample.locationId,
          entityId: sample.locationId ?? customTagId ?? action.metadata.locationId,
          unit: "%",
        }));

        // Remove capacity from countData and add it as its own time series
        if (action.metadata.summed || customTagId === null) {
          let capacitySeries = [];

          // Loop through countData and add capacity as its own time series
          // - start at the end and add newer capacity values to samples that don't have a capacity value
          let previousCapacity = null;
          for (let i = countData.length - 1; i >= 0; i--) {
            let sample = countData[i];
            let nextCapacity = sample.capacity !== undefined ? sample.capacity : previousCapacity;
            capacitySeries.push({
              capacity: nextCapacity,
              datetime: sample.datetime,
              name: "Capacity",
              entityId: "capacity"
            });

            previousCapacity = nextCapacity;

            // Remove capacity from countData
            delete sample.capacity;
          }

          // Flip capacitySeries to match the order of countData
          capacitySeries = capacitySeries.reverse();

          // Add capacitySeries to countData
          countData = [...countData, ...capacitySeries];
        }

        // Store countData and percentageData in queryData
        const queryData = { ...state.queryData };
        queryData[hashKey] = countData;
        queryData[hashKey + "-percentage"] = percentageData;
        const queryLoading = { ...state.queryLoading };
        queryLoading[hashKey] = { type: "finished" };

        return {
          ...state,
          queryData,
          queryLoading
        };
      }

      if (action.fetchType === types.GET_PEOPLE_COUNT_WEEKDAYS) {

        let customTagId = null;
        if (action.metadata.customTagId && action.metadata.customTagId !== "all") {
          customTagId = action.metadata.customTagId;
        }

        // Temp combine capacity and people count
        const capacityData = action.payload.filter(sample => sample.isCapacity);
        let temp = action.payload.filter(sample => !sample.isCapacity);
        temp.forEach(sample => {
          const capacitySample = capacityData.find(capacitySample => capacitySample.weekday === sample.weekday);
          if (capacitySample) {
            sample.capacity = capacitySample.capacity;
          }
        });

        // Translate action.payload to peopleCount object
        let countData = temp.filter(d => !d.isCapacity).map(sample => ({
          avgAvg: sample.avgAvg,
          avgPeak: sample.avgPeak,
          peakPeak: sample.peakPeak,
          weekday: sample.weekday,
          name: sample.name,
          locationId: sample.locationId,
          entityId: sample.locationId ?? customTagId ?? action.metadata.locationId,
          unit: "",
          capacity: sample.capacity,
        }));

        // Translate action.payload to percentage object
        const percentageData = action.payload.filter(d => !d.isCapacity).map(sample => ({
          avgAvg: sample.avgAvgPercentage,
          avgPeak: sample.avgPeakPercentage,
          peakPeak: sample.peakPeakPercentage,
          weekday: sample.weekday,
          name: sample.name,
          locationId: sample.locationId,
          entityId: sample.locationId ?? customTagId ?? action.metadata.locationId,
          unit: "%",
        }));

        // Remove capacity from countData and add it as its own time series
        if (action.metadata.summed || customTagId === null) {
          let capacitySeries = [];

          // Loop through countData and add capacity as its own time series
          // - start at the end and add newer capacity values to samples that don't have a capacity value
          let previousCapacity = null;
          for (let i = countData.length - 1; i >= 0; i--) {
            let sample = countData[i];
            let nextCapacity = sample.capacity !== undefined ? sample.capacity : previousCapacity;
            capacitySeries.push({
              capacity: nextCapacity,
              weekday: sample.weekday,
              name: "Capacity",
              entityId: "capacity"
            });

            previousCapacity = nextCapacity;

            // Remove capacity from countData
            delete sample.capacity;
          }

          // Flip capacitySeries to match the order of countData
          capacitySeries = capacitySeries.reverse();

          // Add capacitySeries to countData
          countData = [...countData, ...capacitySeries];
        }

        // Store countData and percentageData in queryData
        const queryData = { ...state.queryData };
        queryData[hashKey] = countData;
        queryData[hashKey + "-percentage"] = percentageData;
        const queryLoading = { ...state.queryLoading };
        queryLoading[hashKey] = { type: "finished" };

        return {
          ...state,
          queryData,
          queryLoading
        };
      }

      if (action.fetchType === types.GET_PEOPLE_COUNT_WEEKS) {

        let customTagId = null;
        if (action.metadata.customTagId && action.metadata.customTagId !== "all") {
          customTagId = action.metadata.customTagId;
        }

        // Temp combine capacity and people count
        const capacityData = action.payload.filter(sample => sample.isCapacity);
        let temp = action.payload.filter(sample => !sample.isCapacity);
        temp.forEach(sample => {
          const capacitySample = capacityData.find(capacitySample => capacitySample.datetime === sample.datetime);
          if (capacitySample) {
            sample.capacity = capacitySample.capacity;
          }
        });

        // Translate action.payload to peopleCount object
        let countData = temp.filter(d => !d.isCapacity).map(sample => ({
          avgAvg: sample.avgAvg,
          avgPeak: sample.avgPeak,
          peakPeak: sample.peakPeak,
          datetime: sample.datetime,
          name: sample.name,
          locationId: sample.locationId,
          entityId: sample.locationId ?? customTagId ?? action.metadata.locationId,
          unit: "",
          capacity: sample.capacity,
        }));

        // Translate action.payload to percentage object
        const percentageData = action.payload.filter(d => !d.isCapacity).map(sample => ({
          avgAvg: sample.avgAvgPercentage,
          avgPeak: sample.avgPeakPercentage,
          peakPeak: sample.peakPeakPercentage,
          datetime: sample.datetime,
          name: sample.name,
          locationId: sample.locationId,
          entityId: sample.locationId ?? customTagId ?? action.metadata.locationId,
          unit: "%",
        }));

        // Remove capacity from countData and add it as its own time series
        if (action.metadata.summed || customTagId === null) {
          let capacitySeries = [];

          // Loop through countData and add capacity as its own time series
          // - start at the end and add newer capacity values to samples that don't have a capacity value
          let previousCapacity = null;
          for (let i = countData.length - 1; i >= 0; i--) {
            let sample = countData[i];
            let nextCapacity = sample.capacity !== undefined ? sample.capacity : previousCapacity;
            capacitySeries.push({
              capacity: nextCapacity,
              datetime: sample.datetime,
              name: "Capacity",
              entityId: "capacity"
            });

            previousCapacity = nextCapacity;

            // Remove capacity from countData
            delete sample.capacity;
          }

          // Flip capacitySeries to match the order of countData
          capacitySeries = capacitySeries.reverse();

          // Add capacitySeries to countData
          countData = [...countData, ...capacitySeries];
        }

        // Store countData and percentageData in queryData
        const queryData = { ...state.queryData };
        queryData[hashKey] = countData;
        queryData[hashKey + "-percentage"] = percentageData;
        const queryLoading = { ...state.queryLoading };
        queryLoading[hashKey] = { type: "finished" };

        return {
          ...state,
          queryData,
          queryLoading
        };
      }

      if (action.fetchType === types.GET_PEOPLE_COUNT_MONTHS) {

        let customTagId = null;
        if (action.metadata.customTagId && action.metadata.customTagId !== "all") {
          customTagId = action.metadata.customTagId;
        }

        // Temp combine capacity and people count
        const capacityData = action.payload.filter(sample => sample.isCapacity);
        let temp = action.payload.filter(sample => !sample.isCapacity);
        temp.forEach(sample => {
          const capacitySample = capacityData.find(capacitySample => capacitySample.datetime === sample.datetime);
          if (capacitySample) {
            sample.capacity = capacitySample.capacity;
          }
        });

        // Translate action.payload to peopleCount object
        let countData = temp.filter(d => !d.isCapacity).map(sample => ({
          avgAvg: sample.avgAvg,
          avgPeak: sample.avgPeak,
          peakPeak: sample.peakPeak,
          datetime: sample.datetime,
          name: sample.name,
          locationId: sample.locationId,
          entityId: sample.locationId ?? customTagId ?? action.metadata.locationId,
          unit: "",
          capacity: sample.capacity,
        }));

        // Translate action.payload to percentage object
        const percentageData = action.payload.filter(d => !d.isCapacity).map(sample => ({
          avgAvg: sample.avgAvgPercentage,
          avgPeak: sample.avgPeakPercentage,
          peakPeak: sample.peakPeakPercentage,
          datetime: sample.datetime,
          name: sample.name,
          locationId: sample.locationId,
          entityId: sample.locationId ?? customTagId ?? action.metadata.locationId,
          unit: "%",
        }));


        // Use the same data for yearOverYear comparison, but with different entityId and only the last capacity value
        let yearOverYearCountData = countData.map(sample => ({ ...sample }));
        let yearOverYearPercentageData = percentageData.map(sample => ({ ...sample }));

        // Translate samples into format for graph
        let lastCapacity = null;
        yearOverYearCountData = yearOverYearCountData.map(sample => {
          const date = moment(sample.datetime);
          let newSample = {
            entityId: `${date.year()}`,
            month: date.month(),
            datetime: sample.datetime
          };

          newSample.avgAvg = sample.avgAvg;
          newSample.avgPeak = sample.avgPeak;
          newSample.peakPeak = sample.peakPeak;

          lastCapacity = sample.capacity;

          return newSample;
        });

        yearOverYearPercentageData = yearOverYearPercentageData.map(sample => {
          const date = moment(sample.datetime);
          let newSample = {
            entityId: `${date.year()}`,
            month: date.month(),
            datetime: sample.datetime
          };

          newSample.avgAvg = sample.avgAvg;
          newSample.avgPeak = sample.avgPeak;
          newSample.peakPeak = sample.peakPeak;

          return newSample;
        });

        // Add a capacity sample for each month
        let capacitySamples = [];
        if (lastCapacity) {
          capacitySamples = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map(month => {
            return {
              entityId: "capacity",
              month: month,
              capacity: lastCapacity,
            };
          });

          // Append capacity
          yearOverYearCountData = [...yearOverYearCountData, ...Object.values(capacitySamples)];
        }

        // Remove capacity from countData and add it as its own time series
        if (action.metadata.summed || customTagId === null) {
          let capacitySeries = [];

          // Loop through countData and add capacity as its own time series
          // - start at the end and add newer capacity values to samples that don't have a capacity value
          let previousCapacity = null;
          for (let i = countData.length - 1; i >= 0; i--) {
            let sample = countData[i];
            let nextCapacity = sample.capacity !== undefined ? sample.capacity : previousCapacity;
            capacitySeries.push({
              capacity: nextCapacity,
              datetime: sample.datetime,
              name: "Capacity",
              entityId: "capacity"
            });

            previousCapacity = nextCapacity;

            // Remove capacity from countData
            delete sample.capacity;
          }

          // Flip capacitySeries to match the order of countData
          capacitySeries = capacitySeries.reverse();

          // Add capacitySeries to countData
          countData = [...countData, ...capacitySeries];
        }

        // Store countData and percentageData in queryData
        const queryData = { ...state.queryData };
        queryData[hashKey] = countData;
        queryData[hashKey + "-percentage"] = percentageData;
        queryData[hashKey + "-yearOverYear"] = yearOverYearCountData;
        queryData[hashKey + "-yearOverYear-percentage"] = yearOverYearPercentageData;
        const queryLoading = { ...state.queryLoading };
        queryLoading[hashKey] = { type: "finished" };

        return {
          ...state,
          queryData,
          queryLoading
        };
      }

      return state;
    }

    case types.RECV_ERROR: {

      if (action.fetchType === types.GET_WORKPLACE_CATEGORIES) {
        return {
          ...state,
          wpaCategories: [],
          wpaCategoriesLoading: false,
          wpaCategoriesError: action.error
        };
      }

      if (action.fetchType === types.GET_WORKPLACE_FILTERS) {
        return {
          ...state,
          workplaceFilters: {},
          workplaceFiltersLoading: false,
          workplaceFiltersError: action.error
        };
      }

      if (action.fetchType === types.GET_UTILIZATION_INTRADAY ||
        action.fetchType === types.GET_UTILIZATION_DAYS ||
        action.fetchType === types.GET_UTILIZATION_WEEKDAYS ||
        action.fetchType === types.GET_UTILIZATION_WEEKS ||
        action.fetchType === types.GET_UTILIZATION_MONTHS ||
        action.fetchType === types.GET_PEOPLE_COUNT_INTRADAY ||
        action.fetchType === types.GET_PEOPLE_COUNT_DAYS ||
        action.fetchType === types.GET_PEOPLE_COUNT_WEEKDAYS ||
        action.fetchType === types.GET_PEOPLE_COUNT_WEEKS ||
        action.fetchType === types.GET_PEOPLE_COUNT_MONTHS
      ) {

        // Get key for query
        const hashKey = action.metadata.hashKey;

        // Update loading status
        const queryLoading = { ...state.queryLoading };
        queryLoading[hashKey] = { type: "error", error: action.error };

        return {
          ...state,
          queryLoading
        };
      }

      return state;
    }

    case types.CLEAR_DATA: {
      return initialState;
    }

    default: {
      return state;
    }
  }
}

// Find location and workplaceCategory for each query
const updateQueriesWithLocationAndWorkplaceCategory = (queries, wpaCategories, expandedHierarchy, customTags) => {

  let newQueries = queries.map(query => {
    return updateQueryWithObjects(query, wpaCategories, expandedHierarchy, customTags);
  });

  return newQueries;
}

const updateQueryWithObjects = (query, wpaCategories, expandedHierarchy, customTags) => {
  let newQuery = { ...query };

  if (expandedHierarchy.length > 0) {
    // Find location in expandedHierarchy
    const topLocation = { _id: "top", name: "", children: expandedHierarchy };
    const location = getLocation(topLocation, query.locationId);
    newQuery.location = location;
  }

  if (wpaCategories !== undefined) {
    const workplaceCategory = wpaCategories.find(workplaceCategory => workplaceCategory._id === query.workplaceCategoryId);
    newQuery.workplaceCategory = workplaceCategory;
  }

  if (customTags.length > 0) {
    const customTag = customTags.find(customTag => customTag.id === query.customTagId);
    newQuery.customTag = customTag;
  }

  return newQuery;
}


// Expand the location hierarchy to include hasCoverageData, hasOccupancyData and hasPeopleCountData (and if their children have it)
const expandLocationHierarchy = (locationHierarchy, filterLocations) => {

  if (locationHierarchy === undefined || filterLocations === undefined) {
    return locationHierarchy;
  }

  // console.log("expandLocationHierarchy", locationHierarchy, filterLocations);

  // Recursively expand the location hierarchy, by first going to the children
  const expand = (location, filterLocations) => {

    let children = [];
    if (location.children) {
      children = location.children.map(child => {
        return expand(child, filterLocations);
      });
    }

    const childHasCoverageData = children.find(x => x.hasCoverageData || x.childHasCoverageData) !== undefined;
    const childHasUtilizationData = children.find(x => x.hasUtilizationData || x.childHasUtilizationData) !== undefined;
    const childHasPeopleCountData = children.find(x => x.hasPeopleCountData || x.childHasPeopleCountData) !== undefined;

    const filterLocation = filterLocations.find(filterLocation => filterLocation._id === location.id);
    const hasCoverageData = filterLocation ? filterLocation.hasCoverageData : false;
    const hasUtilizationData = filterLocation ? filterLocation.hasUtilizationData : false;
    const hasPeopleCountData = filterLocation ? filterLocation.hasPeopleCountData : false;

    const hasAggregateDataSince = filterLocation ? filterLocation.hasAggregateDataSince : undefined;
    const hasAggregateDataUntil = filterLocation ? filterLocation.hasAggregateDataUntil : undefined;

    // Add all customTags and childrens expandedCustomTags to expandedCustomTags
    // This will result in all available customTags for the location and its children
    let expandedCustomTags = {};
    if (filterLocation) {
      get(filterLocation, "customTags", []).forEach(customTag => {
        expandedCustomTags[customTag._id] = customTag.name;
      });
    }

    children.forEach(child => {
      expandedCustomTags = { ...expandedCustomTags, ...child.expandedCustomTags };
    });

    return {
      ...location,
      hasCoverageData,
      hasUtilizationData,
      hasPeopleCountData,
      childHasCoverageData,
      childHasUtilizationData,
      childHasPeopleCountData,
      hasAggregateDataSince,
      hasAggregateDataUntil,
      expandedCustomTags,
      children
    }
  };

  let expandedLocation = expand({ id: "root", children: locationHierarchy }, filterLocations);

  // console.log("expandLocationHierarchy result", expandedLocation.children);

  return expandedLocation.children;
}