import React, { Component } from "react";
import { isEmpty } from "lodash";
import moment from "moment";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Table from "../../../components/Table";
import TopRowOptions from "../../../components/TopRowOptions";
import SearchBox from "../../../components/SearchBox";
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import { RowIcon, GREEN, GREY } from "../../../components/RowIcon";
import * as unregisteredSensors from "../../../actions/unregisteredSensors";
import * as selectedActions from "../../../actions/selected";

class UnregisteredSensors extends Component {

  constructor(props) {
    super(props);
    const params = new URLSearchParams(props.history.location.search);
    this.state = {
      searchText: params.get("s") || "",
      sortBy: params.get("b") || "createdAt",
      sortOrder: params.get("o") ||  "asc",
      offset: 0,
      limit: 100,
    };
    
    this.onSearchClick = this.onSearchClick.bind(this);
    this.onSearchChange = this.onSearchChange.bind(this);
    this.onSortedChange = this.onSortedChange.bind(this);
    this.onOffsetChange = this.onOffsetChange.bind(this);
    this.onRowClick = this.onRowClick.bind(this);
    this.onHover = this.onHover.bind(this);
    this.onToggle = this.onToggle.bind(this);
    this.onToggleAll = this.onToggleAll.bind(this);
    this.fetchData = this.fetchData.bind(this);
  }

  componentDidMount() {
    this.fetchData();
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    // After registering a sensor, the page should clear the state - once
    const params = new URLSearchParams(nextProps.history.location.search);
    const shouldReset = params.get("r");
    if (shouldReset !== null) {
      params.delete("r");

      nextProps.history.push({
        pathname: nextProps.history.location.pathname.substr(0, nextProps.history.location.pathname.length),
        search: params.toString()
      });

      return { 
        searchText: params.get("s") || "",
        sortBy: params.get("b") || "createdAt",
        sortOrder: params.get("o") ||  "asc",
        offset: 0,
        limit: 100,
       };
    }

    return null;
  }

  onSearchClick() {

    // Add searchText to query params in url
    const queryParams = new URLSearchParams(this.props.history.location.search);
    if (this.state.searchText) {
      queryParams.set("s", this.state.searchText);
    }
    else {
      queryParams.delete("s");
    }

    this.props.history.push({ 
      pathname: this.props.history.location.pathname.substr(0, this.props.history.location.pathname.length),
      search: queryParams.toString()
    });

    this.setState({ offset: 0 }, this.fetchData);
  }

  onSearchChange(value) {
    this.setState({ searchText: value });
  }

  onSortedChange(newSorted) {
    const sortBy = newSorted[0].id;
    const sortOrder = newSorted[0].desc ? "desc" : "asc";

    // Add sortBy and sortOrder to query params in url
    const queryParams = new URLSearchParams(this.props.history.location.search);
    queryParams.set("b", sortBy);
    queryParams.set("o", sortOrder);

    this.props.history.push({
      pathname: this.props.history.location.pathname.substr(0, this.props.history.location.pathname.length),
      search: queryParams.toString()
    });

    this.setState({ sortBy, sortOrder, offset: 0 }, this.fetchData);
  }

  onOffsetChange(offset) {
    this.setState({ offset }, this.fetchData);
  }

  onRowClick(column, rowInfo) {
    return {
      onClick: e => {
        if (column.name !== 'isSelected' && rowInfo !== undefined) {

          // Do not allow clicking on rows when there is a draft change pending
          if (!isEmpty(this.props.draft)) {
            return;
          }

          // Toggle a row when the user clicks on it
          this.onToggle(rowInfo);
        }
      },
      onMouseOver: e => {
        if (rowInfo && rowInfo.original.deviceId) {
          this.onHover(rowInfo.original.deviceId);
        }
      },
      onMouseLeave: e => this.onHover(null),
      style: {
        cursor: !isEmpty(this.props.draft) ? "not-allowed" : "pointer",
        background: (
          rowInfo ? (
            rowInfo.original.deviceId === this.state.highlightedId ? 
              "rgba(0,0,0,0.05)" : null
          ) : null
        )
      }
    }
  }

  onHover(id) {
    if (this.state.highlightedId !== id) {
      this.setState({ highlightedId: id });
    }
  }

  onToggle(row) {
    // Do not allow clicking on rows when there is a draft change pending
    if (!isEmpty(this.props.draft)) {
      return;
    }

    let device = row.original || row.original;
    if (this.props.selectedDevices[device.deviceId] !== undefined && this.props.selectedDevices[device.deviceId]) {
      this.props.deselectUnregisteredSensors([device.deviceId]);
    }
    else {
      this.props.selectUnregisteredSensors([{ ...device }]);
    }
  }

  onToggleAll() {
    // Do not allow clicking on rows when there is a draft change pending
    if (!isEmpty(this.props.draft)) {
      return;
    }

    const allDevicesAreSelected = !isEmpty(this.props.data.devices) && this.props.data.devices.every(device => this.props.selectedDevices[device.deviceId] !== undefined && this.props.selectedDevices[device.deviceId]);

    if (allDevicesAreSelected) {
      this.props.deselectUnregisteredSensors(this.props.data.devices.map(device => device.deviceId));
    }
    else {
      this.props.selectUnregisteredSensors(this.props.data.devices.map(device => {
        return { ...device };
      }));
    }
  }

  fetchData() {
    const queryParams = {
      search: this.state.searchText,
      sortBy: this.state.sortBy,
      sortOrder: this.state.sortOrder,
      offset: this.state.offset,
      limit: this.state.limit
    };

    this.props.getUnregisteredSensors(queryParams);
  }

  render() {

    const heading = (
      <TopRowOptions
        description="Unregistered sensors has to be configured for the system to receive data from it. Select one or more rows to assign a model and a location before registering the sensor. You can only add a description and map placement to one sensor at a time."
        searchbox={(
          <SearchBox
            value={this.state.searchText}
            onSearchChanged={this.onSearchChange}
            onSearchClick={this.onSearchClick}
            onClear={() => this.setState({ searchText: "" }, this.onSearchClick)}
            inListView
          />
        )}
      />
    );

    const anotherTypeSelected = false; 
    const allDevicesAreSelected = !isEmpty(this.props.data.devices) && this.props.data.devices.every(device => this.props.selectedDevices[device.deviceId] !== undefined && this.props.selectedDevices[device.deviceId]);

    const tableElement = (
      <Table
        data={this.props.data.devices}
        sortBy={this.state.sortBy}
        sortOrder={this.state.sortOrder}
        offset={this.state.offset}
        limit={this.state.limit}
        count={this.props.data.count}
        onSortedChange={this.onSortedChange}
        onOffsetChange={this.onOffsetChange}
        noDataText={this.props.isSensorsLoading ? "" : "No unregistered sensors found"}
        columns={[
          {
            id: "id",
            header: () => (
              <label className="checkboxContainer checkboxHeaderContainer" htmlFor={`editCheckbox-header`}>
                <input
                  id={`editCheckbox-header`}
                  type="checkbox"
                  className="checkbox"
                  checked={allDevicesAreSelected}
                  onChange={() => this.onToggleAll()}
                  disabled={anotherTypeSelected || !isEmpty(this.props.draft)}
                />
                <span className={anotherTypeSelected || !isEmpty(this.props.draft) ? "disabledCheckmark" : "checkmark"} />
              </label>
            ),
            sortable: false,
            name: "isSelected",
            cell: ({ row }) => (
              <label className={anotherTypeSelected || !isEmpty(this.props.draft) ? "disabledCheckboxContainer" : "checkboxContainer"} htmlFor={`editCheckbox-${row.original.deviceId}`}>
                <input
                  id={`editCheckbox-${row.original.deviceId}`}
                  type="checkbox"
                  className="checkbox"
                  checked={this.props.selectedDevices[row.original.deviceId] !== undefined && this.props.selectedDevices[row.original.deviceId]}
                  onChange={() => this.onToggle(row)}
                  disabled={anotherTypeSelected || !isEmpty(this.props.draft)}
                />
                <span className={anotherTypeSelected || !isEmpty(this.props.draft) ? "disabledCheckmark" : "checkmark"} />
              </label>
            ),
            width: 60
          },
          {
            header: "Created",
            accessorKey: "createdAt",
            sortable: true,
            width: 130,
            cell: ({ row }) => {
              const date = `${moment(row.original.createdAt).format("HH:mm - DD/MM/YY")}`;
              return <span title={date}>{date}</span>;
            }
          },
          {
            header: "DeviceId",
            accessorKey: "deviceId",
            sortable: true,
            cell: ({ row }) => (<span>{row.original.deviceId}</span>)
          },
          {
            header: "Vendor",
            accessorKey: "vendor",
            sortable: true,
            cell: ({ row }) => (<span>{row.original.vendor}</span>)
          },
          {
            header: "Gateway",
            accessorKey: "gateway",
            sortable: false,
            cell: ({ row }) => (<span>{row.original.gateway.name}</span>)
          },
          {
            header: "Ready",
            accessorKey: "complete",
            sortable: true,
            cell: ({ row }) => {
              if (row.original.complete) {
                return <RowIcon tooltip="yes" bgColor={GREEN} icon={faCheck} styles={{ fontSize: "16px" }}  />
              }
              else {
                return <RowIcon tooltip="no" bgColor={GREY} icon={faTimes} styles={{ fontSize: "16px" }}  />
              }
            }
          }
        ]}
        getTdProps={this.onRowClick}
        className="-row-clickable setMaxHeigth -highlight"
        loading={this.props.isSensorsLoading}
      />
    );

    return (
      <div>
        { heading }
        { tableElement }
        <div style={{ paddingTop: "40px" }} />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    selectedCompany: state.auth.selectedCompany,
    data: state.unregisteredSensors,
    draft: state.unregisteredSensors.draft,
    isSensorsLoading : state.loading.unregisteredSensors,
    selectedDevices: state.selected.unregisteredSensors,
    selectedTypes: state.selected.allTypes,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ 
    getUnregisteredSensors: unregisteredSensors.getUnregisteredSensors,
    selectUnregisteredSensors: selectedActions.selectUnregisteredSensors,
    deselectUnregisteredSensors: selectedActions.deselectUnregisteredSensors
   }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(UnregisteredSensors);
