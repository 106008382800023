import axios from "axios";
import Cookies from "universal-cookie";
import * as types from "../ApiTypes";
import * as actions from "./sharedFunction";
import * as selectionActions from "./selected";
import { API_URL, COOKIE_PREFIX } from "../env";
import { getCompanyId } from "../helpers";

const cookies = new Cookies();

export const getLocationTags = (queryParams) => async (dispatch) => {
  // console.log("getLocationTags", queryParams);
  dispatch(actions.requestData(types.GET_LOCATION_TAGS));

  const apiUrl = await API_URL();
  const companyId = getCompanyId();
  const cookiePrefix = await COOKIE_PREFIX();
  const accessToken = cookies.get(`${cookiePrefix}_access_token`);

  axios.get(`${apiUrl}companies/${companyId}/locationtags`, await actions.getAxiosConfig(queryParams))
    .then((response) => (
      dispatch(actions.receiveData(response.data, types.GET_LOCATION_TAGS))
    ))
    .catch((error) => {
      if (error.response && error.response.status === 401) {
        actions.sessionTokenExpired(cookiePrefix, accessToken);
      } else {
        dispatch(actions.receiveError(error, types.GET_LOCATION_TAGS));
      }
    });
};

export const getLocationTag = (id) => async (dispatch) => {
  dispatch({
    type: types.REQ_DATA,
    payload: {
      id
    },
    fetchType: types.GET_LOCATION_TAG
  });

  const apiUrl = await API_URL();
  const companyId = getCompanyId();
  const cookiePrefix = await COOKIE_PREFIX();
  const accessToken = cookies.get(`${cookiePrefix}_access_token`);

  axios.get(`${apiUrl}companies/${companyId}/locationtags/${id}`, await actions.getAxiosConfig())
    .then((response) => {
      dispatch(actions.receiveData(response.data, types.GET_LOCATION_TAG));
    })
    .catch((error) => {
      if (error.response && error.response.status === 401) {
        actions.sessionTokenExpired(cookiePrefix, accessToken);
      } else {
        dispatch(actions.receiveError(error, types.GET_LOCATION_TAG));
      }
    });
};

export const createLocationTag = (body, redirect) => async (dispatch) => {
  dispatch(actions.requestData(types.CREATE_LOCATION_TAG));

  const apiUrl = await API_URL();
  const companyId = getCompanyId();
  const cookiePrefix = await COOKIE_PREFIX();
  const accessToken = cookies.get(`${cookiePrefix}_access_token`);

  axios.post(`${apiUrl}companies/${companyId}/locationtags`, body, await actions.getAxiosConfig())
    .then((response) => {
      dispatch(actions.receiveData(response.data, types.CREATE_LOCATION_TAG));
      dispatch(selectionActions.clearSelection());
      dispatch(getLocationTags());
      redirect(`/companies/${companyId}/locations/root/location-tags`);
    })
    .catch((error) => {
      if (error.response && error.response.status === 401) {
        actions.sessionTokenExpired(cookiePrefix, accessToken);
      } else {
        dispatch(actions.receiveError(error, types.CREATE_LOCATION_TAG));
      }
    });
};

export const updateLocationTag = (tagId, body, redirect) => async (dispatch) => {
  dispatch(actions.requestData(types.UPDATE_LOCATION_TAG));

  const apiUrl = await API_URL();
  const companyId = getCompanyId();
  const cookiePrefix = await COOKIE_PREFIX();
  const accessToken = cookies.get(`${cookiePrefix}_access_token`);

  axios.put(`${apiUrl}companies/${companyId}/locationtags/${tagId}`, body, await actions.getAxiosConfig())
    .then((response) => {
      dispatch(actions.receiveData(response.data, types.UPDATE_LOCATION_TAG));
      dispatch(selectionActions.clearSelection());
      dispatch(getLocationTags());
      redirect(`/companies/${companyId}/locations/root/location-tags`);
    })
    .catch((error) => {
      if (error.response && error.response.status === 401) {
        actions.sessionTokenExpired(cookiePrefix, accessToken);
      } else {
        dispatch(actions.receiveError(error, types.UPDATE_LOCATION_TAG));
      }
    });
};

export const deleteLocationTag = (tagId) => async (dispatch) => {

  dispatch(actions.requestData(types.DELETE_LOCATION_TAG));

  const apiUrl = await API_URL();
  const companyId = getCompanyId();
  const cookiePrefix = await COOKIE_PREFIX();
  const accessToken = cookies.get(`${cookiePrefix}_access_token`);

  axios.delete(`${apiUrl}companies/${companyId}/locationtags/${tagId}`, await actions.getAxiosConfig())
    .then((response) => {
      dispatch(actions.receiveData(response.data, types.DELETE_LOCATION_TAG));
      dispatch(selectionActions.clearSelection());
      dispatch(getLocationTags());
    })
    .catch((error) => {
      if (error.response && error.response.status === 401) {
        actions.sessionTokenExpired(cookiePrefix, accessToken);
      } else {
        dispatch(actions.receiveError(error, types.DELETE_LOCATION_TAG));
      }
    });
};
