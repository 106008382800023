import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Table from "../../components/Table";
import * as filterActions from "../../actions/filters";

class FilterList extends Component {

  constructor(props) {
    super(props);
    this.state = {
      searchText: undefined,
      sortBy: "name",
      sortOrder: "asc",
      offset: 0,
      limit: 20,
      highlightedId: undefined
    };
    this.fetchData = this.fetchData.bind(this);
    this.onRowClick = this.onRowClick.bind(this);
    this.onHover = this.onHover.bind(this);
    this.onToggle = this.onToggle.bind(this);
    this.onSortedChange = this.onSortedChange.bind(this);
    this.onOffsetChange = this.onOffsetChange.bind(this);
  }

  componentDidMount() {
    this.fetchData();
  }

  onRowClick(column, rowInfo) {
    return {
      onClick: e => {
        // console.log(rowInfo);
        if (rowInfo && column.name !== "isSelected") {
          let link = `/companies/${this.props.match.params.companyId}/filters/${rowInfo.original.id}`;

          if (e.metaKey || e.ctrlKey) {
            window.open(`${link}`);
          }
          else {
            this.props.history.push(link);
          }
        }
      },
      onMouseOver: e => {
        if (rowInfo && rowInfo.original.id) {
          this.onHover(rowInfo.original.id);
        }
      },
      onMouseLeave: e => this.onHover(null),
      style: {
        cursor: "pointer",
        background: (
          rowInfo && rowInfo.original.id === this.state.highlightedId ? "rgba(0,0,0,0.05)" : (rowInfo && rowInfo.original.id === this.props.match.params.id ? "rgba(0,0,0,0.05)" : null))
      }
    }
  }

  onHover(id) {
    if (this.state.highlightedId !== id) {
      this.setState({ highlightedId: id });
    }
  }

  onToggle(row) {
    if (this.props.selectedFilters[row.original.id] !== undefined && this.props.selectedFilters[row.original.id]) {
      this.props.deselectFilter(row.original.id);
    }
    else {
      this.props.selectFilter(row.original);
    }
  }

  onDeleteFilters() {
    const selectedFilters = Object.keys(this.props.selectedFilters).filter(id => this.props.selectedFilters[id]);
    if (selectedFilters.length > 0) {
      this.props.deleteFilters(selectedFilters);
    }
  }

  onSortedChange(newSorted) {
    if (newSorted.length > 0) {
      this.setState({
        sortBy: newSorted[0].id,
        sortOrder: newSorted[0].desc ? "desc" : "asc"
      }, () => this.fetchData());
    }
  }

  onOffsetChange(offset) {
    this.setState({ offset }, () => this.fetchData());
  }

  fetchData() {
    const queryParams = {
      search: this.state.searchText,
      sortBy: this.state.sortBy,
      sortOrder: this.state.sortOrder,
      offset: this.state.offset,
      limit: this.state.limit
    };

    this.props.getFilters(queryParams);
  }

  render() {
    const { isLoading } = this.props;

    return (
      <div>
        { getFiltersTable(this.props.data, isLoading, this.state.sortBy, this.state.sortOrder, this.state.offset, this.state.limit, this.onSortedChange, this.onOffsetChange, this.onRowClick) }
        <div style={{ paddingTop: "40px" }} />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    data: state.filters.data,
    isLoading: state.loading.filters,
    selectedFilters: state.filters.selectedFilters,
    selectedCompany: state.auth.selectedCompany,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    getFilters: filterActions.getFilters,
    deleteFilters: filterActions.deleteFilters,
    selectFilter: filterActions.selectFilter,
    deselectFilter: filterActions.deselectFilter,
    clearAllSelectFilters: filterActions.clearAllSelectFilters,
    highlightFilter: filterActions.highlightFilter
   }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(FilterList);

function getFiltersTable(data, isLoading, sortBy, sortOrder, offset, limit, onSortedChange, onOffsetChange, onRowClick) {
  return (
    <Table
      data={data.results}
      sortBy={sortBy}
      sortOrder={sortOrder}
      offset={offset}
      limit={limit}
      count={data.count}
      onSortedChange={onSortedChange}
      onOffsetChange={onOffsetChange}
      noDataText={isLoading ? "" : "No filters found"}
      columns={[
        {
          header: "Name",
          accessorKey: "name",
          cell: ({ row }) => (<span title={row.original.name}>{row.original.name}</span>)
        },
        {
          id: "arrow",
          header: "",
          accessorKey: 'properties',
          sortable: false,
          className: "pull-right",
          width: 60,
          cell: () => (<div className="arrow" />)
        }
      ]}
      getTdProps={onRowClick}
      className="-row-clickable setMaxHeigth -highlight"
      loading={isLoading}
    />);
}