import React from "react";
import { TreeNode } from "./TreeNode";
import { NodeModel } from "./NodeModel";
import { isEqual } from "lodash"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretSquareUp as collapseIcon } from '@fortawesome/free-regular-svg-icons';
import { Checkbox } from "./Checkbox";
import style from "./style.module.scss";

export class CheckboxTree extends React.Component {
  static defaultProps = {
    nodes: [],
    reset: false,
    userSelectedNodes: [],
    userDeselectedNodes: [],
    unassignedFlag: undefined
  }

  constructor(props) {
    super(props);

    const model = new NodeModel();
    model.flattenNodes(props.nodes);

    this.state = {
      model,
      prevProps: props,

      expanded: [],
      userSelectedNodes: props.userSelectedNodes,
      userDeselectedNodes: props.userDeselectedNodes
    }

    this._toggleExpand = this._toggleExpand.bind(this);
    this._toggleChecked = this._toggleChecked.bind(this);
    this._collapseAll = this._collapseAll.bind(this);
  }

  static getDerivedStateFromProps(newProps, prevState) {
    const { model, prevProps } = prevState;
    const { nodes } = newProps;
    let newState = { ...prevState, prevProps: newProps };

    if (!isEqual(prevProps.nodes, nodes)) {
      model.reset();
      model.flattenNodes(nodes);
    }

    if(newProps.reset) {
      model.reset();
      model.flattenNodes(nodes);
    }

    return newState;
  }

  componentDidUpdate(prevProps, prevState) {
    const { userSelectedNodes, userDeselectedNodes } = this.state;
    const { handleUpdatesToFilter } = this.props;
    
    if(
      prevState.userSelectedNodes !== userSelectedNodes ||
      prevState.userDeselectedNodes !== userDeselectedNodes
    ) {
      handleUpdatesToFilter({
        selectedLocations: userSelectedNodes,
        deselectedLocations: userDeselectedNodes
      })
    }
  }

  _renderTreeNodes(nodes, parent = {}) {
    const { userSelectedNodes, userDeselectedNodes } = this.state;
    const { countByLocation } = this.props;

    const treeNodes = nodes.map(node => {
      const key = node.id;
      const flatNode = this.state.model.getNode(node.id);
      const children = flatNode.isParent ? this._renderTreeNodes(node.children, node) : null;

      if(userSelectedNodes.includes(node.id)) {
        flatNode.checked = true;
        flatNode.userChecked = true;

        const setAllChildrenNodesAsChecked = (child) => {
          const flatNode = this.state.model.getNode(child.id);
          flatNode.checked = true;
          
          if(child.children.length > 0) {
            child.children.forEach(child => setAllChildrenNodesAsChecked(child))
          }
        }

        if(flatNode.children.length > 0) {
          flatNode.children.forEach((child) => {
            setAllChildrenNodesAsChecked(child);
          });
        }
      }

      if(userDeselectedNodes.includes(node.id)) {
        flatNode.checked = false;
        flatNode.userUnchecked = true;
      }

      if(flatNode && flatNode.isParent) {
        if(!flatNode.checked) {
          flatNode.isIntermediate = false;
        }
        if(!flatNode.isChecked && flatNode.isIntermediate) {
          flatNode.isIntermediate = false;
        }

        for(const child of flatNode.children) {
          const childNode = this.state.model.getNode(child.id);

          if(childNode.checked && !flatNode.checked) {
            flatNode.isIntermediate = true;
          }
          else if (childNode.isIntermediate && !flatNode.checked) {
            flatNode.isIntermediate = true;
          }
        }
      }

      return (
        <TreeNode
          key={key}
          id={node.id}
          name={node.name}
          isParent={flatNode.isParent}
          expanded={flatNode.expanded}
          toggleExpand={this._toggleExpand}
          checked={flatNode.checked}
          toggleChecked={this._toggleChecked}
          isLeaf={flatNode.isLeaf}
          treeDepth={flatNode.treeDepth}
          countByLocation={countByLocation}
          isIntermediate={flatNode.isIntermediate}
        >
          {children} 
        </TreeNode>
      );
    });

    return (
      <ol className={style.treeNodes}>
        {treeNodes}
      </ol>
    )
  }

  _toggleExpand(id, toggleValue) {
    this.state.model.toggleExpand(id, 'expanded', toggleValue);

    this.setState(prevState => ({
      ...prevState,
      expanded: this.state.model.serializeList('expanded')
    }));
  }

  _toggleChecked(id, key, toggleValue) {
    this.state.model.toggleChecked(id, key, toggleValue);

    this.setState(prevState => ({
      ...prevState,
      userSelectedNodes: this.state.model.serializeList('userChecked'),
      userDeselectedNodes: this.state.model.serializeList('userUnchecked'),
    }));
  }

  _collapseAll() {
    this.state.model.deserializeLists({
      expanded: false,
    });

    this.setState(prevState => ({
      ...prevState,
      expanded: this.state.model.serializeList('expanded')
    }));
  }

  render () {
    const { userSelectedNodes, userDeselectedNodes } = this.state;
    const { 
      nodes,
      unassignedFlag,
      handleUpdatesToFilter, 
      countByLocation, 
      unassignedEntryLabel, 
      stringToFetchUnassigned,
      unassignedLabel
    } = this.props;
    const isLocationSensorsChecked = userSelectedNodes.length > 0 || userDeselectedNodes.length > 0;
    const treeNodes = this._renderTreeNodes(nodes);

    return (
      <>
        <span style={{ fontSize: "20px", cursor: "pointer", marginBottom: "5px" }}>
          <FontAwesomeIcon onClick={this._collapseAll} icon={collapseIcon} className="fa-rotate-180"/>
          <span style={{ marginLeft: "5px" }}>
            Collapse
          </span>
        </span>

        {treeNodes}

        <div>
          <hr/>

          <span onClick={() => {
            if(!isLocationSensorsChecked) {
              handleUpdatesToFilter({ [`${unassignedLabel}`]: !unassignedFlag })
            }
          }} className={style.treeNodeCheckbox}>
            <Checkbox 
              name={unassignedEntryLabel} 
              checked={isLocationSensorsChecked ? undefined : unassignedFlag}
              onChange={() => {}}
              isIntermediate={false}
              styles={
                isLocationSensorsChecked ? { color: "grey", cursor: "not-allowed" } : null
              }
            />
          </span>

          <span style={
            isLocationSensorsChecked ? { color: "grey" } : null
          }>
            <span className={style.locationLabel} style={{cursor: "default"}}>
              {unassignedEntryLabel} ({countByLocation && countByLocation[stringToFetchUnassigned] ? countByLocation[stringToFetchUnassigned] : 0})
            </span>
          </span>
        </div>
      </>
    )
  }
}
