import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Hidden, Visible } from "react-grid-system";
import isEmpty from "lodash/isEmpty";
import get from "lodash/get";
import ScreenList from "./screenList";
import ScreenDetails from "./screenDetails";
import OptionFooter from "../../components/OptionFooter";
import * as screenActions from "../../actions/screens";
import style from "./style.module.scss";

class ScreensContainer extends Component {

  constructor(props) {
    super(props);
    this.onSaveScreen = this.onSaveScreen.bind(this);
    this.onCancelScreen = this.onCancelScreen.bind(this);
  }
  
  componentDidMount() {
    document.title = `BLDNG.ai - Screens`;
  }

  screenHasChanged() {

    if (isEmpty(this.props.screenForm)) {
      return false;
    }

    const descriptionChanged = get(this.props.screenForm, "description", "") !== get(this.props.screen, "description", "");

    return descriptionChanged;
  }

  screenIsValid() {
    return !this.props.isScreenLoading;
  }

  onSaveScreen() {
    const { push } = this.props.history;
    
    // Copy form to make changes for upload
    const form = JSON.parse(JSON.stringify(this.props.screenForm));
    delete form.id;

    this.props.updateScreen(this.props.screen.id, form, push);
  }

  onCancelScreen() {
    this.props.resetScreenForm();
    this.props.history.push(`/companies/${this.props.match.params.companyId}/screens`);
  }

  render() {
    const hasSelection = !isEmpty(this.props.selectedLocations) || !isEmpty(this.props.selectedUserGroups) || !isEmpty(this.props.selectedContent);

    let optionFooter = null; 
    if (this.screenHasChanged()) {
      optionFooter = (
        <OptionFooter inline={true} cancel={this.onCancelScreen} options={[{ label: "Save", callback: this.onSaveScreen, disabled: !this.screenIsValid() }]} />
      );
    }

    return (
      <>
        <Hidden xs sm md>
          <div className={ (hasSelection || optionFooter) ? style.mainContainer : style.mainContainerHidden }>
            <div className={style.row}>
              <div className={style.listContainer}>
                <div className={style.scroll}>
                  <ScreenList { ...this.props} />
                </div>
              </div>
              <div className={style.sideBar}>
                <div className={style.scroll}>
                  <ScreenDetails { ...this.props} />
                </div>
              </div>
            </div>
          </div>
        </Hidden>
        <Visible xs sm md>
          <div className={ (hasSelection || optionFooter) ? style.mainContainer : style.mainContainerHidden } style={{ backgroundColor: !isEmpty(this.props.screenGroupId) && "#F6F6F6" }}>
            <div className={style.slimScroll}>
              {
                isEmpty(this.props.screenGroupId) ? (<ScreenList { ...this.props} />) : (<ScreenDetails { ...this.props} />)
              }
            </div>
          </div>
        </Visible>

        { optionFooter }
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    isScreenLoading: state.loading.screens,
    screen: state.screen,
    screenForm: state.screen.form,
    selectedLocations: state.selected.locations,
    selectedUserGroups: state.selected.userGroups,
    selectedContent: state.selected.content
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    updateScreen: screenActions.updateScreenGroup,
    resetScreenForm: screenActions.resetScreenGroupForm,
    clearScreenData: screenActions.clearScreenData
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(ScreensContainer);