import React from "react";
import { Col, Row } from "react-grid-system";
import Button from "../Button";
import TextBox from "../TextBox";
import InputBox from "../InputBox";
import style from "./style.module.scss";

const NotificationModal = ({ show, onHide, title, text, primaryBtn, secondaryBtn, titleLabel, titleValue, titleValid, onTitleChange, messageLabel, messageValue, messageValid, onMessageChange }) => (
  show && (
    <div className={style.wrapper}>
      <div className={style.overlay} onClick={onHide} role="presentation" />
      <div className={style.modal}>
        <h1 className={style.title}>{title}</h1>
        <p className={style.text}>{text}</p>
        <Row>
          <Col sm={12} md={12} lg={12}>
            <InputBox onChange={onTitleChange} label={titleLabel} value={titleValue} valid={titleValid} required showValidIcon />
          </Col>
        </Row>
        <Row>
          <Col sm={12} md={12} lg={12}>
            <TextBox onChange={onMessageChange} label={messageLabel} value={messageValue} valid={messageValid} required />
          </Col>
        </Row>
        <div style={{ paddingTop: "20px" }} />
        <Row>
          <Col xs={12} sm={8} md={6} lg={6} offset={{ sm: 2, md: 0 }}>
            {secondaryBtn && <Button color="white" {...secondaryBtn} />}
          </Col>
          <Col xs={12} sm={8} md={6} lg={6} offset={{ sm: 2, md: 0 }}>
            {primaryBtn && <Button {...primaryBtn} disabled={!(titleValid && messageValid)}/>}
          </Col>
        </Row>
      </div>
    </div>
  )
);

export default NotificationModal;
