import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { get, isEmpty } from "lodash";
import Loader from "../../components/Loader";
import MapboxMap from "../Map/MapboxMap";
import Freetext from "../../components/Freetext";
import Table from "../../components/Table";
import Button from "../../components/Button";
import InputGroup from "../../components/InputGroup";
import OptionFooter from "../../components/OptionFooter";
import * as authActions from "../../actions/auth";
import * as locationActions from "../../actions/locations";
import * as sensorActions from "../../actions/sensors";
import * as selectedActions from "../../actions/selected";
import * as API from "../../ApiTypes";
import style from "./style.module.scss";

class NewYanziSensor extends Component {

  constructor(props) {
    super(props);
    this.state = {
      description: "",
      sensorId: "",
      id: props.match.params.id,
      selectedDiscoveredSensorId: null,
      gateways: [],
      selectedGatewayId: null,
      hasLoadedState: false
    };

    this.onBackClick = this.onBackClick.bind(this);
    this.onGatewayToggle = this.onGatewayToggle.bind(this);
    this.onDescriptionChange = this.onDescriptionChange.bind(this);
    this.onSensorIdChange = this.onSensorIdChange.bind(this);
    this.onSaveWithMap = this.onSaveWithMap.bind(this);
    this.onSaveWithoutMap = this.onSaveWithoutMap.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.onDiscoverSensors = this.onDiscoverSensors.bind(this);
    this.onDiscoveredSensorToggle = this.onDiscoveredSensorToggle.bind(this);

    if (props.match.params.id !== props.location.id) {
      props.getLocation(props.match.params.id);
      props.getFloorMap(props.match.params.id);
      // props.getCompanyMap();
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {

    if (prevState.hasLoadedState) {
      return null;
    }

    // Only copy props if they exist and if location id is correct
    if (!isEmpty(nextProps.location) && nextProps.match.params.id === nextProps.location.id) {

      const newState = {
        hasLoadedState: true
      };

      const gateways = nextProps.location.gateways.concat(nextProps.location.closestAncestorGateways);

      // Add selected gateways that are not in location.gateways or location.closestAncestorGateways
      const selectedGatewayIds = Object.keys(nextProps.selectedGateways);
      selectedGatewayIds.forEach(selectedGatewayId => {
        if (!gateways.find(gateway => (gateway.id === selectedGatewayId))) {
          gateways.push(nextProps.selectedGateways[selectedGatewayId]);
        }
      });

      newState.gateways = gateways;

      if (gateways.length === 1) {
        newState.selectedGatewayId = gateways[0].id
      }
      
      return newState;
    }

    return null;
  }

  onBackClick(id) {
    if (get(this.props.location, "state.canGoBack", false)) {
      this.props.history.goBack();
    }
    else {
      this.props.history.push(`/companies/${this.props.match.params.companyId}/locations/${id}/sensors`);
    }
  }

  onDescriptionChange(event) {
    this.setState({ description: event.target.value });
  }

  onSensorIdChange(event) {
    let sensorIdValue = event.target.value;
    sensorIdValue = sensorIdValue.replace("EUI64+","EUI64-");
    this.setState({ sensorId: sensorIdValue });
  }

  onGatewayToggle(row) {
    if (this.state.selectedGatewayId === row.original.id) {
      this.setState({ selectedDiscoveredMeshNodeId: null, selectedGatewayId: null });
    }
    else {
      this.setState({ selectedDiscoveredMeshNodeId: null, selectedGatewayId: row.original.id });
    }
  }

  onDiscoveredSensorToggle(row) {
    if (this.state.selectedDiscoveredSensorId === row.original.sensorDid) {
      this.setState({ selectedDiscoveredSensorId: null });
    }
    else {
      this.setState({ selectedDiscoveredSensorId: row.original.sensorDid });
    }
  }

  onDiscoverSensors() {
    if (!isEmpty(this.state.selectedGatewayId)) {
      this.props.getDiscoveredSensors(this.state.selectedGatewayId);
    }
  }

  onSaveWithMap() {
    const { push } = this.props.history;

    const body = {
      sensorDid: this.state.selectedDiscoveredSensorId || this.state.sensorId,
      locationIds: [this.props.location.id],
      gatewayId: this.state.selectedGatewayId
    };

    if (!isEmpty(this.state.description)) {
      body.description = this.state.description;
    }

    if (this.props.createdFeature) {
      body.geoJsonFeature = this.props.createdFeature;
    }

    this.props.createSensor("yanzi", body, this.props.location.id, push);
  }

  onSaveWithoutMap() {
    const { push } = this.props.history;

    const body = {
      sensorDid: this.state.selectedDiscoveredSensorId || this.state.sensorId,
      locationIds: [this.props.location.id],
      gatewayId: this.state.selectedGatewayId
    };

    if (!isEmpty(this.state.description)) {
      body.description = this.state.description;
    }

    this.props.createSensor("yanzi", body, this.props.location.id, push);
  }

  onCancel() {
    this.onBackClick(this.state.id);
  }

  render() {
    if (this.props.isLoading) {
      return <Loader fullScreen />;
    }

    let mapElement;
    if (this.props.location.floorMap) {
      mapElement = (
        <div className={style.mapPart}>
          <MapboxMap 
            mapId="new-sensor-map-container" 
            id={this.state.id} 
            company={this.props.company} 
            location={this.props.location} 
            map={this.props.location.floorMap} 
            drawMode="draw_point"
            showSensors
            showGateways
            drawSensor
            showMap
          />
          <div className={style.mapHelp}>
            <span>Sensors are </span>
            <span style={{ color: "#0080FF" }}>blue</span>
            <span>. Gateways are </span>
            <span style={{ color: "#82612F" }}>brown</span>
            <span>.</span>
          </div>
        </div>
      );
    }
    else if (this.props.companyMap) {
      mapElement = (
        <div className={style.mapPart}>
          <MapboxMap 
            mapId="new-sensor-map-container" 
            id={this.state.id} 
            company={this.props.company} 
            location={this.props.location} 
            map={this.props.companyMap} 
            drawMode="draw_point"
            showSensors
            showGateways
            drawSensor
            showMap
          />
          <div className={style.mapHelp}>
            <span>Sensors are </span>
            <span style={{ color: "#0080FF" }}>blue</span>
            <span>. Gateways are </span>
            <span style={{ color: "#82612F" }}>brown</span>
            <span>.</span>
          </div>
        </div>
      );
    }

    const TheadComponent = props => null;

    // Build discovered sensors element
    let discoveredSensors;
    if (!isEmpty(this.state.selectedGatewayId)) {
      if (!this.props.isDiscoveryLoading && (!this.props.discoveredSensors || this.props.discoveredSensorsGatewayId !== this.state.selectedGatewayId)) {
        discoveredSensors = (
          <div style={{ paddingTop: "40px" }}>
            <Button text="Discover sensors" onClick={this.onDiscoverSensors} />
          </div>
        );
      }
      else {
        discoveredSensors = (
          <>
            <h3 style={{ marginTop: "60px", marginBottom: 0 }}>Select one discovered sensors</h3>
            <Table
              data={this.props.discoveredSensors || []}
              columns={[
                {
                  accessorKey: "sensorDid",
                  name: "isSelected",
                  cell: ({ row }) => (
                    <label className="checkboxContainer" htmlFor={`editCheckbox-${row.original.sensorDid}`}>
                      <input
                        id={`editCheckbox-${row.original.sensorDid}`}
                        type="checkbox"
                        className="checkbox"
                        checked={this.state.selectedDiscoveredSensorId === row.original.sensorDid}
                        onChange={() => this.onDiscoveredSensorToggle(row)}
                      />
                      <span className="checkmark" />
                    </label>
                  ),
                  width: 60
                },
                {
                  accessorKey: "sensorDid",
                  cell: ({ row }) => (<span title={row.original.sensorDid}>{row.original.sensorDid}</span>),
                  minWidth: 125
                }
              ]}
              className="setMaxHeigth"
              loading={this.props.isDiscoveryLoading}
            />
          </>
        );
      }
    }

    // Options for saving
    const options = [];
    if (isEmpty(this.props.createdFeature)) {
      options.push({ 
        label: "Save without map", 
        callback: this.onSaveWithoutMap, 
        disabled: (!(this.state.selectedDiscoveredSensorId || this.state.sensorId.length > 0) || isEmpty(this.state.selectedGatewayId)) 
      });
    }
    else {
      options.push({ 
        label: "Save with map", 
        callback: this.onSaveWithMap, 
        disabled: (!(this.state.selectedDiscoveredSensorId || this.state.sensorId.length > 0) || isEmpty(this.state.selectedGatewayId))
      });
    }
    
    return (
      <div className={style.editContainer}>
        <div className={style.row}>
          <div className={style.col33}>
            <div className={style.scroll}>
              <div className={style.topRow}>
                <Freetext header={`Add sensor to ${this.props.location.name}`} />
              </div>
              <h3 style={{ marginBottom: 0 }}>1. Select one gateway</h3>
              <Table
                data={this.state.gateways}
                TheadComponent={TheadComponent}
                columns={[
                  {
                    accessorKey: "id",
                    name: "isSelected",
                    cell: ({ row }) => (
                      <label className="checkboxContainer" htmlFor={`editCheckbox-${row.original.id}`}>
                        <input
                          id={`editCheckbox-${row.original.id}`}
                          type="checkbox"
                          className="checkbox"
                          checked={this.state.selectedGatewayId === row.original.id}
                          onChange={() => this.onGatewayToggle(row)}
                        />
                        <span className="checkmark" />
                      </label>
                    ),
                    width: 60
                  },
                  {
                    accessorKey: "name",
                    cell: ({ row }) => (<span title={row.original.name}>{row.original.name}</span>)
                  }
                ]}
                className="setMaxHeigth"
              />
              { discoveredSensors }
            </div>
          </div>
          <div className={style.col33}>
            <div className={style.scroll}>
              <h3>2. Enter ID and name for sensor</h3>
              <InputGroup autoFocus onChange={this.onSensorIdChange} label="Sensor ID" placeholder="e.g. EUI64-0080E1030005DADE" value={this.state.selectedDiscoveredSensorId || this.state.sensorId} valid={this.state.selectedDiscoveredSensorId || this.state.sensorId} required />
              <InputGroup onChange={this.onDescriptionChange} label="Description (optional)" placeholder="e.g. Comfort Workplace 4" value={this.state.description} />
            </div>
          </div>
          <div className={style.col33}>
            <h3 className={style.mapHeader}>3. Place sensor in the map</h3>
            { mapElement }
          </div>
        </div>
        <OptionFooter 
          cancel={this.onCancel} 
          cancelButtonLabel="Cancel"
          options={options} 
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    location: state.location,
    sensor: state.sensor,
    isLoading: state.loading.sensors,
    discoveredSensors: state.sensors.discoveredSensors,
    discoveredSensorsGatewayId: state.sensors.discoveredSensorsGatewayId,
    isDiscoveryLoading: state.loading[API.GET_DISCOVERED_SENSORS],
    company: state.auth.selectedCompany,
    selectedGateways: state.selected.gateways,
    createdFeature: state.selected.createdFeature
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    getLocation: locationActions.getLocation,
    getDiscoveredSensors: sensorActions.getDiscoveredSensors,
    selectGateway: selectedActions.selectGateway,
    deselectGateway: selectedActions.deselectGateway,
    clearSelection: selectedActions.clearSelection,
    createSensor: sensorActions.createSensor,
    getFloorMap: locationActions.getFloorMap,
    getCompanyMap: authActions.getCompanyMap
  }, dispatch);
}

export default connect(mapStateToProps,mapDispatchToProps)(NewYanziSensor);
