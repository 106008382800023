import React from "react";
import { Col, Row } from "react-grid-system";
import { ControlledDropdownSelection } from "../../components/DropdownSelection";
import { get, isEmpty } from "lodash";
import InputBox from "../../components/InputBox";
import TextBox from "../../components/TextBox";
import MarkdownTextBox from "../../components/MarkdownTextBox";
import Button from "../../components/Button";
import SmallButton from "../../components/SmallButton";

export const inputElements = (row, saveRow, updateRow, actionOptions, language, copyTextArea, onUploadImageClick, onUploadImage, inputOpenImageRef) => {
  // console.log("inputElements row", row);
  let elements = [];

  const type = (
    <Row key="type">
      <Col>
        <ControlledDropdownSelection
          label="Type"
          value={row.type}
          onChange={(event) => saveRow({ type: event.target.value })}
          options={[
            { id: "text", name: "text" },
            { id: "text-area", name: "text-area" },
            { id: "image", name: "image" },
            { id: "sensor-data", name: "sensor-data" }
          ]}
        />
      </Col>
    </Row>
  );

  elements.push(type);

  if (row.type === "text") {
    elements.push(
      <Row key="title">
        <Col lg={12} xl={8}>
          <InputBox
            label="Title"
            value={row.title ?? ""}
            onChange={(event) => updateRow({ title: event.target.value })}
            info="required"
          />
        </Col>
      </Row>
    );
    elements.push(
      <Row key="text">
        <Col lg={12} xl={8}>
          <InputBox
            label="Text"
            value={row.text ?? ""}
            onChange={(event) => updateRow({ text: event.target.value })}
            info="optional"
          />
        </Col>
      </Row>
    );
    elements.push(
      <Row key="turl">
        <Col>
          <InputBox
            label="Thumbnail URL"
            value={row.thumbnailUrl ?? ""}
            onChange={(event) => updateRow({ thumbnailUrl: event.target.value })}
            info="optional"
            help={`Include the full URL (including "https://") and make sure the URL is publicly accessible.`}
          />
        </Col>
      </Row>
    );
    elements.push(
      <Row key="tsize">
        <Col>
          <ControlledDropdownSelection
            label="Thumbnail size"
            value={isEmpty(row.thumbnailSize) ? "system" : row.thumbnailSize}
            onChange={(event) => updateRow({ thumbnailSize: event.target.value })}
            options={[
              { id: "system", name: "Default" },
              { id: "large", name: "Large" },
            ]}
          />
        </Col>
      </Row>
    );
    elements.push(
      <Row key="click">
        <Col>
          <ControlledDropdownSelection
            label="Click action"
            value={get(row, "onClick.type", "")}
            onChange={(event) => {
              if (isEmpty(event.target.value)) {
                updateRow({ onClick: null });
              }
              else {
                updateRow({ onClick: { type: event.target.value } })
              }
            }}
            options={actionOptions}
            help="A click action is web page or subview that appears when the user clicks on the row in the app."
          />
        </Col>
      </Row>
    );
  }

  if (row.type === "text-area") {
    elements.push(
      <>
      <Row key="text">
        <Col>
          <MarkdownTextBox
            label="Text"
            value={row.text ?? ""}
            onChange={(content) => updateRow({ text: content ?? "" })}
            // info="required"
            help={`Pressing enter adds a new paragraph. Shift-enter adds a single new line. NB! Copy/paste can cause problems!`}
          />
        </Col>
      </Row>
      {
        language === "en" ? (
          <Row key="button" align="end" justify="end" nogutter>
            <Button text="Duplicate text from Norwegian" slim onClick={copyTextArea} paddingTop="15px" />
          </Row>
        ) : (
          <Row key="button" align="end" justify="end" nogutter>
            <Button text="Duplicate text from English" slim onClick={copyTextArea} paddingTop="15px" />
          </Row>
        )
      }
      </>
    );
  }

  if (row.type === "image") {
    elements.push(
      <Row key="iurl">
        <Col xs={12} md={9}>
          <InputBox
            label="Image URL"
            value={row.imageUrl ?? ""}
            onChange={(event) => updateRow({ imageUrl: event.target.value })}
            info="required"
            help={`Include the full URL (including "https://") and make sure the URL is publicly accessible.`}
          />
        </Col>
        <Col xs={12} md={3}>
          <SmallButton text="Upload" onClick={onUploadImageClick} noLeftMargin style={{ marginTop: "43px"}} />
        </Col>
        <label htmlFor="imageInput">
          <input ref={inputOpenImageRef} name="file" id="imageInput" type="file" onChange={onUploadImage} style={{ display: "none" }} />
        </label>
      </Row>
    );
    elements.push(
      <Row key="scale">
        <Col lg={12} xl={4} key="scale1">
          <InputBox
            label="Aspect ratio"
            value={row.aspectRatio ?? ""}
            onChange={(event) => updateRow({ aspectRatio: event.target.value })}
            info="optional"
            help="The aspect ratio (ex. 4:3) describes how to present the image. Anything outside this ratio will be cropped or padded. Display the full image by entering the exact size (ex. 1920:1080)."
          />
        </Col>
        <Col key="scale2">
          <ControlledDropdownSelection
            label="Scaling mode"
            value={isEmpty(row.scalingMode) ? "fill" : row.scalingMode}
            onChange={(event) => updateRow({ scalingMode: event.target.value })}
            options={[
              { id: "fill", name: "Crop image" },
              { id: "fit", name: "Pad image" }
            ]}
            help="Crop removes parts of the image, while padding adds a white border, if the aspect ratio is different than the image size."
          />
        </Col>
      </Row>
    );
    elements.push(
      <Row key="text">
        <Col>
          <TextBox
            label="Text"
            value={row.text ?? ""}
            onChange={(event) => updateRow({ text: event.target.value })}
            info="optional"
            help="This text will overlay the image. Be careful about putting a dark text on a dark image or a light text on a light image."
            hasDarkBackground
          />
        </Col>
      </Row>
    );
    elements.push(
      <Row key="opt">
        <div style={{ paddingLeft: "10px", paddingRight: "10px" }} key="opt1">
          <ControlledDropdownSelection
            label="Text size"
            value={isEmpty(row.textSize) ? "medium" : row.textSize}
            onChange={(event) => updateRow({ textSize: event.target.value })}
            options={[
              { id: "small", name: "Small" },
              { id: "medium", name: "Medium" },
              { id: "large", name: "Large" },
            ]}
          />
        </div>
        <div style={{ paddingLeft: "10px", paddingRight: "10px" }} key="opt2">
          <ControlledDropdownSelection
            label="Text color"
            value={isEmpty(row.textColor) ? "black" : row.textColor}
            onChange={(event) => updateRow({ textColor: event.target.value })}
            options={[
              { id: "black", name: "Black" },
              { id: "white", name: "White" }
            ]}
          />
        </div>
        <div style={{ paddingLeft: "10px", paddingRight: "10px" }} key="opt3">
          <ControlledDropdownSelection
            label="Horizontal text alignment"
            value={isEmpty(row.horizontalTextAlignment) ? "left" : row.horizontalTextAlignment}
            onChange={(event) => updateRow({ horizontalTextAlignment: event.target.value })}
            options={[
              { id: "left", name: "Left" },
              { id: "center", name: "Center" },
              { id: "right", name: "Right" }
            ]}
          />
        </div>
        <div style={{ paddingLeft: "10px", paddingRight: "10px" }} key="opt4">
          <ControlledDropdownSelection
            label="Vertical text alignment"
            value={isEmpty(row.verticalTextAlignment) ? "center" : row.verticalTextAlignment}
            onChange={(event) => updateRow({ verticalTextAlignment: event.target.value })}
            options={[
              { id: "top", name: "Top" },
              { id: "center", name: "Center" },
              { id: "bottom", name: "Bottom" }
            ]}
          />
        </div>
      </Row>
    );
    elements.push(
      <Row key="click">
        <Col>
          <ControlledDropdownSelection
            label="Click action"
            value={get(row, "onClick.type", "")}
            onChange={(event) => {
              if (isEmpty(event.target.value)) {
                updateRow({ onClick: null });
              }
              else {
                updateRow({ onClick: { type: event.target.value } })
              }
            }}
            options={actionOptions}
            help="A click action is web page or subview that appears when the user clicks on the row in the app."
          />
        </Col>
      </Row>
    );
  }

  if (row.type === "sensor-data") {
    elements.push(
      <Row key="title">
        <Col lg={12} xl={8}>
          <InputBox
            label="Title"
            value={row.title ?? ""}
            onChange={(event) => updateRow({ title: event.target.value })}
            info="optional"
          />
        </Col>
      </Row>
    );
    elements.push(
      <Row key="locationId">
        <Col lg={12} xl={6}>
          <InputBox
            label="Location ID"
            value={row.locationId ?? ""}
            onChange={(event) => updateRow({ locationId: event.target.value })}
            info="required"
            required
            help={`Find the location id in the URL when you navigate to a location. Ex. 5053221a9b7ac700069c47a8`}
          />
        </Col>
      </Row>
    );
    elements.push(
      <Row key="propertyType">
        <Col lg={12} xl={6}>
          <InputBox
            label="Property type"
            value={row.propertyType ?? ""}
            onChange={(event) => updateRow({ propertyType: event.target.value })}
            info="required"
            required
            help={`The property type is the type of sensor data you want to show. Ex. temperature, humidity, peopleCount, etc.`}
          />
        </Col>
      </Row>
    );
    elements.push(
      <Row key="showSensorIcon">
        <div style={{ paddingLeft: "10px", paddingRight: "10px" }} key="opt1">
          <ControlledDropdownSelection
            label="Show sensor icon"
            value={isEmpty(row.useSensorIcon) ? false : row.useSensorIcon}
            onChange={(event) => updateRow({ useSensorIcon: event.target.value })}
            options={[
              { id: false, name: "false" },
              { id: true, name: "true" }
            ]}
            help={`If true, a icon that represent the sensor type will be shown on the left side.`}
          />
        </div>
        <div style={{ paddingLeft: "10px", paddingRight: "10px" }} key="opt3">
          <ControlledDropdownSelection
            label="Value presentation"
            value={isEmpty(row.valuePresentation) ? "raw" : row.valuePresentation}
            onChange={(event) => updateRow({ valuePresentation: event.target.value })}
            options={[
              { id: "raw", name: "Raw value" },
              { id: "text", name: "Text thresholds" },
              { id: "icon", name: "Icon thresholds" },
            ]}
            help={`The value presentation is how the sensor value is presented. Raw value is the actual value. Text thresholds is a text that describes the value (good/poor/bad). Icon thresholds is a icon that describes the value (smileys).`}
          />
        </div>
      </Row>
    );
    elements.push(
      <Row key="turl">
        <Col>
          <InputBox
            label="Thumbnail URL"
            value={row.thumbnailUrl ?? ""}
            onChange={(event) => updateRow({ thumbnailUrl: event.target.value })}
            info="optional"
            help={`Include the full URL (including "https://") and make sure the URL is publicly accessible. This is only used if show sensor icon set to false.`}
          />
        </Col>
      </Row>
    );
    elements.push(
      <Row key="tsize">
        <Col>
          <ControlledDropdownSelection
            label="Thumbnail size"
            value={isEmpty(row.thumbnailSize) ? "system" : row.thumbnailSize}
            onChange={(event) => updateRow({ thumbnailSize: event.target.value })}
            options={[
              { id: "system", name: "Default" },
              { id: "large", name: "Large" },
            ]}
          />
        </Col>
      </Row>
    );
    elements.push(
      <Row key="click">
        <Col>
          <ControlledDropdownSelection
            label="Click action"
            value={get(row, "onClick.type", "")}
            onChange={(event) => {
              if (isEmpty(event.target.value)) {
                updateRow({ onClick: null });
              }
              else {
                updateRow({ onClick: { type: event.target.value } })
              }
            }}
            options={actionOptions}
            help="A click action is web page or subview that appears when the user clicks on the row in the app."
          />
        </Col>
      </Row>
    );
  }

  if (get(row, "onClick.type", "") === "open-url") {
    const url = get(row, "onClick.url", "");
    elements.push(
      <Row key="wurl">
        <Col>
          <InputBox
            label="Website URL"
            value={url}
            onChange={(event) => { updateRow({ onClick: { type: "open-url", url: event.target.value } }) }}
            info="required"
            required
            valid={url.startsWith("https://") || url.startsWith("http://")}
            showValidIcon
            help={`Include the full URL (including "https://") and make sure the URL is publicly accessible.`}
          />
        </Col>
      </Row>
    );
  }

  if (get(row, "onClick.type", "") === "open-app") {
    const androidLink = get(row, "onClick.androidLink", {});
    const androidUrl = get(androidLink, "appUrl", "");
    const androidId = get(androidLink, "appId", "");
    
    const iOSLink = get(row, "onClick.iOSLink", {});
    const iOSUrl = get(iOSLink, "appUrl", "");
    const iOSId = get(iOSLink, "appId", "");

    elements.push(
      <Row key="androidUrl">
        <Col>
          <InputBox
            label="Android app URL"
            value={androidUrl}
            onChange={(event) => { updateRow({ onClick: { type: "open-app", ...row.onClick, androidLink: { ...androidLink, appUrl: event.target.value } } }) }}
            info="optional"
            help={`The app URL will be used to link directly to the app with URL Scheme.`}
          />
        </Col>
      </Row>
    );
    elements.push(
      <Row key="androidId">
        <Col lg={12} xl={6}>
          <InputBox
            label="Android package name"
            value={androidId}
            onChange={(event) => { updateRow({ onClick: { type: "open-app", ...row.onClick, androidLink: { ...androidLink, appId: event.target.value } } }) }}
            info="optional"
            help={`The package name will be used if the app URL is not defined, and will open Google Play.`}
          />
        </Col>
      </Row>
    );
    elements.push(
      <Row key="iosUrl">
        <Col>
          <InputBox
            label="iOS app URL"
            value={iOSUrl}
            onChange={(event) => { updateRow({ onClick: { type: "open-app", ...row.onClick, iOSLink: { ...iOSLink, appUrl: event.target.value } } }) }}
            info="optional"
            help={`The app URL will be used to link directly to the app with URL Scheme.`}
          />
        </Col>
      </Row>
    );
    elements.push(
      <Row key="iosId">
        <Col lg={12} xl={4}>
          <InputBox
            label="iOS App Store ID"
            value={iOSId}
            onChange={(event) => { updateRow({ onClick: { type: "open-app", ...row.onClick, iOSLink: { ...iOSLink, appId: event.target.value } } }) }}
            info="optional"
            help={`The App Store ID will be used if the app URL is not defined, and will open the App Store.`}
          />
        </Col>
      </Row>
    );
  }

  if (get(row, "onClick.type", "") === "goto-location") {
    const locationId = get(row, "onClick.id", "");
    elements.push(
      <Row key="gotoLocationId">
        <Col lg={12} xl={6}>
          <InputBox
            label="Location id"
            value={locationId}
            onChange={(event) => { updateRow({ onClick: { type: "goto-location", id: event.target.value } }) }}
            info="required"
            help={`Find the location id in the URL when you navigate to a location. Ex. 5053221a9b7ac700069c47a8`}
            required
            showValidIcon
            valid={locationId.length == 24}
          />
        </Col>
      </Row>
    );
  }

  return elements;
}