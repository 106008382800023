import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark, faEllipsis, faCopy } from "@fortawesome/free-solid-svg-icons";
import { Popup } from "ui";
import styled from "styled-components";
import strings from "../strings";

const FilterBlock = ({ index, selected, onClick, onDuplicate, onRemove, children }) => {

  const [isPopupVisible, setPopupVisible] = React.useState(false);
  const optionButtonRef = React.useRef(null);
  const popupRef = React.useRef(null);

  const hidePopup = (e) => {
    if (popupRef.current && !popupRef.current.contains(e.target)) {
      setPopupVisible(false);
    }
  };

  const onFilterOptionsClick = () => {
    setPopupVisible(!isPopupVisible);
  };

  const handleScroll = () => {
    setPopupVisible(false); // Hide the popup on scroll
  };

  React.useEffect(() => {
    if (isPopupVisible) {
      // Add event listener when the popup is shown
      document.addEventListener("mousedown", hidePopup);
      window.addEventListener("scroll", handleScroll, true); // Use capture mode
    }
  
    // Cleanup function to remove the event listeners
    return () => {
      document.removeEventListener("mousedown", hidePopup);
      window.removeEventListener("scroll", handleScroll, true);
    };
  }, [isPopupVisible]); // Dependency array includes isPopupVisible to re-run the effect when it changes
  
  // Get button rect
  let optionButtonRect = null;
  if (optionButtonRef.current) {
    optionButtonRect = optionButtonRef.current.getBoundingClientRect();
  }

  const content = (
    <Block selected={selected} onClick={onClick}>
      <FilterTitle>{`${strings.dataset.title} ${index + 1}`}</FilterTitle>
      <OptionsButton ref={optionButtonRef} onClick={onFilterOptionsClick}>
        <FontAwesomeIcon icon={faEllipsis} style={{ fontSize: "18px" }} />
        <Popup ref={popupRef} isVisible={isPopupVisible} sourceRect={optionButtonRect}>
          <PopupContent>
            { onDuplicate && (
              <PopupItem onClick={() => onDuplicate(index)}>
                <PopupItemIcon>
                  <FontAwesomeIcon icon={faCopy} color="#333" />
                </PopupItemIcon>
                <PopupItemText>Duplicate</PopupItemText>
              </PopupItem>
            )}
            { onRemove && (
              <PopupItem onClick={() => onRemove(index)}>
                <PopupItemIcon>
                  <FontAwesomeIcon icon={faXmark} color="#333" />
                </PopupItemIcon>
                <PopupItemText>Remove</PopupItemText>
              </PopupItem>
            )}
          </PopupContent>
        </Popup>
      </OptionsButton>
     { children }
    </Block>
  );

  return content;
}

export default FilterBlock;

const Block = styled.div`
  position: relative;
  background-color: rgb(255, 255, 255);
  border-radius: 6px;
  border-width: 1px;
  border-style: solid;
  border-color: rgb(221, 221, 221);
  margin-top: 20px;
  padding: 15px;
  font-size: 16px;
  font-weight: 400;
  z-index: ${props => props.selected ? 10 : 1};

  &:first-child {
    margin-top: 24px;
  }
`;

const FilterTitle = styled.div`
  font-size: 18px;
  font-weight: 600;
  color: #222222;
`;

const OptionsButton = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: 50px;
  height: 50px;
  cursor: pointer;
  z-index: 3;

  font-size: 20px;
  font-weight: 100;
  color: #333;

  display: flex;
  align-items: center;
  justify-content: center;
`;

const PopupContent = styled.div`
  background-color: white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

  border-radius: 6px;
  border-width: 1px;
  border-style: solid;
  border-color: rgb(221, 221, 221);

  overflow: hidden;
`;

const PopupItem = styled.div`
  display: flex;
  flex-direction: row;
  cursor: pointer;

  &:hover {
    background-color: #eee;
  }
`;

const PopupItemIcon = styled.div`
  display: inline-flex;
  width: 35px;
  height: 40px;
  align-items: center;
  justify-content: center;
`;

const PopupItemText = styled.div`
  display: inline-flex;
  height: 40px;
  line-height: 39px;
  margin-right: 10px;
  color: #333;
`;