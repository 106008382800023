import React, { Component } from "react";
import Button from "../Button";
import style from "./style.module.scss";

class OptionFooter extends Component {
  render() {
    return (
      <div className={style.footer} style={{ position: this.props.inline ? "relative" : "fixed" }}>
        <div className={style.footerContent}>
          <div className={style.selections}>
            { this.props.children }
          </div>
          <div className={style.buttons}>
              {
                this.props.cancel && (
                  <div className={style.simple}>
                    <Button text={this.props.cancelButtonLabel} white onClick={this.props.cancel} color="clear" />
                  </div>
                )
              }
              {
                this.props.options && this.props.options.map(option => (
                  <div className={style.simple} key={`option${option.label}`}>
                    <Button text={option.label} white onClick={option.callback} disabled={option.disabled} color={option.destructive ? "red" : "blue"} />
                  </div>
                ))
              }
          </div>
        </div>
      </div>
    );
  }
}

OptionFooter.defaultProps = {
  cancelButtonLabel: "Cancel"
}

export default OptionFooter;