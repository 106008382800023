import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUp, faArrowDown } from "@fortawesome/free-solid-svg-icons";
import AnimateHeight from "react-animate-height";
import isEmpty from "lodash/isEmpty";
import Caret from "../Home/BuildingCard/caret3_grey.png";
import { OuterContainer, InnerContainer, Row, Col } from ".";
import * as accountActions from "../../actions/account";
import styled from "styled-components";

class Statistics extends Component {

  constructor(props) {
    super(props);
    this.state = {
      expandedLocationId: null
    };

    this.onToggleExpand = this.onToggleExpand.bind(this);
  }

  onToggleExpand(locationId) {
    if (locationId === this.state.expandedLocationId) {
      this.setState({ expandedLocationId: null });
    }
    else {
      this.setState({ expandedLocationId: locationId });
    }
  }

  render() {
    // console.log("Statistics state", this.state);
    // console.log("Statistics props", this.props);

    if (isEmpty(this.props.buildings) || isEmpty(this.props.buildingsTotal) || isEmpty(this.props.locationHierarchy) || isEmpty(this.props.locationBreadcrumbs)) {
      return null;
    }

    let buildingWithTotal = [...this.props.buildings, this.props.buildingsTotal];

    let buildingBlocks = buildingWithTotal.map((building, index) => {

      let totalSensorCount = 0;
      let totalSensorCountPreviousMonth = 0;
      let sensorsElements = building.sensors.map((sensor, index) => {
        totalSensorCount += sensor.totalCount;
        totalSensorCountPreviousMonth += sensor.totalCountPreviousMonth;

        let differenceIcon;
        let valueChange = sensor.totalCount - sensor.totalCountPreviousMonth;
        if (valueChange > 0) {
          differenceIcon = <FontAwesomeIcon icon={faArrowUp} color="green" fontSize={14} transform={{ rotate: 45 }} style={{ marginRight: "3px" }} />;
        }
        else if (valueChange < 0) {
          differenceIcon = <FontAwesomeIcon icon={faArrowDown} color="#CA4C4C" fontSize={14} transform={{ rotate: -45 }} style={{ marginRight: "3px" }} />;
        }
        else if (valueChange === 0) {
          differenceIcon = <FontAwesomeIcon icon={faArrowUp} color="#888888" fontSize={14} transform={{ rotate: 45 }} style={{ marginRight: "3px" }} />;
        }

        return (
          <BlockRow key={`sensor-${index}`}>
            <div style={{ display: "flex" }}>
              <BlockRowText>{sensor.vendor} {sensor.model}</BlockRowText>
            </div>
              
            <div style={{ display: "flex" }}>
              <BlockStatisticsContainer>
                <ValueContainer>
                  <Value>
                  {sensor.totalCount}
                  </Value>
                  <ValueChange>
                    ({ differenceIcon }{ Math.abs(valueChange) })
                  </ValueChange>
                </ValueContainer>
              </BlockStatisticsContainer>
            </div>
          </BlockRow>
        );
      });

      let totalGatewayCount = 0;
      let totalGatewayCountPreviousMonth = 0;
      let gatewaysElements =  building.gateways.map((gateway, index) => {
        totalGatewayCount += gateway.totalCount;
        totalGatewayCountPreviousMonth += gateway.totalCountPreviousMonth;

        let differenceIcon;
        let valueChange = gateway.totalCount - gateway.totalCountPreviousMonth;
        if (valueChange > 0) {
          differenceIcon = <FontAwesomeIcon icon={faArrowUp} color="green" fontSize={14} transform={{ rotate: 45 }} style={{ marginRight: "3px" }} />;
        }
        else if (valueChange < 0) {
          differenceIcon = <FontAwesomeIcon icon={faArrowDown} color="#CA4C4C" fontSize={14} transform={{ rotate: -45 }} style={{ marginRight: "3px" }} />;
        }
        else if (valueChange === 0) {
          differenceIcon = <FontAwesomeIcon icon={faArrowUp} color="#888888" fontSize={14} transform={{ rotate: 45 }} style={{ marginRight: "3px" }} />;
        }

        return (
          <BlockRow key={`gateway-${index}`}>
            <div style={{ display: "flex" }}>
              <BlockRowText>{gateway.vendor} {gateway.model}</BlockRowText>
            </div>
              
            <div style={{ display: "flex" }}>
              <BlockStatisticsContainer>
                <Value>
                  {gateway.totalCount}
                </Value>
                <ValueChange>
                  ({ differenceIcon }{ Math.abs(valueChange) })
                </ValueChange>
              </BlockStatisticsContainer>
            </div>
          </BlockRow>
        );
      });

      let showExpand = false;
      if (building.sensors.length > 0 || building.gateways.length > 0) {
        showExpand = true;
      }

      let differenceSensorIcon;
      let sensorValueChange = totalSensorCount-totalSensorCountPreviousMonth;
      if (sensorValueChange > 0) {
        differenceSensorIcon = <FontAwesomeIcon icon={faArrowUp} color="green" fontSize={14} transform={{ rotate: 45 }} style={{ marginRight: "3px" }} />;
      }
      else if (sensorValueChange < 0) {
        differenceSensorIcon = <FontAwesomeIcon icon={faArrowDown} color="#CA4C4C" fontSize={14} transform={{ rotate: -45 }} style={{ marginRight: "3px" }} />;
      }
      else if (sensorValueChange === 0) {
        differenceSensorIcon = <FontAwesomeIcon icon={faArrowUp} color="#888888" fontSize={14} transform={{ rotate: 45 }} style={{ marginRight: "3px" }} />;
      }

      let differenceGatewayIcon;
      let gatewayValueChange = totalGatewayCount-totalGatewayCountPreviousMonth;
      if (gatewayValueChange > 0) {
        differenceGatewayIcon = <FontAwesomeIcon icon={faArrowUp} color="green" fontSize={14} transform={{ rotate: 45 }} style={{ marginRight: "3px" }} />;
      }
      else if (gatewayValueChange < 0) {
        differenceGatewayIcon = <FontAwesomeIcon icon={faArrowDown} color="#CA4C4C" fontSize={14} transform={{ rotate: -45 }} style={{ marginRight: "3px" }} />;
      }
      else if (gatewayValueChange === 0) {
        differenceGatewayIcon = <FontAwesomeIcon icon={faArrowUp} color="#888888" fontSize={14} transform={{ rotate: 45 }} style={{ marginRight: "3px" }} />;
      }


      let name = building.name;
      if (building.id === undefined) {
        name = "Total";
      }

      return (
        <Row key={`building-${index}`}>
          <Col size="lg" $noMargin>
            <BigBlock>
              <BlockContent>
                <div style={{ display: "flex", justifyContent: "space-between" }} onClick={() => showExpand && this.onToggleExpand(building.id)}>

                <BlockInnerTitle>{ name }</BlockInnerTitle>

                  <div style={{ display: "flex", alignItems: "center" }}>

                    <BlockStatisticsContainer style={{ width: "100px",flexDirection: "column", justifyContent: "center", padding: "12px 10px" }}>
                      <HeaderValueContainer>
                        <HeaderValue>
                          { totalSensorCount }
                        </HeaderValue>
                        <ValueChange>
                          ({ differenceSensorIcon }{ Math.abs(sensorValueChange) })
                        </ValueChange>
                      </HeaderValueContainer>
                      <div style={{ display: "inline-block", fontSize: "14px", color: "#555" }}>sensors</div>
                    </BlockStatisticsContainer>

                    <BlockStatisticsContainer style={{ width: "100px", flexDirection: "column", justifyContent: "center", padding: "12px 10px" }}>
                      <HeaderValueContainer>
                        <HeaderValue>
                          { totalGatewayCount }
                        </HeaderValue>
                        <ValueChange>
                          ({ differenceGatewayIcon }{ Math.abs(gatewayValueChange) })
                        </ValueChange>
                      </HeaderValueContainer>
                      <div style={{ display: "inline-block", fontSize: "14px", color: "#555" }}>gateways</div>
                    </BlockStatisticsContainer>

                    <ExpandContainer>
                      { showExpand && <ExpandIcon src={Caret} $rotated={this.state.expandedLocationId === building.id} /> }
                    </ExpandContainer>
                  </div>

                </div>
                <Drawer>
                  <AnimateHeight duration={500} height={this.state.expandedLocationId === building.id ? "auto" : 0}>
                    <Content>
                      { sensorsElements }
                      { gatewaysElements }
                    </Content>
                  </AnimateHeight>
                </Drawer>
              </BlockContent>
            </BigBlock>
          </Col>
        </Row>
      );
    });

    // String of last month in english
    let lastMonth = new Date();
    lastMonth.setMonth(lastMonth.getMonth() - 1);
    lastMonth = lastMonth.toLocaleString("en-us", { month: "long" });
    lastMonth = lastMonth.toLowerCase();

    return (
      <OuterContainer $darkBackground>
        <InnerContainer>
          <Row $paddingTop="15px">
            <Col>
              <h2>Sensors and gateways<BreadCrumb>({ lastMonth })</BreadCrumb></h2>
              <p>The number of sensors and gateways in your buildings and the change from the previous month.</p>
            </Col>
          </Row>
          { buildingBlocks }
        </InnerContainer>
      </OuterContainer>
    );
  }
}

function mapStateToProps(state) {
  return { 
    auth: state.auth,
    selectedCompany: state.auth.selectedCompany,
    isAuthLoading: state.loading.auth,
    buildings: state.accountSummary.buildings,
    buildingsTotal: state.accountSummary.buildingsTotal,
    locationHierarchy: state.locations.hierarchy,
    locationBreadcrumbs: state.locations.breadcrumbs,
    vortoIds: state.auth.vortoIds
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    getAccountSummary: accountActions.getAccountSummary,
  }, dispatch);
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Statistics));

const BigBlock = styled.div`
  position: relative;
  background-color: rgb(255, 255, 255);
  border-radius: 6px;
  border-width: 1px;
  border-style: solid;
  border-color: rgb(221, 221, 221);
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 10px;
  overflow: hidden;
`;

const BlockContent = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  position: relative;
  // padding: 15px 20px 15px 20px;
  font-size: 16px;
  font-weight: 400;
`;

const BlockInnerTitle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 15px 20px 15px 20px;

  font-size: 20px;
  font-weight: 600;
  color: #222222;

  div { 
    font-size: 20px;
    font-weight: 600;
    color: #222222;
  }

  div:last-child {
    margin-left: 5px;
    
    font-size: 15px;
    font-weight: 600;
    color: #666666;

  }
`;

const BreadCrumb = styled.span`
  font-size: 18px;
  font-weight: 400;
  color: #666;
  // margin-bottom: 5px;
  margin-left: 5px;
`;

const ExpandContainer = styled.div`
  display: flex;
  width: 55px;
  height: 55px;
  align-items: center;
  justify-content: center;
`;

const ExpandIcon = styled.img`
  width: 25px;
  height: 25px;
  transform: ${props => props.$rotated && "rotate(180deg)"};
`;

const Drawer = styled.div`
  overflow: visible;
`;

const Content = styled.div`
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
  border-top: 1px solid #dbdbdb;
`;

const BlockRow = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  box-sizing: border-box;
  justify-content: space-between;
  padding: 13px 20px 13px 20px;
  width: 100%;

  border-bottom: 1px solid #dbdbdb;

  &:last-child {
    border-bottom: none;
  }
`;

const BlockRowText = styled.div`
  font-size: 16px;
  font-weight: 400;
  color: #222222;
  width: 100%;

  // align text in the middle
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const BlockStatisticsContainer = styled.div`
  cursor: pointer;
  font-size: 16px;
  color: #222222;
  padding-right: 20px;
  width: ${props => props.width || "auto"};
  
  // align text in the middle
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const HeaderValueContainer = styled.div`
`;

const ValueContainer = styled.div`
  align-items: baseline;
`;

const Value = styled.div`
  display: inline-block;
  font-family: Source Sans Pro;
  font-size: 21px;
  line-height: 21px;
  font-weight: 600;
  color: #222222;
`;

const HeaderValue = styled.div`
  display: inline-block;
  font-family: Source Sans Pro;
  font-size: 21px;
  line-height: 21px;
  font-weight: 600;
  color: #222222;
`;

const ValueChange = styled.div`
  display: inline-block;
  font-family: Source Sans Pro;
  font-size: 15px;
  font-weight: 600;
  color: #888888;
  margin-left: 4px;
  line-height: 20px
`;