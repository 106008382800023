import React, { Component } from "react";
import { get } from "lodash";
import { Col, Row } from "react-grid-system";
import PropTypes, { string } from "prop-types";
import moment from "moment";
import FilterBlock from "./filterBlock";
import Button from "../../../components/Button";
import SmallButton from "../../../components/SmallButton";
import DateInputBox from "../../../components/DateInputBox";
import ButtonInputBox from "../../../components/ButtonInputBox";
import { ControlledDropdownSelection } from "../../../components/DropdownSelection";
import styled from "styled-components";
import style from "../style.module.scss";
import strings from "../strings";

class SideBar extends Component {

  static propTypes = {
    queries: PropTypes.array.isRequired,
    locationBreadcrumbs: PropTypes.object.isRequired,
    locationData: PropTypes.array.isRequired,
    tempLocationId: PropTypes.string,
    tempDateRanges: PropTypes.array,
    workplaceCategories: PropTypes.array.isRequired,
    onAddFilter: PropTypes.func.isRequired,
    onRemoveFilter: PropTypes.func.isRequired,
    onWorkplaceCategoryChange: PropTypes.func.isRequired,
    onCustomTagChange: PropTypes.func.isRequired,
    onChangeConfigView: PropTypes.func.isRequired,
    disableView: PropTypes.bool,
    selectedQueryIndex: PropTypes.number,
    onClose: PropTypes.func,
    mobile: PropTypes.bool,
    includeWorkplaceCategories: PropTypes.bool,
    includeCustomTags: PropTypes.bool
  };

  getDateRangeString(queryIndex) {

    // Show temporary date range if it exists
    let dateRanges = [];
    if (this.props.selectedQueryIndex === queryIndex && this.props.tempDateRanges) {
      dateRanges = this.props.tempDateRanges;
    }
    else {
      dateRanges = get(this.props, "queries[" + queryIndex + "].dateRanges", []);
    }

    if (dateRanges.length === 0) {
      return "";
    }

    const start = moment(dateRanges[0].startDate);
    const end = moment(dateRanges[dateRanges.length - 1].endDate);
    let string = `${start.format("DD/MM/YY")} - ${end.format("DD/MM/YY")}`;
    return string;
  }

  getNumberOfRemovedDays(queryIndex) {
    const dateRanges = get(this.props, "queries[" + queryIndex + "].dateRanges", []);
    if (dateRanges.length === 0) {
      return 0;
    }

    let numberOfRemovedDays = 0;
    var lastEndDate = moment(dateRanges[0].endDate);
    for (let i = 1; i < dateRanges.length; i++) {
      const dateRange = dateRanges[i];
      let startDate = moment(dateRange.startDate);

      // Get the number of days between the last end date and the current start date
      let numberOfDays = startDate.diff(lastEndDate, "days") - 1;
      numberOfRemovedDays += numberOfDays;

      lastEndDate = moment(dateRange.endDate);
    }

    return numberOfRemovedDays;
  }

  getNumberOfRemovedDaysString(queryIndex) {
    const numberOfRemovedDays = this.getNumberOfRemovedDays();
    if (numberOfRemovedDays === 0) {
      return "";
    }

    return `(${numberOfRemovedDays} unselected days in range)`;
  }

  getLocationString(queryIndex) {

    // Show temporary location if it exists
    let locationId = "";
    if (this.props.selectedQueryIndex === queryIndex && this.props.tempLocationId) {
      locationId = this.props.tempLocationId;
    }
    else {
      locationId = get(this.props, `queries[${queryIndex}].locationId`, "");
    }

    let breadcrumbs = get(this.props, `locationBreadcrumbs[${locationId}]`, []);

    if (breadcrumbs.length === 0) {
      return "";
    }
    else if (breadcrumbs.length === 1) {
      return breadcrumbs[0].name;
    }
    
    // Create breadcrumb string: last location name (, joined parent names)
    return breadcrumbs[breadcrumbs.length - 1].name + " (" + breadcrumbs.slice(0, breadcrumbs.length - 1).map(location => location.name).join(", ") + ")";
  }

  onFilterClick = (e, index) => {
    if (this.props.disableView) { 
      e.stopPropagation(); 
      
      if (this.props.selectedQueryIndex !== index) { 
        this.props.onClose(); 
      }
    }
  }

  render() {
    // console.log("SideBar.render.props", this.props);

    let workplaceCategories = get(this.props, "workplaceCategories", []).map(category => ({ id: category._id, name: category.name }));
    workplaceCategories.unshift({ id: "all", name: strings.dataset.allCategories });

    const content = (
      <>
        { 
          this.props.queries.map((query, index) => {

            const expandedCustomTags = get(query, "location.expandedCustomTags", {});
            let customTags = Object.keys(expandedCustomTags).map(customTagId => ({ id: customTagId, name: expandedCustomTags[customTagId] })).flat();

            // Add selectedCustomTag if it is missing from customTags
            const selectedCustomTag = query.customTag;
            if (selectedCustomTag && !customTags.find(tag => tag.id === selectedCustomTag.id)) {
              customTags.push({ id: selectedCustomTag.id, name: selectedCustomTag.name });
            }

            let customTagsDisabled = false;
            if (customTags.length === 0) {
              customTagsDisabled = true;
              customTags = [{ id: "undefined", name: strings.dataset.undefined }];
            }

            return (
              <FilterBlock
                key={"wpaq-" + index}
                index={index}
                selected={this.props.selectedQueryIndex === index}
                onDuplicate={this.props.queries.length < 20 ? () => this.props.onDuplicateFilter(index) : null}
                onRemove={this.props.queries.length > 1 ? () => this.props.onRemoveFilter(index) : null}
                onClick={(e) => this.onFilterClick(e, index) } // Close the sidebar when clicking on a filter
              >
                <DateInputBox
                  label={strings.dataset.timePeriod}
                  // placeholder={strings.dataset.required}
                  value={this.getDateRangeString(index)}
                  info={this.getNumberOfRemovedDaysString(index)}
                  onClick={() => this.props.onChangeConfigView(index, "dateRangeView")}
                  valid={this.getDateRangeString(index) !== ""}
                  disabled={true}
                />
                <ButtonInputBox
                  label={strings.dataset.location}
                  value={this.getLocationString(index)}
                  placeholder={strings.dataset.selectLocation}
                  onClick={() => this.props.onChangeConfigView(index, "locationView")}
                  valid={this.getLocationString(index) !== ""}
                />
                {
                  this.props.includeWorkplaceCategories && (
                    <ControlledDropdownSelection
                      label={strings.dataset.workplaceCategory}
                      options={workplaceCategories}
                      value={query.workplaceCategoryId ?? "all"}
                      onChange={(event) => this.props.onWorkplaceCategoryChange(index, event.target.value)}
                      style={{ display: "block" }}
                    />
                  )
                }
                {
                  this.props.includeCustomTags && (
                    <ControlledDropdownSelection
                      label={strings.dataset.customTag}
                      options={customTags}
                      value={query.customTagId ?? "undefined"}
                      onChange={(event) => this.props.onCustomTagChange(index, event.target.value)}
                      style={{ display: "block" }}
                      disabled={customTagsDisabled}
                    />
                  )
                }
                {
                  this.props.includeCustomTags && (
                    <ControlledDropdownSelection
                      label={strings.dataset.aggregationLevel}
                      options={[{ id: "true", name: strings.dataset.summed },{ id: "false", name: strings.dataset.notSummed }]}
                      value={query.summed ? "true" : "false"}
                      onChange={(event) => this.props.onSummedChanged(index, event.target.value === "true")}
                      style={{ display: "block" }}
                    />
                  )
                }
              </FilterBlock>
            );
          })
        }
        <div style={{ marginTop: "40px" }} />
        <div>
          <Row>
            <Col md={8} offset={{ md: 2 }}>
              { this.props.queries.length < 20 && (
                <Button
                  text={strings.dataset.newDataset}
                  onClick={this.props.onAddFilter}
                />
              )
              }
            </Col>
          </Row>
        </div>
        <div style={{ paddingTop: "40px" }} />
      </>
    );

    if (this.props.mobile) {
      return (
        <Container onClick={this.props.onClose}>
          <ScrollBox>
            <Block onClick={(e) => e.stopPropagation()}>
              <BlockContent>
                <BlockInnerTitle>Filter</BlockInnerTitle>
                { content }
              </BlockContent>
              <ToolBar>
                <SmallButton text="Close" color="white" onClick={this.props.onClose} style={{ marginRight: this.state.hasChanges ? "20px" : "0" }} noLeftMargin />
                { this.state.hasChanges && <SmallButton text="Apply" noLeftMargin /> }
              </ToolBar>
            </Block>
          </ScrollBox>
        </Container>
      );
    }

    return (
      <div className={style.sideBar} data-open={true} onClick={this.props.disableView ? this.props.onClose : null}>
        <div className={style.sideContent} >
          { content }
        </div>
        { this.props.disableView && <Overlay /> }
      </div>
    );
  }
}

export default SideBar;

const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  z-index: 3;
`;

const ScrollBox = styled.div`
  position: relative;
  overflow: auto;
  max-height: 100%;
  height: 100%;
  padding: 40px;
  box-sizing: border-box;
`;

const Block = styled.div`
  position: relative;
  background-color: rgb(255, 255, 255);
  border-radius: 6px;
  border-width: 1px;
  border-style: solid;
  border-color: rgb(221, 221, 221);
  margin-top: 20px;
  padding: 15px;
  font-size: 16px;
  font-weight: 400;

  &:first-child {
    margin-top: 30px;
  }
`;

const BlockContent = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  position: relative;
  min-height: 380px;
  padding: 10px 20px;
  font-size: 16px;
  font-weight: 400;
`;

const ToolBar = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  box-sizing: border-box;
  padding: 10px 20px;

  border-top: 1px solid #ddd;
`;

const BlockInnerTitle = styled.div`
  font-size: 20px;
  font-weight: 600;
  color: #222222;
  margin-top: 8px;
  margin-bottom: 8px;
`;
const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(216, 216, 216, 0.7);
  z-index: 3;
  cursor: default;
  pointer-events: none;
`;