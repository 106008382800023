import {
  Chart as ChartJS,
} from "chart.js";
import { callback as call } from 'chart.js/helpers';

const chartStates = new WeakMap();

export function getState(chart) {
  let state = chartStates.get(chart);
  if (!state) {
    state = {
      originalScaleLimits: {},
      updatedScaleLimits: {},
      handlers: {},
      panDelta: {}
    };
    chartStates.set(chart, state);
  }
  return state;
}

export function removeState(chart) {
  chartStates.delete(chart);
}

function removeHandler(chart, type) {
  const {handlers} = getState(chart);
  const handler = handlers[type];
  if (handler && handler.target) {
    handler.target.removeEventListener(type, handler);
    delete handlers[type];
  }
}

function addHandler(chart, target, type, handler) {
  const {handlers, options} = getState(chart);
  const oldHandler = handlers[type];
  if (oldHandler && oldHandler.target === target) {
    // already attached
    return;
  }
  removeHandler(chart, type);
  handlers[type] = (event) => handler(chart, event, options);
  handlers[type].target = target;
  target.addEventListener(type, handlers[type]);
}

export function addListeners(chart, options) {
  const canvas = chart.canvas;
  const { drag: dragOptions } = options.zoom;

  // Install listeners. Do this dynamically based on options so that we can turn zoom on and off
  // We also want to make sure listeners aren"t always on. E.g. if you"re scrolling down a page
  // and the mouse goes over a chart you don"t want it intercepted unless the plugin is enabled
  if (dragOptions.enabled) {
    addHandler(chart, canvas, "mousedown", mouseDown);
    addHandler(chart, canvas.ownerDocument, "mouseup", mouseUp);
  } else {
    removeHandler(chart, "mousedown");
    removeHandler(chart, "mousemove");
    removeHandler(chart, "mouseup");
  }
}

export function removeListeners(chart) {
  removeHandler(chart, "mousedown");
  removeHandler(chart, "mousemove");
  removeHandler(chart, "mouseup");
  removeHandler(chart, "click"); // Needed?
}

export function mouseDown(chart, event) {
  const state = getState(chart);
  state.dragStart = event;
  // console.log("mouseDown", event);
  addHandler(chart, chart.canvas, "mousemove", mouseMove);
}

export function mouseMove(chart, event) {
  const state = getState(chart);
  if (state.dragStart) {
    state.dragging = true;
    state.dragEnd = event;
    chart.update("none");
  }
}

export function mouseUp(chart, event) {
  const state = getState(chart);
  if (!state.dragStart) {
    return;
  }

  removeHandler(chart, "mousemove");
  const {onSelectionComplete, drag: {threshold = 0}} = state.options.zoom;
  const rect = computeRect(chart, state.dragStart.offsetX, event.offsetX);
  const distanceX = rect.width;
  const distanceY = 0;
  const distance = Math.sqrt(distanceX * distanceX + distanceY * distanceY);

  // Convert canvas coordinates to timestamps
  const startPercentage = (state.dragStart.offsetX - chart.scales.x.left)/ chart.scales.x.width;
  const newStartTimestamp = Math.floor((startPercentage * (chart.scales.x.max - chart.scales.x.min)) + chart.scales.x.min);
  
  // Do not convert end timestamp, if no drag happened
  let endPercentage;
  let newEndTimestamp;
  if (state.dragEnd) {
    endPercentage = (state.dragEnd.offsetX - chart.scales.x.left)/ chart.scales.x.width;
    newEndTimestamp = Math.ceil((endPercentage * (chart.scales.x.max - chart.scales.x.min)) + chart.scales.x.min);
  }

  // console.log("newStartTimestamp", newStartTimestamp);
  // console.log("newEndTimestamp", newEndTimestamp);

  // Remove drag start and end before chart update to stop drawing selected area
  state.dragStart = state.dragEnd = null;

  if (distance <= threshold) {
    state.dragging = false;
    chart.update("none");
    return;
  }

  setTimeout(() => (state.dragging = false), 500);

  if (newStartTimestamp && newEndTimestamp) {
    call(onSelectionComplete, [{chart, startDate: new Date(newStartTimestamp), endDate: new Date(newEndTimestamp)}]);
  }
}

export function computeRect(chart, startX, endX) {
  const { top, bottom, width: chartWidth } = chart.chartArea;

  const left = Math.min(startX, endX);
  const right = Math.max(startX, endX);

  const width = right - left;
  const height = bottom - top;

  return {
    left,
    top,
    right,
    bottom,
    width,
    height,
    zoomX: width ? 1 + ((chartWidth - width) / chartWidth) : 1,
    zoomY: 1
  };
}

export const SelectionPlugin = {
  id: "time-selection",

  defaults: {
    zoom: {
      drag: {
        enabled: false
      }
    }
  },

  start: (chart, args, options) => {
    const state = getState(chart);
    state.options = options;
  },

  beforeEvent(chart) {
    const state = getState(chart);
    if (state.dragging) {
      // cancel any event handling while dragging
      return false;
    }
  },

  beforeUpdate: (chart, args, options) => {
    const state = getState(chart);
    state.options = options;
    addListeners(chart, options);
  },

  beforeDatasetsDraw: (chart, args, options) => {
    const {dragStart, dragEnd} = getState(chart);

    const startTimestamp = options.zoom.startTimestamp;
    const endTimestamp = options.zoom.endTimestamp;

    if (dragEnd) {
      // console.log("------------ computeRect: Drag --------------");
      // console.log("startTimestamp", startTimestamp);
      // console.log("endTimestamp", endTimestamp);
      // console.log("chart.scales.x.max", chart.scales.x.max);
      // console.log("chart.scales.x.min", chart.scales.x.min);

      const {left, top, width, height} = computeRect(chart, dragStart.offsetX, dragEnd.offsetX);

      const ctx = chart.ctx;
      ctx.save();
      ctx.beginPath();
      ctx.fillStyle = "rgba(225,225,225,0.7)";
      ctx.fillRect(left, top, width, height);
      ctx.restore();
    }
    else if (startTimestamp && endTimestamp) {
      // console.log("-------------- computeRect ----------------");
      // console.log("startTimestamp", startTimestamp);
      // console.log("endTimestamp", endTimestamp);
      // console.log("chart.scales.x.max", chart.scales.x.max);
      // console.log("chart.scales.x.min", chart.scales.x.min);
      // console.log("(startTimestamp - chart.scales.x.min)", (startTimestamp - chart.scales.x.min));
      // console.log("(chart.scales.x.max - chart.scales.x.min)", (chart.scales.x.max - chart.scales.x.min));

      // Get start and end pixel positions
      const startPercentage = (startTimestamp - chart.scales.x.min)/(chart.scales.x.max - chart.scales.x.min);
      const endPercentage = (endTimestamp - chart.scales.x.min)/(chart.scales.x.max - chart.scales.x.min);

      // Multiply by the width of the chart to get the pixel positions
      const startPixel = startPercentage * chart.scales.x.width + chart.scales.x.left;
      const endPixel = endPercentage * chart.scales.x.width + chart.scales.x.left;

      const {left, top, width, height} = computeRect(chart, startPixel, endPixel);

      const ctx = chart.ctx;
      ctx.save();
      ctx.beginPath();
      ctx.fillStyle = "rgba(225,225,225,0.7)";
      ctx.fillRect(left, top, width, height);
      ctx.restore();
    }
  },

  stop: (chart) => {
    removeListeners(chart);
    removeState(chart);
  }
}