import get from "lodash/get";
import { toast } from "react-toastify";
import * as types from "../ApiTypes";

const initialState = {
  currentLocation: null,
  data: {
    count: 0,
    gateways: [],
    limit: 20
  },
  metricData: {
    count: 0,
    results: [],
    limit: 20
  },
  error: false,
  createdJob: null
};

export default function gatewayReducer(state = initialState, action) {

  switch (action.type) {

    case types.REQ_DATA: {
      if (action.fetchType === types.GET_GATEWAYS) {
        return {...initialState, currentLocation: action.metadata };
      }

      if (action.fetchType === types.GET_ALL_GATEWAYS) {
        return {...state, metricData: initialState.metricData };
      }

      if (action.fetchType === types.CREATE_GATEWAY) {
        return {...initialState };
      }

      if (action.fetchType === types.CLEAR_CREATED_JOB) {
        return {...state, createdJob: null };
      }
      
      return state;
    }

    case types.RECV_DATA: {
      if (action.fetchType === types.GET_GATEWAYS) {

        // Check if gateways data is for the latest request
        if (state.currentLocation !== action.metadata) {
          return state;
        }

        return {...initialState, data: action.payload };
      }

      if (action.fetchType === types.GET_ALL_GATEWAYS) {
        return {...state, metricData: action.payload };
      }

      if (action.fetchType === types.CREATE_GATEWAY) {
        toast.success("Gateway created");
        var newState = {...initialState };
        if (action.payload.jobId) {
          newState.createdJob = action.payload;
        }
        return newState;
      }
      
      return state;
    }

    case types.RECV_ERROR: {

      const statusCode = get(action, "payload.response.status", "Error");
      const message = get(action, "payload.response.message", "Error");

      if (action.fetchType === types.GET_GATEWAYS) {
        toast.error(`${statusCode}: Could not get gateways`);
        return initialState;
      }

      if (action.fetchType === types.GET_ALL_GATEWAYS) {
        toast.error(`${statusCode}: Could not get gateways`);
        return {...state, metricData: initialState.metricData };
      }

      if (action.fetchType === types.CREATE_GATEWAY) {
        if (message) {
          toast.error(`${statusCode}: ${message}`);
        }
        else {
          toast.error(`${statusCode}: Could not create gateway`);
        }
        return initialState;
      }

      if (action.fetchType === types.DOWNLOAD_GATEWAY_CSV) {
        toast.error(`${statusCode}: Could not download csv`);
        return state;
      }
      
      return state;
    }

    case types.CLEAR_DATA: {
      return initialState;
    }
    
    default: {
      return state;
    }
  }
}
