import React, { Component } from "react";
import isEmpty from "lodash/isEmpty";
import Button from "../Button";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronUp, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import style from "./style.module.scss";

class AppSection extends Component {

  constructor(props) {
    super(props);

    this.state = {
      hover: false
    };
  }

  render() {
    let { index, count, selectedIndex, section, onClick, hideArrows = false, disableHover = false, moveUp, moveDown, addButton, children } = this.props;
  
    return addButton ? 
      (
        <div className={style.sectionContainer}>
          <div className={style.arrowContainer}>
            <div className={style.arrowPlaceholder} />
          </div>
          <div className={style.newBackground} onClick={(event) => { event.stopPropagation() }}>
            <div className={style.buttonContainer}>
              <Button {...addButton} slim />
            </div>
          </div>
        </div>
      ) : (
        <div className={style.sectionContainer}>
          { !hideArrows && (
            <div className={style.arrowContainer} onClick={(event) => { event.stopPropagation(); }}>
              <div className={style.arrowPlaceholder} />
              <div className={style.arrowUp} 
                onClick={(event) => { if (index !== 0) { moveUp(index); } event.stopPropagation(); }}
                onMouseEnter={() => { this.setState({ upHover: true }); }}
                onMouseLeave={() => { this.setState({ upHover: false }); }}>

                <FontAwesomeIcon icon={faChevronUp} color={index === 0 ? "#ddd" : this.state.upHover ? "#1C4D82" : "#555" } />

              </div>
              <div className={style.arrowDown} 
                onClick={(event) => { if (index !== count - 1) { moveDown(index); } event.stopPropagation(); }}
                onMouseEnter={() => { this.setState({ downHover: true }); }}
                onMouseLeave={() => { this.setState({ downHover: false }); }}>

                <FontAwesomeIcon icon={faChevronDown} color={index === count - 1 ? "#ddd" : this.state.downHover ? "#1C4D82" : "#555" } />
                
              </div>
            </div>
          )}
          <div className={ index === selectedIndex ? style.selectedBackground : style.background} 
            onClick={() => onClick(index)} 
            data-show-arrows={!hideArrows}
            data-disable-hover={disableHover}
            data-hover={this.state.hover}>

            <div className={style.headerContainer}
              onMouseEnter={() => { this.setState({ hover: true }); }}
              onMouseLeave={() => { this.setState({ hover: false }); }}>

              <h3 className={style.header} data-no-header={isEmpty(section.header)}>{ isEmpty(section.header) ? "<no header>" : section.header }</h3>

            </div>
            <div className={style.rowsContainer}>
              { children }
            </div>
          </div>
        </div>
      );
  }
}

export default AppSection;
