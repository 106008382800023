import React from "react";
import { isEmpty, isNil } from "lodash";
import { getFixedScales, getScales } from "./helpers";
import Graph from "./index";

export class DashboardGraph extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fixedScale: false
    };
    this.toggleFixedScale = this.toggleFixedScale.bind(this);
  }

  toggleFixedScale() {
    this.setState(prevState => ({ fixedScale: !prevState.fixedScale }));
  }

  render() {
    // console.log("DashboardGraph.render.props", this.props);

    // Convert to new format
    const x = { min: this.props.startDate, max: this.props.endDate };
    const y = { unit };
    let options = {};

    // Set scales
    const typeScales = getFixedScales(this.props.type, this.props.capacity);
    if (this.state.fixedScale && !isEmpty(typeScales)) {
      options.scales = { ...options.scales, ...typeScales };
    }
    else {
      options.scales =  { ...options.scales, ...getScales(this.props.type, this.props.capacity) }; 
    }

    // Find valueKeys from sample data
    let valueKeys = [];
    var unit;
    if (this.props.samples.length > 0) {
      
      unit = this.props.samples[0].unit;

      // Check if avg, max or min is included in the dataset
      const hasMax = !isEmpty(this.props.samples.find(sample => !isNil(sample.max)));
      const hasAvg = !isEmpty(this.props.samples.find(sample => !isNil(sample.avg)));
      const hasMin = !isEmpty(this.props.samples.find(sample => !isNil(sample.min)));
      const hasCapacity = !isEmpty(this.props.samples.find(sample => !isNil(sample.capacity)));
      const hasCount = !isEmpty(this.props.samples.find(sample => !isNil(sample.count)));
      const hasSum = !isEmpty(this.props.samples.find(sample => !isNil(sample.sum)));
      const hasValue = !isEmpty(this.props.samples.find(sample => !isNil(sample.value)));
      const hasTotal = !isEmpty(this.props.samples.find(sample => !isNil(sample.total)));

      if (hasMax) {
        valueKeys.push("max");
      }

      if (hasAvg) {
        valueKeys.push("avg");
      }

      if (hasMin) {
        valueKeys.push("min");
      }

      if (hasCapacity) {
        valueKeys.push("capacity");
      }

      if (hasCount) {
        valueKeys.push("count");
      }

      if (hasSum) {
        valueKeys.push("sum");
      }

      if (hasValue) {
        valueKeys.push("value");
      }

      if (hasTotal) {
        valueKeys.push("total");
      }
    }

    return (
      <Graph
        { ...this.props}
        x={x}
        y={y}
        valueKeys={valueKeys}
        fixedScale={this.state.fixedScale}
        toggleFixedScale={this.toggleFixedScale}
        options={options}
        aspectRatio={this.props.expanded ? 2.4 : 1.8} // show a wider graph when expanded
      />
    );
  }
}

export default DashboardGraph;