import React from "react";
import styles from "./styles.module.scss";
import Button from "../../../../components/Button";
import PropTypes from 'prop-types';

export class FilterActionFooter extends React.Component {
  static propTypes = {
    resetFiltersToDefault: PropTypes.func.isRequired,
    fetchGateways: PropTypes.func.isRequired,
    haveFiltersBeenUpdated: PropTypes.bool.isRequired
  };

  constructor(props) {
    super(props);
  }

  render() {
    const {
      resetFiltersToDefault,
      fetchGateways,
      haveFiltersBeenUpdated
    } = this.props;
    
    return (
      <div className={styles.floatingBottomFooter}>
        <div className={styles.buttonWrapper}>
          <Button 
            text="Clear filters" 
            onClick={resetFiltersToDefault}
          />

          <Button 
            text="Update gateway list" 
            disabled={!haveFiltersBeenUpdated} 
            onClick={fetchGateways}
          />
        </div>
      </div>
    )
  }
}
