import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import isEmpty from "lodash/isEmpty";
import get from "lodash/get";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleQuestion } from "@fortawesome/pro-duotone-svg-icons";
// import { faCircleQuestion } from "@fortawesome/pro-light-svg-icons";
import { faQuestion } from "@fortawesome/free-solid-svg-icons";
import { ControlledDropdownSelection } from "../../components/DropdownSelection";
import PeopleCountHoursPerDay from "./PeopleCount/peopleCountHoursPerDay";
import PeopleCountDays from "./PeopleCount/peopleCountDays";
import PeopleCountMonths from "./PeopleCount/peopleCountMonths";
import PeopleCountWeekdays from "./PeopleCount/peopleCountWeekdays";
import PeopleCountWeeks from "./PeopleCount/peopleCountWeeks";
import PeopleCountYearOverYear from "./PeopleCount/peopleCountYearOverYear";
import UtilizationHourPerDay from "./Utilization/utilizationHoursPerDay";
import UtilizationDays from "./Utilization/utilizationDays";
import UtilizationMonths from "./Utilization/utilizationMonths";
import UtilizationWeekdays from "./Utilization/utilizationWeekdays";
import UtilizationWeeks from "./Utilization/utilizationWeeks";
import strings from "./strings";
import styled from "styled-components";
import * as reportActions from "../../actions/reports";

class ReportPage extends Component {

  constructor(props) {
    // console.log("ReportPage.constructor");
    super(props);
    this.state = {};
  }
    
  componentDidMount() {
    document.title = `BLDNG.ai - Report - Workplace`;

    // Find queries that have not been fetched yet
    let newQueries = {};
    this.props.queries.forEach(query => {
      if (!isEmpty(query.dateRanges) && query.locationId) {

        let hashKey = "";
        if (this.props.selectedView === "u") {
          hashKey = `${this.props.selectedPage}-${JSON.stringify(query.dateRanges)}-${query.includeHolidays}-${query.includeHolidays}-${query.includeWeekends}-${query.locationId}-${query.workplaceCategoryId}`;
        }
        else if (this.props.selectedView === "pc") {
          const summed = this.props.selectedPage === "yearOverYear" ? true : query.summed;
          const dataTrunc = this.props.selectedPage === "yearOverYear" ? "month" : this.props.selectedPage;
          hashKey = `${dataTrunc}-${JSON.stringify(query.dateRanges)}-${query.includeHolidays}-${query.includeHolidays}-${query.includeWeekends}-${query.locationId}-${query.customTagId}-${summed}`;
        }

        if (!this.props.queryLoading[hashKey]) {
          newQueries[hashKey] = query;
        }
      }
    });

    // Update reports if needed
    Object.keys(newQueries).forEach(hashKey => {
      const query = newQueries[hashKey];
      this.updateReport(hashKey, query);
    });
  }

  componentDidUpdate(prevProps, prevState) {
    // console.log("ReportPage.componentDidUpdate prevProps", prevProps.queries);
    // console.log("ReportPage.componentDidUpdate this.props", this.props.queries);

    // Find queries that have not been fetched yet
    let newQueries = {};
    this.props.queries.forEach(query => {
      if (!isEmpty(query.dateRanges) && query.locationId) {

        let hashKey = "";
        if (this.props.selectedView === "u") {
          hashKey = `${this.props.selectedPage}-${JSON.stringify(query.dateRanges)}-${query.includeHolidays}-${query.includeHolidays}-${query.includeWeekends}-${query.locationId}-${query.workplaceCategoryId}`;
        }
        else if (this.props.selectedView === "pc") {
          const summed = this.props.selectedPage === "yearOverYear" ? true : query.summed;
          const dataTrunc = this.props.selectedPage === "yearOverYear" ? "month" : this.props.selectedPage;
          hashKey = `${dataTrunc}-${JSON.stringify(query.dateRanges)}-${query.includeHolidays}-${query.includeHolidays}-${query.includeWeekends}-${query.locationId}-${query.customTagId}-${summed}`;
        }

        if (!this.props.queryLoading[hashKey]) {
          newQueries[hashKey] = query;
        }
      }
    });

    // Update reports if needed
    Object.keys(newQueries).forEach(hashKey => {
      const query = newQueries[hashKey];
      this.updateReport(hashKey, query);
    });
  }

  updateReport(hashKey, query) {
    console.log("ReportPage.updateReport", hashKey, query);
    if (this.props.selectedView === "u") {
      this.props.getUtilization(hashKey, this.props.selectedPage, query.dateRanges, query.includeHolidays, query.includeWeekends, query.locationId, query.workplaceCategoryId);
    }
    else if (this.props.selectedView === "pc") {

      // If we dont have the customTags or workplaceFilters yet - wait for them
      if (isEmpty(this.props.customTags) || isEmpty(this.props.workplaceFilters)) {
        if (isEmpty(this.props.customTags)) {
          console.log("ReportPage.updateReport - missing customTags")
        }
        if (isEmpty(this.props.workplaceFilters)) {
          console.log("ReportPage.updateReport - missing workplaceFilters")
        }
        return;
      }

      const dataTrunc = this.props.selectedPage === "yearOverYear" ? "month" : this.props.selectedPage;
      this.props.getPeopleCount(hashKey, dataTrunc, query.dateRanges, query.includeHolidays, query.includeWeekends, query.locationId, query.customTagId, query.summed);
    }
  }
  
  render() {
    const { isLoading } = this.props;
    // console.log("Home.Dashboard.render", this.props);

    if (isLoading) {
      return null;
    }

    let dataType = "";
    if (this.props.selectedView === "u") {
      dataType = "utilization";
    }
    else if (this.props.selectedView === "pc") {
      dataType = "peopleCount";
    }

    let report;
    let reportPage;
    switch (dataType) {
      case "utilization":

        switch (this.props.selectedPage) {
          case "intraday":
            reportPage = (
              <UtilizationHourPerDay
                queries={this.props.queries}
                queryData={this.props.queryData}
                queryLoading={this.props.queryLoading}
                workplaceFilters={this.props.workplaceFilters}
                language={this.props.language}
                graphDataKeys={this.props.graphDataKeys}
                graphType={this.props.graphType}
                graphTimeSpan={this.props.graphTimeSpan}
                graphOptionsElement={this.props.graphOptionsElement}
                onGraphTypeChanged={this.props.onGraphTypeChanged}
                onGraphScaleTypeChanged={this.props.onGraphScaleTypeChanged}
                onGraphTimeSpanChanged={this.props.onGraphTimeSpanChanged}
                onGraphShowLabelsChanged={this.props.onGraphShowLabelsChanged}
              />
            );
            break;
          case "day":
            reportPage = (
              <UtilizationDays
                queries={this.props.queries}
                queryData={this.props.queryData}
                queryLoading={this.props.queryLoading}
                workplaceFilters={this.props.workplaceFilters}
                language={this.props.language}
                graphDataKeys={this.props.graphDataKeys}
                graphType={this.props.graphType}
                graphOptionsElement={this.props.graphOptionsElement}
                onGraphTypeChanged={this.props.onGraphTypeChanged}
                onGraphScaleTypeChanged={this.props.onGraphScaleTypeChanged}
                onGraphShowLabelsChanged={this.props.onGraphShowLabelsChanged}
              />
            );
            break;
          case "weekday":
            reportPage = (
              <UtilizationWeekdays
                queries={this.props.queries}
                queryData={this.props.queryData}
                queryLoading={this.props.queryLoading}
                workplaceFilters={this.props.workplaceFilters}
                language={this.props.language}
                graphDataKeys={this.props.graphDataKeys}
                graphType={this.props.graphType}
                graphOptionsElement={this.props.graphOptionsElement}
                onGraphTypeChanged={this.props.onGraphTypeChanged}
                onGraphScaleTypeChanged={this.props.onGraphScaleTypeChanged}
                onGraphShowLabelsChanged={this.props.onGraphShowLabelsChanged}
              />
            );
            break;
          case "week":
            reportPage = (
              <UtilizationWeeks
                queries={this.props.queries}
                queryData={this.props.queryData}
                queryLoading={this.props.queryLoading}
                workplaceFilters={this.props.workplaceFilters}
                language={this.props.language}
                graphDataKeys={this.props.graphDataKeys}
                graphType={this.props.graphType}
                graphOptionsElement={this.props.graphOptionsElement}
                onGraphTypeChanged={this.props.onGraphTypeChanged}
                onGraphScaleTypeChanged={this.props.onGraphScaleTypeChanged}
                onGraphShowLabelsChanged={this.props.onGraphShowLabelsChanged}
              />
            );
            break;
          case "month":
            reportPage = (
              <UtilizationMonths
                queries={this.props.queries}
                queryData={this.props.queryData}
                queryLoading={this.props.queryLoading}
                workplaceFilters={this.props.workplaceFilters}
                language={this.props.language}
                graphDataKeys={this.props.graphDataKeys}
                graphType={this.props.graphType}
                graphOptionsElement={this.props.graphOptionsElement}
                onGraphTypeChanged={this.props.onGraphTypeChanged}
                onGraphScaleTypeChanged={this.props.onGraphScaleTypeChanged}
                onGraphShowLabelsChanged={this.props.onGraphShowLabelsChanged}
              />
            );
            break;
          default:
            break;
        }

        report = (
          <>
            <Row>
              <Col>
                <h1>
                  { strings.utilization.title }
                  <HelpIcon icon={faCircleQuestion} size="sm" color="#222" onClick={this.props.onHelpClicked} />
                </h1>

                { strings.utilization.introduction.map((text, index) => (
                    <p key={"intro-" + index}>{ text }</p>
                  ))
                }

                <Block>{this.props.graphOptionsElement}</Block>

                <Menu>
                  { strings.utilization.pages.map((page) => (
                    <MenuButton
                      key={dataType + page.id}
                      onClick={() => this.props.onSelectedPageChanged(page.id)}
                      $isSelected={this.props.selectedPage === page.id }>
                      <span>{page.name}</span>
                    </MenuButton>
                  ))}
                </Menu>
              </Col>
            </Row>
            { reportPage }
          </>
        );
        break;
      case "peopleCount":

        // Add locations as entity names
        // this.props.queries.forEach(query => {
        //   entityNames[get(query.location, "_id", "?")] = get(query.location, "name", "?");
        // });

        switch (this.props.selectedPage) {
          case "intraday":
            reportPage = (
              <PeopleCountHoursPerDay
                queries={this.props.queries}
                queryData={this.props.queryData}
                queryLoading={this.props.queryLoading}
                customTags={this.props.customTags}
                language={this.props.language}
                graphDataKeys={this.props.graphDataKeys}
                graphType={this.props.graphType}
                graphTimeSpan={this.props.graphTimeSpan}
                graphScale={this.props.graphScale}
                graphOptionsElement={this.props.graphOptionsElement}
                onGraphTypeChanged={this.props.onGraphTypeChanged}
                onGraphScaleTypeChanged={this.props.onGraphScaleTypeChanged}
                onGraphTimeSpanChanged={this.props.onGraphTimeSpanChanged}
                onGraphShowLabelsChanged={this.props.onGraphShowLabelsChanged}
              />
            );
            break;
          case "day":
            reportPage = (
              <PeopleCountDays
                queries={this.props.queries}
                queryData={this.props.queryData}
                queryLoading={this.props.queryLoading}
                customTags={this.props.customTags}
                language={this.props.language}
                holidays={this.props.workplaceFilters.holidays}
                graphDataKeys={this.props.graphDataKeys}
                graphType={this.props.graphType}
                graphScale={this.props.graphScale}
                graphOptionsElement={this.props.graphOptionsElement}
                onGraphTypeChanged={this.props.onGraphTypeChanged}
                onGraphScaleTypeChanged={this.props.onGraphScaleTypeChanged}
                onGraphShowLabelsChanged={this.props.onGraphShowLabelsChanged}
              />
            );
            break;
          case "weekday":
            reportPage = (
              <PeopleCountWeekdays
                queries={this.props.queries}
                queryData={this.props.queryData}
                queryLoading={this.props.queryLoading}
                customTags={this.props.customTags}
                language={this.props.language}
                graphDataKeys={this.props.graphDataKeys}
                graphScale={this.props.graphScale}
                graphOptionsElement={this.props.graphOptionsElement}
                onGraphTypeChanged={this.props.onGraphTypeChanged}
                onGraphScaleTypeChanged={this.props.onGraphScaleTypeChanged}
                onGraphShowLabelsChanged={this.props.onGraphShowLabelsChanged}
              />
            );
            break;
          case "week":
            reportPage = (
              <PeopleCountWeeks
                queries={this.props.queries}
                queryData={this.props.queryData}
                queryLoading={this.props.queryLoading}
                customTags={this.props.customTags}
                language={this.props.language}
                graphDataKeys={this.props.graphDataKeys}
                graphType={this.props.graphType}
                graphScale={this.props.graphScale}
                graphOptionsElement={this.props.graphOptionsElement}
                onGraphTypeChanged={this.props.onGraphTypeChanged}
                onGraphScaleTypeChanged={this.props.onGraphScaleTypeChanged}
                onGraphShowLabelsChanged={this.props.onGraphShowLabelsChanged}
              />
            );
            break;
          case "month":
            reportPage = (
              <PeopleCountMonths
                queries={this.props.queries}
                queryData={this.props.queryData}
                queryLoading={this.props.queryLoading}
                customTags={this.props.customTags}
                language={this.props.language}
                graphDataKeys={this.props.graphDataKeys}
                graphType={this.props.graphType}
                graphScale={this.props.graphScale}
                graphOptionsElement={this.props.graphOptionsElement}
                onGraphTypeChanged={this.props.onGraphTypeChanged}
                onGraphScaleTypeChanged={this.props.onGraphScaleTypeChanged}
                onGraphShowLabelsChanged={this.props.onGraphShowLabelsChanged}
              />
            );
            break;
          case "yearOverYear":
            reportPage = (
              <PeopleCountYearOverYear
                queries={this.props.queries}
                queryData={this.props.queryData}
                queryLoading={this.props.queryLoading}
                customTags={this.props.customTags}
                language={this.props.language}
                graphDataKeys={this.props.graphDataKeys}
                graphType={this.props.graphType}
                graphScale={this.props.graphScale}
                graphOptionsElement={this.props.graphOptionsElement}
                onGraphScaleTypeChanged={this.props.onGraphScaleTypeChanged}
                onGraphShowLabelsChanged={this.props.onGraphShowLabelsChanged}
              />
            );
            break;
          default:
            break;
        }

        report = (
          <>
            <Row>
              <Col>
                <h1>
                  { strings.peopleCount.title }
                  <HelpIcon icon={faCircleQuestion} size="sm" color="#222" onClick={this.props.onHelpClicked} />
                </h1>
                
                { strings.peopleCount.introduction.map((text, index) => (
                    <p key={"intro-" + index}>{ text }</p>
                  ))
                }

                <Block>{this.props.graphOptionsElement}</Block>
            
                <Menu>
                  { strings.peopleCount.pages.map((page) => (
                    <MenuButton
                      key={dataType + page.id}
                      onClick={() => this.props.onSelectedPageChanged(page.id)}
                      $isSelected={this.props.selectedPage === page.id }>
                      <span>{page.name}</span>
                    </MenuButton>
                  ))}
                </Menu>

              </Col>
            </Row>

            { reportPage }
            
          </>
        );
        break;

      default:
        break;
    }
    return (
      <Container>
        <Scroll overflow={this.props.disableView ? "hidden" : "scroll"}>
          <ReportContainer $mobile={this.props.mobile}>
            { report }
          </ReportContainer>
        </Scroll>
        {
          // Disable scrolling and add a semi-transparent overlay to the view
          this.props.disableView && <Overlay />
        }
      </Container>
    );
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
    queries: state.report.queries,
    queryData: state.report.queryData,
    queryLoading: state.report.queryLoading,
    workplaceFilters: state.report.workplaceFilters,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    getUtilization: reportActions.getUtilization,
    getPeopleCount: reportActions.getPeopleCount,
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(ReportPage);

const Container = styled.div`
  height: 100%;
  background-color: #f6f6f6;
  width: 100%;
`;

const Scroll = styled.div`
  position: relative;
  max-height: 100%;
  height: 100%;
  overflow: ${(props) => props.overflow};
`;

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(216, 216, 216, 0.7);
  z-index: 3;
  cursor: pointer;
`;

const ReportContainer = styled.div`
  display: flex;
  flex-direction: column; 
  padding-left: ${props => props.$mobile ? "20px" : "40px"};
  padding-right: ${props => props.$mobile ? "20px" : "40px"};
  padding-top: 20px;
  padding-bottom: 40px;
  min-width: 500px;
  max-width: 1200px;

  h1, h2, h3, h4, h5, h6, p, ol, li {
    margin-top: 0;
  }

  h1 {
    margin-bottom: 15px;
  }

  h2, h3, h4, h5, h6 {
    margin-bottom: 15px;
  }

  p, ol {
    margin-bottom: 20px;
    font-size: 16px;
  }

  li {
    margin-bottom: 10px;
  }

  user-select: auto;
  -webkit-user-select: auto;
  -moz-user-select: auto;
  -ms-user-select: auto;
  -webkit-touch-callout: auto;
  -khtml-user-select: auto;
  -webkit-tap-highlight-color: auto;
  pointer-events: auto;
  touch-action: auto;
`;

const Row = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: space-around;
  margin-bottom: 15px;
`;

// Set a fixed width for the graphs
// This is to prevent the graphs from being too small on small screens
// and show the graphs side by side on large screens
const Col = styled.div`
  position: relative;
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: ${(props) => props.$expanded ? "100%" : (props.$isGraph ? "50%" : "100%")};
  width: ${(props) => props.$expanded ? "100%" : (props.$isGraph ? "50%" : "100%")};
  min-width: ${(props) => props.$expanded ? "100%" : (props.$isGraph ? "508px" : "200px")};
  max-width: ${(props) => props.$expanded ? "100%" : (props.$isGraph ? "calc(50% - 5px)" : "100%")};
`;

const Menu = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 25px;
  // margin-bottom: 10px;

  > div {
    margin-right: 10px;
    margin-top: 0px;
    margin-bottom: 5px;
  }

  > div:last-child {
    margin-right: 0;
    margin-top: 0px;
    margin-bottom: 5px;
  }
`;

const MenuButton = styled.div`
  display: inline-block;
  cursor: pointer;
  margin-top: 10px;
  margin-bottom: 10px;
  background-color: ${(props) => props.$isSelected ? "#1C4D82" : "#fff"};
  border-color: #1C4D82;
  border-radius: 6px;
  border-width: 1px;
  border-style: solid;
  padding: 8px 15px;

  span {
    color: ${(props) => props.$isSelected ? "#fff" : "#1C4D82" };
    font-weight: 600;
    font-size: 15px;
    user-select: none;
  }
`;

const HelpIcon = styled(FontAwesomeIcon)`
  margin-left: 10px;
  color: #222;
  cursor: pointer;

  &:hover {
    color: #000;  // Change the color on hover
  }
`;

const Block = styled.div`
  display: block;
  position: relative;
  box-sizing: border-box;
  background-color: #fff;
  border-radius: 6px;
  border-width: 1px;
  border-style: solid;
  border-color: #ddd;
  font: 12px/20px 'Source Sans Pro', 'Helvetica Neue', Arial, Helvetica, sans-serif;
  margin-bottom: 10px;
  padding: 8px 15px;
`;