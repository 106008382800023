import LocalizedStrings from "react-localization";

export default new LocalizedStrings({
  en: {
    close: "Close",
    save: "Save",
    back: "Back",
    locationTitle: "Location",
    selectLocation: "Select a location",
    locationDescription: "Choose a location with utilization {0} or people count {1} that matches the kind of data you want to inspect. If an icon has a {2} tag, then a location further down the location hierarchy has that kind of data as well.",
    timePeriodTitle: "Time period",
    quickSelect: "Quick select",
    everything: "Everything",
    thisYear: "This year",
    last12Months: "Last 12 months",
    last6Months: "Last 6 months",
    last3Months: "Last 3 months",
    thisMonth: "This month",
    selectDateRange: "Select date range",
    fill: "Fill",
    clear: "Clear",
    holidays: "Holidays",
    weekends: "Weekends",
    show: "Included",
    hide: "Removed",
    hasUtilizationData: "Has utilization data",
    noUtilizationData: "No utilization data",
    hasPeopleCountData: "Has people count data",
    noPeopleCountData: "No people count data",
  },
  nb: {
    close: "Lukk",
    save: "Lagre",
    back: "Tilbake",
    locationTitle: "Lokasjon",
    selectLocation: "Velg en lokasjon",
    locationDescription: "Velg en lokasjon med utnyttelse {0} eller mennesketelling {1} som passer til den typen data du vil se på. Hvis et ikon har et {2} merke, har en lokasjon lenger ned i lokasjonshierarkiet den typen data også.",
    timePeriodTitle: "Tidsperiode",
    quickSelect: "Hurtigvalg",
    everything: "Alt",
    thisYear: "Dette året",
    last12Months: "Forrige 12 måneder",
    last6Months: "Forrige 6 måneder",
    last3Months: "Forrige 3 måneder",
    thisMonth: "Denne måneden",
    selectDateRange: "Velg datoperiode",
    fill: "Fyll",
    clear: "Tøm",
    holidays: "Fridager",
    weekends: "Helger",
    show: "Inkludert",
    hide: "Fjernet",
    hasUtilizationData: "Har utnyttelsesdata",
    noUtilizationData: "Ingen utnyttelsesdata",
    hasPeopleCountData: "Har mennesketellingsdata",
    noPeopleCountData: "Ingen mennesketellingsdata",
  }
});
