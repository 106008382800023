import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import style from './style.module.scss';

export const GREEN = '#1c9548';
export const BLUE = '#1C4D82';
export const RED = '#c12d2a';
export const BROWN = '#95521c';
export const PURPLE = '#841c95';
export const GREY = '#d3d3d3';
export const DARKGREY = '#888';
export const ORANGE = '#ff9943';

export const RowIcon = ({ bgColor, icon, styles, tooltip, size = "sm", fontSize }) => (
  <div title={tooltip} className={style.icon} style={{ backgroundColor: bgColor, ...styles }}>
    {icon && <FontAwesomeIcon icon={icon} color="white" size={!fontSize ? size : undefined} fontSize={fontSize}/>}
  </div>
);
