import { get, isEmpty, isEqual } from "lodash";
import moment from "moment";
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import styled from "styled-components";
import { Hidden, Visible } from "react-grid-system";
import { Col, Row } from ".";
import DetailsHeader from "../../components/DetailsHeader";
import InputBox from "../../components/InputBox";
import Loader from "../../components/Loader";
import OptionFooter from "../../components/OptionFooter";
import SearchBox from "../../components/SearchBox";
import SmallButton from "../../components/SmallButton";
import TopRowOptions from "../../components/TopRowOptions";
import SubNavigation from "../../components/SubNavigation";
import Table from "../../components/Table";
import * as accountActions from "../../actions/account";

const tabBarLinks = [
  { label: `Details` },
  { label: `Translations` }
];

class GraphResources extends Component {

  constructor(props) {
    super(props);
    this.state = {
      searchText: undefined,
      activeSearch: undefined,
      sortBy: "resourceType",
      sortOrder: "asc",
      newResourceType: "",
      newResourceTranslations: {},
      newResourceStartTime: "00:00",
      newResourceEndTime: "00:00",
      newResourceSlotCount: 1,
      newResourceMaxPerDay: 1,
      newResourceMaxPerSlot: 1,
      selectedTabIndex: 0,
      hasLoadedState: false,
    };

    this.selectedTab = this.selectedTab.bind(this);
    this.onNewResourceClick = this.onNewResourceClick.bind(this);
    this.onCancelClick = this.onCancelClick.bind(this);
    this.onRowClick = this.onRowClick.bind(this);
    this.onSave = this.onSave.bind(this);
    this.onDelete = this.onDelete.bind(this);
    this.onNewResourceTypeChange = this.onNewResourceTypeChange.bind(this);
    this.onNewResourceTranslationChange = this.onNewResourceTranslationChange.bind(this);
    this.onNewResourceStartTimeChange = this.onNewResourceStartTimeChange.bind(this);
    this.onNewResourceEndTimeChange = this.onNewResourceEndTimeChange.bind(this);
    this.onNewResourceSlotCountChange = this.onNewResourceSlotCountChange.bind(this);
    this.onNewResourceMaxPerDayChange = this.onNewResourceMaxPerDayChange.bind(this);
    this.onNewResourceMaxPerSlotChange = this.onNewResourceMaxPerSlotChange.bind(this);

    const resourceType = encodeURIComponent(this.props.resourceId);
    const foundGraphResource = this.props.graphResources.find(item => item.resourceType === resourceType);
    if (this.props.resourceId === undefined || !foundGraphResource) {
      this.props.getGraphResources();
    }
  }
  
  static getDerivedStateFromProps(nextProps, prevState) {

    if (prevState.hasLoadedState) {
      return null;
    }

    // If we are creating a new resource, reset the state
    if (nextProps.resourceId === "new-graph-resource-type") {
      return { 
        newResourceType: "",
        newResourceTranslations: {},
        newResourceStartTime: "00:00",
        newResourceEndTime: "00:00",
        newResourceSlotCount: 1,
        newResourceMaxPerDay: 1,
        newResourceMaxPerSlot: 1,
        hasLoadedState: true
      };
    }

    // If we are editing an existing resource, load the state
    const resourceType = encodeURIComponent(nextProps.resourceId);
    const foundGraphResource = nextProps.graphResources.find(item => item.resourceType === resourceType);
    if (foundGraphResource && prevState.newResourceType !== resourceType) {

      let slotCount;
      if (foundGraphResource.slotSizeMinutes === 0 || foundGraphResource.slotSizeMinutes === 1440) {
        slotCount = 1;
      }
      else {
        slotCount = Math.round((foundGraphResource.endTime.hours * 60 + foundGraphResource.endTime.minutes - foundGraphResource.startTime.hours * 60 - foundGraphResource.startTime.minutes) / foundGraphResource.slotSizeMinutes);
      }

      return {
        newResourceType: foundGraphResource.resourceType,
        newResourceTranslations: foundGraphResource.translations,
        newResourceStartTime: (foundGraphResource.startTime.hours < 10 ? "0" + foundGraphResource.startTime.hours : foundGraphResource.startTime.hours) + ":" + (foundGraphResource.startTime.minutes < 10 ? "0" + foundGraphResource.startTime.minutes : foundGraphResource.startTime.minutes),
        newResourceEndTime: (foundGraphResource.endTime.hours < 10 ? "0" + foundGraphResource.endTime.hours : foundGraphResource.endTime.hours) + ":" + (foundGraphResource.endTime.minutes < 10 ? "0" + foundGraphResource.endTime.minutes : foundGraphResource.endTime.minutes),
        newResourceSlotCount: slotCount,
        newResourceMaxPerDay: foundGraphResource.maxBookingsPerDay ?? 1,
        newResourceMaxPerSlot: foundGraphResource.maxBookingsPerSlot ?? 1,
        hasLoadedState: true
      }
    }

    return null;
  }

  selectedTab(index) {
    this.setState({ selectedTabIndex: index });
  }

  onNewResourceClick() {
    let link = `/companies/${this.props.match.params.companyId}/account/graph-resources/new-graph-resource-type`;
    this.props.history.push(link);

    this.setState({
      newResourceType: "",
      newResourceTranslations: {},
      newResourceStartTime: "00:00",
      newResourceEndTime: "00:00",
      newResourceSlotCount: 1,
      newResourceMaxPerDay: 1,
      newResourceMaxPerSlot: 1,
      selectedTabIndex: 0
    });
  }

  onCancelClick() {

    if (this.props.resourceId === "new-graph-resource-type") {
      let link = `/companies/${this.props.match.params.companyId}/account/graph-resources`;
      this.props.history.push(link);

      this.setState({
        newResourceType: "",
        newResourceTranslations: {},
        newResourceStartTime: "00:00",
        newResourceEndTime: "00:00",
        newResourceSlotCount: 1,
        newResourceMaxPerDay: 1,
        newResourceMaxPerSlot: 1,
        selectedTabIndex: 0
      });
    }
    else {
      // If we are editing an existing resource, reset the state
      const resourceType = encodeURIComponent(this.props.resourceId);
      const foundGraphResource = this.props.graphResources.find(item => item.resourceType === resourceType);
      if (foundGraphResource) {

        let slotCount;
        if (foundGraphResource.slotSizeMinutes === 0 || foundGraphResource.slotSizeMinutes === 1440) {
          slotCount = 1;
        }
        else {
          slotCount = Math.round((foundGraphResource.endTime.hours * 60 + foundGraphResource.endTime.minutes - foundGraphResource.startTime.hours * 60 - foundGraphResource.startTime.minutes) / foundGraphResource.slotSizeMinutes);
        }

        this.setState({
          newResourceType: foundGraphResource.resourceType,
          newResourceTranslations: foundGraphResource.translations,
          newResourceStartTime: (foundGraphResource.startTime.hours < 10 ? "0" + foundGraphResource.startTime.hours : foundGraphResource.startTime.hours) + ":" + (foundGraphResource.startTime.minutes < 10 ? "0" + foundGraphResource.startTime.minutes : foundGraphResource.startTime.minutes),
          newResourceEndTime: (foundGraphResource.endTime.hours < 10 ? "0" + foundGraphResource.endTime.hours : foundGraphResource.endTime.hours) + ":" + (foundGraphResource.endTime.minutes < 10 ? "0" + foundGraphResource.endTime.minutes : foundGraphResource.endTime.minutes),
          newResourceSlotCount: slotCount,
          newResourceMaxPerDay: foundGraphResource.maxBookingsPerDay ?? 1,
          newResourceMaxPerSlot: foundGraphResource.maxBookingsPerSlot ?? 1,
          hasLoadedState: true
        });
      }
    }
  }
  
  onRowClick(column, row) {
    return {
      onClick: e => {
        if (column.name !== 'isSelected' && row !== undefined) {

          let link = `/companies/${this.props.match.params.companyId}/account/graph-resources/${row.original.resourceType}`;

          if (e.metaKey || e.ctrlKey) {
            window.open(`${link}`);
          }
          else {
            this.props.history.push(link);
          }

          let slotCount;
          if (row.original.slotSizeMinutes === 0 || row.original.slotSizeMinutes === 1440) {
            slotCount = 1;
          }
          else {
            slotCount = Math.round((row.original.endTime.hours * 60 + row.original.endTime.minutes - row.original.startTime.hours * 60 - row.original.startTime.minutes) / row.original.slotSizeMinutes);
          }

          this.setState({
            newResourceType: row.original.resourceType,
            newResourceTranslations: row.original.translations,
            newResourceStartTime: (row.original.startTime.hours < 10 ? "0" + row.original.startTime.hours : row.original.startTime.hours) + ":" + (row.original.startTime.minutes < 10 ? "0" + row.original.startTime.minutes : row.original.startTime.minutes),
            newResourceEndTime: (row.original.endTime.hours < 10 ? "0" + row.original.endTime.hours : row.original.endTime.hours) + ":" + (row.original.endTime.minutes < 10 ? "0" + row.original.endTime.minutes : row.original.endTime.minutes),
            newResourceSlotCount: slotCount,
            newResourceMaxPerDay: row.original.maxBookingsPerDay ?? 1,
            newResourceMaxPerSlot: row.original.maxBookingsPerSlot ?? 1
          });
        }
      },
      style: {
        cursor: "pointer",
        background: (
          row && row.original.resourceType === this.state.newResourceType ? "rgba(0,0,0,0.05)" : null)
      }
    }
  }

  onNewResourceTypeChange(event) {
    this.setState({ newResourceType: event.target.value });
  }

  onNewResourceTranslationChange(language, event) {
    const newTranslation = event.target.value;
    this.setState(prevState => ({ newResourceTranslations: { ...prevState.newResourceTranslations, [language]: newTranslation }}));
  }

  onNewResourceStartTimeChange(event) {
    this.setState({ newResourceStartTime: event.target.value });
  }

  onNewResourceEndTimeChange(event) {
    this.setState({ newResourceEndTime: event.target.value });
  }

  onNewResourceSlotCountChange(event) {
    let slotCount = parseInt(event.target.value);
    this.setState({ newResourceSlotCount: slotCount });
  }

  onNewResourceMaxPerDayChange(event) {
    let max = parseInt(event.target.value);
    this.setState({ newResourceMaxPerDay: max });
  }

  onNewResourceMaxPerSlotChange(event) {
    let max = parseInt(event.target.value);
    this.setState({ newResourceMaxPerSlot: max });
  }
  
  onSave() {
    let startTimeDate = moment(this.state.newResourceStartTime, "HH:mm");
    let endTimeDate = this.state.newResourceEndTime === "00:00" ? moment(this.state.newResourceEndTime, "HH:mm").add(1, "day") : moment(this.state.newResourceEndTime, "HH:mm");

    // Check if duration can be divided into slots (no remainder)
    const duration = moment.duration(endTimeDate.diff(startTimeDate));
    let slotSize = 0;
    let durationInMinutes = duration.asMinutes();
    if (durationInMinutes % this.state.newResourceSlotCount === 0) {
      slotSize = durationInMinutes / this.state.newResourceSlotCount;
    }

    const newGraphResource = {
      resourceType: this.state.newResourceType,
      translations: this.state.newResourceTranslations,
      startTime: { hours: startTimeDate.hours(), minutes: startTimeDate.minutes() },
      endTime: { hours: endTimeDate.hours(), minutes: endTimeDate.minutes() },
      slotSizeMinutes: slotSize,
      maxBookingsPerDay: this.state.newResourceMaxPerDay,
      maxBookingsPerSlot: this.state.newResourceMaxPerSlot,
      isAllDay: this.state.newResourceSlotCount === 1
    };

    // Add new resource
    const existingResourceIndex = this.props.graphResources.findIndex(slot => slot.resourceType === this.state.newResourceType);
    if (existingResourceIndex === -1) {
      this.props.addGraphResource(newGraphResource);
    }
    else {
      this.props.updateGraphResource(this.state.newResourceType, newGraphResource);
    }
  }

  onDelete() {
    this.props.deleteGraphResource(this.state.newResourceType);
  }

  translationTable() {
    return (
      <>
        <Row $paddingTop="20px">
          <Col>
            <p>The users will see these display names in the app when booking the resource. Use a descriptive and locally known name.</p>
          </Col>
        </Row>
        <Row>
          <Col>
            <InputBox
              type="text"
              label="English (default)"
              placeholder="Required"
              value={get(this.state.newResourceTranslations, "en", "")}
              onChange={(value) => this.onNewResourceTranslationChange("en", value)}
              required
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <InputBox
              type="text"
              label="Norsk"
              placeholder="Optional"
              value={get(this.state.newResourceTranslations, "nb", "")}
              onChange={(value) => this.onNewResourceTranslationChange("nb", value)}
            />
          </Col>
        </Row>
      </>
    );
  }

  optionFooter() {
    const existingResourceIndex = this.props.graphResources.findIndex(slot => slot.resourceType === this.state.newResourceType);

    if (existingResourceIndex === -1 && this.props.resourceId !== "new-graph-resource-type") {
      return;
    }
    
    if (existingResourceIndex > -1) {
      // Check if there is any difference between the existing resource and the new resource
      const existingResource = this.props.graphResources[existingResourceIndex];

      // Add missing isAllDay property and maxBookingsPerSlot property
      if (existingResource.isAllDay === undefined) {
        existingResource.isAllDay = false;
      }

      if (existingResource.maxBookingsPerSlot === undefined) {
        existingResource.maxBookingsPerSlot = 1;
      }

      let startTimeDate = moment(this.state.newResourceStartTime, "HH:mm");
      let endTimeDate = this.state.newResourceEndTime === "00:00" ? moment(this.state.newResourceEndTime, "HH:mm").add(1, "day") : moment(this.state.newResourceEndTime, "HH:mm");

      // Check if duration can be divided into slots (no remainder)
      let newResourceSlotCountIsValid = false;
      const duration = moment.duration(endTimeDate.diff(startTimeDate));
      let slotSize = 0;
      let durationInMinutes = duration.asMinutes();
      if (this.state.newResourceSlotCount >= 0) {
        if (durationInMinutes % this.state.newResourceSlotCount === 0) {
          slotSize = durationInMinutes / this.state.newResourceSlotCount;
          newResourceSlotCountIsValid = true;
        }
      }
      const newResource = {
        resourceType: this.state.newResourceType,
        translations: this.state.newResourceTranslations,
        startTime: { hours: startTimeDate.hours(), minutes:startTimeDate.minutes() },
        endTime: { hours: endTimeDate.hours(), minutes: endTimeDate.minutes() },
        slotSizeMinutes: slotSize,
        maxBookingsPerDay: this.state.newResourceMaxPerDay,
        maxBookingsPerSlot: this.state.newResourceMaxPerSlot,
        isAllDay: this.state.newResourceSlotCount === 1
      };

      const newResourceTypeIsValid = !isEmpty(this.state.newResourceType);
      const newResourceStartTimeIsValid = !isEmpty(this.state.newResourceStartTime) && moment(this.state.newResourceStartTime, "HH:mm").isValid();
      const newResourceEndTimeIsValid = !isEmpty(this.state.newResourceEndTime) && moment(this.state.newResourceEndTime, "HH:mm").isValid() && (this.state.newResourceEndTime === "00:00" || moment(this.state.newResourceEndTime, "HH:mm").isAfter(moment(this.state.newResourceStartTime, "HH:mm")));
      const newResourceMaxPerDayIsValid = this.state.newResourceMaxPerDay >= 0;
      const newResourceMaxPerSlotIsValid = this.state.newResourceMaxPerSlot >= 0;

      const isResourceEqual = isEqual(existingResource, newResource);
      if (!isResourceEqual) {
        return (
          <OptionFooter
            inline={true}
            cancel={this.onCancelClick}
            options={[
              { 
                label: "Update",
                callback: this.onSave,
                disabled: !newResourceTypeIsValid || !newResourceStartTimeIsValid || !newResourceEndTimeIsValid || !newResourceMaxPerDayIsValid || !newResourceMaxPerSlotIsValid || !newResourceSlotCountIsValid || isEmpty(this.state.newResourceTranslations.en)
              }
            ]}
          />
        );
      }
    }
    else {

      let startTimeDate = moment(this.state.newResourceStartTime, "HH:mm");
      let endTimeDate = this.state.newResourceEndTime === "00:00" ? moment(this.state.newResourceEndTime, "HH:mm").add(1, "day") : moment(this.state.newResourceEndTime, "HH:mm");

      // Check if duration can be divided into slots (no remainder)
      let newResourceSlotCountIsValid = false;
      const duration = moment.duration(endTimeDate.diff(startTimeDate));
      let slotSize = 0;
      let durationInMinutes = duration.asMinutes();
      if (this.state.newResourceSlotCount >= 0) {
        if (durationInMinutes % this.state.newResourceSlotCount === 0) {
          slotSize = durationInMinutes / this.state.newResourceSlotCount;
          newResourceSlotCountIsValid = true;
        }
      }

      const newResourceTypeIsValid = !isEmpty(this.state.newResourceType);
      const newResourceStartTimeIsValid = !isEmpty(this.state.newResourceStartTime) && moment(this.state.newResourceStartTime, "HH:mm").isValid();
      const newResourceEndTimeIsValid = !isEmpty(this.state.newResourceEndTime) && moment(this.state.newResourceEndTime, "HH:mm").isValid() && (this.state.newResourceEndTime === "00:00" || moment(this.state.newResourceEndTime, "HH:mm").isAfter(moment(this.state.newResourceStartTime, "HH:mm")));
      const newResourceMaxPerDayIsValid = this.state.newResourceMaxPerDay >= 0;
      const newResourceMaxPerSlotIsValid = this.state.newResourceMaxPerSlot >= 0;

      return (
        <OptionFooter
          inline={true}
          cancel={this.onCancelClick}
          options={[
            { 
              label: "Save",
              callback: this.onSave,
              disabled: !newResourceTypeIsValid || !newResourceStartTimeIsValid || !newResourceEndTimeIsValid || !newResourceMaxPerDayIsValid || !newResourceMaxPerSlotIsValid || !newResourceSlotCountIsValid || isEmpty(this.state.newResourceTranslations.en)
            }
          ]}
        />
      );
    }
    
  }

  render() {
    // const { isLoading } = this.props;

    // console.log("this.state", this.state);
    // console.log("this.props", this.props);

    if (this.props.isLoading) {
      return <Loader fullScreen />;
    }

    // Create table for resource types
    let resourceTypeTable;
    let resourceDetails;

    const newResourceTypeIsValid = !isEmpty(this.state.newResourceType);
    const newResourceStartTimeIsValid = !isEmpty(this.state.newResourceStartTime) && moment(this.state.newResourceStartTime, "HH:mm").isValid();
    const newResourceEndTimeIsValid = !isEmpty(this.state.newResourceEndTime) && moment(this.state.newResourceEndTime, "HH:mm").isValid() && (this.state.newResourceEndTime === "00:00" || moment(this.state.newResourceEndTime, "HH:mm").isAfter(moment(this.state.newResourceStartTime, "HH:mm")));
    const newResourceMaxPerDayIsValid = this.state.newResourceMaxPerDay >= 0;
    const newResourceMaxPerSlotIsValid = this.state.newResourceMaxPerSlot >= 0;

    let newResourceSlotCountIsValid = false;
    let slotSize = 0;
    let durationInMinutes = 0;
    if (newResourceStartTimeIsValid && newResourceEndTimeIsValid && this.state.newResourceSlotCount > 0) {
      const newResourceStartTime = moment(this.state.newResourceStartTime, "HH:mm");
      const newResourceEndTime = this.state.newResourceEndTime === "00:00" ? moment(this.state.newResourceEndTime, "HH:mm").add(1, "day") : moment(this.state.newResourceEndTime, "HH:mm");

      // Check if duration can be divided into slots (no remainder)
      const duration = moment.duration(newResourceEndTime.diff(newResourceStartTime));
      durationInMinutes = duration.asMinutes();
      if (durationInMinutes % this.state.newResourceSlotCount === 0) {
        slotSize = durationInMinutes / this.state.newResourceSlotCount;
        newResourceSlotCountIsValid = true;
      }
    }

    // Filter out resources that are not in the search
    let filteredResources = this.props.graphResources;
    if (this.state.activeSearch) {
      filteredResources = this.props.graphResources.filter((resource) => {
        return resource.resourceType.toLowerCase().includes(this.state.activeSearch.toLowerCase());
      });
    }

    // Sort resources
    filteredResources = filteredResources.sort((a, b) => {
      if (this.state.sortBy === "resourceType") {
        if (this.state.sortOrder === "asc") {
          return a.resourceType.localeCompare(b.resourceType);
        }
        else {
          return b.resourceType.localeCompare(a.resourceType);
        }
      }
    });

    resourceTypeTable = (
      <>
        <Table
          data={filteredResources}
          noDataText="No resource types"
          sortBy={this.state.sortBy}
          sortOrder={this.state.sortOrder}
          onSortedChange={(newSorted) => {
            this.setState({ sortBy: newSorted[0].id, sortOrder: newSorted[0].desc ? "desc" : "asc" });
          }}
          columns={[
            {
              header: "Type", 
              accessorKey: "resourceType", 
              cell: ({ row }) => {
                return <div>{ decodeURIComponent(row.original.resourceType) }</div>
              }
            },
            {
              header: "Time", 
              accessorKey: "startTime", 
              sortable: false,
              cell: ({ row }) => {
                let startTime = moment((row.original.startTime.hours + ":" + row.original.startTime.minutes), "HH:mm");
                let endTime = moment((row.original.endTime.hours + ":" + row.original.endTime.minutes), "HH:mm");
                return <div>{startTime.format("HH:mm")} - {endTime.format("HH:mm")}</div>
              }
            },
            {
              header: "Slots per day",
              accessorKey: "slotSizeMinutes",
              sortable: false,
              cell: ({ row }) => {
                let startTime = moment((row.original.startTime.hours + ":" + row.original.startTime.minutes), "HH:mm");
                let endTime = moment((row.original.endTime.hours + ":" + row.original.endTime.minutes), "HH:mm");
                if (row.original.endTime.hours === 0 && row.original.endTime.minutes === 0) {
                  endTime = endTime.add(1, "day");
                }

                if (row.original.slotSizeMinutes > 0 && startTime.isValid() && endTime.isValid() && startTime.isBefore(endTime)) {
                  const duration = moment.duration(endTime.diff(startTime));
                  const durationInMinutes = duration.asMinutes();
                  const slotCount = durationInMinutes / row.original.slotSizeMinutes;
                  return <div>{slotCount} ({row.original.slotSizeMinutes} min)</div>
                }
                return <div>Invalid</div>
              }
            },
            {
              header: "Max per day", 
              accessorKey: "maxBookingsPerDay",
              sortable: false,
              cell: ({ row }) => {
                if (row.original.maxBookingsPerDay !== 0) {
                  return <div>{row.original.maxBookingsPerDay}</div>
                }

                return <div>No limit</div>
              }
            },
            {
              header: "Max per slot", 
              accessorKey: "maxBookingsPerSlot",
              sortable: false,
              cell: ({ row }) => {
                if (row.original.maxBookingsPerSlot !== 0) {
                  return <div>{row.original.maxBookingsPerSlot ?? 1}</div>
                }

                return <div>No limit</div>
              }
            },
            {
              id: "arrow",
              header: "",
              sortable: false,
              className: "pull-right",
              width: 60,
              cell: () => <div className="arrow" />
            }
          ]}
          getTdProps={this.onRowClick}
          className="-row-clickable setMaxHeigth -highlight"
          loading={this.props.isLoading}
        />
      </>
    );

    const resourceType = encodeURIComponent(this.props.resourceId);
    const foundGraphResource = this.props.graphResources.find(item => item.resourceType === resourceType);
    if (foundGraphResource || resourceType === "new-graph-resource-type") {
      switch (this.state.selectedTabIndex) {
        case 0:
          resourceDetails = (
            <>
              <Row $paddingTop="20px">
                <Col>
                  <p>Type is an unique id for the resource. Start/end time define the available period for booking (set the time period to 00:00-00:00 if you want the period to be 24 hours). Slots per day split the time period into slots (a slot has to be a whole minute). Max per day define how many times a user can book the resource per day. Max per slot define how many times a user can book the same slot.</p>
                </Col>
              </Row>
              { resourceType === "new-graph-resource-type" && (
                <Row>
                  <Col>
                    <InputBox
                      type="text"
                      label="Type"
                      // placeholder="Name of type"
                      placeholder="Required"
                      value={this.state.newResourceType}
                      onChange={this.onNewResourceTypeChange}
                      valid={newResourceTypeIsValid}
                      showValidOutline={!newResourceTypeIsValid}
                      // info="required"
                      required
                    />
                  </Col>
                </Row>
              )}
             
              <Row>
                {/* <InputContainer>
                  <InputBox
                    type="text"
                    label="Type (norsk)"
                    // placeholder="Navn på type"
                    placeholder="Optional"
                    value={this.state.newResourceTypeNorsk}
                    onChange={this.onNewResourceTypeNorskChange}
                    // info="optional"
                  /> 
                </InputContainer> */}
                <InputContainer>
                  <InputBox
                    type="time"
                    label="Start time"
                    value={this.state.newResourceStartTime}
                    onChange={this.onNewResourceStartTimeChange}
                    valid={newResourceStartTimeIsValid}
                    showValidOutline={!newResourceStartTimeIsValid}
                    required
                  />
                </InputContainer>
                <InputContainer>
                  <InputBox
                    type="time"
                    label="End time"
                    value={this.state.newResourceEndTime}
                    onChange={this.onNewResourceEndTimeChange}
                    valid={newResourceEndTimeIsValid}
                    showValidOutline={!newResourceEndTimeIsValid}
                    info={newResourceStartTimeIsValid && newResourceEndTimeIsValid ? (this.state.newResourceEndTime === "00:00" ? "all day" : `(${durationInMinutes} min)`) : "invalid"}
                    required
                  />
                </InputContainer>
              </Row>
              <Row>
                <InputContainer>
                  <InputBox 
                    type="number"
                    label="Slots per day"
                    value={this.state.newResourceSlotCount}
                    onChange={this.onNewResourceSlotCountChange}
                    valid={newResourceSlotCountIsValid}
                    showValidOutline={!newResourceSlotCountIsValid}
                    info={slotSize > 0 ? `(${slotSize} min)` : "not a whole number"}
                    required
                  />
                </InputContainer>
                <InputContainer>
                  <InputBox
                    type="number"
                    label="Max per day"
                    value={this.state.newResourceMaxPerDay}
                    onChange={this.onNewResourceMaxPerDayChange}
                    valid={newResourceMaxPerDayIsValid}
                    showValidOutline={!newResourceMaxPerDayIsValid}
                    info="(0 = no limit)"
                    required
                  />
                </InputContainer>
                <InputContainer>
                  <InputBox
                    type="number"
                    label="Max per slot"
                    value={this.state.newResourceMaxPerSlot}
                    onChange={this.onNewResourceMaxPerSlotChange}
                    valid={newResourceMaxPerSlotIsValid}
                    showValidOutline={!newResourceMaxPerSlotIsValid}
                    info="(0 = no limit)"
                    required
                  />
                </InputContainer>
              </Row>
              {/* <Row>
                <InputContainer $alignSelf="center">
                  <SmallButton
                    text={resourceSlots.filter(slot => slot.resourceType === this.state.newResourceType).length > 0 ? "Update" : "Add"}
                    style={{ marginTop: "20px", marginBottom: "2px", marginLeft: "0px" }}
                    disabled={!newResourceTypeIsValid || !newResourceStartTimeIsValid || !newResourceEndTimeIsValid || !newResourceSlotCountIsValid || !newResourceMaxIsValid}
                    onClick={this.addNewGraphResource}
                  />
                </InputContainer>
              </Row> */}
            </>
          );
          break;
        case 1:
          resourceDetails = this.translationTable();
          break;
        default:
          break;
      }
    }
    

    return (
      <>
        <Hidden xs sm md>
          <SplitView>
            <LeftContainer>
              <ListContainer>
                <TopRowOptions
                  searchbox={(
                    <SearchBox
                      value={this.state.searchText}
                      onSearchChanged={(value) => this.setState({ searchText: value === '' ? undefined : value })}
                      onSearchClick={() => this.setState(prevState => ({ activeSearch: prevState.searchText }))}
                      onClear={() => this.setState({ searchText: undefined, activeSearch: undefined })}
                      inListView
                    />
                  )}
                  buttons={[
                    <SmallButton key="b1" text="New resource" onClick={this.onNewResourceClick} singleLine noMargin />
                  ]}
                
                />
                <Row $paddingTop="20px">
                  <Col>
                    { resourceTypeTable }
                  </Col>
                </Row>
              </ListContainer>
            </LeftContainer>
            <RightContainer>
              { resourceDetails && (
                <ListContainer>
                  <DetailsHeader
                    title={resourceType === "new-graph-resource-type" ? "New resource" : decodeURIComponent(this.state.newResourceType) }
                    canUpdate={resourceType !== "new-graph-resource-type"}
                    isLoading={this.props.isLoading}
                    onDeleteClicked={resourceType !== "new-graph-resource-type" ? this.onDelete : null}
                  />
                  <SubNavigation links={tabBarLinks} selectedIndex={this.state.selectedTabIndex} onClick={this.selectedTab} slim />
                  { resourceDetails }
                </ListContainer> 
              )}
            </RightContainer>
          </SplitView>
        </Hidden>
        <Visible xs sm md>
          <SingleView style={{ backgroundColor: resourceDetails && "#F6F6F6" }}>
            { resourceDetails ? (
              <SlimListContainer>
                <DetailsHeader
                  title={resourceType === "new-graph-resource-type" ? "New resource" : decodeURIComponent(this.state.newResourceType) }
                  canUpdate={resourceType !== "new-graph-resource-type"}
                  isLoading={this.props.isLoading}
                  onDeleteClicked={resourceType !== "new-graph-resource-type" ? this.onDelete : null}
                />
                <SubNavigation links={tabBarLinks} selectedIndex={this.state.selectedTabIndex} onClick={this.selectedTab} slim />
                { resourceDetails }
                <div style={{ paddingTop: "40px" }} />
              </SlimListContainer> 
            ) : (
              <SlimListContainer>
                <TopRowOptions
                  searchbox={(
                    <SearchBox
                      value={this.state.searchText}
                      onSearchChanged={(value) => this.setState({ searchText: value === '' ? undefined : value })}
                      onSearchClick={() => this.setState(prevState => ({ activeSearch: prevState.searchText }))}
                      onClear={() => this.setState({ searchText: undefined, activeSearch: undefined })}
                      inListView
                    />
                  )}
                  buttons={[
                    <SmallButton key="b1" text="New resource" onClick={this.onNewResourceClick} singleLine noMargin />
                  ]}
                
                />
                <Row $paddingTop="20px">
                  <Col>
                    { resourceTypeTable }
                  </Col>
                </Row>
              </SlimListContainer>
            )
          }
          </SingleView>
        </Visible>
        
        { this.optionFooter() }
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
    graphResources: state.graphResources.data,
    isLoading: state.loading.graphResources
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ 
    getGraphResources: accountActions.getGraphResources,
    // getGraphResource: accountActions.getGraphResource,
    addGraphResource: accountActions.addGraphResource,
    updateGraphResource: accountActions.updateGraphResource,
    deleteGraphResource: accountActions.deleteGraphResource
  }, dispatch);
}

export default connect(mapStateToProps,mapDispatchToProps)(GraphResources);

const SplitView = styled.div`
  display: flex;
  flex-direction: row;
  height: calc(100vh - 188px);
  overflow: auto;
`;

const SingleView = styled.div`
  display: block;
  height: calc(100vh - 188px);
  overflow: auto;
`;

const LeftContainer = styled.div`
  overflow: auto;
  height: 100%;
  width: 60%;
  box-shadow: inset -1px 0 0 0 #dbdbdb;
`;

const ListContainer = styled.div`
  padding-left: 60px;
  padding-right: 60px;
  padding-bottom: 40px;
`;

const SlimListContainer = styled.div`
  padding-left: 10px;
  padding-right: 10px;
`;

const RightContainer = styled.div`
  overflow: auto;
  height: 100%;
  width: 40%;
  background-color: #f6f6f6;

  h1, h2, h3, h4, h5, h6, p, ol, li {
    margin-top: 0;
    margin-bottom: 0;
  }
`;

const InputContainer = styled.div`
  margin-right: 20px;
  align-self: ${props => props.$alignSelf || "start" };
`;