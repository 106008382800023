export default {
  "28": " kg/m²",
  "2N": " dB",
  "4H": " µm",
  "4K": " mA",
  "4P": " N/m",
  "59": " ppm",
  "A24": " cd/m²",
  "A86": " GHz",
  "A94": " g/mol",
  "B22": " kA",
  "B32": " kg • m2",
  "B43": " kJ/(kg.K)",
  "B49": " kΩ",
  "B61": " lm/W",
  "BAR": " bar",
  "C16": " mm/s",
  "C24": " mPa.s",
  "C26": " ms",
  "C45": " nm",
  "C62": "",
  "C65": " Pa.s",
  "C91": " 1/K",
  "C94": " min-1",
  "CDL": " cd",
  "CEL": "°C",
  "CMQ": " cm³",
  "CMT": " cm",
  "D33": " T",
  "D52": " W/K",
  "D74": " kg/mol",
  "DAY": " d",
  "DD": "°",
  "E01": " N/cm²",
  "E32": " l/h",
  "FAR": " F",
  "GM": " g/m²",
  "GRM": " g",
  "HTZ": " Hz",
  "HUR": " h",
  "KEL": " K",
  "KGM": " kg",
  "KGS": " kg/s",
  "KHZ": " kHz",
  "KL": " kg/m",
  "KMQ": " kg/m³",
  "KVT": " kV",
  "KWT": " kW",
  "L2": " l/min",
  "LTR": " l",
  "LUM": " lm",
  "LUX": " lx",
  "MBR": " mbar",
  "MHZ": " MHz",
  "MIN": " min",
  "MMK": " mm²",
  "MMQ": " mm³",
  "MMT": " mm",
  "MPA": " MPa",
  "MQH": " m3/h",
  "MQS": " m³/s",
  "MTK": " m²",
  "MTQ": " m³",
  "MTR": " m",
  "MTS": " m/s",
  "NEW": " N",
  "NU": " N • m",
  "OHM": " Ω",
  "P1": "%",
  "PAL": " Pa",
  "SEC": " s",
  "VLT": " V",
  "WTT": " W"
};