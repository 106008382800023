import React, { useState } from "react";
import Caret from "./caret3_grey.png";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar, faLineChart } from "@fortawesome/free-solid-svg-icons";
import { faStar as faEmptyStar } from "@fortawesome/free-regular-svg-icons";
import AnimateHeight from 'react-animate-height';
import Statistics from "./statistics";

interface BuildingCardProps {
  locationId: string;
  title: string;
  favorited: boolean;
  expanded: boolean;
  showExpandToggle: boolean;
  onToggleExpand: (locationId: string) => void;
  onToggleFavorite: (locationId: string) => void;
  onChartClick: (event: React.MouseEvent<HTMLDivElement>, locationId: string) => void;
  graphs: Array<GraphProps>;
  isLoading: boolean;
}

interface GraphProps {
  title: string;
  subtitle: string;
  type: string;
  avgPercentage: number;
  avgPercentageChange: number;
  peakPercentage: number;
  peakPercentageChange: number;
  isLoading: boolean;
}

const BuildingCard: React.FC<BuildingCardProps> = ({ locationId, title, favorited, onToggleFavorite = () => { }, expanded, showExpandToggle, onToggleExpand = () => { }, onChartClick = () => { }, graphs = [], isLoading }) => {
  const [hoverStar, setHoverStar] = useState(false);
  const [hoverChart, setHoverChart] = useState(false);

  // Star icon and color
  let star = favorited ? faStar : faEmptyStar;
  let starColor = favorited ? "#f5a623" : "#6b7073";
  if (hoverStar) {
    star = faStar;
    starColor = "#6b7073";
  }

  // Chart color
  let chartColor = "#6b7073";
  if (hoverChart) {
    chartColor = "#1c4d82";
  }

  const noGraphs = graphs.find(graph => graph.avgPercentage !== undefined || graph.peakPercentage !== undefined) === undefined;

  return (
    <Container>
      <Header $showExpandToggle={showExpandToggle} onClick={() => onToggleExpand(locationId)}>
        <div>
          {
            locationId && (
              <StarContainer onClick={(event) => { onToggleFavorite(locationId); event.stopPropagation(); }} onMouseEnter={() => setHoverStar(true)} onMouseLeave={() => setHoverStar(false)}>
                <FontAwesomeIcon icon={star} fontSize={17} color={starColor} />
              </StarContainer>
            )
          }
          <Title $hasStar={!!locationId}>
            {title}
          </Title>
        </div>
        <RightContainer>
          <ChartContainer onClick={(event) => { onChartClick(event, locationId); event.stopPropagation(); }} onMouseEnter={() => setHoverChart(true)} onMouseLeave={() => setHoverChart(false)}>
            <FontAwesomeIcon icon={faLineChart} fontSize={17} color={chartColor} />
          </ChartContainer>
          {
            showExpandToggle && (
              <ExpandContainer>
                <ExpandIcon src={Caret} $rotated={expanded} />
              </ExpandContainer>
            )
          }
        </RightContainer>
      </Header>
      <Drawer $expanded={expanded}>
        <AnimateHeight duration={500} height={expanded ? "auto" : 0}>
          <Content>
            {
              noGraphs && !isLoading && (
                <EmptyGraph>
                  No data available
                </EmptyGraph>
              )
            }
            {
              graphs.map((graph, index) => {
                // If there is no data, don't show the graph or loading indicator
                if (!isLoading && (graph.avgPercentage === undefined || graph.peakPercentage === undefined)) {
                  return null;
                }

                let description;
                if (graph.type === "meeting") {
                  description = "The occupancy percentage of meeting rooms in the building. The peak is the 15 min when most rooms are occupied during the whole month.";
                }
                else if (graph.type === "workplace") {
                  description = "The occupancy percentage of workplaces in the building. The peak is the 15 min when most workplaces are occupied during the whole month.";
                }

                return (
                  <Statistics
                    key={index}
                    locationId={locationId}
                    title={graph.title}
                    subtitle={graph.subtitle}
                    avgPercentage={graph.avgPercentage}
                    avgPercentageChange={graph.avgPercentageChange}
                    peakPercentage={graph.peakPercentage}
                    peakPercentageChange={graph.peakPercentageChange}
                    isLoading={isLoading}
                    description={description}
                  />
                );
              })
            }
          </Content>
        </AnimateHeight>
      </Drawer>
    </Container>
  );
};

const Container = styled.div`
  display: block;
  background-color: rgb(255, 255, 255);
  border-radius: 6px;
  border-width: 1px;
  border-style: solid;
  border-color: rgb(221, 221, 221);
  margin-bottom: 20px;
  overflow: hidden;
`;

const Header = styled.div<{ $showExpandToggle: boolean }>`
  display: flex;
  height: 55px;
  align-content: space-between;
  justify-content: space-between;
  cursor: ${props => props.$showExpandToggle ? "pointer" : "default"};
  overflow: visible;
`;

const StarContainer = styled.div`
  display: inline-block;
  // padding: 0px 12px 0px 15px;
  padding: 16px 12px 16px 15px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const Title = styled.div<{ $hasStar?: boolean }>`
  display: inline-block;
  padding: 16px 0px;
  padding-left: ${props => props.$hasStar ? "0px" : "15px"};
  font-family: Source Sans Pro;
  font-size: 18px;
  font-weight: 600;
  color: #222222;
`;

const RightContainer = styled.div`
  display: flex;
  align-items: end;
  justify-content: end;
`;

const ChartContainer = styled.div`
  display: flex;
  width: 55px;
  height: 55px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const ExpandContainer = styled.div`
  display: flex;
  width: 55px;
  height: 55px;
  align-items: center;
  justify-content: center;
`;

const ExpandIcon = styled.img<{ $rotated: boolean }>`
  width: 25px;
  height: 25px;
  transform: ${props => props.$rotated && "rotate(180deg)"};
`;

const Drawer = styled.div<{ $expanded: boolean }>`
  // background-color: #fff;
  overflow: visible;
`;

const Content = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1px;
  background-color: #dbdbdb;
  border-top: 1px solid #dbdbdb;
  box-sizing: border-box
  justify-content: space-between;
`;

const EmptyGraph = styled.div`
  width: 100%;
  height: 20px;
  background-color: #fff;
  padding: 20px;
  font-family: Source Sans Pro;
  font-size: 17px;
  font-weight: 400;
  color: #222222;
`;

export default BuildingCard;
