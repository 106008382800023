import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from "moment";
import DateRange from '../DateRange';
import en from 'date-fns/locale/en-US';
import nb from 'date-fns/locale/nb';
import { findNextRangeIndex, generateStyles } from '../../utils';
import classnames from 'classnames';
import coreStyles from '../../styles';
import strings from '../strings';

class DateRangePicker extends Component {

  constructor(props) {
    super(props);

    let monthCount = 8;
    const flexItem = 280; // the width of a flex item (month) with margin
    const margin = 25; // the margin between the flex items
    const minWidth = 1100; // the minimum width of the content area
    const contentWidth = Math.min(window.innerWidth - 345 - 60 - 40 + margin, minWidth); // the width of the content area (minus the width of the sidebar and the margin)
    monthCount = Math.max(Math.floor(contentWidth / flexItem), 1);

    this.state = {
      monthCount,
    };

    this.styles = generateStyles([coreStyles, props.classNames]);

    this.onNewTimeRange = this.onNewTimeRange.bind(this);
    this.onWeekDayClick = this.onWeekDayClick.bind(this);
    this.onMonthClick = this.onMonthClick.bind(this);
  }
  
  getNewRanges(newRange, oldRanges) {
    
    let allRanges = [...oldRanges];

    // Find the ranges that overlap with the new range
    let overlappingRanges = allRanges.filter((existingRange) => {
      return moment(newRange.startDate).subtract(1, "day") <= moment(existingRange.endDate) && moment(newRange.endDate).add(1, "day") >= moment(existingRange.startDate);
    });

    // Check if the new range totally overlaps with an existing range
    // and remove that part of the existing range if it does
    if (overlappingRanges.length > 0) {
      let overlappingRangeIndex = allRanges.findIndex((existingRange) => {
        return moment(newRange.startDate) >= moment(existingRange.startDate) && moment(newRange.endDate) <= moment(existingRange.endDate);
      });

      if (overlappingRangeIndex > -1) {
        let overlappingRange = allRanges[overlappingRangeIndex];
        let newRanges = [];

        if (moment(newRange.startDate).isSame(moment(overlappingRange.startDate))) {
          if (moment(newRange.endDate).isSame(moment(overlappingRange.endDate))) {
            // The new range is the same as the existing range
            // so we can just remove the existing range
          }
          else {
            // The new range starts on the same day as the existing range
            // but ends before the existing range
            newRanges.push({
              startDate: moment(newRange.endDate).add(1, "day").toDate(),
              endDate: overlappingRange.endDate
            });
          }
        }
        else if (moment(newRange.endDate).isSame(moment(overlappingRange.endDate))) {
          // The new range ends on the same day as the existing range
          // but starts after the existing range
          newRanges.push({
            startDate: overlappingRange.startDate,
            endDate: moment(newRange.startDate).subtract(1, "day").toDate()
          });
        }
        else {
          // The new range starts and ends in the middle of the existing range
          newRanges.push({
            startDate: overlappingRange.startDate,
            endDate: moment(newRange.startDate).subtract(1, "day").toDate()
          });

          newRanges.push({
            startDate: moment(newRange.endDate).add(1, "day").toDate(),
            endDate: overlappingRange.endDate
          });
        }

        allRanges.splice(overlappingRangeIndex, 1);
        allRanges = [...allRanges, ...newRanges];

        return allRanges;
      }
    }

    // Combine the overlapping ranges into a single range
    if (overlappingRanges.length > 0) {
      for (let i = 0; i < overlappingRanges.length; i++) {
        let existingRange = overlappingRanges[i];
        newRange.startDate = newRange.startDate < existingRange.startDate ? newRange.startDate : existingRange.startDate;
        newRange.endDate = newRange.endDate > existingRange.endDate ? newRange.endDate : existingRange.endDate;
      }
    }
    
    // Remove the overlapping ranges from the list of all ranges
    allRanges = allRanges.filter((existingRange) => {
      return !overlappingRanges.includes(existingRange);
    });

    allRanges = [newRange, ...allRanges];
    return allRanges;
  }

  onNewTimeRange(range) {
    // console.log("onNewTimeRange", range, this.props.ranges);
    if (range) {
      let newRange = {
        startDate: range.startDate > range.endDate ? range.endDate : range.startDate,
        endDate: range.startDate > range.endDate ? range.startDate : range.endDate,
      };
      let newRanges = this.getNewRanges(newRange, this.props.ranges);
      newRanges = newRanges.sort((a, b) => moment(a.startDate).isBefore(moment(b.startDate)) ? -1 : 1);
      this.props.onTimeRangesChanged(newRanges);
    }
  }

  onWeekDayClick(month, weekday) {
    // console.log("onWeekDayClick", month, moment(month).isoWeekday(), weekday);
    const daysInMonth = moment(month).daysInMonth();
    const daysOfMonth = Array.from({length: daysInMonth}, (x, i) => moment(month).add(i, 'days').toDate());
    const allDatesOfThatWeekDay = daysOfMonth.filter(x => moment(x).isoWeekday() === weekday + 1);

    // Filter out disabled dates and future dates
    const filteredDaysOfMonth = allDatesOfThatWeekDay.filter(x => !this.props.disabledDates.some(y => moment(x).isSame(moment(y))) && !moment(x).isAfter(moment().startOf("day")));

    // Check if any of the dates are in the selection range
    const selectedDates = filteredDaysOfMonth.filter(x => this.props.ranges.some(y => moment(x).isSameOrAfter(moment(y.startDate)) && moment(x).isSameOrBefore(moment(y.endDate))));

    let newRanges = [...this.props.ranges];

    // Check if all dates are selected
    if (selectedDates.length === filteredDaysOfMonth.length) {
      // Remove all dates from the selection range
      selectedDates.forEach(x => {
        newRanges = this.getNewRanges({
          startDate: x,
          endDate: x
        }, newRanges);
      });
    }
    else {
      // Add all dates that are not selected to the selection range
      filteredDaysOfMonth.forEach(x => {
        if (!selectedDates.some(y => moment(x).isSame(moment(y))) && !this.props.disabledDates.some(y => moment(x).isSame(moment(y))) && !moment(x).isAfter(moment().startOf("day"))) {
          newRanges = this.getNewRanges({
            startDate: x,
            endDate: x
          }, newRanges);
        }
      });
    }

    newRanges = newRanges.sort((a, b) => moment(a.startDate).isBefore(moment(b.startDate)) ? -1 : 1);
    this.props.onTimeRangesChanged(newRanges);
  }

  onMonthClick(month) {
    // console.log("onMonthClick", month);
    const daysInMonth = moment(month).daysInMonth();
    const daysOfMonth = Array.from({length: daysInMonth}, (x, i) => moment(month).add(i, 'days').toDate());

    // Filter out disabled dates and future dates
    const filteredDaysOfMonth = daysOfMonth.filter(x => !this.props.disabledDates.some(y => moment(x).isSame(moment(y))) && !moment(x).isAfter(moment().startOf("day")));

    // Check if any of the dates are in the selection range
    const selectedDates = filteredDaysOfMonth.filter(x => this.props.ranges.some(y => moment(x).isSameOrAfter(moment(y.startDate)) && moment(x).isSameOrBefore(moment(y.endDate))));
    
    // console.log("filteredDaysOfMonth", filteredDaysOfMonth);

    let newRanges = [...this.props.ranges];

    // Check if all dates are selected
    if (selectedDates.length === filteredDaysOfMonth.length) {
      // Remove all dates from the selection range
      selectedDates.forEach(x => {
        newRanges = this.getNewRanges({
          startDate: x,
          endDate: x
        }, newRanges);
      });
    }
    else {
      // Add all dates that are not selected to the selection range
      filteredDaysOfMonth.forEach(x => {
        if (!selectedDates.some(y => moment(x).isSame(moment(y)))) {
          newRanges = this.getNewRanges({
            startDate: x,
            endDate: x
          }, newRanges);
        }
      });
    }

    newRanges = newRanges.sort((a, b) => moment(a.startDate).isBefore(moment(b.startDate)) ? -1 : 1);
    this.props.onTimeRangesChanged(newRanges);
  }

  render() {
    const language = localStorage.getItem("language") || "en";
    strings.setLanguage(language);
    const locale = language === "nb" ? nb : en;
      
    return (
      <div className={classnames(this.styles.dateRangePickerWrapper, this.props.className)}>
        <DateRange
          locale={locale}
          months={this.state.monthCount}
          onChange={this.onNewTimeRange}
          onMonthClick={this.onMonthClick}
          onWeekDayClick={this.onWeekDayClick}
          {...this.props}
          ref={t => (this.dateRange = t)}
          className={undefined}
          rangeColors={this.props.ranges.map(x=>"#1C4D82")}
        />
      </div>
    );
  }
}

DateRangePicker.defaultProps = {};

DateRangePicker.propTypes = {
  ...DateRange.propTypes,
  className: PropTypes.string,
};

export default DateRangePicker;
