import axios from "axios";
import Cookies from "universal-cookie";
import * as types from "../ApiTypes";
import * as actions from "./sharedFunction";
import * as selectionActions from "./selected";
import * as locationActions from "./locations";
import * as authActions from "./auth";
import { API_URL, BASE_URL, COOKIE_PREFIX } from "../env";
import { getCompanyId } from "../helpers";

const cookies = new Cookies();

export const registerFeature = (feature, parentIndex, floorLocationId, viewedLocationId) => async (dispatch) => {
  dispatch(actions.requestData(types.EDIT_LOCATION));

  const apiUrl = await API_URL();
  const cookiePrefix = await COOKIE_PREFIX();
  const accessToken = cookies.get(`${cookiePrefix}_access_token`);

  const features = [feature];

  // Strip properties and id
  const createdFeatures = features.map(aFeature => ({
    ...aFeature,
    id: undefined,
    properties: undefined
  }));

  // const updatedFeatures = features.map(aFeature => ({
  //   ...aFeature,
  //   properties: {
  //     index: parentIndex + 1
  //   }
  // }));

  actions.createFeatures(floorLocationId, createdFeatures, apiUrl)
    // .then(() => actions.updateFeatures(floorLocationId, updatedFeatures, apiUrl))
    // .then(() => dispatch(locationActions.mapFeatureToLocation(feature.id, owningLocationId, viewedLocationId)))
    .then(() => {
      dispatch(actions.receiveData(null, types.EDIT_LOCATION));
      dispatch(locationActions.getFloorMap(viewedLocationId));
      dispatch(selectionActions.clearSelection());
    })
    .catch((error) => {
      if (error.response && error.response.status === 401) {
        actions.sessionTokenExpired(cookiePrefix, accessToken);
      } else {
        dispatch(actions.receiveError(error, types.EDIT_LOCATION));
      }
    });
};

export const changeFeatures = (locationFeatures, sensorFeatures, gatewayFeatures, floorLocationId, viewedLocationId) => async (dispatch) => {
  dispatch(actions.requestData(types.UPDATE_FEATURES));

  const apiUrl = await API_URL();
  const cookiePrefix = await COOKIE_PREFIX();
  const accessToken = cookies.get(`${cookiePrefix}_access_token`);

  // Strip properties
  const updatedFeatures = locationFeatures.map(feature => ({
    ...feature,
    properties: undefined
  }));

  const updatedSensors = sensorFeatures.map(sensorFeature => ({
    id: sensorFeature.properties.sensor._id,
    body: {
      geoJsonFeature: sensorFeature
    }
  }));

  const updatedGateways = gatewayFeatures.map(gatewayFeature => ({
    id: gatewayFeature.properties.gateway._id,
    body: {
      geoJsonFeature: gatewayFeature
    }
  }));

  actions.updateFeatures(floorLocationId, updatedFeatures, apiUrl)
    .then(() => actions.updateSensors(updatedSensors, apiUrl))
    .then(() => actions.updateGateways(updatedGateways, apiUrl))
    .then(() => {
      dispatch(actions.receiveData(null, types.UPDATE_FEATURES));
      dispatch(locationActions.getFloorMap(viewedLocationId));
      dispatch(selectionActions.clearSelection());
    })
    .catch((error) => {
      if (error.response && error.response.status === 401) {
        actions.sessionTokenExpired(cookiePrefix, accessToken);
      } else {
        dispatch(actions.receiveError(error, types.UPDATE_FEATURES));
      }
    });
};

export const deleteFeatures = (features, floorLocationId, viewedLocationId, redirect) => async (dispatch) => {
  dispatch(actions.requestData(types.DELETE_FEATURES));

  const apiUrl = await API_URL();
  const companyId = getCompanyId();
  const cookiePrefix = await COOKIE_PREFIX();
  const accessToken = cookies.get(`${cookiePrefix}_access_token`);

  const geoJsonFeatureIds = features.map(feature => feature.id);

  axios.post(`${apiUrl}companies/${companyId}/locations/${floorLocationId}/map/delete-features`, {
      geoJsonFeatureIds
    }, await actions.getAxiosConfig())
    .then((response) => {
      dispatch(actions.receiveData(response.data, types.DELETE_FEATURES));
      dispatch(locationActions.getFloorMap(viewedLocationId));
      dispatch(selectionActions.clearSelection());

      if (redirect) {
        redirect(`/companies/${companyId}/locations/${viewedLocationId}/locations`);
      }
    })
    .catch((error) => {
      if (error.response && error.response.status === 401) {
        actions.sessionTokenExpired(cookiePrefix, accessToken);
      } else {
        dispatch(actions.receiveError(error, types.DELETE_FEATURES));
      }
    });
};

export const registerCompanyFeature = (feature, companyId) => async (dispatch) => {
  dispatch(actions.requestData(types.REGISTER_COMPANY_FEATURE));

  const apiUrl = await API_URL();
  const cookiePrefix = await COOKIE_PREFIX();
  const accessToken = cookies.get(`${cookiePrefix}_access_token`);

  const features = [feature];

  // Strip properties and id
  const createdFeatures = features.map(aFeature => ({
    ...aFeature,
    id: undefined,
    properties: undefined
  }));

  actions.createCompanyFeatures(createdFeatures, companyId, apiUrl)
    .then(() => {
      dispatch(actions.success(types.REGISTER_COMPANY_FEATURE));
      dispatch(authActions.getCompanyMap());
      dispatch(selectionActions.clearSelection());
    })
    .catch((error) => {
      if (error.response && error.response.status === 401) {
        actions.sessionTokenExpired(cookiePrefix, accessToken);
      } else {
        dispatch(actions.receiveError(error, types.REGISTER_COMPANY_FEATURE));
      }
    });
};

export const changeCompanyFeatures = (locationFeatures) => async (dispatch) => {
  dispatch(actions.requestData(types.UPDATE_FEATURES));

  const apiUrl = await API_URL();
  const cookiePrefix = await COOKIE_PREFIX();
  const accessToken = cookies.get(`${cookiePrefix}_access_token`);

  // Strip properties
  const updatedFeatures = locationFeatures.map(feature => ({
    ...feature,
    properties: undefined
  }));

  actions.updateCompanyFeatures(updatedFeatures, apiUrl)
    .then(() => {
      dispatch(actions.success(types.UPDATE_FEATURES));
      dispatch(selectionActions.clearSelection());
      dispatch(authActions.getCompanyMap());
    })
    .catch((error) => {
      if (error.response && error.response.status === 401) {
        actions.sessionTokenExpired(cookiePrefix, accessToken);
      } else {
        dispatch(actions.receiveError(error, types.UPDATE_FEATURES));
      }
    });
};

export const deleteCompanyFeatures = (features) => async (dispatch) => {
  dispatch(actions.requestData(types.DELETE_FEATURES));

  const apiUrl = await API_URL();
  const companyId = getCompanyId();
  const cookiePrefix = await COOKIE_PREFIX();
  const accessToken = cookies.get(`${cookiePrefix}_access_token`);

  const geoJsonFeatureIds = features.map(feature => feature.id);

  axios.post(`${apiUrl}companies/${companyId}/map/delete-features`, {
      geoJsonFeatureIds
    }, await actions.getAxiosConfig())
    .then((response) => {
      dispatch(actions.receiveData(response.data, types.DELETE_FEATURES));
      dispatch(authActions.getCompanyMap());
      dispatch(selectionActions.clearSelection());
    })
    .catch((error) => {
      if (error.response && error.response.status === 401) {
        actions.sessionTokenExpired(cookiePrefix, accessToken);
      } else {
        dispatch(actions.receiveError(error, types.DELETE_FEATURES));
      }
    });
};

export const getBuildingFromOSM = (wayId, companyId) => async (dispatch) => {
  dispatch(actions.requestData(types.ACQUIRE_COMPANY_FEATURE));

  const baseUrl = await BASE_URL();

  axios.get(`/osm/building?id=${wayId}`)
    .then((response) => {
      console.log(response.data);
      if (response.data && response.data.id) {
        dispatch(registerCompanyFeature(response.data, companyId));
      }
      else {
        dispatch(actions.receiveError("not found", types.ACQUIRE_COMPANY_FEATURE));
      }
    })
    .catch((error) => {
      console.log(error);
      dispatch(actions.receiveError(error, types.ACQUIRE_COMPANY_FEATURE));
    });
};
