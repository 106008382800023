import React from "react";
import { MapMode } from "../../constants/stateTypes";
import { StateColors } from "../../constants/colors";
import strings from "./strings";
import styled from "styled-components";

interface LegendProps {

  /**
   * Map mode to know which legend to show.
   */
  mapMode: MapMode;

  /**
   * If debug, show extra info.
   */
  debug: boolean;
}

const Legend: React.FC<LegendProps> = ({ mapMode, debug }) => (
  <Container>
    <div>
      <Circle style={{ backgroundColor: StateColors.connected }}></Circle>{ strings.online }
    </div>
    <div>
      <Circle style={{ backgroundColor: StateColors.disconnected }}></Circle>{ strings.offline }
    </div>
    <div>
      <Circle style={{ backgroundColor: StateColors.disabled }}></Circle>{ strings.disabled }
    </div>
  </Container>
);

const Container = styled.div`
  display: block;
  background-color: #fff;
  border-radius: 4px;
  bottom: 70px;
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.3); 
  font: 12px/20px 'Source Sans Pro', 'Helvetica Neue', Arial, Helvetica, sans-serif;
  padding: 10px;
  position: absolute;
  right: 10px;
  z-index: 1;
`;

const Circle = styled.span`
  border-radius: 50%;
  display: inline-block;
  height: 9px;
  margin-right: 5px;
  width: 9px;
  box-shadow: 0px 0px 0px 1px rgba(119, 119, 119, 1.0);
`;

export default Legend;
