import React from "react";
import Table from "../../components/Table";
import Tag from "../../components/Tag";

export const createExtensionAttributeTable = (attributes, selectedAttributes, onToggleExtensionAttribute) => {

  // Create array for extension attributes
  const attributeArray = Object.keys(attributes).map((extensionName) => (
    { name: extensionName, value: attributes[extensionName] }
  ));

  return (
    <Table
      data={attributeArray}
      columns={[
        {
          id: "type",
          header: "",
          accessorKey: "type",
          sortable: false,
          name: "isSelected",
          cell: ({ row }) => (
            <label className="checkboxContainer" htmlFor={`editCheckbox-${row.original.name}`}>
              <input
                id={`editCheckbox-${row.original.name}`}
                type="checkbox"
                className="checkbox"
                checked={(selectedAttributes[row.original.name] !== undefined && selectedAttributes[row.original.name])}
                onChange={() => onToggleExtensionAttribute(row.original)}
              />
              <span className="checkmark" />
            </label>
          ),
          width: 60
        },
        {
          header: "Name", 
          accessorKey: "name", 
        },
        {
          header: "Value", 
          accessorKey: "value" 
        }]}
      className="-row-clickable setMaxHeigth"
    />
  );
}

export const createOccupancyConfigTable = (occupancyRoomTimeouts, selectedConfigs, onToggleConfig) => {

  // Create array for room occupancy config
  const occupancyConfigs = Object.keys(occupancyRoomTimeouts).map((locationType) => (
    { type: locationType, ...occupancyRoomTimeouts[locationType] }
  ));

  return (
    <Table
      data={occupancyConfigs}
      columns={[
        {
          id: "id",
          header: "",
          accessorKey: "type",
          sortable: false,
          name: "isSelected",
          cell: ({ row }) => (
            <label className="checkboxContainer" htmlFor={`editCheckbox-${row.original.type}`}>
              <input
                id={`editCheckbox-${row.original.type}`}
                type="checkbox"
                className="checkbox"
                checked={(selectedConfigs[row.original.type] !== undefined && selectedConfigs[row.original.type])}
                onChange={() => onToggleConfig(row.original)}
              />
              <span className="checkmark" />
            </label>
          ),
          width: 60
        },
        {
          id: "type",
          header: "Type", 
          accessorKey: "type", 
        },
        {
          header: "Occupancy duration (sec)", 
          accessorKey: "occupiedDurationSeconds" 
        },
        {
          header: "Keep Alive duration (sec)", 
          accessorKey: "keepAliveDurationSeconds" 
        }]}
      className="-row-clickable setMaxHeigth"
    />
  );
}

export const createBookableResourcesTable = (bookableResources, selectedResources, onToggleResource) => {
  return (
    <Table
      data={bookableResources}
      columns={[
        {
          id: "type",
          header: "",
          accessorKey: "type",
          sortable: false,
          name: "isSelected",
          cell: ({ row }) => (
            <label className="checkboxContainer" htmlFor={`editCheckbox-${row.original._index}`}>
              <input
                id={`editCheckbox-${row.original._index}`}
                type="checkbox"
                className="checkbox"
                checked={(selectedResources[row.original._index] !== undefined && selectedResources[row.original._index])}
                onChange={() => onToggleResource(row.original)}
              />
              <span className="checkmark" />
            </label>
          ),
          width: 60
        },
        {
          header: "Type", 
          accessorKey: "type", 
        },
        {
          header: "Start", 
          accessorKey: "startTime",
          cell: ({ row }) => {
            const timeString = `${row.original.startTime.hours < 10 ? "0" : ""}${row.original.startTime.hours}:${row.original.startTime.minutes < 10 ? "0" : ""}${row.original.startTime.minutes}`;
            return <span title={timeString}>{timeString}</span>
          }
        },
        {
          header: "End", 
          accessorKey: "endTime",
          cell: ({ row }) => {
            const timeString = `${row.original.endTime.hours < 10 ? "0" : ""}${row.original.endTime.hours}:${row.original.endTime.minutes < 10 ? "0" : ""}${row.original.endTime.minutes}`;
            return <span title={timeString}>{timeString}</span>
          }
        },
        {
          header: "Capacity", 
          accessorKey: "capacity" 
        }]}
      className="-row-clickable setMaxHeigth"
    />
  );
}

export const createCustomTagsTable = (customTags, selectedCustomTags, onToggleCustomTag) => {
  return (
    <Table
      data={customTags}
      columns={[
        {
          id: "id",
          header: "",
          accessorKey: "id",
          sortable: false,
          name: "isSelected",
          cell: ({ row }) => (
            <label className="checkboxContainer" htmlFor={`editCheckbox-${row.original.id}`}>
              <input
                id={`editCheckbox-${row.original.id}`}
                type="checkbox"
                className="checkbox"
                checked={(selectedCustomTags[row.original.id] !== undefined && selectedCustomTags[row.original.id])}
                onChange={() => onToggleCustomTag(row.original)}
              />
              <span className="checkmark" />
            </label>
          ),
          width: 60
        },
        {
          header: "Name", 
          accessorKey: "name",
          cell: ({ row }) => <Tag text={row.original.name} color={row.original.colorTheme} />
        }]}
      className="-row-clickable setMaxHeigth"
    />
  );
}