import get from "lodash/get";
import { toast } from "react-toastify";
import * as types from "../ApiTypes";

const initialState = {
  currentDeviceId: null,
  count: 0,
  limit: 100,
  offset: 0,
  devices: [],
  draft: {},
  samples: []
};

export default function unregisteredSensorsReducer(state = initialState, action) {

  switch (action.type) {

    case types.REQ_DATA: {
      if (action.fetchType === types.GET_UNREGISTERED_SENSORS) {
        return initialState;
      }

      if (action.fetchType === types.GET_UNREGISTERED_SENSOR_SAMPLES) {
        return { ...state, samples: [], currentDeviceId: action.metadata.deviceId };
      }
      
      return state;
    }

    case types.RECV_DATA: {
      if (action.fetchType === types.GET_UNREGISTERED_SENSORS) {
        return { 
          ...state,
          count: action.payload.count,
          limit: action.payload.limit,
          offset: action.payload.offset,
          devices: action.payload.results
        };
      }

      if (action.fetchType === types.UPDATE_UNREGISTERED_SENSORS) {

        // Update the devices with the new data
        const newDevices = state.devices.map(device => {
          if (device.deviceId === action.payload.device.deviceId) {
            toast.success(`Updated ${device.deviceId}`);
            device = { ...device, ...action.payload.draft };
          }
          return device;
        });

        return {
          ...state,
          devices: newDevices,
          draft: {}
        }
      }

      if (action.fetchType === types.CREATE_UNREGISTERED_SENSORS) {
        toast.success(`Registered sensors`);

        // Remove the registered devices from the list
        const newDevices = state.devices.filter(device => !action.metadata.deviceIds.includes(device.deviceId));

        return {
          ...state,
          devices: newDevices,
          draft: {}
        }
      }

      if (action.fetchType === types.GET_UNREGISTERED_SENSOR_SAMPLES) {
        if (action.metadata.deviceId !== state.currentDeviceId) {
          return state;
        }

        return { ...state, samples: action.payload.messages };
      }
      
      return state;
    }

    case types.RECV_ERROR: {

      const statusCode = get(action, "payload.response.status", "Error");

      if (action.fetchType === types.GET_UNREGISTERED_SENSORS) {
        toast.error(`${statusCode}: Could not get unregistered sensors`);
        return { ...state, devices: [] };
      }

      if (action.fetchType === types.UPDATE_UNREGISTERED_SENSORS) {
        toast.error(`${statusCode}: Could not update ${action.metadata.id}`);
        return state;
      }

      if (action.fetchType === types.CREATE_UNREGISTERED_SENSORS) {
        toast.error(`${statusCode}: Could not register sensors`);
        return state;
      }

      if (action.fetchType === types.GET_UNREGISTERED_SENSOR_SAMPLES) {
        toast.error(`${statusCode}: Could not get unregistered sensor samples`);
        return { ...state, samples: [] };
      }
      
      return state;
    }

    case types.UPDATE_UNREGISTERED_SENSOR_DRAFT: {
      const oldDraft = JSON.parse(JSON.stringify(state.draft));
      return {...state, draft: { ...oldDraft, ...action.draft } };
    }

    case types.DESELECT_UNREGISTERED_SENSORS: {
      return {...state, draft: {} };
    }

    case types.CLEAR_SELECTION:
    case types.CLEAR_UNREGISTERED_SENSOR_DRAFT: {
      return {...state, draft: {}};
    }

    case types.CLEAR_DATA: {
      return initialState;
    }

    default: {
      return state;
    }
  }
}