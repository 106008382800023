import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import isEmpty from "lodash/isEmpty";
import DetailsHeader from "../../components/DetailsHeader";
import SubNavigation from "../../components/SubNavigation";
import ContentInfo from "../App/contentDetailsInfo";
import ContentLocations from "../App/contentDetailsLocations";
import ContentGroups from "../App/contentDetailsGroups";
import * as contentActions from "../../actions/content";
import style from "./style.module.scss";

const tabBarLinks = [
  { label: `Groups` },
  { label: `Locations` }
  // ,{ label: `Config` }
];

class ContentDetails extends Component {

  constructor(props) {
    super(props);
    this.state = {
      selectedIndex: 0
    };
    this.selectedTab = this.selectedTab.bind(this);

    if (isEmpty(this.props.contentId)) {
      this.props.clearContent();
    }
    else if (this.props.content.id !== this.props.contentId) {
      this.props.getContent(this.props.contentId);
    }
  }

  componentDidUpdate(prevProps) {
    // Only load data if id change
    if (isEmpty(this.props.contentId)) {
      this.props.clearContent();
    }
    else if (prevProps.contentId !== this.props.contentId) {
      this.props.getContent(this.props.contentId);
    }
  }

  selectedTab(index) {
    this.setState({ selectedIndex: index });
  }
  
  render() {
    // console.log("ContentDetails.render()", this.props);

    if (isEmpty(this.props.contentId) || isEmpty(this.props.content.id)) {
      return null;
    }

    const title = this.props.content.title ?? " ";

    let detailsElement = null;
    switch (this.state.selectedIndex) {
      case 0:
        detailsElement = <ContentGroups {...this.props} />
        break;
      case 1:
        detailsElement = <ContentLocations {...this.props} />
        break;
      case 2:
        detailsElement = <ContentInfo {...this.props} />
        break;
      default:
        break;
    }

    return (
      <div>
        <DetailsHeader title={title} />
        <SubNavigation links={tabBarLinks} selectedIndex={this.state.selectedIndex} onClick={this.selectedTab} slim />
        { detailsElement }
        <div style={{ paddingTop: "40px" }} />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    content: state.content,
    selectedCompany: state.auth.selectedCompany
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    getContent: contentActions.getContent,
    clearContent: contentActions.clearContent
   }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(ContentDetails);
