import React, { Component } from "react";
import { Col, Row } from "react-grid-system";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { isEmpty } from "lodash";
import Table from "../../../../components/Table";
import Loader from "../../../../components/Loader";
import style from "./style.module.scss";

class AnomalyDetailsAtomicSensors extends Component {

  constructor(props) {
    super(props);
    this.onRowClick = this.onRowClick.bind(this);
  }

  onRowClick(column, rowInfo)  {
    return {
      onClick: e => {
        if (rowInfo && column.name !== "isSelected") {
          let link = `/companies/${this.props.match.params.companyId}/sensors/${rowInfo.original.sensorId}/atomic-sensors/${rowInfo.original.atomicSensorId}/data`;

          if (e.metaKey || e.ctrlKey) {
            window.open(`${link}`);
          }
          else {
            this.props.history.push(link);
          }
        }
      },
      style: {
        cursor: "pointer"
      }
    }
  }

  render() {

    if (isEmpty(this.props.match.params.anomalyId)) {
      return null;
    }

    if (this.props.isLoading) {
      return <Loader fullScreen />;
    }

    const tableElement = (
      <Row className={style.topRow} nogutter>
        <Col sm={12}>
          <Table
            data={this.props.anomaly.atomicSensors}
            noDataText={this.props.isLoading ? "" : "No atomic sensors found"}
            columns={[
              {
                header: "Property name",
                accessorKey: "property",
                cell: ({ row }) => (<span title={row.original.property}>{row.original.property}</span>)
              },
              {
                id: "arrow",
                header: "",
                sortable: false,
                className: "pull-right",
                width: 60,
                cell: ({ row }) => (<div className="arrow" />)
              }
            ]}
            getTdProps={this.onRowClick}
            className="setMaxHeigth -row-clickable -highlight"
            loading={this.props.isLoading}
          />
        </Col>
      </Row>
    );

    return (
      tableElement
    );
  }
}

function mapStateToProps(state) {
  return {
    anomaly: state.anomaly,
    isLoading: state.loading.anomaly,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({

  }, dispatch);
}

export default connect(mapStateToProps,mapDispatchToProps)(AnomalyDetailsAtomicSensors);