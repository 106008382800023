import React from "react";
import style from "./style.module.scss"
import styled from "styled-components";

const Tag = ({text, color = "#1C4D82", hoverColor = "#163d68", showHover = false, not = false, onRemoveClick = null, onClick = () => {}}) => (
  <OuterContainer>
    { not && (
      <NotContainer>
        <NotLabel>NOT</NotLabel>
      </NotContainer>
    )}
    <TagContainer $not={not} onClick={onClick} $hasRemoveClick={onRemoveClick} $color={color} $hoverColor={hoverColor} $showHover={showHover}>
      <LabelContainer>
        <Label>
          {text}
        </Label>
      </LabelContainer>
      { onRemoveClick && (
        <CloseContainer onClick={onRemoveClick}>
          <div className={style.closeIcon} />        
        </CloseContainer>
      )}
    </TagContainer>
  </OuterContainer>
);

export default Tag;

const OuterContainer = styled.div`
  display: inline-flex;
  border-radius: 5px 5px 5px 5px;
  height: 29px;
  box-sizing: border-box;
`;

const TagContainer = styled.div`
  position: relative;
  display: inline-flex;
  margin-right: 6px; 
  line-height: normal;
  padding: ${props => props.$not ? "5px 10px 5px 6px" : "5px 10px 5px 10px"};
  border: 0;
  color: #ffffff;
  border-radius: ${props => props.$not ? "0 5px 5px 0" : "5px"};
  height: 29px;
  box-sizing: border-box;
  background-color: ${props => props.$color};
  cursor: ${props => props.$hasRemoveClick ? "default" : "pointer"};

  &:hover {
    background-color: ${props => props.$showHover && props.$hoverColor};
  }
`;

const LabelContainer = styled.div`
  display: inline-flex;
  height: 18px;
`;

const Label = styled.div`
  font-family: "Source Sans Pro", Helvetica, Arial, sans-serif;
  font-weight: 400;
  display: block;
  position: relative;
  text-decoration: none;
  text-align: center;
  font-size: 14px;
  white-space: nowrap;
`;

const CloseContainer = styled.div`
  display: inline-flex;
  position: relative;
  top: 4px;
  left: 4px;
  margin-right: 1px;
  cursor: pointer;
`;

const NotContainer = styled.div`
  display: inline-flex;
  background-color: gray;
  position: relative;
  line-height: normal;
  padding: 5px 6px 5px 8px;
  border: 0;
  color: #ffffff;
  border-radius: 5px 0 0 5px;
  height: 28px;
  box-sizing: border-box;
  cursor: default;
`;

const NotLabel = styled.div`
  text-decoration: none;
`;