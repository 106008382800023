import { toast } from "react-toastify";
import get from "lodash/get";
import * as types from "../ApiTypes";

const initialState = {
  data: [],
  draft: null,
};

export default function graphResourcesReducer(state = initialState, action) {

  switch (action.type) {
    
    case types.REQ_DATA: {
      if (action.fetchType === types.GET_GRAPH_RESOURCES) {
        return state;
      }
      
      return state;
    }

    case types.RECV_DATA: {
      if (action.fetchType === types.GET_GRAPH_RESOURCES) {
        return {
          ...state,
          data: get(action.payload, "resourceSlots", []),
          // draft: null
        }
      }

      return state;
    }

    case types.RECV_ERROR: {
      if (action.fetchType === types.GET_GRAPH_RESOURCES) {
        toast.error("Could not get graph resources");
        return {...state, data: [], draft: null };
      }

      return state;
    }

    // case types.UPDATE_GRAPH_RESOURCE_DRAFT: {
    //   return {
    //     ...state,
    //     draft: action.payload
    //   };
    // }

    default: {
      return state;
    }
  }
}
