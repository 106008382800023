import React from "react";
import style from "./style.module.scss"

const StatusLabel = ({text, color = "blue", inRow = false, fullWidth = true }) => (
  <div className={inRow && fullWidth ? style.fullRowLabelContainer : (inRow ? style.rowLabelContainer : style.labelContainer)}>
    <div className={color === "blue" ? style.blue : color === "green" ? style.green : color === "red" ? style.red : style.grey}>
      <span>{text}</span>
    </div>
  </div>
);

export default StatusLabel;