export class NodeModel {
  constructor(props, nodes = {}) {
    this.props = props;
    this.flatNodes = nodes;
  }

  getNode(id) {
    return this.flatNodes[id];
  }

  reset() {
    this.flatNodes = {};
  }

  toggleExpand(id, key, toggleValue) {
    this.flatNodes[id][key] = toggleValue;
    return this;
  }

  toggleChecked(id, key, toggleValue) {
    const flatNode = this.flatNodes[id];
    this.flatNodes[id][key] = toggleValue;

    if(flatNode.children.length > 0) {
       flatNode.children.forEach((child) => {
        this.toggleChecked(child.id, 'checked', toggleValue);
      });
    }

    if(flatNode.isChild) {
      if(this.flatNodes[flatNode.parent.id].checked) {
        this.flatNodes[id]["userChecked"] = false;

        if(!this.flatNodes[id].checked) {
          this.flatNodes[id]["userUnchecked"] = true;
        }
        else {
          this.flatNodes[id]["userUnchecked"] = false;
        }
      }
      else {
        this.flatNodes[id]["userUnchecked"] = false;
      }
    } 
    else {
      this.flatNodes[id]["userChecked"] = toggleValue;
    }

    
    if(flatNode.isChild) {
      if(this.flatNodes[flatNode.parent.id].checked) {
      }
      else {
        this.flatNodes[id]["userChecked"] = toggleValue;
      }
    }

    return this;
  }

  nodeHasChildren(node) {
    return node.children.length > 0;
  }

  serializeList(key) {
    const list = [];

    Object.keys(this.flatNodes).forEach(id => {
      if (this.flatNodes[id][key]) {
        list.push(id);
      }
    });

    return list;
  }

  deserializeLists(lists) {
    const listKeys = ['expanded'];

    Object.keys(this.flatNodes).forEach((id) => {
        listKeys.forEach((listKey) => {
            this.flatNodes[id][listKey] = false;
        });
    });
  }

  flattenNodes(nodes, parent = {}, depth = 0) {
    if (!Array.isArray(nodes) || nodes.length === 0) {
      return;
    }

    nodes.forEach((node, index) => {
      const isParent = this.nodeHasChildren(node);

      if(this.flatNodes[node.id] !== undefined) {
        throw new Error(`Duplicate ID ${node.id} detected. All node values must be unique.`);
      }

      this.flatNodes[node.id] = {
        id: node.id,
        name: node.name,
        children: node.children,
        parent,
        isParent,
        isChild: parent.id !== undefined,
        treeDepth: depth,
        expanded: false,
        checked: false,
        isLeaf: !isParent,
        userChecked: false,
        userUnchecked: false,
        isIntermediate: false,
      };

      this.flattenNodes(node.children, node, depth + 1);
    })
  }
}
