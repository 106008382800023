import LocalizedStrings from "react-localization";

export default new LocalizedStrings({
  en: {
    week: "week",
    utilizationTitle: "Utilization - {0} in {1} ({2} - {3})",
    peopleCountTitle: "People count in {0} ({1} - {2})",
    realCoverageDegreeTitle: "Real coverage degree in {0} ({1} - {2})",
    unknown: "Unknown",
    avg: "Avg",
    peak: "Peak",
    everything: "Everything",
    capacity: "Capacity",
  },
  nb: {
    week: "uke",
    utilizationTitle: "Utnyttelse - {0} i {1} ({2} - {3})",
    peopleCountTitle: "Mennesketelling i {0} ({1} - {2})",
    realCoverageDegreeTitle: "Reell dekningsgrad i {0} ({1} - {2})",
    unknown: "Ukjent",
    avg: "Gj. snitt",
    peak: "Maks",
    everything: "Alt",
    capacity: "Kapasitet",
  }
});
