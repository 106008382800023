import React from "react";
import { Switch, Route } from "react-router-dom";
import Display from "./display";
import NotFound from "../NotFound";

const DisplaySwitch = () => (
  <Switch>
    <Route path="/companies/:companyId/displays/:mode/:id" component={Display} />
    <Route component={NotFound} />
  </Switch>
);

export default DisplaySwitch;