import React from "react";
import Freetext from "../../components/Freetext";
import style from "./style.module.scss"

const PrivacyPolicyApp = () => (
  <div className={style.outerScroll}>
    <div className={style.container}>
      <div className={style.innerContainer}>

        <Freetext header="Privacy Policy for myBLDNG App" />

        <h2>Introduction</h2>
        <p>BLDNG (“we,” “our,” or “us”) is committed to protecting your privacy. This Privacy Policy outlines how we collect, use, disclose, and safeguard your information when you use our myBLDNG application (“App”). By using the App, you consent to the data practices described in this policy.</p>

        <h2>Information We Collect</h2>
        <ul>
          <li>Personal Identifiable Information (PII). We collect PII provided by your employer through Azure AD SCIM, including:</li>
          <ul>
            <li>Name</li>
            <li>E-mail address</li>
          </ul>
          <li>Device Information. We collect device-specific information to secure the authentication procedure, including: </li>
          <ul>
            <li>Device model</li>
            <li>Operating system version</li>
          </ul>
          <li>Push Notification Tokens. We store a unique push notification token associated with your device to send you relevant notifications.</li>
          <li>Microsoft Intune Information. If your employer utilizes Microsoft Intune for mobile device management (MDM), we may collect additional information as permitted by Intune policies, including:</li>
          <ul>
            <li>Device compliance status</li>
            <li>App configuration settings</li>
            <li>Security policies applied to your device</li>
            <li>Device identifiers necessary for Intune integration</li>
          </ul>
        </ul>

        <h2>How We Use Your Information</h2>
        <ul>
          <li>Providing Services</li>
          <ul>
            <li>To facilitate access to meeting rooms, office building services and internal company communications.</li>
          </ul>
          <li>Communication</li>
          <ul>
            <li>To send you push notifications related to App functionalities.</li>
          </ul>
          <li>Device Management and Security</li>
          <ul>
            <li>To enforce security policies and manage app configurations as required by your employer through Microsoft Intune.</li>
            <li>To ensure compliance with corporate policies and regulatory requirements.</li>
          </ul>
        </ul>

        <h2>Information Sharing and Disclosure</h2>
        <ul>
          <li>Third-Party Service Providers. We may share your information with third-party service providers who perform services on our behalf, such as cloud hosting. These providers are contractually obligated to protect your information and use it solely for the services they provide to us. Including:</li>
          <ul>
            <li>Microsoft Azure</li>
            <li>Microsoft Intune</li>
            <li>MongoDB Inc</li>
          </ul>
          <li>Legal Requirements. We may disclose your information if required to do so by law or in response to valid requests by public authorities.</li>
        </ul>

        <h2>Data Security</h2>
        <p>We implement appropriate technical and organizational measures to protect your information against unauthorized access, alteration, disclosure, or destruction. However, no method of transmission over the internet or electronic storage is 100% secure. Therefore, we cannot guarantee its absolute security.</p>

        <h2>Data Retention</h2>
        <ul>
          <li>Personal Identifiable Information. PII is retained as long as your account is active. User accounts are irreversibly destroyed upon SCIM request from your employer.</li>
          <li>Access Logs. Anonymized access logs are retained for 90 days for security and auditing purposes.</li>
          <li>Microsoft Intune Data. Any data collected via Microsoft Intune is retained in accordance with your employer&apos;s policies and Microsoft Intune&apos;s data retention guidelines.</li>
        </ul>

        <h2>User Rights</h2>
        <ul>
          <li>Access and Correction. You may modify your information by updating the source data in SCIM through your employer.</li>
          <li>Account Deletion. User accounts are irreversibly destroyed upon SCIM request from your employer.</li>
        </ul>

        <h2>Children&apos;s Privacy</h2>
        <p>Our App is not intended for use by individuals under the age of 16. We do not knowingly collect personal information from children under 16. If we become aware that we have inadvertently received personal information from a user under the age of 16, we will delete such information from our records.</p>

        <h2>Data processing</h2>
        <p>Data processing is governed by a Data Processing Agreement established between BLDNG.ai AS and the account holder.</p>

        <h2>Updates to this privacy policy</h2>
        <p>We may update this privacy policy to reflect changes in our data practices. We will notify you of any significant changes via email.</p>

        <h2>Contact us</h2>
        <p>If you have any questions or concerns about your privacy, please contact us at <a href="mailto:support@bldng.com">support@bldng.com</a>.</p> 

        <div className={style.footnote}>Last updated 03/12/2024</div>
        
      </div>
    </div>
  </div>
);

export default PrivacyPolicyApp;
