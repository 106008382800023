import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Switch, Route } from "react-router-dom";
import { Hidden, Visible } from "react-grid-system";
import NotFound from "../NotFound";
import ScreenConfigDetails from "./screenGroupDetails";
import style from "./style.module.scss";

class ScreenGroupContainer extends Component {

  render() {

    // Only show the map if we are in the details view
    let rightSide = null;
    if (this.props.match.path.endsWith("/details")) {

    }
    
    return (
      <>
        <Hidden xs sm md>
          <div className={style.singleView}>
            <div className={style.row}>
              <div className={style.listContainer}>
                <div className={style.scroll}>
                  <Switch>
                    <Route path="/companies/:companyId/screens/edit/:screenGroupId/details" children={(props) => <ScreenConfigDetails {...props} screenGroupId={this.props.match.params.screenGroupId}/>} />
                    <Route render={NotFound} />
                  </Switch>
                </div>
              </div>
              <div className={style.sideBar}>
                { rightSide }
              </div>
            </div>
          </div>
        </Hidden>

        <Visible xs sm md>
          <div className={style.singleView}>
            <div className={style.slimScroll}>
              <Switch>
                <Route path="/companies/:companyId/screens/edit/:screenGroupId/details" children={(props) => <ScreenConfigDetails {...props} screenGroupId={this.props.match.params.screenGroupId} />} />
                <Route render={NotFound} />
              </Switch>
            </div>
          </div>
        </Visible>
      </>
    );
  }

}

function mapStateToProps(state) {
  return {

  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({

  }, dispatch);
}

export default connect(mapStateToProps,mapDispatchToProps)(ScreenGroupContainer);