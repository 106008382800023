import { toast } from "react-toastify";
import isEmpty from "lodash/isEmpty";
import * as types from "../ApiTypes";

const initialState = {
  data: {},
  draft: {}
};

export default function customTagsReducer(state = initialState, action) {

  switch (action.type) {
    
    case types.REQ_DATA: {
      if (action.fetchType === types.GET_CUSTOM_TAG) {
        return state;
      }

      if (action.fetchType === types.CREATE_CUSTOM_TAG) {
        return state;
      }

      if (action.fetchType === types.UPDATE_CUSTOM_TAG) {
        return state;
      }

      if (action.fetchType === types.DELETE_CUSTOM_TAG) {
        return state;
      }
      
      return state;
    }

    case types.RECV_DATA: {
      if (action.fetchType === types.GET_CUSTOM_TAG) {
        return {
          ...state,
          data: action.payload,
          draft: {}
        }
      }

      if (action.fetchType === types.CREATE_CUSTOM_TAG) {
        return {
          ...state,
          data: {},
          draft: {}
        }
      }

      if (action.fetchType === types.UPDATE_CUSTOM_TAG) {
        return {
          ...state,
          data: {},
          draft: {}
        }
      }

      if (action.fetchType === types.DELETE_CUSTOM_TAG) {
        return {
          ...state,
          data: {},
          draft: {}
        }
      }

      return state;
    }

    case types.RECV_ERROR: {
      if (action.fetchType === types.GET_CUSTOM_TAG) {
        toast.error("Could not get custom tag");
        return {...state, data: {} };
      }

      if (action.fetchType === types.CREATE_CUSTOM_TAG) {
        toast.error("Could not create custom tag");
        return state;
      }

      if (action.fetchType === types.UPDATE_CUSTOM_TAG) {
        toast.error("Could not update custom tag");
        return state;
      }

      if (action.fetchType === types.DELETE_CUSTOM_TAG) {
        toast.error("Could not delete custom tag(s)");
        return state;
      }
      
      return state;
    }

    case types.UPDATE_CUSTOM_TAG_DRAFT: {
      const newDraft = { ...state.draft, ...action.draft };
      
      // Only store new data in draft 
      Object.keys(newDraft).forEach(draftKey => {
        if (newDraft[draftKey] === state.data[draftKey]) {
          delete newDraft[draftKey];
        }
        else if (isEmpty(state.data[draftKey]) && isEmpty(newDraft[draftKey])) {
          delete newDraft[draftKey];
        }
      });

      return {...state, draft: newDraft };
    }

    case types.CLEAR_CUSTOM_TAG_DRAFT: {
      return {...state, draft: {}};
    }

    case types.CLEAR_CUSTOM_TAG: {
      return initialState;
    }

    default: {
      return state;
    }
  }
}
