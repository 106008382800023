import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import InputBox from "../../components/InputBox";
import Freetext from "../../components/Freetext";
import Button from "../../components/Button";
import Table from "../../components/Table";
import Modal from "../../components/Modal";
import { Role } from "../../helpers";
import { OuterContainer, InnerContainer, Row, Col, FloatCol } from ".";
import * as locationActions from "../../actions/locations";
import * as sensorActions from "../../actions/sensors";
import * as gatewayActions from "../../actions/gateways";
import * as API from "../../ApiTypes";
import styled from "styled-components";

class ImportExport extends Component {

  constructor(props) {
    super(props);
    this.state = {

    };
    
    this.onUploadLocations = this.onUploadLocations.bind(this);
    this.onUploadLocationsClick = this.onUploadLocationsClick.bind(this);
    this.onUploadLocationsWithHash = this.onUploadLocationsWithHash.bind(this);
    this.onCancel = this.onCancel.bind(this);

    this.inputOpenLocationsCSVRef = React.createRef();
  }

  onUploadLocations(event) {
    event.preventDefault();
    const reader = new FileReader();
    const file = event.target.files[0];
    this.locationCSVFile = null;

    reader.onloadend = () => {
      this.locationCSVFile = reader.result;
      this.props.uploadLocationsCSV(reader.result);
    }

    if (event.target.files[0]) {
      reader.readAsText(file);
      event.target.value = ''; // eslint-disable-line no-param-reassign
    }
  }

  onUploadLocationsClick() {
    this.inputOpenLocationsCSVRef.current.click();
  }

  onUploadLocationsWithHash() {
    this.props.verifyLocationsCSV(this.locationCSVFile, this.props.uploadResponse);
  }

  onCancel() {
    this.locationCSVFile = null;
    this.props.clearLocationsCSVResponse();
  }

  render() {

    const isExternalUser = !this.props.auth.hasSupportRole;
    const useGraphResources = get(this.props.selectedCompany, "applicationFeatures.useGraphResources", false);

    const exportData = (
      <>
        <Row $paddingTop="15px">
          <Col>
            <h2>Download data</h2>
            <p>Get CSV-files that can be opened in Microsoft Excel or Apple Numbers.</p>
          </Col>
        </Row>
        <Row>
          <Col>
            <div style={{ display: "inline-block", marginRight: "20px" }}>
              <Button
                text="Sensor CSV" 
                onClick={this.props.downloadSensorsCSV}
                disabled={this.props.isDownloadingSensorCSV}
                isLoading={this.props.isDownloadingSensorCSV}
                padding
              />
            </div>
            <div style={{ display: "inline-block", marginRight: "20px" }}>
              <Button
                text="Gateway CSV" 
                onClick={this.props.downloadGatewaysCSV}
                disabled={this.props.isDownloadingGatewayCSV}
                isLoading={this.props.isDownloadingGatewayCSV}
                padding
              />
            </div>
            {
              !useGraphResources && (
                <div style={{ display: "inline-block", marginRight: "20px" }}>
                  <Button
                    text="Resource CSV" 
                    onClick={this.props.downloadResourceCSV}
                    disabled={this.props.isDownloadingResourceCSV}
                    isLoading={this.props.isDownloadingResourceCSV}
                    padding
                  />
                </div>
              )
            }
            {
              isExternalUser && (
                <div style={{ display: "inline-block" }}>
                  <Button
                    text="Locations CSV" 
                    onClick={this.props.downloadLocationsCSV}
                    disabled={this.props.isDownloadingLocationCSV}
                    isLoading={this.props.isDownloadingLocationCSV}
                    padding
                  />
                </div>
              )
            }
          </Col>
        </Row>
      </>
    );

    const locationsToBeDeletedString = get(this.props.uploadResponse,"locationsToBeDeleted",[]).map(location => location.name).join(", ");
    const uploadErrorJSON = this.props.uploadErrors.map(error => {

      if (isEmpty(error.column) || isEmpty(error.row)) {
        return error.message;
      }

      if (isEmpty(error.value)) {
        return `[${error.column}${error.row}]: ${error.message}`;
      }

      return `[${error.column}${error.row}]: ${error.message} (${error.value})`;
    });

    const importExportLocations = (
      <>
       <Row $paddingTop="15px">
          <Col>
            <h2>Import/export locations</h2>
            <p>Create and edit locations manually with import/export. NB! Make sure no one else edits locations while uploading a CSV and wait until the jobs are complete before doing further changes.</p>
          </Col>
        </Row>
        <Row>
          <Col>
            <div style={{ display: "inline-block", marginRight: "20px" }}>
              <Button
                text="Download CSV" 
                onClick={this.props.downloadLocationsCSV}
                disabled={this.props.isDownloadingLocationCSV}
                isLoading={this.props.isDownloadingLocationCSV}
                padding
              />
            </div>
            <div style={{ display: "inline-block" }}>
              <Button
                text="Upload CSV" 
                onClick={this.onUploadLocationsClick}
                disabled={this.props.isUploadingLocationCSV}
                isLoading={this.props.isUploadingLocationCSV}
                padding
              />
              <label htmlFor="locationsInput">
                <FileInput ref={this.inputOpenLocationsCSVRef} name="file" id="locationsInput" type="file" onChange={this.onUploadLocations} />
              </label>
            </div>
          </Col>
        </Row>
      </>
    );

    return (
      <OuterContainer $darkBackground>
        <InnerContainer>
          { exportData }
          { !isExternalUser && importExportLocations }
        </InnerContainer>

        <Modal
          show={!isEmpty(this.props.uploadResponse)}
          title="Verify changes"
          text={ locationsToBeDeletedString.length > 0 ? `Locations that will be deleted: ${locationsToBeDeletedString}` : "No locations will be deleted"}
          primaryBtn={{
            text: "Accept changes",
            onClick: this.onUploadLocationsWithHash
          }}
          secondaryBtn={{
            text: "Cancel",
            onClick: this.onCancel
          }}
        />

        <Modal
          show={!isEmpty(this.props.uploadErrors)}
          title="Errors"
          texts={uploadErrorJSON}
          primaryBtn={{
            text: "OK",
            onClick: this.onCancel
          }}
        />

      </OuterContainer>
    );
  }
}

function mapStateToProps(state) {
  return { 
    auth: state.auth,
    selectedCompany: state.auth.selectedCompany,
    isAuthLoading: state.loading.auth,
    uploadResponse: state.locations.uploadResponse,
    uploadErrors: state.locations.uploadErrors,
    isDownloadingLocationCSV: state.loading[API.GET_LOCATION_CSV],
    isDownloadingSensorCSV: state.loading[API.DOWNLOAD_SENSOR_CSV],
    isDownloadingGatewayCSV: state.loading[API.DOWNLOAD_GATEWAY_CSV],
    isDownloadingResourceCSV: state.loading[API.GET_RESOURCE_CSV],
    isUploadingLocationCSV: state.loading[API.UPLOAD_LOCATION_CSV]
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    downloadLocationsCSV: locationActions.downloadLocationsCSV,
    uploadLocationsCSV: locationActions.uploadLocationsCSV,
    verifyLocationsCSV: locationActions.verifyLocationsCSV,
    clearLocationsCSVResponse: locationActions.clearLocationsCSVResponse,
    downloadSensorsCSV: sensorActions.downloadSensorsCSV,
    downloadGatewaysCSV: gatewayActions.downloadGatewaysCSV,
    downloadResourceCSV: locationActions.downloadResourceCSV
   }, dispatch);
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ImportExport));

const FileInput = styled.input`
  display: none;
`;

const TableContainer = styled.div`
  max-width: ${props => props.width ? props.width : "1000px"};
`;
