import axios from "axios";
import Cookies from "universal-cookie";
import * as types from "../ApiTypes";
import * as actions from "./sharedFunction";
import * as selectionActions from "./selected";
import { API_URL, COOKIE_PREFIX } from "../env";
import { getCompanyId } from "../helpers";

const cookies = new Cookies();

export const getSensorTags = (queryParams) => async (dispatch) => {
  dispatch(actions.requestData(types.GET_SENSOR_TAGS));

  const apiUrl = await API_URL();
  const companyId = getCompanyId();
  const cookiePrefix = await COOKIE_PREFIX();
  const accessToken = cookies.get(`${cookiePrefix}_access_token`);

  axios.get(`${apiUrl}companies/${companyId}/sensortags`, await actions.getAxiosConfig(queryParams))
    .then((response) => (
      dispatch(actions.receiveData(response.data, types.GET_SENSOR_TAGS))
    ))
    .catch((error) => {
      if (error.response && error.response.status === 401) {
        actions.sessionTokenExpired(cookiePrefix, accessToken);
      } else {
        dispatch(actions.receiveError(error, types.GET_SENSOR_TAGS));
      }
    });
};

export const getSensorTag = (id) => async (dispatch) => {
  dispatch({
    type: types.REQ_DATA,
    payload: {
      id
    },
    fetchType: types.GET_SENSOR_TAG
  });

  const apiUrl = await API_URL();
  const companyId = getCompanyId();
  const cookiePrefix = await COOKIE_PREFIX();
  const accessToken = cookies.get(`${cookiePrefix}_access_token`);

  axios.get(`${apiUrl}companies/${companyId}/sensortags/${id}`, await actions.getAxiosConfig())
    .then((response) => {
      dispatch(actions.receiveData(response.data, types.GET_SENSOR_TAG));
    })
    .catch((error) => {
      if (error.response && error.response.status === 401) {
        actions.sessionTokenExpired(cookiePrefix, accessToken);
      } else {
        dispatch(actions.receiveError(error, types.GET_SENSOR_TAG));
      }
    });
};

export const createSensorTag = (body, redirect) => async (dispatch) => {
  dispatch(actions.requestData(types.CREATE_SENSOR_TAG));

  const apiUrl = await API_URL();
  const companyId = getCompanyId();
  const cookiePrefix = await COOKIE_PREFIX();
  const accessToken = cookies.get(`${cookiePrefix}_access_token`);

  axios.post(`${apiUrl}companies/${companyId}/sensortags`, body, await actions.getAxiosConfig())
    .then((response) => {
      dispatch(actions.receiveData(response.data, types.CREATE_SENSOR_TAG));
      dispatch(selectionActions.clearSelection());
      dispatch(getSensorTags());
      redirect(`/companies/${companyId}/installation/sensor-tags`);
    })
    .catch((error) => {
      if (error.response && error.response.status === 401) {
        actions.sessionTokenExpired(cookiePrefix, accessToken);
      } else {
        dispatch(actions.receiveError(error, types.CREATE_SENSOR_TAG));
      }
    });
};

export const updateSensorTag = (tagId, body, redirect) => async (dispatch) => {
  dispatch(actions.requestData(types.UPDATE_SENSOR_TAG));

  const apiUrl = await API_URL();
  const companyId = getCompanyId();
  const cookiePrefix = await COOKIE_PREFIX();
  const accessToken = cookies.get(`${cookiePrefix}_access_token`);

  axios.put(`${apiUrl}companies/${companyId}/sensortags/${tagId}`, body, await actions.getAxiosConfig())
    .then((response) => {
      dispatch(actions.receiveData(response.data, types.UPDATE_SENSOR_TAG));
      dispatch(selectionActions.clearSelection());
      dispatch(getSensorTags());
      redirect(`/companies/${companyId}/installation/sensor-tags`);
    })
    .catch((error) => {
      if (error.response && error.response.status === 401) {
        actions.sessionTokenExpired(cookiePrefix, accessToken);
      } else {
        dispatch(actions.receiveError(error, types.UPDATE_SENSOR_TAG));
      }
    });
};

export const deleteSensorTag = (tagId) => async (dispatch) => {

  dispatch(actions.requestData(types.DELETE_SENSOR_TAG));

  const apiUrl = await API_URL();
  const companyId = getCompanyId();
  const cookiePrefix = await COOKIE_PREFIX();
  const accessToken = cookies.get(`${cookiePrefix}_access_token`);

  axios.delete(`${apiUrl}companies/${companyId}/sensortags/${tagId}`, await actions.getAxiosConfig())
    .then((response) => {
      dispatch(actions.receiveData(response.data, types.DELETE_SENSOR_TAG));
      dispatch(selectionActions.clearSelection());
      dispatch(getSensorTags());
    })
    .catch((error) => {
      if (error.response && error.response.status === 401) {
        actions.sessionTokenExpired(cookiePrefix, accessToken);
      } else {
        dispatch(actions.receiveError(error, types.DELETE_SENSOR_TAG));
      }
    });
};
