import React, { useState } from "react";
import isNumber from "lodash/isNumber";
import moment from "moment";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUp, faArrowDown } from "@fortawesome/free-solid-svg-icons";
import AnimateHeight from 'react-animate-height';
import "chartjs-adapter-moment";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Tooltip,
  TimeScale
} from "chart.js";
import { Bar } from "react-chartjs-2";
import Caret from "./caret3_grey.png";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Tooltip,
  TimeScale,
  {
    id: "white-background",
    beforeDraw: (chart) => {
      const ctx = chart.ctx;
      ctx.fillStyle = "white";
      ctx.fillRect(0, 0, chart.width, chart.height);
    }
  }
);

interface DayUsage {
  date: string;
  count: number;
}

interface AppUsageCardProps {
  title: string;
  subtitle: string;
  value: number;
  valueChange: number;
  monthData: Array<DayUsage>;
  expanded: boolean;
  onToggleExpand: () => void;
}

let chartRef: React.RefObject<ChartJS>;

const AppUsageCard: React.FC<AppUsageCardProps> = ({ title, subtitle, value, valueChange, monthData = [], expanded = false, onToggleExpand = () => { } }) => {

  // Detect if Safari to determine if we should show the chart (animation bug)
  // Safari: chart does not trigger render through animation
  const isSafari = navigator.vendor.match(/apple/i) &&
    !navigator.userAgent.match(/crios/i) &&
    !navigator.userAgent.match(/fxios/i) &&
    !navigator.userAgent.match(/Opera|OPT\//);

  const [showChart, setShowChart] = useState(expanded || !isSafari);

  chartRef = React.createRef();

  const startMoment = moment().startOf("month").subtract(1, "month");
  const endMoment = moment().startOf("month").subtract(1, "month").endOf("month");

  const labels: string[] = [];
  const datasets: any[] = [];
  const filteredCount: any[] = [];

  // Create days array
  const days: moment.Moment[] = [];
  let day = startMoment.clone();

  while (day <= endMoment) {
    days.push(day);
    day = day.clone().add(1, "day");
  }

  // Add options
  const options = {
    aspectRatio: 1.8,
    responsive: true,
    maintainAspectRatio: true,
    clip: false,
    layout: {
      padding: {
        top: 0,
        bottom: 0,
        left: 0,
        right: 0
      }
    },
    plugins: {
      legend: {
        display: false,
        position: "right",
        labels: {
          boxWidth: 16
        }
      },
      title: {
        font: {
          family: "'Helvetica Neue', Arial, Helvetica, sans-serif",
          size: 14,
          weight: "normal",
          lineHeight: 0.5
        }
      },
      datalabels: false,
      reportLegend: false
    },
    devicePixelRatio: 4,
    scales: {
      x: {
        type: "time",
        time: {
          unit: "day",
          tooltipFormat: "D. MMM yyyy",
          displayFormats: {
            day: "D. MMM"
          }
        }
      },
      y: {}
    }
  };

  // Add to dataset
  days.forEach((day, index) => {

    // @ts-ignore
    labels.push(day);

    // Get value for this day
    const sample = monthData.find(sample => {
      return moment(sample.date).set("hours", 0).isSame(day);
    });

    filteredCount.push(sample ? sample.count : null);
  });

  datasets.push({ label: "count", data: filteredCount, borderColor: "#F94144", backgroundColor: "#F94144" });


  let differenceIcon;
  if (valueChange > 0) {
    differenceIcon = <FontAwesomeIcon icon={faArrowUp} color="green" fontSize={14} transform={{ rotate: 45 }} style={{ marginRight: "3px" }} />;
  }
  else if (valueChange < 0) {
    differenceIcon = <FontAwesomeIcon icon={faArrowDown} color="#CA4C4C" fontSize={14} transform={{ rotate: -45 }} style={{ marginRight: "3px" }} />;
  }
  else if (valueChange === 0) {
    differenceIcon = <FontAwesomeIcon icon={faArrowUp} color="#888888" fontSize={14} transform={{ rotate: 45 }} style={{ marginRight: "3px" }} />;
  }

  return (
    <Container>
      <InnerContainer $clickable={monthData.length > 0} onClick={() => onToggleExpand()}>
        <Header>
          <Title>
            {title}
          </Title>
          <Subtitle>
            {subtitle}
          </Subtitle>
        </Header>
        <Content>
          {isNumber(value) && (
            <ContentContainer $clickable={monthData.length > 0}>
              <Value>
                {value}
              </Value>
              <ValueChange>
                ({differenceIcon}{Math.abs(valueChange)})
              </ValueChange>
            </ContentContainer>
          )}
          {monthData.length > 0 && (
            <ExpandContainer>
              <ExpandIcon src={Caret} $rotated={expanded} />
            </ExpandContainer>
          )}
        </Content>
      </InnerContainer>

      <Drawer $expanded={expanded}>
        <AnimateHeight
          duration={500}
          height={expanded ? "auto" : 0}
          onHeightAnimationStart={(newHeight: any) => {
            if (isSafari && newHeight === 0) {
              setShowChart(false);
            }
          }}
          onHeightAnimationEnd={(newHeight: any) => {
            if (isSafari && newHeight !== 0) {
              setShowChart(true);
            }
          }}>

          <GraphContainer>
            <GraphRatio>
              {showChart && (<Bar
                // @ts-ignore
                options={options}
                data={{ labels, datasets }}
                // @ts-ignore
                ref={chartRef}
              />
              )}
            </GraphRatio>
          </GraphContainer>

        </AnimateHeight>
      </Drawer>
    </Container>
  );
};

const Container = styled.div`
  display: block;
  background-color: rgb(255, 255, 255);
  border-radius: 6px;
  border-width: 1px;
  border-style: solid;
  border-color: rgb(221, 221, 221);
  margin-bottom: 20px;
  overflow: hidden;
`;

const InnerContainer = styled.div<{ $clickable?: boolean }>`
  display: flex;
  justify-content: space-between;
  cursor: ${props => props.$clickable ? "pointer" : "default"};
`;

const Header = styled.div`
  display: inline-flex;
  align-items: start;
  justify-content: center;
  flex-direction: column;
  padding-left: 20px;
`;

const Title = styled.div`
  font-family: Source Sans Pro;
  font-size: 18px;
  font-weight: 600;
  color: #222222;
`;

const Subtitle = styled.span`
  font-family: Source Sans Pro;
  font-size: 14px;
  font-weight: 600;
  color: #888888;
`;

const Content = styled.div`
  display: flex;
  align-items: center;
  height: 72px;
`;

const ContentContainer = styled.div<{ $clickable?: boolean }>`
  margin-right: ${props => props.$clickable ? "0px" : "20px"};
`;

const Value = styled.div`
  display: inline-block;
  font-family: Source Sans Pro;
  font-size: 30px;
  font-weight: 600;
  color: #222222;
`;

const ValueChange = styled.div`
  display: inline-block;
  font-family: Source Sans Pro;
  font-size: 16px;
  font-weight: 600;
  color: #888888;
  margin-left: 4px;
`;

const ExpandContainer = styled.div`
  display: flex;
  width: 55px;
  height: 72px;
  align-items: center;
  justify-content: center;
`;

const ExpandIcon = styled.img<{ $rotated: boolean }>`
  width: 25px;
  height: 25px;
  transform: ${props => props.$rotated && "rotate(180deg)"};
`;

const Drawer = styled.div<{ $expanded: boolean }>`
  background-color: #fff;
`;

const GraphContainer = styled.div`
  display: block;
  padding: 20px;
  box-sizing: border-box;
  background-color: #fff;
  border-top: 1px solid #dbdbdb;
`;

const GraphRatio = styled.div`
  display: block;
  height: 100%;
  aspect-ratio: 1.8;
`;

export default AppUsageCard;
