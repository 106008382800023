// import { toast } from "react-toastify";
import * as types from "../ApiTypes";

const initialState = {
  type: "general",
  message: "",
  types: []
};

export default function feedbackReducer(state = initialState, action) {

  switch (action.type) {

    case types.GOT_FEEDBACK_TYPES: {
      return {...state, types: action.payload };
    }

    case types.UPDATE_FEEDBACK_FORM: {
      return {...state, type: action.payload.type, message: action.payload.message };
    }

    case types.CLEAR_DATA: {
      return initialState;
    }
    
    default: {
      return state;
    }
  }
}
