import axios from "axios";
import Cookies from "universal-cookie";
import get from "lodash/get";
import * as types from "../ApiTypes";
import * as actions from "./sharedFunction";
import { API_URL, COOKIE_PREFIX } from "../env";
import { getCompanyId } from "../helpers";

const cookies = new Cookies();

export const getScreenViews = (screenGroupId, queryParams) => async (dispatch) => {
  dispatch(actions.requestData(types.GET_SCREEN_VIEWS));

  const apiUrl = await API_URL();
  const companyId = getCompanyId();
  const cookiePrefix = await COOKIE_PREFIX();
  const accessToken = cookies.get(`${cookiePrefix}_access_token`);
  
  axios.get(`${apiUrl}companies/${companyId}/screengroups/${screenGroupId}/views`, await actions.getAxiosConfig(queryParams))
    .then((response) => {
      dispatch(actions.receiveData(response.data, types.GET_SCREEN_VIEWS));
    })
    .catch((error) => {
      if (error.response && error.response.status === 401) {
        actions.sessionTokenExpired(cookiePrefix, accessToken);
      } else {
        dispatch(actions.receiveError(error, types.GET_SCREEN_VIEWS));
      }
    });
};

export const getScreenView = (screenGroupId, screenViewId) => async (dispatch) => {
  dispatch(actions.requestData(types.GET_SCREEN_VIEW, { id: screenViewId }));

  const apiUrl = await API_URL();
  const companyId = getCompanyId();
  const cookiePrefix = await COOKIE_PREFIX();
  const accessToken = cookies.get(`${cookiePrefix}_access_token`);
  
  axios.get(`${apiUrl}companies/${companyId}/screengroups/${screenGroupId}/views/${screenViewId}`, await actions.getAxiosConfig())
    .then((response) => {
      dispatch(actions.receiveData(response.data, types.GET_SCREEN_VIEW));
    })
    .catch((error) => {
      if (error.response && error.response.status === 401) {
        actions.sessionTokenExpired(cookiePrefix, accessToken);
      } else {
        dispatch(actions.receiveError(error, types.GET_SCREEN_VIEW));
      }
    });
};

export const createScreenView = (screenGroupId, newView, redirect) => async (dispatch) => {
  dispatch(actions.postData(types.CREATE_SCREEN_VIEW));
  
  const apiUrl = await API_URL();
  const companyId = getCompanyId();
  const cookiePrefix = await COOKIE_PREFIX();
  const accessToken = cookies.get(`${cookiePrefix}_access_token`);
  const axiosConfig = await actions.getAxiosConfig();

  axios.post(`${apiUrl}companies/${companyId}/screengroups/${screenGroupId}/views`, newView, axiosConfig)
    .then((response) => {
      dispatch(actions.receiveData(response.data, types.CREATE_SCREEN_VIEW));
      dispatch(getScreenViews(screenGroupId));

      if (response.data && response.data.id) {
        if (redirect) {
          redirect(`/companies/${companyId}/screens/edit/${screenGroupId}/views/${response.data.id}/layout`);
        }
      }
    })
    .catch((error) => {
      if (error.response && error.response.status === 401) {
        actions.sessionTokenExpired(cookiePrefix, accessToken);
      } else {
        dispatch(actions.receiveError(error, types.CREATE_SCREEN_VIEW));
      }
    });
};

export const updateScreenView = (screenGroupId, screenViewId, view) => async (dispatch) => {
  dispatch(actions.postData(types.UPDATE_SCREEN_VIEW));

  const apiUrl = await API_URL();
  const companyId = getCompanyId();
  const cookiePrefix = await COOKIE_PREFIX();
  const accessToken = cookies.get(`${cookiePrefix}_access_token`);
  
  axios.patch(`${apiUrl}companies/${companyId}/screengroups/${screenGroupId}/views/${screenViewId}`, view, await actions.getAxiosConfig())
    .then((response) => {
      dispatch(actions.receiveData(response.data, types.UPDATE_SCREEN_VIEW));
      dispatch(getScreenView(screenGroupId, screenViewId));
    })
    .catch((error) => {
      if (error.response && error.response.status === 401) {
        actions.sessionTokenExpired(cookiePrefix, accessToken);
      } else {
        dispatch(actions.receiveError(error, types.UPDATE_SCREEN_VIEW));
      }
    });
};

export const deleteScreenView = (screenGroupId, screenViewId, redirect) => async (dispatch) => {
  dispatch(actions.postData(types.DELETE_SCREEN_VIEW));

  const apiUrl = await API_URL();
  const companyId = getCompanyId();
  const cookiePrefix = await COOKIE_PREFIX();
  const accessToken = cookies.get(`${cookiePrefix}_access_token`);

  axios.delete(`${apiUrl}companies/${companyId}/screengroups/${screenGroupId}/views/${screenViewId}`, await actions.getAxiosConfig())
    .then((response) => {
      dispatch(actions.receiveData(response.data, types.DELETE_SCREEN_VIEW));
      dispatch(getScreenViews(screenGroupId));
      if (redirect) {
        redirect(`/companies/${companyId}/screens/edit/${screenGroupId}/views`);
      }
    })
    .catch((error) => {
      if (error.response && error.response.status === 401) {
        actions.sessionTokenExpired(cookiePrefix, accessToken);
      } else {
        dispatch(actions.receiveError(error, types.DELETE_SCREEN_VIEW));
      }
    });
};

export const updateScreenViewForm = (form, layoutPath) => async (dispatch) => {
  dispatch(actions.receiveData(form, types.UPDATE_SCREEN_VIEW_FORM, { layoutPath }));
};

export const resetScreenViewForm = () => async (dispatch) => {
  dispatch({ type: types.RESET_SCREEN_VIEW_FORM });
};

export const deselectScreenView = () => async (dispatch) => {
  dispatch({ type: types.DESELECT_SCREEN_VIEW });
}

export const clearScreenViewData = () => async (dispatch) => {
  dispatch({ type: types.CLEAR_SCREEN_VIEW_DATA });
}

