import get from "lodash/get";
import { toast } from "react-toastify";
import * as types from "../ApiTypes";

const initialState = {
  data: [],
  count: 0,
  offset: 0,
  limit: 20
};

export default function anomaliesReducer(state = initialState, action) {

  switch (action.type) {
    
    case types.REQ_DATA: {
      if (action.fetchType === types.GET_ANOMALIES) {
        return initialState;
      }
      
      return state;
    }

    case types.RECV_DATA: {
      if (action.fetchType === types.GET_ANOMALIES) {
        return {
          data: action.payload.results,
          count: action.payload.count,
          limit: action.payload.limit,
          offset: action.payload.offset
        };
      }
      
        
      if (action.fetchType === types.UPDATE_ANOMALY) {
  
        // Find anomaly in state.data and update state for that anomaly in state.data
        const anomalyIndex = state.data.findIndex(anomaly => anomaly.anomalyId === action.metadata.anomalyId);
        if (anomalyIndex > -1) {
          const updatedAnomaly = {
            ...state.data[anomalyIndex],
            state: action.metadata.state
          };
          const updatedData = [...state.data];
          updatedData[anomalyIndex] = updatedAnomaly;
          return {
            ...state,
            data: updatedData
          };
        }
  
        return state;
      }
  
      return state;
    }
    

    case types.RECV_ERROR: {

      const statusCode = get(action, "payload.response.status", "Error");

      if (action.fetchType === types.GET_ANOMALIES) {
        toast.error(`${statusCode}: Could not get anomalies`);
      }
      
      return state;
    }

    case types.CLEAR_DATA: {
      return initialState;
    }

    default: {
      return state;
    }
  }
}
