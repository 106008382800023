import React, { Component } from "react";
import { Col, Row, Hidden, Visible } from "react-grid-system";
import { connect } from "react-redux";
import { Route } from "react-router-dom";
import Freetext from "../../components/Freetext";
import FilterList from "./filterList";
import FilterDetails from "./filterDetails";
import style from "./style.module.scss";

class Filters extends Component {
  render() {
    const description = "Each filter creates a selection of locations that can be used to inspect different parts of the sensor installation.";
    return (
      <>
        <Hidden xs sm md>
          <div className={style.mainContainerHidden}>
            <div className={style.row}>
              <div className={style.listContainer}>
                <div className={style.scroll}>
                  <Row className={style.topRow}>
                    <Col>
                      <Freetext header="Filters" content={description} />
                    </Col>
                  </Row>
                  <Route path="/companies/:companyId/filters/:id?" children={(props) => (<FilterList {...props} />)} />
                </div>
              </div>
              <div className={style.sideBar}>
                <Route path="/companies/:companyId/filters/:id?" children={(props) => (<FilterDetails {...props} />)} />
              </div>
            </div>
          </div>
        </Hidden>
        <Visible xs sm md>
          <div className={style.mainContainerHidden}>
            <div className={style.row}>
              <div className={style.col}>
                <div className={style.slimScroll}>
                  <Row className={style.topRow}>
                    <Col>
                      <Freetext header="Filters" content={description} />
                    </Col>
                  </Row>
                  <Row className={style.topRow}>
                    <Col>
                      <Freetext content="You need a bigger screen to manage filters" />
                    </Col>
                  </Row>
                </div>
              </div>
            </div>
          </div>
        </Visible>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    currentFilterId: state.filter.id
  };
}

export default connect(mapStateToProps, null)(Filters);