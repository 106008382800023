import React, { Component } from "react";
import { Switch, Route, Redirect, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { get, isEmpty } from "lodash";
import moment from "moment";
import NotFound from "../NotFound";
import Loader from "../../components/Loader";
import LargeHeader from "../../components/LargeHeader";
import SubNavigation from "../../components/SubNavigation";
import Theme from "./theme";
import GraphResources from "./graphResources";
import Integrations from "./integrations";
import ServiceAccounts from "./serviceAccounts";
import ImportExport from "./importExport";
import Overview from "./overview";
import Statistics from "./statistics";
import AdvancedSettings from "./advancedSettings";
import * as authActions from "../../actions/auth";
import * as accountActions from "../../actions/account";
import styled from "styled-components";

class AccountContainer extends Component {

  constructor(props) {
    super(props);
    this.state = {
      id: props.match.params.companyId,
      selectedGraphResources: {},
    };

    this.onToggleGraphResource = this.onToggleGraphResource.bind(this);
    this.onDeleteSelectedGraphResources = this.onDeleteSelectedGraphResources.bind(this);

    this.props.getCompany();
    this.props.getAccountSummary();
  }

  onToggleGraphResource(row) {
    if (this.state.selectedGraphResources[row.original.resourceType] !== undefined && this.state.selectedGraphResources[row.original.resourceType]) {
      this.setState(prevState => {
        const newSelectedGraphResources = {...prevState.selectedGraphResources};
        delete newSelectedGraphResources[row.original.resourceType];
        return { selectedGraphResources: newSelectedGraphResources };
      });
    }
    else {
      this.setState(prevState => {
        const newSelectedGraphResources = {...prevState.selectedGraphResources};
        newSelectedGraphResources[row.original.resourceType] = row;

        if (Object.keys(newSelectedGraphResources).length === 1) {
          let startTime = moment((row.original.startTime.hours + ":" + row.original.startTime.minutes), "HH:mm");
          let endTime = moment((row.original.endTime.hours + ":" + row.original.endTime.minutes), "HH:mm");
          let slotCount = 1;
          if (row.original.slotSizeMinutes > 0 && startTime.isValid() && endTime.isValid() && startTime.isBefore(endTime)) {
            let duration = moment.duration(endTime.diff(startTime));
            let durationInMinutes = duration.asMinutes();
            slotCount = durationInMinutes / row.original.slotSizeMinutes;
          }
          
          return { selectedGraphResources: newSelectedGraphResources, newResourceType: row.original.resourceType, newResourceTypeNorsk: row.original.translations.nb, newResourceStartTime: startTime.format("HH:mm"), newResourceEndTime: endTime.format("HH:mm"), newResourceSlotCount: slotCount, newResourceMax: row.original.maxBookingsPerDay };
        }

        return { selectedGraphResources: newSelectedGraphResources };
      });
    }
  }

  onDeleteSelectedGraphResources() {
    if (!isEmpty(this.state.selectedGraphResources)) {
      let newResourceSlots = JSON.parse(JSON.stringify(this.props.companyDraft.resourceSlots || []));
      let selectedResourceTypes = Object.keys(this.state.selectedGraphResources);
      newResourceSlots = newResourceSlots.filter(slot => !selectedResourceTypes.includes(slot.resourceType));
      this.props.updateCompanyDraft({ resourceSlots: newResourceSlots });
      this.setState({ selectedGraphResources: {} });  
    }
  }

  render() {
    if (this.props.isAuthLoading) {
      return <Loader fullScreen />;
    }

    // console.log("AccountContainer", this.props, this.state);

    const subNavigationLinks = [];
    subNavigationLinks.push({ pathname: `/companies/${this.props.match.params.companyId}/account/overview`, label: `Overview` });
    subNavigationLinks.push({ pathname: `/companies/${this.props.match.params.companyId}/account/installation`, label: `Installation` });
    subNavigationLinks.push({ pathname: `/companies/${this.props.match.params.companyId}/account/import-export`, label: `Import/export` });

    if (this.props.auth.hasITAdminRole) {
      subNavigationLinks.push({ pathname: `/companies/${this.props.match.params.companyId}/account/integrations`, label: `Integrations` });
      subNavigationLinks.push({ pathname: `/companies/${this.props.match.params.companyId}/account/service-accounts`, label: `Service accounts` });
      subNavigationLinks.push({ pathname: `/companies/${this.props.match.params.companyId}/account/theme`, label: `Theme` });

      if (get(this.props.company, "applicationFeatures.useGraphResources", false) || this.props.auth.hasSupportRole) {
        subNavigationLinks.push({ pathname: `/companies/${this.props.match.params.companyId}/account/graph-resources`, label: `Graph resources`, hiddenForAdmins: !get(this.props.company, "applicationFeatures.useGraphResources", false) });
      }
    }

    // If user is sudo or support, show advanced settings
    if (this.props.auth.hasSupportRole) {
      subNavigationLinks.push({ pathname: `/companies/${this.props.match.params.companyId}/account/advanced-settings`, label: `Advanced`, hiddenForAdmins: true });
    }
    
    return (
      <Page>
        <LargeHeader title="Account" />
        <SubNavigation links={subNavigationLinks} />
        <Switch>
          <Redirect exact from="/companies/:companyId/account" to={{ pathname: `/companies/${this.props.match.params.companyId}/account/overview` }} />
          <Route path="/companies/:companyId/account/overview" children={(props) => <Overview {...props} />} />
          <Route path="/companies/:companyId/account/installation" children={(props) => <Statistics {...props} />} />
          <Route path="/companies/:companyId/account/import-export" children={(props) => <ImportExport {...props} />} />
          { this.props.auth.hasITAdminRole && <Route path="/companies/:companyId/account/integrations" children={(props) => <Integrations {...props} />} /> }
          { this.props.auth.hasITAdminRole && <Route path="/companies/:companyId/account/service-accounts/:serviceAccountId?" children={(props) => <ServiceAccounts {...props} serviceAccountId={props.match.params.serviceAccountId}  />} /> }
          { this.props.auth.hasITAdminRole && <Route path="/companies/:companyId/account/theme" children={(props) => <Theme {...props} />} /> }
          { this.props.auth.hasITAdminRole && <Route path="/companies/:companyId/account/graph-resources/:resourceId?" children={(props) => <GraphResources {...props} selectedGraphResources={this.state.selectedGraphResources} onToggleGraphResource={this.onToggleGraphResource} resourceId={props.match.params.resourceId} />} /> }
          { this.props.auth.hasSupportRole && <Route path="/companies/:companyId/account/advanced-settings" children={(props) => <AdvancedSettings {...props} />} /> }
          <Route render={NotFound} />
        </Switch>
      </Page>
    );
  }
}

function mapStateToProps(state) {
  return { 
    auth: state.auth,
    company: state.auth.selectedCompany,
    companyDraft: state.sudo.draft,
    isAuthLoading: state.loading.auth
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    getCompany: accountActions.getCompany,
    updateCompanyDraft: authActions.updateCompanyDraft,
    getAccountSummary: accountActions.getAccountSummary,
   }, dispatch);
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AccountContainer));

const Page = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const OuterContainer = styled.div`
  overflow: auto;
  height: 100%;

  background-color: ${props => {
    if (props.$darkBackground) {
      return "#f6f6f6";
    }
    return "white";
  }};
`;

export const InnerContainer = styled.div`
  padding-left: 60px;
  padding-right: 60px;
  margin-bottom: 100px;

  @media (max-width: 992px) {
    padding-left: 10px;
    padding-right: 10px;
  }
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding-top: ${props => props.$paddingTop || "0px"};
  
  justify-content: ${props => {
    if (props.$justifyContent) {
      return props.$justifyContent;
    }
  }};

  width: 100%;
  max-width: ${props => {
    
    if (props.width) {
      return props.width;
    }

    switch (props.size) {
      case "xs":
        return "380px";
      case "sm":
        return "540px";
      case "md":
        return "720px";
      case "lg":
        return "960px";
      case "xl":
        return "1140px";
      default:
        return "100%";
    }}
  };
`;

export const FloatCol = styled.div`
  display: inline-block;
  margin-right: 15px;
`;

export const Col = styled.div`
  display: inline-block;
  // float: left;
  width: 100%;
  margin-right: ${props => props.$noMargin ? "" : "15px"};
  max-width: ${props => {

    if (props.width) {
      return props.width;
    }

    switch (props.size) {
      case "xs":
        return "380px";
      case "sm":
        return "540px";
      case "md":
        return "720px";
      case "lg":
        return "960px";
      case "xl":
        return "1140px";
      default:
        return "100%";
    }}
  };
`;