import axios from "axios";
import get from "lodash/get";
import Cookies from "universal-cookie";
import * as types from "../ApiTypes";
import * as actions from "./sharedFunction";
import * as selectionActions from "./selected";
import { API_URL, CDN_URL, COOKIE_PREFIX } from "../env";
import { getUserGroup } from "./userGroups";
import { getLocation } from "./locations";
import { getCompanyId } from "../helpers";

const cookies = new Cookies();

export const getAllContent = (queryParams) => async (dispatch) => {
  dispatch(actions.requestData(types.GET_ALL_CONTENT));

  const apiUrl = await API_URL();
  const companyId = getCompanyId();
  const cookiePrefix = await COOKIE_PREFIX();
  const accessToken = cookies.get(`${cookiePrefix}_access_token`);

  axios.get(`${apiUrl}companies/${companyId}/applications`, await actions.getAxiosConfig(queryParams))
    .then((response) => {
      dispatch(actions.receiveData(response.data, types.GET_ALL_CONTENT));
    })
    .catch((error) => {
      if (error.response && error.response.status === 401) {
        actions.sessionTokenExpired(cookiePrefix, accessToken);
      } else {
        dispatch(actions.receiveError(error, types.GET_ALL_CONTENT));
      }
    });
};

export const createContent = (newContent, redirect) => async (dispatch) => {
  dispatch(actions.requestData(types.CREATE_CONTENT));

  const apiUrl = await API_URL();
  const companyId = getCompanyId();
  const cookiePrefix = await COOKIE_PREFIX();
  const accessToken = cookies.get(`${cookiePrefix}_access_token`);

  axios.post(`${apiUrl}companies/${companyId}/applications`, newContent, await actions.getAxiosConfig())
    .then((response) => {
      dispatch(actions.receiveData(response.data, types.CREATE_CONTENT));
      dispatch(getAllContent());
      if (redirect) {
        if (response.data._id) {
          redirect(`/companies/${companyId}/app/content/${response.data._id}`);
        }
        else {
          redirect(`/companies/${companyId}/app/content`);
        }
      }
    })
    .catch((error) => {
      if (error.response && error.response.status === 401) {
        actions.sessionTokenExpired(cookiePrefix, accessToken);
      } else {
        dispatch(actions.receiveError(error, types.CREATE_CONTENT));
      }
    });
};

export const getContent = (contentId) => async (dispatch) => {
  dispatch(actions.requestData(types.GET_CONTENT));

  const apiUrl = await API_URL();
  const companyId = getCompanyId();
  const cookiePrefix = await COOKIE_PREFIX();
  const accessToken = cookies.get(`${cookiePrefix}_access_token`);

  axios.get(`${apiUrl}companies/${companyId}/applications/${contentId}`, await actions.getAxiosConfig())
    .then((response) => {
      dispatch(actions.receiveData(response.data, types.GET_CONTENT));
    })
    .catch((error) => {
      if (error.response && error.response.status === 401) {
        actions.sessionTokenExpired(cookiePrefix, accessToken);
      } else {
        dispatch(actions.receiveError(error, types.GET_CONTENT));
      }
    });
};

export const updateContent = (contentId, newContent, redirect) => async (dispatch) => {
  dispatch(actions.requestData(types.UPDATE_CONTENT));

  const apiUrl = await API_URL();
  const companyId = getCompanyId();
  const cookiePrefix = await COOKIE_PREFIX();
  const accessToken = cookies.get(`${cookiePrefix}_access_token`);

  axios.put(`${apiUrl}companies/${companyId}/applications/${contentId}`, newContent, await actions.getAxiosConfig())
    .then((response) => {
      dispatch(actions.receiveData(response.data, types.UPDATE_CONTENT));
      dispatch(selectionActions.clearSelection());
      dispatch(getAllContent());
      dispatch(getContent(contentId));
    })
    .catch((error) => {
      if (error.response && error.response.status === 401) {
        actions.sessionTokenExpired(cookiePrefix, accessToken);
      } else {
        dispatch(actions.receiveError(error, types.UPDATE_CONTENT));
      }
    });
};

export const addUserGroupToContent = (userGroupId, contentIds) => async (dispatch) => {
  dispatch(actions.requestData(types.UPDATE_CONTENT));

  const apiUrl = await API_URL();
  const companyId = getCompanyId();
  const cookiePrefix = await COOKIE_PREFIX();
  const accessToken = cookies.get(`${cookiePrefix}_access_token`);
  const config = await actions.getAxiosConfig();

  // Looping through all content objects... 
  axios.all(contentIds.map((contentId) => {
      return axios.get(`${apiUrl}companies/${companyId}/applications/${contentId}`, config)
        .then((response) => {
          let usergroups = get(response, "data.usergroups", []).filter(element => userGroupId !== element._id).map(element => element._id);
          usergroups.push(userGroupId);
          const newContent = { usergroups }
          return axios.put(`${apiUrl}companies/${companyId}/applications/${contentId}`, newContent, config)
        })
    }))
    .then((response) => {
      dispatch(actions.receiveData(response.data, types.UPDATE_CONTENT));
      dispatch(selectionActions.clearSelection());
      dispatch(getUserGroup(userGroupId));
      dispatch(getAllContent());
    })
    .catch((error) => {
      if (error.response && error.response.status === 401) {
        actions.sessionTokenExpired(cookiePrefix, accessToken);
      } else {
        dispatch(actions.receiveError(error, types.UPDATE_CONTENT));
      }
    });
};

export const removeUserGroupFromContent = (userGroupId, contentIds) =>  async (dispatch) => {
  dispatch(actions.requestData(types.UPDATE_CONTENT));

  const apiUrl = await API_URL();
  const companyId = getCompanyId();
  const cookiePrefix = await COOKIE_PREFIX();
  const accessToken = cookies.get(`${cookiePrefix}_access_token`);
  const config = await actions.getAxiosConfig();

  // Looping through all content objects... 
  axios.all(contentIds.map((contentId) => {
      return axios.get(`${apiUrl}companies/${companyId}/applications/${contentId}`, config)
        .then((response) => {
          let usergroups = get(response, "data.usergroups", []).filter(element => userGroupId !== element._id).map(element => element._id);
          const newContent = { usergroups }
          return axios.put(`${apiUrl}companies/${companyId}/applications/${contentId}`, newContent, config)
        })
    }))
    .then((response) => {
      dispatch(actions.receiveData(response.data, types.UPDATE_CONTENT));
      dispatch(selectionActions.clearSelection());
      dispatch(getUserGroup(userGroupId));
      dispatch(getAllContent());
    })
    .catch((error) => {
      if (error.response && error.response.status === 401) {
        actions.sessionTokenExpired(cookiePrefix, accessToken);
      } else {
        dispatch(actions.receiveError(error, types.UPDATE_CONTENT));
      }
    });
};

export const addLocationToContent = (locationId, contentIds) =>  async (dispatch) => {
  dispatch(actions.requestData(types.UPDATE_CONTENT));

  const apiUrl = await API_URL();
  const companyId = getCompanyId();
  const cookiePrefix = await COOKIE_PREFIX();
  const accessToken = cookies.get(`${cookiePrefix}_access_token`);
  const config = await actions.getAxiosConfig();

  // Looping through all content objects... 
  axios.all(contentIds.map((contentId) => {
      return axios.get(`${apiUrl}companies/${companyId}/applications/${contentId}`, config)
        .then((response) => {
          let locations = get(response, "data.locations", []).filter(element => locationId !== element._id).map(element => element._id);
          locations.push(locationId);
          const newContent = { locations }
          return axios.put(`${apiUrl}companies/${companyId}/applications/${contentId}`, newContent, config)
        })
    }))
    .then((response) => {
      dispatch(actions.receiveData(response.data, types.UPDATE_CONTENT));
      dispatch(selectionActions.clearSelection());
      dispatch(getLocation(locationId));
      dispatch(getAllContent());
    })
    .catch((error) => {
      if (error.response && error.response.status === 401) {
        actions.sessionTokenExpired(cookiePrefix, accessToken);
      } else {
        dispatch(actions.receiveError(error, types.UPDATE_CONTENT));
      }
    });
};

export const removeLocationFromContent = (locationId, contentIds) =>  async (dispatch) => {
  dispatch(actions.requestData(types.UPDATE_CONTENT));

  const apiUrl = await API_URL();
  const companyId = getCompanyId();
  const cookiePrefix = await COOKIE_PREFIX();
  const accessToken = cookies.get(`${cookiePrefix}_access_token`);
  const config = await actions.getAxiosConfig();

  // Looping through all content objects... 
  axios.all(contentIds.map((contentId) => {
      return axios.get(`${apiUrl}companies/${companyId}/applications/${contentId}`, config)
        .then((response) => {
          const locations = get(response, "data.locations", []).filter(element => locationId !== element._id).map(element => element._id);
          const newContent = { locations }
          return axios.put(`${apiUrl}companies/${companyId}/applications/${contentId}`, newContent, config)
        })
    }))
    .then((response) => {
      dispatch(actions.receiveData(response.data, types.UPDATE_CONTENT));
      dispatch(selectionActions.clearSelection());
      dispatch(getLocation(locationId));
      dispatch(getAllContent());
    })
    .catch((error) => {
      if (error.response && error.response.status === 401) {
        actions.sessionTokenExpired(cookiePrefix, accessToken);
      } else {
        dispatch(actions.receiveError(error, types.UPDATE_CONTENT));
      }
    });
};

export const deleteContent = (ids) => async (dispatch) => {
  dispatch(actions.requestData(types.DELETE_CONTENT));

  const apiUrl = await API_URL();
  const companyId = getCompanyId();
  const cookiePrefix = await COOKIE_PREFIX();
  const accessToken = cookies.get(`${cookiePrefix}_access_token`);
  const config = await actions.getAxiosConfig();

  // Looping through all location ids... 
  axios.all(ids.map((id) => (
      axios.delete(`${apiUrl}companies/${companyId}/applications/${id}`, config)
    )))
    .then((response) => {
      dispatch(actions.receiveData(response.data, types.DELETE_CONTENT));
      dispatch(selectionActions.clearSelection());
      dispatch(getAllContent());
    })
    .catch((error) => {
      if (error.response && error.response.status === 401) {
        actions.sessionTokenExpired(cookiePrefix, accessToken);
      } else {
        dispatch(actions.receiveError(error, types.DELETE_CONTENT));
      }
    });
}

export const addLocationToMultipleContent = (locationId, content) => async (dispatch) => {
  // dispatch(actions.requestData(types.UPDATE_CONTENT));

  // const apiUrl = await API_URL();
  // const companyId = getCompanyId();
  // const cookiePrefix = await COOKIE_PREFIX();
  // const accessToken = cookies.get(`${cookiePrefix}_access_token`);

  // axios.put(`${apiUrl}companies/${companyId}/applications/${contentId}`, newContent, await actions.getAxiosConfig())
  //   .then((response) => {
  //     dispatch(actions.receiveData(response.data, types.UPDATE_CONTENT));
  //     dispatch(selectionActions.clearSelection());
  //     dispatch(getContent(contentId));
  //   })
  //   .catch((error) => {
  //     if (error.response && error.response.status === 401) {
  //       actions.sessionTokenExpired(cookiePrefix, accessToken);
  //     } else {
  //       dispatch(actions.receiveError(error, types.UPDATE_CONTENT));
  //     }
  //   });
};

export const removeLocationFromMultipleContent = (locationId, content) => async (dispatch) => {
  // TODO
};

export const clearContent = () => async (dispatch) => {
  dispatch({ type: types.CLEAR_CONTENT })
};

export const draftContentChange = (newContent) => async (dispatch) => {
  dispatch({ type: types.DRAFT_CONTENT_CHANGE, payload: newContent })
};

export const sendNotification = (content, title, message) => async (dispatch) => {
  dispatch(actions.requestData(types.SEND_NOTIFICATION));

  const apiUrl = await API_URL();
  const companyId = getCompanyId();
  const cookiePrefix = await COOKIE_PREFIX();
  const accessToken = cookies.get(`${cookiePrefix}_access_token`);

  const body = {
    title,
    body: message
  };

  axios.post(`${apiUrl}companies/${companyId}/applications/${content.id}/notifications`, body, await actions.getAxiosConfig())
    .then((response) => {
      dispatch(actions.receiveData(response.data, types.SEND_NOTIFICATION));
    })
    .catch((error) => {
      if (error.response && error.response.status === 401) {
        actions.sessionTokenExpired(cookiePrefix, accessToken);
      } else {
        dispatch(actions.receiveError(error, types.SEND_NOTIFICATION));
      }
    });
}

export const uploadImage = (binaryFile, fileName) => async (dispatch) => {

  const cndUrl = await CDN_URL();
  const cookiePrefix = await COOKIE_PREFIX();
  const accessToken = cookies.get(`${cookiePrefix}_access_token`);

  let config = await actions.getAxiosConfig();
  config.headers["Content-Type"] = "multipart/form-data";

  var formData = new FormData();
  formData.append(fileName, binaryFile);
  return axios.post(`${cndUrl}/static-files/upload`, formData, config)
    .then(response => {
      return response.data[fileName];
    })
    .catch(error => {
      if (error.response && error.response.status === 401) {
        actions.sessionTokenExpired(cookiePrefix, accessToken);
      }
      return null;
    });
}