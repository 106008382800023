import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import moment from "moment";
import { Col, Row } from "react-grid-system";
import Freetext from "../../components/Freetext";
import Loader from "../../components/Loader";
import SegmentedControl from "../../components/SegmentedControl";
import { colorGradient } from "../../helpers";
import * as gatewayActions from "../../actions/gateways";
import * as API from "../../ApiTypes";
import style from "./style.module.scss";

class GatewayUptime extends Component {

  constructor(props) {
    super(props);
    this.state = {
      span: "day"
    };

    this.onTimePeriodChanged = this.onTimePeriodChanged.bind(this);

    const today = moment().startOf("day").toISOString();
    if (this.props.gateway.uptime.length === 0 || this.props.gateway.uptimeQueryHash !== `${props.id}-${this.state.span}-${today}`) {
      this.props.getGatewayUptime(props.id, this.state.span);
    }
  }

  onTimePeriodChanged(span) {
    this.setState({ span });
    this.props.getGatewayUptime(this.props.id, span);
  }

  render() {

    let description = "";
    let dateFormat = "dd DD.MM";
    if (this.state.span === "day") {
      description = "Number of samples from the gateway the last 30 days, except for today. NB! The normal amount of samples differs from gateway to gateway and how the gateway is used. Therefore you need to make an educated guess for the amount of samples that constitutes as too few or too many.";
      dateFormat = "dd DD.MM";
    }
    else if (this.state.span === "hour") {
      description = "Number of samples from the gateway the last 7 days, except for the current hour. NB! The normal amount of samples differs from gateway to gateway and how the gateway is used. Therefore you need to make an educated guess for the amount of samples that constitutes as too few or too many.";
      dateFormat = "DD.MM - HH:mm";
    }
    else if (this.state.span === "quarter") {
      description = "Number of samples from the gateway today, except for the current quarter hour. NB! The normal amount of samples differs from gateway to gateway and how the gateway is used. Therefore you need to make an educated guess for the amount of samples that constitutes as too few or too many.";
      dateFormat = "HH:mm";
    }

    // Find max uptime.count
    let max = 1;
    this.props.gateway.uptime.forEach((uptime) => {
      if (uptime.count > max) {
        max = uptime.count;
      }
    });

    // light blue
    let color1 = { red: 161, green: 202, blue: 226 };

    // medium blue
    let color2 = { red: 110, green: 170, blue: 204 };

    // dark blue
    let color3 = { red: 21, green: 114, blue: 161 };

    const uptimeElement = this.props.gateway.uptime.map((item, index) => {
      const color = colorGradient(item.count/max, color1, color2, color3, 1.0);
      const dateString = moment(item.date).format(dateFormat); 
      return (
        <div className={style.uptimeItem} key={index} style={{ "--tooltip-color": color }}>
          <div className={style.uptimeItemTitle}>{dateString}</div>
          <div className={style.uptimeItemValue}>{item.count}</div>
        </div>
      );
    });

    return (
      <>
        <div className={style.page}>
          <div className={style.scroll}>
            <div style={{ paddingTop: "20px" }} />
            <Row className={style.topRow} justify="start" nogutter>
              <div style={{ marginRight: "20px"}}>
                <Freetext 
                  header="Sample count"
                  />
              </div>
              <SegmentedControl
                name="timeperiod"
                lightBg
                value={this.state.span}
                onChange={this.onTimePeriodChanged}
                options={[
                  { label: "Today", value: "quarter" },
                  { label: "Last week", value: "hour" },
                  { label: "Last month", value: "day", default: true }
                ]}
              />
            </Row>
            <Row>
              <Col md={12} xl={10} xxl={8}>
                <Freetext 
                  content={description}
                  />
              </Col>
            </Row>
            <Row>
              <Col md={12} xl={10} xxl={8}>
                <Freetext 
                  content="Coloration: Light blue is the lowest amount of samples, dark blue is the highest amount of samples."
                  />
              </Col>
            </Row>
            <div style={{ paddingTop: "20px" }} />
            { this.props.isLoadingUptime ? <Loader inline /> : uptimeElement }
            <div style={{ paddingTop: "40px" }} />
          </div>
        </div>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    gateway: state.gateway,
    isLoadingUptime: state.loading[API.GET_GATEWAY_UPTIME],
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    getGatewayUptime: gatewayActions.getGatewayUptime,
   }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(GatewayUptime);
