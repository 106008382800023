import isEmpty from "lodash/isEmpty";
import moment from "moment";
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import { RowIcon, BLUE, GREEN, GREY, RED, ORANGE } from "../../components/RowIcon";
import SmallButton from "../../components/SmallButton";
import SearchBox from "../../components/SearchBox";
import TopRowOptions from "../../components/TopRowOptions";
import Table from "../../components/Table";
import InputModal from "../../components/InputModal";
import * as contentActions from "../../actions/content";
import * as selectedActions from "../../actions/selected";

class ContentList extends Component {

  constructor(props) {
    super(props);
    this.state = {
      searchText: undefined,
      sortBy: "title",
      sortOrder: "asc",
      offset: 0,
      limit: 20,
      highlightedId: undefined,
      showNewContentModal: false,
      newContentName: ""
    };
    this.fetchData = this.fetchData.bind(this);
    this.onRowClick = this.onRowClick.bind(this);
    this.onHover = this.onHover.bind(this);
    this.onToggle = this.onToggle.bind(this);
    this.onNewContentClicked = this.onNewContentClicked.bind(this);
    this.onNewContentNameChanged = this.onNewContentNameChanged.bind(this);
    this.openNewContent = this.openNewContent.bind(this);
  }

  componentDidMount() {
    this.fetchData();
  }

  onRowClick(column, rowInfo) {
    return {
      onClick: e => {
        // console.log(rowInfo);
        if (rowInfo && column.name !== "isSelected") {
          let link = `/companies/${this.props.match.params.companyId}/app/content/${rowInfo.original._id}/groups`;

          if (e.metaKey || e.ctrlKey) {
            window.open(`${link}`);
          }
          else {
            this.props.history.push(link);
          }
        }
      },
      onMouseOver: e => {
        if (rowInfo && rowInfo.original._id) {
          this.onHover(rowInfo.original._id);
        }
      },
      onMouseLeave: e => this.onHover(null),
      style: {
        cursor: "pointer",
        background: (
          rowInfo && rowInfo.original._id === this.state.highlightedId ? "rgba(0,0,0,0.05)" : (rowInfo && rowInfo.original._id === this.props.contentId ? "rgba(0,0,0,0.05)" : null))
      }
    }
  }

  onHover(id) {
    if (this.state.highlightedId !== id) {
      this.setState({ highlightedId: id });
    }
  }

  onToggle(row) {
    if (this.props.selectedContent[row.original._id] !== undefined && this.props.selectedContent[row.original._id]) {
      this.props.deselectContent(row.original._id);
    }
    else {
      this.props.selectContent(row.original);
    }
  }

  openNewContent() {
    this.props.history.push(`/companies/${this.props.match.params.companyId}/app/content/edit?name=${this.state.newContentName}`);
  }

  onNewContentClicked() {
    this.setState({ showNewContentModal: true });
  }

  onNewContentNameChanged(event) {
    this.setState({ newContentName: event.target.value });
  }

  fetchData() {
    const queryParams = {
      search: this.state.searchText,
      sortBy: this.state.sortBy,
      sortOrder: this.state.sortOrder,
      offset: this.state.offset,
      limit: this.state.limit
    };

    this.props.getAllContent(queryParams);
  }

  render() {
    const { isLoading } = this.props;

    const options = (
      <TopRowOptions
        searchbox={(
          <SearchBox
            value={this.state.searchText}
            onSearchChanged={(value) => this.setState({ searchText: value })}
            onSearchClick={() => this.setState({ offset: 0 }, this.fetchData)}
            onClear={() => this.setState({ searchText: "", offset: 0 }, this.fetchData)}
            inListView
          />
        )}
        buttons={[
          <SmallButton key="b1" text="New content" onClick={this.onNewContentClicked} singleLine noMargin />
        ]}
      />
    );

    const anotherTypeSelected = this.props.selectedTypes.length > 0 && !this.props.selectedTypes.includes("content");

    const tableElement = (
      <Table
        data={this.props.data.results}
        sortBy={this.state.sortBy}
        sortOrder={this.state.sortOrder}
        offset={this.state.offset}
        limit={this.state.limit}
        count={this.props.data.count}
        onSortedChange={(newSorted) => {
          this.setState({
            sortBy: newSorted[0].id,
            sortOrder: newSorted[0].desc ? "desc" : "asc"
          }, this.fetchData);
        }}
        onOffsetChange={(offset) => {
          this.setState({ offset }, this.fetchData);
        }}
        noDataText={isLoading ? "" : "No content found"}
        columns={[
          {
            id: "id",
            header: "",
            accessorKey: "_id",
            sortable: false,
            name: "isSelected",
            cell: ({ row }) => (
              <label className="checkboxContainer" htmlFor={`editCheckbox-${row.original._id}`}>
                <input
                  id={`editCheckbox-${row.original._id}`}
                  type="checkbox"
                  className="checkbox"
                  checked={this.props.selectedContent[row.original._id] !== undefined && this.props.selectedContent[row.original._id]}
                  onChange={() => this.onToggle(row)}
                  disabled={anotherTypeSelected}
                />
                <span className={anotherTypeSelected ? "disabledCheckmark" : "checkmark"} />
              </label>
            ),
            width: 60
          },
          {
            header: "Title",
            accessorKey: "title",
            cell: ({ row }) => (<span>{row.original.title}</span>)
          },
          {
            header: "Active",
            accessorKey: "usergroups",
            sortable: false,
            width: 120,
            cell: ({ row }) => {
              if (!isEmpty(row.original.usergroups) && (row.original.showInGlobalView || row.original.locations.length > 0)) {
                return <RowIcon tooltip="yes" bgColor={GREEN} icon={faCheck} styles={{ fontSize: "16px" }} />
              }
              else {
                return <RowIcon tooltip="no" bgColor={GREY} icon={faTimes} styles={{ fontSize: "16px" }}  />
              }
            }
          },
          {
            header: "Editable",
            accessorKey: "canEdit",
            sortable: false,
            width: 120,
            cell: ({ row }) => {
              if (row.original.canEdit) {
                return <RowIcon tooltip="yes" bgColor={GREEN} icon={faCheck} styles={{ fontSize: "16px" }}  />
              }
              else {
                return <RowIcon tooltip="no" bgColor={GREY} icon={faTimes} styles={{ fontSize: "16px" }}  />
              }
            }
          },
          {
            header: "Updated",
            accessorKey: "updatedAt",
            width: 200,
            cell: ({ row }) => (<span>{`${moment(row.original.updatedAt).format("HH:mm - DD/MM/YY")}`}</span>)
          },
          {
            id: "arrow",
            header: "",
            sortable: false,
            className: "pull-right",
            width: 60,
            cell: () => (<div className="arrow" />)
          }
        ]}
        getTdProps={this.onRowClick}
        className="setMaxHeigth -row-clickable -highlight"
        loading={isLoading}
      />
    );

    return (
      <div>
        { options }
        { tableElement }
        <div style={{ paddingTop: "40px" }} />

        <InputModal
          show={this.state.showNewContentModal}
          onHide={() => this.setState({ showNewContentModal: false })}
          title="New content"
          inputLabel="Name"
          inputValid={this.state.newContentName.length > 3}
          onInputChange={this.onNewContentNameChanged}
          primaryBtn={{
            text: "Create",
            onClick: this.openNewContent
          }}
          secondaryBtn={{
            text: "Cancel",
            onClick: () => this.setState({ showNewContentModal: false })
          }}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    data: state.allContent.data,
    isLoading: state.loading.allContent,
    selectedContent: state.selected.content,
    selectedCompany: state.auth.selectedCompany,
    selectedTypes: state.selected.allTypes,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    getAllContent: contentActions.getAllContent,
    selectContent: selectedActions.selectContent,
    deselectContent: selectedActions.deselectContent,
    clearSelection: selectedActions.clearSelection
   }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(ContentList);
