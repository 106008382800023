import React, { Component } from "react";
import { Container, Col, Row } from "react-grid-system";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { get, isEmpty } from "lodash";
import Freetext from "../../components/Freetext";
import Loader from "../../components/Loader";
import InputBox from "../../components/InputBox";
import OptionFooter from "../../components/OptionFooter";
import * as authActions from "../../actions/auth";
import style from "./style.module.scss";
import styled from "styled-components";

class Company extends Component {

  constructor(props) {
    // console.log(props);
    super(props);
    this.state = {
      name: "",
      mazemapCampusTag: "",
      mazemapApiKey: "",
      yanziAccountIds: "",
      hasLoadedState: false
    };

    this.onBackClick = this.onBackClick.bind(this);
    this.onNameChange = this.onNameChange.bind(this);
    this.onMazemapCampusTagChanged = this.onMazemapCampusTagChanged.bind(this);
    this.onMazemapApiKeyChanged = this.onMazemapApiKeyChanged.bind(this);
    this.onYanziAccountIDsChange = this.onYanziAccountIDsChange.bind(this);
    this.onSave = this.onSave.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.getOptionFooter = this.getOptionFooter.bind(this);
  }

  onBackClick() {
    this.props.history.push(`/companies/${this.props.match.params.companyId}/home`);
  }

  onNameChange(event) {
    this.setState({ name: event.target.value });
  }

  onMazemapCampusTagChanged(event) {
    this.setState({ mazemapCampusTag: event.target.value });
  }

  onMazemapApiKeyChanged(event) {
    this.setState({ mazemapApiKey: event.target.value });
  }

  onYanziAccountIDsChange(event) {
    this.setState({ yanziAccountIds: event.target.value });
  }

  onSave() {
    const { push } = this.props.history;

    const yanziAccountIds = this.state.yanziAccountIds.split(",");

    const body = { 
      name: this.state.name
    };

    const firstYanziAccountId = get(yanziAccountIds, "[0]", null);
    if (!isEmpty(firstYanziAccountId)) {
      body.yanziAccountIds = yanziAccountIds;
    }

    if (!isEmpty(this.state.mazemapCampusTag)) {
      body.mazemapCampusTag = this.state.mazemapCampusTag;
    }

    if (!isEmpty(this.state.mazemapApiKey)) {
      body.mazemapApiKey = this.state.mazemapApiKey;
    }

    this.props.createCompany(body, push);
  }

  onCancel() {
    this.setState({
      name: this.props.company.name || "",
      mazemapCampusTag: this.props.company.mazemapCampusTag || "",
      mazemapApiKey: this.props.company.mazemapApiKey || "",
      yanziAccountIds: this.props.company.yanziAccountIds || "",
    });
  }

  getOptionFooter() {
    if (this.state.name.length > 0 &&
      (this.state.name !== this.props.company.name || 
        this.state.mazemapCampusTag !== this.props.company.mazemapCampusTag || 
        this.state.mazemapApiKey !== this.props.company.mazemapApiKey || 
        this.state.yanziAccountIds !== this.props.company.yanziAccountIds)) {
      return (<OptionFooter cancel={this.onCancel} options={[{ label: "Save", callback: this.onSave }]} />);
    }

    return null;
  }

  render() {
    // const { isLoading } = this.props;

    // console.log("this.state", this.state);

    if (this.props.isLoading) {
      return <Loader fullScreen />;
    }

    let headerRow = (
      <Row className={style.topRow}>
        <Col md={12}>
          <div>
            <Freetext header="New company" />
          </div>
        </Col>
      </Row>
    );
    
    return (
      <div className={style.outerScroll}>
        <Container className={style.container}>
          { headerRow }
          <Row>
            <Col xs={12} md={5}>
              <InputBox onChange={this.onNameChange} label="Name" valid={this.state.name} value={this.state.name} required showValidIcon />
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={3}>
              <InputBox onChange={this.onMazemapCampusTagChanged} label="MazeMap campus tag" value={this.state.mazemapCampusTag} />
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={10}>
              <InputBox onChange={this.onMazemapApiKeyChanged} label="MazeMap API key" value={this.state.mazemapApiKey} />
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={5}>
              <InputBox onChange={this.onYanziAccountIDsChange} label="Yanzi account IDs" value={this.state.yanziAccountIds} />
            </Col>
          </Row>
          <div style={{ paddingTop: "80px" }} />

          { this.getOptionFooter() }
        </Container>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
    company: state.sudo.company,
    isLoading: state.loading.sudo
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ 
    createCompany: authActions.createCompany
  }, dispatch);
}

export default connect(mapStateToProps,mapDispatchToProps)(Company);