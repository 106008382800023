import React, { Component } from "react";
import PropTypes from 'prop-types';
import style from "./style.module.scss";

class SelectionFooterCell extends Component {

  render() {
    let className;
    switch (this.props.color) {
      case "green": {
        className = style.green;
        break;
      }
      case "purple": {
        className = style.purple;
        break;
      }
      case "orange": {
        className = style.orange;
        break;
      }
      case "red": {
        className = style.red;
        break;
      }
      case "teal": {
        className = style.teal;
        break;
      }
      default: {
        className = style.blue;
      }
    }
    
    return (
      <div className={style.frame}>
        <div className={style.buttonContainer}>
          <button className={className} title={this.props.hoverText} type={this.props.type} disabled={this.props.disabled} onKeyUp={this.props.onClick} >
              <span>{this.props.text}</span>
          </button>
          <div className={style.closeContainer} onClick={this.props.onClick}>
            <div className={style.closeIcon} />
          </div>
        </div>
      </div>
    );
  }
}

SelectionFooterCell.propTypes = {
  id: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  hoverText: PropTypes.string,
  type: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  color: PropTypes.string
};

SelectionFooterCell.defaultProps = {
  type: "button",
  onClick: () => {},
  disabled: false,
  color: "blue"
};

export default SelectionFooterCell;