import get from "lodash/get";
import { toast } from "react-toastify";
import * as types from "../ApiTypes";

const initialState = {
  uptime: [],
  sumUptime: null
};

export default function uptimeReducer(state = initialState, action) {

  switch (action.type) {

    case types.REQ_DATA: {
      if (action.fetchType === types.GET_SYSTEM_UPTIME) {
        return {...state, uptime: [], sumUptime: null };
      }
      
      return state;
    }

    case types.RECV_DATA: {
      if (action.fetchType === types.GET_SYSTEM_UPTIME) {
        const uptime = action.payload;

        let sumUptime = null;
        if (uptime.length > 0) {
          sumUptime = Math.floor(100*uptime.reduce((sum, item) => sum + item.uptime, 0)/uptime.length)/100;
        }

        return {...state, uptime, sumUptime };
      }
      
      return state;
    }

    case types.RECV_ERROR: {

      const statusCode = get(action, "payload.response.status", "Error");

      if (action.fetchType === types.GET_SYSTEM_UPTIME) {
        toast.error(`${statusCode}: Could not get system uptime`);
        return {...state, uptime: [], sumUptime: null };
      }
      
      return state;
    }

    case types.CLEAR_DATA: {
      return initialState;
    }

    default: {
      return state;
    }
  }
}
