import React from "react";
import { Switch, Route } from "react-router-dom";
import ReportContainer from "./container";
import NotFound from "../NotFound";

const ReportRouter = () => (
  <Switch>
    <Route path="/companies/:companyId/reports" children={(props) => <ReportContainer {...props} />} />
    <Route component={NotFound} />
  </Switch>
);

export default ReportRouter;