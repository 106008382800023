import React from "react";
import styled from "styled-components";

interface RowData {
  [key: string]: any;
}

interface ColumnSizes {
  [columnName: string]: number; // Assuming sizes are numbers (e.g., percentages)
}

interface FlexTableProps {
  data: RowData[];
  columnSizes: ColumnSizes;
  style?: React.CSSProperties;
}

const FlexTable: React.FC<FlexTableProps> = ({ data, columnSizes, style }) => {

  // Assuming all objects have the same properties, use the first item to determine columns
  const columns = data.length > 0 ? Object.keys(data[0]) : [];

  return (
    <Container style={style}>
      <Header>
        {columns.map((columnName, index) => (
          <Cell key={index} width={columnSizes[columnName]}>{columnName}</Cell>
        ))}
      </Header>
      {data.map((item: { [key: string]: any }, rowIndex) => (
        <Row key={rowIndex}>
          {columns.map((columnName, columnIndex) => {
            return (
              <Cell key={columnIndex} width={columnSizes[columnName]}>{item[columnName]}</Cell>
            );
          })}
        </Row>
      ))}
    </Container>
  );
};

export default FlexTable;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  border: 1px solid #dbdbdb;
  border-radius: 5px;
  overflow: hidden;
  margin-top: 0.8em;
`;

const Header = styled.div`
  display: flex;
  overflow: hidden;
  border-bottom: 1px solid #dbdbdb;
  font-weight: 600;
  color: #111;
`;

const Row = styled.div`
  display: flex;
  overflow: hidden;
  
  &:not(:last-child) {
    border-bottom: 1px solid #dbdbdb;
  }
`;

const Cell = styled.div<{ width: number }>`
  flex: ${({ width }) => `0 0 ${width}%`};
  max-width: ${({ width }) => `${width}%`}; // Ensure the cell does not grow beyond this width

  background-color: white;
  
  padding: 8px;
  box-sizing: border-box;
  min-height: 30px;
  white-space: normal;
  overflow-wrap: break-word;
  overflow: hidden;
  text-overflow: ellipsis;

  /* Apply right border to all but last cell in a row */
  &:not(:last-child) {
    border-right: 1px solid #dbdbdb;
  }
`;