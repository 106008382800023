import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { isEmpty } from "lodash";
import moment from "moment";
import SearchBox from "../../components/SearchBox";
import Table from "../../components/Table";
import TopRowOptions from "../../components/TopRowOptions";
import StatusLabel from "../../components/StatusLabel";
import * as userActions from "../../actions/users";
import * as selectedActions from "../../actions/selected";

class InviteList extends Component {

  constructor(props) {
    super(props);
    this.state = {
      searchText: undefined,
      sortBy: "email",
      sortOrder: "asc",
      offset: 0,
      limit: 20,
      highlightedId: undefined
    };
    this.fetchData = this.fetchData.bind(this);
    this.onSearchClick = this.onSearchClick.bind(this);
    this.onSearchChange = this.onSearchChange.bind(this);
    this.onRowClick = this.onRowClick.bind(this);
    this.onToggle = this.onToggle.bind(this);
  }

  componentDidMount() {
    this.fetchData();
  }

  onSearchClick() {
    this.setState({ offset: 0 }, this.fetchData);
  }

  onSearchChange(event) {
    this.setState({ searchText: event.target.value === '' ? undefined : event.target.value });
  }

  onRowClick(column, rowInfo) {
    return {
      onClick: e => {
        if (rowInfo && column.name !== "isSelected") {

          let link = `/companies/${this.props.match.params.companyId}/org/invites/${rowInfo.original.id}/info`;

          if (e.metaKey || e.ctrlKey) {
            window.open(`${link}`);
          }
          else {
            this.props.history.push(link);
          }
        }
      },
      onMouseOver: e => {
        if (rowInfo && (rowInfo.original.id)) {
          this.onHover(rowInfo.original.id);
        }
      },
      onMouseLeave: e => this.onHover(null),
      style: {
        cursor: "pointer",
        background: (
          rowInfo && (
            (rowInfo.original.id != null && rowInfo.original.id === this.state.highlightedId) 
          ) ? "rgba(0,0,0,0.05)" : (rowInfo && (
            (rowInfo.original.id != null && rowInfo.original.id === this.props.inviteId)
            ) ? "rgba(0,0,0,0.05)" : null))
      }
    }
  }

  onHover(id) {
    if (this.state.highlightedId !== id) {
      this.setState({ highlightedId: id });
    }
  }

  onToggle(row) {
    if (this.props.selectedInvites[row.original.id] !== undefined && this.props.selectedInvites[row.original.id]) {
      this.props.deselectInvite(row.original.id);
    }
    else {
      this.props.selectInvite(row.original);
    }
  }

  fetchData() {
    const queryParams = {
      search: this.state.searchText,
      sortBy: this.state.sortBy,
      sortOrder: this.state.sortOrder,
      offset: this.state.offset,
      limit: this.state.limit
    };

    this.props.getInvites(queryParams);
  }

  render() {
    const { isLoading } = this.props;
    // console.log("InviteList.render()", this.props);
    
    if (!this.props.auth.hasITAdminRole) {
      return null;
    }

    const options = (
      <TopRowOptions
        searchbox={(
          <SearchBox
            value={this.state.searchText}
            onSearchChanged={(value) => { this.setState({ searchText: value }) }}
            onClear={() => { this.setState({ searchText: "" }, this.onSearchClick) }}
            onSearchClick={this.onSearchClick}
            inListView
          />
        )}
      />
    );

    const tableElement = (
      <Table
        data={this.props.invites.data}
        sortBy={this.state.sortBy}
        sortOrder={this.state.sortOrder}
        offset={this.state.offset}
        limit={this.state.limit}
        count={this.props.invites.count}
        onSortedChange={(newSorted) => {
          this.setState({
            sortBy: newSorted[0].id,
            sortOrder: newSorted[0].desc ? "desc" : "asc"
          }, this.fetchData);
        }}
        onOffsetChanged={(offset) => { this.setState({ offset }, this.fetchData) }}
        noDataText={isLoading ? "" : "No invites found"}
        columns={[
          // {
          //   header: "",
          //   accessorKey: "id",
          //   sortable: false,
          //   name: "isSelected",
          //   cell: ({ row }) => (
          //     <label className="checkboxContainer" htmlFor={`editCheckbox-${row.original.id}`}>
          //       <input
          //         id={`editCheckbox-${row.original.id}`}
          //         type="checkbox"
          //         className="checkbox"
          //         checked={
          //           this.props.selectedInvites[row.original.id] !== undefined && this.props.selectedInvites[row.original.id]
          //         }
          //         onChange={() => this.onToggle(row)}
          //         disabled={anotherTypeSelected}
          //       />
          //       <span className={anotherTypeSelected ? "disabledCheckmark" : "checkmark"} />
          //     </label>
          //   ),
          //   width: 60
          // },
          {
            header: "Email",
            accessorKey: "email"
          },
          {
            header: "Valid until",
            accessorKey: "validUntil",
            cell: ({ row }) => (<div>{row.original.validUntil ? moment(row.original.validUntil).format("DD/MM/YYYY HH:mm") : null}</div>)
          },
          // {
          //   header: "Status",
          //   accessorKey: "id",
          //   width: 120,
          //   cell: ({ row }) => (<div>{row.original.id ? (<StatusLabel text={"Invite sent"} color={"blue"} inRow />) : null}</div>)
          // },
          {
            id: "arrow",
            header: "",
            sortable: false,
            className: "pull-right",
            width: 60,
            cell: ({ row }) => (<div><div className="arrow" /></div>)
          }
        ]}
        getTdProps={this.onRowClick}
        className="setMaxHeigth -row-clickable"
        loading={isLoading}
      />
    );

    return (
      <>
        <div>
          { options }
          { tableElement }
          <div style={{ paddingTop: "40px" }} />
        </div>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    invites: state.invites,
    auth: state.auth,
    isLoading: state.loading.invites,
    selectedInvites: state.selected.invites,
    selectedCompany: state.auth.selectedCompany,
    selectedTypes: state.selected.allTypes,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ 
    getInvites: userActions.getInvites,
    selectInvite: selectedActions.selectInvite,
    deselectInvite: selectedActions.deselectInvite,
    clearSelection: selectedActions.clearSelection
   }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(InviteList);
