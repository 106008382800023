import React, { Component } from "react";
import { Hidden, Visible } from "react-grid-system";
import { Switch, Route, Redirect, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Loader from "../../components/Loader";
import NotFound from "../NotFound";
import SubNavigation from "../../components/SubNavigation";
import LargeHeader from "../../components/LargeHeader";
import Content from "./content";
import style from "./style.module.scss";

class AppContainer extends Component {

  render() {
    if (this.props.isAuthLoading) {
      return <Loader fullScreen />;
    }

    const subNavigationLinks = [];
    subNavigationLinks.push({ pathname: `/companies/${this.props.match.params.companyId}/app/content`, label: `Content` });

    return (
      <div className={style.page}>
        <LargeHeader title="App" />
        <SubNavigation links={subNavigationLinks} /> 
        <Switch>
          <Redirect exact from="/companies/:companyId/app" to={{ pathname: `/companies/${this.props.match.params.companyId}/app/content` }} />
          <Route path="/companies/:companyId/app/content/:contentId?" children={(props) => <Content {...props} contentId={props.match.params.contentId} />} />
          <Route render={NotFound} />
        </Switch>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return { 
    auth: state.auth,
    selectedCompany: state.auth.selectedCompany,
    isAuthLoading: state.loading.auth
  };
}

export default withRouter(connect(mapStateToProps, null)(AppContainer));
