import React, { Component } from "react";
import { Icon, DARKRED } from "../Icon";
import { Info } from "ui";
import { faCalendar } from "@fortawesome/free-regular-svg-icons";
import styled from "styled-components";

class DateInputBox extends Component {
  // label, placeholder, value, onChange, onClick, errorMessage, valid, disabled, style

  render() {
    const { label, placeholder, value, onChange, onClick, help, info, valid, required, disabled, showValidInfo, style, ...rest } = this.props;
    return (
      <InputGroup style={{ paddingTop: label ? "20px" : 0, paddingBottom: info ? "20px" : 0, ...style }}>
        { label && <Label>{label}{ required && <span style={{ color: DARKRED }}>*</span> }</Label> }
        { help && <Info text={help} /> }
        <InputWrapper onClick={onClick}>
          <Input
            placeholder={placeholder}
            value={value}
            $hasOnClick={onClick ? true : false}
            onChange={onChange}
            readOnly={!onChange}
            disabled={disabled}
            required={required}
            $requiredColor={DARKRED}
            $isValid={valid}
            {...rest}
          />
          <Icon color={!valid ? DARKRED : "#333"} icon={faCalendar} styles={{ fontSize: "22px", bottom: "10px", right: "8px" }} classic />
        </InputWrapper>

        {info && <Sub className={style.info} style={showValidInfo && !valid ? { color: DARKRED } : {}}>{info}</Sub>}
      </InputGroup>
    );
  }
}

export default DateInputBox;

const InputGroup = styled.div`
  padding-top: 20px;
  padding-bottom: 20px;
  position: relative;
  text-align: left;
`;

const Label = styled.label`
  font-family: "Source Sans Pro";
  font-size: 14px;
  font-weight: 600;
  color: #000;
`;

const InputWrapper = styled.div`
  position: relative;
  cursor: ${props => props.onClick ? "pointer" : "auto"};
`;

const Input = styled.input`
  border-width: 0;
  outline-color: #D8D8D8;
  outline-width: 1px;
  outline-style: solid;
  box-sizing: border-box;
  border-radius: 5px;
  background-color: white;
  margin-top: 5px;
  margin-bottom: 1px;
  width: 100%;
  font-size: 17px;
  font-weight: 400;
  line-height: 1.6;
  padding: 6px 12px;
  color: #444;

  &:disabled {
    // background: none;
    pointer-events: none;
  }

  outline-color: ${props => props.required && !props.$isValid && props.$requiredColor};
  cursor: ${props => props.$hasOnClick ? "pointer" : "auto"};
`;

const Sub = styled.div`
  font-size: 14px;
  color: #6e777d;
  float: right;
  padding-right: 4px;
`;