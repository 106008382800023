import React, { Component } from "react";
import { Icon, DARKRED } from "../Icon";
import { Info } from "ui";
import { faLocationDot } from "@fortawesome/free-solid-svg-icons";
import styled from "styled-components";

class ButtonInputBox extends Component {
  // label, placeholder, value, onChange, errorMessage, valid, required, disabled, style

  render() {
    const { label, placeholder, value, onChange, onClick, help, info, valid, required, disabled, showValidInfo, ...rest } = this.props;
    return (
      <InputGroup style={{ paddingTop: label ? "20px" : 0, paddingBottom: info ? "20px" : 0, ...this.props.style}}>
        { label && <Label>{label}{ required &&  <span style={{ color: DARKRED }}>*</span>}</Label> }
        { help && <Info text={help} />}
        <InputWrapper onClick={onClick}>
          <Input
            placeholder={placeholder}
            value={value}
            $hasOnClick={onClick ? true : false}
            onChange={onChange}
            onClick={onClick}
            disabled={true}
            required={required}
            $requiredColor={DARKRED}
            $isValid={valid}
            {...rest}
          />
          <Icon color={!this.props.valid ? DARKRED : "#333"} icon={faLocationDot} styles={{ fontSize: "22px", bottom: "10px", right: "8px" }} classic />
        </InputWrapper>

        {info && <Sub style={showValidInfo && !valid ? { color: DARKRED } : {}}>{info}</Sub>}
      </InputGroup>
    );
  }
}

export default ButtonInputBox;

const InputGroup = styled.div`
  padding-top: 20px;
  padding-bottom: 20px;
  position: relative;
  text-align: left;
`;

const Label = styled.label`
  font-family: "Source Sans Pro";
  font-size: 14px;
  font-weight: 600;
  color: #000;
`;

const InputWrapper = styled.div`
  position: relative;
  cursor: ${props => props.onClick ? "pointer" : "auto"};
`;

const Input = styled.input`
  border-width: 0;
  outline-color: #D8D8D8;
  outline-width: 1px;
  outline-style: solid;
  box-sizing: border-box;
  border-radius: 5px;
  background-color: white;
  margin-top: 5px;
  margin-bottom: 1px;
  width: 100%;
  font-size: 17px;
  font-weight: 400;
  line-height: 1.6;
  padding: 6px 12px;
  padding-right: 34px;
  color: #444;

  &:disabled {
    // background: none;
    pointer-events: none;
  }

  outline-color: ${props => props.required && !props.$isValid ? props.$requiredColor : "none"};
  cursor: ${props => props.$hasOnClick ? "pointer" : "auto"};
`;

const Sub = styled.div`
  font-size: 14px;
  color: #6e777d;
  float: right;
  padding-right: 4px;
`;

  // input::placeholder,
  // [required]::-webkit-input-placeholder {
  //   color: #6b7073; //#c12d2a; //
  // }