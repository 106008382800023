import React, { Component } from "react";
import { Container, Col, Row } from "react-grid-system";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import get from "lodash/get";
import Freetext from "../../components/Freetext";
import InputGroup from "../../components/InputGroup";
import OptionFooter from "../../components/OptionFooter";
import Table from "../../components/Table";
import * as locationTagActions from "../../actions/locationTags";
import * as selectedActions from "../../actions/selected";
import style from "./style.module.scss";

class LocationTag extends Component {

  constructor(props) {
    super(props);
    this.state = {
      id: props.match.params.id,
      name: "",
      description: "",
      locations: [],
      hasLoadedState: false
    };

    this.onBackClick = this.onBackClick.bind(this);
    this.onNameChange = this.onNameChange.bind(this);
    this.onDescriptionChange = this.onDescriptionChange.bind(this);
    this.onSave = this.onSave.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.onToggle = this.onToggle.bind(this);
  }

  static getDerivedStateFromProps(nextProps, prevState) {

    if (prevState.hasLoadedState) {
      return null;
    }

    // Only copy props if they exist and if location id is correct
    // if (!isEmpty(nextProps.selectedLocations)) {
      if (nextProps.match.params.id === undefined) {
  
        const newSelectedLocations = Object.keys(nextProps.selectedLocations).map((locationId) => ({ ...nextProps.selectedLocations[locationId], selected: true }));
  
        const newState = {
          ...prevState,
          name: "",
          description: "",
          locations: newSelectedLocations,
          hasLoadedState: true
        };
        
        return newState;
      }
      else if (nextProps.tag._id === nextProps.match.params.id) {
  
        const newSelectedLocations = Object.keys(nextProps.selectedLocations).map((locationId) => ({ ...nextProps.selectedLocations[locationId], selected: false }));
        const existingLocations = nextProps.tag.locations.map((location) => ({ ...location, selected: true }));
  
        const newState = {
          ...prevState,
          name: nextProps.tag.name,
          description: nextProps.tag.description,
          locations: existingLocations.concat(newSelectedLocations),
          hasLoadedState: true
        };
        
        return newState;
      }
    // }

    return null;
  }

  componentDidMount() {
    if (this.props.tag._id !== this.props.match.params.id) {
      this.props.getLocationTag(this.props.match.params.id);
    }
  }

  onBackClick() {
    this.props.history.push(`/companies/${this.props.match.params.companyId}/locations/root/location-tags`);
  }

  onNameChange(event) {
    this.setState({ name: event.target.value });
  }

  onDescriptionChange(event) {
    this.setState({ description: event.target.value });
  }

  onToggle(row) {
    this.setState((prevState) => { 
      const location = prevState.locations.find((location) => (location._id === row._id));
      if (location) {
        if (location.selected === undefined || location.selected) {
          location.selected = false;
        }
        else {
          location.selected = true;
        }
      }

      return prevState;
    });
  }

  onSave() {
    const { push } = this.props.history;

    const locations = this.state.locations.filter((location) => (location.selected));
    const locationIds = locations.map((location) => (location._id));

    if (this.state.id) {
      const body = {
        name: this.state.name,
        description: this.state.description,
        locationIds
       };
  
      this.props.updateLocationTag(this.state.id, body, push);
    }
    else {
      const body = {
        name: this.state.name,
        description: this.state.description,
        locationIds
       };
  
      this.props.createLocationTag(body, push);
    }

    
  }

  onCancel() {
    this.onBackClick();
  }

  render() {
    let headerRow = (
        <Row className={style.topRow}>
          <Col md={9}>
            <div>
              <Freetext header={this.state.id ? "Edit tag" : "New tag" } content="" />
            </div>
          </Col>
        </Row>
      );

    const locationsTable = (
      <Table
        data={this.state.locations}
        columns={[
          {
            id: "id",
            header: "",
            accessorKey: "_id",
            sortable: false,
            name: "isSelected",
            cell: ({ row }) => (
              <label className="checkboxContainer" htmlFor={`editCheckbox-${row._id}`}>
                <input
                  id={`editCheckbox-${row._id}`}
                  type="checkbox"
                  className="checkbox"
                  checked={get(row.original, "selected", true)}
                  onChange={() => this.onToggle(row)}
                  disabled={false}
                />
                <span className="checkmark" />
              </label>
            ),
            width: 60
          },
          { 
            header: "Name", 
            accessorKey: "name", 
          }
          ]}
        className="setMaxHeigth"
      />
    );

    const selectedLocations = this.state.locations.filter((location => location.selected));

    return (
      <div className={style.outerEditScroll}>
        <Container className={style.editContainer}>
          { headerRow }
          <Row>
            <Col sm={12} md={8} lg={8}>
              <InputGroup autoFocus onChange={this.onNameChange} label="Name" placeholder="Tag name" valid={this.state.name} value={this.state.name} required />
            </Col>
          </Row>
          <Row>
            <Col sm={12} md={8} lg={8}>
              <InputGroup onChange={this.onDescriptionChange} label="Description" placeholder="Tag description" valid={this.state.description} value={this.state.description} />
            </Col>
          </Row>
          <div style={{ paddingTop: "40px" }} />
          <Row>
            <Col sm={12} md={12} lg={12}>
              { locationsTable }
            </Col>
          </Row>
          <div style={{ paddingTop: "80px" }} />
        </Container>
        <OptionFooter 
          cancel={this.onCancel} 
          cancelButtonLabel="Cancel"
          options={[
            { 
              label: "Save", 
              callback: this.onSave, 
              disabled: this.state.name.length === 0 || selectedLocations.length < 2
            }
          ]} 
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    tag: state.locationTags.tag,
    selectedLocations: state.selected.locations
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ 
    getLocationTag: locationTagActions.getLocationTag,
    createLocationTag: locationTagActions.createLocationTag,
    updateLocationTag: locationTagActions.updateLocationTag,
    selectLocationTag: selectedActions.selectLocationTag,
    deselectLocationTag: selectedActions.deselectLocationTag
  }, dispatch);
}

export default connect(mapStateToProps,mapDispatchToProps)(LocationTag);
