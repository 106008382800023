import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { omitBy, isNil } from "lodash";
import moment from "moment";
import * as sensorActions from "../../../../actions/sensors";
import Freetext from "../../../../components/Freetext";
import { CheckboxTree } from "../../../../components/CheckboxTree";
import { MainContainer } from "./MainContainer";
import { FilterActionFooter } from "./FilterActionFooter";
import { SensorTable } from "./SensorTable";
import { Filters } from "./FilterSection";
import styles from "./styles.module.scss";

class Sensors extends Component {
  constructor(props) {
    super(props);

    const urlParametersToFilterState = this._readUrlParametersAndSetFilters();
    const state = {
      locations: [],
      filters: {
        fromDate: undefined,
        toDate: undefined,
        noSamplesSinceDate: undefined,
        sensorTableSearchValue: "",
        sortOrder: "desc",
        sortBy: "createdAt",
        offset: 0,
        batteryLevel: undefined,
        signalStrength: undefined,
        selectedLocations: [],
        deselectedLocations: [],
        unassignedSensors: undefined
      },
      haveFiltersBeenUpdated: false,
      successfullyFetchedTheLocationHierarchy: false,
      resetFilters: false
    };

    this.state = {
      ...state,
      filters: {
        ...state.filters,
        ...urlParametersToFilterState
      }
    }

    this._fetchSensors = this._fetchSensors.bind(this);
    this._handleKeyDown = this._handleKeyDown.bind(this);
    this._updateUrlParametersWithFilters = this._updateUrlParametersWithFilters.bind(this);
    this._readUrlParametersAndSetFilters = this._readUrlParametersAndSetFilters.bind(this);
    this.handleUpdatesToFilter = this.handleUpdatesToFilter.bind(this);
    this.handleSearchClear = this.handleSearchClear.bind(this);
    this.resetFiltersToDefault = this.resetFiltersToDefault.bind(this);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if(prevState.successfullyFetchedTheLocationHierarchy) {
      return null;
    } 
   
    if(prevState.locations !== nextProps.locationHierarchy && nextProps.locationHierarchy !== undefined) {
      if(nextProps.locationHierarchy.length === 0) {
        return {successfullyFetchedTheLocationHierarchy: false}
      }

      return {
        locations: nextProps.locationHierarchy,
        successfullyFetchedTheLocationHierarchy: true
      }
    }
    else {
      return null;
    }
  }

  _readUrlParametersAndSetFilters() {
    let queryParams = new URLSearchParams(this.props.location.search);
    let objectToBeWrittenToState = {};

    if(this.props.location.search.length === 0) {}
    else {
      for(const [key, value] of queryParams) {
        switch(key) {
          case "from": 
            if(moment(value, moment.ISO_8601, true).isValid()) {
              Object.assign(objectToBeWrittenToState, {
                fromDate: value
              });
            }
            break;
          
          case "to":
            if(moment(value, moment.ISO_8601, true).isValid()) {
              Object.assign(objectToBeWrittenToState, {
                toDate: value
              });
            }
            break;

          case "previousMessageAt":
            if(moment(value, moment.ISO_8601, true).isValid()) {
              Object.assign(objectToBeWrittenToState, {
                noSamplesSinceDate: value
              });
            }
            break;

          case "search":
            Object.assign(objectToBeWrittenToState, {
              sensorTableSearchValue: value.toString()
            });
            break;

          case "sortOrder":
            if(value.toLowerCase() === "asc" || value.toLowerCase() === "desc") {
              Object.assign(objectToBeWrittenToState, {
                sortOrder: value
              });
            }
            break;

          case "orderBy":
            Object.assign(objectToBeWrittenToState, {
              sortBy: value
            });
            break;

          
          case "offset":
            if(parseInt(value)) {
              Object.assign(objectToBeWrittenToState, {
                offset: value
              });
            }
            break;

          case "batteryLevel":
            if(parseInt(value)) {
              Object.assign(objectToBeWrittenToState, {
                batteryLevel: parseInt(value)
              });
            }
            break;

          case "signalStrength":
            if(parseInt(value)) {
              Object.assign(objectToBeWrittenToState, {
                signalStrength: parseInt(value)
              });
            }
            break;

          case "selectedLocations":
            try {
              const array = JSON.parse(value);
              Object.assign(objectToBeWrittenToState, {
                selectedLocations: array
              });
            }
            catch(error) {
              Object.assign(objectToBeWrittenToState, {
                selectedLocations: []
              });
            }
            break;

          case "deselectedLocations":
            try {
              const array = JSON.parse(value);
              Object.assign(objectToBeWrittenToState, {
                deselectedLocations: array
              });
            }
            catch(error) {
              Object.assign(objectToBeWrittenToState, {
                deselectedLocations: []
              });
            }
            break;

          
          case "unassignedSensors":
            Object.assign(objectToBeWrittenToState, {
              unassignedSensors: value === "true" ? true : false
            });
            break;

          default:
            console.error("This parameter key is not supported.");
            break;
        }
      }
    }

    return objectToBeWrittenToState;
  }

  _updateUrlParametersWithFilters(prevProps, prevState) {
    const { filters } = this.state;
    if(prevState.filters === filters) {
      return;
    }

    const { filters: {
      fromDate, 
      toDate, 
      noSamplesSinceDate, 
      sensorTableSearchValue, 
      sortOrder, 
      sortBy, 
      offset, 
      batteryLevel, 
      signalStrength,
      selectedLocations, 
      deselectedLocations,
      unassignedSensors
    }} = this.state;

    const validQueryParameters = omitBy(Object.assign({}, {
      from: fromDate,
      to: toDate,
      previousMessageAt: noSamplesSinceDate,
      search: sensorTableSearchValue.length > 0 ? sensorTableSearchValue : undefined,
      sortOrder: sortOrder !== "desc" ? sortOrder : undefined,
      sortBy: sortBy !== "createdAt" ? sortBy : undefined,
      offset: offset !== 0 ? offset : undefined,
      batteryLevel,
      signalStrength,
      selectedLocations: selectedLocations.length > 0 ? JSON.stringify(selectedLocations) : undefined,
      deselectedLocations: deselectedLocations.length > 0 ? JSON.stringify(deselectedLocations) : undefined,
      unassignedSensors
    }), isNil);

    let queryString = "?";

    for(const [index, [key, value]] of Object.entries(validQueryParameters).entries()) {
      if(index === 0) {
        queryString = queryString + `${key}=${value}`
      } 
      else {
        queryString = queryString + `&${key}=${value}`
      }
    }

    this.props.history.push({ 
      pathname: this.props.history.location.pathname.substr(0, this.props.history.location.pathname.length),
      search: queryString
    })
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      filters: {
        sortOrder,
        sortBy,
        offset,
        selectedLocations,
        deselectedLocations,
        unassignedSensors
      },
      filters,
      resetFilters
    } = this.state;

    let queryParams = new URLSearchParams(this.props.location.search).toString();

    if(
      queryParams.length === 0 
      && 
      (
        this.state.filters.selectedLocations.length > 0
        || this.state.filters.deselectedLocations.length > 0
        || this.state.filters.batteryLevel !== undefined
        || this.state.filters.sortOrder !== "desc"
        || this.state.filters.toDate !== undefined
        || this.state.filters.fromDate !== undefined
        || this.state.filters.noSamplesSinceDate !== undefined
        || this.state.filters.sensorTableSearchValue !== ""
        || this.state.filters.sortBy !== "createdAt"
        || this.state.filters.offset !== 0
        || this.state.filters.signalStrength !== undefined
        || this.state.filters.unassignedSensors !== undefined
      )
      && JSON.stringify(this.state.filters) === JSON.stringify(prevState.filters) ) {
     
      window.location.reload();
      return;
    } 

    if(
      prevState.filters.sortOrder !== sortOrder ||
      prevState.filters.sortBy !== sortBy ||
      (prevState.filters !== filters && (prevState.filters.offset !== offset))
    ) {
      this._fetchSensors();
    }
    else if(
      prevState.filters.selectedLocations !== selectedLocations ||
      prevState.filters.deselectedLocations !== deselectedLocations ||
      prevState.filters.unassignedSensors !== unassignedSensors
    ) {
      this.setState(prevState => ({
        filters: {
          ...prevState.filters,
          offset: 0
        }
      }));
      this._fetchSensors();
    }
    
    if(resetFilters) {
      this.setState({ resetFilters: false });
      this._fetchSensors();
    }

    this._updateUrlParametersWithFilters(prevProps, prevState);
  }

  _handleKeyDown = (event) => {
    const { haveFiltersBeenUpdated } = this.state;
    if(event.keyCode === 13 && haveFiltersBeenUpdated) {
      this._fetchSensors();
    }
  };

  componentDidMount() {
    this._fetchSensors();

    document.addEventListener("keydown", this._handleKeyDown);
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this._handleKeyDown);
  }

  handleUpdatesToFilter(filters) {
    // console.log("handleUpdatesToFilter", filters);
    this.setState(prevState => ({
      filters: {
        ...prevState.filters,
        ...filters
      },
      haveFiltersBeenUpdated: true
    }));
  }

  handleSearchClear() {
    // Clear search state and refetch data
    this.setState(prevState => ({
      filters: {
        ...prevState.filters,
        sensorTableSearchValue: "",
      }
    }), this._fetchSensors);
  }

  resetFiltersToDefault() {
    let selectedLocations = new URLSearchParams(this.props.location.search).get("selectedLocations");
    let deselectedLocations = new URLSearchParams(this.props.location.search).get("deselectedLocations");
    
    this.setState({
      filters: {
        fromDate: undefined,
        toDate: undefined,
        noSamplesSinceDate: undefined,
        sensorTableSearchValue: "",
        sortOrder: "desc",
        sortBy: "createdAt",
        offset: 0,
        batteryLevel: undefined,
        signalStrength: undefined,
        selectedLocations: selectedLocations ? JSON.parse(selectedLocations) : [],
        deselectedLocations: deselectedLocations ? JSON.parse(deselectedLocations) : [],
        unassignedSensors: undefined
      },
      haveFiltersBeenUpdated: false,
      resetFilters: true
    });
  }

  _fetchSensors() {
    const { filters: {
      fromDate, 
      toDate, 
      noSamplesSinceDate, 
      sensorTableSearchValue, 
      sortOrder, 
      sortBy, 
      offset, 
      batteryLevel, 
      signalStrength, 
      selectedLocations, 
      deselectedLocations,
      unassignedSensors
    }} = this.state;
    const { getSensors } = this.props;

    const queryParams = Object.assign({}, {
      from: fromDate,
      to: toDate,
      previousMessageAt: noSamplesSinceDate,
      search: sensorTableSearchValue,
      sortOrder,
      sortBy,
      offset,
      batteryLevel,
      signalStrength,
      selectedLocations: JSON.stringify(selectedLocations),
      deselectedLocations: JSON.stringify(deselectedLocations),
      unassignedSensors
    });

    getSensors(queryParams);

    this.setState({
      haveFiltersBeenUpdated: false
    });
  }

  render() {
    const { 
      haveFiltersBeenUpdated, 
      filters,
      filters: {
        selectedLocations,
        deselectedLocations,
        unassignedSensors
      },
      resetFilters
    } = this.state;
    const { 
      awaitingSensorResponseFromServer,
      sensorsResponse,
      sensorsResponse: {
        countByLocation
      }, 
    } = this.props;

    return (
      <MainContainer>
        <div className={styles.row}>
          <div className={styles.filterCol}>
            <div className={styles.filterContainer}>
              
              <Freetext header="Location hierarchy" />

              <CheckboxTree 
                nodes={this.state.locations}
                countByLocation={countByLocation}
                reset={resetFilters}
                handleUpdatesToFilter={this.handleUpdatesToFilter}
                userSelectedNodes={selectedLocations}
                userDeselectedNodes={deselectedLocations}
                unassignedFlag={unassignedSensors}
                unassignedEntryLabel="Unassigned sensors"
                stringToFetchUnassigned="unassignedSensor"
                unassignedLabel="unassignedSensors"
              />
          
            </div>
          </div>
          <div className={styles.listCol}>
            <div className={styles.listContainer}>

              <Filters 
                filters={filters}
                handleUpdatesToFilter={this.handleUpdatesToFilter}
                handleSearchClear={this.handleSearchClear}
              />
              
              <SensorTable 
                sensorResponse={sensorsResponse}
                filters={filters}
                handleUpdatesToFilter={this.handleUpdatesToFilter}
                awaitingSensorResponseFromServer={awaitingSensorResponseFromServer}
                history={this.props.history}
                match={this.props.match}
              />

            </div>
          </div>
        </div>

        <FilterActionFooter
          resetFiltersToDefault={this.resetFiltersToDefault}
          fetchSensors={this._fetchSensors}
          haveFiltersBeenUpdated={haveFiltersBeenUpdated}
        />
      </MainContainer>
    );
  }
}

function mapStateToProps(state) {
  return {
    locationHierarchy: state.locations.hierarchy,
    sensorsResponse: state.sensors.metricData,
    awaitingSensorResponseFromServer: state.loading.sensors,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    getSensors: sensorActions.getAllSensors,
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(Sensors);
