import React, { Component } from "react";
import moment from "moment";
import { get } from "lodash";
import { DateRangePicker } from "../../../components/DateRangePicker";
import Tag from "../../../components/Tag";
import SmallButton from "../../../components/SmallButton";
import SegmentedControl from "../../../components/SegmentedControl";
import DateInputBox from "../../../components/DateInputBox";
import styled from "styled-components";
import strings from "./strings";

class DateFilter extends Component {

  constructor(props) {
    super(props);
    this.state = {
      ranges: this.props.query.dateRanges.map(dateRange => ({ startDate: moment(dateRange.startDate).toDate(), endDate: moment(dateRange.endDate).toDate() })),
      includeHolidays: this.props.query.includeHolidays,
      includeWeekends: this.props.query.includeWeekends,
      hasChanges: false
    };

    if (this.state.ranges.length > 0) {
      this.state.customStartDateString = moment(this.state.ranges[0].startDate).format("DD/MM/YY");
      this.state.customEndDateString = moment(this.state.ranges[this.state.ranges.length - 1].endDate).format("DD/MM/YY");
    }
    else {
      this.state.customStartDateString = "";
      this.state.customEndDateString = "";
    }

    this.onRangesChange = this.onRangesChange.bind(this);
    this.hasChanges = this.hasChanges.bind(this);
    this.onInjectCustomDateRange = this.onInjectCustomDateRange.bind(this);
    this.onClearCustomDateRange = this.onClearCustomDateRange.bind(this);
    this.onIncludeHolidaysChange = this.onIncludeHolidaysChange.bind(this);
    this.onIncludeWeekendsChange = this.onIncludeWeekendsChange.bind(this);
    this.onApply = this.onApply.bind(this);
  }

  onRangesChange(ranges) {
    let customStartDateString = "";
    let customEndDateString = "";
    
    if (ranges.length > 0) {
      customStartDateString = moment(ranges[0].startDate).format("DD/MM/YY");
      customEndDateString = moment(ranges[ranges.length - 1].endDate).format("DD/MM/YY");
    }

    this.setState({ ranges, customStartDateString, customEndDateString }, () => this.hasChanges());
  }

  onInjectCustomDateRange() {
    if (this.validCustomRange()) {
      const startDate = moment(this.state.customStartDateString, "DD/MM/YY", true).toDate();
      const endDate = moment(this.state.customEndDateString, "DD/MM/YY", true).toDate();
      this.setState({ ranges:  [{ startDate, endDate }] }, () => this.hasChanges());
    }
  }

  onClearCustomDateRange() {
    this.setState({ ranges: [] }, () => this.hasChanges());
  }

  validCustomRange() {
    const startDate = moment(this.state.customStartDateString, "DD/MM/YY", true);
    const endDate = moment(this.state.customEndDateString, "DD/MM/YY", true);
    return startDate.isValid() && endDate.isValid() && startDate.isBefore(endDate);
  }

  onIncludeHolidaysChange() {
    this.setState({ includeHolidays: !this.state.includeHolidays }, () => this.hasChanges());
  }

  onIncludeWeekendsChange() {
    this.setState({ includeWeekends: !this.state.includeWeekends }, () => this.hasChanges());
  }

  hasChanges() {
    const newDateRanges = this.state.ranges.map(range => ({ startDate: range.startDate.toISOString(), endDate: range.endDate.toISOString() }));

    // Check if the new data are different from the old data
    if (JSON.stringify(newDateRanges) !== JSON.stringify(this.props.query.dateRanges) ||
        this.state.includeHolidays !== this.props.query.includeHolidays ||
        this.state.includeWeekends !== this.props.query.includeWeekends) {
      this.setState({ hasChanges: true });
    }
    else {
      this.setState({ hasChanges: false });
    }

    this.props.onTimeRangesChanged(newDateRanges, this.state.includeHolidays, this.state.includeWeekends);
  }

  onApply() {
    const newDateRanges = this.state.ranges.map(range => ({ startDate: range.startDate.toISOString(), endDate: range.endDate.toISOString() }));
    this.props.onTimeRangesSaved(newDateRanges, this.state.includeHolidays, this.state.includeWeekends);
    this.props.onClose();
  }

  render() {
    const { workplaceFilters, disabledDates } = this.props;

    strings.setLanguage(localStorage.getItem("language") || "en");

    const customStartDate = moment(this.state.customStartDateString, "DD/MM/YY", true);
    const customEndDate = moment(this.state.customEndDateString, "DD/MM/YY", true);

    // Get max and min dates from selectedLocation
    var minDate = moment().subtract(1, "years").startOf("day").toDate();
    var maxDate = moment().subtract(1, "days").startOf("day").toDate();
    if (this.props.selectedLocation) {
      if (this.props.selectedLocation.hasAggregateDataSince) {
        minDate = moment(this.props.selectedLocation.hasAggregateDataSince).toDate();
      }
      if (this.props.selectedLocation.hasAggregateDataUntil) {
        maxDate = moment(this.props.selectedLocation.hasAggregateDataUntil).toDate();
      }
    }
    
    // console.log("this.props.selectedLocation", this.props.selectedLocation);
    // console.log("minDate", minDate);
    // console.log("maxDate", maxDate);

    return (
      <Container onClick={this.props.onClose}>
        <ScrollBox>
          <Block onClick={(e) => e.stopPropagation()}>
            <Header>
              <Title>{strings.timePeriodTitle}</Title>
              <SmallButton text={strings.close} color="white-gray" onClick={this.props.onClose} style={{ margin: 0, padding: "0 10px", minWidth: "70px" }}  />
              <SmallButton text={strings.save} onClick={this.onApply} style={{ margin: 0, padding: "0 10px", minWidth: "70px" }} disabled={this.state.ranges.length === 0} />
            </Header>
            <BlockContent>
              <Section>
                <QuickSelectLabel>{strings.quickSelect}:</QuickSelectLabel>
                <TagContainer>
                  <Tag text={strings.everything} onClick={() => {
                    this.onRangesChange([
                      { startDate: minDate, endDate: moment().subtract(2, "days").startOf("day").toDate() }
                    ]);
                  }} />
                </TagContainer>
                <TagContainer>
                  <Tag text={strings.thisYear} onClick={() => {
                    this.onRangesChange([
                      { startDate: moment().startOf("year").toDate(), endDate: moment().subtract(2, "days").startOf("day").toDate() }
                    ]);
                  }} />
                </TagContainer>
                <TagContainer>
                  <Tag text={strings.last12Months} onClick={() => {
                    this.onRangesChange([
                      { startDate: moment().subtract(12, "months").startOf("month").toDate(), endDate: moment().subtract(1, "months").endOf("month").toDate() }
                    ]);
                  }} />
                </TagContainer>
                <TagContainer>
                  <Tag text={strings.last6Months} onClick={() => {
                    this.onRangesChange([
                      { startDate: moment().subtract(6, "months").startOf("month").toDate(), endDate: moment().subtract(1, "months").endOf("month").toDate() }
                    ]);
                  }} />
                </TagContainer>
                <TagContainer>
                  <Tag text={strings.last3Months} onClick={() => {
                    this.onRangesChange([
                      { startDate: moment().subtract(3, "months").startOf("month").toDate(), endDate: moment().subtract(1, "months").endOf("month").toDate() }
                    ]);
                  }} />
                </TagContainer>
                <TagContainer>
                  <Tag text={strings.thisMonth} onClick={() => {
                    this.onRangesChange([
                      { startDate: moment().startOf("month").toDate(), endDate: moment().subtract(2, "days").startOf("day").toDate() }
                    ]);
                  }} />
                </TagContainer>
              </Section>
              <Section>
                <CustomDateRangeContainer>
                  <DateInputBox
                    label={strings.selectDateRange}
                    value={this.state.customStartDateString}
                    style={{ width: "140px", paddingTop: "0px", marginRight: "10px" }}
                    onChange={(e) => this.setState({ customStartDateString: e.target.value })}
                    valid={customStartDate.isValid()}
                  />
                  <DateInputBox
                    label=" "
                    value={this.state.customEndDateString}
                    style={{ width: "140px", paddingTop: "0px", marginRight: "20px" }}
                    onChange={(e) => this.setState({ customEndDateString: e.target.value })}
                    valid={customEndDate.isValid()}
                  />
                  <SmallButton
                    text={strings.fill}
                    color="white"
                    style={{ marginTop: "1px", marginBottom: "2px", marginLeft: "0px", marginRight: "10px" }}
                    onClick={this.onInjectCustomDateRange}
                    disabled={!this.validCustomRange() || (this.state.ranges.length === 1 && this.state.ranges[0].startDate.toISOString() === customStartDate.toISOString() && this.state.ranges[0].endDate.toISOString() === customEndDate.toISOString())}
                  />
                  <SmallButton
                    text={strings.clear}
                    color="white"
                    style={{ marginTop: "1px", marginBottom: "2px", marginLeft: "0px" }}
                    onClick={this.onClearCustomDateRange}
                    disabled={this.state.ranges.length === 0}
                  />
                </CustomDateRangeContainer>
              </Section>
              <DateRangePicker
                ranges={this.state.ranges}
                disabledDates={disabledDates}
                disableWeekends={!this.state.includeWeekends}
                disableHolidays={!this.state.includeHolidays}
                holidayDates={get(workplaceFilters, "holidays[Europe/Oslo]", [])}
                onTimeRangesChanged={this.onRangesChange}
                maxDate={maxDate}
                minDate={minDate}
              />
              <Section $topMargin="10px" $bottomMargin="0px">
                <CheckboxContainer>
                  <SegmentedControl
                    label={strings.holidays}
                    options={[
                      { label: strings.show, value: true },
                      { label: strings.hide, value: false }
                    ]}
                    value={this.state.includeHolidays}
                    onChange={this.onIncludeHolidaysChange}
                  />
                </CheckboxContainer>
                <CheckboxContainer>
                  <SegmentedControl
                    label={strings.weekends}
                    options={[
                      { label: strings.show, value: true },
                      { label: strings.hide, value: false }
                    ]}
                    value={this.state.includeWeekends}
                    onChange={this.onIncludeWeekendsChange}
                  />
                </CheckboxContainer>
              </Section>
            </BlockContent>
          </Block>
        </ScrollBox>
      </Container> 
    );
  }
}

export default DateFilter;

const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  z-index: 3;
`;

const ScrollBox = styled.div`
  position: relative;
  overflow: auto;
  max-height: 100%;
  height: 100%;
  padding: 30px;
  box-sizing: border-box;
`;

const Block = styled.div`
  position: relative;
  background-color: rgb(255, 255, 255);
  border-radius: 6px;
  border-width: 1px;
  border-style: solid;
  border-color: rgb(221, 221, 221);
  margin-left: "10px";
  margin-right: "10px";
  font-size: 16px;
  font-weight: 400;
  
  max-width: 1100px;
  margin: 0 auto;
`;

const BlockContent = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  position: relative;
  // min-height: 380px;
  padding: 10px 20px;
  padding-bottom: 30px;
  font-size: 16px;
  font-weight: 400;
`;

const Section = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: ${props => props.$topMargin ?? "20px"};
  margin-bottom: ${props => props.$bottomMargin ?? "10px"};
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  padding: 0 20px;
  box-shadow: inset 0 -1px 0 0 #DBDBDB;
  box-sizing: border-box;
  gap: 10px;
`;

const Title = styled.div`
  font-size: 20px;
  font-weight: 600;
  color: #222222;
  flex-grow: 1;
  flex-basis: 0;
`;

const QuickSelectLabel = styled.div`
  height: 28px;
  line-height: 28px;
  font-size: 16px;
  font-weight: 600;
  color: #222;
  margin-right: 10px;
  margin-bottom: 5px;
`;

const TagContainer = styled.div`
  margin-bottom: 5px;
`;

const CheckboxContainer = styled.div`
  margin-right: 20px;
  margin-bottom: 5px;
`;

const CustomDateRangeContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: end;
  justify-content: flex-start;
`;