import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import moment from "moment";
import { get } from "lodash";
import SearchBox from "../../components/SearchBox";
import Table from "../../components/Table";
import TopRowOptions from "../../components/TopRowOptions";
import * as jobActions from "../../actions/jobs";
import style from "./style.module.scss";

class JobGroupList extends Component {

  constructor(props) {
    super(props);
    this.state = {
      pathname: props.pathname,
      history: props.history,
      searchText: undefined,
      sortBy: "lastUpdateAt",
      sortOrder: "desc",
      offset: 0,
      limit: 20,
      highlightedId: null,
      highlightedType: null
    };
    this.fetchData = this.fetchData.bind(this);
    this.onRowClick = this.onRowClick.bind(this);
    this.onHover = this.onHover.bind(this);
  }

  componentDidMount() {
    this.fetchData();
  }

  onRowClick(column, rowInfo) {
    return {
      onClick: e => {
        if (column.name !== "isSelected" && rowInfo !== undefined) {
          this.props.setJobGroup(rowInfo.original)
          this.state.history.push(`/companies/${this.props.match.params.companyId}/installation/job-groups/${rowInfo.original.subject.type}/${rowInfo.original.subject._id}`);
        }
      },
      onMouseOver: e => {
        // console.log(rowInfo);
        if (rowInfo && rowInfo.original.subject._id && rowInfo.original.subject.type) {
          this.onHover(rowInfo.original.subject._id, rowInfo.original.subject.type);
        }
      },
      onMouseLeave: e => this.onHover(null),
      style: {
        cursor: "pointer",
        background: rowInfo && rowInfo.original.subject._id === this.state.highlightedType && rowInfo.original.subject.type === this.state.highlightedId ? "rgba(0,0,0,0.05)" : (rowInfo && rowInfo.original.subject._id === this.props.match.params.id && rowInfo.original.subject.type === this.props.match.params.type ? "rgba(0,0,0,0.05)" : null)
      }
    }
  }

  onHover(id, type) {
    if (this.state.highlightedId !== id && this.state.highlightedType !== type) {
      this.setState({ highlightedId: id, highlightedType: type });
    }
  }

  fetchData() {
    const queryParams = {
      search: this.state.searchText,
      sortBy: this.state.sortBy,
      sortOrder: this.state.sortOrder,
      offset: this.state.offset,
      limit: this.state.limit
    };
    
    this.props.getJobGroups(queryParams);
  }

  render() {
    const isLoading = this.props.isJobsLoading;

    const topRowOptions = (
      <TopRowOptions
        searchbox={(
          <SearchBox
            value={this.state.searchText}
            onSearchChanged={(value) => this.setState({ searchText: value })}
            onSearchClick={() => this.setState({ offset: 0 }, this.fetchData)}
            onClear={() => this.setState({ searchText: undefined, offset: 0 }, this.fetchData)}
            inListView
          />
        )}
      />
    );

    const tableElement = (
      <Table
        data={this.props.jobGroups.queue}
        sortBy={this.state.sortBy}
        sortOrder={this.state.sortOrder}
        offset={this.state.offset}
        limit={this.state.limit}
        count={this.props.jobGroups.count}
        onSortedChange={(newSorted) => {
          this.setState({
            sortBy: newSorted[0].id,
            sortOrder: newSorted[0].desc ? "desc" : "asc"
          }, this.fetchData);
        }}
        onOffsetChange={(offset) => {
          this.setState({ offset }, this.fetchData);
        }}
        noDataText={isLoading ? "" : "No job groups found"}
        columns={[
          {
            header: "Updated",
            accessorKey: "lastUpdateAt",
            defaultSortDesc: true,
            width: 120,
            cell: ({ row }) => {
              const date = `${moment(row.original.lastUpdateAt).format("HH:mm - DD/MM/YY")}`;
              return <span title={date}>{date}</span>;
            }
          },
          {
            header: "Name",
            accessorKey: "subject.name",
            cell: ({ row }) => (<span title={get(row.original, "subject.name", "?")}>{get(row.original, "subject.name", "?")}</span>)
          },
          {
            header: "Type",
            accessorKey: "subject.type",
            width: 160,
            sortable: false,
            cell: ({ row }) => (<span title={get(row.original, "subject.type", "?")}>{get(row.original, "subject.type", "?")}</span>)
          },
          {
            header: "# jobs",
            accessorKey: "jobs",
            sortable: false,
            width: 80,
            cell: ({ row }) => (<span title={get(row.original, "jobs.length", 0)}>{get(row.original, "jobs.length", 0)}</span>)
          },
          // {
          //   header: "Status",
          //   accessorKey: "status",
          //   width: 120,
          //   cell: ({ row }) => {
          //     const { status } = row;
          //     switch (status) {
          //       case "waiting":
          //         return <StatusLabel text={status} color={"grey"} inRow />
          //       case "failed":
          //         return <StatusLabel text={status} color={"red"} inRow />
          //       case "active":
          //         return <StatusLabel text={status} color={"blue"} inRow />
          //       case "completed":
          //         return <StatusLabel text={status} color={"green"} inRow />
          //       default:
          //         break;
          //     }
              
          //     return <StatusLabel text={status} color={"grey"} inRow />
          //   }
          // },
          {
            id: "arrow",
            header: "",
            accessorKey: "",
            sortable: false,
            className: "pull-right",
            width: 60,
            cell: ({ row }) => (<div className="arrow" />)
          }
        ]}
        getTdProps={this.onRowClick}
        className="setMaxHeigth -row-clickable -highlight"
        loading={this.props.isJobsLoading}
      />
    );

    return (
      <div className={style.scroll}>
        { topRowOptions }
        { tableElement }
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    jobGroups: state.jobGroups.data,
    queryParams: state.jobGroups.queryParams,
    isJobsLoading: state.loading.jobGroups
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    getJobGroups: jobActions.getJobGroups,
    setJobGroup: jobActions.setJobGroup
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(JobGroupList);
