import React, { Component } from "react";
import { Row, Col, Hidden, Visible } from "react-grid-system";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Loader from "../../components/Loader";
import Freetext from "../../components/Freetext";
import Button from "../../components/Button";
import InputBox from "../../components/InputBox";
import { ControlledDropdownSelection } from "../../components/DropdownSelection";
import * as locationActions from "../../actions/locations";
import * as API from "../../ApiTypes";
import style from "./style.module.scss";
import { getLocationFilterTypes, getLocation } from "../../locationHelpers";

class LocationSettings extends Component {

  constructor(props) {
    super(props);
    this.state = {
      type: "*",
      email: "",
      bookingQRCodes: false
    };

    this.onLocationTypeChange = this.onLocationTypeChange.bind(this);
    this.onEmailChange = this.onEmailChange.bind(this);
    this.onBookingQRCodesChange = this.onBookingQRCodesChange.bind(this);
    this.generateQRCodes = this.generateQRCodes.bind(this);
  }

  onLocationTypeChange(event) {
    this.setState({ type: event.target.value });
  }

  onEmailChange(event) {
    this.setState({ email: event.target.value });
  }

  onBookingQRCodesChange(event) {
    this.setState({ bookingQRCodes: event.target.value === "book" });
  }

  generateQRCodes() {

    // Get all location ids 
    let newHierarchy = JSON.parse(JSON.stringify(this.props.locationHierarchy));

    let topLocation;
    if (this.props.locationId === "root") {
      topLocation = { id: "*", children: newHierarchy };
    }
    else {
      topLocation = getLocation({ id: "*", children: newHierarchy }, this.props.locationId);
    }

    // Get all location from topLocation.children with type <this.state.type>
    const locationIds = [];
    const getLocations = (location) => {
      
      // Regex to match type with asterisk
      const regex = new RegExp(`^${this.state.type.replace("*", ".*")}$`);
      if (location.id !== "*" && regex.test(location.type)) {
        locationIds.push(location.id);
      }
      if (location.children) {
        location.children.forEach(child => getLocations(child));
      }
    }
    getLocations(topLocation);

    this.props.generateQRCodes(locationIds, this.state.email, this.state.bookingQRCodes);
  }

  render() {
    // console.log("LocationSettings.render()", this.props);

    const isLoading = this.props.isLoadingLocations || this.props.isSyncingMap;

    if (isLoading) {
      return <Loader fullScreen />;
    }

    // Type form
    const locationTypes = [{ id: "*", name: "Any type" }, ...getLocationFilterTypes()];
    const typeDropDown = (
      <ControlledDropdownSelection 
        options={locationTypes}
        name="selectedType" 
        value={this.state.type}
        onChange={this.onLocationTypeChange} 
        label="Location types"
        style={{ marginRight: "10px" }}
      />
    );

    const actionTypes = [{ id: "open", name: "Show location" }, { id: "book", name: "Book location/resource" }];
    const actionTypeDropDown = (
      <ControlledDropdownSelection 
        options={actionTypes}
        name="selectedActionType" 
        value={this.state.bookingQRCodes ? "book" : "open"}
        onChange={this.onBookingQRCodesChange} 
        label="Action after scanning"
      />
    );

    const generateQRCode = (
      <>
        <Row className={style.topRow}>
          <Col md={12} lg={10} xl={8}>
            <Freetext header="Generate QR codes" content="Create a PDF of all locations of the chosen type below this location. The file will be sent to the entered e-mail address when the QR code generation has completed. Specify what will happen when the user scans the QR code with 'Action after scanning'." />
          </Col>
        </Row>
        <Row>
          <Col md={12} lg={8} xl={6}>
            <InputBox 
              value={this.state.email}
              onChange={this.onEmailChange}
              label="Recipient e-mail"
              placeholder="Enter e-mail"
              valid={this.state.email && this.state.email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)} 
              required
              showValidIcon
              />
          </Col>
        </Row>
        <Row nogutter>
          { typeDropDown }
          { actionTypeDropDown }
        </Row>
        <div style={{ paddingTop: "20px" }} />
        <Row>
          <Col>
            <div style={{ display: "inline-block" }}>
              <Button
                text="Get QR codes on e-mail"
                onClick={this.generateQRCodes}
                disabled={!this.state.email || !this.state.email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i) || this.props.isGeneratingQRCodes}
                isLoading={this.props.isGeneratingQRCodes}
                />
            </div>
          </Col>
        </Row>
      </>
    );

    return (
      <>
        <Hidden xs sm md lg>
          <div className={style.listContainerHidden}>
            <div className={style.row}>
              <div className={style.col50}>
                <div className={style.scroll}>
                  { generateQRCode }
                  <div style={{ paddingTop: "40px" }} />
                </div>
              </div>
              <div className={style.mapPart40}></div>
            </div>
          </div>
        </Hidden>

        <Visible lg>
          <div className={style.listContainerHidden}>
            <div className={style.row}>
              <div className={style.col}>
                <div className={style.scroll}>
                  { generateQRCode }
                  <div style={{ paddingTop: "40px" }} />
                </div>
              </div>
            </div>
          </div>
        </Visible>

        <Visible xs sm md>
          <div className={style.listContainerHiddenMobile}>
            <div className={style.row}>
              <div className={style.col}>
                <div className={style.slimScroll}>
                  { generateQRCode }
                  <div style={{ paddingTop: "40px" }} />
                </div>
              </div>
            </div>
          </div>
        </Visible>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    location: state.location,
    locationHierarchy: state.locations.hierarchy,
    isLoadingLocations: state.loading.locations,
    isSyncingMap: state.loading[API.SYNC_FLOOR_MAP],
    isGeneratingQRCodes: state.loading[API.GENERATE_QR_CODES],
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    generateQRCodes: locationActions.generateQRCodes,
  }, dispatch);
}

export default connect(mapStateToProps,mapDispatchToProps)(LocationSettings);