import React, { Component } from "react";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, RichUtils, Modifier} from "draft-js";
import { stateToMarkdown } from "draft-js-export-markdown";
import { stateFromMarkdown } from "draft-js-import-markdown";
import { MARK_DOWN_TOOLBAR } from "./toolbar";
import { Info } from "ui";
import style from  "./style.module.scss";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

//{ placeholder, label, name, input, onChange = () => {}, value, disabled = false, valid = true, showCopy = false, size = "large", isTopRow = false }
class MarkdownTextBox extends Component {
  constructor(props) {
    super(props);
    var fixedValue = fixNewline(props.value);
    this.state = {
      editorState: EditorState.createWithContent(stateFromMarkdown(fixedValue ?? "", { gfm: true })),
      content: fixedValue
    };
    this.onEditorStateChange = this.onEditorStateChange.bind(this);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    // console.log("getDerivedStateFromProps - nextProps", nextProps)
    // console.log("getDerivedStateFromProps - prevState", prevState)
    var fixedValue = fixNewline(nextProps.value);
    if (fixedValue !== prevState.content) {
      const newState = {
        editorState: EditorState.createWithContent(stateFromMarkdown(fixedValue ?? "", { gfm: true })),
        content: fixedValue
      };
      return newState;
    }

    return null;
  }

  onEditorStateChange(editorState) {
    const content = stateToMarkdown(editorState.getCurrentContent(), { gfm: true });
    var fixedValue = fixNewline(content);
    this.setState({
      editorState,
      content: fixedValue
    });
    this.props.onChange(fixedValue);
  };
   
  render() {
    const { editorState } = this.state;
    const { isTopRow, label, help } = this.props;
    return (
      <div className={ isTopRow ? style.topTextbox : style.textbox }>
        <label className={style.label}>{label}</label>
        { help && <Info text={help} />}
        <Editor
          editorState={editorState}
          editorClassName="smartform-wysiwyg-editor"
          wrapperClassName="smartform-wysiwyg-wrapper"
          toolbar={MARK_DOWN_TOOLBAR}
          onEditorStateChange={this.onEditorStateChange}
        />

      { this.props.info && <div className={style.info}>{this.props.info}</div> }
      </div>
    );
  }
}

const fixNewline = (text) => {
  var fixedText = text.replaceAll("\n\n", "#$%newline%$#");
  fixedText = fixedText.replaceAll("  \n", "\n"); // to make sure we're not adding more and more
  fixedText = fixedText.replaceAll("\n", "  \n");
  fixedText = fixedText.replaceAll("#$%newline%$#", "\n\n");
  fixedText = fixedText.replace(/^\s+|\s+$/g, '');
  return fixedText;
}

// const getInputStyles = (value, size, disabled, valid) => {
//   let style = { 
//     height: size === "large" ? "250px" : size === "medium" ? "100px" : "54px"
//   };
  
//   if (!isEmpty(value)) {
//     if (disabled) {
//       style.color = DARKGREY;
//     }
//     else if (!valid) {
//       style.borderColor = DARKRED;
//       style.borderWidth = "2px";
//     }
//   }

//   return style;
// }

export default MarkdownTextBox;