import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { isEmpty } from "lodash";
import moment from "moment";
import UAParser from "ua-parser-js";
import Table from "../../components/Table";

class ScreenDetailsPending extends Component {

  constructor(props) {
    super(props);
    this.state = {
      sortBy: "createdAt",
      sortOrder: "desc"
    }
    this.onPendingDeviceRowClick = this.onPendingDeviceRowClick.bind(this);
    this.onSortedChange = this.onSortedChange.bind(this);
    this.getPendingDevicesTable = this.getPendingDevicesTable.bind(this);
  }

  onPendingDeviceRowClick(column, rowInfo) {
    return {
      onClick: e => {
        // console.log("onRowClick", e, rowInfo, column, instance);
        const hasAccess = this.props.auth.hasITAdminRole;
        if (column.name !== 'isSelected' && rowInfo !== undefined && hasAccess) {

          const link = `/companies/${this.props.match.params.companyId}/screens/edit/${this.props.screenGroupId}/devices/${rowInfo.original.id}`;

          if (e.metaKey || e.ctrlKey) {
            window.open(`${link}`);
          }
          else {
            this.props.history.push(link);
          }
        }
      },
      style: {
        cursor: "pointer"
      }
    }
  }

  onSortedChange(newSorted) {
    const sortBy = newSorted[0].id;
    const sortOrder = newSorted[0].desc ? "desc" : "asc";
    this.setState({ sortBy, sortOrder });
  }

  getPendingDevicesTable() {

    let devices = this.props.pendingDevices[this.props.screenGroupId] ?? [];

    // Sort links
    devices = devices.sort((a, b) => {
      if (this.state.sortBy === "createdAt") {
        if (this.state.sortOrder === "desc") {
          return moment(b.createdAt).diff(a.createdAt);
        }
        else {
          return moment(a.createdAt).diff(b.createdAt);
        }
      }
      else if (this.state.sortBy === "authCode") {
        if (this.state.sortOrder === "desc") {
          return b.authCode.localeCompare(a.authCode);
        }
        else {
          return a.authCode.localeCompare(b.authCode);
        }
      }
      else if (this.state.sortBy === "os") {
        const parserA = new UAParser(a.userAgent);
        const resultA = parserA.getResult();
        const parserB = new UAParser(b.userAgent);
        const resultB = parserB.getResult();
        if (this.state.sortOrder === "desc") {
          return resultB.os.name.localeCompare(resultA.os.name);
        }
        else {
          return resultA.os.name.localeCompare(resultB.os.name);
        }
      }
      else if (this.state.sortBy === "browser") {
        const parserA = new UAParser(a.userAgent);
        const resultA = parserA.getResult();
        const parserB = new UAParser(b.userAgent);
        const resultB = parserB.getResult();
        if (this.state.sortOrder === "desc") {
          return resultB.browser.name.localeCompare(resultA.browser.name);
        }
        else {
          return resultA.browser.name.localeCompare(resultB.browser.name);
        }
      }
      else {
        return 0;
      }
    });

    // Fix reload
    devices = devices.map(s => s);

    const hasAccess = this.props.auth.hasITAdminRole;

    return (
      <Table
        data={devices}
        sortBy={this.state.sortBy}
        sortOrder={this.state.sortOrder}
        onSortedChange={this.onSortedChange}
        noDataText={this.props.isLoading ? "" : "No pending devices"}
        columns={[
          {
            header: "Created",
            accessorKey: "createdAt",
            width: 140,
            cell: ({ row }) => 
            (<span title={moment(row.original.createdAt).format('Do MMMM YYYY, HH:mm')}>
              {moment(row.original.createdAt).format('DD/MM/YY, HH:mm')}
            </span>)
          },
          {
            header: "Auth code",
            accessorKey: "authCode",
            width: 100,
            cell: ({ row }) => (<span>{row.original.authCode}</span>)
          },
          {
            header: "OS",
            accessorKey: "os",
            cell: ({ row }) => {
              const parser = new UAParser(row.original.userAgent);
              const result = parser.getResult();
              return (
                <div>
                  <div>{result.os.name} {result.os.version}</div>
                </div>
              );
            }
          },
          {
            header: "Browser",
            accessorKey: "browser",
            cell: ({ row }) => {
              const parser = new UAParser(row.original.userAgent);
              const result = parser.getResult();
              return (
                <div>
                  <div>{result.browser.name} {result.browser.version}</div>
                </div>
              );
            }
          },
          {
            id: "arrow",
            header: "",
            sortable: false,
            className: "pull-right",
            width: 60,
            cell: () => {
              return hasAccess && <div className="arrow" />
            }
          }
        ]}
        getTdProps={hasAccess && this.onPendingDeviceRowClick}
        className="setMaxHeigth -row-clickable -highlight"
        loading={this.props.isLoading}
      />
    );
  }

  render() {

    if (isEmpty(this.props.screenGroupId) || isEmpty(this.props.screen.name)) {
      return null;
    }

    let pendingDevicesElement = null;
    if (this.props.screen.isPrivate) {
      pendingDevicesElement = (
        <>
          { this.getPendingDevicesTable() }
        </>
      );
    }

    return pendingDevicesElement;
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
    screen: state.screen,
    pendingDevices: state.screens.pendingDevices,
    isLoading: state.loading.screens,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({}, dispatch);
}

export default connect(mapStateToProps,mapDispatchToProps)(ScreenDetailsPending);