import React, { Component } from "react";
import { Route, Switch } from 'react-router-dom';
import { Hidden, Visible } from "react-grid-system";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import isEmpty from "lodash/isEmpty";
import InviteDetails from "./inviteDetails";
import InviteList from "./inviteList";
import * as userActions from "../../actions/users";
import * as selectedActions from "../../actions/selected";
import style from "./style.module.scss";

class Invites extends Component {

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    if (!this.props.location.pathname.endsWith("/groups") && 
        !this.props.location.pathname.endsWith("/locations") &&
        !this.props.location.pathname.endsWith("/content")) {
      document.title = `BLDNG.ai - Org. invites`;
    }
  }

  render() {
    // console.log("Users.render()", this.props)
    const hasSelection = !isEmpty(this.props.selectedInvites);
    
    return (
      <>
        <Hidden xs sm md>
          <div className={ hasSelection ? style.mainContainer : style.mainContainerHidden }>
            <div className={style.row}>
              <div className={style.listContainer}>
                <div className={style.scroll}>
                  <Switch>
                    <InviteList { ...this.props} />
                  </Switch>
                </div>
              </div>
              <div className={style.sideBar}>
                <div className={style.scroll}>
                  <Switch>
                    <InviteDetails { ...this.props} />
                  </Switch>
                </div>
              </div>
            </div>
          </div>
        </Hidden>
        <Visible xs sm md>
          <div className={ hasSelection ? style.mainContainer : style.mainContainerHidden } style={{ backgroundColor: !isEmpty(this.props.inviteId) && "#F6F6F6" }}>
            <div className={style.slimScroll}>
              {
                isEmpty(this.props.inviteId) ? (<InviteList { ...this.props} />) : (<InviteDetails { ...this.props} />)
              }
            </div>
          </div>
        </Visible>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    data: state.userGroups.data,
    isLoading: state.loading.userGroups,
    selectedInvites: state.selected.locations,
    selectedCompany: state.auth.selectedCompany
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ 
    
   }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(Invites);
