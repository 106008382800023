import axios from "axios";
import Cookies from "universal-cookie";
import * as types from "../ApiTypes";
import * as actions from "./sharedFunction";
import { API_URL, COOKIE_PREFIX } from "../env";
import { getCompanyId } from "../helpers";

const cookies = new Cookies();

export const getAnomalies = (queryParams) => async (dispatch) => {
  dispatch(actions.requestData(types.GET_ANOMALIES));
  
  const apiUrl = await API_URL();
  const companyId = getCompanyId();
  const cookiePrefix = await COOKIE_PREFIX();
  const accessToken = cookies.get(`${cookiePrefix}_access_token`);
  
  axios.get(`${apiUrl}companies/${companyId}/anomalies`, await actions.getAxiosConfig(queryParams))
    .then((response) => (
      dispatch(actions.receiveData(response.data, types.GET_ANOMALIES))
    ))
    .catch((error) => {
      if (error.response && error.response.status === 401) {
        actions.sessionTokenExpired(cookiePrefix, accessToken);
      }
      else {
        dispatch(actions.receiveError(error, types.GET_ANOMALIES));
      }
    });
};

export const getAnomaly = (id) => async (dispatch) => {
  dispatch(actions.requestData(types.GET_ANOMALY));
  
  const apiUrl = await API_URL();
  const companyId = getCompanyId();
  const cookiePrefix = await COOKIE_PREFIX();
  const accessToken = cookies.get(`${cookiePrefix}_access_token`);
  
  axios.get(`${apiUrl}companies/${companyId}/anomalies/${id}`, await actions.getAxiosConfig())
    .then((response) => (
      dispatch(actions.receiveData(response.data, types.GET_ANOMALY))
    ))
    .catch((error) => {
      if (error.response && error.response.status === 401) {
        actions.sessionTokenExpired(cookiePrefix, accessToken);
      }
      else {
        dispatch(actions.receiveError(error, types.GET_ANOMALY));
      }
    });
};

export const updateAnomaly = (id, body) => async (dispatch) => {
  dispatch(actions.requestData(types.UPDATE_ANOMALY));

  const apiUrl = await API_URL();
  const cookiePrefix = await COOKIE_PREFIX();
  const accessToken = cookies.get(`${cookiePrefix}_access_token`);
  const companyId = getCompanyId();

  axios.patch(`${apiUrl}companies/${companyId}/anomalies/${id}`, body, await actions.getAxiosConfig())
    .then((response) => {
      dispatch(actions.receiveData(response.data, types.UPDATE_ANOMALY, { anomalyId: id, ...body }));
    })
    .catch((error) => {
      if (error.response && error.response.status === 401) {
        actions.sessionTokenExpired(cookiePrefix, accessToken);
      }
      else {
        dispatch(actions.receiveError(error, types.UPDATE_ANOMALY));
      }
    });
};

export const clearAnomaly = () => async (dispatch) => {
  dispatch({ type: types.CLEAR_ANOMALY })
};