import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Hidden, Visible } from "react-grid-system";
import { get, isEmpty } from "lodash";
import SearchBox from "../../components/SearchBox";
import TopRowOptions from "../../components/TopRowOptions";
import Table from "../../components/Table";
import SmallButton from "../../components/SmallButton";
import * as locationTagActions from "../../actions/locationTags";
import * as selectedActions from "../../actions/selected";
import style from "./style.module.scss";

class Locationtags extends Component {

  constructor(props) {
    super(props);
    this.state = {
      id: props.locationId,
      searchText: undefined,
      sortBy: "name",
      sortOrder: "asc",
      offset: 0,
      limit: 20,
    };

    this.fetchData = this.fetchData.bind(this);
    this.onSearchClick = this.onSearchClick.bind(this);
    this.onSearchChange = this.onSearchChange.bind(this);
    this.onRowClick = this.onRowClick.bind(this);
    this.onToggle = this.onToggle.bind(this);
  }

  componentDidMount() {
    this.fetchData();
  }

  onSearchClick() {
    this.setState({ offset: 0 }, this.fetchData);
  }

  onSearchChange(value) {
    // console.log("onSearchChange", value);
    this.setState({ searchText: value.replace("EUI64+","EUI64-") });
  }

  onRowClick(column, rowInfo) {
    return {
      onClick: e => {
        if (column.name !== "isSelected" && rowInfo !== undefined) {
          let link = `/companies/${this.props.match.params.companyId}/location-tags/${rowInfo.original._id}`;

          if (e.metaKey || e.ctrlKey) {
            window.open(`${link}`);
          }
          else {
            this.props.history.push(link);
          }
        }
      },
      style: {
        cursor: "pointer",
      }
    }
  }

  onToggle(row) {
    if (this.props.selectedLocationTags[row.original._id] !== undefined && this.props.selectedLocationTags[row.original._id]) {
      this.props.deselectLocationTag(row.original._id);
    }
    else {
      this.props.selectLocationTag(row.original);
    }
  }

  fetchData() {
    const queryParams = {
      search: this.state.searchText,
      sortBy: this.state.sortBy,
      sortOrder: this.state.sortOrder,
      offset: this.state.offset,
      limit: this.state.limit
    };

    this.props.getLocationTags(queryParams);
  }

  render() {
    // console.log("LocationTags.render()", this.props, this.state);

    const selectedLocationIds = Object.keys(this.props.selectedLocations);
    
    const newTagLink = `/companies/${this.props.match.params.companyId}/location-tags/`;
    const heading = (
      <TopRowOptions
        description="Location tags are used to group locations together when accessing floor data in maps. You need to select two or more locations to combine them into a location tag."
        searchbox={(
          <SearchBox
            value={this.state.searchText}
            onSearchChanged={this.onSearchChange}
            onSearchClick={this.onSearchClick}
            onClear={() => this.setState({ searchText: "" }, this.onSearchClick)}
            inListView
          />
        )}
        buttons={[
          <SmallButton key="b1" text="Combine into tag" link={newTagLink} disabled={!get(selectedLocationIds, "[1]", false) || !this.props.auth.hasInstallerRole} singleLine noMargin />
        ]}
      />
    );

    const anotherTypeSelected = this.props.selectedTypes.length > 0 && !this.props.selectedTypes.includes("locationTag");

    const tableElement = (
      <Table
        data={this.props.locationTags.tags}
        sortBy={this.state.sortBy}
        sortOrder={this.state.sortOrder}
        offset={this.state.offset}
        limit={this.state.limit}
        count={this.props.locationTags.count}
        onSortedChange={(newSorted) => {
          this.setState({
            sortBy: newSorted[0].id,
            sortOrder: newSorted[0].desc ? "desc" : "asc"
          }, this.fetchData);
        }}
        onOffsetChanged={(offset) => { this.setState({ offset }, this.fetchData) }}
        noDataText={this.props.isLocationTagsLoading ? "" : "No tags found"}
        columns={[
          {
            id: "id",
            header: "",
            accessorKey: "_id",
            sortable: false,
            name: "isSelected",
            cell: ({ row }) => (
              <label className="checkboxContainer" htmlFor={`editCheckbox-${row.original._id}`}>
                <input
                  id={`editCheckbox-${row.original._id}`}
                  type="checkbox"
                  className="checkbox"
                  checked={this.props.selectedLocationTags[row.original._id] !== undefined && this.props.selectedLocationTags[row.original._id]}
                  onChange={() => this.onToggle(row)}
                  disabled={anotherTypeSelected}
                />
                <span className={anotherTypeSelected ? "disabledCheckmark" : "checkmark"} />
              </label>
            ),
            width: 60
          },
          {
            header: "Name",
            accessorKey: "name",
            cell: ({ row }) => (<span title={row.original.name}>{row.original.name}</span>)
          },
          {
            header: "Description",
            accessorKey: "description",
            sortable: false,
            cell: ({ row }) => (<span title={row.original.description}>{row.original.description}</span>)
          },
          {
            id: "arrow",
            header: "",
            accessorKey: "properties",
            sortable: false,
            className: "pull-right",
            width: 60,
            cell: ({ row }) => (<div className="arrow" />)
          }
        ]}
        getTdProps={this.onRowClick}
        className="setMaxHeigth -row-clickable -highlight"
        loading={this.props.isLocationTagsLoading}
      />
    );

    return (
      <>
        <Hidden xs sm md>
          <div className={style.scroll}>
            { heading }
            { tableElement }
            <div style={{ paddingTop: "40px" }} />
          </div>
        </Hidden>
        <Visible xs sm md>
          <div className={style.slimScroll}>
            { heading }
            <div style={{ paddingTop: "40px" }} />
          </div>
        </Visible>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
    selectedCompany: state.auth.selectedCompany,
    location: state.location,
    locationTags: state.locationTags.data,
    isLocationTagsLoading: state.loading.locationTags,
    selectedLocations: state.selected.locations,
    selectedLocationTags: state.selected.locationTags,
    selectedTypes: state.selected.allTypes,
    hoveredFeature: state.selected.hoveredFeature,
    companyMap: state.auth.map,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ 
    getLocationTags: locationTagActions.getLocationTags,
    selectLocationTag: selectedActions.selectLocationTag,
    deselectLocationTag: selectedActions.deselectLocationTag,
    clearSelection: selectedActions.clearSelection
   }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(Locationtags);
