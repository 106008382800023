import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import IntersectionObserverWrapper from "../IntersectionObserverWrapper";
import style from "./style.module.scss";

class SubNavigation extends Component {

  constructor(props) {
		super(props);
    this.state = { hidden: true };
    this.menuToggle = this.menuToggle.bind(this);
	}

  menuToggle() {
    this.setState(prevState => ({ hidden: !prevState.hidden }));
  }

  render() {
    return (
      <div className={this.props.slim ? style.tabsNoMargin : style.tabs}>
        <IntersectionObserverWrapper
          key={JSON.stringify(this.props.links)}
          links={this.props.links}
          history={this.props.history}
          small={this.props.slim}
          selectedIndex={this.props.selectedIndex}
          onSelectIndex={this.props.onClick}
        />
      </div>
    );
  }
}

export default withRouter(SubNavigation);