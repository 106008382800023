import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfo } from "@fortawesome/free-solid-svg-icons";
import PropTypes from "prop-types";
import styled from "styled-components";

interface InfoProps {
  text: string;
  popupBelow?: boolean;
  popupLeft?: boolean;
  warning?: boolean;
  absolute?: boolean;
}

const Info: React.FC<InfoProps> = ({ text = "", popupBelow = false, popupLeft = false, warning = false, absolute = false }) => (
  <Container $absolute={absolute}>
    <Icon aria-label={text} $warning={warning}>
      <FontAwesomeIcon icon={faInfo} color="white" size="xs" />
    </Icon>
    <PopupContainer $directionTop={popupBelow} $directionRight={popupLeft}>
      { text }
    </PopupContainer>
  </Container>
);

export default Info;

Info.propTypes = {
  text: PropTypes.string.isRequired,
  popupBelow: PropTypes.bool,
  popupLeft: PropTypes.bool,
  warning: PropTypes.bool,
  absolute: PropTypes.bool
};

const PopupContainer = styled.div<{ $directionTop: boolean, $directionRight: boolean }>`
  position: absolute;
  opacity: 0;
  display: none;
  padding: 12px;
  width: 200px;
  top: ${props => props.$directionTop && "20px"};
  bottom: ${props => !props.$directionTop && "20px"};
  right: ${props => props.$directionRight && "20px"};
  left: ${props => !props.$directionRight && "20px"};
`;

const Container = styled.div<{ $absolute: boolean }>`
  position: ${props => props.$absolute ? "absolute" : "relative"};
  display: inline-block;
  padding-left: 5px;
  top: ${props => !props.$absolute && "-2px"};
  bottom: ${props => props.$absolute && "-10px"};
  right: ${props => props.$absolute && "-10px"};

  &:hover ${PopupContainer} {
    display: block;
    background-color: #FFFFFF;
    border-radius: 5px;
    border-width: 1px;
    border-color: #ccc;
    border-style: solid;
    opacity: 1;
    z-index: 1000;
  }
`;

const Icon = styled.div<{ $warning: boolean }>`
  position: relative;
  display: inline-flex;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  font-size: 11px;
  background-color: ${props => props.$warning ? "#c12d2a" : "#888"};
  justify-content: center;
  vertical-align: middle;

  svg {
    margin-top: 3px;
  }
`;