import { get, isEmpty } from "lodash";
import moment from "moment";
import { TimePeriod } from "./components/Dashboard/Graph";
import { getLocation } from "./locationHelpers";
import { cyrb53 } from "./helpers";

// Enum for location data errors
export const LOCATION_DATA_ERROR = {
  TOO_OLD: "Too old",
  TOO_MANY_LOCATIONS: "Too many locations",
  NO_LOCATIONS: "No suitable locations",
};

export const getDayData = (inLocation, locationIds, options, props, sensorGraphIds, coreTime) => {
  const startDate = options.date.clone().startOf("day");
  let sampleSize = options.timeScale;

  if (options.showQuarterHours) {
    sampleSize = "quarter";
  }

  const peopleAggregation = getPeopleAggregation(sampleSize);
  const aggregationAll = getAllAggregation(sampleSize);
  const aggregationAvg = getAvgAggregation(sampleSize);
  const aggregationMax = getMaxAggregation(sampleSize);
  const aggregationValue = getValueAggregation(sampleSize);
  const aggregationSum = getSumAggregation(sampleSize);
  const occupiedAggregation = getOccupiedAggregation(locationIds.length, sampleSize);

  for (var i=0; i<sensorGraphIds.length; i++) {
    const sensorGraphId = sensorGraphIds[i];
    const hash = queryHash(sensorGraphId, options, props);
    const hashKey = sensorGraphId + "-" + hash;

    // Skip if cache (hash) already exists
    if (props.dataLoadingStatus[hashKey]) {
      // console.log("SKIP");
      continue;
    }

    // Get sensorType and locationType from sensorGraphId
    const { sensorType, locationType } = getSensorGraphData(sensorGraphId);

    let filteredLocations = [];
    let filteredLocationIds = [];
    if (props.locations) {
      filteredLocations = props.locations.filter(location => location.sensorTypes.includes(sensorType));
      filteredLocationIds = filteredLocations.map(location => { return location.id ?? location._id });
    }
    else {
      filteredLocationIds = locationIds;
    }

    switch (sensorGraphId) {
      case "temperature":
      case "soundPressureLevel":
      case "brightness":
      case "carbonDioxideLevel":
      case "vocLevel":
      case "volatileOrganicCompounds":
      case "indoorAirQualityIndex":
        props.getDaySamples(inLocation, filteredLocationIds, sensorType, aggregationAll, startDate, options.showWholeDay, coreTime, hashKey);
        break;
      case "humidity":
      case "colorTemperature":
        props.getDaySamples(inLocation, filteredLocationIds, sensorType, aggregationAvg, startDate, options.showWholeDay, coreTime, hashKey);
        break;
      case "occupiedMinutes":
        props.getDaySamples(inLocation, filteredLocationIds, "occupiedMinutes", occupiedAggregation, startDate, options.showWholeDay, coreTime, hashKey);
        break;
      case "activePower":
      case "cumulativeEnergy":
        props.getDaySamples(inLocation, filteredLocationIds, sensorType, aggregationMax, startDate, options.showWholeDay, coreTime, hashKey);
        break;
      case "footfallCountIn":
      case "footfallCountOut":
      case "consumedEnergy":
        props.getDaySamples(inLocation, filteredLocationIds, sensorType, aggregationSum, startDate, options.showWholeDay, coreTime, hashKey);
        break;
      case "egress":
      case "ingress":
        props.getDaySamples(inLocation, filteredLocationIds, sensorType, aggregationValue, startDate, options.showWholeDay, coreTime, hashKey);
        break;
      case "peopleCount":
      case "peopleCountInBuildings":
      case "peopleCountInFloors":
      case "peopleCountInZones":
      case "peopleCountInRooms":
      case "peopleCountInMeetingRooms":

        let typedFilteredLocations = [];
        let typedFilteredLocationIds = [];

        // Filter out locations without the spesific sensor type
        if (props.locations && locationType) {
          typedFilteredLocations = filteredLocations.filter(location => location.type.includes(locationType));
          typedFilteredLocationIds = typedFilteredLocations.map(location => { return location.id ?? location._id });
        }
        else {
          typedFilteredLocationIds = locationIds;
        }

        // Find the capacity to compare with the people count
        let typedCapacity;
        if (props.locations) {
          // Sum up all the capacities of the filtered locations
          typedCapacity = typedFilteredLocations.reduce((acc, location) => {
            return acc + get(location, "capacity.soft", get(location, "capacity.hard", 0));
          }, 0);
          typedCapacity = typedCapacity ? typedCapacity : undefined;
        }
        else if (props.location) {
          typedCapacity = get(props.location, "geoJsonFeature.properties.capacity.soft", get(props.location, "geoJsonFeature.properties.capacity.hard", undefined));
        }
        
        props.getDaySamples(inLocation, typedFilteredLocationIds, "peopleCount", peopleAggregation, startDate, options.showWholeDay, coreTime, hashKey, typedCapacity);
  
        break;
        
      default:
        props.getDaySamples(inLocation, filteredLocationIds, sensorType, aggregationAvg, startDate, options.showWholeDay, coreTime, hashKey);
        break;
    }
  }
}

export const getWeekData = (inLocation, locationIds, options, props, sensorGraphIds, coreTime) => {
  const startDate = options.date.clone().startOf("isoWeek");
  const endDate = options.date.clone().endOf("isoWeek");
  let sampleSize = options.timeScale;

  const peopleAggregation = getPeopleAggregation(sampleSize);
  const aggregationAll = getAllAggregation(sampleSize);
  const aggregationAvg = getAvgAggregation(sampleSize);
  const aggregationMax = getMaxAggregation(sampleSize);
  const aggregationValue = getValueAggregation(sampleSize);
  const aggregationSum = getSumAggregation(sampleSize);
  const occupiedAggregation = getOccupiedAggregation(locationIds.length, sampleSize);

  for (var i=0; i<sensorGraphIds.length; i++) {
    const sensorGraphId = sensorGraphIds[i];
    const hash = queryHash(sensorGraphId, options, props);
    const hashKey = sensorGraphId + "-" + hash;

    // Skip if cache (hash) already exists
    if (props.dataLoadingStatus[hashKey]) {
      // console.log("SKIP");
      continue;
    }

    // Get sensorType and locationType from sensorGraphId
    const { sensorType, locationType } = getSensorGraphData(sensorGraphId);

    let filteredLocations = [];
    let filteredLocationIds = [];
    if (props.locations) {
      filteredLocations = props.locations.filter(location => location.sensorTypes.includes(sensorType));
      filteredLocationIds = filteredLocations.map(location => { return location.id ?? location._id });
    }
    else {
      filteredLocationIds = locationIds;
    }

    switch (sensorGraphId) {
      case "temperature":
      case "soundPressureLevel":
      case "brightness":
      case "carbonDioxideLevel":
      case "vocLevel":
      case "volatileOrganicCompounds":
      case "indoorAirQualityIndex":
        props.getWeekSamples(inLocation, filteredLocationIds, sensorType, aggregationAll, startDate, endDate, options.showWeekends, options.showHolidays, options.showWholeDay, coreTime, hashKey);
        break;
      case "humidity":
      case "colorTemperature":
        props.getWeekSamples(inLocation, filteredLocationIds, sensorType, aggregationAvg, startDate, endDate, options.showWeekends, options.showHolidays, options.showWholeDay, coreTime, hashKey);
        break;
      case "occupiedMinutes":
        props.getWeekSamples(inLocation, filteredLocationIds, "occupiedMinutes", occupiedAggregation, startDate, endDate, options.showWeekends, options.showHolidays, options.showWholeDay, coreTime, hashKey);
        break;
      case "activePower":
      case "cumulativeEnergy":
        props.getWeekSamples(inLocation, filteredLocationIds, sensorType, aggregationMax, startDate, endDate, options.showWeekends, options.showHolidays, options.showWholeDay, coreTime, hashKey);
        break;
      case "footfallCountIn":
      case "footfallCountOut":
      case "consumedEnergy":
        props.getWeekSamples(inLocation, filteredLocationIds, sensorType, aggregationSum, startDate, endDate, options.showWeekends, options.showHolidays, options.showWholeDay, coreTime, hashKey);
        break;
      case "egress":
      case "ingress":
        props.getWeekSamples(inLocation, filteredLocationIds, sensorType, aggregationValue, startDate, endDate, options.showWeekends, options.showHolidays, options.showWholeDay, coreTime, hashKey);
        break;
      case "peopleCount":
      case "peopleCountInBuildings":
      case "peopleCountInFloors":
      case "peopleCountInZones":
      case "peopleCountInRooms":
      case "peopleCountInMeetingRooms":

        let typedFilteredLocations = [];
        let typedFilteredLocationIds = [];

        // Filter out locations without the spesific sensor type
        if (props.locations && locationType) {
          typedFilteredLocations = filteredLocations.filter(location => location.type.includes(locationType));
          typedFilteredLocationIds = typedFilteredLocations.map(location => { return location.id ?? location._id });
        }
        else {
          typedFilteredLocationIds = locationIds;
        }

        // Find the capacity to compare with the people count
        let typedCapacity;
        if (props.locations) {
          // Sum up all the capacities of the filtered locations
          typedCapacity = typedFilteredLocations.reduce((acc, location) => {
            return acc + get(location, "capacity.soft", get(location, "capacity.hard", 0));
          }, 0);
          typedCapacity = typedCapacity ? typedCapacity : undefined;
        }
        else if (props.location) {
          typedCapacity = get(props.location, "geoJsonFeature.properties.capacity.soft", get(props.location, "geoJsonFeature.properties.capacity.hard", undefined));
        }
        
        props.getWeekSamples(inLocation, typedFilteredLocationIds, "peopleCount", peopleAggregation, startDate, endDate, options.showWeekends, options.showHolidays, options.showWholeDay, coreTime, hashKey, typedCapacity);

        break;

      default:
        props.getWeekSamples(inLocation, filteredLocationIds, sensorType, aggregationAvg, startDate, endDate, options.showWeekends, options.showHolidays, options.showWholeDay, coreTime, hashKey);
        break;
    }
  }
}

export const getMonthData = (inLocation, locationIds, options, props, sensorGraphIds, coreTime) => {
  const startDate = options.date.clone().startOf("month");
  const endDate = options.date.clone().endOf("month");
  let sampleSize = options.timeScale;

  const peopleAggregation = getPeopleAggregation(sampleSize);
  const aggregationAll = getAllAggregation(sampleSize);
  const aggregationAvg = getAvgAggregation(sampleSize);
  const aggregationMax = getMaxAggregation(sampleSize);
  const aggregationValue = getValueAggregation(sampleSize);
  const aggregationSum = getSumAggregation(sampleSize);
  const occupiedAggregation = getOccupiedAggregation(locationIds.length, sampleSize);

  for (var i=0; i<sensorGraphIds.length; i++) {
    const sensorGraphId = sensorGraphIds[i];
    const hash = queryHash(sensorGraphId, options, props);
    const hashKey = sensorGraphId + "-" + hash;

    // Skip if cache (hash) already exists
    if (props.dataLoadingStatus[hashKey]) {
      // console.log("SKIP");
      continue;
    }

    // Get sensorType and locationType from sensorGraphId
    const { sensorType, locationType } = getSensorGraphData(sensorGraphId);

    let filteredLocations = [];
    let filteredLocationIds = [];
    if (props.locations) {
      filteredLocations = props.locations.filter(location => location.sensorTypes.includes(sensorType));
      filteredLocationIds = filteredLocations.map(location => { return location.id ?? location._id });
    }
    else {
      filteredLocationIds = locationIds;
    }

    switch (sensorGraphId) {
      case "temperature":
      case "soundPressureLevel":
      case "brightness":
      case "carbonDioxideLevel":
      case "vocLevel":
      case "volatileOrganicCompounds":
      case "indoorAirQualityIndex":
        props.getMonthSamples(inLocation, filteredLocationIds, sensorType, aggregationAll, startDate, endDate, options.showWeekends, options.showHolidays, options.showWholeDay, coreTime, hashKey);
        break;
      case "humidity":
      case "colorTemperature":
        props.getMonthSamples(inLocation, filteredLocationIds, sensorType, aggregationAvg, startDate, endDate, options.showWeekends, options.showHolidays, options.showWholeDay, coreTime, hashKey);
        break;
      case "occupiedMinutes":
        props.getMonthSamples(inLocation, filteredLocationIds, "occupiedMinutes", occupiedAggregation, startDate, endDate, options.showWeekends, options.showHolidays, options.showWholeDay, coreTime, hashKey);
        break;
      case "activePower":
      case "cumulativeEnergy":
        props.getMonthSamples(inLocation, filteredLocationIds, sensorType, aggregationMax, startDate, endDate, options.showWeekends, options.showHolidays, options.showWholeDay, coreTime, hashKey)
        break;
      case "footfallCountIn":
      case "footfallCountOut":
      case "consumedEnergy":
        props.getMonthSamples(inLocation, filteredLocationIds, sensorType, aggregationSum, startDate, endDate, options.showWeekends, options.showHolidays, options.showWholeDay, coreTime, hashKey);
        break;
      case "egress":
      case "ingress":
        props.getMonthSamples(inLocation, filteredLocationIds, sensorType, aggregationValue, startDate, endDate, options.showWeekends, options.showHolidays, options.showWholeDay, coreTime, hashKey);
        break;
      case "peopleCount":
      case "peopleCountInBuildings":
      case "peopleCountInFloors":
      case "peopleCountInZones":
      case "peopleCountInRooms":
      case "peopleCountInMeetingRooms":

        let typedFilteredLocations = [];
        let typedFilteredLocationIds = [];

        // Filter out locations without the spesific sensor type
        if (filteredLocations && locationType) {
          typedFilteredLocations = filteredLocations.filter(location => location.type.includes(locationType));
          typedFilteredLocationIds = typedFilteredLocations.map(location => { return location.id ?? location._id });
        }
        else {
          typedFilteredLocationIds = locationIds;
        }

        // Find the capacity to compare with the people count
        let typedCapacity;
        if (props.locations) {
          // Sum up all the capacities of the filtered locations
          typedCapacity = typedFilteredLocations.reduce((acc, location) => {
            return acc + get(location, "capacity.soft", get(location, "capacity.hard", 0));
          }, 0);
          typedCapacity = typedCapacity ? typedCapacity : undefined;
        }
        else if (props.location) {
          typedCapacity = get(props.location, "geoJsonFeature.properties.capacity.soft", get(props.location, "geoJsonFeature.properties.capacity.hard", undefined));
        }
        
        props.getMonthSamples(inLocation, typedFilteredLocationIds, "peopleCount", peopleAggregation, startDate, endDate, options.showWeekends, options.showHolidays, options.showWholeDay, coreTime, hashKey, typedCapacity);

        break;

      default:
        props.getMonthSamples(inLocation, filteredLocationIds, sensorType, aggregationAvg, startDate, endDate, options.showWeekends, options.showHolidays, options.showWholeDay, coreTime, hashKey);
        break;
    }
  }
}

export const getAvgData = (inLocation, options, props, sensorGraphIds, coreTime) => {

  const startDate = moment().subtract(28, "days");
  const endDate = moment().subtract(1, "days");

  for (var i=0; i<sensorGraphIds.length; i++) {
    const sensorGraphId = sensorGraphIds[i];
    const hash = queryHash(sensorGraphId, options, props);
    const hashKey = sensorGraphId + "-" + hash;

    // Skip if cache (hash) already exists
    if (props.dataLoadingStatus[hashKey]) {
      // console.log("SKIP");
      continue;
    }

    // Get sensorType and locationType from sensorGraphId
    const { sensorType, locationType } = getSensorGraphData(sensorGraphId);

    const filteredLocations = props.locations.filter(location => location.sensorTypes.includes(sensorType));
    const filteredLocationIds = filteredLocations.map(location => { return location.id ?? location._id });

    switch (sensorGraphId) {
      case "peopleDayOfWeekInBuildings":
      case "peopleDayOfWeekInFloors":
      case "peopleDayOfWeekInZones":
      case "peopleDayOfWeekInRooms":
      case "peopleDayOfWeekInMeetingRooms":

        const peopleDayOfWeekAggregation = getPeopleDayOfWeekAggregation("day");

        let typedFilteredLocations = [];
        let typedFilteredLocationIds = [];

        // Filter out locations without the spesific sensor type
        if (filteredLocations && locationType) {
          typedFilteredLocations = filteredLocations.filter(location => location.type.includes(locationType));
          typedFilteredLocationIds = typedFilteredLocations.map(location => { return location.id ?? location._id });
        }
        else {
          typedFilteredLocationIds = filteredLocationIds;
        }

        // Sum up all the capacities of the filtered locations
        let capacity = typedFilteredLocations.reduce((acc, location) => {
          return acc + get(location, "capacity.soft", get(location, "capacity.hard", 0));
        }, 0);
        capacity = capacity ? capacity : undefined;

        props.getMonthSamples(inLocation, typedFilteredLocationIds, "peopleCount", peopleDayOfWeekAggregation, startDate, endDate, options.showWeekends, options.showHolidays, options.showWholeDay, coreTime, hashKey, capacity);
       
        break;

      case "occupiedDayOfWeek":
        const occupiedDayOfWeekAggregation = getOccupiedDayOfWeekAggregation("quarter");
        props.getMonthSamples(inLocation, filteredLocationIds, "occupiedMinutes", occupiedDayOfWeekAggregation, startDate, endDate, options.showWeekends, options.showHolidays, options.showWholeDay, coreTime, hashKey);
        break;

      case "occupiedQuarterOfDay":
        const occupiedQuarterOfDayAggregation = getOccupiedQuarterOfDayAggregation("quarter");
        props.getMonthSamples(inLocation, filteredLocationIds, "occupiedMinutes", occupiedQuarterOfDayAggregation, startDate, endDate, options.showWeekends, options.showHolidays, options.showWholeDay, coreTime, hashKey);
    
        break;

      default:
        break;
    }
  }
}

export const getListAvgData = (options, props, sensorGraphIds, coreTime) => {

  const startDate = moment().subtract(28, "days");
  const endDate = moment().subtract(1, "days");

  for (var i=0; i<sensorGraphIds.length; i++) {
    const sensorGraphId = sensorGraphIds[i];
    const hash = queryHash(sensorGraphId, options, props);
    const hashKey = sensorGraphId + "-" + hash;

    // Skip if cache (hash) already exists
    if (props.dataLoadingStatus[hashKey]) {
      // console.log("SKIP");
      continue;
    }

    // Get sensorType and locationType from sensorGraphId
    const { sensorType, locationType } = getSensorGraphData(sensorGraphId);

    // Filter out locations without occupiedMinutes or has type workstation (because of privacy)
    const filteredLocations = props.locations.filter(location => location.type !== "asset.workstation" && location.sensorTypes.includes(sensorType));
    const filteredLocationIds = filteredLocations.map(location => { return location.id ?? location._id });

    switch (sensorGraphId) {
      case "peopleCompareDayOfWeek":

        const peopleCompareDayOfWeekAggregation = getPeopleCompareDayOfWeekAggregation("day");

        let typedFilteredLocations = [];
        let typedFilteredLocationIds = [];

        // Filter out locations without the spesific sensor type
        if (filteredLocations && locationType) {
          typedFilteredLocations = filteredLocations.filter(location => location.type.includes(locationType));
          typedFilteredLocationIds = typedFilteredLocations.map(location => { return location.id ?? location._id });
        }
        else {
          typedFilteredLocationIds = filteredLocationIds;
        }

        // Sum up all the capacities of the filtered locations
        let capacity = typedFilteredLocations.reduce((acc, location) => {
          return acc + get(location, "capacity.soft", get(location, "capacity.hard", 0));
        }, 0);
        capacity = capacity ? capacity : undefined;
        props.getMonthSamples(false, typedFilteredLocationIds, "peopleCount", peopleCompareDayOfWeekAggregation, startDate, endDate, options.showWeekends, options.showHolidays, options.showWholeDay, coreTime, hashKey, capacity);
        break;

      case "occupiedCompareDayOfWeek":
        const occupiedCompareDayOfWeekAggregation = getOccupiedCompareDayOfWeekAggregation("quarter");
        props.getMonthSamples(false, filteredLocationIds, "occupiedMinutes", occupiedCompareDayOfWeekAggregation, startDate, endDate, options.showWeekends, options.showHolidays, options.showWholeDay, coreTime, hashKey);
        break;

      case "maxOccupiedDay":
        const maxOccupiedDayAggregation = getMaxOccupiedDayAggregation("quarter");
        props.getMonthSamples(false, filteredLocationIds, "occupiedMinutes", maxOccupiedDayAggregation, startDate, endDate, options.showWeekends, options.showHolidays, options.showWholeDay, coreTime, hashKey);
        break;

      default:
        break;
    }
  }
}

export const getHealthData = (options, props, sensorGraphIds) => {

  const startDate = moment().subtract(28, "days");
  const endDate = moment().subtract(1, "days");

  const healthCompareAggregation = getHealthListAggregation("month");

  for (var i=0; i<sensorGraphIds.length; i++) {
    const sensorGraphId = sensorGraphIds[i];
    const hash = queryHash(sensorGraphId, options, props);
    const hashKey = sensorGraphId + "-" + hash;

    // Skip if cache (hash) already exists
    if (props.dataLoadingStatus[hashKey]) {
      // console.log("SKIP");
      continue;
    }

    // Get sensorType and locationType from sensorGraphId
    const { sensorType } = getSensorGraphData(sensorGraphId);

    // Filter out locations without occupiedMinutes or has type workstation (because of privacy)
    const filteredLocations = props.locations.filter(location => location.sensorTypes.includes(sensorType));
    const filteredLocationIds = filteredLocations.map(location => { return location.id ?? location._id });

    switch (sensorGraphId) {
      case "temperatureCompare":
      case "vocCompare":
      case "co2Compare":
      case "humidityCompare":
        props.getMonthSamples(false, filteredLocationIds, sensorType, healthCompareAggregation, startDate, endDate, true, true, true, null, hashKey);
        break;
      // case "peopleCountCompare":
      //   break;
      default:
        break;
    }
  }
}

// Get peopleAggregation by location count and sampleSize
export const getPeopleAggregation = (sampleSize) => {
 return {
    "agg1": {
      "by": ["quarter"],
      "aggregate": [
        { "as": "sumQuarter", "operation": "sum", "property": "max" }
      ]
    },
    "agg2": {
      "by": [sampleSize],
      "aggregate": [
        { "as": "max", "operation": "max", "property": "sumQuarter" }
      ],
      "sort": { [sampleSize]: "asc" }
    }
  }
};

// Get occupiedAggregation by location count and sampleSize
export const getOccupiedAggregation = (locationCount, sampleSize) => {
  if (locationCount === 1) {
    if (sampleSize === "quarter") {
      return {
        "agg1": {
          "by": ["quarter"],
          "aggregate": [
            { "as": "total", "operation": "max", "property": "max" },
          ],
          "postprocess": { "total": "DECIMAL_TO_PERCENT" },
          "sort": { "quarter": "asc" }
        }
      };
    }
    else {
      return {
        "agg1": {
          "by": ["quarter"],
          "aggregate": [
            { "as": "avgmax", "operation": "max", "property": "max" },
          ]
        },
        "agg2": {
          "by": [sampleSize],
          "aggregate": [
            { "as": "max", "operation": "max", "property": "avgmax" },
            { "as": "avg", "operation": "avg", "property": "avgmax" }
          ],
          "postprocess": { "max": "DECIMAL_TO_PERCENT", "avg": "DECIMAL_TO_PERCENT" },
          "sort": { [sampleSize]: "asc" }
        }
      };
    }
  }

  if (sampleSize === "quarter") {
    return {
      "agg1": {
        "by": ["entityId", "quarter"],
        "aggregate": [
          { "as": "maxQuarter", "operation": "max", "property": "max" },
        ]
      },
      "agg2": {
        "by": ["quarter"],
        "aggregate": [
          { "as": "total", "operation": "avg", "property": "maxQuarter" },
        ],
        "postprocess": { "total": "DECIMAL_TO_PERCENT" },
        "sort": { "quarter": "asc" }
      }
    };
  }
  else {
    return {
      "agg1": {
        "by": ["entityId", "quarter"],
        "aggregate": [
          { "as": "maxQuarter", "operation": "max", "property": "max" },
        ]
      },
      "agg2": {
        "by": ["quarter"],
        "aggregate": [
          { "as": "avgmax", "operation": "avg", "property": "maxQuarter" },
        ]
      },
      "agg3": {
        "by": [sampleSize],
        "aggregate": [
          { "as": "max", "operation": "max", "property": "avgmax" },
          { "as": "avg", "operation": "avg", "property": "avgmax" }
        ],
        "postprocess": { "max": "DECIMAL_TO_PERCENT", "avg": "DECIMAL_TO_PERCENT" },
        "sort": { [sampleSize]: "asc" }
      }
    };
  }
}

// Get avg aggregation by sampleSize
export const getAvgAggregation = (sampleSize) => {
  return {
    "agg1": {
      "by": [sampleSize],
      "aggregate": [
        { "as": "avg", "operation": "avg", "property": "avg" },
      ],
      "sort": { [sampleSize]: "asc" }
    }
  }
}

// Get max aggregation by sampleSize
export const getMaxAggregation = (sampleSize) => {
  return {
    "agg1": {
      "by": [sampleSize],
      "aggregate": [
        { "as": "max", "operation": "max", "property": "max" },
      ],
      "sort": { [sampleSize]: "asc" }
    }
  }
}

// Get value aggregation by sampleSize
export const getValueAggregation = (sampleSize) => {
  return {
    "agg1": {
      "by": [sampleSize],
      "aggregate": [
        { "as": "count", "operation": "max", "property": "max" },
      ],
      "sort": { [sampleSize]: "asc" }
    }
  }
}

// Get sum aggregation by sampleSize
export const getSumAggregation = (sampleSize) => {
  return {
    "agg1": {
      "by": [sampleSize],
      "aggregate": [
        { "as": "sum", "operation": "sum", "property": "sum" },
      ],
      "sort": { [sampleSize]: "asc" }
    }
  }
}

// Get all aggregation by sampleSize
export const getAllAggregation = (sampleSize) => {
  return {
    "agg1": {
      "by": [sampleSize],
      "aggregate": [
        { "as": "avg", "operation": "avg", "property": "avg" },
        { "as": "max", "operation": "max", "property": "max" },
        { "as": "min", "operation": "min", "property": "min" },
      ],
      "sort": { [sampleSize]: "asc" }
    }
  }
}

// Get day of week aggregation for People by sampleSize (for getAvgData)
export const getPeopleDayOfWeekAggregation = (sampleSize) => {
  //"agg1":{"by":["quarter"],"aggregate":[{"as":"Average Minutes","operation":"avg","property":"avg"}]},
  //"agg2":{"by":["quarterOfDay"],"aggregate":[{"as":"Average occupancy","operation":"avg","property":"Average Minutes"},{"as":"Peak occupancy","operation":"max","property":"Average Minutes"}],"postprocess":{"Average occupancy":"DECIMAL_TO_PERCENT","Peak occupancy":"DECIMAL_TO_PERCENT"},"sort":{"quarterOfDay":"asc"}}}
  return {
    "agg1": {
      "by": ["entityId", sampleSize],
      "aggregate": [
        { "as": "maxCount", "operation": "max", "property": "max" },
      ]
    },
    "agg2": {
      "by": [sampleSize],
      "aggregate": [
        { "as": "sum", "operation": "sum", "property": "maxCount" },
      ]
    },
    "agg3": {
      "by": ["dayOfWeek"],
      "aggregate": [
        { "as": "avg", "operation": "avg", "property": "sum" },
        { "as": "max", "operation": "max", "property": "sum" },
      ],
      "sort": { "dayOfWeek": "asc" }
    }
  }
}

// Get day of week aggregation for Occupied by sampleSize (for getAvgData)
export const getOccupiedDayOfWeekAggregation = (sampleSize) => {
  return {
    "agg1": {
      "by": ["entityId", sampleSize],
      "aggregate": [
        { "as": "maxCount", "operation": "max", "property": "max" },
      ]
    },
    "agg2": {
      "by": [sampleSize],
      "aggregate": [
        { "as": "avgmax", "operation": "avg", "property": "maxCount" },
      ]
    },
    // "agg3": {
    //   "by": ["day"],
    //   "aggregate": [
    //     { "as": "maxday", "operation": "max", "property": "avgmax" },
    //   ]
    // },
    "agg3": {
      "by": ["dayOfWeek"],
      "aggregate": [
        { "as": "avg", "operation": "avg", "property": "avgmax" },
        { "as": "max", "operation": "max", "property": "avgmax" },
      ],
      "postprocess": { "avg": "DECIMAL_TO_PERCENT", "max": "DECIMAL_TO_PERCENT" },
      "sort": { "dayOfWeek": "asc" }
    }
  }
}

// Get day of week aggregation for Occupied by sampleSize (for getAvgData)
export const getOccupiedQuarterOfDayAggregation = (sampleSize) => {
  return {
    "agg1": {
      "by": ["entityId", sampleSize],
      "aggregate": [
        { "as": "maxCount", "operation": "max", "property": "max" },
      ]
    },
    "agg2": {
      "by": [sampleSize],
      "aggregate": [
        { "as": "avgmax", "operation": "avg", "property": "maxCount" },
      ]
    },
    "agg3": {
      "by": ["quarterOfDay"],
      "aggregate": [
        { "as": "avg", "operation": "avg", "property": "avgmax" },
        { "as": "max", "operation": "max", "property": "avgmax" },
      ],
      "postprocess": { "avg": "DECIMAL_TO_PERCENT", "max": "DECIMAL_TO_PERCENT" },
      "sort": { "quarterOfDay": "asc" }
    }
  }
}

// Get day of week aggregation for People by sampleSize (for getListAvgData)
export const getPeopleCompareDayOfWeekAggregation = (sampleSize) => {
  return {
    "agg1": {
      "by": ["entityId", "hour"],
      "aggregate": [
        { "as": "maxCount", "operation": "max", "property": "max" },
      ]
    },
    // "agg2": {
    //   "by": ["entityId", "day"],
    //   "aggregate": [
    //     { "as": "maxday", "operation": "max", "property": "maxCount" },
    //   ]
    // },
    "agg2": {
      "by": ["entityId", "dayOfWeek"],
      "aggregate": [
        { "as": "max", "operation": "max", "property": "maxCount" },
      ]
    }
  }
}

// Get day of week with entityId for Occupied by sampleSize (for getListAvgData)
export const getOccupiedCompareDayOfWeekAggregation = (sampleSize) => {
  return {
    "agg1": {
      "by": ["entityId", sampleSize],
      "aggregate": [
        { "as": "maxCount", "operation": "max", "property": "max" },
      ]
    },
    "agg2": {
      "by": ["entityId", "day"],
      "aggregate": [
        { "as": "avgmax", "operation": "avg", "property": "maxCount" },
      ],
      // "postprocess": { "avgmax": "DECIMAL_TO_PERCENT" }
    },
    "agg3": {
      "by": ["entityId", "dayOfWeek"],
      "aggregate": [
        { "as": "max", "operation": "max", "property": "avgmax" },
      ],
      "postprocess": { "max": "DECIMAL_TO_PERCENT" }
    }
  }
}

// Get max occupied for day with entityId by sampleSize (for getListAvgData)
export const getMaxOccupiedDayAggregation = (sampleSize) => {
  return {
    "agg1": {
      "by": ["entityId", sampleSize],
      "aggregate": [
        { "as": "maxCount", "operation": "max", "property": "max" },
      ]
    },
    "agg2": {
      "by": ["entityId", "day"],
      "aggregate": [
        { "as": "max", "operation": "avg", "property": "maxCount" },
      ],
      "postprocess": { "max": "DECIMAL_TO_PERCENT" },
      "sort": { "day": "asc" }
    }
  }
}

// Get health aggregation for any by sampleSize (for getHealthData)
export const getHealthListAggregation = (sampleSize) => {
  return {
    "agg1": {
      "by": ["entityId", "day"],
      "aggregate": [
        { "as": "maxCount", "operation": "max", "property": "max" },
        { "as": "minCount", "operation": "min", "property": "min" },
      ]
    },
    "agg2": {
      "by": ["entityId"],
      "aggregate": [
        { "as": "max", "operation": "max", "property": "maxCount" },
        { "as": "min", "operation": "min", "property": "minCount" },
      ]
    }
  }
}

// Get sensor types sort order
export const getSensorTypeSortOrder = (sensorType) => {
  const sensorTypeOrder = ["peopleCount", "occupiedMinutes", "temperature", "carbonDioxideLevel", "vocLevel", "volatileOrganicCompounds", "soundPressureLevel", "humidity"];
  const index = sensorTypeOrder.indexOf(sensorType);
  if (index > -1) {
    return index.toString();
  }
  return sensorType;
}

// Sort sensor types by sort order
export const sortSensorTypes = (sensorTypes) => {
  return sensorTypes.sort((a, b) => {
    const aSortOrder = getSensorTypeSortOrder(a);
    const bSortOrder = getSensorTypeSortOrder(b);
    return aSortOrder.localeCompare(bSortOrder);
  });
}

export const queryHash = (sensorGraphId, state, props) => {
  // console.log("queryHash", sensorGraphId, state, props);

  let locationIds = [];

  // Check if location or locations are passed in as a prop
  if (props.location && props.location.id) {
    locationIds = [props.location.id];
  }
  else if (props.locations && props.filterSensorTypes && props.locationHierarchy) {
    locationIds = props.locations.map(l => l._id);
  }

  // Skip update if we don't have the necessary data
  if (locationIds.length === 0) {
    return null;
  }

  let query = {
    locationIds,
    timePeriod: state.timePeriod,
    weekNumber: state.weekNumber,
    month: state.month,
    showWholeDay: state.showWholeDay,
    showWeekends: state.showWeekends,
    showHolidays: state.showHolidays,
    showQuarterHours: state.showQuarterHours,
  };

  // Only include date when the aggregation date is variable
  const noDateTypes = [
    "peopleDayOfWeekInBuildings",
    "peopleDayOfWeekInFloors",
    "peopleDayOfWeekInZones",
    "peopleDayOfWeekInRooms",
    "peopleDayOfWeekInMeetingRooms",
    "occupiedDayOfWeek",
    "occupiedQuarterOfDay",
    "peopleCompareDayOfWeek",
    "occupiedCompareDayOfWeek",
    "maxOccupiedDay"
  ];

  if (!noDateTypes.includes(sensorGraphId)) {
    
    let date = state.date.clone();
    if (state.timePeriod === "week") {
      const earliestMoment = moment().startOf("month").subtract(6, "months");
      if (date.startOf("isoWeek").isBefore(earliestMoment)) {
        date = earliestMoment;
      }
      else {
        query.date = date.startOf("isoWeek").toISOString();
      }
    }
    else if (state.timePeriod === "month") {
      query.date = date.startOf("month").toISOString();
    }
    else {
      // If too early - show yesterday
      if (!state.showWholeDay && moment().isSame(date) && date.hour() < 9) {
        // console.log("-------- FIX EARLY DATE --------");
        date = moment().startOf("day").subtract(1, "day");
      }

      query.date = date.startOf("day").toISOString();

      // If today - cache for 1 hour
      if (query.date === moment().startOf("day").toISOString()) {
        if (query.showQuarterHours) {
          const roundedDown = Math.floor(moment().minute() / 15) * 15;
          const quarterHour = moment().startOf("hour").minute(roundedDown).toISOString();
          query.date = quarterHour;
        }
        else {
          query.date = moment().startOf("hour").toISOString();
        }
      }
    }
  }

  const queryHash = JSON.stringify(query);
  // console.log(sensorGraphId, "- queryHash")//, queryHash);
  const hash = cyrb53(queryHash);
  return hash;
}

// Update dashboard if any changes and update the query hash
export const updateDashboard = (state, props, sensorGraphIds) => {

  // Skip update if we don't have the necessary data
  if (isEmpty(props.locations) || isEmpty(sensorGraphIds) || isEmpty(props.locationHierarchy)) {
    return;
  }

  const locationIds = props.locations.map(l => l._id);

  // Get core hours from locationHierarchy
  let coreTime = { start: { hours: 8, minutes: 0 }, end: { hours: 16, minutes: 0 } };
  for (var i = 0; i < props.locations.length; i++) {
    const location = props.locations[i];
    if (location.type != "region") {
      // Get core hours from parent building in locationHierarchy
      const firstLocation = getLocation({ id: "*", children: props.locationHierarchy }, location._id);
      if (firstLocation.coreTime) { 
        coreTime = firstLocation.coreTime;
        break;
      }
    }
  }

  switch (state.timePeriod) {
    case TimePeriod.Day:
      getDayData(false, locationIds, state, props, sensorGraphIds, coreTime);
      break;
    case TimePeriod.Week:
      getWeekData(false, locationIds, state, props, sensorGraphIds, coreTime);
      break;
    case TimePeriod.Month:
      getMonthData(false, locationIds, state, props, sensorGraphIds, coreTime);
      break;
    case "avg":
      getAvgData(false, state, props, sensorGraphIds, coreTime);
      break;
  
    default:
      break;
  }
}

// Update health dashboard if any changes and update the query hash
export const updateHealthDashboard = (state, props, senorGraphIds) => {

  // Skip update if we don't have the necessary data
  if (isEmpty(props.locations) || isEmpty(senorGraphIds) || isEmpty(props.locationHierarchy)) {
    return;
  }
  
  // Get health for locations list
  getHealthData(state, props, senorGraphIds);
}

// Update dashboard if any changes and update the query hash
export const updateLocationDashboard = (state, props) => {

  // Skip update if we don't have the necessary data
  if (isEmpty(props.location) || isEmpty(props.location.id) || isEmpty(props.locationHierarchy)) {
    return;
  }

  // Stop if no sensors
  if (props.location.sensorTypes.length === 0) {
    return;
  }

  // Get core hours from locationHierarchy
  let coreTime = { start: { hours: 8, minutes: 0 }, end: { hours: 16, minutes: 0 } };
  if (props.location.type != "region") {
    // Get core hours from parent building in locationHierarchy
    const firstLocation = getLocation({ id: "*", children: props.locationHierarchy }, props.location.id);
    if (firstLocation && firstLocation.coreTime) { 
      coreTime = firstLocation.coreTime;
    }
  }

  switch (state.timePeriod) {
    case TimePeriod.Day:
      getDayData(true, [props.location.id], state, props, props.location.sensorTypes, coreTime);
      break;
    case TimePeriod.Week:
      getWeekData(true, [props.location.id], state, props, props.location.sensorTypes, coreTime);
      break;
    case TimePeriod.Month:
      getMonthData(true, [props.location.id], state, props, props.location.sensorTypes, coreTime);
      break;
    default:
      break;
  }
}

export const sensorTypeIgnoreList = [
  "batteryLevel",
  "voltage",
  "totalEnergy",
  "presence",
  "previousMessageAt",
  "signalStrength",
  "connectionoptions",
  "connectionState",
  "rssi",
  "dailyIngress",
  "dailyEgress",
  "timestamp",
  "tampered",
  "open",
  "close",
  "graphBooking"
];

export const sensorGraphTypes = {
  "peopleCountInBuildings": { sensorType: "peopleCount", locationType: "building" },
  "peopleCountInFloors": { sensorType: "peopleCount", locationType: "floor" },
  "peopleCountInZones": { sensorType: "peopleCount", locationType: "zone" },
  "peopleCountInRooms": { sensorType: "peopleCount", locationType: "room" },
  "peopleCountInMeetingRooms": { sensorType: "peopleCount", locationType: "room.meeting" },
  "peopleDayOfWeek": { sensorType: "peopleCount" },
  "peopleDayOfWeekInBuildings": { sensorType: "peopleCount", locationType: "building" },
  "peopleDayOfWeekInFloors": { sensorType: "peopleCount", locationType: "floor" },
  "peopleDayOfWeekInZones": { sensorType: "peopleCount", locationType: "zone" },
  "peopleDayOfWeekInRooms": { sensorType: "peopleCount", locationType: "room" },
  "peopleDayOfWeekInMeetingRooms": { sensorType: "peopleCount", locationType: "room.meeting" },
  "occupiedDayOfWeek": { sensorType: "occupiedMinutes" },
  "occupiedQuarterOfDay": { sensorType: "occupiedMinutes" },
  "peopleCompareDayOfWeek": { sensorType: "peopleCount" },
  "occupiedCompareDayOfWeek": { sensorType: "occupiedMinutes" },
  "maxOccupiedDay": { sensorType: "occupiedMinutes" },
  "temperatureCompare": { sensorType: "temperature" },
  "vocCompare": { sensorType: "vocLevel" },
  "co2Compare": { sensorType: "carbonDioxideLevel" },
  "humidityCompare": { sensorType: "humidity" },
};

export const getSensorGraphData = (sensorGraphId) => {
  const sensorGraph = sensorGraphTypes[sensorGraphId];
  if (sensorGraph) {
    return sensorGraph;
  }
  return { sensorType: sensorGraphId };
}