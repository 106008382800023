import get from "lodash/get";
import { toast } from "react-toastify";
import * as types from "../ApiTypes";

const initialState = {
  data: {
    count: 0,
    results: [],
    limit: 100
  },
  selectedScreen: {},
  pendingDevices: {}
};

export default function screensReducer(state = initialState, action) {

  switch (action.type) {

    case types.REQ_DATA: {
      if (action.fetchType === types.GET_SCREEN_GROUPS) {
        return {...state, data: initialState.data };
      }

      if (action.fetchType === types.GET_SCREEN_GROUP_DETAILS) {
        return {...state, selectedScreen: { id: action.metadata.id } };
      }
      
      return state;
    }

    case types.RECV_DATA: {
      if (action.fetchType === types.GET_SCREEN_GROUPS) {
        return {...state, data: action.payload };
      }

      if (action.fetchType === types.GET_SCREEN_GROUP_DETAILS) {
        
        // Find and refresh in case of update
        let newData = JSON.parse(JSON.stringify(state.data));
        const index = newData.results.findIndex((group) => group.id === action.payload.id);

        if (index === -1) {
          return {...state, selectedScreen: action.payload };
        }

        // Update results
        newData.results[index] = { ...newData.results[index], ...action.payload };

        return {...state, selectedScreen: action.payload, data: newData };
      }

      if (action.fetchType === types.GET_WAITING_SCREEN_DEVICES) {

        // Transform pending devices to object with screen id as key
        const pendingDevices = {};
        action.payload.results.forEach((device) => {
          if (!pendingDevices[device.screenGroup.id]) {
            pendingDevices[device.screenGroup.id] = [];
          }
          pendingDevices[device.screenGroup.id].push(device);
        });

        return {...state, pendingDevices };
      }

      return state;
    }

    case types.RECV_ERROR: {

      const statusCode = get(action, "payload.response.status", "Error");

      if (action.fetchType === types.GET_SCREEN_GROUPS) {
        toast.error(`${statusCode}: Could not get screens`);
        return {...state, data: initialState.data };
      }

      if (action.fetchType === types.GET_SCREEN_GROUP_DETAILS) {
        toast.error(`${statusCode}: Could not get screen details`);
        return {...state, selectedScreen: {} };
      }

      return state;
    }

    case types.CLEAR_DATA: {
      return initialState;
    }
    
    default: {
      return state;
    }
  }
}
