import axios from "axios";
import Cookies from "universal-cookie";
import * as types from "../ApiTypes";
import * as actions from "./sharedFunction";
import * as selectionActions from "./selected";
import { API_URL, COOKIE_PREFIX } from "../env";
import { getCompanyId } from "../helpers";

const cookies = new Cookies();

export const getUnregisteredSensors = (queryParams) => async (dispatch) => {
  dispatch(actions.requestData(types.GET_UNREGISTERED_SENSORS));

  const apiUrl = await API_URL();
  const companyId = getCompanyId();
  const cookiePrefix = await COOKIE_PREFIX();
  const accessToken = cookies.get(`${cookiePrefix}_access_token`);

  // Remove offset if it's 0 (ask Jørgen why)
  const newQueryParams = { ...queryParams };
  if (newQueryParams.offset === 0) {
    delete newQueryParams.offset;
  }

  axios.get(`${apiUrl}companies/${companyId}/unregistered-sensors`, await actions.getAxiosConfig(newQueryParams))
    .then((response) => {
      dispatch(actions.receiveData(response.data, types.GET_UNREGISTERED_SENSORS));
    })
    .catch((error) => {
      if (error.response && error.response.status === 401) {
        actions.sessionTokenExpired(cookiePrefix, accessToken);
      } else {
        dispatch(actions.receiveError(error, types.GET_UNREGISTERED_SENSORS));
      }
    });
}

export const getUnregisteredSensor = (unregisteredSensorId) => async (dispatch) => {
  dispatch(actions.requestData(types.GET_UNREGISTERED_SENSOR));

  const apiUrl = await API_URL();
  const companyId = getCompanyId();
  const cookiePrefix = await COOKIE_PREFIX();
  const accessToken = cookies.get(`${cookiePrefix}_access_token`);

  axios.get(`${apiUrl}companies/${companyId}/unregistered-sensors/${unregisteredSensorId}`, await actions.getAxiosConfig())
    .then((response) => {
      dispatch(actions.receiveData(response.data, types.GET_UNREGISTERED_SENSOR));
    })
    .catch((error) => {
      if (error.response && error.response.status === 401) {
        actions.sessionTokenExpired(cookiePrefix, accessToken);
      } else {
        dispatch(actions.receiveError(error, types.GET_UNREGISTERED_SENSOR));
      }
    });
}

export const updateUnregisteredSensor = (unregisteredSensorId, data) => async (dispatch) => {
  dispatch(actions.requestData(types.UPDATE_UNREGISTERED_SENSOR));

  const apiUrl = await API_URL();
  const companyId = getCompanyId();
  const cookiePrefix = await COOKIE_PREFIX();
  const accessToken = cookies.get(`${cookiePrefix}_access_token`);

  axios.patch(`${apiUrl}companies/${companyId}/unregistered-sensors/${unregisteredSensorId}`, data, await actions.getAxiosConfig())
    .then((response) => {
      dispatch(actions.receiveData(response.data, types.UPDATE_UNREGISTERED_SENSOR));
      // dispatch(selectionActions.clearSelected());
    })
    .catch((error) => {
      if (error.response && error.response.status === 401) {
        actions.sessionTokenExpired(cookiePrefix, accessToken);
      } else {
        dispatch(actions.receiveError(error, types.UPDATE_UNREGISTERED_SENSOR));
      }
    });
}

export const updateUnregisteredSensors = (draft, selectedUnregisteredSensors) => async (dispatch) => {
  dispatch(actions.requestData(types.UPDATE_UNREGISTERED_SENSORS));

  const apiUrl = await API_URL();
  const companyId = getCompanyId();
  const cookiePrefix = await COOKIE_PREFIX();
  const accessToken = cookies.get(`${cookiePrefix}_access_token`);
  const config = await actions.getAxiosConfig();

  // Set locationIds instead of locationId
  const newDraft = { ...draft };
  if (newDraft.locationId) {
    newDraft.locationIds = [newDraft.locationId];
    delete newDraft.locationId;
  }

  const selectedDevicesIds = Object.keys(selectedUnregisteredSensors);
  const requests = selectedDevicesIds.map(id => axios.patch(`${apiUrl}companies/${companyId}/unregistered-sensors/${id}`, newDraft, config))

  axios.all(requests)
    .then(axios.spread((...responses) => {

      // Only update the draft that were successfully updated
      responses.forEach(response => {
        if (response.status !== 200) {
          dispatch(actions.receiveError(null, types.UPDATE_UNREGISTERED_SENSORS, { id: response.config.url.split("/").pop() }));
        }
        else {
          const id = response.config.url.split("/").pop();
          dispatch(actions.receiveData({ device: selectedUnregisteredSensors[id], draft: newDraft }, types.UPDATE_UNREGISTERED_SENSORS));
        }
      });

      // dispatch(actions.receiveData({ selectedDevices, draft: newDraft }, types.UPDATE_UNREGISTERED_SENSORS));
    }))
    .catch((error) => {
      if (error.response && error.response.status === 401) {
        actions.sessionTokenExpired(cookiePrefix, accessToken);
      } else {
        dispatch(actions.receiveError(error, types.UPDATE_UNREGISTERED_SENSORS));
      }
    });
}

export const createUnregisteredSensors = (deviceIds, history) => async (dispatch) => {
  dispatch(actions.requestData(types.CREATE_UNREGISTERED_SENSORS));

  const apiUrl = await API_URL();
  const companyId = getCompanyId();
  const cookiePrefix = await COOKIE_PREFIX();
  const accessToken = cookies.get(`${cookiePrefix}_access_token`);
  

  axios.post(`${apiUrl}companies/${companyId}/unregistered-sensors`, { deviceIds }, await actions.getAxiosConfig())
    .then((response) => {
      dispatch(actions.receiveData(response.data, types.CREATE_UNREGISTERED_SENSORS, { deviceIds }));
      dispatch(selectionActions.clearSelection());
      dispatch(getUnregisteredSensors({ search: "", limit: 100, offset: 0 }))
      if (history) {
        // Reset the view (clear url params and view state)
        history.push(`/companies/${companyId}/installation/unregistered-sensors?r`);
      }
    })
    .catch((error) => {
      if (error.response && error.response.status === 401) {
        actions.sessionTokenExpired(cookiePrefix, accessToken);
      } else {
        dispatch(actions.receiveError(error, types.CREATE_UNREGISTERED_SENSORS));
      }
    });
}

export const updateUnregisteredSensorDraft = (draft) => async (dispatch) => {
  dispatch({ type: types.UPDATE_UNREGISTERED_SENSOR_DRAFT, draft });
}

export const clearUnregisteredSensorDraft = () => async (dispatch) => {
  dispatch({ type: types.CLEAR_UNREGISTERED_SENSOR_DRAFT });
}

export const clearUnregisteredSensor = () => async (dispatch) => {
  dispatch({ type: types.CLEAR_UNREGISTERED_SENSOR });
}

export const getUnregisteredSensorSamples = (unregisteredSensorId) => async (dispatch) => {
  dispatch(actions.requestData(types.GET_UNREGISTERED_SENSOR_SAMPLES, { deviceId: unregisteredSensorId }));

  const apiUrl = await API_URL();
  const companyId = getCompanyId();
  const cookiePrefix = await COOKIE_PREFIX();
  const accessToken = cookies.get(`${cookiePrefix}_access_token`);

  axios.get(`${apiUrl}companies/${companyId}/unregistered-sensors/${unregisteredSensorId}/messages`, await actions.getAxiosConfig())
    .then((response) => {
      dispatch(actions.receiveData(response.data, types.GET_UNREGISTERED_SENSOR_SAMPLES, { deviceId: unregisteredSensorId }));
    })
    .catch((error) => {
      if (error.response && error.response.status === 401) {
        actions.sessionTokenExpired(cookiePrefix, accessToken);
      } else {
        dispatch(actions.receiveError(error, types.GET_UNREGISTERED_SENSOR_SAMPLES));
      }
    });
}