/* eslint-disable no-fallthrough */
import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import DayCell, { rangeShape } from "../DayCell";
import {
  format,
  startOfDay,
  endOfDay,
  startOfWeek,
  endOfWeek,
  isBefore,
  isSameDay,
  isAfter,
  isWeekend,
  isSunday,
  isWithinInterval,
  eachDayOfInterval,
  addDays,
} from "date-fns";
import { getMonthDisplayRange } from "../../utils";

function renderWeekdays(month, styles, dateOptions, weekdayDisplayFormat, onWeekDayClick) {
  return (
    <div className={styles.weekDays}>
      {
        eachDayOfInterval({
          start: startOfWeek(month, dateOptions),
          end: endOfWeek(month, dateOptions),
        }).map((day, i) => (
          <span className={styles.weekDay} key={i} onClick={() => onWeekDayClick(month, i)}>
            {format(day, weekdayDisplayFormat, dateOptions)}
          </span>
        ))
      }
    </div>
  );
}

class Month extends PureComponent {

  render() {
    const now = new Date();
    const { displayMode, focusedRange, drag, styles, disabledDates, disabledDay, holidayDates } = this.props;
    const minDate = this.props.minDate && startOfDay(this.props.minDate);
    const maxDate = this.props.maxDate && endOfDay(this.props.maxDate);
    const monthDisplay = getMonthDisplayRange(
      this.props.month,
      this.props.dateOptions,
      this.props.fixedHeight
    );

    let ranges = this.props.ranges;
    if (displayMode === "dateRange" && drag.status) {
      ranges.push(drag.range);
    }

    // console.log("Month", this.props);
    return (
      <div className={styles.month} style={this.props.style}>
        {this.props.showMonthName ? (
          <div className={styles.monthName} onClick={() => this.props.onMonthClick(this.props.month)}>
            {format(this.props.month, this.props.monthDisplayFormat, this.props.dateOptions)}
          </div>
        ) : null}
        {this.props.showWeekDays &&
          renderWeekdays(this.props.month, styles, this.props.dateOptions, this.props.weekdayDisplayFormat, this.props.onWeekDayClick)}
        <div className={styles.days} onMouseLeave={this.props.onMouseLeave}>
          {eachDayOfInterval({ start: monthDisplay.start, end: monthDisplay.end }).map(
            (day, index) => {
              const isStartOfMonth = isSameDay(day, monthDisplay.startDateOfMonth);
              const isEndOfMonth = isSameDay(day, monthDisplay.endDateOfMonth);
              const isOutsideMinMax =
                (minDate && isBefore(day, minDate)) || (maxDate && isAfter(day, maxDate));
              const isDisabledSpecifically = disabledDates.some(disabledDate =>
                isSameDay(disabledDate, day)
              );
              const isDisabledDay = disabledDay(day);
              const isHoliday = holidayDates.some(holiday => (
                isSameDay(holiday.date, day)
              ));

              const isDisabledHoliday = isHoliday && this.props.disableHolidays;
              const isDisabledWeekend = isWeekend(day, this.props.dateOptions) && this.props.disableWeekends;

              return (
                <DayCell
                  {...this.props}
                  ranges={ranges}
                  day={day}
                  isWeekend={isWeekend(day, this.props.dateOptions)}
                  isSunday={isSunday(day, this.props.dateOptions)}
                  isHoliday={isHoliday}
                  isToday={isSameDay(day, now)}
                  isStartOfWeek={isSameDay(day, startOfWeek(day, this.props.dateOptions))}
                  isEndOfWeek={isSameDay(day, endOfWeek(day, this.props.dateOptions))}
                  isStartOfMonth={isStartOfMonth}
                  isEndOfMonth={isEndOfMonth}
                  key={index}
                  disabled={isOutsideMinMax || isDisabledSpecifically || isDisabledDay || isDisabledHoliday || isDisabledWeekend}
                  aggregatingDisabled={isDisabledSpecifically}
                  isPassive={
                    !isWithinInterval(day, {
                      start: monthDisplay.startDateOfMonth,
                      end: monthDisplay.endDateOfMonth,
                    })
                  }
                  styles={styles}
                  onMouseDown={this.props.onDragSelectionStart}
                  onMouseUp={this.props.onDragSelectionEnd}
                  onMouseEnter={this.props.onDragSelectionMove}
                  dragRange={drag.range}
                  drag={drag.status}
                />
              );
            }
          )}
        </div>
      </div>
    );
  }
}

Month.defaultProps = {};

Month.propTypes = {
  style: PropTypes.object,
  styles: PropTypes.object,
  month: PropTypes.object,
  drag: PropTypes.object,
  dateOptions: PropTypes.object,
  disabledDates: PropTypes.array,
  disabledDay: PropTypes.func,
  disableWeekends: PropTypes.bool,
  disableHolidays: PropTypes.bool,
  holidayDates: PropTypes.array,
  displayMode: PropTypes.oneOf(["dateRange", "date"]),
  minDate: PropTypes.object,
  maxDate: PropTypes.object,
  ranges: PropTypes.arrayOf(rangeShape),
  focusedRange: PropTypes.arrayOf(PropTypes.number),
  onDragSelectionStart: PropTypes.func,
  onDragSelectionEnd: PropTypes.func,
  onDragSelectionMove: PropTypes.func,
  onMouseLeave: PropTypes.func,
  monthDisplayFormat: PropTypes.string,
  weekdayDisplayFormat: PropTypes.string,
  dayDisplayFormat: PropTypes.string,
  showWeekDays: PropTypes.bool,
  showMonthName: PropTypes.bool,
  fixedHeight: PropTypes.bool,

  // New props
  onWeekDayClick: PropTypes.func,
  onMonthClick: PropTypes.func,
};

export default Month;
