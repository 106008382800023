import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Col, Row } from "react-grid-system";
import { get, isEmpty } from "lodash";
import Tag from "../../components/Tag";
import Table from "../../components/Table";
import { ControlledDropdownSelection } from "../../components/DropdownSelection";
import { excludeChildren, fillLocationHierarchyFromQuery, getLocation, getLocationFilterTypes, includeChildren, isCustomTagIncluded } from "../../locationHelpers";
import * as selectedActions from "../../actions/selected";
import style from "./style.module.scss";

class Filters extends Component {

  constructor(props) {
    super(props);
    this.state = {
      selectedLocationIds: [],
      locationSortBy: "name",
      locationSortOrder: "asc",
      customTagSortBy: "name",
      customTagSortOrder: "asc",
      modifiedLocationHierarchy: []
    };

    this.onLocationRowClick = this.onLocationRowClick.bind(this);
    this.onBackClick = this.onBackClick.bind(this);
    this.onLocationFilterChange = this.onLocationFilterChange.bind(this);
    this.onCustomTagFilterChange = this.onCustomTagFilterChange.bind(this);
    this.onLocationColumnFilterChange = this.onLocationColumnFilterChange.bind(this);
    this.onSortedLocationsChange = this.onSortedLocationsChange.bind(this);
    this.onSortedCustomTagsChange = this.onSortedCustomTagsChange.bind(this);
  }

  componentDidMount() {
    document.title = `BLDNG.ai - Home - Filters`;
  }

  static getDerivedStateFromProps(nextProps, prevState) {

    let newState = {
      ...prevState
    };

    if (isEmpty(nextProps.locationHierarchy)) {
      return null;
    }

    const selectedQuery = get(nextProps.queries, `[${nextProps.selectedQueryIndex}]`, null);

    if (isEmpty(selectedQuery)) {
      return null;
    }

    let locations = JSON.parse(JSON.stringify(nextProps.locationHierarchy));
    let locationQueries = get(get(selectedQuery, "and", []).find(q=>q.or), "or", []);
    // console.log("locationQueries", locationQueries);
    locations = fillLocationHierarchyFromQuery(locations, locationQueries);
  
    newState.modifiedLocationHierarchy = locations;
  
    return newState;
  }

  onLocationRowClick(column, row) {
    return {
      onClick: e => {
        if (row && column.name !== "isSelected" && row.original.children.length > 0) { // column.id === "children" &&
          // this.setState({ selectedLocationId: row.original.id });
          this.setState(prevState => ({ selectedLocationIds: [...prevState.selectedLocationIds, row.original.id] }));
        }
      },
      // onMouseOver: e => {
      //   if (rowInfo && rowInfo.original._id) {
      //     this.onHover(rowInfo.original._id);
      //   }
      // },
      // onMouseLeave: e => this.onHover(null),
      style: {
        cursor: "pointer",
        // background: (
        //   row && row.original._id === this.state.highlightedId ? "rgba(0,0,0,0.05)" : (rowInfo && rowInfo.original._id === this.state.selectedUserGroup ? "rgba(0,0,0,0.05)" : null)
        // )
      }
    }
  }

  // onHover(id) {
  //   if (this.state.highlightedId !== id) {
  //     this.setState({ highlightedId: id });
  //   }
  // }

  onBackClick() {
    this.setState(prevState => {
      const selectedLocationIds = prevState.selectedLocationIds;
      selectedLocationIds.pop();
      return { selectedLocationIds };
    })
  }

  onLocationColumnFilterChange(event, locations, include) {
    const locationIds = locations.map(l => l.id);

    // Update hierarchy
    let newLocationHierarchy = JSON.parse(JSON.stringify(this.state.modifiedLocationHierarchy));
    locationIds.forEach(locationId => {
      let location = getLocation({ id: "*", children: newLocationHierarchy }, locationId);
      
      if (include) {
        location.included = true;
        location.children = includeChildren(location.children);
      }
      else {
        delete location.included;
        location.children = excludeChildren(location.children);
      }
    });

    this.props.onLocationFilterChange(this.props.selectedQueryIndex, newLocationHierarchy);

    event.stopPropagation(); 
    event.preventDefault(); 
  }

  onLocationFilterChange(event, locationId, include) {

    // Update hierarchy
    let newLocationHierarchy = JSON.parse(JSON.stringify(this.state.modifiedLocationHierarchy));
    let location = getLocation({ id: "*", children: newLocationHierarchy }, locationId);
      
    if (include) {
      location.included = true;
      location.children = includeChildren(location.children);
    }
    else {
      delete location.included;
      location.children = excludeChildren(location.children);
    }

    this.props.onLocationFilterChange(this.props.selectedQueryIndex, newLocationHierarchy);

    event.stopPropagation(); 
    event.preventDefault(); 
  }

  onCustomTagFilterChange(event, customTagId, include) {
    this.props.onCustomTagFilterChange(this.props.selectedQueryIndex, customTagId, include);

    event.stopPropagation();
    event.preventDefault(); 
  }

  onSortedCustomTagsChange(newSorted) {
    const sortBy = newSorted[0].id;
    const sortOrder = newSorted[0].desc ? "desc" : "asc";
    this.setState({ customTagSortBy: sortBy, customTagSortOrder: sortOrder });
  }

  onSortedLocationsChange(newSorted) {
    const sortBy = newSorted[0].id;
    const sortOrder = newSorted[0].desc ? "desc" : "asc";
    this.setState({ locationSortBy: sortBy, locationSortOrder: sortOrder });
  }

  render() {
    const { isLoading } = this.props;

    const selectedQuery = get(this.props.queries, `[${this.props.selectedQueryIndex}]`, null);

    if (!selectedQuery) {
      return getHelperView();
    }

    // Fetch children to view in location table
    let locations = this.state.modifiedLocationHierarchy;
    this.state.selectedLocationIds.forEach(id => {
      locations = locations.find(child => child.id === id).children;
    });

    // const topLocationIds = this.props.locationHierarchy.map(location => location.id);
    // const modifiedQueries = this.props.queries.map(q => {
    //   // If only top locations is included - hide that in filter field
    //   const topLocationFilters = q.locations.filter(l => topLocationIds.includes(l.id));
    //   const allTopLocationsIsInclude = !topLocationFilters.find(l => l.exclude);

    //   let locations = [];
    //   if (allTopLocationsIsInclude) {
    //     locations = q.locations.filter(l => !topLocationIds.includes(l.id));
    //   }
    //   else {
    //     locations = q.locations.filter(l => !topLocationIds.includes(l.id) || (topLocationIds.includes(l.id) && l.include));
    //   }

    //   // Add names
    //   locations = locations.map(location => ({ ...location, name: getLocationName({ id: "root", children: this.props.locationHierarchy }, location.id) }));

    //   // const includedQueries = 
    //   // if (q.locations.length !== topLocationIds.length) {
    //   //   locations = q.locations;
    //   // }
    //   // else if (q.locations.find(location => !topLocationIds.includes(location.id))) {
    //   //   locations = q.locations;
    //   // }
    //   // else {
    //   //   locations = [];
    //   // }
      
    //   return { locationTypes: q.locationTypes, customTags: q.customTags, locations, value: q.text }
    // });

    locations.sort((a, b) => {
      if (this.state.locationSortOrder === "asc") {
        return (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0);
      } 
      else {
        return (a.name < b.name) ? 1 : ((b.name < a.name) ? -1 : 0);
      }
    });

    // Get Location Filter Table
    const locationFilterTable = getLocationFilterTable(locations, isLoading, this.state.locationSortBy, this.state.locationSortOrder, this.onLocationRowClick, this.onLocationColumnFilterChange, this.onLocationFilterChange, this.onSortedLocationsChange);

    let customTags = JSON.parse(JSON.stringify(this.props.customTags));
    
    customTags.sort((a, b) => {
      if (this.state.customTagSortOrder === "asc") {
        return (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : ((b.name.toLowerCase() > a.name.toLowerCase()) ? -1 : 0);
      } 
      else {
        return (a.name.toLowerCase() < b.name.toLowerCase()) ? 1 : ((b.name.toLowerCase() < a.name.toLowerCase()) ? -1 : 0);
      }
    });

    // Fix sorting issue
    customTags = customTags.map(t => t);

    // Get Custom Tag Filter Table
    const customTagFilterTable = getCustomTagFilterTable(customTags, selectedQuery, isLoading, this.state.customTagSortBy, this.state.customTagSortOrder, this.onCustomTagFilterChange, this.onSortedCustomTagsChange);

    const firstLocationTypes = get(selectedQuery, "and", []).find(q => q.property === "type");

    return (
      <div className={style.listContainer}>
        <div className={style.row}>
          <div className={style.col}>
            <div className={style.scroll}>
              <div style={{ paddingTop: "40px" }} />
              <div className={style.blockTitle}>Location type</div>
              <ControlledDropdownSelection 
                name="searchType"
                options={[{ id: "none", name: "None selected" }, ...getLocationFilterTypes()]}
                value={get(firstLocationTypes, "value", "none")}
                onChange={(event) => this.props.onAddLocationTypeChange(this.props.selectedQueryIndex, event)} 
              />
              <div style={{ paddingTop: "40px" }} />
              <div className={style.blockTitle}>Locations</div>
              <div className={style.block}>
                <Row nogutter>
                  <Col>
                    { this.state.selectedLocationIds.length > 0 && 
                      (
                        <div className={style.backButton} onClick={this.onBackClick}><div className={style.backIcon} />Back</div> 
                      )
                    }
                    { locationFilterTable }
                  </Col>
                </Row>
              </div>
              <div style={{ paddingTop: "40px" }} />
              <div className={style.blockTitle}>Custom tags</div>
              <div className={style.block}>
                <Row nogutter>
                  <Col>
                    { customTagFilterTable }
                  </Col>
                </Row>
                {/* {
                  this.props.customTags.map(tag => (<Tag key={tag.id} text={tag.name} color={tag.colorTheme} />))
                } */}
              </div>
              {/* <div className={style.blockFootnote}>ALT + click (option on mac) to not include sub locations when selecting.</div> */}
              <div style={{ paddingTop: "40px" }} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    customTags: state.customTags.list,
    isLoading: state.loading.locations,
    selectedLocations: state.selected.locations,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    selectLocation: selectedActions.selectLocation,
    deselectLocation: selectedActions.deselectLocation,
    clearSelection: selectedActions.clearSelection
   }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(Filters);

function getLocationFilterTable(locations, isLoading, sortBy, sortOrder, onRowClick, onLocationColumnFilterChange, onLocationFilterChange, onSortedLocationsChange) {
  return (
    <Table
      style={{ paddingTop: 0 }}
      data={locations}
      sortBy={sortBy}
      sortOrder={sortOrder}
      onSortedChange={onSortedLocationsChange}
      noDataText={isLoading ? "" : "No locations found"}
      columns={[
        {
          header: ({data, column}) => {

            let checked = true;
            locations.forEach(location => {
              if (!location.included) {
                checked = false;
              }
            });

            return (
              <label className="checkboxContainer checkboxHeaderContainer" htmlFor={`editCheckbox-${column}-include-header`}>
                <input
                  id={`editCheckbox-${column}-include-header`}
                  type="checkbox"
                  className="checkbox"
                  checked={checked}
                  onChange={(event) => onLocationColumnFilterChange(event, locations, !checked)}
                />
                <span className={"checkmark"} onClick={(event) => onLocationColumnFilterChange(event, locations, !checked)} />
              </label>
            );
          }, 
          accessorKey: "id",
          sortable: false,
          name: "isSelected",
          cell: ({ row }) => {
            
            const checked = row.original.included;

            return (
              <label className="checkboxContainer" htmlFor={`editCheckbox-${row.original.id}-include`}>
                <input
                  id={`editCheckbox-${row.original.id}-include`}
                  type="checkbox"
                  className="checkbox"
                  checked={checked}
                  onChange={(event) => onLocationFilterChange(event, row.original.id, !checked)}
                />
                <span className={"checkmark"} onClick={(event) => onLocationFilterChange(event, row.original.id, !checked)} />
              </label>
            );
          },
          width: 60
        },
        {
          header: "Name",
          accessorKey: "name",
          minWidth: 100,
          cell: ({ row }) => (<span title={row.original.name}>{row.original.name}</span>)
        },
        {
          id: "arrow",
          header: "",
          sortable: false,
          className: "pull-right",
          width: 60,
          cell: ({ row }) => { return row.original.children.length > 0 ? <div className="arrow" /> : null }
        }
      ]}
      getTdProps={onRowClick}
      className="-row-clickable setMaxHeigth -highlight"
      loading={isLoading}
    />
  );
}

function getCustomTagFilterTable(customTags, selectedQuery, isLoading, sortBy, sortOrder, onCustomTagFilterChange, onSortedCustomTagsChange) {
  return (
    <Table
      style={{ paddingTop: 0 }}
      data={customTags}
      sortBy={sortBy}
      sortOrder={sortOrder}
      onSortedChange={onSortedCustomTagsChange}
      noDataText={isLoading ? "" : "No custom tags exist"}
      columns={[
        {
          header: null, 
          accessorKey: "id",
          sortable: false,
          name: "isSelected",
          cell: ({ row }) => {

            const checked = isCustomTagIncluded(row.original.id, selectedQuery);

            return (
              <label className="checkboxContainer" htmlFor={`editCheckbox-${row.original.id}-customtag`}>
                <input
                  id={`editCheckbox-${row.original.id}-customtag`}
                  type="checkbox"
                  className="checkbox"
                  checked={checked}
                  onChange={(event) => onCustomTagFilterChange(event, row.original.id, !checked)}
                />
                <span className={"checkmark"} onClick={(event) => onCustomTagFilterChange(event, row.original.id, !checked)} />
              </label>
            );
          },
          width: 60
        },
        {
          header: "Name",
          accessorKey: "name",
          minWidth: 100,
          //  this.props.customTags.map(tag => (<Tag key={tag.id} text={tag.name} color={tag.colorTheme} />))
          cell: ({ row }) => <Tag text={row.original.name} color={row.original.colorTheme} />
        }
      ]}
      className="setMaxHeigth"
      loading={isLoading}
    />
  );
}

function getHelperView() {
  return (
    <div className={style.listContainer}>
      <div className={style.row}>
        <div className={style.col}>
          <div className={style.scroll}>
            <div style={{ paddingTop: "40px" }} />
            <div className="helper">
              <div className="helper-text">
                <p>
                  <span>1.</span>
                  <span>Select the locations you want to include or exclude.</span>
                </p>
                <p>
                  <span>2.</span>
                  <span>Select the custom tags you want to include or exclude.</span>
                </p>
                <p>
                  <span>3.</span>
                  <span>Click the <i className="fa fa-search" /> button to search.</span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}