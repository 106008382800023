import React from "react";
import { Col, Row } from "react-grid-system";
import Button from "../Button";
import { ControlledDropdownSelection } from "../DropdownSelection";
import style from "./style.module.scss";

const DropdownModal = ({ show, onHide, title, text, selectionName, selectionLabel, selectionValue, selectionOptions, onSelectionChange, primaryBtn, secondaryBtn }) => (
  show && (
    <div className={style.wrapper}>
      <div className={style.overlay} onClick={onHide} role="presentation" />
      <div className={style.modal}>
        <h1 className={style.title}>{title}</h1>
        <p className={style.text}>{text}</p>
        <ControlledDropdownSelection 
          name={selectionName}
          label={selectionLabel}
          options={selectionOptions}
          value={selectionValue}
          onChange={onSelectionChange} 
        />
        <div style={{ paddingTop: "40px" }} />
        <Row>
          <Col xs={12} sm={8} md={6} lg={6} offset={{ sm: 2, md: 0 }}>
            {secondaryBtn && <Button color="white" {...secondaryBtn} />}
          </Col>
          <Col xs={12} sm={8} md={6} lg={6} offset={{ sm: 2, md: 0 }}>
            {primaryBtn && <Button {...primaryBtn} />}
          </Col>
        </Row>
      </div>
    </div>
  )
);

export default DropdownModal;