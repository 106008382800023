export const VortoMapping = {
  "mappingTest": "bldng.ai:dummy:1.0.0",
  "PFW.SD.PSU.E868": "vorto.private.jorgenkg:PressacFlow:1.0.0",
  "PIR.WAL.BAT.WE.E868": "vorto.private.jorgenkg:PressacDeskOccupancySensor:1.0.0",
  "PIR.180.BAT.WE.E868": "vorto.private.jorgenkg:PressacDeskOccupancySensor:1.0.0",
  "PIR.WAL.BAT.TR.E868": "vorto.private.jorgenkg:PressacDeskOccupancySensor:1.0.0", 
  "MS.DOOR.WIN.BAT.868": "vorto.private.jorgenkg:PressacDoorWindow:1.0.0",
  "60.CO2.SLR.TMP.HUM.868": "vorto.private.jorgenkg:PressacEnvironmentalSensor:1.0.0",
};

/*
  [Pressac] Flow/TOF
  PFW_SD_PSU_E868
  vorto.private.jorgenkg:PressacFlow:1.0.0

  [Pressac] Desk Occupancy Sensor
  PIR.WAL.BAT.WE.E868
  PIR.180.BAT.WE.E868
  PIR.WAL.BAT.TR.E868
  vorto.private.jorgenkg:PressacDeskOccupancySensor:1.0.0

  [Pressac] Door/Window
  MS.DOOR.WIN.BAT.868
  vorto.private.jorgenkg:PressacDoorWindow:1.0.0

  [Pressac] Environment
  60.CO2 SLR TMP HUM.868
  vorto.private.jorgenkg:PressacEnvironmentalSensor:1.0.0

  PIR WIDE ANGLE : 30S000005A233ED`1P003C10000024`30PPIR.WAL.BAT.TR.E868`2P1.2.1.1
TOF: 30s000005a23600`1p003c10000025`30ppfw?sd?psu?e868`2p1.3.0.0
*/

