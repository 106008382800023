import { get, isEmpty } from "lodash";
import moment from "moment";
import React, { Component } from "react";
import { Audio } from "react-loader-spinner";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import SegmentedControl from "../../components/SegmentedControl";
import Table from "../../components/Table";
import Tag from "../../components/Tag";
import { queryHash, updateHealthDashboard } from "../../dashboardHelpers";
import { locationFilterTypes } from "../../locationHelpers";
import * as dashboardActions from "../../actions/dashboards";
import * as selectedActions from "../../actions/selected";
import style from "./style.module.scss";
import { Role } from "../../helpers";

class Health extends Component {

  constructor(props) {
    // console.log("Locations.constructor");
    super(props);
    this.state = {
      sortBy: "name", 
      sortOrder: "asc",
      date: moment(),
      displayType: this.getInitialDisplayType(props),
    };
    this.onRowClick = this.onRowClick.bind(this);
    this.onSortedChange = this.onSortedChange.bind(this);
    this.onDisplayTypeChanged = this.onDisplayTypeChanged.bind(this);
  }

  componentDidMount() {
    document.title = `BLDNG.ai - Home - List`;
    // console.log("Home.Dashboard.componentDidMount");
    this.update();
  }
  
  componentDidUpdate(prevProps, prevState) {
    // console.log("Home.Locations.componentDidUpdate prevProps", prevProps);
    // console.log("Home.Locations.componentDidUpdate this.props", this.props);
    this.update();
  }

  update() {

    let sensorGraphIds = [];

    if (this.props.filterSensorTypes.includes("temperature")) {
      sensorGraphIds.push("temperatureCompare");
    }
    if (this.props.filterSensorTypes.includes("humidity")) {
      sensorGraphIds.push("humidityCompare");
    }
    if (this.props.filterSensorTypes.includes("carbonDioxideLevel")) {
      sensorGraphIds.push("co2Compare");
    }
    if (this.props.filterSensorTypes.includes("vocLevel")) {
      sensorGraphIds.push("vocCompare");
    }

    updateHealthDashboard(this.state, this.props, sensorGraphIds);
  }

  getInitialDisplayType(props) {
    const queryParams = new URLSearchParams(props.history.location.search);
    return queryParams.get("hdt") || "temperature";
  }

  onDisplayTypeChanged(value) {
    this.setState({ displayType: value });

    const params = new URLSearchParams(this.props.history.location.search);
    params.set("hdt", value);
    this.props.history.push({ 
      pathname: this.props.history.location.pathname.substr(0, this.props.history.location.pathname.length),
      search: params.toString()
    });
  }

  onRowClick(column, row) {
    return {
      onClick: e => {
        // console.log(row);
        if (row && column.name !== 'isSelected') {
          
          let link = `/companies/${this.props.match.params.companyId}/locations/${row.original._id}/locations/`;

          if (e.metaKey || e.ctrlKey) {
            window.open(`${link}`);
          }
          else {
            this.props.history.push(link);
          }
        }
      },
      style: {
        cursor: "pointer"
      }
    }
  }

  onToggle(row) {
    if (this.props.selectedLocations[row.original._id] !== undefined && this.props.selectedLocations[row.original._id]) {
      this.props.deselectLocation(row.original._id);
    }
    else {
      this.props.selectLocation(row.original);
    }
  }

  onToggleAll() {
    const allLocationsAreSelected = !isEmpty(this.props.locations) && this.props.locations.every(location => this.props.selectedLocations[location._id] !== undefined && this.props.selectedLocations[location._id]);
    if (allLocationsAreSelected) {
      this.props.deselectAllLocations();
    }
    else {
      this.props.selectLocations(this.props.locations);
    }
  }

  onSortedChange(newSorted) {
    const sortBy = newSorted[0].id;
    const sortOrder = newSorted[0].desc ? "desc" : "asc";
    this.setState({ sortBy, sortOrder });
  }

  getTag(id) {
    const tag = this.props.customTags.find(tag => tag.id === id);
    if (tag) {
      return <Tag key={tag.id} text={tag.name} color={tag.colorTheme} />
    }
    return null;
  }

  render() {
    // console.log("Home.Locations.props", this.props)

    let locations = this.props.locations;

    // Add properties to easier sort columns
    locations = locations.map(location => ({ ...location, typeName: get(locationFilterTypes.find(l => l.id === location.type), "name", location.type) }));
    locations = locations.map(location => {
      let tagString = "";
      if (location.customTags) {
        const norway = "nb-NO";
        tagString = location.customTags
          .map(tagId => this.props.customTags.find(tag => tag.id === tagId))
          .filter(n => n)
          .map(tag => tag.name)
          .sort((a, b) => a.localeCompare(b, norway))
          .join(", ");
      }

      return { 
        ...location, 
        tagString
      };
    });

    let sampleKey = "";
    if (this.state.displayType === "temperature") {
      sampleKey = "temperatureCompare";
    }
    else if (this.state.displayType === "humidity") {
      sampleKey = "humidityCompare";
    }
    else if (this.state.displayType === "carbonDioxideLevel") {
      sampleKey = "co2Compare";
    }
    else if (this.state.displayType === "vocLevel") {
      sampleKey = "vocCompare";
    }

    const hash = queryHash(sampleKey, this.state, this.props);
    const hashKey = `${sampleKey}-${hash}`;
    const samples = get(this.props, `dashboardData[${hashKey}]`, []);

    // Hide rows with no data
    locations = locations.filter(location => samples.find(sample => sample.entityId === location._id && sample.max && sample.min));

    if (this.state.sortBy && this.state.sortOrder) {
      locations = locations.sort((a, b) => {
        const norway = "nb-NO";
        if (this.state.sortBy === "name") {
          return this.state.sortOrder === "asc" ? a.name.localeCompare(b.name, norway) : b.name.localeCompare(a.name, norway);
        }
        else if (this.state.sortBy === "customTags") {
          // Sort by tagString with empty tags last
          if (a.tagString === "" && b.tagString !== "") {
            return this.state.sortOrder === "asc" ? 1 : -1;
          }
          else if (a.tagString !== "" && b.tagString === "") {
            return this.state.sortOrder === "asc" ? -1 : 1;
          }
          return this.state.sortOrder === "asc" ? a.tagString.localeCompare(b.tagString, norway) : b.tagString.localeCompare(a.tagString, norway);
        }
        else if (this.state.sortBy === "type") {
          return this.state.sortOrder === "asc" ? a.typeName.localeCompare(b.typeName) : b.typeName.localeCompare(a.typeName);
        }
        else if (this.state.sortBy.includes("max")) {
          const maxA = samples.find(sample => sample.entityId === a._id).max;
          const maxB = samples.find(sample => sample.entityId === b._id).max;
          return this.state.sortOrder === "asc" ? (maxA > maxB ? 1 : -1) : (maxA < maxB ? 1 : -1);
        }
        else if (this.state.sortBy.includes("min")) {
          const minA = samples.find(sample => sample.entityId === a._id).min;
          const minB = samples.find(sample => sample.entityId === b._id).min;
          return this.state.sortOrder === "asc" ? (minA > minB ? 1 : -1) : (minA < minB ? 1 : -1);
        }
        return false;
      });
    }

    const allLocationsAreSelected = !isEmpty(locations) && locations.every(location => this.props.selectedLocations[location._id] !== undefined && this.props.selectedLocations[location._id]);

    const minMaxColumns = [
      {
        header: "Min",
        accessorKey: "min",
        minWidth: 80,
        maxWidth: 120,
        cell: ({ row }) => {
          let sample = samples.find(sample => sample.entityId === row.original._id);

          let color;
          let text;
          if (this.state.displayType === "temperature") {
            color;
            text = sample ? `${Math.floor(sample.min-273.15)}` : "";
          }
          else if (this.state.displayType === "humidity") {
            color;
            text = sample ? `${Math.floor(sample.min)} %` : "";
          }
          else if (this.state.displayType === "carbonDioxideLevel") {
            color;
            text = sample ? `${Math.floor(sample.min)} ppm` : "";
          }
          else if (this.state.displayType === "vocLevel") {
            color;
            text = sample ? `${Math.floor(sample.min)} ppb` : "";
          }

          return (
            <div className={style.weekDayCell} style={{ "--tooltip-color": color }}>
              <span>{text}</span>
            </div>
          );
        }
      },
      {
        header: "Max",
        accessorKey: "max",
        minWidth: 80,
        maxWidth: 120,
        cell: ({ row }) => {
          let sample = samples.find(sample => sample.entityId === row.original._id);

          let color;
          let text;
          if (this.state.displayType === "temperature") {
            color;
            text = sample ? `${Math.floor(sample.max-273.15)}` : "";
          }
          else if (this.state.displayType === "humidity") {
            color;
            text = sample ? `${Math.floor(sample.max)} %` : "";
          }
          else if (this.state.displayType === "carbonDioxideLevel") {
            color;
            text = sample ? `${Math.floor(sample.max)} ppm` : "";
          }
          else if (this.state.displayType === "vocLevel") {
            color;
            text = sample ? `${Math.floor(sample.max)} ppb` : "";
          }

          return (
            <div className={style.weekDayCell} style={{ "--tooltip-color": color }}>
              <span>{text}</span>
            </div>
          );
        }
      }
    ];

    const tableElement = (
      <Table
        data={locations}
        sortBy={this.state.sortBy}
        sortOrder={this.state.sortOrder}
        onSortedChange={this.onSortedChange}
        noDataText={"No locations found"}
        columns={[
          {
            accessorKey: "_id",
            sortable: false,
            name: "isSelected",
            header: () => (
              <label className="checkboxContainer checkboxHeaderContainer" htmlFor={`editCheckbox-header`}>
                <input
                  id={`editCheckbox-header`}
                  type="checkbox"
                  className="checkbox"
                  checked={allLocationsAreSelected}
                  onChange={() => this.onToggleAll()}
                />
                <span className="checkmark" />
              </label>
            ),
            cell: ({ row }) => (
              <label className="checkboxContainer" htmlFor={`editCheckbox-${row.original._id}`}>
                <input
                  id={`editCheckbox-${row.original._id}`}
                  type="checkbox"
                  className="checkbox"
                  checked={(this.props.selectedLocations[row.original._id] !== undefined && this.props.selectedLocations[row.original._id])}
                  onChange={() => this.onToggle(row)}
                />
                <span className="checkmark" />
              </label>
            ),
            width: 60
          },
          {
            header: "Name",
            accessorKey: "name",
            minWidth: 100,
            maxWidth: 500,
            cell: ({ row }) => {
              let breadcrumbs = this.props.locationBreadcrumbs[row.original._id].map(breadcrumb => breadcrumb.name);
              breadcrumbs.pop();
              breadcrumbs = breadcrumbs.join(", ");
              if (breadcrumbs) {
                return (<><span title={row.original.name}>{row.original.name}</span><span title={breadcrumbs} style={{ color: "grey", marginLeft: "10px" }}> ({ breadcrumbs })</span></>)
              }
              return (<span title={row.original.name}>{row.original.name}</span>)
            }
          },
          {
            header: "Type",
            accessorKey: "type",
            minWidth: 50,
            maxWidth: 300,
            cell: ({ row }) => { 
              const locationType = locationFilterTypes.find(locationType => locationType.id === row.original.type); 
              const name = get(locationType,"name", row.original.type); 
              return <span title={name}>{name}</span>
            }
          },
          {
            header: "Tags",
            accessorKey: "customTags",
            minWidth: 100,
            cell: ({ row }) => {
              if (row["customTags"]) {
                const norway = "nb-NO";
                return row["customTags"]
                  .map(tagId => this.props.customTags.find(tag => tag.id === tagId))
                  .filter(n => n)
                  .sort((a, b) => a.name.localeCompare(b.name, norway))
                  .map(tag => <Tag key={tag.id} text={tag.name} color={tag.colorTheme} />);
              }
              else {
                return null;
              }
            }
          },
          ...minMaxColumns,
          {
            id: "arrow",
            header: "",
            sortable: false,
            className: "pull-right",
            width: 60,
            cell: ({ row }) => (<div className="arrow" />)
          }
        ]}
        getTdProps={this.onRowClick}
        className="-row-clickable setMaxHeigth -highlight"
      />
    );

    let links = [
      { label: "Dashboard", value: "dashboard" },
      { label: `Locations (${this.props.locations.length})`, value: "locations" }
    ];

    if (this.props.auth.hasSupportRole) {
      links.push({ label: "Min/max", value: "minmax" });
    }

    const viewControl = (
      <div style={{ display: "flex", justifyContent: "center", flexBasis: "26%", flexGrow: 0 }}>
        <div style={{ marginRight: "10px" }}>
          <SegmentedControl  
            options={links}
            value="minmax"
            onChange={this.props.onChangeView} 
          />
        </div>
      </div>
    );

    const displayControl = (
      <div style={{ display: "flex", gap: "10px", justifyContent: "start", flexBasis: "37%", flexGrow: 0 }}>
        <div style={{ marginRight: "10px" }}>
          <SegmentedControl
            name="displayType"
            lightBg
            value={this.state.displayType}
            onChange={this.onDisplayTypeChanged}
            options={[
              { label: "Temp", value: "temperature", default: true },
              { label: "CO2", value: "carbonDioxideLevel" },
              { label: "VOC", value: "vocLevel" },
              { label: "Humidity", value: "humidity" }
            ]}
          />
        </div>
      </div>
    );

    const heading = (
      <div style={{ display: "flex", flexWrap: "wrap", rowGap: "10px" }}>
        {displayControl}
        {viewControl}
      </div>
    );

    const errorType = get(this.props, `dataLoadingStatus[${hashKey}].type`, null);
    const errorMessage = get(this.props, `dataLoadingStatus[${hashKey}].message`, null);
    
    let title = "Min/max";
    const description = "Shows the min/max values for the last 4 weeks to make it easier to spot spikes/fallouts.";
    if (this.state.displayType === "temperature") {
      title = "Temperature";
    }
    else if (this.state.displayType === "carbonDioxideLevel") {
      title = "Carbon dioxide";
    }
    else if (this.state.displayType === "vocLevel") {
      title = "Volitile organic compounds";
    }
    else if (this.state.displayType === "humidity") {
      title = "Humidity";
    }

    let content;    
    if (!this.props.hasSearchedLocations || this.props.isLoadingFilter || this.props.isLoadingLocations) {
      content = null;
    }
    else if (errorType === "loading") {
      content = (
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", minHeight: "150px" }}>
          <Audio color="#ccc" height={"75px"} width={"75px"} />
        </div> 
      );
    }
    else if (errorMessage) {
      content = (
        <div className={style.blockInnerStatusMessage}>
          Warning: {errorMessage}
        </div>
      );
    }
    else {
      content = tableElement;
    }

    return (
      <div className={style.listContainer}>
        <div className={style.scroll}>
          <div style={{ paddingTop: "40px" }} />
          { heading }
          { content && (
            <>
              <div style={{ paddingTop: "40px" }} />
              <div className={style.block}>
                <div className={style.blockInnerTitle}>{ title }</div>
                <div className={style.blockInnerDescription}>{ description }</div>
                { content }
              </div>
            </>
          )}
          <div style={{ paddingTop: "40px" }} />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
    locations: state.filter.locations,
    locationBreadcrumbs: state.locations.breadcrumbs,
    dashboardData: state.dashboards.data,
    dataLoadingStatus: state.dashboards.dataLoadingStatus,
    customTags: state.customTags.list,
    isLoadingLocations: state.loading.locations,
    isLoadingFilter: state.loading.filter,
    hasSearchedLocations: state.filter.hasSearchedLocations,
    selectedLocations: state.selected.locations,
    filterSensorTypes: state.filter.sensorTypes,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    selectLocation: selectedActions.selectLocation,
    selectLocations: selectedActions.selectLocations,
    deselectLocation: selectedActions.deselectLocation,
    deselectAllLocations: selectedActions.deselectAllLocations,
    clearSelection: selectedActions.clearSelection,
    getMonthSamples: dashboardActions.getMonthSamples,
    storeDashboardQueryHash: dashboardActions.storeDashboardQueryHash,
   }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(Health);