import React from "react";
import { Checkbox } from "./Checkbox";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import style from "./style.module.scss";

export class TreeNode extends React.Component {
  renderChildren() {
    if (!this.props.expanded) {
      return null;
    }

    return this.props.children;
  }

  renderIcon() {
    if (this.props.isLeaf) {
      return <span style={{ paddingLeft: "20px" }}></span>;
    }

    if (!this.props.expanded) {
      return <FontAwesomeIcon className={style.expandIcon} icon={faChevronRight}/>;
    }

    return <FontAwesomeIcon className={style.expandIcon} icon={faChevronDown}/>;
  }

  render() {
    const { id, name, toggleExpand, expanded, checked, toggleChecked, treeDepth, isIntermediate } = this.props;

    return (
      <li style={{ paddingLeft: `${treeDepth === 0 ? 0 : 20}px` }} className={style.treeNodesList}>
        <span onClick={() => toggleExpand(id, !expanded)}>
          {this.renderIcon()}
        </span>

        <span onClick={() => toggleChecked(id, "checked", !checked)} className={style.treeNodeCheckbox}>
          <Checkbox 
            id={id} 
            name={name} 
            checked={checked}
            onChange={() => {}}
            isIntermediate={isIntermediate}
          />
        </span>

        <span>
          <span onClick={() => toggleExpand(id, !expanded)} className={style.locationLabel}>
            {name} ({this.props.countByLocation && this.props.countByLocation[id] ? this.props.countByLocation[id] : 0})
          </span>
        </span>

        <span>
          {this.renderChildren()}
        </span>
      </li>
    )
  }
}