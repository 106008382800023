import { get, isEmpty, isBoolean, isObject, isArray } from "lodash";
import { toast } from "react-toastify";
import { REQ_DATA, RECV_DATA, GET_ADMIN_COMPANIES, GET_SUDO_COMPANIES, RECV_ERROR, GET_SUDO_COMPANY, UPDATE_COMPANY, CREATE_COMPANY, DELETE_COMPANY, SYNC_YANZI, SUCCESS, REGISTER_COMPANY_FEATURE, ACQUIRE_COMPANY_FEATURE, UPDATE_COMPANY_DRAFT, CLEAR_COMPANY_DRAFT } from "../ApiTypes";

const initialState = {
  companies: {},
  company: {},
  draft: {},
};

export default function sudoReducer(state = initialState, action) {

  switch (action.type) {

    case REQ_DATA: {

      if (action.fetchType === GET_ADMIN_COMPANIES || action.fetchType === GET_SUDO_COMPANIES) {
        return {...state, companies: {} };
      }

      if (action.fetchType === GET_SUDO_COMPANY) {
        return {...state, company: {} };
      }
      
      return state;
    }

    case RECV_DATA: {

      if (action.fetchType === GET_ADMIN_COMPANIES || action.fetchType === GET_SUDO_COMPANIES) {
        return {...state, companies: action.payload };
      }

      if (action.fetchType === GET_SUDO_COMPANY || action.fetchType === GET_SUDO_COMPANIES) {
        const payload = action.payload;
        const company = {};
        company._id = payload._id;
        company.name = payload.name || "";
        company.yanziAccountIds = Array.isArray(payload.yanziAccountIds) ? payload.yanziAccountIds.join(",") : payload.yanziAccountIds;
        company.powerbiGroupId = payload.powerbiGroupId || "";
        company.mazemapCampusTag = payload.mazemapCampusTag || "";
        company.mazemapApiKey = payload.mazemapApiKey || "";
        company.showMapIcons = get(payload, "mapConfig.showMapIcons", false);
        company.statusFilter = get(payload, "mapConfig.statusFilter", "");
        company.labelFilter = get(payload, "mapConfig.labelFilter", "");
        company.appLabelFilter = get(payload, "mapConfig.appLabelFilter", "");
        company.iconFilter = get(payload, "mapConfig.iconFilter", "");
        company.maxAllowedBookingsByUser = payload.maxAllowedBookingsByUser || {};
        company.scimToken = get(payload, "scim.token", "");
        company.scimUrl = get(payload, "scim.url", "");
        company.applicationFeatures = get(payload, "applicationFeatures", {});
        company.dashboardFeatures = get(payload, "dashboardFeatures", {});
        company.serviceFeatures = get(payload, "serviceFeatures", {});
        company.theme = get(payload, "theme", null);
        company.screenTheme = get(payload, "screenTheme", null);
        company.hideMotionInRoomsWithCalendar = payload.hideMotionInRoomsWithCalendar || false;
        company.hideMotionInRoomsWithoutCalendar = payload.hideMotionInRoomsWithoutCalendar || false;
        company.resourceSlots = payload.resourceSlots || [];
        return {...state, company };
      }

      if (action.fetchType === UPDATE_COMPANY) {
        toast.success("Company updated");
        return {...state, draft: {}};
      }

      if (action.fetchType === CREATE_COMPANY) {
        toast.success("Company created");
        return state;
      }

      if (action.fetchType === DELETE_COMPANY) {
        toast.success("Company deleted");
        return state;
      }

      if (action.fetchType === SYNC_YANZI) {
        toast.info("Started Yanzi sync");
        return state;
      }
      
      return state;
    }

    case SUCCESS: {
      if (action.fetchType === REGISTER_COMPANY_FEATURE) {
        toast.success(`Map saved`);
      }
      
      return state;
    }

    case RECV_ERROR: {

      const statusCode = get(action, "payload.response.status", "Error");

      if (action.fetchType === GET_ADMIN_COMPANIES) {
        return {...state, companies: null };
      }

      if (action.fetchType === GET_SUDO_COMPANY) {
        return {...state, company: {} };
      }

      if (action.fetchType === UPDATE_COMPANY) {
        toast.error(`${statusCode}: Could not update company`);
        return state;
      }

      if (action.fetchType === CREATE_COMPANY) {
        toast.error(`${statusCode}: Could not create company`);
        return state;
      }

      if (action.fetchType === DELETE_COMPANY) {
        toast.error(`${statusCode}: Could not delete company`);
        return {...state };
      }

      if (action.fetchType === SYNC_YANZI) {
        toast.error(`${statusCode}: Could not sync yanzi`);
        return state;
      }

      if (action.fetchType === REGISTER_COMPANY_FEATURE) {
        toast.error(`Could not save map`);
      }

      if (action.fetchType === ACQUIRE_COMPANY_FEATURE) {
        toast.error(`Could not get map`);
      }
      
      return state;
    }

    case UPDATE_COMPANY_DRAFT: {
      const newDraft = { ...state.draft, ...action.draft };

      // console.log("action.draft", action.draft)
      // console.log("state.draft", state.draft)
      // console.log("state.company", state.company)
      
      Object.keys(newDraft).forEach(draftKey => {
        if (action.draft[draftKey] === state.company[draftKey]) {
          // Ignore value
          delete newDraft[draftKey];
        }
        else if (JSON.stringify(state.company[draftKey]) === JSON.stringify(newDraft[draftKey])) {
          // Ignore value
          delete newDraft[draftKey];
        }
        else if (!isArray(newDraft[draftKey]) && isObject(newDraft[draftKey])) {
          newDraft[draftKey] = { ...state.company[draftKey], ...state.draft[draftKey], ...action.draft[draftKey] };
        }
      });

      return {...state, draft: newDraft };
    }

    case CLEAR_COMPANY_DRAFT: {
      return {...state, draft: {}};
    }

    default:
      return state;
  }
}
