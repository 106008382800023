import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSquare as uncheckedIcon } from '@fortawesome/free-regular-svg-icons';
import { faCheckSquare as checkedIcon } from '@fortawesome/free-regular-svg-icons';
import { faMinusSquare as intermediateIcon } from '@fortawesome/free-regular-svg-icons';

export class Checkbox extends React.PureComponent {


  render() {
    const { checked, isIntermediate, styles } = this.props;

    return (
      <>
        { 
          isIntermediate ?
            <FontAwesomeIcon 
              onClick={this.collapseAll} 
              icon={intermediateIcon} 
              style={{ marginRight: "5px", ...styles }}
            />
          :
            checked ?
              <FontAwesomeIcon 
                onClick={this.collapseAll} 
                icon={checkedIcon} 
                style={{ marginRight: "5px", ...styles }}
              />
            :
              <FontAwesomeIcon 
                onClick={this.collapseAll} 
                icon={uncheckedIcon} 
                style={{ marginRight: "5px", ...styles }}
              />
        }
      </>
    )
  }
}