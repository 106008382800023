import React from "react";
import OverflowMenu from "../OverflowMenu";
import styled from "styled-components";

class IntersectionObserverWrapper extends React.Component {
  
  constructor(props) {
    // console.log("IntersectionObserverWrapper constructor");
    super(props);
    this.state = {
      visibilityMap: {},
      isLoading: true, // new state variable
    };

    this.navRef = React.createRef();

    this.handleIntersection = this.handleIntersection.bind(this);
    this.dropdownLinks = this.dropdownLinks.bind(this);
    this.isSelected = this.isSelected.bind(this);
  }

  componentDidMount() {
    // console.log("IntersectionObserverWrapper componentDidMount");
    this.observer = new IntersectionObserver(this.handleIntersection, {
      root: this.navRef.current,
      threshold: 1,
    });

    Array.from(this.navRef.current.children).forEach((item) => {
      if (item.dataset.targetid) {
        this.observer.observe(item);
      }
    });
  }

  componentWillUnmount() {
    this.observer.disconnect();
  }

  handleIntersection(entries) {
    const updatedEntries = {};
    entries.forEach((entry) => {
      const targetid = entry.target.dataset.targetid;
      // Check if element is visibile within container 
      if (entry.isIntersecting) {
        updatedEntries[targetid] = true;
      } else {
        updatedEntries[targetid] = false;
      }
    });

    // Set isLoading to false once all elements have been observed
    // Overwrite previous state values with current state
    this.setState((prev) => ({
      visibilityMap: {
        ...prev.visibilityMap,
        ...updatedEntries,
      },
      isLoading: false
    }));
  }

  dropdownLinks() {
    return this.props.links.map((link, index) => {
      return {
        ...link,
        visible: !this.state.visibilityMap[index],
      };
    });
  }

  isSelected(path, index) {
    if (this.props.history && path) {
      return this.props.history.location.pathname.includes(path);
    }
    else if (this.props.selectedIndex !== undefined) {
      return this.props.selectedIndex === index;
    }
    return false;
  }
  
  onSelect(event, path, index) {
    if (this.props.history && path) {
      if (event.metaKey || event.ctrlKey) {
        window.open(`${path}`);
      }
      else {
        this.props.history.push(path);
      }
    }
    else if (this.props.onSelectIndex) {
      this.props.onSelectIndex(index);
    }
  }

  render() {
    // console.log("IntersectionObserverWrapper props", this.props);
    // console.log("IntersectionObserverWrapper state", this.state);
    return (
      <Wrapper $topMenu={this.props.topMenu} $small={this.props.small}>
        <ToolbarWrapper ref={this.navRef}>
          {
            this.props.links.map((link, index) => (
              <MenuItem 
                key={index} 
                $visible={this.state.visibilityMap[index]}
                data-targetid={index}
                $selected={this.isSelected(link.pathname, index)} 
                onClick={(event) => this.onSelect(event, link.pathname, index)}
                $small={this.props.small}
                $topMenu={this.props.topMenu}
                $hiddenForAdmins={link.hiddenForAdmins}
                >
                {link.label}
                {link.notifications !== undefined ? <span>({link.notifications})</span> : null}
              </MenuItem>
            ))
          }
        </ToolbarWrapper>
        {
          // Conditionally render OverflowMenu based on isLoading state
          !this.state.isLoading && 
          <OverflowMenu 
            links={this.dropdownLinks()}
            history={this.props.history}
            selectedIndex={this.props.selectedIndex}
            onSelectIndex={this.props.onSelectIndex}
          />
        }
      </Wrapper>
    );
  }
}

export default IntersectionObserverWrapper;

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center; 
  width: ${props => props.$topMenu ? 'calc(100% - 270px)' : (props.$small ? '100%' : 'calc(100% - 120px)')};

  // 992px (md) media (mobile threshold)
  @media (max-width: 992px) {
    width: ${props => props.$topMenu ? 'calc(100% - 270px)' : (props.$small ? '100%' : 'calc(100% - 20px)')};
  }
`;

const ToolbarWrapper = styled.div`
  display: flex;
  overflow: hidden;
  width: 100%;

  .visible {
    order: 0;
    opacity: 1;
    flex-shrink: 0;
    background-color: #3ff;
  }

  .invisible {
    order: 100;
    opacity: 0;
    pointer-events: none;
    flex-shrink: 0;
  }
`;

const MenuItem = styled.div`
  padding: ${props => props.$small ? '15px 0px' : (props.$topMenu ? '22px 0px' : '20px 0px')};
  margin-right: 25px;
  cursor: pointer;
  font-size: 16px;
  color: ${props => props.$hiddenForAdmins ? "#666" : "#1C4D82"}; 
  text-decoration: none;
  text-align: left;
  // transition: all 0.2s ease-in-out;
  flex-shrink: 0;

  // Remove margin right if last element
  &:last-child {
    margin-right: 0px;
  }

  // Add a line to the bottom of the selected tab
  &:hover {
    box-shadow: ${props => props.$hiddenForAdmins ? "inset 0 -2px 0 0 #666" : "inset 0 -2px 0 0 #1C4D82"};
  }

  box-shadow: inset 0 -2px 0 0 ${props => props.$selected ? (props.$hiddenForAdmins ? "#666" : "#1C4D82" ) : "transparent"};

  order: ${props => props.$visible ? 0 : 100};
  opacity: ${props => props.$visible ? 1 : 0};
  pointer-events: ${props => props.$visible ? 'auto' : 'none'};

  span {
    color: #666;
    padding-left: 6px;
    padding-right: 0;
  }
`;