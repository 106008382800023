// dependencies
import React from "react";
import PropTypes from "prop-types";
import style from "./style.module.scss";

const Select = ({ value, onChange, options, ...rest }) => (
  <div className={style.select}>
    <select
      onChange={onChange}
      value={value}
      {...rest}
    >
      {options}
    </select>
  </div>
);

Select.propTypes = {
  // value: PropTypes.any.isRequired,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired
};

Select.defaultProps = {
  value: 0,
  options: []
};

export default Select;
