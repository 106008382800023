import axios from "axios";

let configData = null;
function isLocalhost() {
  return window.location.hostname === "localhost" || window.location.hostname === "localhost.dev.bldng.ai";
}

export const getConfig = () =>
  new Promise((resolve, reject) => {
    if (configData) {
      resolve(configData);
    } else {
      let configUrl = "/config";
      if (process.env.NODE_ENV === "development") {
        configUrl = "http://localhost:8081/config";
      }
      // console.log("configUrl", configUrl);
      axios
        .get(configUrl)
        .then((newConfig) => {
          // console.log("configUrl newConfig", newConfig);
          configData = newConfig.data;
          resolve(configData);
        })
        .catch((error) => {
          console.log("configUrl error", error);
          reject(error);
        });
    }
  });

  export const COOKIE_PREFIX = () =>
  getConfig()
    .then((config) => Promise.resolve(config.cookiePrefix))
    .catch((error) => Promise.reject(error));

export const IDP_URL = () =>
  getConfig()
    .then((config) => Promise.resolve(config.idpUr))
    .catch((error) => Promise.reject(error));

export const API_URL = () =>
  getConfig()
    .then((config) => Promise.resolve(`${config.apiUrl}client/`))
    .catch((error) => Promise.reject(error));

export const API_URL_DASHBOARD = () =>
  getConfig()
    .then((config) => Promise.resolve(`${config.apiUrl}dashboard/`))
    .catch((error) => Promise.reject(error));
    
export const DISPLAY_CONFIG_URL = () =>
  getConfig()
    .then((config) => Promise.resolve(`${config.apiUrl}`))
    .catch((error) => Promise.reject(error));

export const BASE_URL = () =>
  getConfig()
    .then((config) => Promise.resolve(config.baseUrl))
    .catch((error) => Promise.reject(error));

export const WEB_APP_URL = () =>
  getConfig()
    .then((config) => Promise.resolve(config.webAppUrl))
    .catch((error) => Promise.reject(error));

export const SUDO_URL = () =>
  getConfig()
    .then((config) => Promise.resolve(`${config.apiUrl}sudo/`))
    .catch((error) => Promise.reject(error));

export const ROOM_API_URL = () =>
  getConfig()
    .then((config) => Promise.resolve(`${config.roomApiUrl}`))
    .catch((error) => Promise.reject(error));

export const ROOM_URL = () =>
  getConfig()
    .then((config) => Promise.resolve(`${config.roomUrl}`))
    .catch((error) => Promise.reject(error));

export const SUPPORT_EMAIL = () =>
  getConfig()
    .then((config) => Promise.resolve(`${config.supportEmail}`))
    .catch((error) => Promise.reject(error));

export const INCIDENTS_EMAIL = () =>
  getConfig()
    .then((config) => Promise.resolve(`${config.incidentEmail}`))
    .catch((error) => Promise.reject(error));

export const WEBEX_API_URL = () =>
  getConfig()
    .then((config) => Promise.resolve(`${config.webexApiUrl}`))
    .catch((error) => Promise.reject(error));

export const WEBEX_APIS = () =>
  getConfig()
    .then((config) => Promise.resolve(`${config.webexApis}`))
    .catch((error) => Promise.reject(error));

export const DASHBOARD_URL = () =>
  getConfig()
    .then((config) => {
      if (isLocalhost()) {
        return Promise.resolve(`http://localhost:8080`);
      } else {
        return Promise.resolve(`${config.dashboardUrl}`);
      }
    })
    .catch((error) => Promise.reject(error));

export const CDN_URL = () => 
  getConfig()
    .then((config) => Promise.resolve(`${config.cdnUrl}`))
    .catch((error) => Promise.reject(error));

export const WPA_URL = () =>
  getConfig()
    .then((config) => Promise.resolve(`${config.apiUrl}wpa/`))
    .catch((error) => Promise.reject(error));


export const WS_URL = () =>
  getConfig()
    .then((config) => Promise.resolve(`${config.wsUrl}`))
    .catch((error) => Promise.reject(error));
  