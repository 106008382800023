import get from "lodash/get";
import { toast } from "react-toastify";
import * as types from "../ApiTypes";

const initialState = {
  data: [],
  count: 0,
  offset: 0,
  limit: 20
};

export default function supportUsersReducer(state = initialState, action) {

  switch (action.type) {
    
    case types.REQ_DATA: {
      if (action.fetchType === types.GET_SUPPORT_USERS) {
        return initialState;
      }
      
      return state;
    }

    case types.RECV_DATA: {
      if (action.fetchType === types.GET_SUPPORT_USERS) {
        return {
          data: action.payload.users,
          count: action.payload.count,
          limit: action.payload.limit,
          offset: action.payload.offset
        };
      }
      
      return state;
    }

    case types.RECV_ERROR: {

      const statusCode = get(action, "payload.response.status", "Error");

      if (action.fetchType === types.GET_SUPPORT_USERS) {
        toast.error(`${statusCode}: Could not get support users`);
      }
      
      return state;
    }

    case types.CLEAR_DATA: {
      return initialState;
    }

    default: {
      return state;
    }
  }
}
