import { toast } from "react-toastify";
import * as types from "../ApiTypes";

const initialState = {
  data: {
    count: 0,
    results: [],
    limit: 20
  }
};

export default function allContentReducer(state = initialState, action) {

  switch (action.type) {
    
    case types.REQ_DATA: {
      if (action.fetchType === types.GET_ALL_CONTENT) {
        return initialState;
      }
      
      return state;
    }

    case types.RECV_DATA: {
      if (action.fetchType === types.GET_ALL_CONTENT) {
        return { data: action.payload };
      }
      
      return state;
    }

    case types.RECV_ERROR: {
      if (action.fetchType === types.GET_ALL_CONTENT) {
        toast.error("Could not get content");
        return state;
      }
      
      return state;
    }

    case types.CLEAR_DATA: {
      return initialState;
    }

    default: {
      return state;
    }
  }
}
