import get from "lodash/get";
import { toast } from "react-toastify";
import * as types from "../ApiTypes";
import { Role, RoleName, getRole } from "../helpers";

const initialState = {
  id: null,
  name: null,
  email: null,
  phoneNumber: null,
  role: Role.User,
  roleName: RoleName.User,
  userGroups: [],
  managedByScim: true,
  draft: {}
};

export default function userReducer(state = initialState, action) {

  switch (action.type) {
    
    case types.REQ_DATA: {
      if (action.fetchType === types.GET_USER) {
        return initialState;
      }

      return state;
    }

    case types.RECV_DATA: {
      if (action.fetchType === types.GET_USER) {
        return {
          ...state,
          id: action.payload.id,
          name: action.payload.name,
          email: action.payload.email,
          phoneNumber: action.payload.phone_number,
          role: getRole(action.payload.role),
          roleName: action.payload.role,
          userGroups: action.payload.userGroups,
          managedByScim: action.payload.managedByScim
        };
      }
      
      if (action.fetchType === types.UPDATE_USER) {
        toast.success("User updated");
        return state;
      }

      if (action.fetchType === types.DELETE_USER) {
        toast.success("User deleted");
        return initialState;
      }

      if (action.fetchType === types.GRANT_ROLE) {
        toast.success("Access level saved");
        return {...state, draft: {} };
      }

      return state;
    }

    case types.RECV_ERROR: {

      const statusCode = get(action, "payload.response.status", "Error");

      if (action.fetchType === types.GET_USER) {
        toast.error(`${statusCode}: Could not get user`);
        return initialState;
      }

      if (action.fetchType === types.UPDATE_USER) {
        toast.error(`${statusCode}: Could not update user`);
        return state;
      }

      if (action.fetchType === types.DELETE_USER) {
        toast.error(`${statusCode}: Could not delete user`);
        return state;
      }

      if (action.fetchType === types.GRANT_ROLE) {
        toast.error(`${statusCode}: Could not save access level`);
        return state;
      }
      
      return state;
    }

    case types.DRAFT_USER_CHANGE: {
      return {...state, draft: action.payload };
    }

    case types.CLEAR_DATA, types.CLEAR_USER: {
      return initialState;
    }

    case types.CLEAR_SELECTION: {
      return {...state, draft: {} };
    }

    default: {
      return state;
    }
  }
}
