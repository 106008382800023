import { get, isEmpty } from "lodash";
import { toast } from "react-toastify";
import { sortSensorTypes, sensorTypeIgnoreList } from "../dashboardHelpers";
import * as types from "../ApiTypes";

const initialState = {
  currentLocationId: null,
  id: undefined,
  name: undefined,
  type: undefined,
  breadcrumbs: undefined,
  showInMap: undefined,
  isBookable: false,
  bookableResources: [],
  occupancyRoomTimeouts: {},
  extensionAttributes: {},
  usergroups: [],
  content: [],
  gateways: [],
  sensorTypes: [],
  locationCount: null,
  sensorCount: null,
  gatewayCount: null,
  usergroupCount: null,
  contentCount: null,
  resourceCount: null,
  closestAncestorGateways: [],
  children: [],
  parents: [],
  geoJsonFeature: undefined,
  floorMap: undefined,
  floorMapId: undefined,
  customTags: [],
  atomicSensors: [],
  squareMeters: "",
  mazemapSquareMeters: "",
};

export default function locationReducer(state = initialState, action) {

  switch (action.type) {

    case types.REQ_DATA: {

      if (action.fetchType === types.GET_LOCATION) {
        // Reset state except for floorMap and floorMapId
        return {...initialState, floorMap: state.floorMap, floorMapId: state.floorMapId, currentLocationId: action.metadata.locationId };
      }

      if (action.fetchType === types.GET_FLOOR_MAP) {
        return {...state, floorMap: undefined, floorMapId: action.metadata.id };
      }
      
      return state;
    }

    case types.RECV_DATA: {

      if (action.fetchType === types.GET_LOCATION) {
        
        let convertedClosestAncestorGateways = [];
        if (action.payload.closestAncestorGateways) {
          convertedClosestAncestorGateways = action.payload.closestAncestorGateways.map(closestAncestorGateway => ({ ...closestAncestorGateway, id: closestAncestorGateway._id }));
        }
        
        let convertedGateways = [];
        if (action.payload.gateways) {
          convertedGateways = action.payload.gateways.map(gateway => ({ ...gateway, id: gateway._id }));
        }

        // Flatten bookableCapacities for easier management
        let bookableResources = [];
        if (action.payload.bookableCapacities) {
          const resourceTypes = Object.keys(action.payload.bookableCapacities);
          bookableResources = resourceTypes.flatMap(resourceType => action.payload.bookableCapacities[resourceType].map(timeslot => ({ ...timeslot, type: resourceType })));
        }

        // Remove unused sensortypes
        let sensorTypes = action.payload.sensorTypes || [];
        if (sensorTypes.includes("footfallCountIn") && sensorTypes.includes("footfallCountOut")) {
          sensorTypes = sensorTypes.filter(type => !["egress", "ingress", "peopleCount"].includes(type));
          sensorTypes = [...sensorTypes, "peopleCount"];
        }

        // Remove unwanted sensorTypes
        sensorTypes = sensorTypes.filter(type => !sensorTypeIgnoreList.includes(type));

        // Replace motionCount with occupiedMinutes
        if (sensorTypes.includes("motionCount")) {
          sensorTypes = sensorTypes.filter(type => !["occupiedMinutes", "motionCount"].includes(type));
          sensorTypes = [...sensorTypes, "occupiedMinutes"];
        }

        // Sort sensors by type
        sensorTypes = sortSensorTypes(sensorTypes);

        return {
          ...state,
          id: action.payload._id, 
          name: action.payload.name,
          type: action.payload.type,
          breadcrumbs: action.payload.breadcrumbs,
          showInMap: get(action, "payload.showLocationStatusInMap", null),
          occupancyRoomTimeouts: get(action, "payload.occupancyRoomTimeouts", {}) || {},
          children: action.payload.children,
          usergroups: action.payload.usergroups,
          content: action.payload.applications,
          gateways: convertedGateways,
          sensorTypes,
          locationCount: get(action.payload, "children.length", 0),
          gatewayCount: get(action.payload, "gateways.length", 0),
          usergroupCount: get(action.payload, "usergroups.length", 0),
          contentCount: get(action.payload, "applications.length", 0),
          resourceCount: bookableResources.length,
          closestAncestorGateways: convertedClosestAncestorGateways,
          parents: action.payload.parents,
          geoJsonFeature: action.payload.geoJsonFeature,
          floorLevel: action.payload.floorLevel || "",
          defaultFloorId: action.payload.defaultFloorId || "",
          isBookable: action.payload.isBookable || false,
          bookableResources,
          extensionAttributes: get(action, "payload.externalExtensionAttributes", {}) || {},
          customTags: get(action.payload, "customTags", []),
          atomicSensors: get(action.payload, "atomicSensors", []),
          squareMeters: get(action.payload, "squareMeters", ""),
          mazemapSquareMeters: get(action.payload, "mazemapSquareMeters", "")
        };
      }

      if (action.fetchType === types.GET_ROOT_LOCATION) {
        return {
          ...initialState, 
          id: "root", 
          name: undefined,
          type: "root"
        };
      }

      if (action.fetchType === types.GET_FLOOR_MAP) {
        if (state.floorMapId === action.metadata.id) {
          const firstFeature = get(action.payload, "features[0]", {});
          if (!isEmpty(firstFeature) && !get(firstFeature, "properties.companyMap", false)) { // && state.floorLevel !== ""
            return {...state, floorMap: action.payload };
          }
  
          return {...state, floorMap: null };
        }
        else {
          return state;
        }
      }

      // Update sensor count
      if (action.fetchType === types.GET_SENSORS) {
        return {...state, sensorCount: action.payload.count };
      }

      // Complete map sync
      if (action.fetchType === types.SYNC_FLOOR_MAP) {
        toast.success("Map sync done");
        return state;
      }

      if (action.fetchType === types.GENERATE_QR_CODES) {
        toast.success("Generating QR codes");
        return state;
      }
      
      return state;
    }

    case types.RECV_ERROR: {

      const statusCode = get(action, "payload.response.status", "Error");

      if (action.fetchType === types.GET_LOCATION) {
        toast.error(`${statusCode}: Could not get location`);
        return { ...state, currentLocationId: null };
      }

      if (action.fetchType === types.GET_FLOOR_MAP) {
        if (state.floorMapId === action.metadata.id) {
          toast.error(`${statusCode}: Could not get floor map`);
          return {...state, floorMap: undefined };
        }
        else {
          return state;
        }
      }

      if (action.fetchType === types.EDIT_LOCATION) {
        toast.error(`${statusCode}: Could not save location`);
        return state;
      }

      if (action.fetchType === types.SYNC_FLOOR_MAP) {
        toast.error(`${statusCode}: Could not sync floor map`);
        return state;
      }

      if (action.fetchType === types.GENERATE_QR_CODES) {
        toast.error(`${statusCode}: Could generate QR codes`);
        return state;
      }
      
      return state;
    }

    case types.CLEAR_DATA: {
      return initialState;
    }

    case types.CLEAR_LOCATION: {
      return initialState;
    }

    default: {
      return state;
    }
  }
}