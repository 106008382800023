import React from "react";
import { useReactTable, getCoreRowModel, flexRender } from "@tanstack/react-table";
import classnames from "classnames";
import "./style.global.css";

const Table = (props) => {
  // console.log("Table props", props);

  const {
    className = "",
    data,
    columns,
    loading = false,
    offset = 0,
    limit = 20,
    count = 0,
    sortBy,
    sortOrder = "desc",
    hideHeaders = false,
    getTdProps,
    onOffsetChange,
    onSortedChange,
    noDataText,
    style,
    SubComponent
  } = props;
  
  const page = (offset / limit) + 1;
  const pages = Math.ceil(count / limit);

  /* If the table is expandable then add the expander column */
  if (SubComponent) {
    columns.unshift({
      header: "",
      id: "expander",
      resizable: false,
      className: "text-center",
      cell: ({ row }) => {
        return (
          // Applying the toggle expander props i.e onClick, style and title
          <div
            {...row.getToggleRowExpandedProps()}
            title="Click here to see more information"
            className="rt-td rt-expandable p-0"
          >
            <div
              className={classnames(
                "rt-expander",
                row.isExpanded ? "-open" : ""
              )}
            >
              •
            </div>
          </div>
        );
      }
    });
  }

  // For resizable columns
  const [columnWidths, setColumnWidths] = React.useState({});
  const columnRefs = React.useRef({});

  const startResizing = (columnId, initialClientX) => {
    const initialWidth = columnRefs.current[columnId].getBoundingClientRect().width;

    const handleMouseMove = (e) => {
      const delta = e.clientX - initialClientX;
      setColumnWidths(currentWidths => ({
          ...currentWidths,
          [columnId]: Math.max(initialWidth + delta, 50),
      }));
    };

    const handleMouseUp = () => {
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('mouseup', handleMouseUp);
    };

    window.addEventListener('mousemove', handleMouseMove);
    window.addEventListener('mouseup', handleMouseUp);
  };

  const tableInstance = useReactTable({
    data,
    columns,
    manualSorting: true,
    getCoreRowModel: getCoreRowModel(),
    columnResizeMode: "onChange",
  });

  const headerGroups = tableInstance.getHeaderGroups();
  const rows = tableInstance.getCoreRowModel().rows;

  return (
    <div className={classnames("ReactTable", className)} style={style}>
      <div className="rt-table">

        {/* Table header */}
        <div className="rt-thead -header">
          {
            // Looping over the header rows
            !hideHeaders && headerGroups.map((headerGroup) => (
              <div className="rt-tr" key={headerGroup.id}>
                {
                  // Looping over the headers in each row
                  headerGroup.headers.map((header) => {

                    // Add style to the header cell
                    const style = {
                      ...header.column.columnDef.style,
                      minWidth: 50 // Always set default minWidth to 50
                    };
                  
                    let resizable = true;
                    let tableHeaderClass = "rt-th"; 
                    let resizerClass = "rt-resizer";

                    // Check if only width is defined -> means the column is not resizable
                    if (header.column.columnDef.width && !header.column.columnDef.minWidth && !header.column.columnDef.maxWidth) {
                      resizable = false;
                      style.width = header.column.columnDef.width;
                      style.minWidth = header.column.columnDef.width;
                      style.maxWidth = header.column.columnDef.width;
                    }

                    // Checking if column is resizable
                    if (resizable) {
                      tableHeaderClass = classnames(tableHeaderClass, "rt-resizable-header");
                      resizerClass = classnames(resizerClass, header.column.getIsResizing() ? "isResizing" : "");

                      if (columnWidths[header.column.id]) {
                        style.width = columnWidths[header.column.id];
                        style.maxWidth = columnWidths[header.column.id];
                        style.flex = `0 0 auto`;

                        if (header.column.columnDef.minWidth && columnWidths[header.column.id] < header.column.columnDef.minWidth) {
                          style.width = header.column.columnDef.minWidth;
                          style.maxWidth = header.column.columnDef.minWidth;
                          style.flex = `0 0 auto`;
                        }

                        if (header.column.columnDef.maxWidth && columnWidths[header.column.id] > header.column.columnDef.maxWidth) {
                          style.width = header.column.columnDef.maxWidth;
                          style.maxWidth = header.column.columnDef.maxWidth;
                          style.flex = `0 0 auto`;
                        }
                      }
                      else {
                        style.width = header.column.columnDef.width;
                        style.maxWidth = header.column.columnDef.width;
                        style.flex = `${header.column.columnDef.width} 0 auto`;

                        if (header.column.columnDef.minWidth) {
                          style.minWidth = header.column.columnDef.minWidth;
                        }

                        if (header.column.columnDef.maxWidth) {
                          style.maxWidth = header.column.columnDef.maxWidth;
                        }
                      }
                    }
                   
                    
                    // Checking if column is sortable
                    const sortable = header.column.columnDef.sortable !== false;
                    let onSort = null;
                    if (sortable) {
                    
                      tableHeaderClass = classnames(tableHeaderClass, "-cursor-pointer");

                      if (header.column.id === sortBy) {
                        tableHeaderClass = classnames(tableHeaderClass, sortOrder === "desc" ? "-sort-desc" : "-sort-asc");
                      }

                      // Add onSort function if onSortedChange is passed
                      if (onSortedChange) {
                        onSort = () => {
                          const newSorting = [
                            {
                              id: header.column.id,
                              desc: header.column.id === sortBy ? sortOrder !== "desc" : false
                            }
                          ];
                          onSortedChange(newSorting);
                        }
                      }
                    }
                    
                    return resizable ? (
                      <div className={tableHeaderClass} key={header.column.id} style={style}>
                        <div className="rt-resizable-header-content" onClick={onSort} ref={el => columnRefs.current[header.column.id] = el}>
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                        </div>
                        <div className={resizerClass} onMouseDown={(e) => startResizing(header.column.id, e.clientX)} onTouchStart={(e) => startResizing(header.column.id, e.clientX)} />
                      </div>
                    ) : (
                      <div className={tableHeaderClass} colSpan={header.colSpan} key={header.column.id} style={style} onClick={onSort}>
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                      </div>
                    );
                  })}
              </div>
            ))}
        </div>

        {/* Table body */}
        <div className="rt-tbody">
          { 
            // Looping over the table rows
            rows.map((row, index) => {
              // console.log("row", row, index);
              return (
                <React.Fragment key={row.id}>
                  <div className="rt-tr-group">
                    <div className={classnames("rt-tr", index % 2 === 0 ? "-odd" : "-even")}>
                      { 
                        // Looping over the row cells
                        row.getAllCells().map((cell) => {
                          // console.log("cell", cell);

                          const cellProps = getTdProps ? getTdProps(cell.column.columnDef, cell.row) : {};

                          const style = {
                            ...cellProps.style,
                            ...cell.column.columnDef.style,
                            minWidth: 50 // Always set default minWidth to 50
                          };

                          let resizable = true;
                          
                          // Check if only width is defined -> means the column is not resizable
                          if (cell.column.columnDef.width && !cell.column.columnDef.minWidth && !cell.column.columnDef.maxWidth) {
                            resizable = false;
                            style.width = cell.column.columnDef.width;
                            style.minWidth = cell.column.columnDef.width;
                            style.maxWidth = cell.column.columnDef.width;
                          }

                          if (resizable) {
                            if (columnWidths[cell.column.id]) {
                              style.width = columnWidths[cell.column.id];
                              style.maxWidth = columnWidths[cell.column.id];
                              style.flex = `0 0 auto`;
                              
                              if (cell.column.columnDef.minWidth && columnWidths[cell.column.id] < cell.column.columnDef.minWidth) {
                                style.width = cell.column.columnDef.minWidth;
                                style.maxWidth = cell.column.columnDef.minWidth;
                                style.flex = `0 0 auto`;
                              }
  
                              if (cell.column.columnDef.maxWidth && columnWidths[cell.column.id] > cell.column.columnDef.maxWidth) {
                                style.width = cell.column.columnDef.maxWidth;
                                style.maxWidth = cell.column.columnDef.maxWidth;
                                style.flex = `0 0 auto`;
                              }
                            } 
                            else {
                              style.width = cell.column.columnDef.width;
                              style.maxWidth = cell.column.columnDef.width;
                              style.flex = `${cell.column.columnDef.width} 0 auto`;
  
                              if (cell.column.columnDef.minWidth) {
                                style.minWidth = cell.column.columnDef.minWidth;
                              }
    
                              if (cell.column.columnDef.maxWidth) {
                                style.maxWidth = cell.column.columnDef.maxWidth;
                              }
                            }
                          }
                          
                          return (
                            // Applying the cell props and rendering the cell content
                            <div {...cellProps} className={classnames("rt-td", cell.column.columnDef.className)} style={style} key={cell.column.id + index}>
                              {
                                flexRender(
                                  cell.column.columnDef.cell,
                                  cell.getContext()
                                )
                              }
                            </div>
                          );
                        })}
                    </div>
                    {/* Checking row expansion */}
                    {row.isExpanded ? SubComponent({ row }) : null}
                  </div>
                </React.Fragment>
              );
            })}

            {/* No data text & loading */}
            {
              rows.length === 0 && (
                <div className="rt-tr-group-noData">
                  <div className="rt-tr-noData">
                    <div className="rt-td-noData">
                      <span className="rt-noData">
                        {loading ? "Loading..." : (noDataText ? noDataText : "No data available")}
                      </span>
                    </div>
                  </div>
                </div>
              )
            }
        </div>
      </div>

      {/* Pagination */}
      { pages > 1 && (
        <div className="pagination-bottom">
          <div className="-pagination">
            <div className="-previous">
              <button type="button" disabled={page === 1} className="-btn" onClick={() => onOffsetChange(offset - limit)}>Previous</button>
            </div>
            <div className="-center">
              <span className="-pageInfo">page
                <div className="-pageJump">
                  <input
                    aria-label="jump to page"
                    type="number"
                    value={page}
                    onChange={(e) => {
                      const newValue = e.target.value;
                      if (newValue > 0 && newValue <= pages) {
                        onOffsetChange((e.target.value - 1) * limit);
                      }
                    }}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        const newValue = e.target.value;
                        if (newValue > 0 && newValue <= pages) {
                          onOffsetChange((e.target.value - 1) * limit);
                        }
                      }
                    }}
                  />
                </div>of <span className="-totalPages">{pages}</span>
              </span>
            </div>
            <div className="-next">
              <button type="button" disabled={page === pages} className="-btn" onClick={() => onOffsetChange(offset + limit)}>Next</button>
            </div>
          </div>
        </div>
      ) }

    </div>
  );
};

export default Table;
