import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Switch, Route } from "react-router-dom";
import isEmpty from "lodash/isEmpty";
import Table from "../../components/Table";
import DetailsHeader from "../../components/DetailsHeader";
import SubNavigation from "../../components/SubNavigation";
import ScreenDetailsPending from "./screenDetailsPending";
import ScreenDetailsInfo from "./screenDetailsInfo";
import * as screenActions from "../../actions/screens";

class ScreenDetails extends Component {

  constructor(props) {
    super(props);
    this.onEditScreenClicked = this.onEditScreenClicked.bind(this);
    this.onUpdateScreenName = this.onUpdateScreenName.bind(this);
    this.onDeleteScreen = this.onDeleteScreen.bind(this);
  }

  componentDidMount() {
    if (isEmpty(this.props.screenGroupId)) {
      this.props.clearScreenData();
    }
    else if (this.props.screen.id !== this.props.screenGroupId) {
      this.props.getScreenGroup(this.props.screenGroupId);
    }
  }

  componentDidUpdate(prevProps) {
    // Only load data if id change
    if (isEmpty(this.props.screenGroupId)) {
      this.props.clearScreenData();
    }
    else if (this.props.screen.id !== this.props.screenGroupId) {
      this.props.getScreenGroup(this.props.screenGroupId);
    }
  }

  onEditScreenClicked(event) {
    const link = `/companies/${this.props.match.params.companyId}/screens/edit/${this.props.screenGroupId}`;

    if (event.metaKey || event.ctrlKey) {
      window.open(`${link}`);
    }
    else {
      this.props.history.push(link);
    }
  }

  onUpdateScreenName(name) {
    this.props.updateScreenGroup(this.props.screen.id, { name }, () => {
      this.props.getScreenGroup(this.props.screenGroupId);
    });
  }

  onDeleteScreen() {
    this.props.deleteScreenGroup(this.props.screen.id, this.props.history.push);
  }

  render() {

    if (isEmpty(this.props.screenGroupId) || isEmpty(this.props.screen.name)) {
      return null;
    }

    const hasAccess = this.props.auth.hasITAdminRole;

    const subNavigationLinks = [];
    subNavigationLinks.push({ pathname: `/companies/${this.props.match.params.companyId}/screens/${this.props.screenGroupId}/details`, label: `Details` });

    if (this.props.screen.isPrivate) {
      subNavigationLinks.push({ pathname: `/companies/${this.props.match.params.companyId}/screens/${this.props.screenGroupId}/pending`, label: `Pending devices` });
    }

    return (
      <div>
        <DetailsHeader
          title={this.props.screen.name}
          canUseCustomButton={hasAccess}
          canUpdateName={hasAccess}
          canDelete={hasAccess}
          isLoading={this.props.isLoading}
          customButtonText="Inspect"
          onCustomButtonClicked={this.onEditScreenClicked}
          onNameSaveClicked={this.onUpdateScreenName}
          onDeleteClicked={this.onDeleteScreen}
        />
        <SubNavigation links={subNavigationLinks} slim />
        <Switch>
          <Route path="/companies/:companyId/screens/:screenGroupId/details" children={(props) => <ScreenDetailsInfo {...props} screenGroupId={this.props.screenGroupId} />} />
          <Route path="/companies/:companyId/screens/:screenGroupId/pending" children={(props) => <ScreenDetailsPending {...props} screenGroupId={this.props.screenGroupId} />} />
        </Switch>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
    screen: state.screen,
    pendingDevices: state.screens.pendingDevices,
    isLoading: state.loading.screens,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    getScreenGroup: screenActions.getScreenGroup,
    updateScreenGroup: screenActions.updateScreenGroup,
    updateScreenGroupForm: screenActions.updateScreenGroupForm,
    deleteScreenGroup: screenActions.deleteScreenGroup,
    clearScreenData: screenActions.clearScreenData
  }, dispatch);
}

export default connect(mapStateToProps,mapDispatchToProps)(ScreenDetails);