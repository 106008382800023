import get from "lodash/get";
import set from "lodash/set";
import { toast } from "react-toastify";
import * as types from "../ApiTypes";

const initialState = {
  id: null,
  name: null,
  description: null,
  layout: {},
  form: {},

  locations: {},
  maps: {}
};

export default function screenViewReducer(state = initialState, action) {

  switch (action.type) {

    case types.REQ_DATA: {
      if (action.fetchType === types.GET_SCREEN_VIEW) {
        return { ...initialState, id: action.metadata.id };
      }
      
      return state;
    }

    case types.RECV_DATA: {
      if (action.fetchType === types.GET_SCREEN_VIEW) {

        let newState = {
          ...state,
          id: action.payload.id,
          name: get(action.payload, "name", ""),
          description: get(action.payload, "description", undefined),
          layout: get(action.payload, "layout", {}),
        };

        // Keep form object if it is the same screen view
        if (action.payload.id === state.form.id) {
          return newState;
        }

        newState.form = {
          id: get(action.payload, "id", ""),
          name: get(action.payload, "name", ""),
          description: get(action.payload, "description", undefined),
          layout: get(action.payload, "layout", {}),
        };
          
        return newState;
      }

      if (action.fetchType === types.CREATE_SCREEN_VIEW) {
        toast.success("Screen view created");
        return state;
      }

      if (action.fetchType === types.UPDATE_SCREEN_VIEW_FORM) {

        let form = JSON.parse(JSON.stringify(state.form));
        if (action.metadata.layoutPath) {
          // Update the layout component
          set(form.layout, action.metadata.layoutPath, action.payload);
        }
        else {
          // Update the layout
          form = action.payload;
        }

        return {...state, form };
      }

      if (action.fetchType === types.UPDATE_SCREEN_VIEW) {
        toast.success("Screen view updated");
        return state;
      }

      if (action.fetchType === types.DELETE_SCREEN_VIEW) {
        toast.success("Screen view deleted");
        return initialState;
      }

      if (action.fetchType === types.GET_SCREEN_LOCATION) {
        return {
          ...state,
          locations: { ...state.locations, [action.metadata.id]: action.payload }
        };
      }

      if (action.fetchType === types.GET_SCREEN_FLOOR_MAP) {
        return {
          ...state,
          maps: { ...state.maps, [action.metadata.id]: action.payload }
        };
      }

      return state;
    }

    case types.RECV_ERROR: {

      const statusCode = get(action, "payload.response.status", "Error");

      if (action.fetchType === types.GET_SCREEN_VIEW) {
        toast.error(`${statusCode}: Could not get screen view`);
        return initialState;
      }

      if (action.fetchType === types.CREATE_SCREEN_VIEW) {
        toast.error(`${statusCode}: Could not create screen view`);
        return state;
      }

      if (action.fetchType === types.UPDATE_SCREEN_VIEW) {
        toast.error(`${statusCode}: Could not update screen view`);
        return state;
      }

      if (action.fetchType === types.DELETE_SCREEN_VIEW) {
        toast.error(`${statusCode}: Could not delete screen view`);
        return state;
      }

      return state;
    }

    case types.RESET_SCREEN_VIEW_FORM: {
      // Create form object
      const form = {
        id: state.id,
        name: state.name,
        layout: state.layout
      };

      return {...state, form };
    }

    case types.CLEAR_DATA:
    case types.CLEAR_SCREEN_VIEW_DATA: {
      return initialState;
    }
    
    default: {
      return state;
    }
  }
}
