import React, { Component } from "react";
import { Hidden, Visible } from "react-grid-system";
import { connect } from "react-redux";
import isEmpty from "lodash/isEmpty";
import UnregisteredList from "./unregisteredList";
import UnregisteredDetails from "./unregisteredDetails";
import style from "./style.module.scss";

class UnregisteredContainer extends Component {

  render() {
    // console.log("UnregisteredContainer.render", this.props);
    const hasSelection = !isEmpty(this.props.selectedUnregisteredSensors);
    return (
      <>
        <Hidden xs sm md>
          <div className={ hasSelection ? style.mainContainer : style.mainContainerHidden }>
            <div className={style.row}>
              <div className={style.listContainer}>
                <div className={style.scroll}>
                  <UnregisteredList {...this.props} />
                </div>
              </div>
              <div className={style.sideBar}>
                <div className={style.scroll}>
                  <UnregisteredDetails {...this.props} />
                </div>
              </div>
            </div>
          </div>
        </Hidden>
        <Visible xs sm md>
          <div className={ hasSelection ? style.mainContainer : style.mainContainerHidden }>
            <div className={style.slimScroll}>
              <UnregisteredList {...this.props} />
              { hasSelection && <UnregisteredDetails {...this.props} /> }
            </div>
          </div>
        </Visible>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    selectedUnregisteredSensors: state.selected.unregisteredSensors
  };
}

export default connect(mapStateToProps, null)(UnregisteredContainer);