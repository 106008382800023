import React, { Component } from "react";
import { Col, Hidden, Row, Visible } from "react-grid-system";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { v1 as uuid } from "uuid";
import { get, isEmpty, isNumber } from "lodash";
import AppRow from "../../components/AppRow";
import AppSection from "../../components/AppSection";
import BreadCrumb from "../../components/BreadCrumb";
import Button from "../../components/Button";
import Freetext from "../../components/Freetext";
import InputBox from "../../components/InputBox";
import OptionFooter from "../../components/OptionFooter";
import SegmentedControl from "../../components/SegmentedControl";
import Title from "../../components/Title";
import Loader from "../../components/Loader";
import { getParameterByName } from "../../helpers";
import { inputElements } from "./helpers";
import * as contentActions from "../../actions/content";
import style from "./style.module.scss";

class EditContent extends Component {

  constructor(props) {
    super(props);
    this.state = {
      hasLoadedState: false,
      selectedSection: null,
      selectedSectionIndex: null,
      selectedRow: null,
      selectedRowIndex: null,
      selectedMicroAppId: null,
      selectedSubviewId: null,
      breadcrumbs: [],
      title: getParameterByName("name") ?? "",
      section: { "en": { rows: [{ type: "text", title: "" }] }, "nb": { rows: [{ type: "text", title: "" }] }},
      rowString: null,
      microapps: [],
      microappString: null,
      language: "en",
      showAdvancedEdit: false
    };

    this.inputOpenImageRef = React.createRef();

    this.onSave = this.onSave.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.onLanguageChange = this.onLanguageChange.bind(this);
    this.onBreadCrumbClick = this.onBreadCrumbClick.bind(this);
    this.onSelectedRow = this.onSelectedRow.bind(this);
    this.onSelectedSection = this.onSelectedSection.bind(this);
    this.onAddRow = this.onAddRow.bind(this);
    this.onDeleteRow = this.onDeleteRow.bind(this);
    this.onAddSection = this.onAddSection.bind(this);
    this.onDeleteSection = this.onDeleteSection.bind(this);
    this.saveRow = this.saveRow.bind(this);
    this.updateRow = this.updateRow.bind(this);
    this.copyTextArea = this.copyTextArea.bind(this);
    this.updateSection = this.updateSection.bind(this);
    this.moveRowUp = this.moveRowUp.bind(this);
    this.moveRowDown = this.moveRowDown.bind(this);
    this.moveSectionUp = this.moveSectionUp.bind(this);
    this.moveSectionDown = this.moveSectionDown.bind(this);
    // this.onReplaceFromOtherLang = this.onReplaceFromOtherLang.bind(this);
    this.gotoMicroApp = this.gotoMicroApp.bind(this);
    this.getOptionFooter = this.getOptionFooter.bind(this);
    this.sideBar = this.sideBar.bind(this);
    this.onUploadImage = this.onUploadImage.bind(this);
    this.onUploadImageClick = this.onUploadImageClick.bind(this);

    if (isEmpty(this.props.match.params.id)) {
      // TODO: This clears content on refresh auth also (or does it?) - is there another way to clear?
      this.props.clearContent();
    }
    else if (this.props.content.id !== this.props.match.params.id) {
      this.props.getContent(this.props.match.params.id);
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {

    if (prevState.hasLoadedState) {
      return null;
    }

    // Only copy props if they exist and if content id is correct
    if (!isEmpty(nextProps.content) && !isEmpty(nextProps.content.id) && nextProps.match.params.id === nextProps.content.id) {

      const copiedSection = JSON.parse(JSON.stringify(nextProps.content.section));
      const copiedMicroapps = nextProps.content.microapps.map(microapp => JSON.parse(JSON.stringify(microapp)));

      const newState = {
        hasLoadedState: true,
        title: nextProps.content.title,
        description: nextProps.content.description,
        section: copiedSection,
        microapps: copiedMicroapps
      };
      
      return newState;
    }

    return null;
  }

  componentDidUpdate(prevProps) {
    // Only load data if id change
    if (isEmpty(this.props.match.params.id)) {
      // this.props.clearContent();
    }
    else if (prevProps.match.params.id !== this.props.match.params.id) {
      this.props.getContent(this.props.match.params.id);
    }
  }

  onSave() {
    const { push } = this.props.history;

    const body = {};

    body.title = this.state.title;
    // body.description = this.state.description;
    body.section = this.state.section;

    // Only add micro apps that exist in a row with a onClick
    let filterdMicroapps = [];
    filterdMicroapps = this.state.microapps.filter(microapp => {
      var foundOnClick = false;
      const langSections = Object.values(this.state.section);
      langSections.forEach(section => {
        const found = section.rows.find(row => {
          if (isEmpty(row.onClick) || row.onClick.type !== "microapp") {
            return false;
          }
          
          let microAppId = null;
          if (get(row, "onClick.url", "").includes('{{')) {
            microAppId = row.onClick.url;
          }
          else if (get(row, "onClick.url", false)) {
            const splitArray = row.onClick.url.split('/microapp/');
            microAppId = get(splitArray, "[1]", undefined);
          }
  
          return (!isEmpty(microapp.id) && microapp.id === microAppId);
        }) !== undefined;

        if (found) {
          foundOnClick = true;
        }
      });

      return foundOnClick;
    });

    // Remove unused subviews
    let foundZombie = true;
    const findZombie = () => {
      let found = false;
      filterdMicroapps.forEach((microapp, index, array) => {
        const langs = Object.keys(microapp.content);
        langs.forEach(lang => {
          const stringContent = JSON.stringify(microapp.content[lang]);

          const subviews = get(microapp, `content[${lang}].subviews`, []);
          array[index].content[lang].subviews = subviews.filter(subview => {
            // If string is split only once - then there are no onClick
            const moreThan = stringContent.split(subview.id).length > 2;
            if (!moreThan) {
              found = true;
            }
            return moreThan;
          });
          
        });
      });
      return found;
    }

    // Loop until no unused subviews
    while (foundZombie) {
      foundZombie = findZombie();
    }

    body.microapps = filterdMicroapps;

    if (!isEmpty(this.props.match.params.id)) {
      this.props.updateContent(this.props.match.params.id, body, push);
    }
    else {
      this.props.createContent(body, push);
    }
  }

  onCancel() {
    // console.log("onCancel", this.props.location);
    const locationState = this.props.location.state;
    if (locationState && locationState.from.pathname) {
      this.props.history.push(locationState.from.pathname);
    }
    else {
      this.props.history.push(`/companies/${this.props.match.params.companyId}/app/content`);
    }
  }

  onLanguageChange(value) {
    this.setState(prevState => { 
      var newState = {};

      newState.language = value;
      
      // If viewing outer section
      if (isEmpty(prevState.selectedMicroAppId)) {
        
        if (!isEmpty(prevState.selectedSection)) {
          newState.selectedSection = prevState.section[newState.language];
        }

        if (!isEmpty(prevState.selectedRow)) {
          newState.selectedRow = get(prevState.section[newState.language], `rows[${prevState.selectedRowIndex}]`, "");
          newState.rowString = JSON.stringify(newState.selectedRow);
        }
      }
      else {
        const selectedMicroApp = prevState.microapps.find(microapp => microapp.id === prevState.selectedMicroAppId);

        // Select row/section in micro app or subview
        if (isEmpty(prevState.selectedSubviewId)) {
          if (!isEmpty(prevState.selectedSection)) {
            newState.selectedSection = get(selectedMicroApp, `content[${newState.language}].sections[${prevState.selectedSectionIndex}]`, "");
          }
  
          if (!isEmpty(prevState.selectedRow)) {
            newState.selectedRow = get(selectedMicroApp, `content[${newState.language}].sections[${prevState.selectedSectionIndex}].rows[${prevState.selectedRowIndex}]`, "");
            newState.rowString = JSON.stringify(newState.selectedRow);
          }
        }
        else {
          const selectedSubview = selectedMicroApp.content[newState.language].subviews.find(subview => subview.id === prevState.selectedSubviewId);

          if (!isEmpty(prevState.selectedSection)) {
            newState.selectedSection = get(selectedSubview, `sections[${prevState.selectedSectionIndex}]`, "");
          }
          
          if (!isEmpty(prevState.selectedRow)) {
            newState.selectedRow = get(selectedSubview, `sections[${prevState.selectedSectionIndex}].rows[${prevState.selectedRowIndex}]`, "");
            newState.rowString = JSON.stringify(newState.selectedRow);
          }
        }
      }

      return newState;
    });
  }

  onBreadCrumbClick(newMicroAppId) {
    // console.log("onBreadCrumbClick", newMicroAppId);
    this.setState(prevState => { 
      var newState = { ...prevState };

      // Pop to selected breadcrumb
      const index = newState.breadcrumbs.findIndex(crumb => crumb.id === newMicroAppId);
      
      if (index === -1) {
        newState.selectedMicroAppId = null;
        newState.microappString = null;
        newState.breadcrumbs = [];
      }
      else {
        newState.breadcrumbs.splice(index);
      }

      if (index > 1) {
        let selectedMicroApp = newState.microapps.find(microapp => microapp.id === prevState.selectedMicroAppId);
        let selectedSubview = selectedMicroApp.content[prevState.language].subviews.find(subview => subview.id === newMicroAppId);
        newState.selectedSubviewId = newMicroAppId;
        newState.microappString = JSON.stringify(selectedSubview);
      }
      else {
        let selectedMicroApp = newState.microapps.find(microapp => microapp.id === prevState.selectedMicroAppId);
        newState.microappString = JSON.stringify(selectedMicroApp.content[prevState.language]);
        newState.selectedSubviewId = null;
      }

      newState.selectedSectionIndex = null;
      newState.selectedSection = null;
      newState.selectedRowIndex = null;
      newState.selectedRow = null;

      return newState;
    });
  }

  onSelectedRow(sectionIndex, rowIndex) {
    // console.log("onSelectedRow", sectionIndex, rowIndex);
    this.setState(prevState => {

      var newState = {
        selectedSectionIndex: sectionIndex,
        selectedSection: null,
        selectedRowIndex: rowIndex
      };

      // If viewing outer section
      if (isEmpty(prevState.selectedMicroAppId)) {
        newState.selectedRow = get(prevState.section[prevState.language], `rows[${rowIndex}]`, "");
        newState.rowString = JSON.stringify(newState.selectedRow);
      }
      else {
        const selectedMicroApp = prevState.microapps.find(microapp => microapp.id === prevState.selectedMicroAppId);

        // Select row in micro app or subview
        if (isEmpty(prevState.selectedSubviewId)) {
          newState.selectedRow = get(selectedMicroApp, `content[${prevState.language}].sections[${sectionIndex}].rows[${rowIndex}]`, "");
          newState.rowString = JSON.stringify(newState.selectedRow);
        }
        else {
          const selectedSubview = selectedMicroApp.content[prevState.language].subviews.find(subview => subview.id === prevState.selectedSubviewId);

          newState.selectedRow = get(selectedSubview, `sections[${sectionIndex}].rows[${rowIndex}]`, "");
          newState.rowString = JSON.stringify(newState.selectedRow);
        }
      }

      return newState;

    });
  }

  onSelectedSection(sectionIndex) {
    // console.log("onSelectedSection", sectionIndex);

    this.setState(prevState => {

      var newState = {
        selectedSectionIndex: sectionIndex,
        selectedRowIndex: null,
        selectedRow: null,
        rowString: null,
        microappString: null
      };

      // If viewing outer section
      if (isEmpty(prevState.selectedMicroAppId)) {
        newState.selectedSection = get(prevState.section, `[${prevState.language}]`, "");
      }
      else {
        const selectedMicroApp = prevState.microapps.find(microapp => microapp.id === prevState.selectedMicroAppId);

        // Select section in micro app or subview
        if (isEmpty(prevState.selectedSubviewId)) {
          newState.selectedSection = get(selectedMicroApp, `content[${prevState.language}].sections[${sectionIndex}]`, "");
        }
        else {
          const selectedSubview = selectedMicroApp.content[prevState.language].subviews.find(subview => subview.id === prevState.selectedSubviewId);
          newState.selectedSection = get(selectedSubview, `sections[${sectionIndex}]`, "");
        }
      }

      return newState;

    });
  }

  onAddRow(sectionIndex) {
    this.setState(prevState => {
      var newState = { ...prevState };
      const languages = Object.keys(newState.section);

      if (isEmpty(prevState.selectedMicroAppId)) {
        languages.forEach(lang => {
          if (isEmpty(newState.section[lang])) {
            newState.section[lang] = { rows: [] };
          }
          newState.section[lang].rows.push({ type: "text", title: "" });
        });

        newState.selectedRowIndex = newState.section[newState.language].rows.length - 1;
      }
      else if (isEmpty(prevState.selectedSubviewId)) {
        const microAppIndex = newState.microapps.findIndex(microapp => microapp.id === newState.selectedMicroAppId);
        languages.forEach(lang => {
          if (isEmpty(newState.microapps[microAppIndex].content[lang])) {
            newState.microapps[microAppIndex].content[lang] = { sections: [{ rows: [] }] };
          }
          newState.microapps[microAppIndex].content[lang].sections[sectionIndex].rows.push({ type: "text", title: "" });
        });
  
        newState.selectedRowIndex = newState.microapps[microAppIndex].content[newState.language].sections[sectionIndex].rows.length - 1;
      }
      else {
        const microAppIndex = newState.microapps.findIndex(microapp => microapp.id === newState.selectedMicroAppId);
        const subviewIndex = newState.microapps[microAppIndex].content[newState.language].subviews.findIndex(subview => subview.id === prevState.selectedSubviewId);
        languages.forEach(lang => {
          newState.microapps[microAppIndex].content[lang].subviews[subviewIndex].sections[sectionIndex].rows.push({ type: "text", title: "" });
        });

        newState.selectedRowIndex = newState.microapps[microAppIndex].content[newState.language].subviews[subviewIndex].sections[sectionIndex].rows.length - 1;
      }

      newState.selectedSectionIndex = sectionIndex;
      newState.selectedSection = null;
      newState.selectedRow = { type: "text", title: "" };
      newState.rowString = JSON.stringify({ type: "text", title: "" });
      
      return newState;
    });
  }

  onDeleteRow() {
    this.setState(prevState => {
      var newState = { ...prevState };
      const languages = Object.keys(newState.section);

      if (isEmpty(prevState.selectedMicroAppId)) {
        languages.forEach(lang => {
          newState.section[lang].rows.splice(newState.selectedRowIndex, 1);
        });
      }
      else if (isEmpty(prevState.selectedSubviewId)) {
        const microAppIndex = newState.microapps.findIndex(microapp => microapp.id === newState.selectedMicroAppId);
        languages.forEach(lang => {
          newState.microapps[microAppIndex].content[lang].sections[newState.selectedSectionIndex].rows.splice(newState.selectedRowIndex, 1);
        });
      }
      else {
        const microAppIndex = newState.microapps.findIndex(microapp => microapp.id === newState.selectedMicroAppId);
        const subviewIndex = newState.microapps[microAppIndex].content[newState.language].subviews.findIndex(subview => subview.id === prevState.selectedSubviewId);
        languages.forEach(lang => {
          newState.microapps[microAppIndex].content[lang].subviews[subviewIndex].sections[newState.selectedSectionIndex].rows.splice(newState.selectedRowIndex, 1);
        });
      }

      newState.selectedSectionIndex = null;
      newState.selectedRowIndex = null;
      newState.selectedRow = null;
      newState.rowString = null;
      return newState;
    });
  }

  onAddSection() {
    // console.log("onAddSection");
    this.setState(prevState => {
      var newState = { ...prevState };
      const languages = Object.keys(newState.section);

      // If viewing outer section
      if (isEmpty(prevState.selectedMicroAppId)) {
        // Outer section does not allow multiple sections
        return {};
      }
      else {
        const microAppIndex = prevState.microapps.findIndex(microapp => microapp.id === prevState.selectedMicroAppId);
        newState.selectedSectionIndex = newState.microapps[microAppIndex].content[newState.language].sections.length - 1;

        if (isEmpty(prevState.selectedSubviewId)) {
          const microAppIndex = prevState.microapps.findIndex(microapp => microapp.id === prevState.selectedMicroAppId);
        
          languages.forEach(lang => {
            if (isEmpty(newState.microapps[microAppIndex].content[lang])) {
              newState.microapps[microAppIndex].content[lang] = { sections: [{ rows: [] }] };
            }
    
            newState.microapps[microAppIndex].content[lang].sections.push({ rows: [{ type: "text", title: "" }]});
          });
        }
        else {
          const subviewIndex = prevState.microapps[microAppIndex].content[newState.language].subviews.findIndex(subview => subview.id === prevState.selectedSubviewId);
      
          languages.forEach(lang => {
            newState.microapps[microAppIndex].content[lang].subviews[subviewIndex].sections.push({ rows: [{ type: "text", title: "" }]});
          });
        }
      }
        
      newState.selectedSection = { rows: [{ type: "text", title: "" }]};
      newState.selectedRow = null;
      newState.selectedRowIndex = null;
      newState.rowString = null;
      
      return newState;
    });
  }

  onDeleteSection() {
    // console.log("onDeleteSection");
    this.setState(prevState => {
      var newState = { ...prevState };
      const languages = Object.keys(newState.section);

      if (isEmpty(prevState.selectedMicroAppId)) {
        // Do not delete only section on front page
        return;
      }
      else if (isEmpty(prevState.selectedSubviewId)) {
        const microAppIndex = newState.microapps.findIndex(microapp => microapp.id === newState.selectedMicroAppId);

        if (newState.microapps[microAppIndex].content[newState.language].sections.length === 1) {
          // Do not delete only section
          return;
        }

        languages.forEach(lang => {
          newState.microapps[microAppIndex].content[lang].sections.splice(newState.selectedSectionIndex, 1);
        });
      }
      else {
        const microAppIndex = newState.microapps.findIndex(microapp => microapp.id === newState.selectedMicroAppId);
        const subviewIndex = newState.microapps[microAppIndex].content[newState.language].subviews.findIndex(subview => subview.id === prevState.selectedSubviewId);

        if (newState.microapps[microAppIndex].content[newState.language].subviews[subviewIndex].sections.length === 1) {
          // Do not delete only section
          return;
        }

        languages.forEach(lang => {
          newState.microapps[microAppIndex].content[lang].subviews[subviewIndex].sections[newState.selectedSectionIndex].rows.splice(newState.selectedRowIndex, 1);
        });
      }

      newState.selectedSectionIndex = null;
      newState.selectedSection = null;
      return newState;
    });
  }

  gotoMicroApp(newMicroAppId) {
    // console.log("gotoMicroApp", newMicroAppId);
    this.setState(prevState => { 
      var newState = { ...prevState };

      const pageNames = ["Front page", "Second page", "Third page", "Forth page"];
      if (newState.breadcrumbs.length === 4) {
        return newState;
      }

      // If not viewing micro app - select micro app
      if (isEmpty(prevState.selectedMicroAppId)) {
        const newMicroApp = newState.microapps.find(microapp => microapp.id === newMicroAppId);
        newState.breadcrumbs.push({ id: null, name: pageNames[0] });
        newState.selectedMicroAppId = newMicroAppId;
        newState.microappString = JSON.stringify(newMicroApp);
      }
      else if (isEmpty(prevState.selectedSubviewId)) {
        const selectedMicroApp = newState.microapps.find(microapp => microapp.id === prevState.selectedMicroAppId);
        const newSubview = selectedMicroApp.content[newState.language].subviews.find(subview => subview.id === newMicroAppId);
        newState.breadcrumbs.push({ id: prevState.selectedMicroAppId, name: pageNames[1] });
        newState.selectedSubviewId = newMicroAppId;
        newState.microappString = JSON.stringify(newSubview);
      }
      // If viewing micro app - select subview
      else {
        const selectedMicroApp = newState.microapps.find(microapp => microapp.id === prevState.selectedMicroAppId);
        // const selectedSubview = selectedMicroApp.content[newState.language].subviews.find((subview => subview.id === prevState.selectedSubviewId));
        const newSubview = selectedMicroApp.content[newState.language].subviews.find(subview => subview.id === newMicroAppId);
        newState.breadcrumbs.push({ id: prevState.selectedSubviewId, name: pageNames[newState.breadcrumbs.length] });
        newState.selectedSubviewId = newMicroAppId;
        newState.microappString = JSON.stringify(newSubview);
      }
      
      newState.selectedSectionIndex = null;
      newState.selectedSection = null;
      newState.selectedRowIndex = null;
      newState.selectedRow = null;

      return newState;
    });
  }

  saveRow(newRow) {
    // console.log("onSaveRow", newRow); 
    this.setState(prevState => { 
      var newState = { ...prevState };
      let languages = Object.keys(newState.section);

      newState.selectedRow = newRow;

      // If viewing outer section
      if (isEmpty(newState.selectedMicroAppId)) {
        languages.forEach(lang => {
          newState.section[lang].rows[newState.selectedRowIndex] = { ...newState.selectedRow };
        });
      }
      // If viewing sections in a micro app
      else if (isEmpty(newState.selectedSubviewId)) {
        const microAppIndex = newState.microapps.findIndex(microapp => microapp.id === newState.selectedMicroAppId);
        languages.forEach(lang => {
          newState.microapps[microAppIndex].content[lang].sections[newState.selectedSectionIndex].rows[newState.selectedRowIndex] = { ...newState.selectedRow };
        });
      }
      // If viewing sections in a subview
      else {
        const microAppIndex = newState.microapps.findIndex(microapp => microapp.id === newState.selectedMicroAppId);
        const subviewIndex = newState.microapps[microAppIndex].content[newState.language].subviews.findIndex(subview => subview.id === prevState.selectedSubviewId);
         languages.forEach(lang => {
          newState.microapps[microAppIndex].content[lang].subviews[subviewIndex].sections[newState.selectedSectionIndex].rows[newState.selectedRowIndex] = { ...newState.selectedRow };
        });
      }

      return newState;
    });
  }

  updateRow(newRow) {
    // console.log("updateRow", newRow);
    this.setState(prevState => { 
      var newState = { ...prevState };
      let languages = Object.keys(newState.section);
      
      newState.selectedRow = { ...newState.selectedRow, ...newRow };

      let prevType = get(prevState, "selectedRow.onClick.type", "");
      let newType = get(newState, "selectedRow.onClick.type", "");

      // Clear onClick if undefined
      if (newState.selectedRow.onClick === null) {
        delete newState.selectedRow.onClick;

        // Remove old micro app or subview
        if (prevType === "microapp") {
          let microAppIdToDelete = null;
          if (get(prevState.selectedRow, "onClick.url", "").includes('{{')) {
            microAppIdToDelete = prevState.selectedRow.onClick.url;
          }
          else if (get(prevState.selectedRow, "onClick.url", false)) {
            const splitArray = prevState.selectedRow.onClick.url.split('/microapp/');
            microAppIdToDelete = get(splitArray, "[1]", undefined);
          }

          newState.microapps = newState.microapps.filter(microapp => microapp.id !== microAppIdToDelete);

          languages.forEach(lang => {
            delete newState.section[lang].rows[newState.selectedRowIndex].onClick;
          });

        }
        else if (prevType === "open-subview") {
          const subviewIdToDelete = prevState.selectedRow.onClick.id;
          const microAppIndex = newState.microapps.findIndex(microapp => microapp.id === newState.selectedMicroAppId);
          languages.forEach(lang => {
            newState.microapps[microAppIndex].content[lang].subviews = newState.microapps[microAppIndex].content[lang].subviews.filter(subview => subview.id !== subviewIdToDelete);
            delete newState.microapps[microAppIndex].content[lang].sections[newState.selectedSectionIndex].rows[newState.selectedRowIndex].onClick;
          });
        }
      }
      // If creating a new micro app
      else if (newType === "microapp" && prevType !== "microapp") {
        const newUUID = uuid();
        newState.selectedRow.onClick.url = `{{${newUUID}}}`;
        const newMicroapp = { id: `{{${newUUID}}}`, content: {}};

        languages.forEach(lang => {
          newState.section[lang].rows[newState.selectedRowIndex].onClick = { type: "microapp", url: `{{${newUUID}}}` };
          newMicroapp.content[lang] = { id: `${newUUID}`, sections:[{ rows:[{ type: "text", title: "" }]}]};
        });

        newState.microapps.push(newMicroapp);
      }
      // If creating a new subview
      else if (newType === "open-subview" && prevType !== "open-subview") {
        const newUUID = uuid();
        newState.selectedRow.onClick.id = `${newUUID}`;

        const microAppIndex = newState.microapps.findIndex(microapp => microapp.id === newState.selectedMicroAppId);

        // If viewing sections in a micro app
        if (isEmpty(newState.selectedSubviewId)) {
          languages.forEach(lang => {
            newState.microapps[microAppIndex].content[lang].sections[newState.selectedSectionIndex].rows[newState.selectedRowIndex].onClick = { type: "open-subview", id: `${newUUID}` };
            
            if (isEmpty(newState.microapps[microAppIndex].content[lang].subviews)) {
              newState.microapps[microAppIndex].content[lang].subviews = [];
            }

            newState.microapps[microAppIndex].content[lang].subviews.push({ id: `${newUUID}`, sections:[{ rows:[{ type: "text", title: "" }]}]});
          });
        }
        // If viewing sections in a subview
        else {
          const subviewIndex = newState.microapps[microAppIndex].content[newState.language].subviews.findIndex(subview => subview.id === prevState.selectedSubviewId);
          languages.forEach(lang => {
            newState.microapps[microAppIndex].content[lang].subviews[subviewIndex].sections[newState.selectedSectionIndex].rows[newState.selectedRowIndex].onClick = { type: "open-subview", id: `${newUUID}` };
            newState.microapps[microAppIndex].content[lang].subviews.push({ id: `${newUUID}`, sections:[{ rows:[{ type: "text", title: "" }]}]});
          });
        }
      }
      // else if (newType === "open-url") {
      //   // If viewing outer section
      //   if (isEmpty(newState.selectedMicroAppId)) {
      //     newState.section[newState.language].rows[newState.selectedRowIndex] = newState.selectedRow;
      //   }
      //   // If viewing sections in a micro app
      //   else if (isEmpty(newState.selectedSubviewId)) {
      //     const microAppIndex = newState.microapps.findIndex(microapp => microapp.id === newState.selectedMicroAppId);
      //     newState.microapps[microAppIndex].content[newState.language].sections[newState.selectedSectionIndex].rows[newState.selectedRowIndex] = newState.selectedRow;
      //   }
      //   // If viewing sections in a subview
      //   else {
      //     const microAppIndex = newState.microapps.findIndex(microapp => microapp.id === newState.selectedMicroAppId);
      //     const subviewIndex = newState.microapps[microAppIndex].content["en"].subviews.findIndex(subview => subview.id === prevState.selectedSubviewId);
      //     newState.microapps[microAppIndex].content[newState.language].subviews[subviewIndex].sections[newState.selectedSectionIndex].rows[newState.selectedRowIndex] = newState.selectedRow;
      //     otherLanguages.forEach(lang => {
      //       newState.microapps[microAppIndex].content[lang].subviews[subviewIndex].sections[newState.selectedSectionIndex].rows[newState.selectedRowIndex] = { ...newState.microapps[microAppIndex].content[lang].subviews[subviewIndex].sections[newState.selectedSectionIndex].rows[newState.selectedRowIndex], ...strippedRow };
      //     });
      //   }
      // }

      // Get all other languages
      let otherLanguages = Object.keys(newState.section);
      otherLanguages = otherLanguages.filter(lang => lang !== newState.language);

      // Copy everything to other languages except title, text, tag, content, thumbnailUrl, imageUrl, url
      let strippedRow = JSON.parse(JSON.stringify(newState.selectedRow));
      delete strippedRow.title;
      delete strippedRow.text;
      delete strippedRow.tag;
      delete strippedRow.content;
      delete strippedRow.thumbnailUrl;
      delete strippedRow.imageUrl;

      if (get(strippedRow, "onClick.type", "") === "open-url") {
        delete strippedRow.onClick.url;
      }

      // If viewing outer section
      if (isEmpty(newState.selectedMicroAppId)) {
        newState.section[newState.language].rows[newState.selectedRowIndex] = newState.selectedRow;
        otherLanguages.forEach(lang => {
          const oldOnClickUrl = get(newState.section[lang].rows[newState.selectedRowIndex], "onClick.url", null);
          newState.section[lang].rows[newState.selectedRowIndex] = { ...newState.section[lang].rows[newState.selectedRowIndex], ...strippedRow };

          // Re-add onClick url for this language
          if (oldOnClickUrl) {
            newState.section[lang].rows[newState.selectedRowIndex].onClick.url = oldOnClickUrl;
          }
        });
      }
      // If viewing sections in a micro app
      else if (isEmpty(newState.selectedSubviewId)) {
        const microAppIndex = newState.microapps.findIndex(microapp => microapp.id === newState.selectedMicroAppId);
        newState.microapps[microAppIndex].content[newState.language].sections[newState.selectedSectionIndex].rows[newState.selectedRowIndex] = newState.selectedRow;
        otherLanguages.forEach(lang => {
          const oldOnClickUrl = get(newState.microapps[microAppIndex].content[lang].sections[newState.selectedSectionIndex].rows[newState.selectedRowIndex], "onClick.url", null);
          newState.microapps[microAppIndex].content[lang].sections[newState.selectedSectionIndex].rows[newState.selectedRowIndex] = { ...newState.microapps[microAppIndex].content[lang].sections[newState.selectedSectionIndex].rows[newState.selectedRowIndex], ...strippedRow };
          
          // Re-add onClick url for this language
          if (oldOnClickUrl) {
            newState.microapps[microAppIndex].content[lang].sections[newState.selectedSectionIndex].rows[newState.selectedRowIndex].onClick.url = oldOnClickUrl;
          }
        });
      }
      // If viewing sections in a subview
      else {
        const microAppIndex = newState.microapps.findIndex(microapp => microapp.id === newState.selectedMicroAppId);
        const subviewIndex = newState.microapps[microAppIndex].content[newState.language].subviews.findIndex(subview => subview.id === prevState.selectedSubviewId);
        newState.microapps[microAppIndex].content[newState.language].subviews[subviewIndex].sections[newState.selectedSectionIndex].rows[newState.selectedRowIndex] = newState.selectedRow;
        otherLanguages.forEach(lang => {
          const oldOnClickUrl = get(newState.microapps[microAppIndex].content[lang].subviews[subviewIndex].sections[newState.selectedSectionIndex].rows[newState.selectedRowIndex], "onClick.url", null);
          newState.microapps[microAppIndex].content[lang].subviews[subviewIndex].sections[newState.selectedSectionIndex].rows[newState.selectedRowIndex] = { ...newState.microapps[microAppIndex].content[lang].subviews[subviewIndex].sections[newState.selectedSectionIndex].rows[newState.selectedRowIndex], ...strippedRow };

          // Re-add onClick url for this language
          if (oldOnClickUrl) {
            newState.microapps[microAppIndex].content[lang].subviews[subviewIndex].sections[newState.selectedSectionIndex].rows[newState.selectedRowIndex].onClick.url = oldOnClickUrl;
          }
        });
      }

      return newState;
    });
  }

  copyTextArea() {
    // console.log("copyTextArea");
    this.setState(prevState => { 
      var newState = { ...prevState };

      // Copy from Norwegian or English
      let copyLanguage = "en";
      if (newState.language === "en") {
        copyLanguage = "nb";
      }

      // Get all other languages
      let otherLanguages = Object.keys(newState.section);
      otherLanguages = [newState.language];

      // If viewing outer section
      if (isEmpty(newState.selectedMicroAppId)) {
        const copyText = newState.section[copyLanguage].rows[newState.selectedRowIndex].text;
        newState.section[newState.language].rows[newState.selectedRowIndex].text = copyText;
      }
      // If viewing sections in a micro app
      else if (isEmpty(newState.selectedSubviewId)) {
        const microAppIndex = newState.microapps.findIndex(microapp => microapp.id === newState.selectedMicroAppId);
        const copyText = newState.microapps[microAppIndex].content[copyLanguage].sections[newState.selectedSectionIndex].rows[newState.selectedRowIndex].text;
        newState.microapps[microAppIndex].content[newState.language].sections[newState.selectedSectionIndex].rows[newState.selectedRowIndex].text = copyText;
      }
      // If viewing sections in a subview
      else {
        const microAppIndex = newState.microapps.findIndex(microapp => microapp.id === newState.selectedMicroAppId);
        const subviewIndex = newState.microapps[microAppIndex].content[newState.language].subviews.findIndex(subview => subview.id === prevState.selectedSubviewId);
        const copyText = newState.microapps[microAppIndex].content[copyLanguage].subviews[subviewIndex].sections[newState.selectedSectionIndex].rows[newState.selectedRowIndex].text;
        newState.microapps[microAppIndex].content[newState.language].subviews[subviewIndex].sections[newState.selectedSectionIndex].rows[newState.selectedRowIndex].text = copyText;
      }

      return newState;
    });
  }

  updateSection(newSection) {
    this.setState(prevState => { 
      var newState = { ...prevState };

      newState.selectedSection = { ...newState.selectedSection, ...newSection };

      // Do not send in empty header
      if (newState.selectedSection.header === "") {
        delete newState.selectedSection.header;
      }

      // If viewing outer section
      if (isEmpty(newState.selectedMicroAppId)) {
        newState.section[newState.language] = newState.selectedSection;
      }
      // If viewing sections in a micro app
      else {
        const microAppIndex = newState.microapps.findIndex(microapp => microapp.id === newState.selectedMicroAppId);

        if (isEmpty(newState.selectedSubviewId)) {
          newState.microapps[microAppIndex].content[newState.language].sections[newState.selectedSectionIndex] = newState.selectedSection;
        }
        else {
          const subviewIndex = newState.microapps[microAppIndex].content[newState.language].subviews.findIndex(subview => subview.id === newState.selectedSubviewId);
          newState.microapps[microAppIndex].content[newState.language].subviews[subviewIndex].sections[newState.selectedSectionIndex] = newState.selectedSection;
        }
      }

      return newState;
    });
  }

  moveRowUp(sectionIndex, index) {
    this.setState(prevState => { 
      var newState = { ...prevState };
      let languages = Object.keys(newState.section);

      // If viewing outer section
      if (isEmpty(newState.selectedMicroAppId)) {
        languages.forEach(lang => {
          const rows = newState.section[lang].rows;
          [rows[index-1], rows[index]] = [rows[index], rows[index-1]];
        });
      }
      // If viewing sections in a micro app
      else {
        const microAppIndex = newState.microapps.findIndex(microapp => microapp.id === newState.selectedMicroAppId);

        if (isEmpty(newState.selectedSubviewId)) {
          languages.forEach(lang => {
            const rows = newState.microapps[microAppIndex].content[lang].sections[newState.selectedSectionIndex].rows;
            [rows[index-1], rows[index]] = [rows[index], rows[index-1]];
          });
        }
        else {
          languages.forEach(lang => {
            const subviewIndex = newState.microapps[microAppIndex].content[lang].subviews.findIndex(subview => subview.id === newState.selectedSubviewId);
            const rows = newState.microapps[microAppIndex].content[lang].subviews[subviewIndex].sections[newState.selectedSectionIndex].rows;
            [rows[index-1], rows[index]] = [rows[index], rows[index-1]];
          });
        }
      }

      return newState;
    });
  }

  moveRowDown(sectionIndex, index) {
    this.setState(prevState => { 
      var newState = { ...prevState };
      let languages = Object.keys(newState.section);

      // If viewing outer section
      if (isEmpty(newState.selectedMicroAppId)) {
        languages.forEach(lang => {
          const rows = newState.section[lang].rows;
          [rows[index], rows[index+1]] = [rows[index+1], rows[index]];
        });
      }
      // If viewing sections in a micro app
      else {
        const microAppIndex = newState.microapps.findIndex(microapp => microapp.id === newState.selectedMicroAppId);

        if (isEmpty(newState.selectedSubviewId)) {
          languages.forEach(lang => {
            const rows = newState.microapps[microAppIndex].content[lang].sections[newState.selectedSectionIndex].rows;
            [rows[index], rows[index+1]] = [rows[index+1], rows[index]];
          });
        }
        else {
          languages.forEach(lang => {
            const subviewIndex = newState.microapps[microAppIndex].content[lang].subviews.findIndex(subview => subview.id === newState.selectedSubviewId);
            const rows = newState.microapps[microAppIndex].content[lang].subviews[subviewIndex].sections[newState.selectedSectionIndex].rows;
            [rows[index], rows[index+1]] = [rows[index+1], rows[index]];
          });
        }
      }

      return newState;
    });
  }

  moveSectionUp(index) {
    this.setState(prevState => { 
      var newState = { ...prevState };
      let languages = Object.keys(newState.section);

      // If viewing outer section
      if (isEmpty(newState.selectedMicroAppId)) {
        // Not allowed
      }
      // If viewing sections in a micro app
      else {
        const microAppIndex = newState.microapps.findIndex(microapp => microapp.id === newState.selectedMicroAppId);

        if (isEmpty(newState.selectedSubviewId)) {
          languages.forEach(lang => {
            const sections = newState.microapps[microAppIndex].content[lang].sections;
            [sections[index-1], sections[index]] = [sections[index], sections[index-1]];
          });
        }
        else {
          languages.forEach(lang => {
            const subviewIndex = newState.microapps[microAppIndex].content[lang].subviews.findIndex(subview => subview.id === newState.selectedSubviewId);
            const sections = newState.microapps[microAppIndex].content[lang].subviews[subviewIndex].sections;
            [sections[index-1], sections[index]] = [sections[index], sections[index-1]];
          });
        }
      }

      return newState;
    });
  }

  moveSectionDown(index) {
    this.setState(prevState => { 
      var newState = { ...prevState };
      let languages = Object.keys(newState.section);

      // If viewing outer section
      if (isEmpty(newState.selectedMicroAppId)) {
        // Not allowed
      }
      // If viewing sections in a micro app
      else {
        const microAppIndex = newState.microapps.findIndex(microapp => microapp.id === newState.selectedMicroAppId);

        if (isEmpty(newState.selectedSubviewId)) {
          languages.forEach(lang => {
            const sections = newState.microapps[microAppIndex].content[lang].sections;
            [sections[index], sections[index+1]] = [sections[index+1], sections[index]];
          });
        }
        else {
          languages.forEach(lang => {
            const subviewIndex = newState.microapps[microAppIndex].content[lang].subviews.findIndex(subview => subview.id === newState.selectedSubviewId);
            const sections = newState.microapps[microAppIndex].content[lang].subviews[subviewIndex].sections;
            [sections[index], sections[index+1]] = [sections[index+1], sections[index]];
          });
        }
      }

      return newState;
    });
  }

  // onReplaceFromOtherLang() {
  //   this.setState(prevState => {
  //     var newState = { ...prevState };

  //     if (isEmpty(newState.section["en"])) {
  //       newState.section["en"] = { rows: [] };
  //     }

  //     if (isEmpty(newState.section["nb"])) {
  //       newState.section["nb"] = { rows: [] };
  //     }

  //     if (newState.language === "en") {

  //       // Deep copy
  //       var rowsCopy = JSON.parse(JSON.stringify(newState.section["nb"].rows));
  //       newState.section["en"].rows = rowsCopy;

  //       if (!isEmpty(newState.microapps)) {
  //         newState.microapps.forEach(microapp => {
  //           // Deep copy
  //           var microAppCopy = JSON.parse(JSON.stringify(microapp.content["nb"]));
  //           microapp.content["en"] = microAppCopy;
  //         });
  //       }
  //     }
  //     else {

  //       // Deep copy
  //       var rowsCopy = JSON.parse(JSON.stringify(newState.section["en"].rows));
  //       newState.section["nb"].rows = rowsCopy;
        
  //       if (!isEmpty(newState.microapps)) {
  //         newState.microapps.forEach(microapp => {
  //           // Deep copy
  //           var microAppCopy = JSON.parse(JSON.stringify(microapp.content["en"]));
  //           microapp.content["nb"] = microAppCopy;
  //         });
  //       }
  //     }

  //     newState.selectedRowIndex = null;
  //     newState.rowString = null;
  //     newState.selectedMicroAppId = null;
  //     newState.microappString = null;

  //     return newState;
  //   });
  // }

  getOptionFooter() {

    const sectionChanged = JSON.stringify(this.state.section) !== JSON.stringify(this.props.content.section);

    let microappsChanged = false;
    if (this.state.microapps.length !== this.props.content.microapps.length) {
      microappsChanged = true;
    }
    else if (this.state.microapps.length > 0) {
      this.state.microapps.forEach((microapp, index) => {
        const oldMicroapp = this.props.content.microapps[index];
        if (JSON.stringify(microapp) !== JSON.stringify(oldMicroapp)) {
          microappsChanged = true;
        }
      });
    }

    return (
      <OptionFooter 
        cancel={this.onCancel} 
        options={[
          { 
            label: "Save", 
            callback: this.onSave, 
            disabled: (this.state.title.length === 0) 
              || !(this.state.title !== this.props.content.title || 
              this.state.description !== this.props.content.description || 
              sectionChanged || microappsChanged
              )
          }
        ]} 
      />
    );
  }

  async onUploadImage(event) {
    event.preventDefault();
    var files = event.target.files;
    var file = files[0];

    if (files && file) {
      const url = await this.props.uploadImage(file, file.name);
      this.updateRow({ imageUrl: url });
      if (event.target) event.target.value = ''; // eslint-disable-line no-param-reassign
    }
  }

  onUploadImageClick() {
    this.inputOpenImageRef.current.click();
  }

  render() {
    // console.log("EditContent.render()", this.state);
    // const isCreate = isEmpty(this.props.match.params.id);

    if (this.props.isLoading) {
      return <Loader fullScreen />;
    }

    // Create sections and rows
    let sections = [];

    // If viewing outer section
    if (isEmpty(this.state.selectedMicroAppId)) {
      sections = [this.state.section[this.state.language]];
    }
    // If viewing sections in a micro app
    else {
      const selectedMicroApp = this.state.microapps.find(microapp => microapp.id === this.state.selectedMicroAppId);

      // Show sections from micro app or subview
      if (isEmpty(this.state.selectedSubviewId)) {
        sections = [...selectedMicroApp.content[this.state.language].sections];
      }
      else {
        const selectedSubview = selectedMicroApp.content[this.state.language].subviews.find(subview => subview.id === this.state.selectedSubviewId);
        sections = [...selectedSubview.sections];
      }
    }

    let sectionElements = sections.map((section, sectionIndex) => {
      return (
        <AppSection 
          key={`section-${sectionIndex}`}
          index={sectionIndex} 
          count={sections.length}
          hideArrows={isEmpty(this.state.selectedMicroAppId)}
          disableHover={this.state.rowHover}
          selectedIndex={!isEmpty(this.state.selectedRow) ? null : this.state.selectedSectionIndex} 
          section={section} 
          moveUp={this.moveSectionUp}
          moveDown={this.moveSectionDown}
          onClick={this.onSelectedSection}>
          {
            section.rows.map((row, rowIndex) => {

              let microAppId = null;
              if (!isEmpty(row.onClick)) {
                if (row.onClick.type === "microapp") {
                  if (get(row, "onClick.url", "").includes('{{')) {
                    microAppId = row.onClick.url;
                  }
                  else if (get(row, "onClick.url", false)) {
                    const splitArray = row.onClick.url.split('/microapp/');
                    microAppId = get(splitArray, "[1]", undefined);
                  }
                }
                if (row.onClick.type === "open-subview") {
                  microAppId = row.onClick.id;
                }
              }

              return (
                <AppRow 
                  key={`row-${rowIndex}`}
                  index={rowIndex} 
                  count={section.rows.length}
                  selectedIndex={this.state.selectedRowIndex} 
                  sectionIndex={sectionIndex}
                  selectedSectionIndex={this.state.selectedSectionIndex} 
                  row={row}
                  onClick={this.onSelectedRow}
                  moveUp={this.moveRowUp}
                  moveDown={this.moveRowDown}
                  onMouseEnter={() => this.setState({ rowHover: true })}
                  onMouseLeave={() => this.setState({ rowHover: false })}
                  viewButton={ microAppId && {
                    text: "Subview",
                    onClick: () => { this.gotoMicroApp(microAppId) }
                  }}
                  />
                );
              }
            )
          }
          {
            <AppRow
              key={`row-add`}
              onMouseEnter={() => this.setState({ rowHover: true })}
              onMouseLeave={() => this.setState({ rowHover: false })}
              addButton={{
                text: "Add row",
                onClick: () => this.onAddRow(sectionIndex)
              }}
              />
          }
        </AppSection>
      );
    });

    // If viewing a micro app
    if (!isEmpty(this.state.selectedMicroAppId)) {
      
      // Show add section button
      sectionElements.push((
        <AppSection 
          key={`section-add`}
          addButton={{
            text: "Add section",
            onClick: this.onAddSection
          }}
          />
      ));
    }
    
    return (
      <>
        <Hidden xs sm md>
          <div className={style.editContainer}>
            <div className={style.row}>
              <div className={style.listContainer}>
                <div className={style.scroll}>
                  <Row className={style.topRow}>
                    <Col xs={12} lg={8} xl={9}>
                      <Title text={this.state.title} />
                      { 
                        this.state.breadcrumbs && (
                          <BreadCrumb crumbs={[this.state.breadcrumbs]} onClick={this.onBreadCrumbClick} />
                        )
                      }
                    </Col>
                    <Col xs={12} lg={4} xl={3} offset={{}}>
                      <div style={{ float: "right" }}>
                        <SegmentedControl 
                          name="language"
                          value={this.state.language}
                          onChange={this.onLanguageChange} 
                          options={[ 
                            { label: "English", value: "en", default: true },
                            { label: "Norwegian", value: "nb" }
                          ]}
                          />
                      </div>
                    </Col>
                  </Row>
                  <div style={{ paddingTop: "40px" }} />
                  <Row>
                    <Col>
                      { sectionElements }
                    </Col>
                  </Row>
                  <div style={{ paddingTop: "40px" }} />
                </div>
              </div>
              <div className={style.sideBar}>
                { this.sideBar() }
              </div>
            </div>
          </div>
        </Hidden>
        <Visible xs sm md>
          <div className={style.editContainer}>
            <div className={style.row}>
              <div className={style.col}>
                <div className={style.slimScroll}>
                  <Row className={style.topRow}>
                    <Col>
                      <Freetext header="Edit content" content="" />
                    </Col>
                  </Row>
                  <Row className={style.topRow}>
                    <Col>
                      <Freetext content="You need a bigger screen to manage content" />
                    </Col>
                  </Row>
                </div>
              </div>
            </div>
          </div>
        </Visible>
        { this.getOptionFooter() }
      </>
    );
  }

  sideBar() {

    // No section or row selected
    if (isEmpty(this.state.selectedRow) && isEmpty(this.state.selectedSection)) {
      return null;
    }

    // Check if row is valid
    let validRowJson = false;
    try {
      JSON.parse(this.state.rowString);
      validRowJson = true;
    } catch (error) {}

    // Check if micro app is valid
    let validMicroAppJson = false;
    try {
      JSON.parse(this.state.microappString);
      validMicroAppJson = true;
    } catch (error) {}


    let canDeleteRow = false;
    let canDeleteSection = false;
    // If viewing outer section
    if (isEmpty(this.state.selectedMicroAppId)) {
      canDeleteSection = false;
      canDeleteRow = this.state.section[this.state.language].rows.length > 1;
    }
    // If viewing sections in a micro app
    else {
      const selectedMicroApp = this.state.microapps.find(microapp => microapp.id === this.state.selectedMicroAppId);

      // Show sections from micro app or subview
      if (isEmpty(this.state.selectedSubviewId)) {
        canDeleteSection = selectedMicroApp.content[this.state.language].sections.length > 1;

        if (isNumber(this.state.selectedSectionIndex)) {
          canDeleteRow = selectedMicroApp.content[this.state.language].sections[this.state.selectedSectionIndex].rows.length > 1;
        }
      }
      else {
        const selectedSubview = selectedMicroApp.content[this.state.language].subviews.find(subview => subview.id === this.state.selectedSubviewId);
        canDeleteSection = selectedSubview.sections.length > 1;

        if (isNumber(this.state.selectedSectionIndex)) {
          canDeleteRow = selectedSubview.sections[this.state.selectedSectionIndex].rows.length > 1;
        }
      }
    }

    // If showing selected section //onChange={} 
    if (!isEmpty(this.state.selectedSection)) {
      const header = this.state.selectedSection.header ?? "";
      return (
        <div className={style.scroll}>
          <Row className={style.topRow}>
            <Col>
              <Freetext header="Section" />
            </Col>
            <Col align="end">
              <Row justify="end" nogutter>
                <Button text="Delete" color="red" onClick={this.onDeleteSection} slim disabled={!canDeleteSection} />
              </Row>
            </Col>
          </Row>
          <Row>
            <Col>
              <InputBox label="Header" value={header} onChange={(event) => this.updateSection({ header: event.target.value })} /> 
            </Col>
          </Row>
          <div style={{ paddingTop: "40px" }} />
        </div>
      );
    }
  
    // If showing selected row
    if (!isEmpty(this.state.selectedRow)) {

      // Show different options when in a micro app vs in outer section
      // Do not allow more than 4 levels of microapps/subviews
      let actionOptions = [];
      if (isEmpty(this.state.selectedMicroAppId)) {
        actionOptions = [
          { id: "", name: "No action" }, 
          { id: "microapp", name: "Open subview" }, 
          { id: "open-url", name: "Open web page" },
          { id: "open-app", name: "Open app" },
          { id: "goto-location", name: "Go to location" }
        ];
      }
      else if (this.state.breadcrumbs.length === 4) {
        actionOptions = [
          { id: "", name: "No action" },
          { id: "open-url", name: "Open web page" },
          { id: "open-app", name: "Open app" },
          { id: "goto-location", name: "Go to location" }
        ]
      }
      else {
        actionOptions = [
          { id: "", name: "No action" }, 
          { id: "open-subview", name: "Open subview" }, 
          { id: "open-url", name: "Open web page" },
          { id: "open-app", name: "Open app" },
          { id: "goto-location", name: "Go to location" }
        ]
      }

      return (
        <div className={style.scroll}>
          <Row className={style.topRow}>
            <Col>
              <Freetext header="Row" />
            </Col>
            <Col align="end">
              <Row justify="end" nogutter>
                <Button text="Delete" color="red" onClick={this.onDeleteRow} slim disabled={!canDeleteRow} />
              </Row>
            </Col>
          </Row>
          { inputElements(this.state.selectedRow, this.saveRow, this.updateRow, actionOptions, this.state.language, this.copyTextArea, this.onUploadImageClick, this.onUploadImage, this.inputOpenImageRef) }
          <div style={{ paddingTop: "40px" }} />
        </div>
      );
    }

    return null;
  }
}

function mapStateToProps(state) {
  return {
    content: state.content,
    isLoading: state.loading.content
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    getContent: contentActions.getContent,
    createContent: contentActions.createContent,
    updateContent: contentActions.updateContent,
    clearContent: contentActions.clearContent,
    uploadImage: contentActions.uploadImage
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(EditContent);