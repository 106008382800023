import React from "react";
import { Audio } from "react-loader-spinner";
import PropTypes from "prop-types";
import style from "./style.module.scss";

// Define the type for Loader's props
type LoaderProps = {
  size?: string;
  fullScreen?: boolean;
  inline?: boolean;
};

// Test
const Loader: React.FC<LoaderProps> = ({ size = "75px", fullScreen = false, inline = false }) => {
  if (inline) {
    return (
      <div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
        <Audio color="#ccc" height={size} width={size} /> 
      </div>
    );
  }

  return (
    <>
      <div className={style.loader}>
        <Audio color="#ccc" height={size} width={size} />
      </div>
      {
        fullScreen && (
          <div style={{ width: "100%", height: "100%", backgroundColor: "#F6F6F6" }}></div>
        )
      }
    </>
  );
};

// Define the prop types
Loader.propTypes = {
  size: PropTypes.string,
  fullScreen: PropTypes.bool,
  inline: PropTypes.bool,
};

export default Loader;
