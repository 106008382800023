import React, { useState } from "react";
import Select, { components } from "react-select";
import { Info } from "ui";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import styled from "styled-components";
import styling from "./style.module.scss";

export const DropdownSelection = ({ onChange = () => {}, value, defaultValue = "", options, name = "options", label, help, disabled = false, placeholder = "Select one", info, style, hideWrapper = false }) => {
  
  let labelElement = null;
  if (label && help) {
    labelElement = (
      <div className={styling.labelContainer}>
        { label && <label>{label}</label> }
        { help && <Info text={help} />}
      </div>
    );
  }
  else if (label) {
    labelElement = <label>{label}</label>;
  }
  
  return (
    <div className={label ? styling.dropdownWrapper : styling.dropdownWrapperNoLabel} style={style}>
      { labelElement }
      <div className={styling.dropdownSelector} style={hideWrapper ? { border: 0, marginTop: 0 } : {}}>
        <select name={name} onChange={ e => onChange(e)} value={value} defaultValue={defaultValue} disabled={disabled}>
        <option value="" disabled>{placeholder}</option>
          { options.map((category, i) => {
            if (category.children !== undefined) {
              return (<optgroup key={`og-${i}`} label={category.name}>
                { category.children.map((option, i) => (
                    <option key={`o-${i}`} value={option.id}>{option.name}</option>
                  ))
                }
              </optgroup>)
            }
            
            return (
              <option key={`o-${i}`} value={category.id}>{category.name}</option>
              )
          })}
        </select>
      </div>

      { info && <div className={styling.info}>{info}</div> }
    </div>
  );
}

export const ControlledDropdownSelection = ({ onChange = () => {}, value, options, name = "options", label, help, disabled = false, info, style, hideWrapper = false, noMarginTop = false }) => {
  
  let labelElement = null;
  if (label && help) {
    labelElement = (
      <div className={styling.labelContainer}>
        { label && <label>{label}</label> }
        { help && <Info text={help} />}
      </div>
    );
  }
  else if (label) {
    labelElement = <label>{label}</label>;
  }

  return (
    <div className={label ? styling.dropdownWrapper : styling.dropdownWrapperNoLabel} style={style}>
      { labelElement }
      <div className={styling.dropdownSelector} data-hidewrapper={hideWrapper} data-disabled={disabled} data-nomargintop={noMarginTop}>
        <select name={name} onChange={ e => onChange(e)} value={value} disabled={disabled}>
        { options.map((category, i) => {
            if (category.children !== undefined) {
              return (<optgroup key={`og-${i}`} label={category.name}>
                { category.children.map((option, i) => (
                    <option key={`o-${i}`} value={option.id}>{option.name}</option>
                  ))
                }
              </optgroup>)
            }
            
            return (
              <option key={`o-${i}`} value={category.id}>{category.name}</option>
              )
          })}
        </select>
      </div>

      { info && <div className={styling.info}>{info}</div> }
    </div>
  );
}

const DropdownWrapper = styled.div`
  display: inline-grid;
`;

const LabelContainer = styled.div`
  display: flex;
  justify-content: flex-start;
`;

const Label = styled.label`
  font-family: "Source Sans Pro";
  font-size: 14px;
  font-weight: 600;
  color: #000;
`;

const DropdownSelector = styled.div`
  position: relative;
  border-radius: 4px;
  border: solid 1px #D8D8D8;
  width: 100%;
  overflow: hidden;
  background-color: #fff;
  margin-top: 5px;
  cursor: pointer;
  transition: background-color 0.2s ease, border-color 0.2s ease;

  &:hover {
    background-color: #f5f5f5;
    border-color: #c0c0c0;
  }

  &[data-hidewrapper='true'] {
    border-radius: 4px;
    border: 0;
    width: 100%;
    overflow: hidden;
    background-color: #fff;
    margin-top: 0;
    cursor: pointer;
  }

  &[data-disabled='true'] {
    background-color: #eee;
    cursor: not-allowed;
  }

  &[data-nomargintop='true'] {
    margin-top: 0;
  }

  // &[data-slim='true'] {
  //   border-color: #1C4D82;
  // }
`;

const NewSelect = styled.select`
  font-family: Source Sans Pro;
  font-size: 17px;
  padding: 8px 32px 8px 12px;
  border: none;
  box-shadow: none;
  background: transparent;
  background-image: none;
  -webkit-appearance: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 360px;
  width: 100%;
  color: #444;
  cursor: pointer;

  &:focus {
    outline: none;
  }

  &[data-hidewrapper='true'] {
    color: #666;
    padding: 9px 32px 10px 12px;
    cursor: pointer;
  }

  &[data-slim='true'] {
    // color: #1C4D82;
    color: #333;
    font-weight: 500;
    font-size: 15px;
    user-select: none;
  }
`;

const NewDropdownIndicator = styled.div`
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-43%);
  pointer-events: none;
`;

const NewInfo = styled.div`
  font-size: 14px;
  color: #6e777d;
  float: right;
  padding-right: 4px;
`;

export const NewDropdownSelection = ({ onChange = () => {}, value, defaultValue = "", options, name = "options", label, help, disabled = false, placeholder = "Select one", info, style, hideWrapper = false, noMarginTop = false, slim = false }) => {
  
  let labelElement = null;
  if (label && help) {
    labelElement = (
      <LabelContainer>
        { label && <Label>{label}</Label> }
        { help && <NewInfo>{help}</NewInfo> }
      </LabelContainer>
    );
  } else if (label) {
    labelElement = <Label>{label}</Label>;
  }
  
  return (
    <DropdownWrapper style={style}>
      { labelElement }
      <DropdownSelector hideWrapper={hideWrapper} data-disabled={disabled}  data-nomargintop={noMarginTop} data-slim={slim}>
        <NewSelect name={name} onChange={ e => onChange(e)} value={value} defaultValue={defaultValue} disabled={disabled} data-slim={slim}>
          <option value="" disabled>{placeholder}</option>
          { options.map((category, i) => {
            if (category.children !== undefined) {
              return (
                <optgroup key={`og-${i}`} label={category.name}>
                  { category.children.map((option, i) => (
                      <option key={`o-${i}`} value={option.id}>{option.name}</option>
                    ))
                  }
                </optgroup>
              );
            }
            
            return (
              <option key={`o-${i}`} value={category.id}>{category.name}</option>
            );
          })}
        </NewSelect>
      </DropdownSelector>

      { info && <NewInfo>{info}</NewInfo> }
    </DropdownWrapper>
  );
}

export const NewControlledDropdownSelection = ({ onChange = () => {}, value, options, name = "options", label, help, disabled = false, info, style, hideWrapper = false, noMarginTop = false, slim = false }) => {

  let labelElement = null;
  if (label && help) {
    labelElement = (
      <LabelContainer>
        { label && <Label>{label}</Label> }
        { help && <NewInfo>{help}</NewInfo> }
      </LabelContainer>
    );
  } else if (label) {
    labelElement = <Label>{label}</Label>;
  }

  return (
    <DropdownWrapper style={style}>
      { labelElement }
      <DropdownSelector data-hidewrapper={hideWrapper} data-disabled={disabled} data-nomargintop={noMarginTop} data-slim={slim}>
        <NewSelect name={name} onChange={ e => onChange(e)} value={value} disabled={disabled} data-slim={slim}>
          { options.map((category, i) => {
              if (category.children !== undefined) {
                return (
                  <optgroup key={`og-${i}`} label={category.name}>
                    { category.children.map((option, i) => (
                        <option key={`o-${i}`} value={option.id}>{option.name}</option>
                      ))
                    }
                  </optgroup>
                );
              }
              
              return (
                <option key={`o-${i}`} value={category.id}>{category.name}</option>
              );
            })}
        </NewSelect>
        <NewDropdownIndicator>
          <FontAwesomeIcon icon={faAngleDown} size="lg" color="#333" />
        </NewDropdownIndicator>
      </DropdownSelector>

      { info && <NewInfo>{info}</NewInfo> }
    </DropdownWrapper>
  );
}

const DropdownIndicator = props => {
  return (
    <components.DropdownIndicator {...props}>
      <div style={{ display: "flex", alignItems: "center", justifyContent: "center", width: "100%", height: "100%" }}>
        <FontAwesomeIcon icon={faAngleDown} size="lg" color="#333" style={{ paddingRight: "10px"}}/>
      </div>
    </components.DropdownIndicator>
  );
};

const groupStyles = {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
};

const groupBadgeStyles= {
  backgroundColor: "#EBECF0",
  borderRadius: "2em",
  color: "#172B4D",
  display: "inline-block",
  fontSize: 12,
  fontWeight: "normal",
  lineHeight: "1",
  minWidth: 1,
  padding: "0.16666666666667em 0.5em",
  textAlign: "center",
};

const formatGroupLabel = (data) => (
  <div style={groupStyles}>
    <span>{data.label}</span>
    <span style={groupBadgeStyles}>{data.options.length}</span>
  </div>
);

export const Dropdown = ({ onChange = () => {}, value, options, label, help, disabled = false, info, style, hideWrapper = false }) => {
  // const [menuIsOpen, setMenuIsOpen] = useState(true);

  return (
    <div className={label ? styling.dropdownWrapper : styling.dropdownWrapperNoLabel} style={style}>
      {label && <label>{label}</label>}
      {help && <Info text={help} />}
      <Select
        className="basic-single"
        classNamePrefix="select"
        components={{ DropdownIndicator }}
        styles={{
          container: (base) => ({
            ...base,
            margin: "0px",
            padding: "0px",
            cursor: "pointer",
          }),
          control: () => ({
            display: "flex",
            width: "100%",
            height: "33px",
            borderRadius: "4px",
            border: "solid 1px #D8D8D8",
            backgroundColor: "white",
          }),
          valueContainer: (base) => ({
            ...base,
            display: "flex",
            padding: "0px 4px 0px 10px",
          }),
          option: (base, state) => ({
            ...base,
            color: state.isSelected ? "white" : "black",
            backgroundColor: state.isSelected ? "#1C4D82" : "transparent",
            transition: "background-color 0.2s ease",
            borderRadius: state.isFocused && !state.isSelected ? "4px" : "0px",
            "&:hover": {
              backgroundColor: state.isSelected ? "#1C4D82" : "#ddd",
              color: state.isSelected ? "white" : "black",
            },
            "&:active": {
              backgroundColor: "#1C4D82",
              color: "white",
            },
            // Apply border-radius conditionally
            ":first-of-type": {
              borderTopLeftRadius: "4px",
              borderTopRightRadius: "4px",
            },
            ":last-of-type": {
              borderBottomLeftRadius: "4px",
              borderBottomRightRadius: "4px",
            },
          }),
          indicatorSeparator: () => ({}),
          dropdownIndicator: () => ({}),
          menu: (base) => ({
            ...base,
            width: "max-content",
            minWidth: "100%",
            boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
          }),
          menuList: (base) => ({
            ...base,
            paddingTop: "0px",
            paddingBottom: "0px",
          }),
        }}
        maxMenuHeight={200}
        options={
          options.map((option) => {
            if (option.children !== undefined) {
              return {
                options: option.children,
                label: option.label
              };
            }

            return option;
          })
        }
        onChange={(e) => onChange(e)}
        value={options.find(option => option.value === value)}
        isDisabled={disabled}
        isClearable={false}
        isSearchable={false}
        formatGroupLabel={formatGroupLabel}
        // menuIsOpen={menuIsOpen}
      />

      {info && <div className={styling.info}>{info}</div>}
    </div>
  );
};