import React from "react";
import { Switch, Route } from "react-router-dom";
import SelectType from "./selectType";
import SelectLocation from "./selectLocation";
import SelectInsight from "./selectInsight";
import NotFound from "../NotFound";

const CreateDisplaySwitch = () => (
  <Switch>
    <Route path="/companies/:companyId/config-display/:mode/type" children={(props) => (<SelectType {...props} />)} />
    <Route path="/companies/:companyId/config-display/:mode/locations/:locationId" children={(props) => (<SelectLocation {...props} />)} />
    <Route path="/companies/:companyId/config-display/:mode/insights" children={(props) => (<SelectInsight {...props} />)} />
    <Route component={NotFound} />
  </Switch>
);

export default CreateDisplaySwitch;