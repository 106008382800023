import React, { Component } from "react";
import { Hidden, Visible } from "react-grid-system";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import isEmpty from "lodash/isEmpty";
import Table from "../../components/Table";
import TopRowOptions from "../../components/TopRowOptions";
import SearchBox from "../../components/SearchBox";
import ContentDetails from "./contentDetails";
import * as selectedActions from "../../actions/selected";
import style from "./style.module.scss";

class Content extends Component {

  constructor(props) {
    super(props);
    this.state = {
      searchText: "",
      activeSearch: undefined,
      sortBy: "title",
      sortOrder: "asc",
      highlightedId: undefined
    };

    this.onSearchClick = this.onSearchClick.bind(this);
    this.onSearchChange = this.onSearchChange.bind(this);
    this.onRowClick = this.onRowClick.bind(this);
    this.onToggle = this.onToggle.bind(this);
    this.onHover = this.onHover.bind(this);
  }

  onSearchClick() {
    this.setState({ activeSearch: this.state.searchText === "" ? undefined : this.state.searchText });
  }

  onSearchChange(value) {
    this.setState({ searchText: value });
  }

  onRowClick(column, rowInfo) {
    return {
      onClick: e => {
        if (rowInfo && column.name !== "isSelected") {
          this.props.history.push(`/companies/${this.props.match.params.companyId}/locations/${this.props.locationId}/content/${rowInfo.original._id}`);
        }
      },
      onMouseOver: e => {
        if (rowInfo && rowInfo.original._id) {
          this.onHover(rowInfo.original._id);
        }
      },
      onMouseLeave: e => this.onHover(null),
      style: {
        cursor: "pointer",
        background: (
          rowInfo && rowInfo.original._id === this.state.highlightedId ? "rgba(0,0,0,0.05)" : (rowInfo && rowInfo.original._id === this.props.contentId ? "rgba(0,0,0,0.05)" : null)
        )
      }
    }
  }

  onHover(id) {
    if (this.state.highlightedId !== id) {
      this.setState({ highlightedId: id });
    }
  }

  onToggle(row) {
    if (this.props.selectedContent[row.original._id] !== undefined && this.props.selectedContent[row.original._id]) {
      this.props.deselectContent(row.original._id);
    }
    else {
      this.props.selectContent(row.original);
    }
  }

  render() {

    const hasSelection = !isEmpty(this.props.selectedLocations) || 
      !isEmpty(this.props.selectedSensors) || 
      !isEmpty(this.props.selectedGateways) || 
      !isEmpty(this.props.selectedContent) || 
      !isEmpty(this.props.selectedUserGroups);

    const options = (
      <TopRowOptions
        searchbox={(
          <SearchBox
            value={this.state.searchText}
            onSearchChanged={this.onSearchChange}
            onSearchClick={this.onSearchClick}
            onClear={() => this.setState({ searchText: "", activeSearch: undefined }, this.onSearchClick)}
            inListView
          />
        )}
      />
    );

    const anotherTypeSelected = this.props.selectedTypes.length > 0 && !this.props.selectedTypes.includes("content");

    // Filter out content not in the search
    let filteredContent = this.props.locationContent;
    if (this.state.activeSearch) {
      filteredContent = filteredContent.filter((content) => {
        return content.title.toLowerCase().includes(this.state.activeSearch.toLowerCase());
      });
    }

    // Sort the content
    filteredContent = filteredContent.sort((a, b) => {
      if (this.state.sortOrder === "asc") {
        return a.title.localeCompare(b.title);
      }
      else {
        return b.title.localeCompare(a.title);
      }
    });

    const tableElement = (
      <Table
        data={filteredContent}
        sortBy={this.state.sortBy}
        sortOrder={this.state.sortOrder}
        onSortedChange={(newSorted) => {
          this.setState({ sortBy: newSorted[0].id, sortOrder: newSorted[0].desc ? "desc" : "asc" });
        }}
        noDataText={this.props.isLoading ? "" : "No content found"}
        columns={[
          {
            id: "id",
            header: "",
            accessorKey: "_id",
            sortable: false,
            name: "isSelected",
            cell: ({ row }) => (
              <label className="checkboxContainer" htmlFor={`editCheckbox-${row.original._id}`}>
                <input
                  id={`editCheckbox-${row.original._id}`}
                  type="checkbox"
                  className="checkbox"
                  checked={this.props.selectedContent[row.original._id] !== undefined && this.props.selectedContent[row.original._id]}
                  onChange={() => this.onToggle(row)}
                  disabled={anotherTypeSelected}
                />
                <span className={anotherTypeSelected ? "disabledCheckmark" : "checkmark"} />
              </label>
            ),
            width: 60
          },
          {
            header: "Title",
            accessorKey: "title"
          },
          {
            id: "arrow",
            header: "",
            sortable: false,
            className: "pull-right",
            width: 60,
            cell: () => <div className="arrow" />
          }
        ]}
        getTdProps={this.onRowClick}
        className="setMaxHeigth -row-clickable -highlight"
        loading={this.props.isLoading}
      />
    );

    let tableStyle = hasSelection ? style.listContainer : style.listContainerHidden;
    
    return (
      <>
        <Hidden xs sm md>
          <div className={tableStyle}>
            <div className={style.row}>
              <div className={style.col50}>
                <div className={style.scroll}>
                  {options}
                  {tableElement}
                  <div style={{ paddingTop: "40px" }} />
                </div>
              </div>
              <div className={style.mapPart40}>
                <div className={style.scroll}>
                  { !isEmpty(this.props.contentId) && <ContentDetails {...this.props} />}
                </div>
              </div>
            </div>
          </div>
        </Hidden>
        <Visible xs sm md>
          <div className={tableStyle}>
            <div className={style.row}>
              <div className={style.col} style={{ backgroundColor: !isEmpty(this.props.contentId) && "#F6F6F6" }}>
                <div className={style.slimScroll}>
                  { isEmpty(this.props.contentId) ? (
                    <>
                      {options}
                      {tableElement}
                      <div style={{ paddingTop: "40px" }} />
                    </>
                  ) : (
                    <ContentDetails {...this.props} />
                  )}
                </div>
              </div>
            </div>
          </div>
        </Visible>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    locationContent: state.location.content,
    isLoading: state.loading.location,
    selectedContent: state.selected.content,
    selectedLocations: state.selected.locations,
    selectedSensors: state.selected.sensors,
    selectedGateways: state.selected.gateways,
    selectedUserGroups: state.selected.userGroups,
    selectedTypes: state.selected.allTypes,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ 
    selectContent: selectedActions.selectContent,
    deselectContent: selectedActions.deselectContent,
    clearSelection: selectedActions.clearSelection
   }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(Content);
