import React from "react";
import styled from "styled-components";

interface ListCardProps {
  title?: string;
  children?: React.ReactNode;
}

const ListCard: React.FC<ListCardProps> = ({ title, children }) => {

  if (title) {
    return (
      <Container>
        <Header>
          <Title>
            { title }
          </Title>
        </Header>
        <Content>
          { children }
        </Content>
      </Container>
    );
  }

  return (
    <Container>
      <Content>
        { children }
      </Content>
    </Container>
  );
};

const Container = styled.div`
  display: block;
  background-color: rgb(255, 255, 255);
  border-radius: 6px;
  border-width: 1px;
  border-style: solid;
  border-color: rgb(221, 221, 221);
  margin-bottom: 20px;
  overflow: hidden;
`;

const Header = styled.div`
  display: flex;
  padding: 16px 20px;
`;

const Title = styled.div`
  display: inline-block;
  font-family: Source Sans Pro;
  font-size: 18px;
  font-weight: 600;
  color: #222222;
`;

const Content = styled.div`
  display: block;
  background-color: #fff;
  box-sizing: border-box
`;

export default ListCard;
