import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import isEmpty from "lodash/isEmpty";
import InputBox from "../../components/InputBox";
import { getRoleName } from "../../helpers";

class UserDetailsInvite extends Component {

  constructor(props) {
    super(props);
  }

  render() {
    // console.log("UserDetailsInvite.render()", this.props);
    const { isLoading } = this.props;

    if (isEmpty(this.props.inviteId) || isLoading) {
      return null;
    }

    return (
      <>
        <InputBox label="Email" value={this.props.invite.email} readOnly style={{ maxWidth: "400px" }} />
        <InputBox label="Access level" value={getRoleName(this.props.invite.role)} readOnly style={{ maxWidth: "120px" }} />
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    invite: state.invite,
    selectedCompany: state.auth.selectedCompany,
    isLoading: state.invite.isLoading,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
   
   }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(UserDetailsInvite);
