import get from "lodash/get";
import { toast } from "react-toastify";
import * as types from "../ApiTypes";

const initialState = {
  data: {
    count: 0,
    queue: [],
    limit: 20
  },
  queryParams: {
    search: undefined,
    offset: 0,
    limit: 20
  }
};

export default function jobGroupsReducer(state = initialState, action) {

  switch (action.type) {

    case types.REQ_DATA: {
      if (action.fetchType === types.GET_JOB_GROUPS) {
        return {...state, data: initialState.data };
      }

      return state;
    }

    case types.RECV_DATA: {
      if (action.fetchType === types.GET_JOB_GROUPS) {
        return {...state, data: action.payload, queryParams: action.metadata };
      }

      return state;
    }

    case types.RECV_ERROR: {

      const statusCode = get(action, "payload.response.status", "Error");

      if (action.fetchType === types.GET_JOB_GROUPS) {
        toast.error(`${statusCode}: Could not get jobs`);
        return {...state, data: initialState.data };
      }
      
      return state;
    }

    case types.CLEAR_DATA: {
      return initialState;
    }
    
    default: {
      return state;
    }
  }
}
