import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { get, isEmpty } from "lodash";
import Loader from "../../components/Loader";
import MapboxMap from "../Map/MapboxMap";
import Freetext from "../../components/Freetext";
import InputGroup from "../../components/InputGroup";
import OptionFooter from "../../components/OptionFooter";
import * as authActions from "../../actions/auth";
import * as locationActions from "../../actions/locations";
import * as sensorActions from "../../actions/sensors";
import * as selectedActions from "../../actions/selected";
import style from "./style.module.scss";

class NewManualWebexSensor extends Component {

  constructor(props) {
    super(props);
    this.state = {
      name: "",
      description: "",
      id: props.match.params.id
    };

    this.onBackClick = this.onBackClick.bind(this);
    this.onNameChange = this.onNameChange.bind(this);
    this.onDescriptionChange = this.onDescriptionChange.bind(this);
    this.onSaveWithMap = this.onSaveWithMap.bind(this);
    this.onSaveWithoutMap = this.onSaveWithoutMap.bind(this);
    this.onCancel = this.onCancel.bind(this);

    if (props.match.params.id !== props.location.id) {
      props.getLocation(props.match.params.id);
      props.getFloorMap(props.match.params.id);
      // props.getCompanyMap();
    }
  }

  onBackClick(id) {
    if (get(this.props.location, "state.canGoBack", false)) {
      this.props.history.goBack();
    }
    else {
      this.props.history.push(`/companies/${this.props.match.params.companyId}/locations/${id}/sensors`);
    }
  }

  onNameChange(event) {
    this.setState({ name: event.target.value });
  }

  onDescriptionChange(event) {
    this.setState({ description: event.target.value });
  }

  onSaveWithMap() {
    const { push } = this.props.history;

    const body = {
      name: this.state.name,
      locationIds: [this.props.location.id],
      skipProvisioning: true
    };

    if (!isEmpty(this.state.description)) {
      body.description = this.state.description;
    }

    if (this.props.createdFeature) {
      body.geoJsonFeature = this.props.createdFeature;
    }

    this.props.createSensor("webex-manual", body, this.props.location.id, push);
  }

  onSaveWithoutMap() {
    const { push } = this.props.history;

    const body = {
      name: this.state.name,
      locationIds: [this.props.location.id],
      skipProvisioning: true
    };

    if (!isEmpty(this.state.description)) {
      body.description = this.state.description;
    }

    this.props.createSensor("webex-manual", body, this.props.location.id, push);
  }

  onCancel() {
    this.onBackClick(this.state.id);
  }

  render() {
    if (this.props.isLoading) {
      return <Loader fullScreen />;
    }

    let mapElement;
    if (this.props.location.floorMap) {
      mapElement = (
        <div className={style.mapPart}>
          <MapboxMap 
            mapId="new-sensor-map-container" 
            id={this.state.id} 
            company={this.props.company} 
            location={this.props.location} 
            map={this.props.location.floorMap} 
            drawMode="draw_point"
            showSensors
            drawSensor
            showMap
          />
          <div className={style.mapHelp}>
            <span>Sensors are </span>
            <span style={{ color: "#0080FF" }}>blue</span>
            <span>.</span>
          </div>
        </div>
      );
    }
    else if (this.props.companyMap) {
      mapElement = (
        <div className={style.mapPart}>
          <MapboxMap 
            mapId="new-sensor-map-container" 
            id={this.state.id} 
            company={this.props.company} 
            location={this.props.location} 
            map={this.props.companyMap} 
            drawMode="draw_point"
            showSensors
            drawSensor
            showMap
          />
          <div className={style.mapHelp}>
            <span>Sensors are </span>
            <span style={{ color: "#0080FF" }}>blue</span>
            <span>.</span>
          </div>
        </div>
      );
    }
    
    // Options for saving
    const options = [];
    if (isEmpty(this.props.createdFeature)) {
      options.push({ 
        label: "Save without map", 
        callback: this.onSaveWithoutMap, 
        disabled: (this.state.name.length < 3)
      });
    }
    else {
      options.push({ 
        label: "Save with map", 
        callback: this.onSaveWithMap, 
        disabled: (this.state.name.length < 3)
      });
    }

    return (
      <div className={style.editContainer}>
        <div className={style.row}>
          <div className={style.col50}>
            <div className={style.scroll}>
              <div className={style.topRow}>
                <Freetext header={`Add sensor to ${this.props.location.name}`} />
              </div>
              <h3>1. Enter ID, name and description for sensor</h3>
              <InputGroup onChange={this.onNameChange} label="Name (minimum 3 characters)" placeholder="e.g. K2B sensor" value={this.state.name} valid={this.state.name.length > 2} required />
              <InputGroup onChange={this.onDescriptionChange} label="Description (optional)" placeholder="e.g. The sensor is on the north wall" value={this.state.description} valid={this.state.description} />
            </div>
          </div>
          <div className={style.col50}>
            <h3 className={style.mapHeader}>2. Place sensor in the map</h3>
            { mapElement }
          </div>
        </div>
        <OptionFooter 
          cancel={this.onCancel} 
          cancelButtonLabel="Cancel"
          options={options} 
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    location: state.location,
    sensor: state.sensor,
    isLoading: state.loading.sensors,
    company: state.auth.selectedCompany,
    createdFeature: state.selected.createdFeature
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    getLocation: locationActions.getLocation,
    clearSelection: selectedActions.clearSelection,
    createSensor: sensorActions.createSensor,
    getFloorMap: locationActions.getFloorMap,
    getCompanyMap: authActions.getCompanyMap
  }, dispatch);
}

export default connect(mapStateToProps,mapDispatchToProps)(NewManualWebexSensor);