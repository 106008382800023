import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Col, Row, Hidden, Visible } from "react-grid-system";
import moment from "moment";
import { isEmpty } from "lodash";
import Table from "../../components/Table";
import SearchBox from "../../components/SearchBox";
import TopRowOptions from "../../components/TopRowOptions";
import Button from "../../components/Button";
import SegmentedControl from "../../components/SegmentedControl";
import InputGroup from "../../components/InputGroup";
import { ControlledDropdownSelection } from "../../components/DropdownSelection";
import { resourceTypeSelector } from "../../locationHelpers";
import * as locationActions from "../../actions/locations";
import style from "./style.module.scss";

class Resources extends Component {

  constructor(props) {
    super(props);
    this.state = {
      id: props.locationId,
      resources: [],
      selectedResources: {},
      resourceType: "",
      resourceAllDay: false,
      resourceStart: "00:00",
      resourceEnd: "01:00",
      resourceCapacity: 1,
      searchText: "",
      activeSearch: undefined,
      sortBy: "type",
      sortOrder: "asc",
    };
    this.onSearchClick = this.onSearchClick.bind(this);
    this.onSearchChange = this.onSearchChange.bind(this);
    this.onSortedChange = this.onSortedChange.bind(this);
    this.onResourceTypeChange = this.onResourceTypeChange.bind(this);
    this.onResourceAllDayChange = this.onResourceAllDayChange.bind(this);
    this.onResourceStartChange = this.onResourceStartChange.bind(this);
    this.onResourceEndChange = this.onResourceEndChange.bind(this);
    this.onResourceCapacityChange = this.onResourceCapacityChange.bind(this);
    this.onResourceTimeValid = this.onResourceTimeValid.bind(this);
    this.addNewResource = this.addNewResource.bind(this);
    this.updateResource = this.updateResource.bind(this);
    this.deleteResources = this.deleteResources.bind(this);
    this.onToggleResource = this.onToggleResource.bind(this);
    this.save = this.save.bind(this);
  }
  
  static getDerivedStateFromProps(nextProps, prevState) {

    if (prevState.hasLoadedState) {
      return null;
    }

    // Only copy props if they exist and if location id is correct
    if (!isEmpty(nextProps.location) && nextProps.locationId === nextProps.location.id) {

      const newState = {
        id: nextProps.location.id,
        resources: nextProps.location.bookableResources,
        hasLoadedState: true
      };

      return newState;
    }

    return null;
  }

  onSearchClick() {
    this.setState({ activeSearch: this.state.searchText === "" ? undefined : this.state.searchText });
  }

  onSearchChange(value) {
    this.setState({ searchText: value });
  }

  onSortedChange(newSorted) {
    const sortBy = newSorted[0].id;
    const sortOrder = newSorted[0].desc ? "desc" : "asc";
    this.setState({ sortBy, sortOrder });
  }

  onResourceTypeChange(event) {
    this.setState({ resourceType: event.target.value });
  }

  onResourceAllDayChange(value) {
    if (value) {
      this.setState({ 
        resourceAllDay: true,
        resourceStart: "00:00",
        resourceEnd: "23:59" 
      });
    }
    else {
      this.setState({ 
        resourceAllDay: false
      });
    }
  }

  onResourceStartChange(event) {
    this.setState({ resourceStart: event.target.value });
  }

  onResourceEndChange(event) {
    this.setState({ resourceEnd: event.target.value });
  }

  onResourceTimeValid(value) {
    if (!/^([0-1]?[0-9]|2[0-3]):([0-5][0-9])(:[0-5][0-9])?$/.test(value)) {
      return false;
    }

    const startDate = moment(this.state.resourceStart, ['h:m', 'H:m']).toDate();
    const endDate = moment(this.state.resourceEnd, ['h:m', 'H:m']).toDate();

    if (startDate >= endDate) {
      return false;
    }
    
    return true;
  }

  onResourceCapacityChange(event) {
    let capacity = parseInt(event.target.value);
    this.setState({ resourceCapacity: capacity });
  }

  addNewResource() {
    this.setState(prevState => {
      var newBookableResources = [...prevState.resources];
      const startDate = moment(prevState.resourceStart, ['h:m', 'H:m']).toDate();
      const endDate = moment(prevState.resourceEnd, ['h:m', 'H:m']).toDate();
      const newResource = {
        type: prevState.resourceType,
        capacity: prevState.resourceCapacity,
        startTime: {
          hours: startDate.getHours(),
          minutes: startDate.getMinutes()
        },
        endTime: {
          hours: endDate.getHours(),
          minutes: endDate.getMinutes()
        }
      };
      newBookableResources.push(newResource);
      return { resources: newBookableResources };
    }, () => {
      this.save();
    });
  }

  updateResource() {
    if (Object.keys(this.state.selectedResources).length === 1) {
      const key = Object.keys(this.state.selectedResources)[0];

      this.setState(prevState => {
        var newBookableResources = [...prevState.resources];
        const startDate = moment(prevState.resourceStart, ['h:m', 'H:m']).toDate();
        const endDate = moment(prevState.resourceEnd, ['h:m', 'H:m']).toDate();
        const newResource = {
          type: prevState.resourceType,
          capacity: prevState.resourceCapacity,
          startTime: {
            hours: startDate.getHours(),
            minutes: startDate.getMinutes()
          },
          endTime: {
            hours: endDate.getHours(),
            minutes: endDate.getMinutes()
          }
        };
  
        newBookableResources[key] = newResource;
        return { 
          resources: newBookableResources,
          selectedResources: {},
          resourceType: "",
          resourceAllDay: false,
          resourceStart: "00:00",
          resourceEnd: "01:00",
          resourceCapacity: 1
        };
      }, () => {
        this.save();
      });
    }
  }

  deleteResources() {
    if (!isEmpty(this.state.selectedResources)) {
      this.setState(prevState => {
        let newBookableResources = [...prevState.resources];
        newBookableResources = newBookableResources.filter((_, index) => {
          const indexString = String(index);
          return !Object.keys(prevState.selectedResources).includes(indexString);
        });
        
        return { 
          resources: newBookableResources, 
          selectedResources: {},
          resourceType: "",
          resourceAllDay: false,
          resourceStart: "00:00",
          resourceEnd: "01:00",
          resourceCapacity: 1
        };
      }, () => {
        this.save();
      });
    }
  }

  onToggleResource(row) {
    let selectedResource = null; 
    if (this.state.selectedResources[row.index] !== undefined && this.state.selectedResources[row.index]) {
      this.setState(prevState => {
        const newSelectedResources = {...prevState.selectedResources};
        delete newSelectedResources[row.index];
        
        if (Object.keys(newSelectedResources).length === 1) {
          const key = Object.keys(newSelectedResources)[0];
          selectedResource = newSelectedResources[key];

          const allDay = (selectedResource.endTime.hours === 23 && selectedResource.endTime.minutes === 59);
          const start = `${selectedResource.startTime.hours < 10 ? "0" : ""}${selectedResource.startTime.hours}:${selectedResource.startTime.minutes < 10 ? "0" : ""}${selectedResource.startTime.minutes}`;
          const end = `${selectedResource.endTime.hours < 10 ? "0" : ""}${selectedResource.endTime.hours}:${selectedResource.endTime.minutes < 10 ? "0" : ""}${selectedResource.endTime.minutes}`;

          return { 
            selectedResources: newSelectedResources,
            resourceType: selectedResource.type,
            resourceCapacity: selectedResource.capacity,
            resourceAllDay: allDay,
            resourceStart: start,
            resourceEnd: end
          };
        }

        return { 
          selectedResources: newSelectedResources,
          resourceType: "",
          resourceAllDay: false,
          resourceStart: "00:00",
          resourceEnd: "01:00",
          resourceCapacity: 1
        };
      });
    }
    else {
      this.setState(prevState => {
        const newSelectedResources = {...prevState.selectedResources};
        newSelectedResources[row.index] = row.original;

        if (Object.keys(newSelectedResources).length === 1) {
          const key = Object.keys(newSelectedResources)[0];
          selectedResource = newSelectedResources[key];

          const allDay = (selectedResource.endTime.hours === 23 && selectedResource.endTime.minutes === 59);
          const start = `${selectedResource.startTime.hours < 10 ? "0" : ""}${selectedResource.startTime.hours}:${selectedResource.startTime.minutes < 10 ? "0" : ""}${selectedResource.startTime.minutes}`;
          const end = `${selectedResource.endTime.hours < 10 ? "0" : ""}${selectedResource.endTime.hours}:${selectedResource.endTime.minutes < 10 ? "0" : ""}${selectedResource.endTime.minutes}`;

          return { 
            selectedResources: newSelectedResources,
            resourceType: selectedResource.type,
            resourceCapacity: selectedResource.capacity,
            resourceAllDay: allDay,
            resourceStart: start,
            resourceEnd: end
          };
        }

        return { 
          selectedResources: newSelectedResources,
          resourceType: "",
          resourceAllDay: false,
          resourceStart: "00:00",
          resourceEnd: "01:00",
          resourceCapacity: 1
        };
      });
    }
  }

  save() {
    const body = {};

    // Add bookable resources
    if (!isEmpty(this.state.resources)) {
      // Convert to objects
      body.bookableCapacities = {};
      this.state.resources.forEach(resource => {
        body.bookableCapacities[resource.type] = [];
      });
      this.state.resources.forEach(resource => {
        body.bookableCapacities[resource.type].push({ ...resource, type: undefined, _id: undefined });
      });
    }
    else {
      body.bookableCapacities = {};
    }

    this.props.editLocation(this.props.location, body, null);
  }

  render() {

    const hasSelection = !isEmpty(this.props.selectedLocations) || 
      !isEmpty(this.props.selectedSensors) || 
      !isEmpty(this.props.selectedGateways) || 
      !isEmpty(this.props.selectedContent) || 
      !isEmpty(this.props.selectedUserGroups);
    
    const heading = (
      <TopRowOptions
        searchbox={(
          <SearchBox
            value={this.state.searchText}
            onSearchChanged={this.onSearchChange}
            onSearchClick={this.onSearchClick}
            onClear={() => { this.setState({ searchText: "" }, this.onSearchClick) }}
            inListView
          />
        )}
      />
    );

    // Filter resources
    let filteredResources = this.state.resources;
    if (this.state.activeSearch) {
      filteredResources = filteredResources.filter((resource) => {
        return resource.type.toLowerCase().includes(this.state.activeSearch.toLowerCase());
      });
    }

    // Sort the resources
    filteredResources = filteredResources.sort((a, b) => {
      if (this.state.sortOrder === "asc") {
        return a.type.localeCompare(b.type);
      }
      else {
        return b.type.localeCompare(a.type);
      }
    });

    // Create table for resources
    const anotherTypeSelected = this.props.selectedTypes.length > 0 && !this.props.selectedTypes.includes("resource");
    const resourcesTable = createResourcesTable(filteredResources, this.state.selectedResources, anotherTypeSelected, this.onToggleResource, this.state.sortBy, this.state.sortOrder, this.onSortedChange, this.props.isLoading);

    // Action buttons
    let actionButtons;
    const selectedResourcesCount = Object.keys(this.state.selectedResources).length;
    if (selectedResourcesCount === 0) {
      actionButtons = (
        <Row>
          <Col sm={12} md={4} offset={{ md: 8 }}>
            <Button 
              text="Add" 
              disabled={isEmpty(this.state.resourceType) || isEmpty(this.state.resourceStart) || isEmpty(this.state.resourceEnd) || this.state.resourceCapacity < 1}
              onClick={this.addNewResource}
              padding
            />
          </Col>
        </Row>
      );
    }
    else if (selectedResourcesCount === 1) {
      actionButtons = (
        <Row>
          <Col sm={12} md={4} offset={{ md: 4 }}>
            <Button 
              text="Delete" 
              color="red"
              onClick={this.deleteResources}
              padding
            />
          </Col>
          <Col sm={12} md={4} offset={{ md: 0}}>
            <Button 
              text="Update" 
              disabled={isEmpty(this.state.resourceType) || isEmpty(this.state.resourceStart) || isEmpty(this.state.resourceEnd) || this.state.resourceCapacity < 1}
              onClick={this.updateResource}
              padding
            />
          </Col>
        </Row>
      );
    }
    else {
      actionButtons = (
        <Row>
          <Col sm={12} md={4} offset={{ md: 8 }}>
            <Button 
              text="Delete" 
              color="red"
              onClick={this.deleteResources}
              padding
            />
          </Col>
        </Row>
      );
    }

    // Add resource elements
    const addResourceElements = (
      <>
      <Row className={style.topRow}>
        <p>Add a new resource timeslot below or edit one by selecting it.</p>
      </Row>
      <Row>
        <Col sm={12} md={4}>
          <ControlledDropdownSelection
            options={resourceTypeSelector}
            value={this.state.resourceType}
            name="newResourceType" 
            onChange={this.onResourceTypeChange} 
            label="Type" 
          />
        </Col>
        <Col sm={12} md={4}>
          <div className={style.segmentControllPadding}>
            <SegmentedControl 
              title="Timeslot"
              name="all day"
              value={this.state.resourceAllDay}
              onChange={this.onResourceAllDayChange}
              options={[ 
                { label: "All day", value: true },
                { label: "Custom", value: false }
              ]} 
            />
          </div>
        </Col>
      </Row>
      <Row>
        <Col sm={12} md={4}>
          <InputGroup type="string" onChange={this.onResourceStartChange} label="Start" valid={this.onResourceTimeValid(this.state.resourceStart)} value={this.state.resourceStart} required hideicon={1} disabled={this.state.resourceAllDay} />
        </Col>
        <Col sm={12} md={4}>
          <InputGroup type="string" onChange={this.onResourceEndChange} label="End" valid={this.onResourceTimeValid(this.state.resourceEnd)} value={this.state.resourceEnd} required hideicon={1} disabled={this.state.resourceAllDay} />
        </Col>
        <Col sm={12} md={4}>
          <InputGroup type="number" onChange={this.onResourceCapacityChange} label="Capacity" valid={(this.state.resourceCapacity > 0)} value={this.state.resourceCapacity} required hideicon={1} />
        </Col>
      </Row>
      { actionButtons }
      </>
    );

    let tableStyle = hasSelection ? style.listContainer : style.listContainerHidden;
    let tableMobileStyle = hasSelection ? style.listContainerMobile : style.listContainerHiddenMobile;

    return (
      <>
        <Hidden xs sm md>
          <div className={tableStyle}>
            <div className={style.row}>
              <div className={style.col50}>
                <div className={style.scroll}>
                  { heading }
                  { resourcesTable }
                  <div style={{ paddingTop: "40px" }} />
                </div>
              </div>
              <div className={style.mapPart40}>
                <div className={style.scroll}>
                  { this.props.locationId !== "root" && addResourceElements }
                </div>
              </div>
            </div>
          </div>
        </Hidden>
        <Visible xs sm md>
          <div className={tableMobileStyle}>
            <div className={style.row}>
              <div className={style.col}>
                <div className={style.slimScroll}>
                  { heading }
                  { resourcesTable }
                  <div style={{ paddingTop: "40px" }} />
                </div>
              </div>
            </div>
          </div>
        </Visible>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    selectedCompany: state.auth.selectedCompany,
    location: state.location,
    isLoading: state.loading.location,
    hoveredFeature: state.selected.hoveredFeature,
    companyMap: state.auth.map,
    selectedContent: state.selected.content,
    selectedLocations: state.selected.locations,
    selectedSensors: state.selected.sensors,
    selectedGateways: state.selected.gateways,
    selectedUserGroups: state.selected.userGroups,
    selectedTypes: state.selected.allTypes,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    editLocation: locationActions.editLocation
   }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(Resources);

export const createResourcesTable = (resources, selectedResources, anotherTypeSelected, onToggleResource, sortBy, sortOrder, onSortedChange, isLoading) => {
  return (
    <Table
      data={resources}
      sortBy={sortBy}
      sortOrder={sortOrder}
      onSortedChange={onSortedChange}
      noDataText={isLoading ? "" : "No resources found"}
      columns={[
        {
          id: "id",
          header: "",
          sortable: false,
          name: "isSelected",
          cell: ({ row }) => (
            <label className="checkboxContainer" htmlFor={`editCheckbox-${row.index}`}>
              <input
                id={`editCheckbox-${row.index}`}
                type="checkbox"
                className="checkbox"
                checked={(selectedResources[row.index] !== undefined && selectedResources[row.index])}
                onChange={() => onToggleResource(row)}
                disabled={anotherTypeSelected}
              />
              <span className={anotherTypeSelected ? "disabledCheckmark" : "checkmark"} />
            </label>
          ),
          width: 60
        },
        {
          header: "Type", 
          accessorKey: "type", 
        },
        {
          header: "Start", 
          accessorKey: "startTime",
          sortable: false,
          cell: ({ row }) => {
            const timeString = `${row.original.startTime.hours < 10 ? "0" : ""}${row.original.startTime.hours}:${row.original.startTime.minutes < 10 ? "0" : ""}${row.original.startTime.minutes}`;
            return <span title={timeString}>{timeString}</span>
          }
        },
        {
          header: "End", 
          accessorKey: "endTime",
          sortable: false,
          cell: ({ row }) => {
            const timeString = `${row.original.endTime.hours < 10 ? "0" : ""}${row.original.endTime.hours}:${row.original.endTime.minutes < 10 ? "0" : ""}${row.original.endTime.minutes}`;
            return <span title={timeString}>{timeString}</span>
          }
        },
        {
          header: "Capacity", 
          accessorKey: "capacity",
          sortable: false,
        }
      ]}
      className="-row-clickable setMaxHeigth"
    />
  );
}