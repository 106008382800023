import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Container, Col, Row } from "react-grid-system";
import ReactQRCode from "qrcode.react";
import get from "lodash/get";
import Loader from "../../components/Loader";
import LogoBar from "../../components/LogoBar";
import Freetext from "../../components/Freetext";
import InsightView from "./insightView";
import * as displayActions from "../../actions/display";
import style from "./style.module.scss";

let embed = null;
let timer = null;

class DisplayView extends Component {

  constructor(props) {
    super(props);
    this.state = {
      urlDisplayCode: props.match.params.code
    };
    this.getDisplayConfig = this.getDisplayConfig.bind(this);
    this.onEmbedded = this.onEmbedded.bind(this);
    this.receiveMessage = this.receiveMessage.bind(this);
  }

  componentDidMount() {
    document.title = `BLDNG.ai - Display`;
    timer = window.setTimeout(this.getDisplayConfig, 1000);
    window.addEventListener("message", this.receiveMessage, false);
  }

  shouldComponentUpdate(nextProps) {
    // console.log("Dashboard.shouldComponentUpdate()", this.props, nextProps);
    
    // Check for changes in config
    const isFloorIdChanged = get(nextProps, "displayConfig.map.floorId", undefined) !== get(this.props, "displayConfig.map.floorId", undefined);
    const isNameChanged = get(nextProps, "displayConfig.map.config.name", undefined) !== get(this.props, "displayConfig.map.config.name", undefined);
    const isShowTitleChanged = get(nextProps, "displayConfig.map.config.showTitle", undefined) !== get(this.props, "displayConfig.map.config.showTitle", undefined);
    const isShowLocationMenuChanged = get(nextProps, "displayConfig.map.config.showLocationMenu", undefined) !== get(this.props, "displayConfig.map.config.showLocationMenu", undefined);
    const isShowFloorMenuChanged = get(nextProps, "displayConfig.map.config.showFloorMenu", undefined) !== get(this.props, "displayConfig.map.config.showFloorMenu", undefined);
    const isShowLabelsChanged = get(nextProps, "displayConfig.map.config.showSBLabels", undefined) !== get(this.props, "displayConfig.map.config.showSBLabels", undefined);
    const isAllowInteractionsChanged = get(nextProps, "displayConfig.map.config.allowInteractions", undefined) !== get(this.props, "displayConfig.map.config.allowInteractions", undefined);
    const isShowCompassChanged = get(nextProps, "displayConfig.map.config.showCompass", undefined) !== get(this.props, "displayConfig.map.config.showCompass", undefined);
    const isShowVacantChanged = get(nextProps, "displayConfig.map.config.showVacant", undefined) !== get(this.props, "displayConfig.map.config.showVacant", undefined);
    const isShowOccupiedChanged = get(nextProps, "displayConfig.map.config.showOccupied", undefined) !== get(this.props, "displayConfig.map.config.showOccupied", undefined);
    const isInitialBearingChanged = get(nextProps, "displayConfig.map.config.initialBearing", undefined) !== get(this.props, "displayConfig.map.config.initialBearing", undefined);
    const isYourLocationLatChanged = get(nextProps, "displayConfig.map.config.yourLocation.lat", undefined) !== get(this.props, "displayConfig.map.config.yourLocation.lat", undefined);
    const isYourLocationLngChanged = get(nextProps, "displayConfig.map.config.yourLocation.lng", undefined) !== get(this.props, "displayConfig.map.config.yourLocation.lng", undefined);
    const isYourLocationColorChanged = get(nextProps, "displayConfig.map.config.yourLocation.color", undefined) !== get(this.props, "displayConfig.map.config.yourLocation.color", undefined);
    const isStatusFilterChanged = get(nextProps, "displayConfig.map.config.statusFilter", undefined) !== get(this.props, "displayConfig.map.config.statusFilter", undefined);
    const isLabelFilterChanged = get(nextProps, "displayConfig.map.config.labelFilter", undefined) !== get(this.props, "displayConfig.map.config.labelFilter", undefined);
    const isIconFilterChanged = get(nextProps, "displayConfig.map.config.iconFilter", undefined) !== get(this.props, "displayConfig.map.config.iconFilter", undefined);
    const isReportIdChanged = get(nextProps, "displayConfig.powerbi.reportId", undefined) !== get(this.props, "displayConfig.powerbi.reportId", undefined);
    const isReportTokenExpirationChanged = get(nextProps, "displayConfig.powerbi.expiration", undefined) !== get(this.props, "displayConfig.powerbi.expiration", undefined);
    const isDashboardTokenChanged = get(nextProps, "displayConfig.dashboard.token", undefined) !== get(this.props, "displayConfig.dashboard.token", undefined);
    const isDashboardChanged = get(nextProps, "displayConfig.dashboard", undefined) !== get(this.props, "displayConfig.dashboard", undefined);


    const isRestartMessageDateChanged = nextProps.restartMessageDate !== this.props.restartMessageDate;
    const isErrorStateChanged = nextProps.error !== this.props.error;

    // console.log("isFloorIdChanged", isFloorIdChanged);
    // console.log("isFloorLockChanged", isFloorLockChanged);
    // console.log("isReportIdChanged", isReportIdChanged);
    // console.log("isReportTokenExpirationChanged", isReportTokenExpirationChanged);
    // console.log("isRestartMessageDateChanged", isRestartMessageDateChanged);

    if (isFloorIdChanged || 
      isNameChanged ||
      isShowTitleChanged ||
      isShowLocationMenuChanged || 
      isShowFloorMenuChanged || 
      isShowLabelsChanged ||
      isAllowInteractionsChanged ||
      isShowCompassChanged ||
      isShowVacantChanged ||
      isShowOccupiedChanged ||
      isInitialBearingChanged ||
      isYourLocationLatChanged ||
      isYourLocationLngChanged ||
      isYourLocationColorChanged ||
      isStatusFilterChanged ||
      isLabelFilterChanged ||
      isIconFilterChanged ||
      isReportIdChanged || 
      isReportTokenExpirationChanged || 
      isRestartMessageDateChanged ||
      isDashboardChanged ||
      isDashboardTokenChanged ||
      isErrorStateChanged) {
      return true;
    }
    
    return false;
  }

  componentWillUnmount() {
    if (timer) {
      clearTimeout(timer);
      timer = null;
    }
    if (embed) {
      embed = null;
    }
    window.removeEventListener("message");
  }

  onEmbedded(embedObject) {
    embed = embedObject;
  }

  getDisplayConfig() {
    let code;
    if (this.state.urlDisplayCode) {
      code = this.state.urlDisplayCode;
    }
    else {
      code = this.props.displayCode;
    }

    this.props.getDisplayConfig(code);
    timer = window.setTimeout(this.getDisplayConfig, 60*1000);
  }

  receiveMessage(event) {
    // console.log("Dashboard.receiveMessage()", event);
    if (event.data === "restart") {
      this.props.setRestartMessageDate(new Date().toISOString());
    }
  }

  render() {
    if (this.props.displayConfig) {
      
      // Show report
      const report = get(this.props.displayConfig, "powerbi", null);
      if (report) {
        return (
          <div className="fullscreen">
          </div>
        );
      }
      const dashboard = get(this.props.displayConfig, "dashboard", null);
      const companyId = get(this.props.displayConfig, "companyId", null);
      
      if (dashboard) {
        return (
          <InsightView 
            companyId={companyId} 
            displayId={this.state.urlDisplayCode || this.props.displayCode } 
            dashboardId={dashboard.dashboardId} 
            accessToken={dashboard.token} 
            refresh={() => {
              this.props.setRestartMessageDate(new Date().toISOString());
            }} 
          />
        );
      }
      
      // Show room map
      const mapCode = get(this.props.displayConfig, "map.code", null);
      if (companyId && mapCode) {

        const floorId = get(this.props.displayConfig, "map.floorId", null);
        const floorLevel = get(this.props.displayConfig, "map.floorLevel", null);
        const floorLocked = get(this.props.displayConfig, "map.config.floorLocked", null);

        if (floorId && floorLevel && floorLocked) {
          return <iframe title="Map" src={`${this.props.roomUrl}?code=${mapCode}&company=${companyId}&fid=${floorId}&fl=${floorLevel}`} />;
        }
        
        return <iframe title="Map" src={`${this.props.roomUrl}?code=${mapCode}&company=${companyId}`} />;
      }
    }

    let code;
    if (this.state.urlDisplayCode) {
      code = this.state.urlDisplayCode;
    }
    else {
      code = this.props.displayCode;
    }

    if (this.props.error) {
      return (
        <>
          <LogoBar />
          <div className={style.page}>
            <Container className={style.container}>
              <Row className={style.topRow}>
                <Col>
                  <Freetext header="Display code" content="Use your phone to configure what to show here." />
                </Col>
              </Row>
              <Row>
                <Col>
                  <ReactQRCode value={`${window.location.origin}/connect?code=${code}`} size={500} />
                </Col>
              </Row>
            </Container>
          </div>
        </>
      );
    }
    
    // Show loader until getDisplayConfig returns with either a config or an error
    return <Loader fullScreen />;
  }
  
}

function mapStateToProps(state) {
  return {
    displayCode: state.displayView.displayCode,
    displayConfig: state.displayView.displayConfig,
    roomUrl: state.displayView.roomUrl,
    restartMessageDate: state.displayView.restartMessageDate,
    error: state.displayView.error,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ 
    getDisplayConfig: displayActions.getDisplayConfig,
    setRestartMessageDate: displayActions.setRestartMessageDate
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(DisplayView);
