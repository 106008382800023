import React, { Component } from "react";
import { Route, Switch } from 'react-router-dom';
import { Hidden, Visible } from "react-grid-system";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import isEmpty from "lodash/isEmpty";
import AdminList from "./adminList";
import UserDetails from "./userDetails";
import style from "./style.module.scss";

class Admins extends Component {

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    if (!this.props.location.pathname.endsWith("/groups") && 
        !this.props.location.pathname.endsWith("/locations") &&
        !this.props.location.pathname.endsWith("/content")) {
      document.title = `BLDNG.ai - Org. admins`;
    }
  }

  render() {
    // console.log("Admins.render()", this.props)
    const hasSelection = !isEmpty(this.props.selectedLocations) || 
                         !isEmpty(this.props.selectedUserGroups) || 
                         !isEmpty(this.props.selectedContent) || 
                         !isEmpty(this.props.selectedUsers);

    const showOptionFooter = !isEmpty(this.props.userDraft);

    return (
      <>
        <Hidden xs sm md>
          <div className={ (hasSelection || showOptionFooter) ? style.mainContainer : style.mainContainerHidden }>
            <div className={style.row}>
              <div className={style.listContainer}>
                <div className={style.scroll}>
                  <AdminList { ...this.props} />
                </div>
              </div>
              <div className={style.sideBar}>
                <div className={style.scroll}>
                  <UserDetails { ...this.props} />
                </div>
              </div>
            </div>
          </div>
        </Hidden>
        <Visible xs sm md>
          <div className={ (hasSelection || showOptionFooter) ? style.mainContainer : style.mainContainerHidden } style={{ backgroundColor: !isEmpty(this.props.userId) && "#F6F6F6" }}>
            <div className={style.slimScroll}>
              {
                isEmpty(this.props.userId) ? (<AdminList { ...this.props} />) : (<UserDetails { ...this.props} />)
              }
            </div>
          </div>
        </Visible>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    selectedLocations: state.selected.locations,
    selectedUserGroups: state.selected.userGroups,
    selectedContent: state.selected.content,
    selectedUsers: state.selected.users,
    selectedCompany: state.auth.selectedCompany,
    userDraft: state.user.draft,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ 
    
   }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(Admins);
