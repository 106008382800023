import get from "lodash/get";
import { toast } from "react-toastify";
import * as types from "../ApiTypes";

const initialState = {
  usage: {}
};

export default function appUsageReducer(state = initialState, action) {

  switch (action.type) {

    case types.REQ_DATA: {
      if (action.fetchType === types.GET_UNIQUE_USER_LOGINS) {
        return initialState;
      }
      
      return state;
    }

    case types.RECV_DATA: {
      if (action.fetchType === types.GET_UNIQUE_USER_LOGINS) {
        const usage = get(action.payload, "[0]", {});
        return { usage };
      }
      
      return state;
    }

    case types.RECV_ERROR: {

      const statusCode = get(action, "payload.response.status", "Error");

      if (action.fetchType === types.GET_UNIQUE_USER_LOGINS) {
        toast.error(`${statusCode}: Could not get app usage`);
        return initialState;
      }
      
      return state;
    }

    case types.CLEAR_DATA: {
      return initialState;
    }

    default: {
      return state;
    }
  }
}
