import React, { Component } from "react";
import get from "lodash/get";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPercent as faUtilization, faUserPlus as faPeopleCount, faChevronRight, faChevronLeft, faGreaterThan, faHome, faLocationDot } from "@fortawesome/free-solid-svg-icons";
import { faFolderPlus } from "@fortawesome/pro-duotone-svg-icons";
import { getBreadcrumbLocations, getLocation, getParentLocation } from "../../../locationHelpers";
import { RowIcon } from "../../../components/RowIcon";
import styled from "styled-components";
import SmallButton from "../../../components/SmallButton";
import strings from "./strings";

class LocationFilter extends Component {

  constructor(props) {
    super(props);
    this.state = {
      inspectedLocationId: null,
      selectedLocationId: this.props.selectedLocation ? this.props.selectedLocation.id : null,
      hasChanges: false
    };
    this.onRowClick = this.onRowClick.bind(this);
    this.onBreadCrumbClick = this.onBreadCrumbClick.bind(this);
    this.onLocationClick = this.onLocationClick.bind(this);
    this.onApply = this.onApply.bind(this);

    // Set the inspected location to the selected location parent
    if (this.props.selectedLocation) {
      const inspectedLocation = getParentLocation(this.props.topLocation, this.props.selectedLocation.id);
      this.state.inspectedLocationId = inspectedLocation.id || "top";
    }
  }

  onRowClick(column, rowInfo) {
    return {
      onClick: e => {
        if (rowInfo && column.name !== "isSelected" && rowInfo.original.children && rowInfo.original.children.length > 0) {
          this.setState({ inspectedLocationId: rowInfo.original.id });
        }
      },
      style: {
        cursor: "pointer",
        position: "relative"
      }
    }
  }

  onBreadCrumbClick(id) {
    // console.log("onBreadCrumbClick", id, this.state);
    this.setState({ inspectedLocationId: id })
  }

  onLocationClick(id) {

    let currentLocationId = null;
    if (this.props.tempLocationId) {
      currentLocationId = this.props.tempLocationId;
    }
    else if (this.props.selectedLocation) {
      currentLocationId = this.props.selectedLocation.id;
    }

    const isEqualToLocalId = currentLocationId === id;
    
    let hasChanges = currentLocationId !== id;
    if (hasChanges) {
      this.setState({ selectedLocationId: id, hasChanges });
      this.props.onLocationChanged(id);
    }
  }

  onApply() {
    this.props.onLocationSaved(this.state.selectedLocationId, this.props.onLocationChanged(null));
    this.props.onClose();
  }

  render() {
    const { topLocation, companyName } = this.props;

    strings.setLanguage(localStorage.getItem("language") || "en");

    let currentLocationId = null;
    if (this.props.tempLocationId) {
      currentLocationId = this.props.tempLocationId;
    }
    else if (this.props.selectedLocation) {
      currentLocationId = this.props.selectedLocation.id;
    }

    const currentLocation = getLocation(topLocation, currentLocationId);
    let breadcrumbLocations = getBreadcrumbLocations(topLocation, currentLocationId) || [];
    const parent = breadcrumbLocations.length > 0 ? breadcrumbLocations[breadcrumbLocations.length - 1] : topLocation;
    const siblings = parent.children || [];
    const children = get(currentLocation, "children", []);

    // Remove top breadcrumb (company name)
    breadcrumbLocations = breadcrumbLocations.slice(1);

    const siblingRows = siblings.map((location, index) => {

      const bgColorUtilization = location.hasUtilizationData ? "rgb(7, 126, 128)" : "#888888";
      const tooltipUtilization = location.hasUtilizationData ? strings.hasUtilizationData : strings.noUtilizationData;
      const childHasUtilizationData = location.childHasUtilizationData;

      const bgColorPeopleCount = location.hasPeopleCountData ? "#1C4D82" : "#888888";
      const tooltipPeopleCount = location.hasPeopleCountData ? strings.hasPeopleCountData : strings.noPeopleCountData;
      const childHasPeopleCountData = location.childHasPeopleCountData;

      return (
        <Row key={index} $selected={currentLocationId === location.id} onClick={() => this.onLocationClick(location.id)}>
          <RowLeft>
            <RowText>{location.name}</RowText>
          </RowLeft>
          <RowRight>
            <div style={{ position: "relative", height: "100%" }}>
              <RowIcon tooltip={tooltipUtilization} bgColor={bgColorUtilization} icon={faUtilization} />
              {
                childHasUtilizationData && (
                  <div style={{ position: "absolute", bottom: "-3px", right: "-3px", display: "flex" }}>
                    <FontAwesomeIcon icon={faFolderPlus} size={"sm"} style={{ "--fa-primary-color": "white", "--fa-secondary-color": "#141f3c", "--fa-primary-opacity": 1, "--fa-secondary-opacity": 1 }}/>
                  </div>
                )
              }
            </div>
            <div style={{ position: "relative", height: "100%", marginLeft: "5px" }}>
              <RowIcon tooltip={tooltipPeopleCount} bgColor={bgColorPeopleCount} icon={faPeopleCount} />
              {
                childHasPeopleCountData && (
                  <div style={{ position: "absolute", bottom: "-3px", right: "-3px", display: "flex" }}>
                    <FontAwesomeIcon icon={faFolderPlus} size={"sm"} style={{ "--fa-primary-color": "white", "--fa-secondary-color": "#141f3c", "--fa-primary-opacity": 1, "--fa-secondary-opacity": 1 }}/>
                  </div>
                )
              }
            </div>
            <ArrowContainer>
              { location.children && location.children.length > 0 && <FontAwesomeIcon icon={faChevronRight} size={"sm"} /> }
            </ArrowContainer>
          </RowRight>
        </Row>
      );
    });

    const childrenRows = children.map((location, index) => {

      const bgColorUtilization = location.hasUtilizationData ? "rgb(7, 126, 128)" : "#888888";
      const tooltipUtilization = location.hasUtilizationData ? strings.hasUtilizationData : strings.noUtilizationData;
      const childHasUtilizationData = location.childHasUtilizationData;

      const bgColorPeopleCount = location.hasPeopleCountData ? "#1C4D82" : "#888888";
      const tooltipPeopleCount = location.hasPeopleCountData ? strings.hasPeopleCountData : strings.noPeopleCountData;
      const childHasPeopleCountData = location.childHasPeopleCountData;

      return (
        <Row key={index} onClick={() => this.onLocationClick(location.id)}>
          <RowLeft>
            <RowText>{location.name}</RowText>
          </RowLeft>
          <RowRight>
            <div style={{ position: "relative", height: "100%" }}>
              <RowIcon tooltip={tooltipUtilization} bgColor={bgColorUtilization} icon={faUtilization} />
              {
                childHasUtilizationData && (
                  <div style={{ position: "absolute", bottom: "-3px", right: "-3px", display: "flex" }}>
                    <FontAwesomeIcon icon={faFolderPlus} size={"sm"} style={{ "--fa-primary-color": "white", "--fa-secondary-color": "#141f3c", "--fa-primary-opacity": 1, "--fa-secondary-opacity": 1 }}/>
                  </div>
                )
              }
            </div>
            <div style={{ position: "relative", height: "100%", marginLeft: "5px" }}>
              <RowIcon tooltip={tooltipPeopleCount} bgColor={bgColorPeopleCount} icon={faPeopleCount} />
              {
                childHasPeopleCountData && (
                  <div style={{ position: "absolute", bottom: "-3px", right: "-3px", display: "flex" }}>
                    <FontAwesomeIcon icon={faFolderPlus} size={"sm"} style={{ "--fa-primary-color": "white", "--fa-secondary-color": "#141f3c", "--fa-primary-opacity": 1, "--fa-secondary-opacity": 1 }}/>
                  </div>
                )
              }
            </div>
            <ArrowContainer>
              { location.children && location.children.length > 0 && <FontAwesomeIcon icon={faChevronRight} size={"sm"} /> }
            </ArrowContainer>
          </RowRight>
        </Row>
      );
    });

    let backButton = null;
    if (parent.id) {
      backButton = (
        <LocationColumn>
          <Row onClick={() => this.onLocationClick(parent.id)}>
            <RowLeft>
              <BackArrowContainer>
                <FontAwesomeIcon icon={faChevronLeft} size={"sm"} />
              </BackArrowContainer>
              <RowText>{strings.back}</RowText>
            </RowLeft>
          </Row>
        </LocationColumn>
      );
    }
    
    return (
      <Container onClick={this.props.onClose}>
        <ScrollBox>
          <Block onClick={(e) => e.stopPropagation()}>
            <Header>
              <Title>{strings.locationTitle}</Title>
              <SmallButton text={strings.close} color="white-gray" onClick={this.props.onClose} style={{ margin: 0, padding: "0 10px", minWidth: "70px" }}  />
              <SmallButton text={strings.save} onClick={this.onApply} style={{ margin: 0, padding: "0 10px", minWidth: "70px" }} disabled={!this.state.selectedLocationId}  />
            </Header>
            <BlockContent>
              <LeftBar>
                <Breadcrumb>
                  <Clickable onClick={() => this.onLocationClick(null)}>
                    <FontAwesomeIcon icon={faHome} size={"sm"} />
                  </Clickable>
                </Breadcrumb>

                { breadcrumbLocations.map((breadcrumb, index) => {
                  return (
                    <Breadcrumb key={index} $isLast={index === breadcrumbLocations.length - 1}>
                      <FontAwesomeIcon icon={faGreaterThan} size={"xs"} style={{ marginRight: "5px" }} color="#555" />
                      <Clickable onClick={() => this.onLocationClick(breadcrumb.id)}>{breadcrumb.name}</Clickable>
                    </Breadcrumb>
                  );
                })}

                <LocationTitle>
                  <FontAwesomeIcon icon={faGreaterThan} size={"xs"} style={{ marginRight: "5px" }} color="#555" />
                  { currentLocation ? currentLocation.name : strings.selectLocation }
                </LocationTitle>

              </LeftBar>

              <ThreeColumns>
                { backButton }
                { siblingRows.length > 0 && (
                  <LocationColumn>
                    { siblingRows }
                  </LocationColumn>
                )}
                { childrenRows.length > 0 ? (
                  <LocationColumn>
                    { childrenRows }
                  </LocationColumn>
                ) : (
                  <LocationColumn>
                  </LocationColumn>
                )}
              </ThreeColumns>

              <BlockInnerDescription>
                {
                  strings.formatString(
                    strings.locationDescription,
                    <RowIcon bgColor={"rgb(7, 126, 128)"} icon={faUtilization} fontSize="10px" styles={{ width: "18px", height: "18px", marginLeft: "3px", marginRight: "3px" }} />,
                    <RowIcon bgColor={"#1C4D82"} icon={faPeopleCount} fontSize="8px" styles={{ width: "18px", height: "18px", marginLeft: "3px", marginRight: "3px" }} />,
                    <FontAwesomeIcon icon={faFolderPlus} size={"sm"} style={{ "--fa-primary-color": "white", "--fa-secondary-color": "#141f3c", "--fa-primary-opacity": 1, "--fa-secondary-opacity": 1, marginLeft: "3px", marginRight: "3px" }}/>
                  )
                }
              </BlockInnerDescription>
            
            </BlockContent>
          </Block>
        </ScrollBox>
      </Container> 
    );
  }
}

export default LocationFilter;

const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  z-index: 3;
`;

const ScrollBox = styled.div`
  position: relative;
  overflow: auto;
  max-height: 100%;
  height: 100%;
  padding: 30px;
  box-sizing: border-box;
`;

const Block = styled.div`
  position: relative;
  background-color: rgb(255, 255, 255);
  border-radius: 6px;
  border-width: 1px;
  border-style: solid;
  border-color: rgb(221, 221, 221);
  margin-left: "10px";
  margin-right: "10px";
  font-size: 16px;
  font-weight: 400;

  max-width: 1100px;
  margin: 0 auto;
`;

const BlockContent = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  position: relative;
  // min-height: 350px;
  padding: 10px 20px;
  font-size: 16px;
  font-weight: 400;
  justify-content: flex-start;
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  padding: 0 20px;
  box-shadow: inset 0 -1px 0 0 #DBDBDB;
  box-sizing: border-box;
  gap: 10px;
`;

const Title = styled.div`
  font-size: 20px;
  font-weight: 600;
  color: #222222;
  flex-grow: 1;
  flex-basis: 0;
`;

const BottomRight = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  margin-top: 10px;
  margin-bottom: 20px;
  margin-right: 20px;
  z-index: 3;
`;

const Breadcrumb = styled.div`
  display: inline-block;
  cursor: default;
  font-size: 16px;
  font-weight: 600;
  margin-right: 5px;
  color: #1C4D82;
`;

const LocationTitle = styled.div`
  display: inline-block;
  cursor: default;
  font-size: 16px;
  font-weight: 600;
  color: #333;
  margin-right: 5px;
`;

const Clickable = styled.div`
  display: inline-block;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
`;

const LeftBar = styled.div`
  display: inline-block;
  width: 100%;
  vertical-align: top;
  margin-top: 15px;
`;

const BlockInnerDescription = styled.div`
  font-size: 16px;
  font-weight: 400;
  color: #666;
  margin-bottom: 15px;
`;

const ThreeColumns = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-top: 15px;
  margin-bottom: 30px;
`;

const LocationColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  box-sizing: border-box;
  width: calc(33.33% - 10px);
  margin-right: 15px;

  &:last-child {
    margin-right: 0;
  }
`;

const Row = styled.div`
  display: flex;
  width: 100%;
  height: 50px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-top: 5px;
  padding-bottom: 5px;
  box-sizing: border-box;
  border: 1px solid #ddd;
  border-bottom: none;

  &:first-child {
    border-radius: 6px 6px 0 0;
  }

  &:last-child {
    border-radius: 0 0 6px 6px;
    border-bottom: 1px solid #ddd;
  }

  &:first-child:last-child {
    border-radius: 6px;
  }

  background-color: ${props => props.$selected && !props.disabled ? "#f3f3f3" : "none"};
  cursor: ${props => props.disabled ? "default" : "pointer"};
  
  &:hover {
    background-color: ${props => props.disabled ? "none" : "#f3f3f3"};
  }
`;

const RowLeft = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  flex-grow: 1;
  margin-left: 12px;
  height: 40px;
  font-size: 15px;
  font-weight: 400;
  color: #333;
  margin-right: 12px;
  overflow: hidden;

`;

const RowRight = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin-right: 12px;
  // height: 50px;
  width: 99px;
`;

const RowText = styled.div`
  // font-size: 15px;
  // font-weight: 400;
  // color: #333;
  // overflow: hidden;
  // text-overflow: ellipsis;

  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  line-clamp: 2; 
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;

  // text-overflow: ellipsis;
  // word-wrap: break-word;
  // line-height: 18px;
  // max-height
`;

const ArrowContainer = styled.div`
  margin-left: 12px;
  width: 10px;
`;

const BackArrowContainer = styled.div`
  margin-right: 12px;
  height: 18px;
`;