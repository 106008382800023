import React, { Component } from "react";
import { Switch, Route, Redirect, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import moment from "moment";
import { get, isEmpty } from "lodash";
import Loader from "../../components/Loader";
import SensorDetails from "./sensorDetails";
import NotFound from "../NotFound";
import Modal from "../../components/Modal";
import SubNavigation from "../../components/SubNavigation";
import LargeHeader from "../../components/LargeHeader";
import AtomicSensors from "./atomicSensors";
import SensorUptime from "./sensorUptime";
import NoAccess from "../NoAccess";
import * as selectedActions from "../../actions/selected";
import * as sensorActions from "../../actions/sensors";
import style from "./style.module.scss";

class SensorContainer extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
      showDeleteModal: false,
      deleteAtomicSensorId: null,
      deleteStartDate: null,
      deleteEndDate: null
    };

    this.onEditNameSave = this.onEditNameSave.bind(this);
    this.getUpdatedString = this.getUpdatedString.bind(this);
    this.onDeleteDataClicked = this.onDeleteDataClicked.bind(this);
    this.onDeleteDataConfirmed = this.onDeleteDataConfirmed.bind(this);
    
    // Only load data if sensor change
    if (props.sensor.id !== props.match.params.id) {
      props.getSensor(props.match.params.id);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    // Only load data if sensor change
    if (this.props.sensor.id !== this.props.match.params.id) {
      this.props.getSensor(this.props.match.params.id);
    }
  }

  onEditNameSave(name) {
    if (isEmpty(name)) { return; }
    this.props.updateSensor(this.props.sensor.id, { name });
  }

  getUpdatedString() {
    const updatedAt = get(this.props.sensor, `previousMessageAt`, null);
    if (!updatedAt) {
      return `?`;
    }

    return `${moment(updatedAt).format("HH:mm:ss - DD/MM/YY")}`;
  }

  onDeleteDataClicked(atomicSensorId, selectionStartDate, selectionEndDate) {
    this.setState({
      showDeleteModal: true,
      deleteAtomicSensorId: atomicSensorId,
      deleteStartDate: selectionStartDate,
      deleteEndDate: selectionEndDate
    });
  }

  onDeleteDataConfirmed() {
    this.props.deleteSamples(this.props.sensor.id, this.state.deleteAtomicSensorId, this.state.deleteStartDate, this.state.deleteEndDate);
    this.setState({
      showDeleteModal: false,
      deleteAtomicSensorId: null,
      deleteStartDate: null,
      deleteEndDate: null
    });
  }

  render() {
    const canViewSensor = this.props.auth.hasAdminRole;
    const canUpdateSensor = this.props.auth.hasInstallerRole;

    if (!canViewSensor) {
      return <NoAccess />;
    }

    if (this.props.isLoading) {
      return <Loader fullScreen />;
    }

    if (!get(this.props.sensor, "id", false)) {
      return <Loader fullScreen />;
    }

    const subNavigationLinks = [];
    subNavigationLinks.push({ pathname: `/companies/${this.props.match.params.companyId}/sensors/${this.props.match.params.id}/details`, label: `Details` });
    subNavigationLinks.push({ pathname: `/companies/${this.props.match.params.companyId}/sensors/${this.props.match.params.id}/atomic-sensors`, label: `Atomic sensors` });
    subNavigationLinks.push({ pathname: `/companies/${this.props.match.params.companyId}/sensors/${this.props.match.params.id}/uptime`, label: `Uptime` });

    return (
      <>
        <div className={style.container}>
          <LargeHeader
            title={this.props.sensor.name}
            rightText={`last sample ${this.getUpdatedString()}`}
            canUpdateName={true}
            onNameSaveClicked={this.onEditNameSave}
          />
          <SubNavigation links={subNavigationLinks} /> 
          <Switch>
            <Redirect exact from="/companies/:companyId/sensors/:id" to={{ pathname: `/companies/:companyId/sensors/${this.props.match.params.id}/details` }} />
            <Route path="/companies/:companyId/sensors/:id/details" children={(props) => <SensorDetails {...props} id={props.match.params.id} />} />
            <Route path="/companies/:companyId/sensors/:id/atomic-sensors/:atomicSensorId?" children={(props) => <AtomicSensors {...props} id={props.match.params.id} onDeleteDataClicked={this.onDeleteDataClicked} />} />
            <Route path="/companies/:companyId/sensors/:id/uptime" children={(props) => <SensorUptime {...props} id={props.match.params.id} />} />
            <Route>
              <NotFound />
            </Route>
          </Switch>
        </div>

        <Modal
          show={this.state.showDeleteModal}
          onHide={() => this.setState({ showDeleteModal: false })}
          title="Permanently delete data"
          text={`Are you sure you want to delete the data between ${this.state.deleteStartDate && this.state.deleteStartDate.format("DD/MM/YYYY HH:mm:ss")} and ${this.state.deleteEndDate && this.state.deleteEndDate.format("DD/MM/YYYY HH:mm:ss")}?`}
          primaryBtn={{
            text: "Delete",
            onClick: this.onDeleteDataConfirmed,
            color: "red"
          }}
          secondaryBtn={{
            text: "Close",
            onClick: () => this.setState({ showDeleteModal: false })
          }}
        />
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    sensor: state.sensor,
    currentLocation: state.location,
    auth: state.auth,
    selectedCompany: state.auth.selectedCompany,
    isLoading: state.loading.sensor,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    getSensor: sensorActions.getSensor,
    updateSensor: sensorActions.updateSensor,
    selectSensor: selectedActions.selectSensor,
    deleteSamples: sensorActions.deleteSamples
   }, dispatch);
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SensorContainer));
