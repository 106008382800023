import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Switch, Route, Redirect } from "react-router-dom";
import isEmpty from "lodash/isEmpty";
import NotFound from "../NotFound";
import DetailsHeader from "../../components/DetailsHeader";
import SubNavigation from "../../components/SubNavigation";
import GroupUsers from "./groupDetailsUsers";
import GroupLocations from "./groupDetailsLocations";
import GroupContent from "./groupDetailsContent";
import GroupSettings from "./groupDetailsSettings";
import * as userGroupActions from "../../actions/userGroups";

class GroupDetails extends Component {

  constructor(props) {
    super(props);
    // console.log("GroupDetails.constructor",this.props);

    this.onEditNameSave = this.onEditNameSave.bind(this);
    this.onDeleteClicked = this.onDeleteClicked.bind(this);

    if (isEmpty(this.props.groupId)) {
      this.props.clearUserGroup();
    }
    else if (this.props.userGroup.id !== this.props.groupId) {
      this.props.getUserGroup(this.props.groupId);
    }
  }

  componentDidUpdate(prevProps) {
    // console.log("GroupDetails.componentDidUpdate",this.props);
    // Only load data if id change
    if (isEmpty(this.props.groupId)) {
      this.props.clearUserGroup();
    }
    else if (prevProps.groupId !== this.props.groupId) {
      this.props.getUserGroup(this.props.groupId);
    }
  }

  onEditNameSave(name) {
    const body = {};

    if (name.length > 0) {
      body.name = name;
    }

    this.props.updateUserGroup(this.props.userGroup.id, body, true);
  }

  onDeleteClicked() {
    this.props.deleteUserGroup(this.props.userGroup.id);
  }

  render() {
    // console.log("GroupDetails.render()", this.props)
    const { isLoading } = this.props;

    if (isEmpty(this.props.groupId) || isEmpty(this.props.userGroup.id)) {
      return null;
    }

    const subNavigationLinks = [];
    subNavigationLinks.push({ pathname: `/companies/${this.props.match.params.companyId}/org/groups/${this.props.groupId}/users`, label: `Users` });
    subNavigationLinks.push({ pathname: `/companies/${this.props.match.params.companyId}/org/groups/${this.props.groupId}/locations`, label: `Locations` });
    subNavigationLinks.push({ pathname: `/companies/${this.props.match.params.companyId}/org/groups/${this.props.groupId}/content`, label: `Content` });
    subNavigationLinks.push({ pathname: `/companies/${this.props.match.params.companyId}/org/groups/${this.props.groupId}/settings`, label: `Settings` });

    const title = this.props.userGroup.name ?? "";

    return (
      <div>
        <DetailsHeader
          title={title}
          canUpdate={this.props.userGroup.canEdit}
          canUpdateName={this.props.userGroup.canEdit}
          canDelete={this.props.userGroup.canDelete}
          isLoading={isLoading}
          onNameSaveClicked={this.onEditNameSave}
          onDeleteClicked={this.onDeleteClicked}
        />
        <SubNavigation links={subNavigationLinks} slim />
        <Switch>
          <Redirect exact from={`/companies/:companyId/org/groups/${this.props.groupId}`} to={{ pathname: `/companies/${this.props.match.params.companyId}/org/groups/${this.props.groupId}/users` }} />
          <Route path="/companies/:companyId/org/groups/:groupId/users" children={(props) => <GroupUsers {...props} groupId={this.props.groupId} />} />
          <Route path="/companies/:companyId/org/groups/:groupId/locations" children={(props) => <GroupLocations {...props} groupId={this.props.groupId} />} />
          <Route path="/companies/:companyId/org/groups/:groupId/content" children={(props) => <GroupContent {...props} groupId={this.props.groupId} />} />
          <Route path="/companies/:companyId/org/groups/:groupId/settings" children={(props) => <GroupSettings {...props} groupId={this.props.groupId} />} />
          <Route component={NotFound} />
        </Switch>
        <div style={{ paddingTop: "40px" }} />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    userGroup: state.userGroup,
    selectedCompany: state.auth.selectedCompany
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    getUserGroup: userGroupActions.getUserGroup,
    updateUserGroup: userGroupActions.updateUserGroup,
    deleteUserGroup: userGroupActions.deleteUserGroup,
    clearUserGroup: userGroupActions.clearUserGroup
   }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(GroupDetails);
