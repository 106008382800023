import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import * as authActions from "../../actions/auth";
import * as locationActions from "../../actions/locations";
import * as types from "../../ApiTypes";

// Fetch company map (or floor map if needed)
class MapContainer extends Component {

  constructor(props) {
    // console.log("MapContainer.constructor");
    super(props);

    props.getCompanyMap();
  }

  render() {
    // console.log("MapContainer.props", this.props);
    return this.props.children;
  }
}

function mapStateToProps(state) {
  return {
    locationHierarchy: state.locations.hierarchy,
    selectedCompany: state.auth.selectedCompany,
    selectedCompanyId: state.auth.selectedCompany._id,
    isLoadingHierarchy: state.loading[types.GET_LOCATION_HIERARCHY],
    isLoadingFloorMap: state.loading[types.GET_FLOOR_MAP],
    isLoadingCompanyMap: state.loading[types.GET_COMPANY_MAP],
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    getFloorMap: locationActions.getFloorMap,
    getCompanyMap: authActions.getCompanyMap,
   }, dispatch);
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MapContainer));