import get from "lodash/get";
import { toast } from "react-toastify";
import * as types from "../ApiTypes";

const initialState = {
  data: {
    count: 0,
    usergroups: [],
    limit: 20
  }
};

export default function userGroupsReducer(state = initialState, action) {

  switch (action.type) {
    
    case types.REQ_DATA: {
      if (action.fetchType === types.GET_USER_GROUPS) {
        return initialState;
      }

      return state;
    }

    case types.RECV_DATA: {
      if (action.fetchType === types.GET_USER_GROUPS) {
        return { data: action.payload };
      }

      if (action.fetchType === types.CREATE_USER_GROUP) {
        toast.success("User group created");
        return { data: action.payload };
      }
      
      return state;
    }

    case types.RECV_ERROR: {

      const statusCode = get(action, "payload.response.status", "Error");

      if (action.fetchType === types.GET_USER_GROUPS) {
        toast.error(`${statusCode}: Could not get user groups`);
        return state;
      }

      if (action.fetchType === types.CREATE_USER_GROUP) {
        toast.error(`${statusCode}: Could not create user groups`);
        return state;
      }
      
      return state;
    }

    case types.CLEAR_DATA: {
      return initialState;
    }

    default: {
      return state;
    }
  }
}
