import React, { Component } from "react";
import { Container, Col, Row, Hidden, Visible } from "react-grid-system";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { isEmpty } from "lodash";
import moment from "moment";
import UAParser from "ua-parser-js";
import Freetext from "../../components/Freetext";
import Loader from "../../components/Loader";
import Table from "../../components/Table";
import SmallButton from "../../components/SmallButton";
import SearchBox from "../../components/SearchBox";
import TopRowOptions from "../../components/TopRowOptions";
import Tag from "../../components/Tag";
import ScreenGroupDeviceDetails from "./screenGroupDeviceDetails";
import * as screenActions from "../../actions/screens";
import style from "./style.module.scss";
import styled from "styled-components";

class ScreenGroupDevices extends Component {

  constructor(props) {
    super(props);
    this.state = {
      searchText: "",
      search: undefined,
      sortBy: "createdAt",
      sortOrder: "desc",
      highlightedId: null,
      copiedSetupLink: false
    }

    this.onRowClick = this.onRowClick.bind(this);
    this.onHover = this.onHover.bind(this);
    this.onCopyURLClicked = this.onCopyURLClicked.bind(this);
    this.onSearchClick = this.onSearchClick.bind(this);
    this.onSearchChange = this.onSearchChange.bind(this);
    this.getDeviceTable = this.getDeviceTable.bind(this);
  }

  componentDidMount() {
    // console.log("ScreenGroupDevices.componentDidMount", this.props);
    this.props.getScreenDevices(this.props.screenGroupId);
  }

  onRowClick(column, rowInfo) {
    return {
      onClick: e => {
        if (rowInfo) {
          let link = `/companies/${this.props.match.params.companyId}/screens/edit/${this.props.screenGroupId}/devices/${rowInfo.original.id}/details`;

          if (e.metaKey || e.ctrlKey) {
            window.open(`${link}`);
          }
          else {
            this.props.history.push(link);
          }
        }
      },
      onMouseOver: e => {
        if (rowInfo && rowInfo.original.id) {
          this.onHover(rowInfo.original.id);
        }
      },
      onMouseLeave: e => this.onHover(null),
      style: {
        cursor: "pointer",
        background: (
          rowInfo && rowInfo.original.id === this.state.highlightedId ? "rgba(0,0,0,0.05)" : (rowInfo && rowInfo.original.id === this.props.match.params.deviceId ? "rgba(0,0,0,0.05)" : null))
      }
    }
  }

  onHover(id) {
    if (this.state.highlightedId !== id) {
      this.setState({ highlightedId: id });
    }
  }

  onCopyURLClicked() {
    let domain = window.location.host.replace("admin.", "screen.");
    const url = `https://${domain}/${this.props.match.params.companyId}/${this.props.match.params.screenGroupId}`;

    // Add url to clipboard
    window.Clipboard.copy(url);

    this.setState({ copiedSetupLink: true });

    setTimeout(() => {
      this.setState({ copiedSetupLink: false });
    }, 2000);
  }

  onSearchClick() {
    this.setState({ search: this.state.searchText });
  }

  onSearchChange(value) {
    this.setState({ searchText: value === '' ? undefined : value });
  }

  getDeviceTable() {
    const devices = this.props.screen.devices;

    // Filter devices with search
    let filteredDevices = devices;
    if (this.state.search) {
      filteredDevices = devices.filter(v => v.authCode.toLowerCase().includes(this.state.search.toLowerCase()));
    }

    // Get status weight
    const getStatusWeight = (device) => {
      if (device.isOnline) {
        if (device.approvalState === "approved") {
          return 1;
        }
        else {
          return 2;
        }
      }
      else {
        if (device.approvalState === "approved") {
          return 3;
        }
        else {
          return 4;
        }
      }
    };

    // Sort devices
    let sortedDevices = filteredDevices.sort((a, b) => {
      if (this.state.sortBy === "createdAt") {
        if (this.state.sortOrder === "desc") {
          return moment(b.createdAt).diff(a.createdAt);
        }
        else {
          return moment(a.createdAt).diff(b.createdAt);
        }
      }
      else if (this.state.sortBy === "authCode") {
        if (this.state.sortOrder === "desc") {
          return b.authCode.localeCompare(a.authCode);
        }
        else {
          return a.authCode.localeCompare(b.authCode);
        }
      }
      else if (this.state.sortBy === "os") {
        const parserA = new UAParser(a.userAgent);
        const resultA = parserA.getResult();
        const parserB = new UAParser(b.userAgent);
        const resultB = parserB.getResult();
        if (this.state.sortOrder === "desc") {
          return resultB.os.name.localeCompare(resultA.os.name);
        }
        else {
          return resultA.os.name.localeCompare(resultB.os.name);
        }
      }
      else if (this.state.sortBy === "browser") {
        const parserA = new UAParser(a.userAgent);
        const resultA = parserA.getResult();
        const parserB = new UAParser(b.userAgent);
        const resultB = parserB.getResult();
        if (this.state.sortOrder === "desc") {
          return resultB.browser.name.localeCompare(resultA.browser.name);
        }
        else {
          return resultA.browser.name.localeCompare(resultB.browser.name);
        }
      }
      else if (this.state.sortBy === "approvalState") {
        
        const weightA = getStatusWeight(a);
        const weightB = getStatusWeight(b);

        if (this.state.sortOrder === "desc") {
          return weightB - weightA;
        } else {
          return weightA - weightB;
        }
      }
      else {
        return 0;
      }
    });

    // Fix reload
    sortedDevices = sortedDevices.map(s => s);

    return (
      <Table
        data={sortedDevices}
        sortBy={this.state.sortBy}
        sortOrder={this.state.sortOrder}
        onSortedChange={(newSorted) => {
          this.setState({ sortBy: newSorted[0].id, sortOrder: newSorted[0].desc ? "desc" : "asc" });
        }}
        noDataText={"No devices"}
        columns={[
          {
            header: "Created",
            accessorKey: "createdAt",
            defaultSortDesc: true,
            width: 140,
            cell: ({ row }) => 
            (<span title={moment(row.original.createdAt).format('Do MMMM YYYY, HH:mm')}>
              {moment(row.original.createdAt).format('DD/MM/YY, HH:mm')}
            </span>)
          },
          {
            header: "Auth code",
            accessorKey: "authCode",
            width: 100,
            cell: ({ row }) => (<span title={row.original.authCode}>{row.original.authCode}</span>)
          },
          ,
          {
            header: "OS",
            accessorKey: "os",
            width: 120,
            cell: ({ row }) => {
              const parser = new UAParser(row.original.userAgent);
              const result = parser.getResult();
              return (
                <div>
                  <div>{result.os.name}</div>
                </div>
              );
            }
          },
          {
            header: "Browser",
            accessorKey: "browser",
            cell: ({ row }) => {
              const parser = new UAParser(row.original.userAgent);
              const result = parser.getResult();
              return (
                <div>
                  <div>{result.browser.name} {result.browser.version}</div>
                </div>
              );
            }
          },
          {
            header: "Status",
            accessorKey: "approvalState",
            cell: ({ row }) => {
              let status = "Offline";
              let color = "#d15656";
              if (row.original.approvalState === "approved") {
                if (row.original.isOnline) {
                  status = "Online";
                  color = "#429953";
                }
                else {
                  status = "Offline";
                  color = "#d15656";
                }
              }
              else if (row.original.approvalState === "disabled") {
                status = "Disabled";
                color = "#555";
              }
              else {
                if (row.original.isOnline) {
                  status = "Waiting for approval...";
                  color = "#999";
                }
                else {
                  status = "Offline and not approved";
                  color = "#999";
                }
              }

              return (<Tag text={status} color={color} />);
            }
          },
          {
            id: "arrow",
            header: "",
            accessorKey: "id",
            sortable: false,
            className: "pull-right",
            width: 60,
            cell: () => (<div className="arrow" />)
          }
        ]}
        getTdProps={this.onRowClick}
        // hideHeaders
        className="-row-clickable -highlight"
      />
    );
  }

  render() {
    // console.log("ScreenConfigDevices.render", this.props);

    if (this.props.isLoading) {
      return <Loader fullScreen />;
    }

    // Create table for devices  
    let tableElement = this.getDeviceTable();
    let description = `A device appears in this list when it opens the screen URL in a browser and connects to our server. The device remains inactive and won’t display any data until it is approved by an administrator. Approval can be granted directly through this administration page or by scanning the QR code displayed on the device.`;

    // Find device from match.params.deviceId
    const device = this.props.screen.devices.find(d => d.id === this.props.match.params.deviceId);
    
    const options = (
      <TopRowOptions
        description={description}
        searchbox={(
          <SearchBox
            value={this.state.searchText}
            onSearchChanged={this.onSearchChange}
            onSearchClick={this.onSearchClick}
            onClear={() => this.setState({ searchText: "" }, this.onSearchClick)}
            inListView
          />
        )}
        buttons={[
          <AdjustedButtonText key="ab2">
            <SmallButton key="b2" text={this.state.copiedSetupLink ? "Copied!" : "Copy URL"} onClick={this.onCopyURLClicked} disabled={this.state.copiedSetupLink} singleLine noMargin />
          </AdjustedButtonText>
        ]}
      />
    );

    return (
      <>
        <Hidden xs sm md lg>
          <div className={style.mainContainerHidden}>
            <div className={style.row}>
              <div className={style.listContainer}>
                <div className={style.scroll}>
                  { options }
                  { tableElement }
                  <div style={{ paddingTop: "40px" }} />
                </div>
              </div>
              <div className={style.sideBar}>
                <div className={style.scroll}>
                  { device && <ScreenGroupDeviceDetails {...this.props} device={device} /> }
                </div>
              </div>
            </div>
          </div>
        </Hidden>

        <Visible lg>
          <div className={style.mainContainerHidden}>
            <div className={style.scroll}>
              { options }
              { tableElement }
              <div style={{ paddingTop: "40px" }} />
            </div>
          </div>
        </Visible>

        <Visible xs sm md>
          <div className={style.mainContainerHidden}>
            <div className={style.slimScroll}>
              { options }
              { tableElement }
              <div style={{ paddingTop: "40px" }} />
            </div>
          </div>
        </Visible>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    screen: state.screen,
    form: state.screen.form,
    isLoading: state.loading.screen,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    getScreenDevices: screenActions.getScreenDevices,
    updateScreenDevice: screenActions.updateScreenDevice,
    deleteScreenDevice: screenActions.deleteScreenDevice
  }, dispatch);
}

export default connect(mapStateToProps,mapDispatchToProps)(ScreenGroupDevices);

const AdjustedButtonText = styled.div`
  span {
    position: relative;
    top: -1px;
  }
`;