import { toast } from "react-toastify";
import * as types from "../ApiTypes";

const initialState = {
  data: {
    count: 0,
    results: [],
    limit: 20
  },
  highlightedFilter: null,
  selectedFilters: {}
};

export default function filtersReducer(state = initialState, action) {

  switch (action.type) {
    
    case types.REQ_DATA: {
      if (action.fetchType === types.GET_FILTERS) {
        return {...state, data: initialState.data };
      }

      if (action.fetchType === types.GET_FILTER) {
        return {...state, highlightedFilter: null };
      }

      return state;
    }

    case types.RECV_DATA: {
      if (action.fetchType === types.GET_FILTERS) {
        return {...state, data: action.payload };
      }

      if (action.fetchType === types.GET_FILTER) {
        let highlightedFilter = action.payload;
        highlightedFilter.filter = JSON.parse(highlightedFilter.filter);
        return {...state, highlightedFilter };
      }

      if (action.fetchType === types.CREATE_FILTER) {
        toast.success("Filter saved");
        let highlightedFilter = action.payload;
        highlightedFilter.filter = JSON.parse(highlightedFilter.filter);
        return {...state, highlightedFilter };
      }

      if (action.fetchType === types.UPDATE_FILTER) {
        toast.success("Filter saved");
        let highlightedFilter = action.payload;
        highlightedFilter.filter = JSON.parse(highlightedFilter.filter);
        return {...state, highlightedFilter };
      }

      if (action.fetchType === types.DELETE_FILTERS) {
        toast.success("Filter(s) deleted");
        return {...state, selectedFilters: [], highlightedFilter: null };
      }
      
      return state;
    }

    case types.RECV_ERROR: {
      if (action.fetchType === types.GET_FILTERS) {
        toast.error("Could not get filters");
        return state;
      }

      if (action.fetchType === types.GET_FILTER) {
        toast.error("Could not get filter");
        return {...state, highlightedFilter: null };
      }

      if (action.fetchType === types.CREATE_FILTER) {
        toast.error("Could not save filter");
        return state;
      }

      if (action.fetchType === types.UPDATE_FILTER) {
        toast.error("Could not save filter");
        return state;
      }

      if (action.fetchType === types.DELETE_FILTERS) {
        toast.error("Could not delete filter(s)");
        return state;
      }
      
      return state;
    }

    case types.HIGHLIGHT_FILTER: {
      return {...state, highlightedFilter: { id: action.payload.id, name: action.payload.name, filter: JSON.parse(action.payload.filter) } };
    }

    case types.CLEAR_HIGHLIGHTED_FILTER:
    case types.UPDATE_FILTER_DRAFT: {
      return {...state, highlightedFilter: null };
    }

    case types.SELECT_FILTER: {
      return {...state, selectedFilters: { ...state.selectedFilters, [action.payload.id]: action.payload } };
    }

    case types.DESELECT_FILTER: {
      let selectedFilters = { ...state.selectedFilters };
      delete selectedFilters[action.payload.id];
      return {...state, selectedFilters };
    }

    case types.CLEAR_ALL_SELECTED_FILTERS: {
      return {...state, selectedFilters: {} };
    }

    case types.CLEAR_DATA: {
      return initialState;
    }

    default: {
      return state;
    }
  }
}
