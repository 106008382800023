import get from "lodash/get";
import { toast } from "react-toastify";
import * as types from "../ApiTypes";

const initialState = {
  id: null,
  name: null,
  users: [],
  content: [],
  locations: [],
  roles: [],
  managedByScim: true,
  canDelete: false,
  canEdit: false,
  canEditMembers: false,
  isEveryoneGroup: false,
  isSupportGroup: false,
  isItAdminGroup: false,
  isInstallerGroup: false,
  isAdminGroup: false,
  enableApp: false,
  enableEnergyReportAccess: false,
  enableNoShowReportAccess: false,
  enableWPAReportAccess: false,
  draft: {}
};

export default function userGroupReducer(state = initialState, action) {

  switch (action.type) {
    
    case types.REQ_DATA: {
      if (action.fetchType === types.GET_USER_GROUP) {
        return initialState;
      }

      return state;
    }

    case types.RECV_DATA: {
      if (action.fetchType === types.GET_USER_GROUP) {
        return {
          ...state,
          id: action.payload._id,
          name: action.payload.name,
          content: action.payload.applications,
          locations: action.payload.locations,
          managedByScim: action.payload.managedByScim,
          roles: get(action.payload, "roles", []),
          canDelete: action.payload.canDelete,
          canEdit: action.payload.canEdit,
          canEditMembers: action.payload.canEditMembers,
          isEveryoneGroup: action.payload.isEveryoneGroup ?? false,
          isSupportGroup: action.payload.isSupportGroup ?? false,
          isItAdminGroup: action.payload.isItAdminGroup ?? false,
          isInstallerGroup: action.payload.isInstallerGroup ?? false,
          isAdminGroup: action.payload.isAdminGroup ?? false,
          enableApp: get(action.payload, "serviceFeatures.enableApp", false),
          enableEnergyReportAccess: get(action.payload, "serviceFeatures.enableEnergyReportAccess", false),
          enableNoShowReportAccess: get(action.payload, "serviceFeatures.enableNoShowReportAccess", false),
          enableWPAReportAccess: get(action.payload, "serviceFeatures.enableWPAReportAccess", false),
        };
      }
      
      if (action.fetchType === types.UPDATE_USER_GROUP) {
        toast.success("Group updated");
        return state;
      }

      if (action.fetchType === types.DELETE_USER_GROUP) {
        toast.success("Deleted group");
        return state;
      }

      if (action.fetchType === types.GET_USER_GROUP_USERS) {
        return {...state, users: action.payload.users };
      }

      if (action.fetchType === types.ADD_USER_GROUP_USERS) {
        toast.success("User(s) added to group");
        return state;
      }

      if (action.fetchType === types.DELETE_USER_GROUP_USERS) {
        toast.success("User(s) remove from group");
        return state;
      }

      return state;
    }

    case types.RECV_ERROR: {

      const statusCode = get(action, "payload.response.status", "Error");

      if (action.fetchType === types.GET_USER_GROUP) {
        toast.error(`${statusCode}: Could not get group`);
        return initialState;
      }

      if (action.fetchType === types.UPDATE_USER_GROUP) {
        toast.error(`${statusCode}: Could not update group`);
        return state;
      }

      if (action.fetchType === types.DELETE_USER_GROUP) {
        toast.error(`${statusCode}: Could not delete group`);
        return state;
      }

      if (action.fetchType === types.GET_USER_GROUP_USERS) {
        toast.error(`${statusCode}: Could not get users from group`);
        return state;
      }

      if (action.fetchType === types.ADD_USER_GROUP_USERS) {
        toast.error(`${statusCode}: Could not add user(s) to group`);
        return state;
      }

      if (action.fetchType === types.DELETE_USER_GROUP_USERS) {
        toast.error(`${statusCode}: Could not remove user(s) from group`);
        return state;
      }
      
      return state;
    }

    case types.DRAFT_USER_GROUP_CHANGE: {
      return {...state, draft: action.payload };
    }

    case types.CLEAR_DATA, types.CLEAR_USER_GROUP: {
      return initialState;
    }

    case types.CLEAR_SELECTION: {
      return {...state, draft: {} };
    }

    default: {
      return state;
    }
  }
}
