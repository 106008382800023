import { toast } from "react-toastify";
import { get } from "lodash";
import * as types from "../ApiTypes";

const initialState = {
  data: {}
};

export default function offlineSensorsReducer(state = initialState, action) {
  switch (action.type) {
    
    case types.REQ_DATA: {
      if (action.fetchType === types.GET_OFFLINE_SENSORS) {
        return initialState;
      }

      return state;
    }

    case types.RECV_DATA: {
      if (action.fetchType === types.GET_OFFLINE_SENSORS) {
        return { data: action.payload };
      }

      return state;
    }

    case types.RECV_ERROR: {
      const statusCode = get(action, "payload.response.status", "Error");

      if (action.fetchType === types.GET_OFFLINE_SENSORS) {
        toast.error(`${statusCode}: Could not get offline sensors`);
        return initialState;
      }

      return state;
    }

    case types.CLEAR_DATA: {
      return initialState;
    }

    default: {
      return state;
    }
  }
}
