import React from "react";
import style from "./style.module.scss"

const Checkbox = ({onClick = () => {}, label = "", description = "", isChecked = false, disabled = false }) => (
  <label className={style.checkboxContainer}>
    {label}
    <span className={style.description}>{description}</span>
    <input type="checkbox" onChange={onClick} checked={isChecked} disabled={disabled} />
    <span className={style.checkmark} />
  </label>
);

export default Checkbox;
