import React, { useState, useRef, useCallback, useEffect } from "react";
import styled from "styled-components";

interface RangeSliderProps {
  values: number[];
  min: number;
  max: number;
  onChange: (newValues: [number, number]) => void;
  onDragStart: () => void;
  onDragEnd: () => void;
}

const RangeSlider: React.FC<RangeSliderProps> = ({ values = [0, 1], min = 0, max = 1, onChange, onDragStart, onDragEnd }) => {

  // console.log("values", values);

  const [dragging, setDragging] = useState(false);
  const [initialValues, setInitialValues] = useState(values);
  const [dragStart, setDragStart] = useState(0);
  const containerRef = useRef<HTMLDivElement>(null);
  const [lastRoundedValues, setLastRoundedValues] = useState<number[]>(values);
  
  const handleContainerMouseDown = (e: React.MouseEvent<HTMLDivElement>) => {
    if (containerRef.current) {
      const rect = containerRef.current.getBoundingClientRect();
      const clickPosition = e.clientX - rect.left;
      const rangeWidth = rect.width;
      const clickPercentage = (clickPosition / rangeWidth) * 100;
  
      const rangeLengthPercentage = ((values[1] - values[0]) / (max - min)) * 100;
      let newLowerPercentage = clickPercentage - rangeLengthPercentage / 2;
      let newUpperPercentage = clickPercentage + rangeLengthPercentage / 2;
  
      // Adjust the new lower and upper percentages if they go beyond the limits
      if (newLowerPercentage < 0) {
        newUpperPercentage -= newLowerPercentage;
        newLowerPercentage = 0;
      } else if (newUpperPercentage > 100) {
        newLowerPercentage -= (newUpperPercentage - 100);
        newUpperPercentage = 100;
      }
  
      const newLowerValue = Math.round(((newLowerPercentage / 100) * (max - min)) + min);
      const newUpperValue = Math.round(((newUpperPercentage / 100) * (max - min)) + min);
      if (newLowerValue !== values[0]) {
        // console.log("MouseDown -> onChange", [newLowerValue, newUpperValue]);
        onChange([newLowerValue, newUpperValue]);
        // console.log("MouseDown -> setInitialValues", [newLowerValue, newUpperValue]);
        setInitialValues([newLowerValue, newUpperValue]);
      }
  
      setDragStart(e.clientX);
      setDragging(true);
      onDragStart();
    }
  };

  const handleContainerMouseMove = useCallback(
    (e: MouseEvent) => {
      if (dragging && containerRef.current) {
        const rect = containerRef.current.getBoundingClientRect();
        const delta = e.clientX - dragStart;
        const rangeWidth = rect.width;
        const rangeDelta = (delta / rangeWidth) * (max - min);
        const newLowerValue = Math.min(Math.max(initialValues[0] + rangeDelta, min), max - (values[1] - values[0]));
        const newUpperValue = Math.min(Math.max(initialValues[1] + rangeDelta, values[0] + 1), max);

        // console.log("MouseMove -> newLowerValue", newLowerValue);
        // console.log("MouseMove -> newUpperValue", newUpperValue);
        
        const roundedLowerValue = Math.round(newLowerValue);
        const roundedUpperValue = Math.round(newUpperValue);

        if (roundedLowerValue !== lastRoundedValues[0] || roundedUpperValue !== lastRoundedValues[1]) {
          // console.log("MouseMove -> setLastRoundedValues", [roundedLowerValue, roundedUpperValue]);
          setLastRoundedValues([roundedLowerValue, roundedUpperValue]);
          onChange([roundedLowerValue, roundedUpperValue]);
        }
      }
    },
    [dragging, dragStart, initialValues, min, max, values, lastRoundedValues, onChange]
  );

  // Make sure the slider moves when the mouse moves
  useEffect(() => {
    if (dragging) {
      window.addEventListener("mousemove", handleContainerMouseMove);
    } else {
      window.removeEventListener("mousemove", handleContainerMouseMove);
    }
  
    return () => {
      window.removeEventListener("mousemove", handleContainerMouseMove);
    };
  }, [dragging, handleContainerMouseMove]);  

  // Make sure the slider stops moving when the mouse is released outside the window
  useEffect(() => {
    const handleMouseUp = () => {
      // console.log("MouseUp -> setInitialValues", lastRoundedValues);
      setDragging(false);
      onDragEnd();
      setInitialValues(lastRoundedValues);
      window.removeEventListener("mouseup", handleMouseUp);
    };
  
    if (dragging) {
      window.addEventListener("mouseup", handleMouseUp);
    }
  
    return () => {
      window.removeEventListener("mouseup", handleMouseUp);
    };
  }, [dragging, lastRoundedValues, onDragEnd]);
  

  const lowerPercentage = ((values[0] - min) / (max - min)) * 100;
  const upperPercentage = ((values[1] - min) / (max - min)) * 100;

  return (
    <Container ref={containerRef} onMouseDown={handleContainerMouseDown}>
      <Slider left={`${lowerPercentage}%`} width={`${upperPercentage - lowerPercentage}%`} />
    </Container>
  );
};

export default RangeSlider;

const Container = styled.div`
  position: relative;
  width: 100%;
  height: 14px;
  background-color: #ddd;
`;

const Slider = styled.div<{ left: string; width: string }>`
  position: absolute;
  left: ${props => props.left };
  width: ${props => props.width };
  height: 14px;
  background-color: #1C4D82;
  border-radius: 7px;
  cursor: pointer;
`;