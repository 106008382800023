import axios from "axios";
import moment from "moment";
import Cookies from "universal-cookie";
import * as types from "../ApiTypes";
import * as actions from "./sharedFunction";
import { API_URL, BASE_URL, ROOM_API_URL, ROOM_URL, COOKIE_PREFIX } from "../env";
import { getCompanyId } from "../helpers";

const cookies = new Cookies();

export const getCompanyMapUrl = (companyId, debug) => async (dispatch) => {

  const roomApiUrl = await ROOM_API_URL();
  const roomUrl = await ROOM_URL();
  const cookiePrefix = await COOKIE_PREFIX();
  const accessToken = cookies.get(`${cookiePrefix}_access_token`);

  const body = { companyId };
  
  axios.post(`${roomApiUrl}code`, body, await actions.getAxiosConfig())
    .then((response) => {
      dispatch(actions.receiveData(`${roomUrl}?code=${response.data}&company=${companyId}${debug ? "&debug=1" : ""}`, types.GET_ROOM_MAP_LINK))
    })
    .catch((error) => {
      if (error.response && error.response.status === 401) {
        actions.sessionTokenExpired(cookiePrefix, accessToken);
      }
    });
};

// export const getFloorMapUrl = (companyId, floorId, floorLevel) => async (dispatch) => {

//   const roomApiUrl = await ROOM_API_URL();
//   const roomUrl = await ROOM_URL();
// const cookiePrefix = await COOKIE_PREFIX();
  // const accessToken = cookies.get(`${cookiePrefix}_access_token`);

//   const body = { companyId };
  
//   axios.post(`${roomApiUrl}code`, body, await actions.getAxiosConfig())
//     .then((response) => {
//       dispatch(actions.receiveData(`${roomUrl}?code=${response.data}&company=${companyId}&fid=${floorId}&fl=${floorLevel}`, types.GET_ROOM_MAP_LINK))
//     })
//     .catch((error) => {
//       if (error.response && error.response.status === 401) {
//         actions.sessionTokenExpired(cookiePrefix, accessToken);
//       }
//     });
// };

export const clearUrlForRoomMap = () => async (dispatch) => {
  dispatch({
    type: types.CLEAR_ROOM_MAP_LINK
  });
};