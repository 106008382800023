import React, { Component } from "react";
import { Switch, Route, Redirect, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Loader from "../../components/Loader";
import NotFound from "../NotFound";
import SubNavigation from "../../components/SubNavigation";
import LargeHeader from "../../components/LargeHeader";
import Users from "./users";
import Admins from "./admins";
import SupportUsers from "./supportUsers";
import Invites from "./invites";
import Groups from "./groups";
import style from "./style.module.scss";

class OrgContainer extends Component {

  render() {
    if (this.props.isAuthLoading) {
      return <Loader fullScreen />;
    }

    const subNavigationLinks = [];
    subNavigationLinks.push({ pathname: `/companies/${this.props.match.params.companyId}/org/groups`, label: `Groups` });
    subNavigationLinks.push({ pathname: `/companies/${this.props.match.params.companyId}/org/users`, label: `Users` });
    subNavigationLinks.push({ pathname: `/companies/${this.props.match.params.companyId}/org/admins`, label: `Admins` });

    if (this.props.auth.hasITAdminRole) {
      subNavigationLinks.push({ pathname: `/companies/${this.props.match.params.companyId}/org/support`, label: `Support` });
      subNavigationLinks.push({ pathname: `/companies/${this.props.match.params.companyId}/org/invites`, label: `Invites` });
    }
    else if (this.props.auth.hasInstallerRole) {
      subNavigationLinks.push({ pathname: `/companies/${this.props.match.params.companyId}/org/support`, label: `Support` });
    }

    return (
      <div className={style.page}>
        <LargeHeader title="Organization" />
        <SubNavigation links={subNavigationLinks} /> 
        <Switch>
          <Redirect exact from="/companies/:companyId/org" to={{ pathname: `/companies/${this.props.match.params.companyId}/org/groups` }} />
          <Route path="/companies/:companyId/org/groups/:groupId?" children={(props) => <Groups {...props} groupId={props.match.params.groupId} />} />
          <Route path="/companies/:companyId/org/users/:userId?" children={(props) => <Users {...props} userId={props.match.params.userId} />} />
          <Route path="/companies/:companyId/org/admins/:userId?" children={(props) => <Admins {...props} userId={props.match.params.userId} />} />
          <Route path="/companies/:companyId/org/support/:userId?" children={(props) => <SupportUsers {...props} userId={props.match.params.userId} />} /> 
          <Route path="/companies/:companyId/org/invites/:inviteId?" children={(props) => <Invites {...props} inviteId={props.match.params.inviteId} />} />
          <Route render={NotFound} />
        </Switch>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return { 
    auth: state.auth,
    selectedCompany: state.auth.selectedCompany,
    isAuthLoading: state.loading.auth
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    // getLocation: locationActions.getLocation,
   }, dispatch);
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(OrgContainer));
