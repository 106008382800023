import axios from "axios";
import Cookies from "universal-cookie";
import get from "lodash/get";
import * as types from "../ApiTypes";
import * as actions from "./sharedFunction";
import * as selectionActions from "./selected";
import { API_URL, COOKIE_PREFIX } from "../env";
import { getAllContent } from "./content";
import { getLocation } from "./locations";
import { getCompanyId } from "../helpers";

const cookies = new Cookies();

export const getUserGroups = (queryParams) => async (dispatch) => {
  dispatch(actions.requestData(types.GET_USER_GROUPS));

  const apiUrl = await API_URL();
  const companyId = getCompanyId();
  const cookiePrefix = await COOKIE_PREFIX();
  const accessToken = cookies.get(`${cookiePrefix}_access_token`);

  axios.get(`${apiUrl}companies/${companyId}/usergroups`, await actions.getAxiosConfig(queryParams))
    .then((response) => {
      dispatch(actions.receiveData(response.data, types.GET_USER_GROUPS));
    })
    .catch((error) => {
      if (error.response && error.response.status === 401) {
        actions.sessionTokenExpired(cookiePrefix, accessToken);
      } else {
        dispatch(actions.receiveError(error, types.GET_USER_GROUPS));
      }
    });
};

export const getUserGroup = (userGroupId) => async (dispatch) => {
  dispatch(actions.requestData(types.GET_USER_GROUP));

  const apiUrl = await API_URL();
  const companyId = getCompanyId();
  const cookiePrefix = await COOKIE_PREFIX();
  const accessToken = cookies.get(`${cookiePrefix}_access_token`);

  axios.get(`${apiUrl}companies/${companyId}/usergroups/${userGroupId}`, await actions.getAxiosConfig())
    .then((response) => {
      dispatch(actions.receiveData(response.data, types.GET_USER_GROUP));
    })
    .catch((error) => {
      if (error.response && error.response.status === 401) {
        actions.sessionTokenExpired(cookiePrefix, accessToken);
      } else {
        dispatch(actions.receiveError(error, types.GET_USER_GROUP));
      }
    });
};

export const createUserGroup = (newUserGroup) => async (dispatch) => {
  dispatch(actions.requestData(types.CREATE_USER_GROUP));

  const apiUrl = await API_URL();
  const companyId = getCompanyId();
  const cookiePrefix = await COOKIE_PREFIX();
  const accessToken = cookies.get(`${cookiePrefix}_access_token`);

  axios.post(`${apiUrl}companies/${companyId}/usergroups`, newUserGroup, await actions.getAxiosConfig())
    .then((response) => {
      dispatch(actions.receiveData(response.data, types.CREATE_USER_GROUP));
      dispatch(selectionActions.clearSelection());
      dispatch(getUserGroups());
    })
    .catch((error) => {
      if (error.response && error.response.status === 401) {
        actions.sessionTokenExpired(cookiePrefix, accessToken);
      } else {
        dispatch(actions.receiveError(error, types.CREATE_USER_GROUP));
      }
    });
};

export const updateUserGroup = (userGroupId, newUserGroup, refreshUserGroups) => async (dispatch) => {
  dispatch(actions.requestData(types.UPDATE_USER_GROUP));

  const apiUrl = await API_URL();
  const companyId = getCompanyId();
  const cookiePrefix = await COOKIE_PREFIX();
  const accessToken = cookies.get(`${cookiePrefix}_access_token`);

  // Move enableX into serviceFeatures
  let body = { ...newUserGroup };
  if (body.enableApp !== undefined || body.enableEnergyReportAccess !== undefined || body.enableNoShowReportAccess !== undefined || body.enableWPAReportAccess !== undefined) {
    body.serviceFeatures = {};

    if (body.enableApp !== undefined) {
      body.serviceFeatures.enableApp = body.enableApp;
      delete body.enableApp;
    }

    if (body.enableEnergyReportAccess !== undefined) {
      body.serviceFeatures.enableEnergyReportAccess = body.enableEnergyReportAccess;
      delete body.enableEnergyReportAccess;
    }

    if (body.enableNoShowReportAccess !== undefined) {
      body.serviceFeatures.enableNoShowReportAccess = body.enableNoShowReportAccess;
      delete body.enableNoShowReportAccess;
    }

    if (body.enableWPAReportAccess !== undefined) {
      body.serviceFeatures.enableWPAReportAccess = body.enableWPAReportAccess;
      delete body.enableWPAReportAccess;
    }
  }

  axios.put(`${apiUrl}companies/${companyId}/usergroups/${userGroupId}`, body, await actions.getAxiosConfig())
    .then((response) => {
      dispatch(actions.receiveData(response.data, types.UPDATE_USER_GROUP));
      dispatch(selectionActions.clearSelection());
      dispatch(getUserGroup(userGroupId));
      dispatch(getAllContent());
      if (refreshUserGroups) {
        dispatch(getUserGroups());
      }
    })
    .catch((error) => {
      if (error.response && error.response.status === 401) {
        actions.sessionTokenExpired(cookiePrefix, accessToken);
      } else {
        dispatch(actions.receiveError(error, types.UPDATE_USER_GROUP));
      }
    });
};

export const deleteUserGroup = (userGroupId, redirect) => async (dispatch) => {
  dispatch(actions.requestData(types.DELETE_USER_GROUP));

  const apiUrl = await API_URL();
  const companyId = getCompanyId();
  const cookiePrefix = await COOKIE_PREFIX();
  const accessToken = cookies.get(`${cookiePrefix}_access_token`);

  axios.delete(`${apiUrl}companies/${companyId}/usergroups/${userGroupId}`, await actions.getAxiosConfig())
    .then((response) => {
      dispatch(actions.receiveData(response.data, types.DELETE_USER_GROUP));
      window.location.replace("/org/groups");
    })
    .catch((error) => {
      if (error.response && error.response.status === 401) {
        actions.sessionTokenExpired(cookiePrefix, accessToken);
      } else {
        dispatch(actions.receiveError(error, types.DELETE_USER_GROUP));
      }
    });
};

export const getUserGroupUsers = (userGroupId, queryParams) => async (dispatch) => {
  dispatch(actions.requestData(types.GET_USER_GROUP_USERS));
  
  const apiUrl = await API_URL();
  const companyId = getCompanyId();
  const cookiePrefix = await COOKIE_PREFIX();
  const accessToken = cookies.get(`${cookiePrefix}_access_token`);
  
  axios.get(`${apiUrl}companies/${companyId}/usergroups/${userGroupId}/users`, await actions.getAxiosConfig(queryParams))
    .then((response) => (
      dispatch(actions.receiveData(response.data, types.GET_USER_GROUP_USERS))
    ))
    .catch((error) => {
      if (error.response && error.response.status === 401) {
        actions.sessionTokenExpired(cookiePrefix, accessToken);
      }
      else {
        dispatch(actions.receiveError(error, types.GET_USER_GROUP_USERS));
      }
    });
};

export const addLocationToUserGroups = (locationId, userGroupIds) => async (dispatch) => {
  dispatch(actions.requestData(types.UPDATE_USER_GROUP));

  const apiUrl = await API_URL();
  const companyId = getCompanyId();
  const cookiePrefix = await COOKIE_PREFIX();
  const accessToken = cookies.get(`${cookiePrefix}_access_token`);
  const config = await actions.getAxiosConfig();

  // Looping through all user group objects... 
  axios.all(userGroupIds.map((userGroupId) => {
      return axios.get(`${apiUrl}companies/${companyId}/usergroups/${userGroupId}`, config)
        .then((response) => {
          let locations = get(response, "data.locations", []).filter(element => locationId !== element._id).map(element => element._id);
          locations.push(locationId);
          const newContent = { locations };
          return axios.put(`${apiUrl}companies/${companyId}/usergroups/${userGroupId}`, newContent, config)
        })
    }))
    .then((response) => {
      dispatch(actions.receiveData(response.data, types.UPDATE_USER_GROUP));
      dispatch(selectionActions.clearSelection());
      dispatch(getLocation(locationId));
      dispatch(getUserGroups());
    })
    .catch((error) => {
      if (error.response && error.response.status === 401) {
        actions.sessionTokenExpired(cookiePrefix, accessToken);
      } else {
        dispatch(actions.receiveError(error, types.UPDATE_USER_GROUP));
      }
    });
};

export const removeLocationFromUserGroups = (locationId, userGroupIds) => async (dispatch) => {
  dispatch(actions.requestData(types.UPDATE_USER_GROUP));

  const apiUrl = await API_URL();
  const companyId = getCompanyId();
  const cookiePrefix = await COOKIE_PREFIX();
  const accessToken = cookies.get(`${cookiePrefix}_access_token`);
  const config = await actions.getAxiosConfig();

  // Looping through all user group objects... 
  axios.all(userGroupIds.map((userGroupId) => {
      return axios.get(`${apiUrl}companies/${companyId}/usergroups/${userGroupId}`, config)
        .then((response) => {
          const locations = get(response, "data.locations", []).filter(element => locationId !== element._id).map(element => element._id);
          const newContent = { locations }
          return axios.put(`${apiUrl}companies/${companyId}/usergroups/${userGroupId}`, newContent, config)
        })
    }))
    .then((response) => {
      dispatch(actions.receiveData(response.data, types.UPDATE_USER_GROUP));
      dispatch(selectionActions.clearSelection());
      dispatch(getLocation(locationId));
      dispatch(getUserGroups());
    })
    .catch((error) => {
      if (error.response && error.response.status === 401) {
        actions.sessionTokenExpired(cookiePrefix, accessToken);
      } else {
        dispatch(actions.receiveError(error, types.UPDATE_USER_GROUP));
      }
    });
};

export const addUsersToUserGroup = (userGroupId, userIds) => async (dispatch) => {
  dispatch(actions.requestData(types.ADD_USER_GROUP_USERS));

  const apiUrl = await API_URL();
  const companyId = getCompanyId();
  const cookiePrefix = await COOKIE_PREFIX();
  const accessToken = cookies.get(`${cookiePrefix}_access_token`);

  axios.post(`${apiUrl}companies/${companyId}/usergroups/${userGroupId}/users`, { userIds }, await actions.getAxiosConfig())
    .then((response) => {
      dispatch(actions.receiveData(response.data, types.ADD_USER_GROUP_USERS));
      dispatch(selectionActions.clearSelection());
      dispatch(getUserGroupUsers(userGroupId));
    })
    .catch((error) => {
      if (error.response && error.response.status === 401) {
        actions.sessionTokenExpired(cookiePrefix, accessToken);
      } else {
        dispatch(actions.receiveError(error, types.ADD_USER_GROUP_USERS));
      }
    });
};

export const deleteUsersFromUserGroup = (userGroupId, userIds) => async (dispatch) => {
  dispatch(actions.requestData(types.DELETE_USER_GROUP_USERS));

  const apiUrl = await API_URL();
  const companyId = getCompanyId();
  const cookiePrefix = await COOKIE_PREFIX();
  const accessToken = cookies.get(`${cookiePrefix}_access_token`);

  var body = await actions.getAxiosConfig();
  body.data = { userIds };

  axios.delete(`${apiUrl}companies/${companyId}/usergroups/${userGroupId}/users`, body)
    .then((response) => {
      dispatch(actions.receiveData(response.data, types.DELETE_USER_GROUP_USERS));
      dispatch(selectionActions.clearSelection());
      dispatch(getUserGroupUsers(userGroupId));
    })
    .catch((error) => {
      if (error.response && error.response.status === 401) {
        actions.sessionTokenExpired(cookiePrefix, accessToken);
      } else {
        dispatch(actions.receiveError(error, types.DELETE_USER_GROUP_USERS));
      }
    });
};

export const draftUserGroupChange = (newUserGroup) => async (dispatch) => {
  dispatch({ type: types.DRAFT_USER_GROUP_CHANGE, payload: newUserGroup })
};

export const clearUserGroup = () => async (dispatch) => {
  dispatch({ type: types.CLEAR_USER_GROUP })
};