import React, { Component } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt } from "@fortawesome/free-solid-svg-icons";
import Title from "../Title";
import InputBox from "../InputBox";
import SmallButton from "../SmallButton";
import styled from "styled-components";

class DetailsHeader extends Component {

  constructor(props) {
    super(props);
    this.state = {
      showNameInput: false,
      newName: ""
    };

    this.onNameChange = this.onNameChange.bind(this);
    this.onEditNameClicked = this.onEditNameClicked.bind(this);
    this.onEditNameSave = this.onEditNameSave.bind(this);
    this.onEditNameCancel = this.onEditNameCancel.bind(this);
  }

  onNameChange(event) {
    this.setState({ newName: event.target.value });
  }

  onEditNameClicked() {
    this.setState({ showNameInput: true, newName: this.props.title });
  }

  onEditNameSave() {
    this.setState({ showNameInput: false }, () => this.props.onNameSaveClicked(this.state.newName));
  }

  onEditNameCancel() {
    this.setState({ showNameInput: false, newName: "" });
  }

  render() {

    if (this.state.showNameInput) {
      return (
        <Container $justify="start">
          <TextContainer>
            <InputBox
              value={this.state.newName}
              valid={this.state.newName}
              onChange={this.onNameChange}
              required
              showValidIcon
              noMargin
              style={{ minWidth: "300px" }}
            />
          </TextContainer>
          <ButtonContainer>
            <SmallButton text="Save" onClick={this.onEditNameSave} disabled={!this.props.canUpdateName || this.props.isLoading} noLeftMargin />
            <SmallButton text="Cancel" onClick={this.onEditNameCancel} color="red" />
          </ButtonContainer>
        </Container>
      );
    }
    
    return (
      <Container>
        <TextContainer>
          <Title text={this.props.title} />
          <TagContainer>
            { this.props.tags }
          </TagContainer>
          <div role="button" tabIndex="0" onClick={this.onEditNameClicked} onKeyDown={this.onEditNameClicked}>
            { this.props.canUpdateName && <FontAwesomeIcon icon={faPencilAlt} size="lg" color="#1C4D82" /> }
          </div>
        </TextContainer>
        <ButtonContainer>
          { this.props.onEditClicked && <SmallButton text="Edit" onClick={this.props.onEditClicked} disabled={!this.props.canUpdate || this.props.isLoading} noLeftMargin /> }
          { this.props.onCustomButtonClicked && <SmallButton text={this.props.customButtonText} color={this.props.customButtonColor} onClick={this.props.onCustomButtonClicked} disabled={!this.props.canUseCustomButton || this.props.isLoading} onlyLeftMargin={!!this.props.onEditClicked} noLeftMargin={!this.props.onEditClicked} /> }
          { this.props.onDeleteClicked && <SmallButton text="Delete" onClick={this.props.onDeleteClicked} color="red" disabled={!this.props.canDelete || this.props.isLoading} onlyLeftMargin={!!this.props.onEditClicked || !!this.props.onCustomButtonClicked} noLeftMargin={!this.props.onEditClicked} /> }
        </ButtonContainer>
      </Container>
    );
  }
}

export default DetailsHeader;

const Container = styled.div`
  display: flex;
  padding-top: 25px;
  margin-bottom: 5px;
  flex-wrap: wrap;
  min-height: 44px;
  justify-content: ${props => props.$justify ?? "space-between"};
`;

const TextContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-right: 10px;
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: end;
`;

const TagContainer = styled.div`
  display: inline-block;
  vertical-align: text-bottom;
`;