import isEmpty from "lodash/isEmpty";
import { Visible, Hidden } from "react-grid-system";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark, faSearch } from "@fortawesome/free-solid-svg-icons";
import styled from "styled-components";

const SearchBox = ({ value = "", placeholder = "Search", onSearchChanged = () => {}, onSearchClick = () => {}, onClear = null, showSearchButton = false, inListView = false, inDetailsView = false }) => (
  <>
    <SearchContainer>
      <Box $hasSearchIcon={!showSearchButton} $inListView={inListView} $inDetailsView={inDetailsView}>
        <InnerBox>
          { // search icon
            !showSearchButton && (
              <SearchIcon>
                <FontAwesomeIcon icon={faSearch} color={"#999"} />
              </SearchIcon>
            )
          }
          <Input
            name="search"
            placeholder={placeholder} 
            onChange={(event) => onSearchChanged(event.target.value)} 
            onKeyDown={(event) => { if (event.key === "Enter") onSearchClick() }}
            value={value}
            autoComplete="off"
            />
          { // button to clear the search field  style={{ marginRight: "12px" }}
            !isEmpty(value) && onClear && (
              <ClearButton onClick={() => onClear()}>
                <FontAwesomeIcon icon={faXmark} color={"#999"} />
              </ClearButton>
            )
          }
        </InnerBox>
      </Box>
      {
        showSearchButton && (
          <Hidden xs sm>
            <SearchButton onClick={() => onSearchClick()}>
              <SearchButtonText>Search</SearchButtonText>
            </SearchButton>
          </Hidden>
        )
      }
    </SearchContainer>
    {
      showSearchButton && (
        <Visible xs sm>
          <SearchButton $mobile={mobile} onClick={() => onSearchClick()}>
            <SearchButtonText>Search</SearchButtonText>
          </SearchButton>
        </Visible>
      )
    }
  </>
);

export default SearchBox;

const SearchContainer = styled.div`
  display: flex;
  max-width: 100%;

  *:focus {
    outline: none;
  }
`;

const Box = styled.div`
  display: block;
  flex-grow: 1;
  background-color: rgb(255, 255, 255);
  border-width: 0;
  outline-color: rgb(221, 221, 221);
  outline-width: 1px;
  outline-style: solid;
  border-radius: 5px;
  box-sizing: border-box;
  overflow: hidden;
  padding-left: ${props => props.$hasSearchIcon ? "0px" : "10px"}; 
  max-width: 100%;
  width: ${props => props.$inListView ? "400px" : (props.$inDetailsView ? "300px" : 0)};
  // margin-right: ${props => (props.$inListView || props.$inDetailsView) ? "10px" : 0};
  margin-top: ${props => (props.$inListView || props.$inDetailsView) ? "5px" : 0};
  margin-bottom: ${props => (props.$inListView || props.$inDetailsView) ? "5px" : 0};
`;

const InnerBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const SearchIcon = styled.div`
  height: 100%;
  width: 39px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
`;

const SearchButton = styled.div`
  display: flex;
  height: 41px;
  width: ${props => props.$mobile ? "100%" : "120px"};
  min-width: 100px;
  margin-top: ${props => props.$mobile ? "10px" : "0"};
  margin-left: ${props => props.$mobile ? "0" : "20px"};
  cursor: pointer;
  background-color: #1C4D82;
  border-radius: 6px;
  align-self: center;
`;

const SearchButtonText = styled.div`
  display: inline-block;
  width: 100%;
  color: white;
  text-align: center;
  line-height: 40px;
  font-size: 17px;
  white-space: nowrap;
`;

const ClearButton = styled.div`
  height: 100%;
  width: 39px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  flex-shrink: 0;
`;

const Input = styled.input`
  background-color: white !important;
  border: none;
  border-radius: 0;
  box-shadow: none;
  color: #222;
  font-size: 17px;
  font-weight: 400;
  height: 40px;
  padding: 0;
  flex-grow: 1;

  &:-webkit-autofill,
  &:-webkit-autofill:focus {
    transition: background-color 600000s 0s, color 600000s 0s;
  }
  &[data-autocompleted] {
    background-color: transparent !important;
  }
`;
