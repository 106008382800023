import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Switch, Route, Redirect } from "react-router-dom";
import isEmpty from "lodash/isEmpty";
import NotFound from "../NotFound";
import SubNavigation from "../../components/SubNavigation";
import ContentInfo from "./contentDetailsInfo";
import ContentLocations from "./contentDetailsLocations";
import ContentGroups from "./contentDetailsGroups";
import * as contentActions from "../../actions/content";
import DetailsHeader from "../../components/DetailsHeader";

class ContentDetails extends Component {

  constructor(props) {
    super(props);

    this.onEditNameSave = this.onEditNameSave.bind(this);
    this.onEditClicked = this.onEditClicked.bind(this);
    this.onDeleteClicked = this.onDeleteClicked.bind(this);

    if (isEmpty(this.props.contentId)) {
      this.props.clearContent();
    }
    else if (this.props.content.id !== this.props.contentId) {
      this.props.getContent(this.props.contentId);
    }
  }

  componentDidUpdate(prevProps) {
    // console.log("ContentDetails.props", this.props);

    // Only load data if id change
    if (isEmpty(this.props.contentId)) {
      this.props.clearContent();
    }
    else if (prevProps.contentId !== this.props.contentId) {
      this.props.getContent(this.props.contentId);
    }
  }

  onEditNameSave(name) {

    const body = {};

    if (name.length > 0) {
      body.title = name;
    }

    this.props.updateContent(this.props.content.id, body);
  }

  onEditClicked() {
    this.props.history.push(`/companies/${this.props.match.params.companyId}/app/content/edit/${this.props.contentId}`);
  }

  onDeleteClicked() {
    this.props.deleteContent([this.props.content.id]);
  }
  
  render() {
    // console.log("ContentDetails.render()", this.props);
    const { isLoading } = this.props;

    if (isEmpty(this.props.contentId) || isEmpty(this.props.content.id)) {
      return null;
    }

    const subNavigationLinks = [];
    subNavigationLinks.push({ pathname: `/companies/${this.props.match.params.companyId}/app/content/${this.props.contentId}/groups`, label: `Groups` });
    subNavigationLinks.push({ pathname: `/companies/${this.props.match.params.companyId}/app/content/${this.props.contentId}/locations`, label: `Locations` });
    subNavigationLinks.push({ pathname: `/companies/${this.props.match.params.companyId}/app/content/${this.props.contentId}/config`, label: `Config` });

    const title = this.props.content.title ?? " ";

    const canUpdateContent = this.props.content.canEdit;

    return (
      <>
        <DetailsHeader
          title={title}
          canUpdate={canUpdateContent}
          canUpdateName={canUpdateContent}
          canDelete={canUpdateContent}
          isLoading={isLoading}
          onNameSaveClicked={this.onEditNameSave}
          onDeleteClicked={this.onDeleteClicked}
          onEditClicked={this.onEditClicked}
        />
        <SubNavigation links={subNavigationLinks} slim />
        <Switch>
          <Redirect exact from="/companies/:companyId/app/content/:contentId/" to={{ pathname: `/companies/${this.props.match.params.companyId}/app/content/${this.props.contentId}/locations` }} />
          <Route path="/companies/:companyId/app/content/:contentId/locations" children={(props) => <ContentLocations {...props} contentId={this.props.contentId} />} />
          <Route path="/companies/:companyId/app/content/:contentId/groups" children={(props) => <ContentGroups {...props} contentId={this.props.contentId} />} />
          <Route path="/companies/:companyId/app/content/:contentId/config" children={(props) => <ContentInfo {...props} contentId={this.props.contentId} />} />
          <Route component={NotFound} />
        </Switch>
        <div style={{ paddingTop: "40px" }} />
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    content: state.content,
    isLoading: state.loading.content,
    selectedCompany: state.auth.selectedCompany
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    getContent: contentActions.getContent,
    updateContent: contentActions.updateContent,
    deleteContent: contentActions.deleteContent,
    clearContent: contentActions.clearContent
   }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(ContentDetails);
