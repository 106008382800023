import React from "react";
import Freetext from "../../components/Freetext";
import style from "./style.module.scss"

const TermsOfService = () => (
  <div className={style.outerScroll}>
    <div className={style.container}>
      <div className={style.innerContainer}>

        <Freetext header="Terms of service" />

        <h2>1 Introduction</h2>
        <p>The terms and conditions (the &quot;Terms&quot;) outlined here are a fundamental component of all contracts between BLDNG.ai AS (as the &quot;Provider&quot;) and the purchasing entity (referred to as the &quot;Customer&quot;), governing the provision of BLDNG.ai AS&apos;s services to the Customer. These terms are automatically included in every agreement unless both parties explicitly agree to different terms in a written format.</p> 
        <p>These Terms are subject to change. The Provider will provide notice by email of any significant changes to these Terms, effective as of the date specified in the notice. Continued use of the services after such modifications will constitute acknowledgment and acceptance of the modified Terms.</p>
        
        <h3>1.1 Definitions</h3>
        <ol type="a">
          <li><strong>Services</strong> - Services purchased by the Customer may include, but are not limited to, software components, sensors, gateways, workshops, and project management.</li>
          <li><strong>IoT Data</strong> - Information deemed relevant for data analysis by the Customer and the Provider.</li>
          <li><strong>IoT Data Source</strong> - A physical or logical unit that serves information to be processed by the Provider&apos;s Services.</li>
          <li><strong>Derived Data</strong> - Artifacts produced from processing IoT Data into operational metrics, profiling, and statistical models.</li>
        </ol>

        <h2>2 Acceptance of terms</h2>
        <p>BLDNG.ai AS is registered under Norwegian law with organization number 926707345 and business address Snarøyveien 30, 1360 Fornebu, Norway. The Customer may purchase services from the Provider by entering into an agreement and thereby accepting these Terms. Unless otherwise agreed in writing, the Terms apply to all deliveries and commercial relationships.</p>

        <h2>3 Description of service</h2>
        <p>The Provider provides Services related to collecting data recorded from IoT Data Sources and delivering insights from processed data. Services include, but are not limited to:</p>
        <ol type="a">
          <li>Storage of IoT Data</li>
          <li>Real-time processing of IoT Data</li>
          <li>Data Analytics</li>
          <li>Installing and maintaining physical IoT installations</li>
          <li>End-user applications</li>
        </ol>
        <p>The Services are subject to the service levels and uptime guarantees defined in the Service Level Agreement.</p>
        <p>The Provider processes personally identifiable information to facilitate the Customer in maintaining its user base through the Services&apos; administration interfaces. The Privacy Policy outlines the processing of this information.</p>

        <h2>4 Privacy policy</h2>
        <p>The Provider publishes and maintains an updated privacy policy at the following URL: <a href="https://admin.bldng.ai/privacy-policy">https://admin.bldng.ai/privacy-policy</a>.</p>
        <p>The Customer may enter into a Data Processing Agreement upon written request.</p>

        <h2>5 Payment and delivery</h2>
        <h3>5.1 Subscription period</h3>
        <p>The Customer must purchase the Provider&apos;s Services for at least 12 months unless both parties explicitly agree to different terms in a written format.</p>

        <p>The Subscription Period will be extended automatically by 12 months thereafter unless either party notifies the other in writing.</p>

        <h3>5.2 Payment terms</h3>
        <p>The following Payment Terms apply unless both parties explicitly agree to different terms in a written format.</p>
        
        <h4>5.2.1 Regular payment schedule</h4>
        <p>Services are made available on a subscription basis in monthly installments. Payments are due within a net period of 30 days from the invoice date. The invoice shall be sent by email.</p>
        <p>The Provider reserves the right to adjust the monthly fee once per calendar year following Statistics Norway&apos;s producer price index for IT services, subject to a three-month advance notice.</p>

        <h4>5.2.2 Late payment consequences</h4>
        <p>Payment is considered made on the day the Provider receives the payable sum. In the event of a payment delay beyond the due date, a Delayed Payment Fee (&quot;Standardkompensasjon&quot;) shall be levied. This fee is denominated in the up-to-date amount determined by the Norwegian Ministry of Finance (&quot;Forskrift om forsinkelsesrente og kompensasjon for inndrivelseskostnader&quot;).</p>
        <p>Additionally, interest shall accrue on any unpaid balance starting from the calendar day following the due date until the payment is made in full. The Late Payment Interest rate is defined as the up-to-date interest rate (&quot;Forsinkelsesrente&quot;) determined by the Norwegian Ministry of Finance (&quot;Forskrift om forsinkelsesrente og kompensasjon for inndrivelseskostnader&quot;).</p>

        <h4>5.2.3 Actions on extended payment delays</h4>
        <p>If payment is delayed for more than 14 days, the outstanding amount, including the Delayed Payment Fee and accrued Late Payment Interests, may be transferred to a debt collection service of the Provider&apos;s choosing. The Customer shall be responsible for any additional costs incurred by the debt collection process.</p>
        <p>It is the Customer&apos;s responsibility to ensure timely payment of invoices to avoid additional fees and service disruption. The Provider reserves the right to suspend ongoing service provision in case of extended payment delays.</p>

        <h3>5.3 Cancellation</h3>
        <p>The Customer may cancel the agreement at any time. Upon cancellation, the Customer enters a 3-month cancellation period. After which, the contract is concluded unless the Customer is still obliged to stay in the agreement due to an agreed Subscription Period.</p>
        <p>The Customer shall comply with the Subscription Period unless both parties explicitly agree to different terms in a written format.</p>

        <h2>6 Property rights</h2>
        <h3>6.1 Customer IoT Data</h3>
        <p>The Customer shall have all rights to IoT Data processed and stored as part of this agreement. However, the Customer grants the Provider a worldwide right to use the IoT Data to provide its Services to the Customer for the Subscription Period.</p>
        <p>The Customer shall grant the Provider the right to derive data and insights from IoT Data to provide the Services. Derived Data shall be considered artifacts of the Provider&apos;s expertise and proprietary to the Provider.</p>
        <p>The Customer may use IoT Data provided by the Provider in third-party solutions. Such solutions shall be considered artifacts of the Customer&apos;s know-how and proprietary to the Customer.</p>

        <h4>6.1.1 Provision for customer support</h4>
        <p>The Customer shall grant key Provider personnel access to the Customer Data to perform Customer support as required.</p>

        <h3>6.2 Intellectual properties and trade secrets</h3>
        <p>The Customer acknowledges that the Provider&apos;s Intellectual Property includes valuable Trade Secrets and is protected by applicable laws. The Provider grants the Customer a limited, nonexclusive license to use its Intellectual Property to the extent required for using the Services as permitted under this Contract.</p>

        <h4>6.2.1 Definition of trade secrets</h4>
        <p>For this agreement, &quot;trade secrets&quot; refers to any confidential business information, technical or commercial information, including, but not limited to, algorithms, formulas, techniques, procedures, and know-how that are developed, discovered, or generated by the Provider and have economic value or provide a competitive advantage.</p>

        <h4>6.2.2 Confidentiality and non-use obligations</h4>
        <p>The Customer acknowledges and agrees to treat all trade secrets disclosed by the Provider as confidential. The Customer shall use the trade secrets exclusively for the purpose of using the Services offered. The Customer shall not disclose, transfer, or make any unauthorized use of the trade secrets for its benefit or the benefit of any third party without the Provider&apos;s prior written consent.</p>

        <h4>6.2.3 Limitations on disclosure, duration, and return of trade secrets</h4>
        <p>Customer shall limit the disclosure of trade secrets to its employees, contractors, or agents who have a legitimate need to know to use the Services. The Customer shall ensure that such individuals are bound by confidentiality and non-use obligations at least as protective as those outlined in this agreement.</p>
        <p>The confidentiality and non-use obligations shall remain in effect during the contract term and six months after its termination.</p>

        <h3>6.3 Open source</h3>
        <p>The Services may contain Open-Source software components developed by third parties and published under Open-Source licenses. The Provider may produce a list of such software components and the applicable licenses upon request. The Provider does not own the Intellectual Property of Open-Source software components. The Customer shall adhere to such Open-Source license terms.</p>

        <h2>7 Infringement of third-party intellectual property rights</h2>
        <p>The Provider shall indemnify the Customer against all liability or cost resulting from any third-party claims made against the Customer to the extent such Liability arises from the Provider knowingly having infringed the proprietary right of any third party.</p>
        <p>If the Service or part thereof is, or, in the opinion of the Provider, could become the subject of a lawsuit regarding infringement of third-party rights, the Provider may choose to:</p>
        <ol type="a">
          <li>Provide the Customer with the right to use the Services exempt from liability.</li>
          <li>Modify the Services so that it no longer infringes on the rights of any third party.</li>
          <li>Refund the price paid pro rata.</li>
        </ol>

        <h2>8 Acceptable use policy</h2>
        <p>This Acceptable Use Policy (&quot;AUP&quot;) sets forth the guidelines and acceptable behavior for using the Services. By using these services, the Customer agrees to comply with this AUP and ensure that any employees, contractors, or agents who use the Services also comply.</p>

        <h3>8.1 No misuse of APIs</h3>
        <ol type="a">
          <li><strong>System overload prohibited</strong> - Any attempts to overload, disrupt, or sabotage the system through the APIs are prohibited. This includes any actions that could negatively impact the performance or security of the services.</li>
          <li><strong>Responsible usage</strong> - The APIs should be used consistently with their intended purpose and should not be utilized in a way that could harm the service or impair its use by others. This includes adhering to any usage limits or restrictions the Provider sets.</li>
        </ol>

        <h3>8.2 No misuse of user account data</h3>
        <ol type="a">
          <li><strong>Confidentiality and integrity</strong> - Customers must respect the confidentiality and integrity of any user account information accessed through our services.</li>
          <li><strong>Prohibition of unauthorized access</strong> - Unauthorized access, sharing, or use of other users&apos; account data is forbidden. Customers are expected to safeguard user data and prevent unauthorized access or distribution.</li>
        </ol>

        <h3>8.3 Responsibility for user access and data protection</h3>
        <ol type="a">
          <li><strong>User access management</strong> - Customers are responsible for maintaining the correct level of user access within their organization, especially regarding personally identifiable information and insights derived from IoT data within the Service.</li>
          <li><strong>Internal controls</strong> - The Customer must implement and maintain adequate internal controls to prevent unauthorized access or disclosure of sensitive data.</li>
        </ol>

        <h3>8.4 Compliance with laws and regulations</h3>
        <ol type="a">
          <li><strong>Adherence to laws</strong> - Customers must use the Services in compliance with all applicable laws, regulations, and ethical standards. This includes, but is not limited to, data protection and privacy laws.</li>
          <li><strong>Prohibition of illegal use</strong> - Using the service for illegal activities or promoting illicit acts is prohibited.</li>
        </ol>

        <h2>9 Warranties and liability</h2>
        <h3>9.1 Service availability and uptime</h3>
        <p>The expected uptime for the services is governed by a separate Service Level Agreement (&quot;SLA&quot;). The Provider commits to maintaining service availability, subject to the terms outlined in the SLA.</p>

        <h3>9.2 Limitation of liability</h3>
        <ol type="a">
          <li><strong>Liability for data loss and service interruptions</strong> - The Provider&apos;s liability in cases of data loss, service interruptions, or other issues shall be limited as specified in the SLA. The Provider shall not be liable for any indirect, incidental, special, or consequential damages resulting from the use or inability to use the services.</li>
          <li><strong>Force majeure</strong> - The Provider shall not be liable for any failure to perform its obligations where such failure results from any cause beyond the Provider&apos;s reasonable control, including, without limitation, mechanical, electronic, cloud platform outage, or communications failure or degradation (including &quot;force majeure&quot; or any other natural disasters, pandemic, or governmental act).</li>
        </ol>

        <h2>10 Remedies for delays and defects</h2>
        <ol type="a">
          <li><strong>Addressing delays and defects</strong> - In case of any delays or defects in the Services, the remedies available to the Customer will be limited to those expressly provided in the SLA. The Provider&apos;s primary remedy shall be correcting the defect or providing a workaround solution, subject to the terms outlined in the SLA.</li>
        </ol>
        
        <div className={style.footnote}>Last updated 13/12/2023</div>
        
      </div>
    </div>
  </div>
);

export default TermsOfService;
