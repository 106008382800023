import get from "lodash/get";
import { toast } from "react-toastify";
import * as types from "../ApiTypes";

const initialState = {
  buildings: {}
};

export default function occupancyReducer(state = initialState, action) {

  switch (action.type) {

    case types.REQ_DATA: {
      if (action.fetchType === types.GET_OCCUPANCY_METRICS) {
        let buildings = JSON.parse(JSON.stringify(state.buildings));
        action.metadata.locationIds.forEach(locationId => {
          buildings[locationId] = {};
        });
        return { buildings };
      }
      
      return state;
    }

    case types.RECV_DATA: {
      if (action.fetchType === types.GET_OCCUPANCY_METRICS) {
        let buildings = JSON.parse(JSON.stringify(state.buildings));

        // Set all the buildings to loaded and null data
        const buildingIds = Object.keys(buildings);
        buildingIds.forEach(buildingId => {
          buildings[buildingId] = {};
        });

        // Fill in occupancy data for each building
        action.payload.forEach(agg => {
          if (buildings[agg.buildingId][agg.type] === undefined) {
            buildings[agg.buildingId][agg.type] = agg.monthlyUsage;
          }
          else {
            // Check if stored data is for the previous month or the following month
            // Store current month data plus change in occupancy
            let storedMonth = new Date(buildings[agg.buildingId][agg.type].month);
            let newMonth = new Date(agg.monthlyUsage.month);

            // If stored month is after new month
            if (storedMonth > newMonth) {
              buildings[agg.buildingId][agg.type].avgChange = buildings[agg.buildingId][agg.type].avg - agg.monthlyUsage.avg;
              buildings[agg.buildingId][agg.type].peakChange = buildings[agg.buildingId][agg.type].peak - agg.monthlyUsage.peak;
            }
            else {
              buildings[agg.buildingId][agg.type].avgChange = agg.monthlyUsage.avg - buildings[agg.buildingId][agg.type].avg;
              buildings[agg.buildingId][agg.type].peakChange = agg.monthlyUsage.peak - buildings[agg.buildingId][agg.type].peak;
              buildings[agg.buildingId][agg.type].avg = agg.monthlyUsage.avg;
              buildings[agg.buildingId][agg.type].peak = agg.monthlyUsage.peak;
              buildings[agg.buildingId][agg.type].month = agg.monthlyUsage.month;
            }
          }
        });

        return { buildings };
      }
      
      return state;
    }

    case types.RECV_ERROR: {

      const statusCode = get(action, "payload.response.status", "Error");
      
      if (action.fetchType === types.GET_OCCUPANCY_METRICS) {
        toast.error(`${statusCode}: Could not get occupancy metrics`);

        let buildings = JSON.parse(JSON.stringify(state.buildings));
        const buildingIds = Object.keys(buildings);
        buildingIds.forEach(buildingId => {
          buildings[buildingId] = null;
        });

        return { buildings };
      }
      
      return state;
    }

    case types.CLEAR_DATA: {
      return initialState;
    }

    default: {
      return state;
    }
  }
}
