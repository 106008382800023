import React, { Component } from "react";
import { get } from "lodash";
import { DASHBOARD_URL, DISPLAY_CONFIG_URL } from "../../env";
import axios from "axios";

function postMessage(targetWindow, mesg, targetOrigin) {
  targetWindow.postMessage(JSON.stringify(mesg), targetOrigin);
}

class InsightView extends Component {

  constructor(props) {
    super(props);
    this.state = { 
      dashboardURL: null, 
      src: null, 
      accessToken: null,
      displayId: null 
    };

    this.iframe = React.createRef();
  }
  
  async componentDidMount() {
    const url = await DASHBOARD_URL();

    this.setState({
      dashboardURL: url,
      accessToken:btoa(this.props.accessToken)
    });

    window.addEventListener("message", this.onMessage);
  }

  componentWillUnmount() {
    window.removeEventListener("message", this.onMessage);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.accessToken !== this.props.accessToken) {
      window.postMessage({
        action:'connected',
        data: {
          access_token:btoa(this.props.accessToken),
        }
      }, this.state.dashboardURL)
    }
  }

  getToken = async (token) => {
    const displayConfigUrl = await DISPLAY_CONFIG_URL();

    const { data } = await axios
      .get(`${displayConfigUrl}display/configuration`, {
        headers:{
          Authorization: `Bearer ${token}`,
        }
      })
    return get(data,['dashboard','token'],null)
  
  }

  onMessage = (e) => {

    function getData(data) {
      if (typeof data === "string") {
        try {
          data = JSON.parse(data);
        } catch (e) {
          // Got junk in the message, ignore.
          data = {};
        }
      }
      return data;
    }

    const source = e.source;
    const origin = e.origin;
    const data = getData(e.data) || {};
    const action = data.action;

    switch (action) {
      case "reconnect":
        this.getToken(this.props.displayId).then((token)=>{
          postMessage(source, {
            action:'connected',
            data: {
              access_token:token,
            }
          }, origin)
        }).catch((e)=>{
          postMessage(source, {
            action:'connect-failed',
            data: {
              msg:e.message
            }
          }, origin)
        })
        
        break;
      default:
      //none
    }
  }

  render() {

    if (!this.state.dashboardURL) {
      return null;
    }

    return (
      <iframe
        title="dashboard"
        height="100%"
        width="100%"
        ref={this.iframe}
        src={`${this.state.dashboardURL}/companies/${this.props.companyId}/dashboards/${this.props.dashboardId}/view`}
        allow="clipboard-read; clipboard-write"
      />
    );
  }
}
export default InsightView;
