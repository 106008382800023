import { get, isEmpty, isBoolean, isObject, isArray } from "lodash";
import { toast } from "react-toastify";
import { REQ_DATA, RECV_DATA, RECV_ERROR, GET_COMPANY_DETAILS, UPDATE_COMPANY_DETAILS, UPDATE_COMPANY_DETAILS_DRAFT, CLEAR_COMPANY_DETAILS_DRAFT } from "../ApiTypes";

const initialState = {
  data: {},
  draft: {},
};

export default function companyReducer(state = initialState, action) {

  switch (action.type) {

    case REQ_DATA: {

      if (action.fetchType === GET_COMPANY_DETAILS) {
        return {...state, data: {} };
      }
      
      return state;
    }

    case RECV_DATA: {

      if (action.fetchType === GET_COMPANY_DETAILS) {
        const payload = action.payload;
        const company = {};
        company.mazemapCampusTag = payload.mazemapCampusTag || "";
        company.showMapIcons = get(payload, "mapConfig.showMapIcons", false);
        company.statusFilter = get(payload, "mapConfig.statusFilter", "");
        company.labelFilter = get(payload, "mapConfig.labelFilter", "");
        company.appLabelFilter = get(payload, "mapConfig.appLabelFilter", "");
        company.iconFilter = get(payload, "mapConfig.iconFilter", "");
        company.scimActive = get(payload, "scim.active", false);
        company.applicationFeatures = get(payload, "applicationFeatures", {});
        company.dashboardFeatures = get(payload, "dashboardFeatures", {});
        company.serviceFeatures = get(payload, "serviceFeatures", {});
        company.theme = get(payload, "theme", null);
        company.screenTheme = get(payload, "screenTheme", null);
        company.hideMotionInRoomsWithCalendar = payload.hideMotionInRoomsWithCalendar || false;
        company.hideMotionInRoomsWithoutCalendar = payload.hideMotionInRoomsWithoutCalendar || false;
        return {...state, data: company };
      }

      if (action.fetchType === UPDATE_COMPANY_DETAILS) {
        toast.success("Company updated");
        return {...state, draft: {} };
      }
      
      return state;
    }

    case RECV_ERROR: {

      const statusCode = get(action, "payload.response.status", "Error");

      if (action.fetchType === GET_COMPANY_DETAILS) {
        return {...state, data: {} };
      }

      if (action.fetchType === UPDATE_COMPANY_DETAILS) {
        toast.error(`${statusCode}: Could not update company`);
        return state;
      }

      return state;
    }

    case UPDATE_COMPANY_DETAILS_DRAFT: {
      const newDraft = { ...state.draft, ...action.draft };

      // console.log("action.draft", action.draft)
      // console.log("state.draft", state.draft)
      // console.log("state.data", state.data)
      
      Object.keys(newDraft).forEach(draftKey => {
        if (action.draft[draftKey] === state.data[draftKey]) {
          // Ignore value
          delete newDraft[draftKey];
        }
        else if (JSON.stringify(state.data[draftKey]) === JSON.stringify(newDraft[draftKey])) {
          // Ignore value
          delete newDraft[draftKey];
        }
        else if (!isArray(newDraft[draftKey]) && isObject(newDraft[draftKey])) {
          newDraft[draftKey] = { ...state.data[draftKey], ...state.draft[draftKey], ...action.draft[draftKey] };
        }
      });

      return {...state, draft: newDraft };
    }

    case CLEAR_COMPANY_DETAILS_DRAFT: {
      return {...state, draft: {}};
    }

    default:
      return state;
  }
}
