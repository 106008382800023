import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import isEmpty from "lodash/isEmpty";
import SegmentedControl from "../../components/SegmentedControl";
import * as locationActions from "../../actions/locations";
import * as selectedActions from "../../actions/selected";
import style from "./style.module.scss";

class MazeMap extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
      drawMode: "simple_select"
    };
    this.onEditModeChange = this.onEditModeChange.bind(this);
    this.changeDrawMode = this.changeDrawMode.bind(this);
  }

  onEditModeChange(value) {
    this.props.changeEditMode(value);
  }

  changeDrawMode(value) {
    this.props.setMapMode(value);
  }

  render() {

    const hasSelection = !isEmpty(this.props.selectedLocations) || !isEmpty(this.props.selectedSensors) || !isEmpty(this.props.selectedGateways);
    
    return (
      <div className={hasSelection ? style.mapContainer : style.mapContainerHidden}>
        {/* map overlay */}
        <div className={style.modeButton}>
          <SegmentedControl 
            name="mode" 
            value={this.props.editMode} 
            onChange={this.onEditModeChange} 
            options={[ 
              { label: "Navigate", value: "goto", default: true },
              { label: "Edit", value: "edit", color: "red" }
            ]} 
          />
        </div>
        { this.props.editMode === "edit" && 
          (
            <div className={style.editModeButton}>
              <SegmentedControl 
                name="mode" 
                value={this.props.mapMode} 
                onChange={this.changeDrawMode} 
                options={[ 
                  { label: "Select", value: "simple_select", default: true },
                  { label: "Rotate", value: "RotateMode", color: "green" }
                ]} 
              />
            </div>
          )
        }
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    location: state.location,
    sensors: state.sensors.data.sensors,
    company: state.auth.selectedCompany,
    user: state.auth.user,
    companyMap: state.auth.map,
    mapMode: state.selected.mapMode,
    editMode: state.selected.editMode
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    saveGeoJsonFeature: locationActions.saveGeoJsonFeature,
    changeEditMode: selectedActions.changeEditMode,
    setMapMode: selectedActions.setMapMode
   }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(MazeMap);
