import * as types from "../ApiTypes";
import { SUPPORT_EMAIL, INCIDENTS_EMAIL } from "../env";

export const getFeedbackTypes = () => async (dispatch) => {

  const supportEmail = await SUPPORT_EMAIL();
  const incidentsEmail = await INCIDENTS_EMAIL();

  const feedbackTypes = [
    { id:"general", name:"General request", email: supportEmail },
    { id:"support", name:"Support request", email: supportEmail },
    { id:"bug", name:"Bug report", email: incidentsEmail },
    { id:"suggestion", name:"Suggestion", email: supportEmail }
  ];

  dispatch({ type: types.GOT_FEEDBACK_TYPES, payload: feedbackTypes });
};


export const updateFeedbackForm = (data) => async (dispatch) => {
  dispatch({ type: types.UPDATE_FEEDBACK_FORM, payload: data });
};

export const clearDisplayData = () => async (dispatch) => {
  dispatch({ type: types.CLEAR_DATA });
}