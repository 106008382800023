import React, { Component } from "react";
import { Container, Col, Row, Hidden, Visible } from "react-grid-system";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getParameterByName } from "../../helpers";
import Freetext from "../../components/Freetext";
import OptionFooter from "../../components/OptionFooter";
import Button from "../../components/Button";
import LargeButton from "../../components/LargeButton";
import * as displayActions from "../../actions/display";
import style from "./style.module.scss";

class DisplayTypeSelect extends Component {

  constructor(props) {
    super(props);
    this.state = {
      mode: props.match.params.mode,
      displayId: getParameterByName("code")
    };
    this.onCancel = this.onCancel.bind(this);
    this.onMapClick = this.onMapClick.bind(this);
    this.onDashboardClick = this.onDashboardClick.bind(this);
  }
  
  onCancel() {
    this.props.history.push(`/companies/${this.props.match.params.companyId}/displays`);
  }

  onMapClick() {
    if (this.state.mode === "new") {
      this.props.clearDisplayData();
    }
    else {
      const form = {...this.props.form};
      delete form.reportId;
      this.props.updateDisplayForm(form);
    }
    
    this.props.history.push(`/companies/${this.props.match.params.companyId}/config-display/${this.state.mode}/locations/root?code=${this.state.displayId}`);
  }

  onDashboardClick() {
    if (this.state.mode === "new") {
      this.props.clearDisplayData();
    }
    else {
      const form = { name: this.props.form.name };
      this.props.updateDisplayForm(form);
    }

    this.props.history.push(`/companies/${this.props.match.params.companyId}/config-display/${this.state.mode}/insights?code=${this.state.displayId}`);
  }

  render() {
    // console.log("DisplayTypeSelect.render()", this.props);

    return (
      <div className={style.outerEditScroll}>
        <Container className={style.editContainer}>
          <Row className={style.topRow}>
            <Col>
              <Freetext header="Select type" content="Should the display show a map or a report?" />
            </Col>
          </Row>
          <Hidden xs sm md>
            <Row>
              <Col>
                <LargeButton text="Map" onClick={this.onMapClick} />  
              </Col>
            </Row>
            <Row>
              <Col>
                <LargeButton text="Insight" onClick={this.onDashboardClick} />  
              </Col>
            </Row>
          </Hidden>
          <Visible xs sm md>
            <Row>
              <Col>
                <Button text="Map" onClick={this.onMapClick} />  
              </Col>
            </Row>
            <Row>
              <Col>
                <Button text="Insight" onClick={this.onDashboardClick} />  
              </Col>
            </Row>
          </Visible>
          <div style={{ paddingTop: "40px" }} />
        </Container>
        <OptionFooter cancel={this.onCancel} />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    form: state.displays.form
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    updateDisplayForm: displayActions.updateDisplayForm,
    clearDisplayData: displayActions.clearDisplayData
  }, dispatch);
}

export default connect(mapStateToProps,mapDispatchToProps)(DisplayTypeSelect);
