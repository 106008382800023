import React, { Component } from "react";
import { Container, Col, Row } from "react-grid-system";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Freetext from "../../components/Freetext";
import { ControlledDropdownSelection } from "../../components/DropdownSelection";
import SelectionFooterCell from "../../components/SelectionFooterCell";
import Button from "../../components/Button";
import Loader from "../../components/Loader";
import TextBox from "../../components/TextBox";
import * as feedbackActions from "../../actions/feedback";
import * as selectedActions from "../../actions/selected";
import style from "./style.module.scss";

class Feedback extends Component {

  constructor(props) {
    super(props);
    this.onCreateEmail = this.onCreateEmail.bind(this);
    this.onTypeChange = this.onTypeChange.bind(this);
    this.onMessageChange = this.onMessageChange.bind(this);

    this.props.getFeedbackTypes();
  }
  
  componentDidMount() {
    document.title = `BLDNG.ai - Support`;
  }

  onTypeChange(event) {
    this.props.updateFeedbackForm({ type: event.target.value, message: this.props.message });
  }

  onMessageChange(event) {
    this.props.updateFeedbackForm({ type: this.props.type, message: event.target.value });
  }

  onLocationClick(id) {
    this.props.deselectLocation(id);
  }

  onSensorClick(id) {
    this.props.deselectSensor(id);
  }

  onGatewayClick(id) {
    this.props.deselectGateway(id);
  }

  onUserClick(id) {
    this.props.deselectUser(id);
  }

  onDisplayClick(id) {
    this.props.deselectDisplay(id);
  }

  onReportClick(id) {
    this.props.deselectReport(id);
  }

  onCreateEmail() {

    const locationIds = Object.keys(this.props.selectedLocations);
    const sensorIds = Object.keys(this.props.selectedSensors);
    const gatewayIds = Object.keys(this.props.selectedGateways);
    const reportIds = Object.keys(this.props.selectedReports);
    const userIds = Object.keys(this.props.selectedUsers);
    const displayIds = Object.keys(this.props.selectedDisplays);

    const feedbackType = this.props.feedbackTypes.find((type) => (type.id === this.props.type));

    let body = `${this.props.message}\n\n\n--- DEBUG INFORMATION ---\nCompany ID: ${this.props.selectedCompany.name} (${this.props.selectedCompany._id})`;

    if (userIds.length > 0) {
      body = `${body}\nUser IDs:`;
      let addComma = false;
      userIds.forEach((id) => {
        const user = this.props.selectedUsers[id];
        body = `${body}${addComma ? "," : ""} ${user.email ? user.email : user.phone_number} (${id})`;
        addComma = true;
      });
    }

    if (locationIds.length > 0) {
      body = `${body}\nLocation IDs:`;
      let addComma = false;
      locationIds.forEach((id) => {
        const location = this.props.selectedLocations[id];
        body = `${body}${addComma ? "," : ""} ${location.name} (${id})`;
        addComma = true;
      });
    }

    if (sensorIds.length > 0) {
      body = `${body}\nSensor IDs:`;
      let addComma = false;
      sensorIds.forEach((id) => {
        const sensor = this.props.selectedSensors[id];
        body = `${body}${addComma ? "," : ""} ${sensor.name} (${id})`;
        addComma = true;
      });
    }

    if (gatewayIds.length > 0) {
      body = `${body}\nGateway IDs:`;
      let addComma = false;
      gatewayIds.forEach((id) => {
        const gateway = this.props.selectedGateways[id];
        body = `${body}${addComma ? "," : ""} ${gateway.name} (${id})`;
        addComma = true;
      });
    }

    if (reportIds.length > 0) {
      body = `${body}\nReport IDs:`;
      let addComma = false;
      reportIds.forEach((id) => {
        const report = this.props.selectedReports[id];
        body = `${body}${addComma ? "," : ""} ${report.name} (${id})`;
        addComma = true;
      });
    }

    if (displayIds.length > 0) {
      body = `${body}\nDisplay IDs:`;
      let addComma = false;
      displayIds.forEach((id) => {
        const display = this.props.selectedDisplays[id];
        body = `${body}${addComma ? "," : ""} ${display.name} (${id})`;
        addComma = true;
      });
    }
    
    body = `${body}\n----------------------------------------`;

    const encodedBody = encodeURIComponent(body);
    // console.log("encodedBody", encodedBody);
    window.location.href = `mailto:${feedbackType.email}?subject=${feedbackType.name}&body=${encodedBody}`;
  }

  render() {

    if (this.props.isLoading) {
      return <Loader fullScreen />;
    }

    // console.log("Feedback.render()", this.props);

    // Locations
    const locationIds = Object.keys(this.props.selectedLocations);
    const locations = locationIds.map((id) => {
      const location = this.props.selectedLocations[id];
      return (
        <SelectionFooterCell id={id} key={`${id}-button`} text={location.name} onClick={() => this.onLocationClick(id)} />
      );
    });

    // Sensors
    const sensorIds = Object.keys(this.props.selectedSensors);
    const sensors = sensorIds.map((id) => {
      const sensor = this.props.selectedSensors[id];
      return (
        <SelectionFooterCell id={id} key={`${id}-button`} text={sensor.name} color="green" onClick={() => this.onSensorClick(id)} />
      );
    });

    // Gateways
    const gatewayIds = Object.keys(this.props.selectedGateways);
    const gateways = gatewayIds.map((id) => {
      const gateway = this.props.selectedGateways[id];
      return (
        <SelectionFooterCell id={id} key={`${id}-button`} text={gateway.name} color="purple" onClick={() => this.onGatewayClick(id)} />
      );
    });

    // Users
    const usersIds = Object.keys(this.props.selectedUsers);
    const users = usersIds.map((id) => {
      const user = this.props.selectedUsers[id];
      return (
        <SelectionFooterCell id={id} key={`${id}-button`} text={user.email ? user.email : user.phone_number} color="orange" onClick={() => this.onUserClick(id)} />
      );
    });

    // Reports
    const reportIds = Object.keys(this.props.selectedReports);
    const reports = reportIds.map((id) => {
      const report = this.props.selectedReports[id];
      return (
        <SelectionFooterCell id={id} key={`${id}-button`} text={report.name} color="red" onClick={() => this.onReportClick(id)} />
      );
    });

    // Displays
    const displayIds = Object.keys(this.props.selectedDisplays);
    const displays = displayIds.map((id) => {
      const display = this.props.selectedDisplays[id];
      return (
        <SelectionFooterCell id={id} key={`${id}-button`} text={display.name} color="teal" onClick={() => this.onDisplayClick(id)} />
      );
    });

    return (
      <div className={style.outerScroll}>
        <Container className={style.editContainer}>
          <Row className={style.topRow}>
            <Col md={12}>
              <Freetext 
                header="Support" 
                content="Use this form to contact us or send a mail to support@bldng.ai. If you want to reference a location, sensor or gateway in you installation, find it under Setup, click the check mark next to it and return here."
                link="support@bldng.ai" />
            </Col>
          </Row>
          <div style={{ paddingTop: "40px" }} />
          <Row>
            <Col sm={12} md={12} lg={12}>
              <Freetext header="Subject" />
            </Col>
          </Row>
          <Row>
            <Col sm={12} md={12} lg={12}>
              <ControlledDropdownSelection 
                options={this.props.feedbackTypes}
                value={this.props.type}
                name="feedbackType" 
                onChange={this.onTypeChange}
              />
            </Col>
          </Row>
          {
            users.length > 0 && (
              <>
                <div style={{ paddingTop: "40px" }} />
                <Freetext header="Users" />
                <div className={style.selections}>
                  { users }
                </div>
              </>
            )
          }
          {
            locations.length > 0 && (
              <>
                <div style={{ paddingTop: "40px" }} />
                <Freetext header="Locations" />
                <div className={style.selections}>
                  { locations }
                </div>
              </>
            )
          }
          {
            sensors.length > 0 && (
              <>
                <div style={{ paddingTop: "40px" }} />
                <Freetext header="Sensors" />
                <div className={style.selections}>
                  { sensors }
                </div>
              </>
            )
          }
          {
            gateways.length > 0 && (
              <>
                <div style={{ paddingTop: "40px" }} />
                <Freetext header="Gateways" />
                <div className={style.selections}>
                  { gateways }
                </div>
              </>
            )
          }
          {
            reports.length > 0 && (
              <>
                <div style={{ paddingTop: "40px" }} />
                <Freetext header="Reports" />
                <div className={style.selections}>
                  { reports }
                </div>
              </>
            )
          }
          {
            displays.length > 0 && (
              <>
                <div style={{ paddingTop: "40px" }} />
                <Freetext header="Displays" />
                <div className={style.selections}>
                  { displays }
                </div>
              </>
            )
          }
          <div style={{ paddingTop: "40px" }} />
          <Row>
            <Col>
              <Freetext header="Message" />
              <TextBox value={this.props.message} onChange={this.onMessageChange} singleLine />
            </Col>
          </Row>
          <div style={{ paddingTop: "40px" }} />
          <div style={{ display: "flex", justifyContent: "end" }}>
            <Button text="Open in email" onClick={this.onCreateEmail} singleLine />
          </div>
          <div style={{ paddingTop: "80px" }} />
        </Container>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    selectedLocations: state.selected.locations,
    selectedSensors: state.selected.sensors,
    selectedGateways: state.selected.gateways,
    selectedCompany: state.auth.selectedCompany,
    selectedUsers: state.selected.users,
    selectedDisplays: state.selected.displays,
    selectedReports: state.selected.reports,
    type: state.feedback.type,
    message: state.feedback.message,
    feedbackTypes: state.feedback.types
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ 
    getFeedbackTypes: feedbackActions.getFeedbackTypes,
    updateFeedbackForm: feedbackActions.updateFeedbackForm,
    deselectLocation: selectedActions.deselectLocation,
    deselectSensor: selectedActions.deselectSensor,
    deselectGateway: selectedActions.deselectGateway,
    deselectDisplay: selectedActions.deselectDisplay,
    deselectReport: selectedActions.deselectReport,
    deselectUser: selectedActions.deselectUser
  }, dispatch);
}

export default connect(mapStateToProps,mapDispatchToProps)(Feedback);
