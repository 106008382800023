import React, { Component } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsis } from "@fortawesome/free-solid-svg-icons";
import styled from "styled-components";

export default class OverflowMenu extends Component {

  constructor(props) {
    super(props);
    this.state = { 
      show: false
    };
    this.showToggle = this.showToggle.bind(this);
  }

  showToggle() {
    this.setState({ show: !this.state.show });
  }

  onClick(path, index) {
    this.showToggle();

    if (this.props.history) {
      this.props.history.push(path);
    }
    else if (this.props.onSelectIndex) {
      this.props.onSelectIndex(index);
    }
  }

  render() {

    const dropdownLinks = this.props.links.find(link => link.visible);
    if (!dropdownLinks) {
      return null;
    }

    return (
      <>
        <Container>
          <MenuIcon onClick={this.showToggle}>
            <FontAwesomeIcon icon={faEllipsis} color="#222" size="lg" />
          </MenuIcon>
          { 
            this.state.show && (
              <DropDown>
                  {
                    this.props.links.map((link, index) => {

                      if (!link.visible) {
                        return null;
                      }

                      return (
                        <MenuItem key={index} onClick={() => this.onClick(link.pathname, index)} hiddenForAdmins={link.hiddenForAdmins}>
                          { link.label }
                          { link.notifications !== undefined ? <span>({link.notifications})</span> : null }
                        </MenuItem>
                      );
                    })
                  }
              </DropDown>
            )
          }
        </Container>
        {
          this.state.show && (
            <ExitBackground onClick={this.showToggle} />
          )
        }
      </>
    );
  }
}

const Container = styled.div`
  display: block;
  position: relative;
`;

const ExitBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  // background-color: rgba(0, 0, 0, 0.05);
  z-index: 99;
`;

const MenuItem = styled.div`
  padding: 10px 20px;
  cursor: pointer;
  font-size: 16px;
  color: ${props => props.hiddenForAdmins ? "#666" : "#1C4D82"};
  text-decoration: none;
  display: block;
  text-align: left;

  &:hover {
    background-color: #F2F2F2;
  }

  span {
    color: #666;
    padding-left: 6px;
    padding-right: 0;
  }
`;

const MenuIcon = styled.div`
  display: block;
  padding: 5px 10px;
  border-radius: 10px;
  cursor: pointer;
  font-size: 16px;
  color: ${props => props.hiddenForAdmins ? "#666" : "#1C4D82"};
  text-decoration: none;
  text-align: left;
  &:hover {
    background-color: #F2F2F2;
  }
`;

const DropDown = styled.div`
  position: absolute;
  top: calc(100% + 5px); /* Position it right below the Container */
  right: -4px; /* Align it to the right of the Container */
  background-color: white;
  box-shadow: 0 4px 6px -1px rgba(0,0,0,0.1),0 2px 4px -1px rgba(0,0,0,0.06);
  border-radius: 6px;
  border-width: 1px;
  border-style: solid;
  border-color: rgb(221,221,221);
  padding: 10px 0;
  z-index: 100;
  width: 160px;

  // White square with 2 grey borders (rotated 180 degrees)
  &:before {
    content: "";
    position: absolute;
    top: -7px;
    right: 14px;
    width: 12px;
    height: 12px;
    background-color: white;
    border-width: 1px;
    border-style: solid;
    border-color: rgb(221,221,221) transparent transparent rgb(221,221,221);
    transform: rotate(45deg);
    z-index: 100;
  }
  
`;