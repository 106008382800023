import React, { Component } from "react";
import isEmpty from "lodash/isEmpty";
import style from "./style.module.scss";

class SegmentedControl extends Component {
  constructor(props) {
    super(props);

    let upperIndex;
    let lowerIndex;

    // Check if we need to scroll because of too many options
    if (props.options.length > 7) {
      const selectedOptionIndex = props.options.findIndex(option => option.value === props.value);
      // Put selected option in the middle
      if (selectedOptionIndex < 3) {
        upperIndex = 0;
        lowerIndex = 4;
      } else if (selectedOptionIndex > props.options.length - 4) {
        upperIndex = props.options.length - 5;
        lowerIndex = props.options.length - 1;
      } else {
        upperIndex = selectedOptionIndex - 2;
        lowerIndex = selectedOptionIndex + 2;
      }
    }

    this.state = {
      upperIndex,
      lowerIndex
    };

    this.handleChange = this.handleChange.bind(this);
    this.onUpClicked = this.onUpClicked.bind(this);
    this.onDownClicked = this.onDownClicked.bind(this);
  }

  handleChange(value) {
    this.props.onChange(value);
  }

  onUpClicked() {
    if (this.state.upperIndex > 0) {
      this.setState(prevState => ({ lowerIndex: prevState.lowerIndex - 1, upperIndex: prevState.upperIndex - 1 }));
    }
  }

  onDownClicked() {
    if (this.state.lowerIndex < this.props.options.length - 1) {
      this.setState(prevState => ({ lowerIndex: prevState.lowerIndex + 1, upperIndex: prevState.upperIndex + 1 }));
    }
  }

  render() {
    const limitedOptions = this.props.options.filter((_, index) => {
      if (this.state.upperIndex === undefined) {
        return true;
      }

      if (this.state.lowerIndex === undefined) {
        return true;
      }

      return index >= this.state.upperIndex && index <= this.state.lowerIndex;
    });

    return (
      <>
        {
          !isEmpty(this.props.title) && (
            <label className={style.title}>{this.props.title}</label>
          )
        }
        {
          !isEmpty(this.props.label) && (
            <label className={style.label}>{this.props.label}</label>
          )
        }
        <div className={style.container} data-vertical={this.props.vertical ? true : false} data-light-bg={this.props.lightBg}>
          {this.state.upperIndex !== undefined && (
            <div className={style.button} key={`sc-up`} data-left={true} data-right={false} onClick={this.onUpClicked} data-disabled={this.state.upperIndex === 0}>
              {this.props.vertical ? "▲" : "◄"}
            </div>
          )}
          {limitedOptions.map((option, index) => {
            const selected = option.value === this.props.value;
            return (
              <div
                className={style.button}
                key={`sc-${this.props.name}${option.value}`}
                data-checked={selected}
                data-value={option.value}
                data-left={this.state.upperIndex === undefined && index === 0}
                data-right={this.state.lowerIndex === undefined && index === limitedOptions.length - 1}
                data-color={option.color || "blue"}
                data-minwidth={this.props.minwidth}
                onClick={() => this.handleChange(option.value)}
              >
                {option.label}
              </div>
            );
          })}
          {this.state.lowerIndex !== undefined && (
            <div className={style.button} key={`sc-down`} data-left={false} data-right={true} onClick={this.onDownClicked} data-disabled={this.state.lowerIndex === this.props.options.length - 1}>
              {this.props.vertical ? "▼" : "►"}
            </div>
          )}
        </div>
      </>
    );
  }
}

export default SegmentedControl;
