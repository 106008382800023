import { createStore, applyMiddleware, compose } from "redux";
// import { routerMiddleware } from "react-router-redux";
import thunk from "redux-thunk";
// import { createBrowserHistory } from "history";
import logger from "redux-logger";
import rootReducer from "./reducers";

// export const history = createBrowserHistory();

// const initialState = {};
const enhancers = [];
const middleware = [thunk];
// const middleware = [thunk, routerMiddleware(history)];

if (process.env.NODE_ENV === "development" && window) {
  /* const { devToolsExtension } = window.devToolsExtension;
  if (typeof devToolsExtension === "function") {
    enhancers.push(devToolsExtension());
  } */ 
  middleware.push(logger);
}

const composedEnhancers = compose(applyMiddleware(...middleware), ...enhancers);

const store = createStore(rootReducer, composedEnhancers);

export default store;
