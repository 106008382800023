import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { get, isEmpty } from "lodash";
import moment from "moment";
import { Audio } from "react-loader-spinner";
import Table from "../../components/Table";
import Tag from "../../components/Tag";
import SegmentedControl from "../../components/SegmentedControl";
import CheckboxBlock from "../../components/CheckboxBlock";
import { locationFilterTypes, getLocation } from "../../locationHelpers";
import { getListAvgData, queryHash } from "../../dashboardHelpers";
import { Role, colorGradient } from "../../helpers";
import * as selectedActions from "../../actions/selected";
import * as dashboardActions from "../../actions/dashboards";
import style from "./style.module.scss";

class Locations extends Component {

  constructor(props) {
    // console.log("Locations.constructor");
    super(props);
    this.state = {
      sortBy: "name", 
      sortOrder: "asc",
      date: moment(),
      displayType: this.getInitialDisplayType(props),
      showWholeDay: this.getInitialWholeDay(props),
      showWeekends: this.getInitialWeekends(props),
      showHolidays: this.getInitialHolidays(props),
    };
    this.onRowClick = this.onRowClick.bind(this);
    this.onSortedChange = this.onSortedChange.bind(this);
    this.onDisplayTypeChanged = this.onDisplayTypeChanged.bind(this);
    this.onShowWholeDayChanged = this.onShowWholeDayChanged.bind(this);
    this.onShowWeekendsChanged = this.onShowWeekendsChanged.bind(this);
    this.onShowHolidaysChanged = this.onShowHolidaysChanged.bind(this);
  }

  componentDidMount() {
    document.title = `BLDNG.ai - Home - List`;
    // console.log("Home.Dashboard.componentDidMount");
    this.update();
  }
  
  componentDidUpdate(prevProps, prevState) {
  // console.log("Home.Locations.componentDidUpdate prevProps", prevProps);
  // console.log("Home.Locations.componentDidUpdate this.props", this.props);
    this.update();
  }

  update() {
    let sensorGraphIds = [];

    if (this.props.filterSensorTypes.includes("peopleCount")) {
      sensorGraphIds.push("peopleCompareDayOfWeek");
    }

    if (this.props.filterSensorTypes.includes("occupiedMinutes")) {
      sensorGraphIds.push("occupiedCompareDayOfWeek");
      sensorGraphIds.push("maxOccupiedDay");
    }

    // Skip update if we don't have the necessary data
    if (isEmpty(this.props.locations) || isEmpty(sensorGraphIds) || isEmpty(this.props.locationHierarchy)) {
      return;
    }

    // Get core hours from locationHierarchy
    let coreTime = { start: { hours: 8, minutes: 0 }, end: { hours: 16, minutes: 0 } };
    for (var i = 0; i < this.props.locations.length; i++) {
      const location = this.props.locations[i];
      if (location.type != "region") {
        // Get core hours from parent building in locationHierarchy
        const firstLocation = getLocation({ id: "*", children: this.props.locationHierarchy }, location._id);
        if (firstLocation.coreTime) {
          coreTime = firstLocation.coreTime;
          break;
        }
      }
    }

    // Get details for locations list as well
    getListAvgData(this.state, this.props, sensorGraphIds, coreTime);
  }

  getInitialDisplayType(props) {
    const queryParams = new URLSearchParams(props.history.location.search);
    return queryParams.get("dt") || "all";
  }

  getInitialWholeDay(props) {
    const queryParams = new URLSearchParams(props.history.location.search);
    return queryParams.get("wd") === "true";
  }

  getInitialWeekends(props) {
    const queryParams = new URLSearchParams(props.history.location.search);
    return queryParams.get("w") !== "false";
  }

  getInitialHolidays(props) {
    const queryParams = new URLSearchParams(props.history.location.search);
    return queryParams.get("hd") !== "false";
  }

  onDisplayTypeChanged(value) {
    this.setState({ displayType: value });

    const params = new URLSearchParams(this.props.history.location.search);
    params.set("dt", value);
    this.props.history.push({ 
      pathname: this.props.history.location.pathname.substr(0, this.props.history.location.pathname.length),
      search: params.toString()
    });
  }

  onShowWholeDayChanged() {
    const params = new URLSearchParams(this.props.history.location.search);
    params.set("wd", !this.state.showWholeDay);
    this.props.history.push({ 
      pathname: this.props.history.location.pathname.substr(0, this.props.history.location.pathname.length),
      search: params.toString()
    });

    this.setState(prevState => ({ showWholeDay: !prevState.showWholeDay }));
  }

  onShowWeekendsChanged() {
    const params = new URLSearchParams(this.props.history.location.search);
    params.set("w", !this.state.showWeekends);
    this.props.history.push({ 
      pathname: this.props.history.location.pathname.substr(0, this.props.history.location.pathname.length),
      search: params.toString()
    });

    this.setState(prevState => ({ showWeekends: !prevState.showWeekends }));
  }

  onShowHolidaysChanged() {
    const params = new URLSearchParams(this.props.history.location.search);
    params.set("hd", !this.state.showHolidays);
    this.props.history.push({ 
      pathname: this.props.history.location.pathname.substr(0, this.props.history.location.pathname.length),
      search: params.toString()
    });

    this.setState(prevState => ({ showHolidays: !prevState.showHolidays }));
  }

  onRowClick(column, row) {
    return {
      onClick: e => {
        // console.log(row);
        if (row && column.name !== 'isSelected') {
          
          let link = `/companies/${this.props.match.params.companyId}/locations/${row.original._id}/locations/`;

          if (e.metaKey || e.ctrlKey) {
            window.open(`${link}`);
          }
          else {
            this.props.history.push(link);
          }
        }
      },
      style: {
        cursor: "pointer"
      }
    }
  }

  onToggle(row) {
    if (this.props.selectedLocations[row.original._id] !== undefined && this.props.selectedLocations[row.original._id]) {
      this.props.deselectLocation(row.original._id);
    }
    else {
      this.props.selectLocation(row.original);
    }
  }

  onToggleAll() {
    const allLocationsAreSelected = !isEmpty(this.props.locations) && this.props.locations.every(location => this.props.selectedLocations[location._id] !== undefined && this.props.selectedLocations[location._id]);
    if (allLocationsAreSelected) {
      this.props.deselectAllLocations();
    }
    else {
      this.props.selectLocations(this.props.locations);
    }
  }

  onSortedChange(newSorted) {
    const sortBy = newSorted[0].id;
    const sortOrder = newSorted[0].desc ? "desc" : "asc";
    this.setState({ sortBy, sortOrder });
  }

  getTag(id) {
    const tag = this.props.customTags.find(tag => tag.id === id);
    if (tag) {
      return <Tag key={tag.id} text={tag.name} color={tag.colorTheme} />
    }
    return null;
  }

  render() {
    // console.log("Home.Locations.state", this.state)

    // Add options
    const options = (
      <div style={{ display: "flex", gap: "10px", justifyContent: "end", flexBasis: "37%", flexGrow: 0 }}>
        <div style={{ width: "120px", marginBottom: "10px" }}>
          <CheckboxBlock key="workdayOption" label="Whole day" isChecked={this.state.showWholeDay} onClick={this.onShowWholeDayChanged} />
        </div>
        <div style={{ width: "120px", marginBottom: "10px" }}>
          <CheckboxBlock key="weekendsOption" label="Weekends" isChecked={this.state.showWeekends} onClick={this.onShowWeekendsChanged} />
        </div>
        <div style={{ width: "120px", marginBottom: "10px" }}>
          <CheckboxBlock key="holidaysOption" label="Holidays" isChecked={this.state.showHolidays} onClick={this.onShowHolidaysChanged} />
        </div>
      </div>
    );

    // Add properties to easier sort columns
    let locations = this.props.locations.map(location => ({ ...location, typeName: get(locationFilterTypes.find(l => l.id === location.type), "name", location.type) }));
    locations = locations.map(location => {
      let tagString = "";
      if (location.customTags) {
        const norway = "nb-NO";
        tagString = location.customTags
          .map(tagId => this.props.customTags.find(tag => tag.id === tagId))
          .filter(n => n)
          .map(tag => tag.name)
          .sort((a, b) => a.localeCompare(b, norway))
          .join(", ");
      }

      return { 
        ...location, 
        tagString
      };
    });

    let sampleKey = "";
    if (this.state.displayType === "occupancy") {
      sampleKey = "occupiedCompareDayOfWeek";
    }
    else if (this.state.displayType === "people") {
      sampleKey = "peopleCompareDayOfWeek";
    }
    else if (this.state.displayType === "highcount" || this.state.displayType === "lowcount") {
      sampleKey = "maxOccupiedDay";
    }

    const hash = queryHash(sampleKey, this.state, this.props);
    const hashKey = `${sampleKey}-${hash}`;
    const samples = get(this.props, `dashboardData[${hashKey}]`, []);

    // Hide rows with no data
    if (this.state.displayType !== "all") {
      locations = locations.filter(location => samples.find(sample => sample.entityId === location._id));
    }

    // if (this.state.displayType === "occupancy") {
    //   locations = locations.filter(location => samples.find(sample => sample.entityId === location._id)); // && sample.max
    // }
    // else if (this.state.displayType === "people") {
    //   locations = locations.filter(location => samples.find(sample => sample.entityId === location._id));

    // Add week day columns for display of occupancy
    let weekDays = [];
    if (samples.length > 0) {
      if (this.state.showWeekends) {
        weekDays = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
      }
      else {
        weekDays = ["Mon", "Tue", "Wed", "Thu", "Fri"];
      }

      if (this.state.displayType === "highcount" || this.state.displayType === "lowcount") {
        weekDays.push("All days");
      }
    }

    if (this.state.sortBy && this.state.sortOrder) {
      locations = locations.sort((a, b) => {
        const norway = "nb-NO";
        if (this.state.sortBy === "name") {
          return this.state.sortOrder === "asc" ? a.name.localeCompare(b.name, norway) : b.name.localeCompare(a.name, norway);
        }
        else if (this.state.sortBy === "customTags") {
          // Sort by tagString with empty tags last
          if (a.tagString === "" && b.tagString !== "") {
            return this.state.sortOrder === "asc" ? 1 : -1;
          }
          else if (a.tagString !== "" && b.tagString === "") {
            return this.state.sortOrder === "asc" ? -1 : 1;
          }
          return this.state.sortOrder === "asc" ? a.tagString.localeCompare(b.tagString, norway) : b.tagString.localeCompare(a.tagString, norway);
        }
        else if (this.state.sortBy === "type") {
          return this.state.sortOrder === "asc" ? a.typeName.localeCompare(b.typeName) : b.typeName.localeCompare(a.typeName);
        }
        else if (this.state.sortBy.includes("occupied")) {
          const sortedDayOfWeek = this.state.sortBy.split("-")[1];

          if (["occupancy", "people"].includes(this.state.displayType)) {
            const sampleA = samples.find(sample => sample.entityId === a._id && `${sample.weekday}` === `${sortedDayOfWeek}`);
            const sampleB = samples.find(sample => sample.entityId === b._id && `${sample.weekday}` === `${sortedDayOfWeek}`);

            if (this.state.sortOrder === "asc") {
              const maxA = get(sampleA, "max", 1000);
              const maxB = get(sampleB, "max", 1000);
              return (maxA > maxB ? 1 : -1);
            }
            else {
              const maxA = get(sampleA, "max", -1);
              const maxB = get(sampleB, "max", -1);
              return (maxA < maxB ? 1 : -1);
            }
          }
          else if (["highcount"].includes(this.state.displayType)) {
            const sampleA = samples.find(sample => sample.entityId === a._id);
            const sampleB = samples.find(sample => sample.entityId === b._id);

            if (this.state.sortOrder === "asc") {
              const maxA = get(sampleA, `above[${sortedDayOfWeek}]`, 1000);
              const maxB = get(sampleB, `above[${sortedDayOfWeek}]`, 1000);
              return (maxA > maxB ? 1 : -1);
            }
            else {
              const maxA = get(sampleA, `above[${sortedDayOfWeek}]`, -1);
              const maxB = get(sampleB, `above[${sortedDayOfWeek}]`, -1);
              return (maxA < maxB ? 1 : -1);
            }
          }
          else if (["lowcount"].includes(this.state.displayType)) {
            const sampleA = samples.find(sample => sample.entityId === a._id);
            const sampleB = samples.find(sample => sample.entityId === b._id);

            if (this.state.sortOrder === "asc") {
              const maxA = get(sampleA, `below[${sortedDayOfWeek}]`, 1000);
              const maxB = get(sampleB, `below[${sortedDayOfWeek}]`, 1000);
              return (maxA > maxB ? 1 : -1);
            }
            else {
              const maxA = get(sampleA, `below[${sortedDayOfWeek}]`, -1);
              const maxB = get(sampleB, `below[${sortedDayOfWeek}]`, -1);
              return (maxA < maxB ? 1 : -1);
            }
          }
        }
        return false;
      });
    }

    const allLocationsAreSelected = !isEmpty(locations) && locations.every(location => this.props.selectedLocations[location._id] !== undefined && this.props.selectedLocations[location._id]);

    const weekDayColumns = weekDays.map((day, index) => (
      {
        header: day,
        accessorKey: weekDays.length === index + 1 ? `occupied-all` : `occupied-${index + 1}`,
        minWidth: 50,
        maxWidth: 80,
        cell: ({ row }) => {

          let color1 = { red: 19, green: 233, blue: 19 };
          let color2 = { red: 255, green: 255, blue: 0 };
          let color3 = { red: 255, green: 0, blue: 0 };
          let color;
          let text;

          if (["occupancy", "people"].includes(this.state.displayType)) {
            let sample = samples.find(sample => sample.entityId === row.original._id && sample.weekday === index + 1);
          
            if (sample) {
              if (this.state.displayType === "occupancy") {
                color = colorGradient(sample.max/100, color1, color2, color3, 0.6);
                text = `${sample.max} %`;
              }
              else {
                let location = locations.find(location => location._id === row.original._id);
                let capacity = get(location, `capacity.soft`, get(location, `capacity.hard`, null));
                if (capacity) {
                  color = colorGradient(sample.max/capacity, color1, color2, color3, 0.6);
                }
                text = `${sample.max}`;
              }
            }
          }
          else if (["highcount"].includes(this.state.displayType)) {
            let sample = samples.find(sample => sample.entityId === row.original._id);

            if (!sample) {
              return null;
            }

            // If last index show "sum of all days"
            if (index === weekDays.length - 1) {
              const sum = get(sample, `above.all`, 0);
              text = sum;
              color = colorGradient(sum/(4 * (weekDays.length - 1)), color1, color2, color3, 0.6);
            }
            else {
              const sampleValue = get(sample, `above.[${index + 1}]`, -1);

              if (sampleValue < 0) {
                return null;
              }

              text = sampleValue;
              color = colorGradient(sampleValue/4, color1, color2, color3, 0.6);
            }
          }
          else if (["lowcount"].includes(this.state.displayType)) {
            let sample = samples.find(sample => sample.entityId === row.original._id);

            if (!sample) {
              return null;
            }

            // If last index show "sum of all days"
            if (index === weekDays.length - 1) {
              const sum = get(sample, `below.all`, 0);
              text = sum;
              color = colorGradient(sum/(4 * (weekDays.length - 1)), color1, color2, color3, 0.6);
            }
            else {
              const sampleValue = get(sample, `below.[${index + 1}]`, -1);

              if (sampleValue < 0) {
                return null;
              }

              text = sampleValue;
              color = colorGradient(sampleValue/4, color1, color2, color3, 0.6);
            }
          }

          return (
            <div className={style.weekDayCell} style={{ "--tooltip-color": color }}>
              <span title={text}>{text}</span>
            </div>
          );
          
        },
        style: {
          margin: 0,
          padding: 0
        }
      }
    ));

    const tableElement = (
      <Table
        data={locations}
        sortBy={this.state.sortBy}
        sortOrder={this.state.sortOrder}
        onSortedChange={this.onSortedChange}
        noDataText={"No locations found"}
        columns={[
          {
            accessorKey: "_id",
            sortable: false,
            name: "isSelected",
            header: () => (
              <label className="checkboxContainer checkboxHeaderContainer" htmlFor={`editCheckbox-header`}>
                <input
                  id={`editCheckbox-header`}
                  type="checkbox"
                  className="checkbox"
                  checked={allLocationsAreSelected}
                  onChange={() => this.onToggleAll()}
                />
                <span className="checkmark" />
              </label>
            ),
            cell: ({ row }) => (
              <label className="checkboxContainer" htmlFor={`editCheckbox-${row.original._id}`}>
                <input
                  id={`editCheckbox-${row.original._id}`}
                  type="checkbox"
                  className="checkbox"
                  checked={(this.props.selectedLocations[row.original._id] !== undefined && this.props.selectedLocations[row.original._id])}
                  onChange={() => this.onToggle(row)}
                />
                <span className="checkmark" />
              </label>
            ),
            width: 60
          },
          {
            header: "Name",
            accessorKey: "name",
            minWidth: 100,
            maxWidth: 700,
            cell: ({ row }) => {
              let breadcrumbs = this.props.locationBreadcrumbs[row.original._id].map(breadcrumb => breadcrumb.name);
              breadcrumbs.pop();
              breadcrumbs = breadcrumbs.join(", ");
              if (breadcrumbs) {
                return (<><span title={row.original.name}>{row.original.name}</span><span title={breadcrumbs} style={{ color: "grey", marginLeft: "10px" }}> ({ breadcrumbs })</span></>)
              }
              return (<span title={row.original.name}>{row.original.name}</span>)
            }
          },
          {
            header: "Type",
            accessorKey: "type",
            minWidth: 50,
            maxWidth: 200,
            cell: ({ row }) => { 
              const locationType = locationFilterTypes.find(locationType => locationType.id === row.original.type); 
              const name = get(locationType,"name", row.original.type); 
              return <span title={name}>{name}</span>
            }
          },
          {
            header: "Tags",
            accessorKey: "customTags",
            minWidth: 100,
            cell: ({ row }) => {
              if (row["customTags"]) {
                const norway = "nb-NO";
                return row["customTags"]
                  .map(tagId => this.props.customTags.find(tag => tag.id === tagId))
                  .filter(n => n)
                  .sort((a, b) => a.name.localeCompare(b.name, norway))
                  .map(tag => <Tag key={tag.id} text={tag.name} color={tag.colorTheme} />);
              }
              else {
                return null;
              }
            }
          },
          ...weekDayColumns,
          {
            id: "arrow",
            header: "",
            sortable: false,
            className: "pull-right",
            width: 60,
            cell: ({ row }) => (<div className="arrow" />)
          }
        ]}
        getTdProps={this.onRowClick}
        className="-row-clickable setMaxHeigth -highlight"
      />
    );

    let links = [
      { label: "Dashboard", value: "dashboard" },
      { label: `Locations (${this.props.locations.length})`, value: "locations" }
    ];

    if (this.props.auth.hasSupportRole) {
      links.push({ label: "Min/max", value: "minmax" });
    }

    const viewControl = (
      <div style={{ display: "flex", justifyContent: "center", flexBasis: "26%", flexGrow: 0 }}>
        <div style={{ marginRight: "10px" }}>
          <SegmentedControl  
            options={links}
            value="locations"
            onChange={this.props.onChangeView} 
          />
        </div>
      </div>
    );

    const displayControl = (
      <div style={{ display: "flex", gap: "10px", justifyContent: "start", flexBasis: "37%", flexGrow: 0 }}>
        <div style={{ marginRight: "10px" }}>
          <SegmentedControl
            name="displayType"
            lightBg
            value={this.state.displayType}
            onChange={this.onDisplayTypeChanged}
            options={[
              { label: "All locations", value: "all", default: true },
              { label: "Occupied %", value: "occupancy" },
              { label: "Busy days", value: "highcount" },
              { label: "Slow days", value: "lowcount" },
              { label: "Max people", value: "people" },
            ]}
          />
        </div>
      </div>
    );

    const heading = (
      <div style={{ display: "flex", flexWrap: "wrap", rowGap: "10px" }}>
        {displayControl}
        {viewControl}
        {options}
      </div>
    );

    const errorType = get(this.props, `dataLoadingStatus[${hashKey}].type`, null);
    const errorMessage = get(this.props, `dataLoadingStatus[${hashKey}].message`, null);

    let title;
    let description;
    if (this.state.displayType === "occupancy") {
      title = "Occupied %";
      description = "The occupancy on the busiest week days the last 4 weeks based on quarter hour data. Showing locations with occupancy data, but not personal workstations (to secure privacy).";
    }
    else if (this.state.displayType === "people") {
      title = "Max people";
      description = "The peak amount of people each week day the last 4 weeks. Showing locations with people count data.";
    }
    else if (this.state.displayType === "highcount") {
      title = "Busy days";
      description = "The number of days with occupancy above 80% the last 4 weeks. Showing locations with occupancy data, but not personal workstations (to secure privacy).";
    }
    else if (this.state.displayType === "lowcount") {
      title = "Slow days";
      description = "The number of days with occupancy below 20% the last 4 weeks. Showing locations with occupancy data, but not personal workstations (to secure privacy).";
    }
    else {
      title = "All locations";
      description = "A complete list over all locations that matches the active filter.";
    }

    let content;
    if (!this.props.hasSearchedLocations || this.props.isLoadingFilter || this.props.isLoadingLocations) {
      content = null;
    }
    else if (errorType === "loading") {
      content = (
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", minHeight: "150px" }}>
          <Audio color="#ccc" height={"75px"} width={"75px"} />
        </div> 
      );
    }
    else if (errorMessage) {
      content = (
        <div className={style.blockInnerStatusMessage}>
          Warning: {errorMessage}
        </div>
      );
    }
    else {
      content = tableElement;
    }

    return (
      <div className={style.listContainer}>
        <div className={style.scroll}>
          <div style={{ paddingTop: "40px" }} />
          { heading }
          {
            content && (
              <>
                <div style={{ paddingTop: "40px" }} />
                <div className={style.block}>
                  <div className={style.blockInnerTitle}>{ title }</div>
                  <div className={style.blockInnerDescription}>{ description }</div>
                  { content }
                </div>
              </>
            )
          }
          <div style={{ paddingTop: "40px" }} />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
    locations: state.filter.locations,
    locationHierarchy: state.filter.hierarchy,
    filterSensorTypes: state.filter.sensorTypes,
    locationBreadcrumbs: state.locations.breadcrumbs,
    dashboardData: state.dashboards.data,
    dataLoadingStatus: state.dashboards.dataLoadingStatus,
    customTags: state.customTags.list,
    isLoadingLocations: state.loading.locations,
    isLoadingFilter: state.loading.filter,
    hasSearchedLocations: state.filter.hasSearchedLocations,
    selectedLocations: state.selected.locations
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    selectLocation: selectedActions.selectLocation,
    selectLocations: selectedActions.selectLocations,
    deselectLocation: selectedActions.deselectLocation,
    deselectAllLocations: selectedActions.deselectAllLocations,
    clearSelection: selectedActions.clearSelection,
    getMonthSamples: dashboardActions.getMonthSamples,
    storeDashboardQueryHash: dashboardActions.storeDashboardQueryHash,
   }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(Locations);