import get from "lodash/get";
import { toast } from "react-toastify";
import * as types from "../ApiTypes";

const initialState = {
  currentLocation: null,
  data: {
    count: 0,
    sensors: [],
    limit: 20
  },
  metricData: {
    count: 0,
    results: [],
    limit: 20
  },
  sensorsQueryHash: null,
  discoveredSensors: null,
  discoveredSensorsGatewayId: null,
  gatewaySensors: {
    count: 0,
    sensors: [],
    limit: 100
  },
  error: false,
  createdJob: null,
  webexDevices: [],
  webexDeviceMacroStatus: null
};

export default function sensorsReducer(state = initialState, action) {

  switch (action.type) {

    case types.REQ_DATA: {
      if (action.fetchType === types.GET_LOCATION) {
        return {...state, data: initialState.data };
      }

      if (action.fetchType === types.GET_SENSORS) {
        return {...state, data: initialState.data, createdJob: null, currentLocation: action.metadata, sensorsQueryHash: `${action.metadata.id}-${JSON.stringify(action.metadata.hashParams)}` };
      }

      if (action.fetchType === types.GET_ALL_SENSORS) {
        return {...state, metricData: initialState.metricData, createdJob: null};
      }

      if (action.fetchType === types.GET_DISCOVERED_SENSORS) {
        return {...state, discoveredSensors: null, discoveredSensorsGateway: action.metadata };
      }

      if (action.fetchType === types.GET_GATEWAY_SENSORS) {
        return {...state, gatewaySensors: initialState.gatewaySensors };
      }

      if (action.fetchType === types.CLEAR_CREATED_JOB) {
        return {...state, createdJob: null };
      }

      if (action.fetchType === types.GET_WEBEX_DEVICES) {
        return {...state, webexDevices: [] };
      }

      if (action.fetchType === types.GET_WEBEX_MACRO_STATUS) {
        return {...state, webexDeviceMacroStatus: null };
      }
      
      return state;
    }

    case types.RECV_DATA: {
      if (action.fetchType === types.GET_SENSORS) {
        return {...state, data: action.payload };
      }

      if (action.fetchType === types.GET_ALL_SENSORS) {
        return {...state, metricData: action.payload };
      }

      if (action.fetchType === types.GET_DISCOVERED_SENSORS) {
        return {...state, discoveredSensors: action.payload, discoveredSensorsGatewayId: action.metadata };
      }

      if (action.fetchType === types.GET_GATEWAY_SENSORS) {
        const newData = action.payload;
        return {...state, gatewaySensors: newData };
      }

      if (action.fetchType === types.CREATE_SENSOR) {
        toast.success("Sensor created");
        var newState = {...state };
        if (action.payload.jobId) {
          newState.createdJob = action.payload;
        }
        return newState
      }

      if (action.fetchType === types.GET_WEBEX_DEVICES) {
        return {...state, webexDevices: action.payload.items };
      }

      if (action.fetchType === types.GET_WEBEX_MACRO_STATUS) {
        if (action.payload === 404) {
          toast.error(`Device offline`);
        }
        else if (action.payload === 403) {
          toast.error(`Device forbidden`);
        }
        else if (action.payload === 200) {
          toast.error(`Device already provisioned`);
        }
        else if (action.payload === 400) {
          toast.success(`Device ready`);
        }
        return {...state, webexDeviceMacroStatus: action.payload };
      }

      if (action.fetchType === types.MOVE_SENSORS_TO_GATEWAY) {
        toast.success("Sensors moved");
        return state;
      }
      
      return state;
    }

    case types.RECV_ERROR: {

      const statusCode = get(action, "payload.response.status", "Error");

      if (action.fetchType === types.GET_SENSORS) {
        toast.error(`${statusCode}: Could not get sensors`);
        return {...state, data: initialState.data };
      }

      if (action.fetchType === types.GET_ALL_SENSORS) {
        toast.error(`${statusCode}: Could not get sensors`);
        return {...state, metricData: initialState.metricData };
      }

      if (action.fetchType === types.GET_DISCOVERED_SENSORS) {
        toast.error(`${statusCode}: Could not get discovered sensors`);
        return {...state, discoveredSensors: null, discoveredSensorsGatewayId: null };
      }

      if (action.fetchType === types.GET_GATEWAY_SENSORS) {
        toast.error(`${statusCode}: Could not get sensors from gateway`);
        return {...state, gatewaySensors: initialState.gatewaySensors };
      }

      if (action.fetchType === types.CREATE_SENSOR) {
        toast.error(`${statusCode}: Could not create sensor`);
        return state;
      }

      if (action.fetchType === types.MOVE_SENSORS_TO_GATEWAY) {
        toast.error(`${statusCode}: Could not move sensors to gateway`);
        return state;
      }

      if (action.fetchType === types.DOWNLOAD_SENSOR_CSV) {
        toast.error(`${statusCode}: Could not download csv`);
        return state;
      }
      
      return state;
    }

    case types.CLEAR_DATA: {
      return initialState;
    }
    
    default: {
      return state;
    }
  }
}
