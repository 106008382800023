import React from "react";
import get from "lodash/get";
import moment from "moment";
import Graph, { GraphType, TimePeriod } from "./index";
import strings from "./strings";

export class ReportGraph extends React.Component {
  
  constructor(props) {
    super(props);
    this.state = {
      fixedScale: true,
      graphType: GraphType.Bar
    };
    this.toggleFixedScale = this.toggleFixedScale.bind(this);
    this.toggleGraphType = this.toggleGraphType.bind(this);
  }

  // Get graphType from props
  static getDerivedStateFromProps(nextProps, prevState) {
    console.log("ReportGraph.getDerivedStateFromProps", nextProps, prevState);
    if (nextProps.graphType && nextProps.graphType !== prevState.graphType) {
      return {
        graphType: nextProps.graphType
      };
    }
    return null;
  }

  toggleFixedScale() {
    this.setState(prevState => ({ fixedScale: !prevState.fixedScale }));
  }

  toggleGraphType() {
    this.setState(prevState => ({ graphType: prevState.graphType === GraphType.Bar ? GraphType.Line : GraphType.Bar }));
  }

  render() {
    // console.log("ReportGraph.render.props", this.props);

    // Update language
    if (this.props.language) {
      moment.locale(this.props.language);
      strings.setLanguage(this.props.language);    }

    // Get number of weeks between x.min and x.max
    let startDate = moment(this.props.x.min);
    let endDate = moment(this.props.x.max);
    const numWeeks = Math.ceil((endDate - startDate) / (1000 * 60 * 60 * 24 * 7));

    // Set aspect ratio based if we compare graphs or not
    let aspectRatio = 2;
    // console.log("window.innerWidth", window.innerWidth);
    if (window.innerWidth > 768) {
      if (this.props.showInFullWidth || this.props.expanded) {
        aspectRatio = 2.6;
      }
    }

    // Create title
    let title;
    if (this.props.dataType === "utilization") {
      title = strings.formatString(strings.utilizationTitle, this.props.workplaceCategory || strings.everything, this.props.locationName || strings.unknown, startDate.format("DD/MM/YYYY"), endDate.format("DD/MM/YYYY"));
    }
    else if (this.props.dataType === "peopleCount") {
      title = strings.formatString(strings.peopleCountTitle, this.props.locationName || strings.unknown, startDate.format("DD/MM/YYYY"), endDate.format("DD/MM/YYYY"));
    }
    else if (this.props.dataType === "realCoverageDegree") {
      title = strings.formatString(strings.realCoverageDegreeTitle, this.props.locationName || strings.unknown, startDate.format("DD/MM/YYYY"), endDate.format("DD/MM/YYYY"));
    }
    
    return (
      <Graph
        { ...this.props}
        type={title}
        graphType={this.state.graphType}
        aspectRatio={aspectRatio}
        fixedScale={get(this.props.y, "max", null) ? this.state.fixedScale : false}
        toggleFixedScale={get(this.props.y, "max", null) ? this.toggleFixedScale : undefined}
        toggleGraphType={this.toggleGraphType}
      />
    );
  }
}

export default ReportGraph;