import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Switch, Route, withRouter } from "react-router-dom";
import Loader from "../../components/Loader";
import NotFound from "../NotFound";
import SubNavigation from "../../components/SubNavigation";
import LargeHeader from "../../components/LargeHeader";
import Screens from "./screens";
import style from "./style.module.scss";

class ScreenContainer extends Component {

  render() {
    // console.log("ScreenContainer", this.props);
    if (this.props.isAuthLoading) {
      return <Loader fullScreen />;
    }

    const subNavigationLinks = [];
    subNavigationLinks.push({ pathname: `/companies/${this.props.match.params.companyId}/screens`, label: `Overview` });

    return (
      <div className={style.page}>
        <LargeHeader title="Screens" />
        <SubNavigation links={subNavigationLinks} /> 
        <Switch>
          <Route path="/companies/:companyId/screens/:screenGroupId?" children={(props) => <Screens {...props} screenGroupId={props.match.params.screenGroupId} />} />
          <Route render={NotFound} />
        </Switch>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return { 
    auth: state.auth,
    selectedCompany: state.auth.selectedCompany,
    isAuthLoading: state.loading.auth,
    locationHierarchy: state.locations.hierarchy,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
   }, dispatch);
}


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ScreenContainer));
