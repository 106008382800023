import Cookies from "universal-cookie";
import { GET_DISPLAY_CONFIG, REQ_DATA, RECV_DATA, RECV_ERROR, RESTART_MESSAGE } from "../ApiTypes";

// Look into what is stored in the cookie
const cookies = new Cookies();
const displayCode = cookies.get("sb_display_code");

const initialState = {
  displayCode,
  displayConfig: null,
  roomUrl: "",
  restartMessageDate: null,
  error: false
};

export default function displayReducer(state = initialState, action) {
  switch (action.type) {

    case REQ_DATA: {
      return state;
    }

    case RECV_DATA: {

      if (action.fetchType === GET_DISPLAY_CONFIG) {
        return {...state, error: false, displayConfig: action.payload, roomUrl: action.metadata };
      }

      if (action.fetchType === RESTART_MESSAGE) {
        return {...state, error: false, restartMessageDate: action.payload };
      }
      
      return state;
    }

    case RECV_ERROR: {

      if (action.fetchType === GET_DISPLAY_CONFIG) {
        return {...state, error: true, displayConfig: null };
      }
      
      return state;
    }

    default:
      return state;
  }
}
