let updated = false;
export const BarWidthPlugin = {
  id: "barWidth",
  beforeDraw: (chart) => {

    const ctx = chart.ctx;
    const { datasets } = chart.data;
    // console.log("getDatasetMeta", chart.getDatasetMeta);

    if (datasets.length > 0) {
      const datasetMeta = chart.getDatasetMeta(0);
      // console.log("datasetMeta", datasetMeta);

      if (datasetMeta.type === "bar" && datasetMeta.data.length > 0) {
        const barWidth = datasetMeta.data[0].width;
        // console.log("barWidth", barWidth);
        chart.$_reportBarWidth = barWidth;
      }
    }

    // var barWidth = chart.getDatasetMeta(1).data[0]._model.width;
    // console.log("barWidth", barWidth);
    // var line = chart.data.datasets[0];
    // line.pointRadius = barWidth / 2;
    // line.pointHoverRadius = barWidth / 2;
    // if (!this.updated) {
    //    chart.update();
    //    this.updated = true;
    // }
 }
};