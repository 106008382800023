import axios from "axios";
import Cookies from "universal-cookie";
import get from "lodash/get";
import * as types from "../ApiTypes";
import * as actions from "./sharedFunction";
import * as selectionActions from "./selected";
import { API_URL, BASE_URL, COOKIE_PREFIX } from "../env";
import { getCompanyId } from "../helpers";

const cookies = new Cookies();

export const getCustomTags = (queryParams) => async (dispatch) => {
  dispatch(actions.requestData(types.GET_CUSTOM_TAGS));

  const apiUrl = await API_URL();
  const companyId = getCompanyId();
  const cookiePrefix = await COOKIE_PREFIX();
  const accessToken = cookies.get(`${cookiePrefix}_access_token`);
  
  axios.get(`${apiUrl}companies/${companyId}/custom-tags`, await actions.getAxiosConfig(queryParams))
    .then((response) => {
      dispatch(actions.receiveData(response.data, types.GET_CUSTOM_TAGS));
    })
    .catch((error) => {
      if (error.response && error.response.status === 401) {
        actions.sessionTokenExpired(cookiePrefix, accessToken);
      } else {
        dispatch(actions.receiveError(error, types.GET_CUSTOM_TAGS));
      }
    });
};

export const getCustomTag = (customTagId) => async (dispatch) => {
  dispatch(actions.requestData(types.GET_CUSTOM_TAG));

  const apiUrl = await API_URL();
  const companyId = getCompanyId();
  const cookiePrefix = await COOKIE_PREFIX();
  const accessToken = cookies.get(`${cookiePrefix}_access_token`);
  
  axios.get(`${apiUrl}companies/${companyId}/custom-tags/${customTagId}`, await actions.getAxiosConfig())
    .then((response) => {
      dispatch(actions.receiveData(response.data, types.GET_CUSTOM_TAG));
    })
    .catch((error) => {
      if (error.response && error.response.status === 401) {
        actions.sessionTokenExpired(cookiePrefix, accessToken);
      } else {
        dispatch(actions.receiveError(error, types.GET_CUSTOM_TAG));
      }
    });
};

export const createCustomTag = (tag, redirect) => async (dispatch) => {
  dispatch(actions.postData(types.CREATE_CUSTOM_TAG));

  const apiUrl = await API_URL();
  const companyId = getCompanyId();
  const cookiePrefix = await COOKIE_PREFIX();
  const accessToken = cookies.get(`${cookiePrefix}_access_token`);

  // Replace wpaCategories with wpaCategoryIds in body
  let newBody = { ...tag };
  const wpaCategories = newBody.wpaCategories;
  if (wpaCategories) {
    delete newBody.wpaCategories;
    newBody.wpaCategoryIds = wpaCategories.map((wpaCategory) => wpaCategory._id ?? wpaCategory.id);
  }

  // Set default color
  newBody.colorTheme = get(newBody, "colorTheme", "#6c757d");
  
  axios.post(`${apiUrl}companies/${companyId}/custom-tags`, newBody, await actions.getAxiosConfig())
    .then((response) => {
      dispatch(actions.receiveData(response.data, types.CREATE_CUSTOM_TAG));
      dispatch(getCustomTags());
      dispatch(selectionActions.clearSelection());
      if (redirect) {
        redirect(`/companies/${companyId}/locations/root/custom-tags`);
      }
    })
    .catch((error) => {
      if (error.response && error.response.status === 401) {
        actions.sessionTokenExpired(cookiePrefix, accessToken);
      } else {
        dispatch(actions.receiveError(error, types.CREATE_CUSTOM_TAG));
      }
    });
};

export const updateCustomTag = (customTagId, body, redirect) => async (dispatch) => {
  dispatch(actions.postData(types.UPDATE_CUSTOM_TAG));

  const apiUrl = await API_URL();
  const companyId = getCompanyId();
  const cookiePrefix = await COOKIE_PREFIX();
  const accessToken = cookies.get(`${cookiePrefix}_access_token`);

  // Replace wpaCategories with wpaCategoryIds in body
  let newBody = { ...body };
  const wpaCategories = newBody.wpaCategories;
  if (wpaCategories) {
    delete newBody.wpaCategories;
    newBody.wpaCategoryIds = wpaCategories.map((wpaCategory) => wpaCategory._id ?? wpaCategory.id);
  }

  // Set default color
  newBody.colorTheme = get(newBody, "colorTheme", "#6c757d");

  axios.put(`${apiUrl}companies/${companyId}/custom-tags/${customTagId}`, newBody, await actions.getAxiosConfig())
    .then((response) => {
      dispatch(actions.receiveData(response.data, types.UPDATE_CUSTOM_TAG));
      dispatch(getCustomTags());
      dispatch(getCustomTag(customTagId));
      dispatch(selectionActions.clearSelection());
      if (redirect) {
        redirect(`/companies/${companyId}/locations/root/custom-tags`);
      }
    })
    .catch((error) => {
      if (error.response && error.response.status === 401) {
        actions.sessionTokenExpired(cookiePrefix, accessToken);
      } else {
        dispatch(actions.receiveError(error, types.UPDATE_CUSTOM_TAG));
      }
    });
};

export const deleteCustomTag = (customTagId, redirect) => async (dispatch) => {
  dispatch(actions.requestData(types.DELETE_CUSTOM_TAG));

  const apiUrl = await API_URL();
  const companyId = getCompanyId();
  const cookiePrefix = await COOKIE_PREFIX();
  const accessToken = cookies.get(`${cookiePrefix}_access_token`);
  
  axios.delete(`${apiUrl}companies/${companyId}/custom-tags/${customTagId}`, await actions.getAxiosConfig())
    .then((response) => {
      dispatch(actions.receiveData(response.data, types.DELETE_CUSTOM_TAG));
      dispatch(getCustomTags());
      dispatch(selectionActions.clearSelection());
      if (redirect) {
        redirect(`/companies/${companyId}/locations/root/custom-tags`);
      }
    })
    .catch((error) => {
      if (error.response && error.response.status === 401) {
        actions.sessionTokenExpired(cookiePrefix, accessToken);
      } else {
        dispatch(actions.receiveError(error, types.DELETE_CUSTOM_TAG));
      }
    });
};

export const deleteCustomTags = (customTagIds) => async (dispatch) => {
  dispatch(actions.requestData(types.DELETE_CUSTOM_TAG));

  const apiUrl = await API_URL();
  const companyId = getCompanyId();
  const cookiePrefix = await COOKIE_PREFIX();
  const accessToken = cookies.get(`${cookiePrefix}_access_token`);
  const config = await actions.getAxiosConfig();

   // Looping through all custom tag ids
   axios.all(customTagIds.map((customTagId) => (
    axios.delete(`${apiUrl}companies/${companyId}/custom-tags/${customTagId}`, config)
  )))
  .then((response) => {
    dispatch(actions.receiveData(response.data, types.DELETE_CUSTOM_TAG));
    dispatch(getCustomTags());
    dispatch(selectionActions.clearSelection());
  })
  .catch((error) => {
    if (error.response && error.response.status === 401) {
      actions.sessionTokenExpired(cookiePrefix, accessToken);
    } else {
      dispatch(actions.receiveError(error, types.DELETE_CUSTOM_TAG));
    }
  });
};

export const updateCustomTagDraft = (tag) => async (dispatch) => {
  dispatch({ type: types.UPDATE_CUSTOM_TAG_DRAFT, draft: tag });
}

export const clearCustomTagDraft = () => async (dispatch) => {
  dispatch({ type: types.CLEAR_CUSTOM_TAG_DRAFT });
}

export const clearCustomTag = () => async (dispatch) => {
  dispatch({ type: types.CLEAR_CUSTOM_TAG });
}