import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Col, Row } from "react-grid-system";
import { isEmpty } from "lodash";
import Table from "../../components/Table";
import TopRowOptions from "../../components/TopRowOptions";
import SearchBox from "../../components/SearchBox";
import * as selectedActions from "../../actions/selected";
import * as customTagActions from "../../actions/customTags";

class CustomTagLocations extends Component {

  constructor(props) {
    super(props);
    this.state = {
      search: "",
      searchFilter: ""
    }
    this.onAddLocationClicked = this.onAddLocationClicked.bind(this);
    this.onRowClick = this.onRowClick.bind(this);
    this.onSearchClicked = this.onSearchClicked.bind(this);
  }

  componentDidMount() {
    if (!isEmpty(this.props.customTag.title)) {
      document.title = `BLDNG.ai - ${this.props.customTag.title} - locations`;
    }
  }

  onAddLocationClicked() {
    const { push } = this.props.history;
    // this.props.selectContent(this.props.content, push, `/companies/${this.props.match.params.companyId}/locations/root/locations`);
  }

  onRowClick(column, rowInfo) {
    return {
      onClick: e => {
        // console.log(rowInfo);
        if (rowInfo && column.name !== "isSelected") {
          let link = `/companies/${this.props.match.params.companyId}/locations/${rowInfo.original.id}/locations`;

          if (e.metaKey || e.ctrlKey) {
            window.open(`${link}`);
          }
          else {
            this.props.history.push(link);
          }
        }
      },
      style: {
        cursor: "pointer"
      }
    }
  }

  onToggle(row) {
    if (this.props.selectedLocations[row.original.id] !== undefined && this.props.selectedLocations[row.original.id]) {
      this.props.deselectLocation(row.original.id);
    }
    else {
      this.props.selectLocation(row.original);
    }
  }

  onToggleAll(locations, checked) {
    if (checked) {
      this.props.selectLocations(locations);
    }
    else {
      this.props.deselectLocations(locations);
    }
  }

  onSearchClicked() {
    this.setState({ searchFilter: this.state.search });
  }

  render() {
    const { isLoading } = this.props;

    const anotherTypeSelected = this.props.selectedTypes.length > 0 && !this.props.selectedTypes.includes("location");

    // Filter locations based on search
    let locations = this.props.customTag.locations;
    if (this.state.searchFilter !== "") {

      // Search in breadcrumbs
      if (this.props.breadcrumbs) {

        locations = locations.filter(location => {
          const breadcrumbs = this.props.breadcrumbs[location.id];
          if (!isEmpty(breadcrumbs)) {
            // Split search string into words and check if all of them are in breadcrumbs
            const searchWords = this.state.searchFilter.split(" ");
            return searchWords.every(searchWord => {
              return breadcrumbs.some(breadcrumb => breadcrumb.name.toLowerCase().includes(searchWord.toLowerCase()));
            });
          }
          else {
            // If there are no breadcrumbs, search in location name
            const searchWords = this.state.searchFilter.split(" ");
            return searchWords.every(searchWord => {
              return location.name.toLowerCase().includes(searchWord.toLowerCase());
            });
          }
        });

      }
      else {
        locations = locations.filter(location => {
          const searchWords = this.state.searchFilter.split(" ");
          return searchWords.every(searchWord => {
            return location.name.toLowerCase().includes(searchWord.toLowerCase());
          });
        });
      }
    }

    const tableElement = (
      <Table
        data={locations ?? []}
        noDataText={isLoading ? "" : "No locations found"}
        columns={[
          {
            header: ({data, column}) => {

              let checked = isEmpty(locations) ? false : locations.every(location => {
                return this.props.selectedLocations[location.id] !== undefined && this.props.selectedLocations[location.id];
              });
  
              return (
                <label className="checkboxContainer checkboxHeaderContainer" htmlFor={`editCheckbox-${column}-include-header`}>
                  <input
                    id={`editCheckbox-${column}-include-header`}
                    type="checkbox"
                    className="checkbox"
                    checked={checked}
                    onChange={(event) => this.onToggleAll(locations, !checked)}
                  />
                  <span className={"checkmark"} onClick={(event) => this.onToggleAll(locations, !checked)} />
                </label>
              );
            }, 
            accessorKey: "id",
            sortable: false,
            name: "isSelected",
            cell: ({ row }) => (
              <label className="checkboxContainer" htmlFor={`editCheckbox-${row.original.id}`}>
                <input
                  id={`editCheckbox-${row.original.id}`}
                  type="checkbox"
                  className="checkbox"
                  checked={(this.props.selectedLocations[row.original.id] !== undefined && this.props.selectedLocations[row.original.id])}
                  onChange={() => this.onToggle(row)}
                  disabled={anotherTypeSelected}
                />
                <span className={anotherTypeSelected ? "disabledCheckmark" : "checkmark"} />
              </label>
            ),
            width: 60
          },
          {
            header: "Name",
            accessorKey: "name",
            sortable: false,
            cell: ({ row }) => {
              // Show breadcrumb based on hierarchy
              if (this.props.breadcrumbs) {
                let breadcrumbs = this.props.breadcrumbs[row.original.id];
                
                // Remove last breadcrumb (current location)
                breadcrumbs = breadcrumbs && breadcrumbs.length > 0 ? breadcrumbs.slice(0, -1) : null;

                if (!isEmpty(breadcrumbs)) {
                  return <span>{row.original.name}<span style={{ color: "#666", marginLeft: "10px" }}>({breadcrumbs.map(breadcrumb => breadcrumb.name).join(", ")})</span></span>;
                }
              }

              return <span>{row.name}</span>;
            }
          },
          {
            id: "arrow",
            header: "",
            sortable: false,
            className: "pull-right",
            width: 60,
            cell: () => <div className="arrow" />
          }
        ]}
        getTdProps={this.onRowClick}
        className="setMaxHeigth -row-clickable -highlight"
        loading={isLoading}
      />
    );

    return (
      <>
        <Row>
          <Col xs={12} md={12}>
            <TopRowOptions
              searchbox={(
                <SearchBox
                  value={this.state.search}
                  onSearchChanged={(value) => { this.setState({ search: value }) }}
                  onSearchClick={this.onSearchClicked}
                  onClear={() => { this.setState({ search: "", searchFilter: "" }) }}
                  inDetailsView
                />
              )}
            />
            { tableElement }
          </Col>
        </Row>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    customTag: state.customTag.data,
    isLoading: state.customTag.isLoading,
    selectedCompany: state.auth.selectedCompany,
    selectedLocations: state.selected.locations,
    selectedTypes: state.selected.allTypes,
    breadcrumbs: state.locations.breadcrumbs
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    updateCustomTagDraft: customTagActions.updateCustomTagDraft,
    selectCustomTag: selectedActions.selectCustomTag,
    selectLocation: selectedActions.selectLocation,
    selectLocations: selectedActions.selectLocations,
    deselectLocation: selectedActions.deselectLocation,
    deselectLocations: selectedActions.deselectLocations,
   }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomTagLocations);
