import React from "react";
import style from "./style.module.scss"

const LargeButton = ({text, type = "button", hoverText, onClick = () => {}, disabled = false, singleLine = false }) => (
  <div className={style.buttonContainer}>
    <button className={style.button} title={hoverText} type={type} disabled={disabled} onClick={onClick} data-singleline={singleLine}>
        <span>{text}</span>
    </button>
  </div>
);

export default LargeButton;