import React, { Component } from "react";
import { Hidden, Visible } from "react-grid-system";
import { Switch, Route, Redirect, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import moment from "moment";
import { get, isEmpty } from "lodash";
import MapboxMap from "../Map/MapboxMap";
import Loader from "../../components/Loader";
import GatewayDetails from "./gatewayDetails";
import NotFound from "../NotFound";
import Modal from "../../components/Modal";
import SubNavigation from "../../components/SubNavigation";
import LargeHeader from "../../components/LargeHeader";
import Sensors from "./sensors";
import GatewayUptime from "./gatewayUptime";
import NoAccess from "../NoAccess";
import * as selectedActions from "../../actions/selected";
import * as gatewayActions from "../../actions/gateways";
import * as locationActions from "../../actions/locations";
import * as API from "../../ApiTypes";
import style from "./style.module.scss";

class GatewayContainer extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
      showDeleteModal: false,
      deleteAtomicSensorId: null,
      deleteStartDate: null,
      deleteEndDate: null,
      highlightedId: null,
    };

    this.updateData = this.updateData.bind(this);
    this.onEditNameSave = this.onEditNameSave.bind(this);
    this.getUpdatedString = this.getUpdatedString.bind(this);
    this.setHighlightedFeature = this.setHighlightedFeature.bind(this);

    document.title = `BLDNG.ai - Gateway`;
  }

  componentDidMount() {
    // console.log("GatewayContainer componentDidMount", this.props, this.state);
    this.updateData();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    // console.log("GatewayContainer componentDidUpdate", this.props, prevProps, this.state, prevState);
    this.updateData();
  }

  updateData() {
    // console.log("GatewayContainer updateData");
     // Only load data if sensor change
     if (this.props.gateway.id !== this.props.match.params.id) {
      this.props.getGateway(this.props.match.params.id);
    }
    else {
      const locationId = get(this.props.gateway, "properties.locationIds[0]", null);
      if (!isEmpty(this.props.locationHierarchy)) {

        if (locationId) {
          const breadcrumbs = this.props.breadcrumbs[locationId] ?? [];

          if (locationId !== this.props.currentLocation.id && !this.props.isLoadingLocation) {
            this.props.getLocation(locationId);
          }

          // If breadcrumbs include a floor, load the floor map
          const floor = breadcrumbs.find(breadcrumb => breadcrumb.type === "floor");
          if (floor && floor.id !== this.props.currentLocation.floorMapId && !this.props.isLoadingMap) {
            this.props.getFloorMap(floor.id);
          }
        }
        else {
          this.props.clearLocation();
        }

      }
    }
  }

  onEditNameSave(name) {

    if (isEmpty(name)) {
      return;
    }

    this.props.updateGateway(this.props.gateway.id, { name }, this.props.currentLocation.id);
  }

  getUpdatedString() {
    const updatedAt = get(this.props.gateway, `previousMessageAt`, null);
    if (!updatedAt) {
      return `?`;
    }

    return `${moment(updatedAt).format("HH:mm:ss - DD/MM/YY")}`;
  }

  setHighlightedFeature(id) {
    if (this.state.highlightedId !== id) {
      this.setState({ highlightedId: id });
    }
  }

  render() {
    // console.log("GatewayContainer render", this.props);
    const canViewGateway = this.props.auth.hasAdminRole;
    const canUpdateGateway = this.props.auth.hasInstallerRole;

    if (!canViewGateway) {
      return <NoAccess />;
    }

    if (this.props.isLoading) {
      return <Loader fullScreen />;
    }

    if (!get(this.props.gateway, "id", false)) {
      return <Loader fullScreen />;
    }

    const subNavigationLinks = [];
    subNavigationLinks.push({ pathname: `/companies/${this.props.match.params.companyId}/gateways/${this.props.match.params.id}/details`, label: `Details` });
    subNavigationLinks.push({ pathname: `/companies/${this.props.match.params.companyId}/gateways/${this.props.match.params.id}/sensors`, label: `Sensors` });
    subNavigationLinks.push({ pathname: `/companies/${this.props.match.params.companyId}/gateways/${this.props.match.params.id}/uptime`, label: `Uptime` });

    const routeSwitch = (
      <Switch>
        <Redirect exact from="/companies/:companyId/gateways/:id" to={{ pathname: `/companies/:companyId/gateways/${this.props.match.params.id}/details` }} />
        <Route path="/companies/:companyId/gateways/:id/details" children={(props) => <GatewayDetails {...props} id={props.match.params.id} />} />
        <Route path="/companies/:companyId/gateways/:id/sensors/:sensorId?" children={(props) => <Sensors {...props} id={props.match.params.id} onDeleteDataClicked={this.onDeleteDataClicked} setHighlightedFeature={this.setHighlightedFeature} />} />
        <Route path="/companies/:companyId/gateways/:id/uptime" children={(props) => <GatewayUptime {...props} id={props.match.params.id} />} />
        <Route>
          <NotFound />
        </Route>
      </Switch>
    );
    
    const path = window.location.pathname;
    const inUptimeView = path.includes("/uptime");
    const showMap = !inUptimeView;

    const mapElement = getMapElement(this.props, this.state);
    
    const contentView = (
      <>
        <Hidden xs sm md>
          <div className={style.listContainer}>
            <div className={style.row}>
              <div className={showMap ? style.colList : style.col}>
                {routeSwitch}
              </div>
              <div className={style.colMap} style={showMap ? {} : { display: "none" }}>
                {mapElement}
              </div>
            </div>
          </div>
        </Hidden>

        <Visible xs sm md>
          <div className={style.listContainerMobile}>
            <div className={style.row}>
              <div className={style.col}>
                {routeSwitch}
              </div>
            </div>
          </div>
        </Visible>
      </>
    );

    return (
      <div className={style.container}>
        <LargeHeader
          title={this.props.gateway.name}
          rightText={`last sample ${this.getUpdatedString()}`}
          canUpdateName={true}
          onNameSaveClicked={this.onEditNameSave}
        />
        <SubNavigation links={subNavigationLinks} /> 
        
        { contentView }

        <Modal
          show={this.state.showDeleteModal}
          onHide={() => this.setState({ showDeleteModal: false })}
          title="Permanently delete data"
          text={`Are you sure you want to delete the data between ${this.state.deleteStartDate && this.state.deleteStartDate.format("DD/MM/YYYY HH:mm:ss")} and ${this.state.deleteEndDate && this.state.deleteEndDate.format("DD/MM/YYYY HH:mm:ss")}?`}
          primaryBtn={{
            text: "Delete",
            onClick: this.onDeleteDataConfirmed,
            color: "red"
          }}
          secondaryBtn={{
            text: "Close",
            onClick: () => this.setState({ showDeleteModal: false })
          }}
        />

      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    gateway: state.gateway,
    currentLocation: state.location,
    auth: state.auth,
    selectedCompany: state.auth.selectedCompany,
    locationHierarchy: state.locations.hierarchy,
    breadcrumbs: state.locations.breadcrumbs,
    isLoading: state.loading.gateway,
    isLoadingLocation: state.loading[API.GET_LOCATION],
    isLoadingMap: state.loading[API.GET_FLOOR_MAP],
    isLoadingCompanyMap: state.loading[API.GET_COMPANY_MAP],
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    getGateway: gatewayActions.getGateway,
    updateGateway: gatewayActions.updateGateway,
    selectGateway: selectedActions.selectGateway,
    getLocation: locationActions.getLocation,
    clearLocation: locationActions.clearLocation,
    getFloorMap: locationActions.getFloorMap,
   }, dispatch);
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(GatewayContainer));

function getMapElement(props, state) {

  const path = window.location.pathname;
  const inUptimeView = path.includes("/uptime");

  const showMap = !inUptimeView;

  let mapElement;
  // If gateway has a geoJSONFeature - show movable point
  // else show map with draw_point to let user place the gateway in the map
  if (props.currentLocation.floorMap) {
    const geoJsonFeature = get(props.gateway, `properties.geoJsonFeature`, null);
    if (!isEmpty(geoJsonFeature)) {
      mapElement = (
        <div className={style.mapPart}>
          <MapboxMap 
            mapId="edit-gateway-map-container"
            id={state.id}
            company={props.selectedCompany}
            location={props.currentLocation}
            map={props.currentLocation.floorMap}
            drawMode="simple_select"
            geoJsonFeature={geoJsonFeature}
            highlightedId={state.highlightedId} 
            showSensors
            showGateways
            showMap={showMap}
          />
          <div className={style.mapHelp}>
            <span>Sensors are </span>
            <span style={{ color: "#0080FF" }}>blue</span>
            <span>. Gateways are </span>
            <span style={{ color: "#82612F" }}>brown</span>
            <span>.</span>
          </div>
        </div>
      );
    }
    else {
      mapElement = (
        <div className={style.mapPart}>
          <MapboxMap 
            mapId="edit-gateway-map-container"
            id={state.id}
            company={props.selectedCompany}
            location={props.currentLocation}
            map={props.currentLocation.floorMap}
            highlightedId={state.highlightedId} 
            drawMode="draw_point"
            showSensors
            showGateways
            showMap={showMap}
          />
          <div className={style.mapHelp}>
            <span>Sensors are </span>
            <span style={{ color: "#0080FF" }}>blue</span>
            <span>. Gateways are </span>
            <span style={{ color: "#82612F" }}>brown</span>
            <span>.</span>
          </div>
        </div>
      );
    }
  }
  else {
    // If no floor map is available, show a message
    mapElement = (
      <div className={style.mapPart}>
        <div className={style.mapHelpCenter}>
          No floor map available
        </div>
      </div>
    );
  }

  return mapElement;
}