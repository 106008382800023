import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Switch, Route, Redirect } from "react-router-dom";
import isEmpty from "lodash/isEmpty";
import NotFound from "../NotFound";
import DetailsHeader from "../../components/DetailsHeader";
import SubNavigation from "../../components/SubNavigation";
import UserInfo from "./userDetailsInfo";
import UserGroups from "./userDetailsGroups";
import * as usersActions from "../../actions/users";

class UserDetails extends Component {

  constructor(props) {
    super(props);
    // console.log("UserDetails.constructor",this.props);
    this.onDelete = this.onDelete.bind(this);

    if (isEmpty(this.props.userId)) {
      this.props.clearUser();
    }
    else if (this.props.user.id !== this.props.userId) {
      // console.log("UserDetails.getUser");
      this.props.getUser(this.props.userId);
    }
  }

  componentDidUpdate(prevProps) {
    // console.log("UserDetails.componentDidUpdate",this.props);
    // Only load data if id change
    if (isEmpty(this.props.userId)) {
      this.props.clearUser();
    }
    else if (prevProps.userId !== this.props.userId) {
      this.props.getUser(this.props.userId);
    }
  }

  onDelete() {
    let push = this.props.history.push;
    this.props.deleteUser(this.props.user.id, push);
  }

  render() {
    // console.log("UserDetails.render()", this.props)
    const { isLoading } = this.props;

    if (isEmpty(this.props.userId) || !this.props.user.id) {
      return null;
    }

    const subNavigationLinks = [];
    if (this.props.match.path.includes("/org/support")) {
      subNavigationLinks.push({ pathname: `/companies/${this.props.match.params.companyId}/org/support/${this.props.userId}/info`, label: `Info` });
      subNavigationLinks.push({ pathname: `/companies/${this.props.match.params.companyId}/org/support/${this.props.userId}/groups`, label: `Groups` });
    }
    else if (this.props.match.path.includes("/org/admins")) {
      subNavigationLinks.push({ pathname: `/companies/${this.props.match.params.companyId}/org/admins/${this.props.userId}/info`, label: `Info` });
      subNavigationLinks.push({ pathname: `/companies/${this.props.match.params.companyId}/org/admins/${this.props.userId}/groups`, label: `Groups` });
    }
    else {
      subNavigationLinks.push({ pathname: `/companies/${this.props.match.params.companyId}/org/users/${this.props.userId}/info`, label: `Info` });
      subNavigationLinks.push({ pathname: `/companies/${this.props.match.params.companyId}/org/users/${this.props.userId}/groups`, label: `Groups` });
    }
   
    let title = "Unknown";
    title = this.props.user.name ?? "Unknown";

    return (
      <div>
        <DetailsHeader
          title={title}
          isLoading={isLoading}
          canDelete={!this.props.user.managedByScim}
          onDeleteClicked={!this.props.user.managedByScim && this.onDelete}
        />
        <SubNavigation links={subNavigationLinks} slim />
        <Switch>
          <Redirect exact from={`/companies/:companyId/org/users/${this.props.userId}`} to={{ pathname: `/companies/${this.props.match.params.companyId}/org/users/${this.props.userId}/info` }} />
          <Redirect exact from={`/companies/:companyId/org/admins/${this.props.userId}`} to={{ pathname: `/companies/${this.props.match.params.companyId}/org/admins/${this.props.userId}/info` }} />
          <Redirect exact from={`/companies/:companyId/org/support/${this.props.userId}`} to={{ pathname: `/companies/${this.props.match.params.companyId}/org/support/${this.props.userId}/info` }} />
          <Route path="/companies/:companyId/org/users/:userId/info" children={(props) => <UserInfo {...props} userId={this.props.userId} />} />
          <Route path="/companies/:companyId/org/users/:userId/groups" children={(props) => <UserGroups {...props} userId={this.props.userId} />} />
          <Route path="/companies/:companyId/org/admins/:userId/info" children={(props) => <UserInfo {...props} userId={this.props.userId} />} />
          <Route path="/companies/:companyId/org/admins/:userId/groups" children={(props) => <UserGroups {...props} userId={this.props.userId} />} />
          <Route path="/companies/:companyId/org/support/:userId/info" children={(props) => <UserInfo {...props} userId={this.props.userId} />} />
          <Route path="/companies/:companyId/org/support/:userId/groups" children={(props) => <UserGroups {...props} userId={this.props.userId} />} />
          <Route component={NotFound} />
        </Switch>
        <div style={{ paddingTop: "40px" }} />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.user,
    selectedCompany: state.auth.selectedCompany
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    getUser: usersActions.getUser,
    deleteUser: usersActions.deleteUser,
    clearUser: usersActions.clearUser
   }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(UserDetails);