import React, { Component } from "react";
import Table from "../../../components/Table";
import SmallButton from "../../../components/SmallButton";
import styled from "styled-components";
import { getBreadcrumbLocations, getLocation, getParentLocation } from "../../../locationHelpers";

class LocationSelector extends Component {

  constructor(props) {
    super(props);
    this.state = {
      inspectedLocationId: null,
      selectedLocationId: this.props.selectedLocation ? this.props.selectedLocation.id : null,
      hasChanges: false
    };
    this.onRowClick = this.onRowClick.bind(this);
    this.onBreadCrumbClick = this.onBreadCrumbClick.bind(this);
    this.onLocationClick = this.onLocationClick.bind(this);
    this.onApply = this.onApply.bind(this);

    // Set the inspected location to the selected location parent
    if (this.props.selectedLocation) {
      const inspectedLocation = getParentLocation(this.props.topLocation, this.props.selectedLocation.id);
      this.state.inspectedLocationId = inspectedLocation.id || "top";
    }
  }

  onRowClick(column, rowInfo) {
    return {
      onClick: e => {
        if (rowInfo && column.name !== "isSelected" && rowInfo.original.children && rowInfo.original.children.length > 0) {
          this.setState({ inspectedLocationId: rowInfo.original.id });
        }
      },
      style: {
        cursor: "pointer",
        position: "relative"
      }
    }
  }

  onBreadCrumbClick(id) {
    // console.log("onBreadCrumbClick", id, this.state);
    this.setState({ inspectedLocationId: id })
  }

  onLocationClick(id) {
    const isEqualToLocalId = this.state.selectedLocationId === id;
    
    let hasChanges = true;
    let newId = !isEqualToLocalId ? id : null;
    if (this.props.selectedLocation) {
      if (isEqualToLocalId) {
        hasChanges = this.props.selectedLocation.id !== null;
      }
      else {
        hasChanges = this.props.selectedLocation.id !== id;
      }
    }
    else {
      hasChanges = !isEqualToLocalId;
    }
    
    this.setState({ selectedLocationId: newId, hasChanges });
  }

  onApply() {
    this.props.onLocationChange(this.state.selectedLocationId);
    this.props.onClose();
  }

  render() {
    const { topLocation, onClose, isLoading, companyName } = this.props;

    // console.log("LocationFilter.render()", this.props);

    let locations = [];
    let breadcrumbLocations = [];
    let inspectedLocation = null;

    if (this.state.inspectedLocationId === "top") {
      inspectedLocation = topLocation;
      breadcrumbLocations = [];
      locations = topLocation.children || [];
    }
    else if (this.state.inspectedLocationId) {
      inspectedLocation = getLocation(topLocation, this.state.inspectedLocationId);
      breadcrumbLocations = getBreadcrumbLocations(topLocation, this.state.inspectedLocationId) || [];
      locations = inspectedLocation.children || [];
    }
    else {
      inspectedLocation = topLocation;
      breadcrumbLocations = [];
      locations = topLocation.children || [];
    }
    
    return (
      <Container onClick={onClose}>
        <ScrollBox>
          <Block onClick={(e) => e.stopPropagation()}>
            <BlockContentContainer>
              <BlockContent>
                <BlockInnerTitle>Select location</BlockInnerTitle>
                <LeftBar>
                  { breadcrumbLocations.map((breadcrumb, index) => {
                    return (
                      <Breadcrumb key={index} $isLast={index === breadcrumbLocations.length - 1} onClick={() => this.onBreadCrumbClick(breadcrumb.id)}>
                        {breadcrumb.name}
                      </Breadcrumb>
                    );
                  })}
                  <LocationTitle>{ inspectedLocation ? inspectedLocation.name : companyName }</LocationTitle>
                </LeftBar>
                
                <SmallTable
                  style={{ paddingTop: 0 }}
                  data={locations}
                  sortBy={this.state.sortBy}
                  sortOrder={this.state.sortOrder}
                  noDataText={isLoading ? "" : "No locations found"}
                  columns={[
                    {
                      id: "id",
                      header: "",
                      accessorKey: "id",
                      sortable: false,
                      name: "isSelected",
                      cell: ({ row }) => (
                        <label className="checkboxContainer" htmlFor={`editCheckbox-${row.original.id}`}>
                          <input
                            id={`editCheckbox-${row.original.id}`}
                            type="checkbox"
                            className="checkbox"
                            checked={(this.state.selectedLocationId === row.original.id)}
                            onChange={() => this.onLocationClick(row.original.id)}
                          />
                          <span className={"checkmark"} />
                        </label>
                      ),
                      width: 60
                    },
                    {
                      id: "name",
                      header: "",
                      accessorKey: "name",
                      minWidth: 100,
                      cell: ({ row }) => (<span title={row.original.name}>{row.original.name}</span>)
                    },
                    {
                      id: "arrow",
                      header: "",
                      sortable: false,
                      className: "pull-right",
                      width: 60,
                      cell: ({ row }) => { return row.original.children.length > 0 ? <div className="arrow" /> : null }
                    }
                  ]}
                  hideHeaders
                  getTdProps={this.onRowClick}
                  className="-row-clickable setMaxHeigth -highlight noHeader"
                  loading={isLoading}
                />
                <BottomBar>
                  <BlockInnerDescription>{`Select a location and click "apply" to use that location.`}</BlockInnerDescription>
                </BottomBar>
              </BlockContent>
            </BlockContentContainer>
            <ToolBar>
              <SmallButton text="Close" color="white" onClick={onClose} style={{ marginRight: this.state.hasChanges ? "20px" : "0" }} noLeftMargin />
              { this.state.hasChanges && <SmallButton text="Apply" noLeftMargin onClick={this.onApply} /> }
            </ToolBar>
          </Block>
        </ScrollBox>
      </Container> 
    );
  }
}

export default LocationSelector;

const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  overflow: hidden; /* Add this to prevent content overflow */
`;

const ScrollBox = styled.div`
  position: relative;
  overflow: auto;
  max-height: 100%;
  height: 100%;
  padding: 40px;
  box-sizing: border-box;
`;

const Block = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 100%;
  background-color: rgb(255, 255, 255);
  border-radius: 6px;
  border-width: 1px;
  border-style: solid;
  border-color: rgb(221, 221, 221);
  font-size: 16px;
  font-weight: 400;
  min-height: 100%; /* Ensure the Block takes full height */
  box-sizing: border-box;
`;

const BlockContentContainer = styled.div`
  position: relative;
  display: flex;
  flex-grow: 1;
  width: 100%;
`; 

const BlockContent = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  font-size: 16px;
  font-weight: 400;
  padding: 10px 20px;
`;

const ToolBar = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  box-sizing: border-box;
  padding: 10px 20px;
  border-top: 1px solid #ddd;
  bottom: 0;
  background-color: white;
  z-index: 2;
  width: 100%;
`;

const SmallTable = styled(Table)`
  margin-top: 20px;
  border-top: 1px solid #ddd;
`;

const Breadcrumb = styled.div`
  display: inline-block;
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
  color: #333;
  margin-right: 10px;
  color: #1C4D82;

  &:after {
    content: ">";
    margin-left: 5px;
    color: #1C4D82;
  }
`;

const LocationTitle = styled.div`
  display: inline-block;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  color: #333;
  margin-right: 10px;
`;

const LeftBar = styled.div`
  display: inline-block;
  width: 100%;
  vertical-align: top;
  margin-top: 20px;
`;

const BottomBar = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 25px;
  margin-bottom: 25px;
`;

const BlockInnerTitle = styled.div`
  font-size: 20px;
  font-weight: 600;
  color: #222222;
  margin-top: 8px;
  margin-bottom: 8px;
`;

const BlockInnerDescription = styled.div`
  font-size: 16px;
  font-weight: 400;
  color: #666;
  margin-bottom: 5px;
`;