import axios from "axios";
import Cookies from "universal-cookie";
import * as types from "../ApiTypes";
import * as actions from "./sharedFunction";
import * as selectionActions from "./selected";
import { API_URL, COOKIE_PREFIX } from "../env";
import { getCompanyId } from "../helpers";

const cookies = new Cookies();

export const getUsers = (queryParams) => async (dispatch) => {
  dispatch(actions.requestData(types.GET_USERS));
  
  const apiUrl = await API_URL();
  const companyId = getCompanyId();
  const cookiePrefix = await COOKIE_PREFIX();
  const accessToken = cookies.get(`${cookiePrefix}_access_token`);
  
  axios.get(`${apiUrl}companies/${companyId}/users`, await actions.getAxiosConfig(queryParams))
    .then((response) => (
      dispatch(actions.receiveData(response.data, types.GET_USERS))
    ))
    .catch((error) => {
      if (error.response && error.response.status === 401) {
        actions.sessionTokenExpired(cookiePrefix, accessToken);
      }
      else {
        dispatch(actions.receiveError(error, types.GET_USERS));
      }
    });
};

export const getUser = (id) => async (dispatch) => {
  dispatch(actions.requestData(types.GET_USER));
  
  const apiUrl = await API_URL();
  const companyId = getCompanyId();
  const cookiePrefix = await COOKIE_PREFIX();
  const accessToken = cookies.get(`${cookiePrefix}_access_token`);
  
  axios.get(`${apiUrl}companies/${companyId}/users/${id}`, await actions.getAxiosConfig())
    .then((response) => (
      dispatch(actions.receiveData(response.data, types.GET_USER))
    ))
    .catch((error) => {
      if (error.response && error.response.status === 401) {
        actions.sessionTokenExpired(cookiePrefix, accessToken);
      }
      else {
        dispatch(actions.receiveError(error, types.GET_USER));
      }
    });
};

export const inviteUsers = (emails, role, validUntil) => async (dispatch) => {
  dispatch(actions.requestData(types.INVITE_USERS));

  const apiUrl = await API_URL();
  const cookiePrefix = await COOKIE_PREFIX();
  const accessToken = cookies.get(`${cookiePrefix}_access_token`);
  const companyId = getCompanyId();

  const body = {
    emails,
    role,
    validUntil
  };

  axios.post(`${apiUrl}companies/${companyId}/invites`, body, await actions.getAxiosConfig())
    .then((response) => {
      dispatch(actions.receiveData(response.data, types.INVITE_USERS));
      dispatch(getUsers());
    })
    .catch((error) => {
      if (error.response && error.response.status === 401) {
        actions.sessionTokenExpired(cookiePrefix, accessToken);
      }
      else {
        dispatch(actions.receiveError(error, types.INVITE_USERS));
      }
    });
};

export const deleteUser = (userId, redirect) => async (dispatch) => {
  dispatch(actions.requestData(types.DELETE_USER));
  
  const apiUrl = await API_URL();
  const companyId = getCompanyId();
  const cookiePrefix = await COOKIE_PREFIX();
  const accessToken = cookies.get(`${cookiePrefix}_access_token`);

  axios.delete(`${apiUrl}companies/${companyId}/users/${userId}`, await actions.getAxiosConfig())
    .then((response) => {
      dispatch(actions.receiveData(null, types.DELETE_USER));
      dispatch({
        type: types.CLEAR_SELECTION
      });
      dispatch(getUsers());
      if (redirect) {
        redirect(`/companies/${companyId}/org/users`);
      }
    })
    .catch((error) => {
      if (error.response && error.response.status === 401) {
        actions.sessionTokenExpired(cookiePrefix, accessToken);
      } else {
        dispatch(actions.receiveError(error, types.DELETE_USER));
      }
    });
};

export const clearUser = () => async (dispatch) => {
  dispatch({ type: types.CLEAR_USER })
};

export const getInvites = (queryParams) => async (dispatch) => {
  dispatch(actions.requestData(types.GET_INVITES));
  
  const apiUrl = await API_URL();
  const companyId = getCompanyId();
  const cookiePrefix = await COOKIE_PREFIX();
  const accessToken = cookies.get(`${cookiePrefix}_access_token`);
  
  axios.get(`${apiUrl}companies/${companyId}/invites`, await actions.getAxiosConfig(queryParams))
    .then((response) => (
      dispatch(actions.receiveData(response.data, types.GET_INVITES))
    ))
    .catch((error) => {
      if (error.response && error.response.status === 401) {
        actions.sessionTokenExpired(cookiePrefix, accessToken);
      }
      else {
        dispatch(actions.receiveError(error, types.GET_INVITES));
      }
    });
};

export const getInvite = (id) => async (dispatch) => {
  dispatch(actions.requestData(types.GET_INVITE));
  
  const apiUrl = await API_URL();
  const companyId = getCompanyId();
  const cookiePrefix = await COOKIE_PREFIX();
  const accessToken = cookies.get(`${cookiePrefix}_access_token`);
  
  axios.get(`${apiUrl}companies/${companyId}/invites/${id}`, await actions.getAxiosConfig())
    .then((response) => (
      dispatch(actions.receiveData(response.data, types.GET_INVITE))
    ))
    .catch((error) => {
      if (error.response && error.response.status === 401) {
        actions.sessionTokenExpired(cookiePrefix, accessToken);
      }
      else {
        dispatch(actions.receiveError(error, types.GET_INVITE));
      }
    });
};

export const deleteInvite = (inviteId) => async (dispatch) => {
  dispatch(actions.requestData(types.DELETE_INVITE));
  
  const apiUrl = await API_URL();
  const companyId = getCompanyId();
  const cookiePrefix = await COOKIE_PREFIX();
  const accessToken = cookies.get(`${cookiePrefix}_access_token`);

  axios.delete(`${apiUrl}companies/${companyId}/invites/${inviteId}`, await actions.getAxiosConfig())
    .then((response) => {
      dispatch(actions.receiveData(null, types.DELETE_INVITE));
      dispatch({
        type: types.CLEAR_SELECTION
      });
      dispatch(getInvites());
    })
    .catch((error) => {
      if (error.response && error.response.status === 401) {
        actions.sessionTokenExpired(cookiePrefix, accessToken);
      } else {
        dispatch(actions.receiveError(error, types.DELETE_INVITE));
      }
    });
};

export const clearInvite = () => async (dispatch) => {
  dispatch({ type: types.CLEAR_INVITE })
};

export const getSupportUsers = (queryParams) => async (dispatch) => {
  dispatch(actions.requestData(types.GET_SUPPORT_USERS));
  
  const apiUrl = await API_URL();
  const companyId = getCompanyId();
  const cookiePrefix = await COOKIE_PREFIX();
  const accessToken = cookies.get(`${cookiePrefix}_access_token`);
  
  axios.get(`${apiUrl}companies/${companyId}/support-users`, await actions.getAxiosConfig(queryParams))
    .then((response) => (
      dispatch(actions.receiveData(response.data, types.GET_SUPPORT_USERS))
    ))
    .catch((error) => {
      if (error.response && error.response.status === 401) {
        actions.sessionTokenExpired(cookiePrefix, accessToken);
      }
      else {
        dispatch(actions.receiveError(error, types.GET_SUPPORT_USERS));
      }
    });
};

export const getAdmins = (queryParams) => async (dispatch) => {
  dispatch(actions.requestData(types.GET_ADMINS));
  
  const apiUrl = await API_URL();
  const companyId = getCompanyId();
  const cookiePrefix = await COOKIE_PREFIX();
  const accessToken = cookies.get(`${cookiePrefix}_access_token`);
  
  axios.get(`${apiUrl}companies/${companyId}/administrators`, await actions.getAxiosConfig(queryParams))
    .then((response) => (
      dispatch(actions.receiveData(response.data, types.GET_ADMINS))
    ))
    .catch((error) => {
      if (error.response && error.response.status === 401) {
        actions.sessionTokenExpired(cookiePrefix, accessToken);
      }
      else {
        dispatch(actions.receiveError(error, types.GET_ADMINS));
      }
    });
};

export const grantRoleToUsers = (userIds, role, inUsersView, inAdminsView, inSupportView) => async (dispatch) => {
  dispatch(actions.requestData(types.GRANT_ROLE));
  
  const apiUrl = await API_URL();
  const companyId = getCompanyId();
  const cookiePrefix = await COOKIE_PREFIX();
  const accessToken = cookies.get(`${cookiePrefix}_access_token`);

  const body = {
    userIds,
    role
  };
  
  axios.post(`${apiUrl}companies/${companyId}/grant-role`, body, await actions.getAxiosConfig())
    .then((response) => {
      dispatch(actions.receiveData(response.data, types.GRANT_ROLE))
      if (inUsersView) {
        dispatch(getUsers())
      }
      if (inAdminsView) {
        dispatch(getAdmins())
      }
      if (inSupportView) {
        dispatch(getSupportUsers())
      }

      if (userIds.length === 1) {
        dispatch(getUser(userIds[0]))
      }
    })
    .catch((error) => {
      if (error.response && error.response.status === 401) {
        actions.sessionTokenExpired(cookiePrefix, accessToken);
      }
      else {
        dispatch(actions.receiveError(error, types.GRANT_ROLE));
      }
    });
};

export const draftUserChange = (draft) => async (dispatch) => {
  dispatch({ type: types.DRAFT_USER_CHANGE, payload: draft })
};

export const addUserGroupsToUser = (userId, userGroupIds) => async (dispatch) => {
  dispatch(actions.requestData(types.ADD_USER_USER_GROUPS));

  const apiUrl = await API_URL();
  const companyId = getCompanyId();
  const cookiePrefix = await COOKIE_PREFIX();
  const accessToken = cookies.get(`${cookiePrefix}_access_token`);
  const config = await actions.getAxiosConfig();

  const body = { userIds: [userId] };

  axios.all(userGroupIds.map((userGroupId) => {
    return axios.post(`${apiUrl}companies/${companyId}/usergroups/${userGroupId}/users`, body, config)
  }))
  .then((response) => {
    dispatch(actions.receiveData(response.data, types.ADD_USER_USER_GROUPS));
    dispatch(selectionActions.clearSelection());
    dispatch(getUser(userId));
  })
  .catch((error) => {
    if (error.response && error.response.status === 401) {
      actions.sessionTokenExpired(cookiePrefix, accessToken)
    } else {
      dispatch(actions.receiveError(error, types.ADD_USER_USER_GROUPS))
    }
  });
};

export const removeUserGroupsFromUser = (userId, userGroupIds) => async (dispatch) => {
  dispatch(actions.requestData(types.DELETE_USER_USER_GROUPS));

  const apiUrl = await API_URL();
  const companyId = getCompanyId();
  const cookiePrefix = await COOKIE_PREFIX();
  const accessToken = cookies.get(`${cookiePrefix}_access_token`);

  var body = await actions.getAxiosConfig();
  body.data = { userIds: [userId] };

  axios.all(userGroupIds.map((userGroupId) => {
      return axios.delete(`${apiUrl}companies/${companyId}/usergroups/${userGroupId}/users`, body)
    }))
    .then((response) => {
      dispatch(actions.receiveData(response.data, types.DELETE_USER_USER_GROUPS));
      dispatch(selectionActions.clearSelection());
      dispatch(getUser(userId));
    })
    .catch((error) => {
      if (error.response && error.response.status === 401) {
        actions.sessionTokenExpired(cookiePrefix, accessToken)
      } else {
        dispatch(actions.receiveError(error, types.DELETE_USER_USER_GROUPS))
      }
    });
};