import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Switch, Route, Redirect } from "react-router-dom";
import isEmpty from "lodash/isEmpty";
import get from "lodash/get";
import { ScreenHelpers } from "ui";
import Loader from "../../components/Loader";
import NotFound from "../NotFound";
import SubNavigation from "../../components/SubNavigation";
import LargeHeader from "../../components/LargeHeader";
import OptionFooter from "../../components/OptionFooter";
import ScreenGroupDevices from "./screenGroupDevices";
import ScreenGroupContainer from "./screenGroupContainer";
import ScreenViewContainer from "./screenViewContainer";
import ScreenGroupViews from "./screenGroupViews";
import ScreenGroupLinks from "./screenGroupLinks";
import * as screenActions from "../../actions/screens";
import * as screenViewActions from "../../actions/screenViews";
import style from "./style.module.scss";

class ScreenGroup extends Component {

  constructor(props) {
    super(props);

    this.onSaveScreen = this.onSaveScreen.bind(this);
    this.onSaveScreenView = this.onSaveScreenView.bind(this);
    this.onCancelScreen = this.onCancelScreen.bind(this);
    this.onCancelScreenView = this.onCancelScreenView.bind(this);
    this.screenHasChanged = this.screenHasChanged.bind(this);
    this.screenViewHasChanged = this.screenViewHasChanged.bind(this);
    this.screenIsValid = this.screenIsValid.bind(this);
    this.screenViewIsValid = this.screenViewIsValid.bind(this);

    if (isEmpty(this.props.match.params.screenGroupId)) {
      this.props.clearScreenData();
    }
    else if (this.props.screen.id !== this.props.match.params.screenGroupId) {
      this.props.getScreenGroup(this.props.match.params.screenGroupId);
      this.props.getScreenViews(this.props.match.params.screenGroupId);
      this.props.getScreenDevices(this.props.match.params.screenGroupId);
      this.props.getScreenLinks(this.props.match.params.screenGroupId);
    }
  }

  componentDidUpdate(prevProps) {
    // console.log("ScreenGroup.componentDidUpdate", this.props);
    // Only load data if id change
    if (isEmpty(this.props.match.params.screenGroupId)) {
      this.props.clearScreenData();
    }
    else if (this.props.screen.id !== this.props.match.params.screenGroupId) {
      this.props.getScreenGroup(this.props.match.params.screenGroupId);
      this.props.getScreenViews(this.props.match.params.screenGroupId);
      this.props.getScreenDevices(this.props.match.params.screenGroupId);
      this.props.getScreenLinks(this.props.match.params.screenGroupId);
    }
  }

  componentDidMount() {
    document.addEventListener("keydown", this.handleKeyDown);
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.handleKeyDown);
  }

  handleKeyDown = (event) => {
    if ((event.metaKey || event.ctrlKey) && event.key === 's') {
      event.preventDefault(); // Prevent the default save dialog

      if (this.screenHasChanged()) {
        if (this.screenIsValid()) {
          this.onSaveScreen();
        }
      }
      else if (this.screenViewHasChanged()) {
        if (this.screenViewIsValid()) {
          this.onSaveScreenView();
        }
      }
    }
  }
  
  onSaveScreen() {
    const { push } = this.props.history;
    
    // Copy form to make changes for upload
    const form = JSON.parse(JSON.stringify(this.props.screenForm));
    delete form.id;

    this.props.updateScreen(this.props.screen.id, form, push);
  }

  onSaveScreenView() {

    // Copy form to make changes for upload
    const form = JSON.parse(JSON.stringify(this.props.screenViewForm));
    delete form.id;

    // Clean up the map components in the layout
    const layout = get(form, "layout", {});
    const mapComponentPaths = ScreenHelpers.getMapViewComponentPaths(layout);
    mapComponentPaths.forEach((path) => {

      // Clean up the map component
      const mapComponent = get(layout, path);
      if (mapComponent) {
        if (mapComponent.type === "map") {

          if (get(mapComponent, "youAreHere", null)) {
            const lat = Number(get(mapComponent, "youAreHere.lat", null));
            const lng = Number(get(mapComponent, "youAreHere.lng", null));
            const color = get(mapComponent, "youAreHere.color", null);
            if (lat && lng) {
              mapComponent.youAreHere = {};
              mapComponent.youAreHere.lat = lat;
              mapComponent.youAreHere.lng = lng;
              if (!isEmpty(color)) {
                mapComponent.youAreHere.color = color;
              }
              else {
                delete mapComponent.youAreHere.color;
              }
            }
            else {
              delete mapComponent.youAreHere;
            }
          }

          const bearing = Number(get(mapComponent, "initialBearing", 0));
          if (bearing) {
            mapComponent.initialBearing = bearing;
          }
          else {
            delete mapComponent.initialBearing;
          }

          const pitch = Number(get(mapComponent, "initialPitch", 0));
          if (pitch) {
            mapComponent.initialPitch = pitch;
          }
          else {
            delete mapComponent.initialPitch;
          }

          const initialLocation = get(mapComponent, "initialLocation", null);
          if (!initialLocation) {
            delete mapComponent.initialLocation;
          }
        }
      }
    });

    this.props.updateScreenView(this.props.screen.id, this.props.screenView.id, form);
  }

  onCancelScreen() {
    this.props.resetScreenForm();
    this.props.history.push(`/companies/${this.props.match.params.companyId}/screens`);
  }

  onCancelScreenView() {
    this.props.resetScreenViewForm();
    this.props.history.push(`/companies/${this.props.match.params.companyId}/screens/edit/${this.props.match.params.screenGroupId}/views`);
  }

  screenHasChanged() {

    if (isEmpty(this.props.screenForm)) {
      return false;
    }

    const nameChanged = get(this.props.screenForm, "name", "") !== get(this.props.screen, "name", "");
    const languageChanged = get(this.props.screenForm, "language", "") !== get(this.props.screen, "language", "");
    const allowInteractionsChanged = get(this.props.screenForm, "allowInteractions", false) !== get(this.props.screen, "allowInteractions", false);
    const hideViewIdentifierChanged = get(this.props.screenForm, "hideViewIdentifier", false) !== get(this.props.screen, "hideViewIdentifier", false);
    const useMeetingRoomCalendarsChanged = get(this.props.screenForm, "useMeetingRoomCalendars", false) !== get(this.props.screen, "useMeetingRoomCalendars", false);
    const resetTimerChanged = JSON.stringify(get(this.props.screenForm, "resetTimer", {})) !== JSON.stringify(get(this.props.screen, "resetTimer", {}));
    const configOverridesChanged = JSON.stringify(get(this.props.screenForm, "configOverrides", {})) !== JSON.stringify(get(this.props.screen, "configOverrides", {}));

    return nameChanged || languageChanged || allowInteractionsChanged || hideViewIdentifierChanged || useMeetingRoomCalendarsChanged || resetTimerChanged || configOverridesChanged;
  }

  screenViewHasChanged() {

    if (isEmpty(this.props.screenViewForm)) {
      return false;
    }

    const nameChanged = get(this.props.screenViewForm, "name", "") !== get(this.props.screenView, "name", "");
    const descriptionChanged = get(this.props.screenViewForm, "description", "") !== get(this.props.screenView, "description", "");
    const layoutChanged = JSON.stringify(get(this.props.screenViewForm, "layout", {})) !== JSON.stringify(get(this.props.screenView, "layout", {}));

    return nameChanged || descriptionChanged || layoutChanged;
  }

  screenIsValid() {
    return this.props.screenForm.name && this.props.screenForm.name.length > 0 && !this.props.isScreenLoading;
  }

  screenViewIsValid() {
    if (this.props.screenViewForm.name) {
      if (this.props.screenViewForm.name.length === 0) {
        return false;
      }
    }

    return !this.props.isScreenLoading;
  }

  render() {
    // console.log("ScreenContainer", this.props);
    if (this.props.isAuthLoading || isEmpty(this.props.screenForm)) {
      return <Loader fullScreen />;
    }

    const subNavigationLinks = [];
    let mapComponentPaths = [];

    // If in screen group, add details, devices and views
    // If in screen view, add details and layout
    let header = null;
    const screenViewId = this.props.match.params.screenViewId;
    const isShowingScreenView = screenViewId && this.props.screenViewForm && (this.props.location.pathname.includes(`/views/${screenViewId}/layout`) || this.props.location.pathname.includes(`/views/${screenViewId}/map`));
    if (isShowingScreenView) {
      
      const formView = this.props.screenViewForm;

      subNavigationLinks.push({ pathname: `/companies/${this.props.match.params.companyId}/screens/edit/${this.props.match.params.screenGroupId}/views/${this.props.match.params.screenViewId}/layout`, label: `Layout` });

      header = (
        <LargeHeader
          parent={{ label: this.props.screen.name, onClick: () => { this.props.history.push(`/companies/${this.props.match.params.companyId}/screens/edit/${this.props.match.params.screenGroupId}`) } }}
          title={formView.name}
        />
      );

      // Check if the layout is a valid json
      // And find all map and svg-map components in the layout to create edit views for them
      try {
        const layout = formView.layout;

        // Validate the layout JSON
        const layoutString = JSON.stringify(layout, null, 2);
        JSON.parse(layoutString);

        mapComponentPaths = ScreenHelpers.getMapViewComponentPaths(layout);

        if (mapComponentPaths.length === 1) {
          subNavigationLinks.push({ pathname: `/companies/${this.props.match.params.companyId}/screens/edit/${this.props.match.params.screenGroupId}/views/${this.props.match.params.screenViewId}/map/0`, label: `Map` });
        }
        else {
          mapComponentPaths.forEach((path, index) => {
            subNavigationLinks.push({ pathname: `/companies/${this.props.match.params.companyId}/screens/edit/${this.props.match.params.screenGroupId}/views/${this.props.match.params.screenViewId}/map/${index}`, label: `Map ${index + 1}` });
          });
        }
      }
      catch (e) {
        // console.error("Invalid JSON in layout", e);
      }
    }
    else {

      subNavigationLinks.push({ pathname: `/companies/${this.props.match.params.companyId}/screens/edit/${this.props.match.params.screenGroupId}/details`, label: `Details` });
      subNavigationLinks.push({ pathname: `/companies/${this.props.match.params.companyId}/screens/edit/${this.props.match.params.screenGroupId}/views`, label: `Views` });
    
      // Check if the screen group is public or not
      if (this.props.screen.isPrivate) {
        subNavigationLinks.push({ pathname: `/companies/${this.props.match.params.companyId}/screens/edit/${this.props.match.params.screenGroupId}/devices`, label: `Devices` });
      }
      else {
        subNavigationLinks.push({ pathname: `/companies/${this.props.match.params.companyId}/screens/edit/${this.props.match.params.screenGroupId}/links`, label: `Links` });
      }

      header = (
        <LargeHeader
          title={this.props.screen.name}
        />
      );
    }

    let optionFooter = null; 
    if (!isShowingScreenView && this.screenHasChanged()) {
      optionFooter = (
        <OptionFooter inline={true} cancel={this.onCancelScreen} options={[{ label: "Save", callback: this.onSaveScreen, disabled: !this.screenIsValid() }]} />
      );
    }
    else if (this.screenViewHasChanged()) {
      optionFooter = (
        <OptionFooter inline={true} cancel={this.onCancelScreenView} options={[{ label: "Save view", callback: this.onSaveScreenView, disabled: !this.screenViewIsValid() }]} />
      );
    }

    return (
      <div className={style.page}>
        { header }
        <SubNavigation links={subNavigationLinks} />
        <Switch>
          <Redirect exact from="/companies/:companyId/screens/edit/:screenGroupId/views/:screenViewId" to={{ pathname: `/companies/${this.props.match.params.companyId}/screens/edit/${this.props.match.params.screenGroupId}/views/${this.props.match.params.screenViewId}/layout` }} />
          <Route path="/companies/:companyId/screens/edit/:screenGroupId/views/:screenViewId/map/:mapIndex" children={(props) => <ScreenViewContainer {...props} screenGroupId={this.props.match.params.screenGroupId} screenViewId={this.props.match.params.screenViewId} mapComponentPaths={mapComponentPaths} />} />
          <Route path="/companies/:companyId/screens/edit/:screenGroupId/views/:screenViewId/layout" children={(props) => <ScreenViewContainer {...props} screenGroupId={this.props.match.params.screenGroupId} screenViewId={this.props.match.params.screenViewId} />} />

          <Redirect exact from="/companies/:companyId/screens/edit/:screenGroupId" to={{ pathname: `/companies/${this.props.match.params.companyId}/screens/edit/${this.props.match.params.screenGroupId}/details` }} />
          <Route path="/companies/:companyId/screens/edit/:screenGroupId/details" children={(props) => <ScreenGroupContainer {...props} screenGroupId={this.props.match.params.screenGroupId} />} />
          <Route path="/companies/:companyId/screens/edit/:screenGroupId/devices/:deviceId?" children={(props) => <ScreenGroupDevices {...props} screenGroupId={this.props.match.params.screenGroupId} />} />
          <Route path="/companies/:companyId/screens/edit/:screenGroupId/links/:linkId?" children={(props) => <ScreenGroupLinks {...props} screenGroupId={this.props.match.params.screenGroupId} />} />
          <Route path="/companies/:companyId/screens/edit/:screenGroupId/views/:screenViewId?" children={(props) => <ScreenGroupViews {...props} screenGroupId={this.props.match.params.screenGroupId} />} />
          <Route render={NotFound} />
        </Switch>
        { optionFooter }
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
    screen: state.screen,
    screenForm: state.screen.form,
    screenView: state.screenView,
    screenViewForm: state.screenView.form,
    selectedCompany: state.auth.selectedCompany,
    isAuthLoading: state.loading.auth,
    isScreenLoading: state.loading.screen
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    getScreenGroup: screenActions.getScreenGroup,
    getScreenViews: screenViewActions.getScreenViews,
    getScreenDevices: screenActions.getScreenDevices,
    getScreenLinks: screenActions.getScreenLinks,
    updateScreen: screenActions.updateScreenGroup,
    updateScreenView: screenViewActions.updateScreenView,
    resetScreenViewForm: screenViewActions.resetScreenViewForm,
    resetScreenForm: screenActions.resetScreenGroupForm,
    clearScreenData: screenActions.clearScreenData
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(ScreenGroup);
