import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Container, Row, Col } from "react-grid-system";
import jsQR from "jsqr";
import isEmpty from "lodash/isEmpty";
import Freetext from "../../components/Freetext";
import * as sensorActions from "../../actions/sensors";
import style from "./style.module.scss"

const constraints = {
  audio: false,
  video: { facingMode: "environment" }
};

class Scan extends Component {

  constructor(props) {
    super(props);
    this.state = {
      code: "",
      error: ""
    }
    this.handleSuccess = this.handleSuccess.bind(this);
    this.handleError = this.handleError.bind(this);
    this.tick = this.tick.bind(this);
    this.drawLine = this.drawLine.bind(this);
    navigator.mediaDevices.getUserMedia(constraints).then(this.handleSuccess).catch(this.handleError);
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps.sensor.id) {
      this.props.history.push({
        pathname: `/companies/${this.props.match.params.companyId}/sensors/${nextProps.sensor.id}`,
        state: { canGoBack: true }
      });

      return false;
    }

    return true;
  }

  handleSuccess(stream) {
    window.stream = stream;

    const video = document.querySelector("video");
    video.srcObject = stream;
    requestAnimationFrame(this.tick);
  }

  handleError(error) {
    console.log("navigator.getUserMedia error: ", error);
    this.setState({ error });
  }

  tick() {
    const video = document.querySelector("video");
    if (!isEmpty(video)) {
      if (video.readyState === video.HAVE_ENOUGH_DATA) {

        const canvasElement = document.querySelector("canvas");
        const canvas = canvasElement.getContext("2d");
        canvasElement.width = video.videoWidth;
        canvasElement.height = video.videoHeight;

        canvas.drawImage(video, 0, 0, canvasElement.width, canvasElement.height);

        const imageData = canvas.getImageData(0, 0, canvasElement.width, canvasElement.height);
        const code = jsQR(imageData.data, imageData.width, imageData.height, {
          inversionAttempts: "dontInvert",
        });
        
        if (code) {
          this.drawLine(code.location.topLeftCorner, code.location.topRightCorner, "#FF3B58");
          this.drawLine(code.location.topRightCorner, code.location.bottomRightCorner, "#FF3B58");
          this.drawLine(code.location.bottomRightCorner, code.location.bottomLeftCorner, "#FF3B58");
          this.drawLine(code.location.bottomLeftCorner, code.location.topLeftCorner, "#FF3B58");
          
          // Find sensor
          if (!this.props.isLoading) {
            this.props.findSensor(code.data);
          }
        }
      }
      requestAnimationFrame(this.tick);
    }
  }

  drawLine(begin, end, color) {
    const canvasElement = document.querySelector("canvas");
    const canvas = canvasElement.getContext("2d");
    
    canvas.beginPath();
    canvas.moveTo(begin.x, begin.y);
    canvas.lineTo(end.x, end.y);
    canvas.lineWidth = 4;
    canvas.strokeStyle = color;
    canvas.stroke();
  }

  render() {
    return (
      <div>
        <Container className={style.container}>
          <Row className={style.topRow}>
            <Col>
              <Freetext header="Scan QR code" />
            </Col>
          </Row>
          <Row>
            <Col>
              <canvas className={style.image} />
              <video className={style.video} playsInline autoPlay />
            </Col>
          </Row>
          <Row>
            <Col>
              <Freetext content={(this.state.error !== "" ? this.state.error : this.state.code)} />
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return { 
    sensor: state.sensor,
    isLoading: state.loading.sensor
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ findSensor: sensorActions.findSensor }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(Scan);
