import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Col, Row } from "react-grid-system";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import isEmpty from "lodash/isEmpty";
import Title from "../../components/Title";
import SmallButton from "../../components/SmallButton";
import InputBox from "../../components/InputBox";
import SearchBox from "../../components/DashboardFilterSearchBox";
import { locationFilterTypes, getLocationName } from "../../locationHelpers";
import * as filterActions from "../../actions/filters";
import style from "./style.module.scss";

class FilterDetails extends Component {

  constructor(props) {
    super(props);
    // console.log("FilterDetails constructor", props);

    this.state = {
      showNameInput: false,
      newName: ""
    };
    
    this.onEditNameClicked = this.onEditNameClicked.bind(this);
    this.onNameChange = this.onNameChange.bind(this);
    this.onEditNameSave = this.onEditNameSave.bind(this);
    this.onEditNameCancel = this.onEditNameCancel.bind(this);
    this.onViewDashboardClicked = this.onViewDashboardClicked.bind(this);
    this.onDeleteClicked = this.onDeleteClicked.bind(this);

    if (isEmpty(this.props.match.params.id)) {
      this.props.clearHighlightedFilter();
    }
    else if (isEmpty(this.props.filter) || this.props.filter.id !== this.props.match.params.id) {
      this.props.getFilter(this.props.match.params.id);
    }
  }

  componentDidUpdate(prevProps) {
    // console.log("FilterDetails componentDidUpdate", prevProps);

    // Only load data if id change
    if (isEmpty(this.props.match.params.id)) {
      this.props.clearHighlightedFilter();
    }
    else if (prevProps.match.params.id !== this.props.match.params.id) {
      this.props.getFilter(this.props.match.params.id);
    }
  }

  onEditNameClicked() {
    this.setState({ showNameInput: true, newName: this.props.filter.name });
  }

  onNameChange(event) {
    this.setState({ newName: event.target.value });
  }

  onEditNameSave() {

    if (isEmpty(this.state.newName)) {
      return;
    }

    const body = {
      name: this.state.newName,
      filter: JSON.stringify(this.props.filter.filter)
    }

    this.props.updateFilter(this.props.filter.id, body);
    this.setState({ showNameInput: false });
  }

  onEditNameCancel() {
    this.setState({ showNameInput: false });
  }

  onViewDashboardClicked() {
    const { push } = this.props.history;
    this.props.updateFilterDraft(this.props.filter.filter.or, this.props.filter.id, this.props.filter.name, push);
  }

  onDeleteClicked() {
    this.props.deleteFilters([this.props.filter.id]);
  }
  
  render() {
    const { isLoading } = this.props;

    if (isEmpty(this.props.match.params.id) || isEmpty(this.props.filter) ) {
      return null;
    }

    const name = this.props.filter.name ?? " ";

    const queriesForSearchBox = this.props.filter.filter.or.map(q => {

      let locations = [];
      let customTags = [];
      let locationTypes = [];
      let names = [];

      q.and.map(subQuery => {
        if (subQuery.or) {
          subQuery.or.map(sub2 => {
            if (sub2.and) {
              sub2.and.map(sub3 => {
                if (sub3.property === "_id") {
                  const locationName = getLocationName({ id: "root", children: this.props.locationHierarchy }, sub3.value);
                  if (locationName) {
                    locations.push({ id: sub3.value, name: locationName, include: sub3.operator === "eq" });
                  }
                }
              });
            }
            else if (sub2.property === "_id") {
              const locationName = getLocationName({ id: "root", children: this.props.locationHierarchy }, sub2.value);
              if (locationName) {
                locations.push({ id: sub2.value, name: locationName, include: sub2.operator === "eq" });
              }
            }
          });
        }
        else if (subQuery.property === "_id") {
          const locationName = getLocationName({ id: "root", children: this.props.locationHierarchy }, subQuery.value);
          if (locationName) {
            locations.push({ id: subQuery.value, name: locationName, include: subQuery.operator === "eq" });
          }
        }
        else if (subQuery.property === "customTag") {
          const tag = this.props.customTags.find(tag => tag.id === subQuery.value)
          if (tag) {
            customTags.push(tag);
          }
        }
        else if (subQuery.property === "type") {
          const type = locationFilterTypes.find(type => type.id === subQuery.value);
          if (type) {
            locationTypes.push(type);
          }
        }
        else if (subQuery.property === "name") {
          names.push(subQuery.value);
        }
      });

      return { locationTypes, customTags, locations, value: names.join(" ") }
    });

    return (
      <div className={style.scroll}>
        <Row className={style.topRow}>
          { this.state.showNameInput ?
            (
              <>
                <Col lg={12} xl={7}>
                  <InputBox
                    value={this.state.newName} 
                    valid={this.state.newName} 
                    onChange={this.onNameChange}
                    required 
                    showValidIcon
                    />
                </Col>
                <Col lg={12} xl={5}>
                  <SmallButton text="Save" onClick={this.onEditNameSave} disabled={isLoading} noLeftMargin />
                  <SmallButton text="Cancel" onClick={this.onEditNameCancel} color="red" />
                </Col>
              </>
            ) : (
              <>
                <Col lg={12} xl={7}>
                  <Title text={name} />
                  <div role="button" tabIndex="0" className={style.editPencil} onClick={this.onEditNameClicked} onKeyDown={this.onEditNameClicked}>
                    <FontAwesomeIcon icon={faPencilAlt} size="lg" color="#1C4D82" />
                  </div>
                </Col>  
                <Col lg={12} xl={5} align="end">
                  <Row justify="end" nogutter>
                    <SmallButton text="View dashboard" onClick={this.onViewDashboardClicked} disabled={isLoading} noLeftMargin />
                    <SmallButton text="Delete" onClick={this.onDeleteClicked} color="red" disabled={isLoading} />
                  </Row>
                </Col>  
              </>
            )
          }
        </Row>
        <div style={{ paddingTop: "40px" }} />
        <SearchBox
          hideEditButton={true}
          editMode={false}
          queries={queriesForSearchBox}
          />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    filter: state.filters.highlightedFilter,
    isLoading: state.loading.filters,
    selectedCompany: state.auth.selectedCompany,
    locationHierarchy: state.locations.hierarchy,
    customTags: state.customTags.list,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    getFilter: filterActions.getFilter,
    updateFilter: filterActions.updateFilter,
    deleteFilters: filterActions.deleteFilters,
    clearHighlightedFilter: filterActions.clearHighlightedFilter,
    clearAllSelectFilters: filterActions.clearAllSelectFilters,
    updateFilterDraft: filterActions.updateFilterDraft,
   }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(FilterDetails);
