import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Col, Row } from "react-grid-system";
import { get, isEmpty } from "lodash";
import SearchBox from "../../components/SearchBox";
import TopRowOptions from "../../components/TopRowOptions";
import Table from "../../components/Table";
import * as selectedActions from "../../actions/selected";

class GroupUsers extends Component {

  constructor(props) {
    super(props);
    this.state = {
      searchText: undefined,
      activeSearch: undefined,
      sortBy: "name",
      sortOrder: "asc",
    };
    this.onRowClick = this.onRowClick.bind(this);
    this.onToggle = this.onToggle.bind(this);
  }

  componentDidMount() {
    const userName = get(this.props.user, "name", null);
    if (!userName) {
      document.title = `BLDNG.ai - ${userName} - groups`;
    }
  }

  onRowClick(column, rowInfo) {
    return {
      onClick: e => {
        if (column.name !== 'isSelected' && rowInfo !== undefined) {
          let link = `/companies/${this.props.match.params.companyId}/org/groups/${rowInfo.original.id}/users`;

          if (e.metaKey || e.ctrlKey) {
            window.open(`${link}`);
          }
          else {
            this.props.history.push(link);
          }
        }
      },
      style: {
        cursor: "pointer"
      }
    }
  }

  onToggle(row) {
    if (this.props.selectedUserGroups[row.original.id] !== undefined && this.props.selectedUserGroups[row.original.id]) {
      this.props.deselectUserGroup(row.original.id);
    }
    else {
      this.props.selectUserGroup(row.original);
    }
  }

  render() {
    const { isLoading } = this.props;

    if (isEmpty(this.props.userId)) {
      return null;
    }

    const anotherTypeSelected = this.props.selectedTypes.length > 0 && !this.props.selectedTypes.includes("userGroup");

    // Filter userGroups based on search
    let userGroups = this.props.user.userGroups;
    if (this.state.activeSearch) {
      userGroups = userGroups.filter((userGroup) =>  userGroup.name.toLowerCase().includes(this.state.activeSearch.toLowerCase()));
    }

    // Filter out userGroups that has isSupportGroup, isInstallerGroup, isItAdminGroup or isAdminGroup set to true
    userGroups = userGroups.filter((userGroup) => {
      return !userGroup.isSupportGroup && !userGroup.isInstallerGroup && !userGroup.isItAdminGroup && !userGroup.isAdminGroup;
    });

    // Sort userGroups
    userGroups = userGroups.sort((a, b) => {
      if (this.state.sortBy === "name") {
        if (this.state.sortOrder === "asc") {
          return a.name.localeCompare(b.name);
        }
        else {
          return b.name.localeCompare(a.name);
        }
      }
    });

    const tableElement = (
      <Table
        data={userGroups}
        noDataText={isLoading ? "" : "No groups found"}
        sortBy={this.state.sortBy}
        sortOrder={this.state.sortOrder}
        columns={[
          {
            id: "id",
            header: "",
            accessorKey: "id",
            sortable: false,
            name: "isSelected",
            cell: ({ row }) => (
              <label className="checkboxContainer" htmlFor={`editCheckbox-${row.original.id}`}>
                <input
                  id={`editCheckbox-${row.original.id}`}
                  type="checkbox"
                  className="checkbox"
                  checked={(this.props.selectedUserGroups[row.original.id] !== undefined && this.props.selectedUserGroups[row.original.id])}
                  onChange={() => this.onToggle(row)}
                  disabled={anotherTypeSelected}
                />
                <span className={anotherTypeSelected ? "disabledCheckmark" : "checkmark"} />
              </label>
            ),
            width: 60
          },
          {
            header: "Name",
            accessorKey: "name",
            cell: ({ row }) => (<span>{row.original.name}</span>)
          },
          {
            id: "arrow",
            header: "",
            sortable: false,
            className: "pull-right",
            width: 60,
            cell: () => (<div className="arrow" />)
          }
        ]}
        getTdProps={this.onRowClick}
        className="setMaxHeigth -row-clickable -highlight"
        loading={isLoading}
      />
    );

    return (
      <>
        <TopRowOptions
          searchbox={(
            <SearchBox
              value={this.state.searchText}
              onSearchChanged={(value) => this.setState({ searchText: value === '' ? undefined : value })}
              onSearchClick={() => this.setState(prevState => ({ activeSearch: prevState.searchText }))}
              onClear={() => this.setState({ searchText: undefined, activeSearch: undefined })}
              inDetailsView
            />
          )}
        />
        <Row>
          <Col xs={12} md={12}>
            { tableElement }
          </Col>
        </Row>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.user,
    selectedCompany: state.auth.selectedCompany,
    selectedUserGroups: state.selected.userGroups,
    selectedTypes: state.selected.allTypes,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    selectUserGroup: selectedActions.selectUserGroup,
    deselectUserGroup: selectedActions.deselectUserGroup
   }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(GroupUsers);
