import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {  Col, Row } from "react-grid-system";
import { get, isEmpty } from "lodash";
import InputBox from "../../components/InputBox";
import { ControlledDropdownSelection } from "../../components/DropdownSelection";
import { RoleName, getRoleStringOptions, getRoleName } from "../../helpers";
import * as usersActions from "../../actions/users"

class UserInfo extends Component {

  constructor(props) {
    super(props);
    this.privilegesChanged = this.privilegesChanged.bind(this);
  }

  componentDidMount() {
    const userName = get(this.props.user, "name", null);
    if (!userName) {
      document.title = `BLDNG.ai - ${userName} - info`;
    }
  }

  privilegesChanged(role) {
    let draft = {};
    if (this.props.user.roleName !== role) {
      draft = { role };
    }

    this.props.draftUserChange(draft);
  }

  render() {
    const role = get(this.props.user.draft, "role", get(this.props.user, "roleName", RoleName.User));
    // console.log("UserInfo.render()", this.props);

    if (isEmpty(this.props.userId) || role === undefined) {
      return null;
    }

    let roleOptions = getRoleStringOptions();
    if (!this.props.auth.hasSupportRole) {
      roleOptions = roleOptions.filter((role) => role.id !== RoleName.Support && role.id !== RoleName.Installer);
    }

    // Stop ITAdmin from changing roles for users with Support or Installer roles
    let hideSelector = !this.props.auth.hasSupportRole && (this.props.user.roleName === RoleName.Installer || this.props.user.roleName === RoleName.Support);

    return (
      <>
        <InputBox label="Email" value={this.props.user.email} readOnly style={{ maxWidth: "400px" }}  />
        { this.props.user.id && this.props.user.phoneNumber &&
          <InputBox label="Phone number" value={this.props.user.phoneNumber} readOnly style={{ maxWidth: "400px" }} />
        }
        { !hideSelector && this.props.auth.hasITAdminRole ? (
          <ControlledDropdownSelection
            name="role"
            value={role}
            label="Access level"
            options={roleOptions}
            onChange={(event) => this.privilegesChanged(event.target.value)}
            disabled={false}
            />
        ) : (
          <InputBox label="Access level" value={getRoleName(this.props.user.role)} readOnly style={{ maxWidth: "110px" }} />
        )}
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
    user: state.user,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    draftUserChange: usersActions.draftUserChange,
   }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(UserInfo);
