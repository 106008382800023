import React, { Component } from "react";
import { Switch, Route, Redirect, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Sensors from "./sub-pages/sensors";
import Gateways from "./sub-pages/gateways";
import JobGroups from "./jobGroupsContainer";
import Sensortags from "./sensorTags";
import Unregistered from "./Unregistered";
import NotFound from "../NotFound";
import SubNavigation from "../../components/SubNavigation";
import LargeHeader from "../../components/LargeHeader";
import style from "./style.module.scss";
import Anomalies from "./sub-pages/anomalies/anomalies";

class InstallationContainer extends Component {
  
  componentDidMount() {
    document.title = `BLDNG.ai - Installation`;
  }

  render() {
    // console.log("InstallationContainer.render", this.props);

    let fallbackLink;
    const subNavigationLinks = [];
    if (this.props.auth.hasSupportRole) {
      subNavigationLinks.push({ pathname: `/companies/${this.props.match.params.companyId}/installation/job-groups`, label: `Job groups`, hiddenForAdmins: true });
      fallbackLink = { pathname: `/companies/${this.props.match.params.companyId}/installation/job-groups` };
    }
    else {
      fallbackLink = { pathname: `/companies/${this.props.match.params.companyId}/installation/sensors` };
    }

    subNavigationLinks.push({ pathname: `/companies/${this.props.match.params.companyId}/installation/sensors`, label: `Sensors` });
    subNavigationLinks.push({ pathname: `/companies/${this.props.match.params.companyId}/installation/gateways`, label: `Gateways` });
    
    if (this.props.auth.hasSupportRole) {
      subNavigationLinks.push({ pathname: `/companies/${this.props.match.params.companyId}/installation/sensor-tags`, label: `Sensor tags`, hiddenForAdmins: true });
    }
    
    subNavigationLinks.push({ pathname: `/companies/${this.props.match.params.companyId}/installation/unregistered-sensors`, label: `Unregistered sensors` });
    
    if (this.props.auth.hasSupportRole) {
      subNavigationLinks.push({ pathname: `/companies/${this.props.match.params.companyId}/installation/anomalies`, label: `Anomalies`, hiddenForAdmins: true });
    }

    return (
      <div className={style.page}>
        <LargeHeader title="Installation" />
        <SubNavigation links={subNavigationLinks} /> 
        <Switch>
          <Redirect exact from="/companies/:companyId/installation" to={fallbackLink} />
          <Route path="/companies/:companyId/installation/job-groups/:jobGroupType?/:jobGroupId?/:jobId?" children={(props) => (<JobGroups { ...props} />)} />
          <Route path="/companies/:companyId/installation/sensors" children={(props) => (<Sensors { ...props} />)} />
          <Route path="/companies/:companyId/installation/gateways" children={(props) => (<Gateways { ...props} />)} />
          <Route path="/companies/:companyId/installation/sensor-tags" children={(props) => (<Sensortags { ...props} />)} />
          <Route path="/companies/:companyId/installation/unregistered-sensors/:deviceId?" children={(props) => (<Unregistered { ...props} />)} />
          <Route path="/companies/:companyId/installation/anomalies/:anomalyId?" children={(props) => (<Anomalies { ...props} />)} />
          <Route component={NotFound} />
        </Switch>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return { 
    auth: state.auth
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    
   }, dispatch);
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(InstallationContainer));
