import React, { Component } from "react";
import { Icon, DARKGREEN, DARKRED, DARKGREY, WHITE } from "../Icon";
import { Info } from "ui";
import { faCheck, faExclamation } from "@fortawesome/free-solid-svg-icons";
import style from "./style.module.scss";

interface InputBoxProps {
  label?: string;
  placeholder?: string;
  value?: string;
  onChange?: any;
  help?: string;
  info?: string;
  valid?: boolean;
  formSubmitted?: boolean;
  required?: boolean;
  disabled?: boolean;
  readOnly?: boolean;
  showValidIcon?: boolean;
  showValidOutline?: boolean;
  showValidInfo?: boolean;
  noMargin?: boolean;
  hideicon?: boolean;
  style?: any;
}

interface InputBoxState {
  didBlur: boolean;
}

class InputBox extends Component<InputBoxProps, InputBoxState> {

  constructor(props: InputBoxProps) {
    super(props);

    this.state = {
      didBlur: false
    };
  }

  getInputStyles() {
    let style: any = {};
    if (this.props.noMargin) {
      style.margin = 0;
    }

    if (this.props.disabled) {
      return { ...style, backgroundColor: "#eee", color: DARKGREY };
    }
    else if (this.props.value === undefined || this.props.value === null || this.props.value === "") {
      return style;
    }
    else if (this.props.valid) {
      return { ...style, outlineColor: DARKGREEN, outlineWidth: "2px" };
    }
    return { ...style, outlineColor: DARKRED, outlineWidth: "2px" };
  }

  renderIcon() {
    if (this.props.value === undefined || this.props.value === null || this.props.value === "" || this.props.hideicon) {
      return null;
    }
    else if (this.props.disabled) {
      return null;
    }
    else if (this.props.valid) {
      return <Icon color={WHITE} bgColor={DARKGREEN} icon={faCheck} classic />;
    }
    return <Icon color={WHITE} bgColor={DARKRED} icon={faExclamation} classic />;
  }

  render() {
    const { label, placeholder, value, onChange, help, info, valid, formSubmitted, required, disabled, readOnly, showValidIcon, showValidOutline, showValidInfo, noMargin, ...rest } = this.props;
    const invalid = (formSubmitted || this.state.didBlur) && !valid;
    return (
      <div className={style.inputGroup} style={{ marginTop: label ? "20px" : 0, marginBottom: info ? "20px" : 0, ...this.props.style}}>
        { label && <label>{label}</label> }
        { help && <Info text={help} />}
        <div className={style.inputWrapper}>
          <input
            placeholder={placeholder}
            value={value}
            onChange={onChange}
            onBlur={() => this.setState({ didBlur: true })}
            {...rest}
            style={required && showValidOutline ? this.getInputStyles() : {}}
            disabled={disabled}
            readOnly={readOnly}
          />
          {required && showValidIcon && this.renderIcon()}
        </div>

        {info && <div className={style.info} style={showValidInfo && !valid ? { color: DARKRED } : {}}>{info}</div>}
      </div>
    );
  }
}

export default InputBox;