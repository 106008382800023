import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Route } from "react-router-dom";
import { Col, Row, Hidden, Visible } from "react-grid-system";
import isEmpty from "lodash/isEmpty";
import Freetext from "../../components/Freetext";
import JobModal from "./JobModal";
import JobList from "./jobList";
import JobGroupList from "./jobGroupList";
import * as jobActions from "../../actions/jobs";
import style from "./style.module.scss";

class JobGroups extends Component {

  constructor(props) {
    super(props);

    this.onCloseModal = this.onCloseModal.bind(this);
    this.onDeleteJob = this.onDeleteJob.bind(this);
    this.onRetryJob = this.onRetryJob.bind(this);

    if (!isEmpty(this.props.match.params.jobId) && isEmpty(this.props.job.id) && !this.props.isLoading) {
      this.props.getJob(this.props.match.params.jobId);
    }
  }

  componentDidMount() {
    if (!this.props.location.pathname.endsWith("/info") &&
        !this.props.location.pathname.endsWith("/timeline") && 
        !this.props.location.pathname.endsWith("/error") && 
        !this.props.location.pathname.endsWith("/actions")) {
      document.title = `BLDNG.ai - JobGroups`;
    }
  }

  onCloseModal() {
    this.props.setJob({});
    if (this.props.match.params.jobGroupType && this.props.match.params.jobGroupType !== "unknown") {
      this.props.history.push(`/companies/${this.props.match.params.companyId}/installation/job-groups/${this.props.match.params.jobGroupType}/${this.props.match.params.jobGroupId}`);
    }
    else {
      this.props.history.push(`/companies/${this.props.match.params.companyId}/installation/job-groups`);
    }
  }

  onDeleteJob() {
    const { push } = this.props.history;
    this.props.deleteJob(this.props.job.id, this.props.jobGroup, push);
  }

  onRetryJob() {
    // this.props.clearCreatedJob();
  }

  render() {
    return (
      <>
        <Hidden xs sm md>
          <div className={style.mainContainerHidden}>
            <div className={style.row}>
              <div className={style.listContainer}>
                <Route path="/companies/:companyId/installation/job-groups/:type?/:id?/:jobId?" children={(props) => (<JobGroupList { ...props} />)} />
              </div>
              <div className={style.sideBar}>
                <Route path="/companies/:companyId/installation/job-groups/:type?/:id?/:jobId?" children={(props) => (<JobList { ...props} />)} />
              </div>
            </div>
          </div>
        </Hidden>
        <Visible xs sm md>
          <div className={style.mainContainerHiddenMobile}>
            <div className={style.row}>
              <div className={style.col}>
                <div className={style.slimScroll}>
                  <Row className={style.topRow}>
                    <Col>
                      <Freetext content="You need a bigger screen to manage content" />
                    </Col>
                  </Row>
                </div>
              </div>
            </div>
          </div>
        </Visible>

        { !isEmpty(this.props.job.id) && 
          <JobModal
            { ...this.props }
            primaryBtn={{
              text: "Retry",
              color: "blue", 
              disabled: !this.props.job.canRetry,
              onClick: this.onRetryJob
            }}
            secondaryBtn={{
              text: "Delete",
              color: "red",
              disabled: !this.props.job.canDelete,
              onClick: this.onDeleteJob
            }}
            tertiaryBtn={{
              text: "Close",
              onClick: this.onCloseModal
            }}
          />
        }
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    job: state.job,
    jobGroup: state.jobGroup,
    isLoading: state.loading.job
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    getJob: jobActions.getJob,
    setJob: jobActions.setJob,
    deleteJob: jobActions.deleteJob
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(JobGroups);
