import { get, isEmpty } from "lodash";

export const locationTypes = [
  { id: "region", name: "Region" },
  { id: "building", name: "Building" },
  { id: "floor", name: "Floor" },
  { id: "corridor", name: "Corridor" },
  { id: "stairs", name: "Stairs" },
  { id: "elevator", name: "Elevator" },
  { id: "zone", name: "General zone", groupName: "Zones" },
  { id: "zone.collaboration", name: "Collaboration zone", groupName: "Zones" },
  { id: "zone.social", name: "Social zone", groupName: "Zones" },
  { id: "zone.exhibition", name: "Exhibition", groupName: "Zones" },
  { id: "zone.reception", name: "Reception", groupName: "Zones" },
  { id: "zone.canteen", name: "Canteen", groupName: "Zones" },
  { id: "zone.standing", name: "Standing zone", groupName: "Zones" },
  { id: "zone.hotspot", name: "Hotspot zone", groupName: "Zones" },
  { id: "zone.hotdesk", name: "Hotdesk zone", groupName: "Zones" },
  { id: "room", name: "General room", groupName: "Rooms" },
  { id: "room.meeting", name: "Meeting room", groupName: "Rooms" },
  { id: "room.quiet", name: "Quiet room", groupName: "Rooms" },
  { id: "room.private-office", name: "Private office", groupName: "Rooms" },
  { id: "room.locker", name: "Locker room", groupName: "Rooms" },
  { id: "room.facility", name: "Facility room", groupName: "Rooms" },
  { id: "room.auditorium", name: "Auditorium", groupName: "Rooms" },
  { id: "room.class", name: "Classroom", groupName: "Rooms" },
  { id: "room.toilet.unisex", name: "Unisex toilet", groupName: "Toilets" },
  { id: "room.toilet.female", name: "Female toilet", groupName: "Toilets" },
  { id: "room.toilet.male", name: "Male toilet", groupName: "Toilets" },
  { id: "room.toilet.handicap", name: "Handicap toilet", groupName: "Toilets" },
  { id: "asset.social.seat", name: "Social seat", groupName: "Assets" },
  { id: "asset.collaboration.seat", name: "Collaboration seat", groupName: "Assets" },
  { id: "asset.meeting.seat", name: "Meeting room seat", groupName: "Assets" },
  { id: "asset.quiet.seat", name: "Quiet room seat", groupName: "Assets" },
  { id: "asset.education.seat", name: "Education seat", groupName: "Assets" },
  { id: "asset.canteen.seat", name: "Canteen seat", groupName: "Assets" },
  { id: "asset.facility.seat", name: "Facility seat", groupName: "Assets" },
  { id: "asset.hotdesk.seat", name: "Hotdesk seat", groupName: "Assets" },
  { id: "asset.workstation", name: "Workstation", groupName: "Assets" },
  { id: "asset.pod", name: "Pod", groupName: "Assets" }
];

export const locationFilterTypes = [
  { id: "region", name: "Region" },
  { id: "building", name: "Building" },
  { id: "floor", name: "Floor" },
  { id: "corridor", name: "Corridor" },
  { id: "stairs", name: "Stairs" },
  { id: "elevator", name: "Elevator" },
  { id: "zone.*", operator: "regex", name: "Any zone", groupName: "Zones" },
  { id: "zone", name: "General zone", groupName: "Zones" },
  { id: "zone.collaboration", name: "Collaboration zone", groupName: "Zones" },
  { id: "zone.social", name: "Social zone", groupName: "Zones" },
  { id: "zone.exhibition", name: "Exhibition", groupName: "Zones" },
  { id: "zone.reception", name: "Reception", groupName: "Zones" },
  { id: "zone.canteen", name: "Canteen", groupName: "Zones" },
  { id: "zone.standing", name: "Standing zone", groupName: "Zones" },
  { id: "zone.hotspot", name: "Hotspot zone", groupName: "Zones" },
  { id: "zone.hotdesk", name: "Hotdesk zone", groupName: "Zones" },
  { id: "room.*", operator: "regex", name: "Any room", groupName: "Rooms" },
  { id: "room", name: "General room", groupName: "Rooms" },
  { id: "room.meeting", name: "Meeting room", groupName: "Rooms" },
  { id: "room.quiet", name: "Quiet room", groupName: "Rooms" },
  { id: "room.private-office", name: "Private office", groupName: "Rooms" },
  { id: "room.locker", name: "Locker room", groupName: "Rooms" },
  { id: "room.facility", name: "Facility room", groupName: "Rooms" },
  { id: "room.auditorium", name: "Auditorium", groupName: "Rooms" },
  { id: "room.class", name: "Classroom", groupName: "Rooms" },
  { id: "room.toilet", operator: "regex", name: "Any toilet", groupName: "Toilets" },
  { id: "room.toilet.unisex", name: "Unisex toilet", groupName: "Toilets" },
  { id: "room.toilet.female", name: "Female toilet", groupName: "Toilets" },
  { id: "room.toilet.male", name: "Male toilet", groupName: "Toilets" },
  { id: "room.toilet.handicap", name: "Handicap toilet", groupName: "Toilets" },
  { id: "asset.*", operator: "regex", name: "Any asset", groupName: "Assets" },
  { id: "asset.*.seat", operator: "regex", name: "Any seat", groupName: "Assets" },
  { id: "asset.social.seat", name: "Social seat", groupName: "Assets" },
  { id: "asset.collaboration.seat", name: "Collaboration seat", groupName: "Assets" },
  { id: "asset.meeting.seat", name: "Meeting room seat", groupName: "Assets" },
  { id: "asset.quiet.seat", name: "Quiet room seat", groupName: "Assets" },
  { id: "asset.education.seat", name: "Education seat", groupName: "Assets" },
  { id: "asset.canteen.seat", name: "Canteen seat", groupName: "Assets" },
  { id: "asset.facility.seat", name: "Facility seat", groupName: "Assets" },
  { id: "asset.hotdesk.seat", name: "Hotdesk seat", groupName: "Assets" },
  { id: "asset.workstation", name: "Workstation", groupName: "Assets" },
  { id: "asset.pod", name: "Pod", groupName: "Assets" }
];

export const showInMapOptions = [
  { id: "inherit", name: "Inherited", value: null },
  { id: "show", name: "Show", value: true },
  { id: "hide", name: "Hide", value: false }
];

export const durationOptions = [
  { "id": 60, "name": "1 min" },
  { "id": 120, "name": "2 min" },
  { "id": 180, "name": "3 min" },
  { "id": 240, "name": "4 min" },
  { "id": 300, "name": "5 min" },
  { "id": 360, "name": "6 min" },
  { "id": 420, "name": "7 min" },
  { "id": 480, "name": "8 min" },
  { "id": 540, "name": "9 min" },
  { "id": 600, "name": "10 min" },
  { "id": 660, "name": "11 min" },
  { "id": 720, "name": "12 min" },
  { "id": 780, "name": "13 min" },
  { "id": 840, "name": "14 min" },
  { "id": 900, "name": "15 min" },
  { "id": 1200, "name": "20 min" },
  { "id": 1800, "name": "30 min" },
  { "id": 3600, "name": "60 min" },
  { "id": 10800, "name": "3 hours" },
  { "id": 21600, "name": "6 hours" },
  { "id": 43200, "name": "12 hours" },
  { "id": 86400, "name": "24 hours" },
  { "id": 604800, "name": "1 week" },
  { "id": 2419200, "name": "4 weeks" }
];

const supportHierarchy = [
  {
    id: "root",
    children: [
      "region",
      "building"
    ]
  },
  {
    id: "region",
    children: [
      "region",
      "building"
    ]
  },
  {
    id: "building",
    children: [
      "floor",
      "stairs",
      "elevator"
    ]
  },
  {
    id: "floor",
    children: [
      "zone",
      "zone.collaboration",
      "zone.social",
      "zone.exhibition",
      "zone.reception",
      "zone.canteen",
      "zone.hotspot",
      "zone.standing",
      "zone.hotdesk",
      "corridor",
      "stairs",
      "elevator",
      "room",
      "room.meeting",
      "room.quiet",
      "room.private-office",
      "room.locker",
      "room.facility",
      "room.auditorium",
      "room.class",
      "room.toilet.unisex",
      "room.toilet.female",
      "room.toilet.male",
      "room.toilet.handicap",
      "asset.social.seat",
      "asset.collaboration.seat",
      "asset.hotdesk.seat",
      "asset.workstation",
      "asset.pod"
    ]
  },
  {
    id: "zone",
    children: [
      "zone",
      "zone.collaboration",
      "zone.social",
      "zone.exhibition",
      "zone.reception",
      "zone.canteen",
      "zone.hotspot",
      "zone.standing",
      "zone.hotdesk",
      "corridor",
      "stairs",
      "elevator",
      "room",
      "room.meeting",
      "room.quiet",
      "room.private-office",
      "room.locker",
      "room.facility",
      "room.auditorium",
      "room.class",
      "room.toilet.unisex",
      "room.toilet.female",
      "room.toilet.male",
      "room.toilet.handicap",
      "asset.social.seat",
      "asset.collaboration.seat",
      "asset.hotdesk.seat",
      "asset.workstation",
      "asset.pod"
    ]
  },
  {
    id: "zone.collaboration",
    children: [
      "zone",
      "zone.hotspot",
      "zone.standing",
      "zone.hotdesk",
      "corridor",
      "stairs",
      "elevator",
      "room",
      "room.meeting",
      "room.quiet",
      "room.private-office",
      "room.toilet.unisex",
      "room.toilet.female",
      "room.toilet.male",
      "room.toilet.handicap",
      "asset.social.seat",
      "asset.collaboration.seat",
      "asset.hotdesk.seat",
      "asset.workstation",
      "asset.pod"
    ]
  },
  {
    id: "zone.social",
    children: [
      "zone",
      "zone.hotspot",
      "zone.standing",
      "zone.hotdesk",
      "corridor",
      "stairs",
      "elevator",
      "room",
      "room.meeting",
      "room.quiet",
      "room.private-office",
      "room.toilet.unisex",
      "room.toilet.female",
      "room.toilet.male",
      "room.toilet.handicap",
      "asset.social.seat",
      "asset.collaboration.seat",
      "asset.hotdesk.seat",
      "asset.workstation",
      "asset.pod"
    ]
  },
  {
    id: "zone.exhibition",
    children: [
      "zone",
      "corridor",
      "stairs",
      "elevator",
      "room",
      "room.auditorium",
      "room.class",
      "room.toilet.unisex",
      "room.toilet.female",
      "room.toilet.male",
      "room.toilet.handicap",
      "asset.social.seat",
      "asset.collaboration.seat",
      "asset.education.seat",
    ]
  },
  {
    id: "zone.reception",
    children: [
      "zone",
      "corridor",
      "stairs",
      "elevator",
      "room",
      "room.toilet.unisex",
      "room.toilet.female",
      "room.toilet.male",
      "room.toilet.handicap",
      "asset.social.seat",
    ]
  },
  {
    id: "zone.canteen",
    children: [
      "zone",
      "corridor",
      "stairs",
      "elevator",
      "room",
      "room.toilet.unisex",
      "room.toilet.female",
      "room.toilet.male",
      "room.toilet.handicap",
      "asset.social.seat",
      "asset.collaboration.seat",
      "asset.canteen.seat"
    ]
  },
  {
    id: "zone.hotspot",
    children: [
      "asset.collaboration.seat",
    ]
  },
  {
    id: "zone.standing",
    children: []
  },
  {
    id: "zone.hotdesk",
    children: [
      "asset.hotdesk.seat"
    ]
  },
  {
    id: "corridor", 
    children: [
      "asset.social.seat",
      "asset.collaboration.seat",
      "asset.pod"
    ]
  },
  {
    id: "stairs", 
    children: []
  },
  {
    id: "elevator", 
    children: []
  },
  {
    id: "room", 
    children: [
      "asset.social.seat",
      "asset.collaboration.seat",
      "asset.hotdesk.seat",
      "asset.workstation",
      "asset.pod"
    ]
  },
  {
    id: "room.meeting", 
    children: [
      "asset.meeting.seat"
    ]
  },
  {
    id: "room.quiet", 
    children: [
      "asset.quiet.seat"
    ]
  },
  {
    id: "room.private-office", 
    children: []
  },
  {
    id: "room.locker", 
    children: []
  },
  {
    id: "room.facility", 
    children: [
      "asset.facility.seat"
    ]
  },
  {
    id: "room.auditorium", 
    children: [
      "asset.education.seat"
    ]
  },
  {
    id: "room.class", 
    children: [
      "asset.education.seat"
    ]
  },
  {
    id: "room.toilet.unisex", 
    children: []
  },
  {
    id: "room.toilet.female", 
    children: []
  },
  {
    id: "room.toilet.male", 
    children: []
  },
  {
    id: "room.toilet.handicap", 
    children: []
  },
  {
    id: "asset.social.seat", 
    children: []
  },
  {
    id: "asset.collaboration.seat", 
    children: []
  },
  {
    id: "asset.meeting.seat", 
    children: []
  },
  {
    id: "asset.quiet.seat", 
    children: []
  },
  {
    id: "asset.education.seat", 
    children: []
  },
  {
    id: "asset.canteen.seat",
    children: []
  },
  {
    id: "asset.facility.seat",
    children: []
  },
  {
    id: "asset.hotdesk.seat", 
    children: []
  },
  {
    id: "asset.workstation", 
    children: []
  },
  {
    id: "asset.pod", 
    children: []
  }
];

export const getSupportedLocationTypes = (type) => {
  // console.log("getSupportedLocationTypes", type);

  if (!type) {
    return locationTypes;
  }

  const supportedLocationType = supportHierarchy.find((location) => location.id === type);
  if (!supportedLocationType) {
    return [];
  }

  let supportedLocationTypes = [];
  const supportedChildren = supportedLocationType.children;
  supportedChildren.forEach((childId) => {
    let foundTypes = locationTypes.filter((locationType) => {
      return locationType.id === childId;
    });
    supportedLocationTypes = [...supportedLocationTypes, ...foundTypes];
  });

  // Create groups
  const groupedSupportedLocationTypes = [];
  supportedLocationTypes.forEach(supportedLocationType => {
    if (supportedLocationType.groupName) {
      const supportedGroup = groupedSupportedLocationTypes.find((group) => group.name === supportedLocationType.groupName);
      if (supportedGroup) {
        supportedGroup.children.push(supportedLocationType);
      }
      else {
        groupedSupportedLocationTypes.push({ name: supportedLocationType.groupName, children: [supportedLocationType] });
      }
    }
    else {
      groupedSupportedLocationTypes.push(supportedLocationType);
    }
  });

  return groupedSupportedLocationTypes;
};

export const getLocationFilterTypes = () => {
  // Create groups
  const groupedLocationFilterTypes = [];
  locationFilterTypes.forEach(locationType => {
    if (locationType.groupName) {
      const group = groupedLocationFilterTypes.find((group) => group.name === locationType.groupName);
      if (group) {
        group.children.push(locationType);
      }
      else {
        groupedLocationFilterTypes.push({ name: locationType.groupName, children: [locationType] });
      }
    }
    else {
      groupedLocationFilterTypes.push(locationType);
    }
  });

  return groupedLocationFilterTypes;
};


// Resource types used for booking
export const resourceTypeOptions = [
  { id: "parking", name: "Parking" },
  { id: "locker room", name: "Locker room" },
  { id: "lunch", name: "Lunch" },
  { id: "team table", name: "Team table" },
  { id: "workstation", name: "Workstation" }
];

export const resourceTypeSelector = [
  { id: "", name: "Select type" },
  { id: "parking", name: "Parking" },
  { id: "locker room", name: "Locker room" },
  { id: "lunch", name: "Lunch" },
  { id: "team table", name: "Team table" },
  { id: "workstation", name: "Workstation" }
];

// Extension attributes value types
export const extensionAttributeValueTypes = [
  { id: "string", name: "String" },
  { id: "number", name: "Number" },
  // { id: "boolean", name: "Boolean" },
  // { id: "json", name: "JSON" },
];

export const getLocationName = (location, locationId) => {

  if (location.id === locationId || location._id === locationId) {
    return location.name;
  }

  let locationName = null;
  location.children.forEach(child => {
    const result = getLocationName(child, locationId);
    if (result) {
      locationName = result;
    }
  });

  return locationName;
}

export const getParentLocationId = (location, locationId) => {

  const foundChild = location.children.find(child => child.id === locationId);
  if (foundChild) {
    return location.id;
  }

  let foundLocationId = null;
  location.children.forEach(child => {
    const result = getParentLocationId(child, locationId);
    if (result) {
      foundLocationId = result;
    }
  });

  return foundLocationId;
}

export const getParentLocation = (location, locationId) => {

  const foundChild = location.children.find(child => child.id === locationId || child._id === locationId);
  if (foundChild) {
    return location;
  }

  let foundLocation = null;
  location.children.forEach(child => {
    const result = getParentLocation(child, locationId);
    if (result) {
      foundLocation = result;
    }
  });

  return foundLocation;
}

export const getBreadcrumbs = (location, locationId) => {

  const foundChild = location.children.find(child => child._id === locationId || child.id === locationId);
  console.log("foundChild", foundChild, locationId);
  if (foundChild) {
    return location.name;
  }

  let childBreadcrumbs = null;
  location.children.forEach(child => {
    const result = getBreadcrumbs(child, locationId);
    if (result) {
      childBreadcrumbs = result;
    }
  });

  if (childBreadcrumbs === null) {
    return null;
  }
  
  if (location.name === "") {
    return childBreadcrumbs;
  }
  
  return `${location.name}, ${childBreadcrumbs}`;
}

export const getBreadcrumbLocations = (location, locationId) => {

  const foundChild = location.children.find(child => child._id === locationId || child.id === locationId);
  if (foundChild) {
    return [location];
  }

  let childBreadcrumbs = null;
  location.children.forEach(child => {
    const result = getBreadcrumbLocations(child, locationId);
    if (result) {
      childBreadcrumbs = result;
    }
  });

  if (childBreadcrumbs === null) {
    return null;
  }
  
  return [location, ...childBreadcrumbs];
}

export const buildFilterBody = (queries) => {

  let body = {};
  let or = [];

  // Loop over all location queries and make an OR query for each with descendants
  queries.forEach(query => {

    let safeQueries = [];

    // Add location name sub query
    if (query.search) {
      safeQueries.push({ property: "name", value: `${query.search}`, operator: "regex", flags: "gi" });
    }

    // Add location type sub query
    if (query.locationTypes && query.locationTypes.length > 0) {
      safeQueries = safeQueries.concat(query.locationTypes.map(locationType => ({ property: "type", value: locationType.id, operator: "eq" })));
    }

    // Add custom tag sub query
    if (query.customTags && query.customTags.length > 0) {
      safeQueries = safeQueries.concat(query.customTags.map(customTag => ({ property: "customTag", value: customTag.id, operator: "eq" })));
    }

    let locationQueries = [];

    // Add location id sub query
    if (query.locations && query.locations.length > 0) {

      query.locations.map(location => {
        let subQuery = { property: "_id", value: location.id };
        
        if (location.include) {
          subQuery.operator = "eq";
        }
        else {
          subQuery.operator = "ne";
        }

        // TODO - add include/exclude descendants logic to picker
        if (location.descendants) {
          subQuery.options = { includeDescendants: true };
        }
        else {
          subQuery.options = { includeDescendants: false };
        }

        // Add extra OR queries
        if (location.untilIds && location.untilIds.length > 0) {
          let untilQueries = location.untilIds.map(untilId => ({ property: "_id", value: untilId, operator: "ne", options: { includeDescendants: true } }));
          locationQueries.push([subQuery, ...untilQueries]);
        }
        else {
          locationQueries.push([subQuery]);
        }

      });
    }

    // Combine locationQueries with safeQueries
    if (locationQueries.length > 0) {
      locationQueries.forEach(locationQuery => {
        let and = [...locationQuery, ...safeQueries];
        or.push({ and });
      });
    }
    else if (safeQueries.length > 1) {
      or.push({ and: safeQueries });
    }
    else if (safeQueries.length > 0) {
      or.push(safeQueries[0]);
    }

  });

  // If there are multiple queries - encapsulate it in an OR
  // If there are only 1 query - do NOT encapsulate it in an OR
  // If there are no queries - add a query that returns everything
  if (or.length > 1) {
    body = { or };
  }
  else if (or.length === 1) {
    body = or[0];
  }
  else {
    body = { property: "name", value: "", operator: "regex" };
  }

  return body;
};

export const buildQueriesFromFilter = (filter) => {
  let queries = [];
  if (filter.or) {
    queries = filter.or.map(sub => getQueryFromFilterPart(sub));
  }
  else {
    queries = [getQueryFromFilterPart(filter)];
  }

  return queries;
};

/*
{ or: [] }
{ and: [] }
{ property }
*/
export const getQueryFromFilterPart = (filter) => {
  let query = {
    locationTypes: [],
    locations: [],
    customTags: [],
    names: []
  };

  if (filter.or) {
    filter.or.forEach(sub => {
      let result = getQueryFromFilterPart(sub);
      query.locationTypes = [...query.locationTypes, ...result.locationTypes];
      query.locations = [...query.locations, ...result.locations];
      query.customTags = [...query.customTags, ...result.customTags];
      query.names = [...query.names, ...result.names];
    });
  }
  else if (filter.and) {
    filter.and.forEach(sub => {
      let result = getQueryFromFilterPart(sub);
      query.locationTypes = [...query.locationTypes, ...result.locationTypes];
      query.locations = [...query.locations, ...result.locations];
      query.customTags = [...query.customTags, ...result.customTags];
      query.names = [...query.names, ...result.names];
    });

    // Combine locations (1 include + X exclude) into one
    if (query.locations.length > 1) {
      let includedLocations = query.locations.filter(l => l.include);
      if (includedLocations.length === 1) {
        let excludedLocations = query.locations.filter(l => !l.include);
        includedLocations[0].untilIds = excludedLocations.map(l => l.id);
        query.locations = includedLocations;
      }
    }
  }
  else {
    if (filter.property === "_id") {
      query.locations = [{ id: filter.value, include: filter.operator === "eq", descendants: get(filter, "options.includeDescendants", false) }];
    }

    if (filter.property === "customTag") {
      query.customTags = [filter.value];
    }

    if (filter.property === "type") {
      query.locationTypes = [filter.value];
    }

    if (filter.property === "name") {
      query.names = [filter.value];
    }
  }

  return query;
};


/*
  + Bygg K
    - Møterom K3A
    - Møterom K3B
    - Møterom K3C
  + Trondheim
    - Only Møterom Lilla
*/

// Return { or: [], and?: [] }
export const getQueryFromLocationHierarchy = (ancestorQuery, children) => {

  let or = [];
  let and = [];

  children.forEach(child => {
    let result = { or: [] };
    
    if (!ancestorQuery) {
      if (child.included) {
        // No existing top include - so add this as the top 
        let query = { property: "_id", value: child.id, operator: "eq", options: { includeDescendants: true }};
        result = getQueryFromLocationHierarchy(query, child.children);
        
        if (result.and && result.and.length > 0) {
          or.push({ and: [query, ...result.and]});
        }
        else {
          or.push(query);
        }
      }
      else {
        // Ignore top excludes
        result = getQueryFromLocationHierarchy(null, child.children);
      }
    }
    else if (ancestorQuery.operator === "eq") {
      if (child.included) {
        // Pass along consecutive includes
        result = getQueryFromLocationHierarchy(ancestorQuery, child.children);
        if (result.and) {
          and = [...and, ...result.and];
        }
      }
      else {
        // Ancestor is included, but this is excluded - add to current sub query - and dive deeper
        let query = { property: "_id", value: child.id, operator: "ne", options: { includeDescendants: true }};
        and.push(query);

        result = getQueryFromLocationHierarchy(null, child.children);
      }
    }

    or = [...or, ...result.or];
  });

  if (and.length > 0) {
    return { and, or };
  }
  else {
    return { or };
  }
}

export const fillLocationHierarchyFromQuery = (hierarchy, queries) => {

  let newHierarchy = hierarchy;

  queries.forEach(query => {
    if (query.and) {
      let includedLocationIds = query.and[0].value;
      let excludedLocationIds = query.and.slice(1).map(q => q.value);
      let location = getLocation({ id: "*", children: newHierarchy }, includedLocationIds);
      location.included = true;
      location.children = includeChildren(location.children, excludedLocationIds);
    }
    else {
      let location = getLocation({ id: "*", children: newHierarchy }, query.value);
      location.included = true;
      location.children = includeChildren(location.children);
    }
  })

  return newHierarchy;
}

// Adds included to each child until spesific ids or if child already is included
// - and return everything
export const includeChildren = (children, untilIds = []) => {
  for (var i=0; i<children.length; i++) {
    if (!children[i].included && !untilIds.includes(children[i].id)) {
      children[i].included = true;
      includeChildren(children[i].children, untilIds);
    }
  }
  return children;
}

// Adds exclude to each child until spesific ids or if child already is excluded
// - and return everything
export const excludeChildren = (children, untilIds = []) => {
  for (var i=0; i<children.length; i++) {
    if (children[i].included && !untilIds.includes(children[i].id)) {
      delete children[i].included;
      excludeChildren(children[i].children, untilIds);
    }
  }
  return children;
}

export const getLocation = (location, locationId) => {

  if (location.id === locationId || location._id === locationId) {
    return location;
  }

  let foundLocation = null;
  location.children.forEach(child => {
    const result = getLocation(child, locationId);
    if (result) {
      foundLocation = result;
    }
  });

  return foundLocation;
}

export const removeLocationQuery = (query, id) => {
  
  // Remove outer location query
  query.and = query.and.filter(q => q.property !== "_id" || q.value !== id);

  let orIndex = query.and.findIndex(q => q.or);
  if (orIndex > -1) {
    // Remove singular inner location query
    query.and[orIndex].or = query.and[orIndex].or.filter(q => q.property !== "_id" || q.value !== id);
  
    let andIndex = query.and[orIndex].or.findIndex(q => q.and);
    if (andIndex > -1) {
      // Remove inner location query
      query.and[orIndex].or[andIndex].and = query.and[orIndex].or[andIndex].and.filter(q => q.property !== "_id" || q.value !== id);

      // Remove inner location query if empty or only has exclude
      if (query.and[orIndex].or[andIndex].and.length === 0) {
        query.and[orIndex].or.splice(andIndex, 1);
      } 
      else {
        let allAreExclude = query.and[orIndex].or[andIndex].and.every(q => q.operator === "ne");
        if (allAreExclude) {
          query.and[orIndex].or.splice(andIndex, 1);
        }
      }
    }
  }

  return query;
}

export const isCustomTagIncluded = (tagId, selectedQuery) => {
  const queries = get(selectedQuery, "and", []);
  return !isEmpty(queries.find(q => q.property === "customTag" && q.value === tagId));
}

// Transform a location hierarchy to a object with all locations as keys and values as the locations ancestors + self
export const populateAncestors = (children, ancestors, result) => {
  children.forEach(location => {
    result[location.id] = [...ancestors, { id: location.id, name: location.name, type: location.type }];
    populateAncestors(location.children, result[location.id], result);
  });
}

// Flatten the location hierarchy and include the ancestors
export const flattenHierarchy = (children, ancestors, result) => {
  children.forEach(location => {
    result[location.id] = { ...location, ancestors };
    flattenHierarchy(location.children, [...ancestors, { id: location.id, name: location.name }], result);
  });
}

export const getDirectDescendantsWithFilter = (children, filterIds) => {

  let result = [];
  children.forEach(child => {
    if (filterIds.includes(child.id)) {
      result = [...result, child.id];
    }
    else {
      result = [...result, ...getDirectDescendantsWithFilter(child.children, filterIds)];
    }
  });

  return result;
}

export const getSubLocationHierarchy = (location, locationId) => {

  if (location.id === locationId) {
    return location.children;
  }

  let subHierarchy = null;
  // console.log(location);
  location.children.forEach(child => {
    const result = getSubLocationHierarchy(child, locationId);
    if (result) {
      subHierarchy = result;
    }
  });

  return subHierarchy;
}
