import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Switch, Route, Redirect } from "react-router-dom";
import isEmpty from "lodash/isEmpty";
import NotFound from "../NotFound";
import DetailsHeader from "../../components/DetailsHeader";
import SubNavigation from "../../components/SubNavigation";
import Tag from "../../components/Tag";
import InviteDetailsInfo from "./inviteDetailsInfo";
import InviteDetailsGroups from "./inviteDetailsGroups";
import * as usersActions from "../../actions/users";
import { getRoleName } from "../../helpers";

class InviteDetails extends Component {

  constructor(props) {
    super(props);
    // console.log("InviteDetails.constructor",this.props);
    if (isEmpty(this.props.inviteId)) {
      this.props.clearInvite();
    }
    else if (this.props.invite.id !== this.props.inviteId) {
      // console.log("InviteDetails.getInvite");
      this.props.getInvite(this.props.inviteId);
    }
  }

  componentDidUpdate(prevProps) {
    // console.log("InviteDetails.componentDidUpdate",this.props);
    // Only load data if id change
    if (isEmpty(this.props.inviteId)) {
      this.props.clearInvite();
    }
    else if (prevProps.inviteId !== this.props.inviteId) {
      this.props.getInvite(this.props.inviteId);
    }
  }

  render() {
    // console.log("InviteDetails.render()", this.props)
    const { isLoading } = this.props;

    if (isEmpty(this.props.inviteId) || isEmpty(this.props.invite.id)) {
      return null;
    }

    const subNavigationLinks = [];
    subNavigationLinks.push({ pathname: `/companies/${this.props.match.params.companyId}/org/invites/${this.props.inviteId}/info`, label: `Info` });
    subNavigationLinks.push({ pathname: `/companies/${this.props.match.params.companyId}/org/invites/${this.props.inviteId}/groups`, label: `Groups` });
    
    let title = "Invite";

    return (
      <div>
        <DetailsHeader
          title={title}
          isLoading={isLoading}
          canDelete={true}
          onDeleteClicked={() => this.props.deleteInvite(this.props.invite.id)}
        />
        <SubNavigation links={subNavigationLinks} slim />
        <Switch>
          <Redirect exact from={`/companies/:companyId/org/invites/${this.props.inviteId}`} to={{ pathname: `/companies/${this.props.match.params.companyId}/org/invites/${this.props.inviteId}/info` }} />
          <Route path="/companies/:companyId/org/invites/:inviteId/info" children={(props) => <InviteDetailsInfo {...props} inviteId={this.props.inviteId} />} />
          <Route path="/companies/:companyId/org/invites/:inviteId/groups" children={(props) => <InviteDetailsGroups {...props} inviteId={this.props.inviteId} />} />
          <Route component={NotFound} />
        </Switch>
        <div style={{ paddingTop: "40px" }} />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    invite: state.invite,
    selectedCompany: state.auth.selectedCompany
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    getInvite: usersActions.getInvite,
    deleteInvite: usersActions.deleteInvite,
    clearInvite: usersActions.clearInvite
   }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(InviteDetails);